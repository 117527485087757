import moment from 'moment'
import attribDataTypeResponse from '../constants/AttributeDataTypeStaticResponse'
import util from './common'
import MarketplaceToaster from './marketplaceToaster'
// to get image URL using image path

export const sortObjectArray = (prop, objectArray) => {
    prop = prop.split('.')
    var len = prop.length

    objectArray.sort(function (a, b) {
        var i = 0
        while (i < len) {
            a = a[prop[i]]
            b = b[prop[i]]
            i++
        }
        if (a < b) {
            return -1
        } else if (a > b) {
            return 1
        } else {
            return 0
        }
    })
    return objectArray
}

export const sortObjectArrayByKey = (key, keyType, array, sortType) => {
    return array.sort(function (a, b) {
        var x, y

        if (keyType === 'number') {
            x = a[key]

            y = b[key]
        } else {
            x = a[key].toLowerCase()

            y = b[key].toLowerCase()
        }

        if (sortType === 'desc') return x > y ? -1 : x < y ? 1 : 0
        else return x < y ? -1 : x > y ? 1 : 0
    })
}

export const isInteger = (value) => {
    return Number.isInteger(parseInt(value))
}
export const isFloating = (value) => {
    return !Number.isNaN(parseFloat(value))
}

export const getTitleForKeyFromArrayObject = (filterKey, filterKeyValue, titleKey, arrayObject) => {
    try {
        const filteredData = arrayObject.filter((element) => String(element[filterKey]) === String(filterKeyValue))
        return filteredData.length > 0 ? filteredData[0][titleKey] : 'Not Available'
    } catch (error) {
        return 'Not Supported'
    }
}

export const getProductTypeSupportedDataList = (productTypeData, ListData) => {
    if (productTypeData && productTypeData.length > 0) {
        const onlySupportedProductTypesData = productTypeData.filter((element) => element.status !== 'unsupported')

        const filterLists = []
        for (var i = 0; i < onlySupportedProductTypesData.length; i++) {
            for (var j = 0; j < ListData.length; j++) {
                if (onlySupportedProductTypesData[i].product_type_id === ListData[j].product_type) {
                    filterLists.push(ListData[j])
                }
            }
        }

        return filterLists
    }
}
export const deepCopy = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    if (obj instanceof Date) {
        return new Date(obj.getTime())
    }

    if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopy(item)
            return arr
        }, [])
    }

    if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
            newObj[key] = deepCopy(obj[key])
            return newObj
        }, {})
    }
}

// function will validate the string value based on the different type of regex pattern
// Note: user has to pass the valid regex pattern to this function
export const testValueByRegexPattern = (regexPattern, StringValue) => {
    try {
        if (new RegExp(regexPattern).test(StringValue)) {
            return true
        } else {
            return false
        }
    } catch {
        MarketplaceToaster.showToast(util.getToastObject(`Invalid regular expression :${regexPattern}`, 'error'))
    }
}

export const getImageUrl = (imagePath) => {
    return imagePath
}

//! this util function will give object based on params what it will receive in url
export const generateObjectFromUrl = (url) => {
    //? const urlParams = new URLSearchParams(url);
    //? console.log(urlParams.has('product'));   // true
    //? const product = urlParams.get('image_path')  // hudwromgbkmf

    var queryString = url ? url.split('?')[1] : window.location.search.slice(1)
    // we'll store the parameters here
    var obj = {}

    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0]

        // split our query string into its component parts
        var arr = queryString.split('&')

        for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=')

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0]
            var paramValue = typeof a[1] === 'undefined' ? true : a[1]

            // (optional) keep case consistent
            paramName = paramName.toLowerCase()
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

            // if the paramName ends with square brackets,
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '')
                if (!obj[key]) obj[key] = []

                // if it's an indexed array
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var index = /\[(\d+)\]/.exec(paramName)[1]
                    obj[key][index] = paramValue
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue)
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array

                    obj[paramName] = [obj[paramName]]
                    obj[paramName].push(paramValue)
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue)
                }
            }
        }
    }

    //! making object according to required structure
    //! removing and adding some more key to Object a/c to our req
    //! (supporting- image and document)
    if (obj && Object.keys(obj).length > 0) {
        if (Object.keys(obj)[0] === 'image-path') {
            // adding more key : pairs to object
            obj['image_path'] = obj['image-path']
            obj['image_type'] = obj['image-type']
            obj['store_id'] = obj['store-id']
            obj['status'] = '100'
            obj['specific_to'] = null
            obj['visibility'] = null
            obj['default_ar'] = null
            obj['default_size_name'] = null
            delete obj['image-path']
            delete obj['image-type']
            delete obj['store-id']
        } else if (Object.keys(obj)[0] === 'document-path') {
            obj['document_path'] = obj['document-path']
            obj['document_type'] = obj['document-type']
            obj['store_id'] = obj['store-id']
            obj['status'] = '1'
            obj['specific_to'] = null
            obj['visibility'] = null
            delete obj['document-path']
            delete obj['document-type']
            delete obj['store-id']
        }
    }
    return obj
}
//!This will return the required format for the array and object with image_full_path when provided value(String)
export const generateObjectFromUrlWithFullImagePath = (value) => {
    var imageArray = []
    var imageFullObject = {}
    imageFullObject.image_full_path = value
    imageArray.push(imageFullObject)
    return imageArray
}
//!This will return the required format for the array and object with document_full_path when provided value(String)
export const generateObjectFromUrlWithFullDocumentPath = (value) => {
    var documentArray = []
    var documentFullObject = {}
    documentFullObject.document_full_path = value
    documentArray.push(documentFullObject)
    return documentArray
}

// function will validate the Character Code to know, whether it is a alphabet/special character or not
// if it is alphabet/special character, will return true.
// if it is number, will return false.
export const isAlphabetOrNot = (charCode) => {
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return true
    } else {
        return false
    }
}

// function will validate the Character Code to allow only alphabets to enter in textfield
// if it is number/special character it will return false
//  if it is alphabets it will return true
export const restrictNumbersAndSpecialCharacter = (charCode) => {
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) return true
    else {
        return false
    }
}

//! This function filters the categoryLists according to parentCategoryId which we are sent and it will return the data according to it
//? first parameter accepts the parentCategoryId which is integer value
//? second parameter accepts the categoryLists which is array of objects
//! how to use
// import the function in component
// ie. import {filterCategoriesByParentCategoryId} from "../../util/util"; here path may vary according to component
// then const filterData=filterCategoriesByParentCategoryId(1,productTypeList);
// filterData variable will store all categories which are having parentCategory which we are send

export const filterCategoriesByParentCategoryId = (parentCategoryId, categoryList) => {
    let filterParentCategory = []
    if (parentCategoryId === null) {
        filterParentCategory = categoryList.filter((ele) => ele.parent_category === null)
    } else {
        filterParentCategory = categoryList.filter((ele) => ele.parent_category === parentCategoryId)
    }
    return filterParentCategory
}

//! It is a recursive function it takes 4 parameters and it will return the children categories to parent category
//? first parameter accepts the parentCategoryId which is integer value
//? second parameter accepts the categoryLists which is array of objects
//? third parameter accepts the isButtonRequired which accepts boolean here if you send true it will return one extra
//? data which used for designing button in list categories for this only we are passing value true but in most of cases it is false only
//? fourth parameter accepts the productType lists which is array of objects
//! How to use
// import the function in component
// ie. import {getChildrenForParentCategoryRecursiveFunction} from "../../util/util"; here path may vary according to component
// then const data=getChildrenForParentCategoryRecursiveFunction(1,lists,false,productTypeList);
// data variable will store the all children of particular parentCategoryId what we are send

export const getChildrenForParentCategoryRecursiveFunction = (
    parentcategoryId,
    categoryLists,

    productTypeList
) => {
    let parentChildrenData = []
    if (categoryLists && categoryLists.length > 0) {
        const filterChildrenData = categoryLists.filter((item) => item.parent_category === parentcategoryId)
        for (var j = 0; j < filterChildrenData.length; j++) {
            const categoryId = filterChildrenData[j].category_id
            const storeproductTypeRevisionName = filterChildrenData[j].store_product_type_name

            let Childrens = getChildrenForParentCategoryRecursiveFunction(
                categoryId,
                categoryLists,

                productTypeList
            )
            if (Childrens.length > 0) {
                filterChildrenData[j]['children'] = Childrens
            }

            filterChildrenData[j]['store_product_type_revision_name'] = storeproductTypeRevisionName
            parentChildrenData.push(filterChildrenData[j])
        }

        return parentChildrenData
    } else {
        return []
    }
}

//! It  takes 3 parameters and it will return the parent children categories list
//? first parameter accepts the categories lists
//? second parameter accepts the storeProductTypes lists
//? third parameter accepts the isButtonRequired which accepts boolean here if you send true it will return one extra
//? data which used for designing button in list categories for this only we are passing value true but in most of cases it is false only

//! How to use
// import the function in component
// ie. import {getParentChildrenCategories} from "../../util/util"; here path may vary according to component
// then const data=getParentChildrenCategories(lists,productTypeList,false);
// data variable will store the all children of particular parentCategoryId what we are send

export const getParentChildrenCategories = (categories, storeProductTypes, parentCategory) => {
    const parentChildrenData = []
    if (categories && categories.length > 0) {
        const filterParentCategories = filterCategoriesByParentCategoryId(parentCategory, categories)
        for (var i = 0; i < filterParentCategories.length; i++) {
            const categoryId = filterParentCategories[i].category_id
            const storeproductTypeRevisionName = filterParentCategories[i].store_product_type_name

            let Children = getChildrenForParentCategoryRecursiveFunction(
                categoryId,
                categories,

                storeProductTypes
            )
            if (Children.length > 0) {
                filterParentCategories[i]['children'] = Children
            }

            filterParentCategories[i]['store_product_type_revision_name'] = storeproductTypeRevisionName
            parentChildrenData.push(filterParentCategories[i])
        }
        return parentChildrenData
    } else {
        return []
    }
}

//! It  takes 3 parameters and it will return the parent children categories list along with root data
//? first parameter accepts the categories lists
//? second parameter accepts the storeProductTypes lists
//? third parameter accepts the isButtonRequired which accepts boolean here if you send true it will return one extra
//? data which used for designing button in list categories for this only we are passing value true but in most of cases it is false only

//! How to use
// import the function in component
// ie. import {getParentChildrenData} from "../../util/util"; here path may vary according to component
// then const data=getParentChildrenData(lists,productTypeList,false);
// data variable will store the all children of particular parentCategoryId what we are send
export const getParentChildrenData = (dataProcessorData, storeProductTypes) => {
    if (dataProcessorData && dataProcessorData.length > 0) {
        // if (dataProcessorData && dataProcessorData.length === 1) {
        if (dataProcessorData && dataProcessorData[0].children.length > 0) {
            let copyDataProcessorData = dataProcessorData
            const categorylists = getParentChildrenCategories(
                dataProcessorData[0].children,
                storeProductTypes,
                dataProcessorData[0].value
            )
            copyDataProcessorData[0].children = categorylists
            return copyDataProcessorData
        } else {
            let copyDataProcessorData = dataProcessorData
            return copyDataProcessorData
        }
    } else {
        return []
    }
}

export const getCategoryListsRecursiveFunction = (categoryLists, currentCategoryId, booleanChildren) => {
    let parentChildrenData = []
    if (categoryLists && categoryLists.length > 0) {
        for (var j = 0; j < categoryLists.length; j++) {
            if (categoryLists[j].id !== currentCategoryId) {
                const temp = {}
                temp['key'] = categoryLists[j].id
                temp['value'] = categoryLists[j].id
                temp['title'] = categoryLists[j].display_name
                temp['id'] = categoryLists[j].id
                temp['parentCategoryId'] = categoryLists[j].parent_category_id
                temp['categoryStatus'] = categoryLists[j].category_status
                temp['categoryImage'] = categoryLists[j].category_image
                temp['name'] = categoryLists[j].name
                temp['description'] = categoryLists[j].description
                temp['displayName'] = categoryLists[j].display_name
                temp['displayDescription'] = categoryLists[j].display_description
                temp['totalProductTemplate'] = categoryLists[j].total_product_template
                temp['publishedProducts'] = categoryLists[j].total_products
                temp['deletable'] = categoryLists[j].deletable
                temp['deleteMessage'] = categoryLists[j].delete_message
                temp['showDeleteIcon'] = booleanChildren
                temp['category_media_full_path'] = categoryLists[j].category_media_full_path
                if (categoryLists[j].children_category) {
                    temp['SubCategories'] = getSubCategoriesLength(categoryLists[j].children_category)
                } else {
                    temp['SubCategories'] = 0
                }

                let Childrens = getCategoryListsRecursiveFunction(
                    categoryLists[j].children_category,
                    currentCategoryId,
                    true
                )

                if (Childrens.length > 0) {
                    temp['children'] = Childrens
                }

                parentChildrenData.push(temp)
            }
        }
        return parentChildrenData
    } else {
        return []
    }
}

export const getParentChildrenDataForCategories = (categoriesList, currentCategoryId) => {
    let localData = []
    if (categoriesList && categoriesList.length > 0) {
        for (var i = 0; i < categoriesList.length; i++) {
            const temp = {}
            temp['key'] = categoriesList[i].id
            temp['id'] = categoriesList[i].id
            temp['value'] = categoriesList[i].id
            temp['title'] = categoriesList[i].display_name
            temp['parentCategoryId'] = categoriesList[i].parent_category_id
            temp['categoryStatus'] = categoriesList[i].category_status
            temp['categoryImage'] = categoriesList[i].category_image
            temp['name'] = categoriesList[i].name
            temp['description'] = categoriesList[i].description
            temp['displayName'] = categoriesList[i].display_name
            temp['displayDescription'] = categoriesList[i].display_description
            temp['totalProductTemplate'] = categoriesList[i].total_product_template
            temp['publishedProducts'] = categoriesList[i].total_products
            temp['deletable'] = categoriesList[i].deletable
            temp['deleteMessage'] = categoriesList[i].delete_message
            temp['SubCategories'] = getSubCategoriesLength(categoriesList[i].children_category)
            temp['category_media_full_path'] = categoriesList[i].category_media_full_path
            temp['showDeleteIcon'] =
                categoriesList[i].name.toUpperCase() === 'HEADER' || categoriesList[i].name.toUpperCase() === 'HOME'
                    ? false
                    : true
            let booleanChildren = true
            if (categoriesList[i].name.toUpperCase() === 'HEADER' || categoriesList[i].name.toUpperCase() === 'HOME') {
                if (categoriesList[i].children_category && categoriesList[i].children_category.length === 1) {
                    booleanChildren = false
                } else {
                    booleanChildren = true
                }
            }
            let childrenData = getCategoryListsRecursiveFunction(
                categoriesList[i].children_category,
                currentCategoryId,
                booleanChildren
            )
            if (childrenData.length > 0) {
                temp['children'] = childrenData
            }

            localData.push(temp)
        }
        return localData
    } else {
        return []
    }
}
export const getSubCategoriesLengthRecursiveFunction = (childrenCategory) => {
    let subCategories = childrenCategory.length
    if (childrenCategory && childrenCategory.length > 0) {
        for (var i = 0; i < childrenCategory.length; i++) {
            if (childrenCategory[i].children_category) {
                subCategories =
                    subCategories + getSubCategoriesLengthRecursiveFunction(childrenCategory[i].children_category)
            }
        }
    }
    return subCategories
}

export const getSubCategoriesLength = (childrenCategory) => {
    let subCategories = childrenCategory.length
    if (childrenCategory && childrenCategory.length > 0) {
        for (var i = 0; i < childrenCategory.length; i++) {
            if (childrenCategory[i].children_category) {
                subCategories =
                    subCategories + getSubCategoriesLengthRecursiveFunction(childrenCategory[i].children_category)
            }
        }
    }
    return subCategories
}

let flatProductTemplateData = []
export const getActiveProductTemplateRecursiveFunction = (activeProductTemplateData, parentCategoryId, add) => {
    let productParentChildrenData = []
    if (activeProductTemplateData && activeProductTemplateData.length > 0) {
        for (var j = 0; j < activeProductTemplateData.length; j++) {
            const temp = {}
            temp['key'] = activeProductTemplateData[j].product_template_id
            temp['value'] = activeProductTemplateData[j].product_template_id
            if (parentCategoryId !== undefined && parentCategoryId.length > 0) {
                let index = parentCategoryId.findIndex(
                    (ele) => ele === activeProductTemplateData[j].product_template_id
                )
                if (index !== -1) {
                    if (add) {
                        temp['disabled'] = false
                        temp['halfChecked'] = false
                    } else {
                        temp['disabled'] = true
                        temp['halfChecked'] = false
                    }
                }
            }

            temp['revisionId'] = activeProductTemplateData[j].id
            temp['title'] = activeProductTemplateData[j].display_name
            temp['description'] = activeProductTemplateData[j].description
            temp['product_status'] = activeProductTemplateData[j].product_status
            temp['last_published_revision'] = activeProductTemplateData[j].last_published_revision
            temp['display_name'] = activeProductTemplateData[j].display_name
            temp['display_description'] = activeProductTemplateData[j].display_description
            temp['product_template_revision_details'] = activeProductTemplateData[j].product_template_revision_details
            let childrenData = getActiveProductTemplateRecursiveFunction(
                activeProductTemplateData[j].child_product_template,
                parentCategoryId
            )
            flatProductTemplateData.push(temp)
            if (childrenData.length > 0) {
                temp['children'] = childrenData
            }
            productParentChildrenData.push(temp)
        }
        return productParentChildrenData
    } else {
        return []
    }
}

export const getParentChildrenDataForProductParentTemplate = (activeProductTemplateDataList, parentCategoryId, add) => {
    // console.log("parentCategoryId--->", parentCategoryId);
    let localProductTemplateData = []
    if (activeProductTemplateDataList && activeProductTemplateDataList.length > 0) {
        for (var i = 0; i < activeProductTemplateDataList.length; i++) {
            const temp = {}
            temp['key'] = activeProductTemplateDataList[i].product_template_id
            temp['value'] = activeProductTemplateDataList[i].product_template_id
            if (parentCategoryId !== undefined && parentCategoryId.length > 0) {
                let index = parentCategoryId.findIndex(
                    (ele) => ele === activeProductTemplateDataList[i].product_template_id
                )
                if (index !== -1) {
                    if (add) {
                        temp['disabled'] = false
                        temp['halfChecked'] = false
                    } else {
                        temp['disabled'] = true
                        temp['halfChecked'] = false
                    }
                }
            }

            temp['revisionId'] = activeProductTemplateDataList[i].id
            temp['title'] = activeProductTemplateDataList[i].display_name
            temp['description'] = activeProductTemplateDataList[i].description
            temp['product_status'] = activeProductTemplateDataList[i].product_status
            temp['last_published_revision'] = activeProductTemplateDataList[i].last_published_revision
            temp['display_name'] = activeProductTemplateDataList[i].display_name
            temp['display_description'] = activeProductTemplateDataList[i].display_description
            temp['product_template_revision_details'] =
                activeProductTemplateDataList[i].product_template_revision_details
            let childrenData = getActiveProductTemplateRecursiveFunction(
                activeProductTemplateDataList[i].child_product_template,
                parentCategoryId
            )
            flatProductTemplateData.push(temp)
            if (childrenData.length > 0) {
                temp['children'] = childrenData
            }
            localProductTemplateData.push(temp)
        }
        return [
            {
                localProductTemplateData: localProductTemplateData,
                flatproductTemplateData: flatProductTemplateData,
            },
        ]
    } else {
        return [
            {
                localProductTemplateData: [],
                flatproductTemplateData: [],
            },
        ]
    }
}
//!getNextNumber
export const getNextNumber = (currentNumber, returnType) => {
    if (returnType.toUpperCase() === 'INTEGER' && currentNumber > 0) {
        return Math.trunc(currentNumber + 1)
    } else if (returnType.toUpperCase() === 'FLOAT' && currentNumber > 0) {
        if (!Number.isInteger(currentNumber)) {
            const str = String(currentNumber)
            const count = str.split('.')[1].length
            const decimalNumber = str.split('.')[1]
            const indexValue = decimalNumber.charAt(2)
            if (count > 2) {
                if (indexValue >= 5) {
                    const result = currentNumber + 0.2
                    const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                    return finalResult.replace(/\.0+$/, '') // Remove trailing zeros
                } else {
                    const result = currentNumber + 0.1
                    const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                    return finalResult.replace(/\.0+$/, '') // Remove trailing zeros
                }
            } else {
                const result = currentNumber + 0.1
                const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                return finalResult.replace(/\.0+$/, '') // Remove trailing zeros
            }
        } else {
            return (currentNumber + 0.1).toFixed(1).replace(/\.0+$/, '') // Remove trailing zeros
        }
    } else {
        return currentNumber.toFixed(1).replace(/\.0+$/, '') // Remove trailing zeros
    }
}

export const getNextNumber2 = (currentNumber, returnType) => {
    if (returnType.toUpperCase() === 'INTEGER' && currentNumber > 0) {
        return Math.trunc(currentNumber + 1)
    } else if (returnType.toUpperCase() === 'FLOAT' && currentNumber > 0) {
        if (!Number.isInteger(currentNumber)) {
            const str = String(currentNumber)
            const count = str.split('.')[1].length
            const decimalNumber = str.split('.')[1]
            const indexValue = decimalNumber.charAt(2)
            if (count > 2) {
                if (indexValue >= 5) {
                    const result = currentNumber + 0.2
                    const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                    return parseFloat(finalResult)
                } else {
                    const result = currentNumber + 0.1
                    const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                    return parseFloat(finalResult)
                }
            } else {
                const result = currentNumber + 0.1
                const finalResult = (Math.floor(result * Math.pow(10, 2)) / Math.pow(10, 2)).toFixed(2)
                return parseFloat(finalResult)
            }
        } else {
            return currentNumber + 0.1
        }
    } else {
        return currentNumber
    }
}

export const getNextRevisionNumber = (currentNumber) => {
    const currentFloatNumber = parseFloat(currentNumber)
    return parseFloat(currentFloatNumber + 0.1).toFixed(1)
}

//!The below function will return attribute grouped data which can be editable irrespective of the datatype
//! -> For the single data types it will return with value.
//! -> For the Interactive data types it will return with option.
export const getProductAttributePostValue = (attributeGroupData) => {
    var productAttributePostData = []
    var individualAttributeDataObject = {}
    var product_attribute_group_data = []
    var product_attribute_group_data_object = {}
    attributeGroupData &&
        attributeGroupData.length > 0 &&
        attributeGroupData.forEach((element, index) => {
            individualAttributeDataObject = {}
            individualAttributeDataObject.product_attribute_group_id = element.attribute_group_id
            product_attribute_group_data = []
            element.attribute &&
                element.attribute.length > 0 &&
                element.attribute.forEach((element1) => {
                    product_attribute_group_data_object = {}
                    if (!element1.is_static && element.attribute_group_id === element1.product_attribute_group_id) {
                        product_attribute_group_data_object.data_type = element1.datatype_id
                        product_attribute_group_data_object.attribute_name = element1.name
                        // product_attribute_group_data_object.isValid = true;
                        product_attribute_group_data_object.is_mandatory = element1.mandatory
                        product_attribute_group_data_object.is_edited_now = false
                        product_attribute_group_data_object.is_modified_and_valid = false
                        var filteredAttributeData = attribDataTypeResponse.find(
                            (element) => parseInt(element.id) === parseInt(element1.datatype_id)
                        )
                        if (filteredAttributeData) {
                            if (
                                parseInt(element1.datatype_id) === 11 ||
                                parseInt(element1.datatype_id) === 12 ||
                                parseInt(element1.datatype_id) === 13 ||
                                parseInt(element1.datatype_id) === 20 ||
                                parseInt(element1.datatype_id) === 21 ||
                                parseInt(element1.datatype_id) === 22 ||
                                parseInt(element1.datatype_id) === 23 ||
                                parseInt(element1.datatype_id) === 24 ||
                                parseInt(element1.datatype_id) === 25 ||
                                parseInt(element1.datatype_id) === 26 ||
                                parseInt(element1.datatype_id) === 27 ||
                                parseInt(element1.datatype_id) === 30
                            ) {
                                product_attribute_group_data_object.regex = filteredAttributeData.regex
                                product_attribute_group_data_object.regexMessage =
                                    filteredAttributeData.regex_exception_message
                            }
                        }
                        product_attribute_group_data_object.attribute_id = element1.attribute_id
                        if (element1.attribute_option && element1.attribute_option.length > 0) {
                            if (parseInt(element1.datatype_id) === 16) {
                                product_attribute_group_data_object.value =
                                    element1.attribute_values &&
                                    element1.attribute_values.length > 0 &&
                                    element1.attribute_values[0] &&
                                    element1.attribute_values[0].length > 0 &&
                                    element1.attribute_values[0][0]
                                        ? element1.attribute_values &&
                                          element1.attribute_values.length > 0 &&
                                          element1.attribute_values[0] &&
                                          element1.attribute_values[0].length > 0 &&
                                          element1.attribute_values[0][0] &&
                                          element1.attribute_values[0][0].display_value
                                        : null
                            } else if (parseInt(element1.datatype_id) === 4) {
                                var sortedOptions = sortObjectArrayByKey(
                                    'id',
                                    'number',
                                    element1.attribute_option,
                                    'asc'
                                )
                                if (sortedOptions && sortedOptions.length > 0) {
                                    if (
                                        element1.attribute_values &&
                                        element1.attribute_values.length > 0 &&
                                        element1.attribute_values[0] &&
                                        element1.attribute_values[0].length > 0 &&
                                        element1.attribute_values[0][0].option &&
                                        element1.attribute_values[0][0].option.length > 0
                                    ) {
                                        product_attribute_group_data_object.is_edited_now = false
                                    } else {
                                        product_attribute_group_data_object.is_edited_now = true
                                    }
                                    var valueFromServerOption =
                                        element1.attribute_values &&
                                        element1.attribute_values.length > 0 &&
                                        element1.attribute_values[0] &&
                                        element1.attribute_values[0].length > 0 &&
                                        element1.attribute_values[0][0] &&
                                        element1.attribute_values[0][0].option &&
                                        element1.attribute_values[0][0].option.length > 0
                                            ? element1.attribute_values &&
                                              element1.attribute_values.length > 0 &&
                                              element1.attribute_values[0] &&
                                              element1.attribute_values[0].length > 0 &&
                                              element1.attribute_values[0][0] &&
                                              element1.attribute_values[0][0].option
                                            : []
                                    product_attribute_group_data_object.option =
                                        valueFromServerOption && valueFromServerOption.length > 0
                                            ? valueFromServerOption
                                            : [sortedOptions[1].id]
                                }
                            } else {
                                product_attribute_group_data_object.option =
                                    element1.attribute_values &&
                                    element1.attribute_values.length > 0 &&
                                    element1.attribute_values[0] &&
                                    element1.attribute_values[0].length > 0 &&
                                    element1.attribute_values[0][0]
                                        ? element1.attribute_values &&
                                          element1.attribute_values.length > 0 &&
                                          element1.attribute_values[0] &&
                                          element1.attribute_values[0].length > 0 &&
                                          element1.attribute_values[0][0] &&
                                          element1.attribute_values[0][0].option
                                        : element1.attribute_values
                            }
                        } else {
                            product_attribute_group_data_object.value =
                                element1.attribute_values &&
                                element1.attribute_values.length > 0 &&
                                element1.attribute_values[0] &&
                                element1.attribute_values[0].length > 0 &&
                                element1.attribute_values[0][0]
                                    ? element1.attribute_values &&
                                      element1.attribute_values.length > 0 &&
                                      element1.attribute_values[0] &&
                                      element1.attribute_values[0].length > 0 &&
                                      element1.attribute_values[0][0] &&
                                      element1.attribute_values[0][0].display_value
                                    : null
                        }
                        product_attribute_group_data.push(product_attribute_group_data_object)
                        if (
                            parseInt(individualAttributeDataObject.product_attribute_group_id) ===
                            parseInt(element1.product_attribute_group_id)
                        ) {
                            individualAttributeDataObject.product_attribute_group_data = product_attribute_group_data
                        }
                    }
                })
            if (product_attribute_group_data && product_attribute_group_data.length > 0) {
                productAttributePostData.push(individualAttributeDataObject)
            }
        })
    return productAttributePostData
}

export const getProductAttributesValuesFromServer = (attributeGroupData) => {
    var product_attribute_group_data = []
    var product_attribute_group_data_object = {}
    attributeGroupData &&
        attributeGroupData.length > 0 &&
        attributeGroupData.forEach((element, index) => {
            element.attribute &&
                element.attribute.length > 0 &&
                element.attribute.forEach((element1) => {
                    product_attribute_group_data_object = {}
                    if (!element1.is_static && element.attribute_group_id === element1.product_attribute_group_id) {
                        product_attribute_group_data_object.attribute_id = element1.attribute_id
                        if (element1.attribute_option && element1.attribute_option.length > 0) {
                            if (parseInt(element1.datatype_id) === 16) {
                                product_attribute_group_data_object.value =
                                    element1.attribute_values &&
                                    element1.attribute_values.length > 0 &&
                                    element1.attribute_values[0] &&
                                    element1.attribute_values[0].length > 0 &&
                                    element1.attribute_values[0][0]
                                        ? element1.attribute_values &&
                                          element1.attribute_values.length > 0 &&
                                          element1.attribute_values[0] &&
                                          element1.attribute_values[0].length > 0 &&
                                          element1.attribute_values[0][0] &&
                                          element1.attribute_values[0][0].display_value
                                        : null
                            } else if (parseInt(element1.datatype_id) === 4) {
                                var sortedOptions = sortObjectArrayByKey(
                                    'id',
                                    'number',
                                    element1.attribute_option,
                                    'asc'
                                )
                                if (sortedOptions && sortedOptions.length > 0) {
                                    var valueFromServerOption =
                                        element1.attribute_values &&
                                        element1.attribute_values.length > 0 &&
                                        element1.attribute_values[0] &&
                                        element1.attribute_values[0].length > 0 &&
                                        element1.attribute_values[0][0].option &&
                                        element1.attribute_values[0][0].option.length > 0
                                            ? element1.attribute_values &&
                                              element1.attribute_values.length > 0 &&
                                              element1.attribute_values[0] &&
                                              element1.attribute_values[0].length > 0 &&
                                              element1.attribute_values[0][0] &&
                                              element1.attribute_values[0][0].option
                                            : []
                                    product_attribute_group_data_object.option =
                                        valueFromServerOption && valueFromServerOption.length > 0
                                            ? valueFromServerOption
                                            : []
                                }
                            } else {
                                product_attribute_group_data_object.option =
                                    element1.attribute_values &&
                                    element1.attribute_values.length > 0 &&
                                    element1.attribute_values[0] &&
                                    element1.attribute_values[0].length > 0 &&
                                    element1.attribute_values[0][0]
                                        ? element1.attribute_values &&
                                          element1.attribute_values.length > 0 &&
                                          element1.attribute_values[0] &&
                                          element1.attribute_values[0].length > 0 &&
                                          element1.attribute_values[0][0] &&
                                          element1.attribute_values[0][0].option
                                        : element1.attribute_values
                            }
                        } else {
                            product_attribute_group_data_object.value =
                                element1.attribute_values &&
                                element1.attribute_values.length > 0 &&
                                element1.attribute_values[0] &&
                                element1.attribute_values[0].length > 0 &&
                                element1.attribute_values[0][0]
                                    ? element1.attribute_values &&
                                      element1.attribute_values.length > 0 &&
                                      element1.attribute_values[0] &&
                                      element1.attribute_values[0].length > 0 &&
                                      element1.attribute_values[0][0] &&
                                      element1.attribute_values[0][0].display_value
                                    : null
                        }
                        product_attribute_group_data.push(product_attribute_group_data_object)
                    }
                })
            console.log('product attribute group data', product_attribute_group_data)
        })
    return product_attribute_group_data
}

//!The below function will return attribute based on the parameter
//? -> this will return the attributes which are not is_variant_attribute
//! -> if the parameter is variant_specific then it will return the attributes which are variant_specific.
//! -> If the parameter is non_variant_specific then it will return the attributes which are not variant_specific.
export const getFilteredProductAttributeGroupData = (attributeGroupData, type) => {
    let attributeGroupDataCopy = deepCopy(attributeGroupData)
    let tempArray = []
    for (var i = 0; i < attributeGroupDataCopy.length; i++) {
        tempArray = []
        for (var j = 0; j < attributeGroupDataCopy[i].attribute.length; j++) {
            if (type === 'variant_specific') {
                if (attributeGroupDataCopy[i].attribute[j].varient_specific) {
                    tempArray.push(attributeGroupDataCopy[i].attribute[j])
                }
            } else if (type === 'non_variant_specific') {
                if (
                    !attributeGroupDataCopy[i].attribute[j].is_variant_attribute &&
                    !attributeGroupDataCopy[i].attribute[j].varient_specific
                ) {
                    tempArray.push(attributeGroupDataCopy[i].attribute[j])
                }
            } else if (type === 'both') {
                if (!attributeGroupDataCopy[i].attribute[j].is_variant_attribute) {
                    tempArray.push(attributeGroupDataCopy[i].attribute[j])
                }
            }
        }
        attributeGroupDataCopy[i].attribute = tempArray
    }
    return attributeGroupDataCopy
}

//!The below function will return the EditableAndMandatory attributes
export const getEditableMandatoryData = (attributeGroupData) => {
    let copiedFilteredAttributeGroupData = deepCopy(attributeGroupData)
    let mandatoryAttributeIds = []
    copiedFilteredAttributeGroupData &&
        copiedFilteredAttributeGroupData.forEach((element) => {
            element.product_attribute_group_data &&
                element.product_attribute_group_data.length > 0 &&
                element.product_attribute_group_data.forEach((element1) => {
                    if (element1.is_mandatory === true) {
                        mandatoryAttributeIds.push(element1.attribute_id)
                    }
                })
        })
    return mandatoryAttributeIds
}

//! this function accepts two parameters
//? first one which accepts string data that contains the value
//? second function which accepts data array for which will check extension available or not
export const isSupportedMedia = (value, data) => {
    if (value !== '') {
        let extension = value.substr(value.lastIndexOf('.') + 1)
        if (data && data.length > 0) {
            let existsData = data.includes(extension)
            return existsData
        } else {
            return false
        }
    } else {
        return false
    }
}
// to remove the url search params after login
export const removeUrlSearchData = () => {
    const url = new URL(window.location.href)
    url.search = ''
    const newUrl = url.toString()
    window.history.replaceState({}, document.title, newUrl)
}

//? this util function accepts date what we are receiving from server as first param and second param is formatter
//? formatter means which format date should appear
//?Example for Format :
//?'D M YYY'--->01 Mar 2023
//?'MMM D YYYY'---> Mar 01 2023
//?'MMMM D YYY'----->March 01 2023
//?'MMMM Do YYYY, h:mm:ss a'----->MMMM Do YYYY, h:mm:ss a'
//?-----

export const getGenerateDateAndTime = (data, format) => {
    let tempDate = ''
    if (data !== undefined) {
        if (format !== undefined && format !== null) {
            tempDate = moment(data).format(format)
        } else {
            tempDate = moment(data).format('D-M-YYYY')
        }
    }

    return tempDate
}

export const getDateFromCurrentFormatToNewFormat = (inputDate, inputDateFormat, expectedFormat) => {
    let expectedFormatDate = ''

    let partsOfDates = []
    if (inputDateFormat === 'DD-MM-YYYY') {
        partsOfDates = inputDate.split('-')
    } else if (inputDateFormat === 'DD/MM/YYYY') {
        partsOfDates = inputDate.split('/')
    }

    if (partsOfDates.length > 0) {
        let output = new Date(+partsOfDates[2], partsOfDates[1] - 1, +partsOfDates[0])
        expectedFormatDate = moment(output).format(expectedFormat)
    } else {
        expectedFormatDate = moment(inputDate).format(expectedFormat)
    }

    return expectedFormatDate
}

export const getPrefixAndSuffix = (attributeUnitValues, displayName, type, maxLengthFromParams) => {
    let prefixSuffixMaxLength = process.env.REACT_APP_PREFIX_SUFFIX_MAX_SHOW_LENGTH
    const prefix =
        (attributeUnitValues &&
            attributeUnitValues.length > 0 &&
            attributeUnitValues?.find((unit) => unit.key === 'prefix_displayname')?.value) ||
        ''
    const suffix =
        (attributeUnitValues &&
            attributeUnitValues.length > 0 &&
            attributeUnitValues?.find((unit) => unit.key === 'suffix_displayname')?.value) ||
        ''

    const fullDisplayName = `${prefix ? String(prefix) + '-' : ''}${displayName}${suffix ? '-' + String(suffix) : ''}`
    const maxLength = maxLengthFromParams ? maxLengthFromParams : prefixSuffixMaxLength
    const displayText =
        fullDisplayName.length > maxLength ? `${fullDisplayName.slice(0, maxLength)}...` : fullDisplayName
    switch (type) {
        case 'full-name':
            return fullDisplayName
        case 'name':
            return displayText
        default:
            return fullDisplayName
    }
}
