import React, { useState, useEffect } from 'react'
import { Layout, Select, Button, Typography, Tabs, Skeleton, Tooltip } from 'antd'
import { InboxOutlined, SyncOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import util from '../../util/common'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { journeyPlusIcon, JourneyRefreshIcon } from '../../constants/media'

const { Content } = Layout
const { Option } = Select
const { Text } = Typography

const listJourneysAPI = process.env.REACT_APP_LIST_JOURNEYS_API
const canvasIframeURL = process.env.REACT_APP_CANVAS_IFRAME_URL
const storeJourneyAPI = process.env.REACT_APP_STORE_JOURNEY_MAPPING_API

const StoreJourney = () => {
    const { t } = useTranslation()

    const journeyEventsForStores = [
        {
            key: 'Order_Creation',

            label: t(`coupler:${util.getCouplerMultilingualKey('Order Creation')}`),

            value: 'Order_Creation',
            journeyType: '',
            level: 'notifications',
            packageName: 'order-creation-mail',
        },
        {
            key: 'Order_Invoice_Download',

            label: t(`coupler:${util.getCouplerMultilingualKey('Order Invoice Download')}`),

            value: 'Order_Invoice_Download',
            journeyType: '',
            level: 'notifications',
            packageName: 'download-invoice',
        },
    ]

    const [listJourneys, setListJourneys] = useState([])
    const [journeyEvents, setJourneyEvents] = useState([])
    const [selectedJourneyEvent, setSelectedJourneyEvent] = useState()
    const [selectedListJourney, setSelectedListJourney] = useState()
    const [iframeUrl, setIframeUrl] = useState()

    const [mappingId, setMappingId] = useState()
    const [mappedJourneysData, setMappedJourneysData] = useState([])
    const [disableSaveButton, setIsDisableSaveButton] = useState(true)
    const [listJourneyLoading, setListJourneyLoading] = useState(true)
    const [listJourneyNetworkError, setListJourneyNetworkError] = useState(false)
    const [showIframeModal, setShowIframeModal] = useState(false)

    const generateIframeUrl = (journeyId, journeyFlowName, journeyPackageName) => {
        let canvasURL = canvasIframeURL
        canvasURL = canvasURL.replace('{journeyID}', journeyId)
        canvasURL = canvasURL.replace('{journeyFlowName}', journeyFlowName)
        canvasURL = canvasURL.replace('{journeyPackageName}', journeyPackageName)
        canvasURL = canvasURL.replace('{realmName}', util.getClient('lower'))
        setIframeUrl(canvasURL)
    }

    const handleChangeListJourneys = (value, obj) => {
        setSelectedListJourney(value)

        generateIframeUrl(obj.journeyId, obj.journeyFlowName, obj.journeyPackageName)
        if (mappedJourneysData && mappedJourneysData.length > 0) {
            let filterSelectedEventsInMappedJourneysData = mappedJourneysData.filter(
                (ele) => ele.journey_for === selectedJourneyEvent
            )

            if (filterSelectedEventsInMappedJourneysData && filterSelectedEventsInMappedJourneysData.length > 0) {
                let temp = listJourneys.filter(
                    (ele) => ele.path === filterSelectedEventsInMappedJourneysData[0].journey_url
                )
                console.log('temp--->', temp)
                if (temp && temp.length > 0) {
                    if (temp[0].path == value) {
                        setIsDisableSaveButton(true)
                    } else {
                        setIsDisableSaveButton(false)
                    }
                } else {
                    setIsDisableSaveButton(false)
                }
            } else {
                setIsDisableSaveButton(false)
            }
        } else {
            setIsDisableSaveButton(false)
        }
    }

    const getAllJourneys = (level, packageName, journeyType) => {
        let requestBody = {}
        if (level != '') {
            requestBody['level'] = level
        }
        if (packageName != '') {
            requestBody['packageName'] = packageName
        }
        if (journeyType != '') {
            requestBody['journeyType'] = journeyType
        }

        MarketplaceServices.findAllWithoutPage(listJourneysAPI, requestBody, false)
            .then((response) => {
                console.log(
                    'Server Response from getOrderJourneys API ',
                    listJourneysAPI,
                    response?.data?.response_body?.Response
                )
                setListJourneys(response?.data?.response_body?.Response)
                setListJourneyLoading(false)
                setListJourneyNetworkError(false)
            })
            .catch((error) => {
                setListJourneyLoading(false)
                setListJourneyNetworkError(true)
                setListJourneys([])
            })
    }

    const alreadyMappedStoreJourney = () => {
        MarketplaceServices.findAll(storeJourneyAPI, null, false)
            .then(function (response) {
                if (
                    response.data.response_body.data.store_journey_mapping_data &&
                    response.data.response_body.data.store_journey_mapping_data.length > 0
                ) {
                    setMappedJourneysData(response.data.response_body.data.store_journey_mapping_data)
                } else {
                    setMappedJourneysData([])
                }
            })
            .catch((error) => {
                setMappedJourneysData([])
                console.log('Server Error Response alreadyMappedStoreProductTypesJourney', error)
            })
    }

    const validationJourneys = () => {
        if (mappingId === undefined) {
            postCallStoreJourneyMapping()
        } else {
            updateCallStoreJourneyMapping()
        }
    }

    const postCallStoreJourneyMapping = () => {
        let requestBody = {}
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.save(storeJourneyAPI, requestBody, null)
            .then(function (response) {
                console.log('postCallStoreJourneyAPI', storeJourneyAPI, response.data.response_body)
                MarketplaceToaster.showToast(response)

                alreadyMappedStoreJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log('Error Server response from saveJourneyMappingOnServer api ', error)
                MarketplaceToaster.toast(error.response)

                setIsDisableSaveButton(true)
            })
    }

    const updateCallStoreJourneyMapping = () => {
        let requestBody = {}
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.update(storeJourneyAPI, requestBody, {
            _id: mappingId,
        })
            .then(function (response) {
                console.log('Server response from updateCallStoreJourneyAPI', storeJourneyAPI, response.data)
                MarketplaceToaster.showToast(response)

                alreadyMappedStoreJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                console.log('ERROR Server response from updateCallProductTypeJourneyMapping', storeJourneyAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const handleRefreshJourney = () => {
        setIsDisableSaveButton(true)
        MarketplaceToaster.showToast(util.getToastObject(`${t('product_types:refresh_journey')}`, 'success'))
        setListJourneyLoading(true)
        let filterParticularEvents = journeyEvents?.filter((ele) => ele.value == selectedJourneyEvent)
        if (filterParticularEvents?.length > 0) {
            getAllJourneys(
                filterParticularEvents[0].level,
                filterParticularEvents[0].packageName,
                filterParticularEvents[0].journeyType
            )
        } else {
            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }

    const onTabChange = (value) => {
        setIsDisableSaveButton(true)
        setSelectedJourneyEvent(value)
        setListJourneyLoading(true)
        let filterParticularEvents = journeyEvents?.filter((ele) => ele.value == value)
        if (filterParticularEvents?.length > 0) {
            getAllJourneys(
                filterParticularEvents[0].level,
                filterParticularEvents[0].packageName,
                filterParticularEvents[0].journeyType
            )
        } else {
            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }

    const handleDiscard = () => {
        setIsDisableSaveButton(true)
        if (mappedJourneysData?.length > 0 && listJourneys?.length > 0 && selectedJourneyEvent) {
            let filterAlreadyMappedEvents = mappedJourneysData.filter((ele) => ele.journey_for == selectedJourneyEvent)

            if (filterAlreadyMappedEvents?.length > 0) {
                if (filterAlreadyMappedEvents && filterAlreadyMappedEvents.length > 0) {
                    let filterAlreadyMappedJourney = listJourneys.filter(
                        (ele) => ele.path == filterAlreadyMappedEvents[0].journey_url
                    )

                    if (filterAlreadyMappedJourney && filterAlreadyMappedJourney.length > 0) {
                        setMappingId(filterAlreadyMappedEvents[0].id)
                        setSelectedJourneyEvent(filterAlreadyMappedEvents[0].journey_for)
                        setSelectedListJourney(filterAlreadyMappedEvents[0].journey_url)
                        generateIframeUrl(
                            filterAlreadyMappedJourney[0].id,
                            filterAlreadyMappedJourney[0].journey_flow_name,
                            filterAlreadyMappedJourney[0].journey_package_name
                        )
                    }
                } else {
                    setIframeUrl()
                    setSelectedJourneyEvent()
                    setSelectedListJourney()
                }
            } else {
                setIframeUrl()
                setSelectedListJourney()
                setMappingId()
            }
        } else {
            setIframeUrl()
            setSelectedListJourney()
            setMappingId()
        }
    }

    useEffect(() => {
        alreadyMappedStoreJourney()
        setSelectedJourneyEvent()
        setSelectedListJourney()
        setIsDisableSaveButton(true)
        setJourneyEvents(journeyEventsForStores)
    }, [])

    useEffect(() => {
        if (journeyEvents?.length > 0) {
            setSelectedJourneyEvent(journeyEvents[0].value)

            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }, [journeyEvents])

    useEffect(() => {
        if (mappedJourneysData?.length > 0 && listJourneys?.length > 0 && selectedJourneyEvent) {
            let filterAlreadyMappedEvents = mappedJourneysData.filter((ele) => ele.journey_for == selectedJourneyEvent)

            if (filterAlreadyMappedEvents?.length > 0) {
                if (filterAlreadyMappedEvents && filterAlreadyMappedEvents.length > 0) {
                    let filterAlreadyMappedJourney = listJourneys.filter(
                        (ele) => ele.path == filterAlreadyMappedEvents[0].journey_url
                    )

                    if (filterAlreadyMappedJourney && filterAlreadyMappedJourney.length > 0) {
                        setMappingId(filterAlreadyMappedEvents[0].id)
                        setSelectedJourneyEvent(filterAlreadyMappedEvents[0].journey_for)
                        setSelectedListJourney(filterAlreadyMappedEvents[0].journey_url)
                        generateIframeUrl(
                            filterAlreadyMappedJourney[0].id,
                            filterAlreadyMappedJourney[0].journey_flow_name,
                            filterAlreadyMappedJourney[0].journey_package_name
                        )
                    }
                } else {
                    setIframeUrl()
                    setSelectedJourneyEvent()
                    setSelectedListJourney()
                }
            } else {
                setIframeUrl()
                setSelectedListJourney()
                setMappingId()
            }
        } else {
            setIframeUrl()
            setSelectedListJourney()
            setMappingId()
        }
    }, [mappedJourneysData, listJourneys, selectedJourneyEvent])

    return (
        <section className='bg-white py-4 !pr-4 !rounded-md'>
            <div className='flex flex-row justify-between border-b-[1px] !border-[#0000000F] pl-4 pb-4'>
                <p className='!text-regal-blue font-semibold text-lg'>
                    {t('product_types:product_type_journey_label')}
                </p>
            </div>
            <div className='pt-4 flex flex-row'>
                <Tabs
                    activeKey={selectedJourneyEvent ? selectedJourneyEvent : journeyEvents?.[0]?.value}
                    tabPosition={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'right' : 'left'}
                    items={journeyEvents}
                    onTabClick={onTabChange}
                    className='noVerticalLine'
                />
                {listJourneyLoading ? (
                    <Content className=' bg-white p-3'>
                        <Skeleton />
                    </Content>
                ) : listJourneyNetworkError ? (
                    <Content className='text-center p-3 !bg-white'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : (
                    <div className='w-full'>
                        <p className='text-sm font-medium text-[#8899A8] inline-block'>
                            {t('product_types:journey_label_name')}
                        </p>
                        <span className={'mandatory-symbol-color text-sm text-center ml-1'}>*</span>
                        <div className='flex flex-row  mt-2'>
                            <Select
                                placeholder={t('product_types:journey_not_associated')}
                                dropdownStyle={{ zIndex: 1 }}
                                value={selectedListJourney}
                                onChange={handleChangeListJourneys}
                                className='!w-[400px] mr-4'>
                                {listJourneys &&
                                    listJourneys.length > 0 &&
                                    listJourneys.map((item) => (
                                        <Option
                                            key={item.id}
                                            value={item.path}
                                            journeyId={item.id}
                                            journeyPackageName={item.journey_package_name}
                                            journeyFlowName={item.journey_flow_name}
                                            flowInfo={item.flow_info}
                                            journeyUrl={item.url}>
                                            {t(`coupler:${util.getCouplerMultilingualKey(item.journey_flow_name)}`)}
                                        </Option>
                                    ))}
                            </Select>

                            <Tooltip title={t('dashboard:refresh')}>
                                <div
                                    className='cursor-pointer w-8 h-8 border-[1px] border-[#D9D9D9] rounded-md flex justify-center items-center hover:border-[#fb8500]'
                                    onClick={handleRefreshJourney}>
                                    <img
                                        src={JourneyRefreshIcon}
                                        alt='journeyRefreshIcon'
                                        width='13.27px'
                                        height='12.26px'
                                    />
                                </div>
                            </Tooltip>
                        </div>

                        <div className='mt-5'>
                            {iframeUrl ? (
                                <Content className='h-[300px] overflow-auto relative'>
                                    <iframe
                                        id='canvasIframe'
                                        src={iframeUrl}
                                        title={t('product_types:journey_canvas')}
                                        width='100%'
                                        height='627'
                                        loading='eager'
                                    />
                                    <div className='  absolute top-2  right-2 '>
                                        <div
                                            className=' h-8 w-8 rounded-full border-[1px] border-[#8899A8] flex justify-center items-center bg-white cursor-pointer '
                                            onClick={() => setShowIframeModal(true)}>
                                            <img src={journeyPlusIcon} width={14} height={14} />
                                        </div>
                                    </div>
                                </Content>
                            ) : (
                                <Content className='bg-slate-100 p-10 text-center my-2'>
                                    <Content>
                                        <InboxOutlined className='my-2 text-2xl' />
                                    </Content>
                                    <h1>{t('product_types:journey_not_yet_mapped')}</h1>
                                </Content>
                            )}
                        </div>
                        {disableSaveButton == false ? (
                            <div className='pt-4'>
                                <Button
                                    disabled={disableSaveButton}
                                    className='app-btn-primary'
                                    onClick={() => validationJourneys()}>
                                    {t('common:update')}
                                </Button>
                                <Button
                                    disabled={disableSaveButton}
                                    className='app-btn-secondary ml-3'
                                    onClick={() => handleDiscard()}>
                                    {t('common:cancel')}
                                </Button>
                            </div>
                        ) : null}
                    </div>
                )}
                <StoreModal
                    isVisible={showIframeModal}
                    width={1200}
                    //okCallback={() => handleSaveImage()}
                    cancelCallback={() => setShowIframeModal(false)}
                    okButtonText={null}
                    cancelButtonText={null}
                    title={''}
                    isSpin={false}>
                    <iframe
                        id='canvasIframe'
                        src={iframeUrl}
                        title={t('product_types:journey_canvas')}
                        width='100%'
                        height='627'
                        loading='eager'
                        className='mt-9'
                    />
                </StoreModal>
            </div>
        </section>
    )
}

export default StoreJourney
