import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../../util/MarketplaceMutlitenancy'
const BASE_URL = process.env.REACT_APP_USER_CONSENT_BY_STORE
const useGetUserConsentByStore = ({ userType }) => {
    const getUserConsentByStore = async () => {
        const params = {
            'user-type': userType,
            'store-name': MarketplaceAppConfig.getStore('onlyClient'),
        }
        const res = await MarketplaceServices.findAll(BASE_URL, params)
        return res?.data?.response_body
    }
    return useQuery({
        queryKey: ['user', 'policy', userType],
        queryFn: getUserConsentByStore,
        refetchOnWindowFocus: false,
    })
}
export default useGetUserConsentByStore
