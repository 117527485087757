import { TranslationOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Input, Layout, Row, Skeleton, Typography, Collapse, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import DynamicJourneyComponent from '../../components/Journey/DynamicJourneyComponent'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnRevisionInfo, fnRevisionSelectedInfo } from '../../services/redux/actions/ActionsRevisionInfo'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import { sortObjectArrayByKey } from '../../util/util'
import ChooseLayout from '../SFSettings/ChooseLayout'
import TranslatorModal from '../product-category/TranslatorModal'
import AttributesDetail from './AttributesDetail'
import ProductPublishingHeader from './ProductPublishingHeader'
import './product.css'

import {
    Afternoon,
    AvailableIcon,
    BookedIcon,
    Evening,
    Morning,
    Night,
    PartiallyBookedIcon,
    SlotsMsgIcon,
} from '../../constants/media'
import util from '../../util/common'
const productTemplateRevisionAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_API
const getAllProductVariants = process.env.REACT_APP_PRODUCT_VARIANT_API
const variantDetailsByVersionNumberAPI = process.env.REACT_APP_VARIANT_ATTRIBUTES_BY_VERSION_NUMBER_API
const productJourneyMappingAPI = process.env.REACT_APP_PRODUCT_JOURNEY_MAPPING
const variantAttributesDetailsByProductIdAPI = process.env.REACT_APP_VARIANT_ATTRIBUTES_DETAILS_BY_PRODUCT_ID_API
const deliverySlotsAPI = process.env.REACT_APP_DELIVERY_SLOTS_API

//!Ant Design destructing
const { Title, Text } = Typography
const { Content } = Layout
const { Panel } = Collapse
const VariantAttributeDetails = () => {
    const search = useLocation().search
    let navigate = useNavigate()
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    /**
     * !Below are variables got from the URl Search Params.
     */
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    let version_status = new URLSearchParams(search).get('vrsts')
    let product_id = new URLSearchParams(search).get('vrid')
    let version_number = new URLSearchParams(search).get('vrno')
    let variant_id = new URLSearchParams(search).get('vaid')
    let mode = new URLSearchParams(search).get('m')

    let storePTRevisionId = new URLSearchParams(search).get('ptrid')
    let storePTId = new URLSearchParams(search).get('ptid')
    let approval_id_from_URL = new URLSearchParams(search).get('apprid')
    let totalVariantCountFromURl = new URLSearchParams(search).get('vact')
    let storeProductTypeName = new URLSearchParams(search).get('ptname')
    const [selectedVariant, setSelectedVariant] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetWorkError, setIsNetworkError] = useState(false)
    const [allProductVariants, setAllProductVariants] = useState()
    const [rowIndex, setRowIndex] = useState(1)
    const [isSaveClicked, setIsSaveClicked] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [currentVersionRelatedVariants, setCurrentVersionRelatedVariants] = useState([])
    const [currentVersionRelatedVariantIds, setCurrentVersionRelatedVariantIds] = useState([])
    const [listProductAttributeGroupProps, setListProductAttributeGroupProps] = useState()
    const [isSlotAttributeValuesPosted, setIsSlotAttributeValuesPosted] = useState(false)

    const [storeProductTypeId, setStoreProductTypeId] = useState()
    const [propsType, setPropsType] = useState()
    const [variantAttributesDetailsLoading, setVariantAttributesDetailsLoading] = useState(true)
    const [variantAttributesDetailsNetworkError, setVariantAttributesDetailsNetworkError] = useState(false)
    const [variantAttributesDetailsAPIData, setVariantAttributesDetailsAPIData] = useState([])
    const [variantAttributesWithOptionsData, setVariantAttributesWithOptionsData] = useState([])
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [selectedVariantDisplayNameData, setSelectedVariantDisplayNameData] = useState()

    const [dateForTimeSlots, setDateForTimeSlots] = useState()
    const [customDate, setCustomDate] = useState('')
    const [slotsAPIData, setSlotsAPIData] = useState([])
    const [slotsAPIDataLoading, setSlotsAPIDataLoading] = useState(false)
    const [isSlotPreviewModalOpen, setIsSlotPreviewModalOpen] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)
    const [loadingSkelton, setLoadingSkelton] = useState(false)

    const generateDateArray = (startDate, numDays) => {
        const dateArr = []
        for (let i = 0; i < numDays; i++) {
            const date = new Date(startDate)
            date.setDate(startDate.getDate() + i)
            dateArr.push(date)
        }
        return dateArr
    }
    const dateArray = generateDateArray(new Date(), 4)
    const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
 
    const dateArraySimplified = dateArray.map((dateString) => {
        const date = new Date(dateString)
        const dayName = daysOfWeek[date.getDay()]
        const formattedDate = date.toISOString().split('T')[0]
        const dayOfMonth = date.getDate()
        return {
            day: dayName,
            value: formattedDate,
            date: dayOfMonth,
        }
    })

    //!GET call to get the variant details by product ID
    const getVariantDetailsByVersionNumber = () => {
        MarketplaceServices.findAllWithoutPage(
            variantDetailsByVersionNumberAPI,
            {
                product_template_id: parseInt(productTemplateId),
                product_template_revision_id: parseInt(productTemplateRevisionId),
                version_number: version_number,
                store_product_type_revision_id: parseInt(storePTRevisionId),
            },
            true
        )
            .then(function (response) {
                console.log(
                    'server response from variants by product id ## :',
                    variantDetailsByVersionNumberAPI,
                    'response',
                    response.data.response_body
                )
                if (response.data && response.data.response_body.variants.length > 0) {
                    var currentVariants = []
                    var currentVariantIds = []
                    var individualObjectForVariant = {}
                    response.data.response_body.variants &&
                        response.data.response_body.variants.length > 0 &&
                        response.data.response_body.variants.forEach((element) => {
                            individualObjectForVariant = {}
                            individualObjectForVariant.product_id = element.product_id
                            individualObjectForVariant.variant_id = element.variant_id
                            individualObjectForVariant.product_status = element.product_status
                            currentVariantIds.push(element.variant_id)
                            currentVariants.push(individualObjectForVariant)
                        })
                    setCurrentVersionRelatedVariantIds(currentVariantIds)
                    setCurrentVersionRelatedVariants(currentVariants)
                } else {
                    setCurrentVersionRelatedVariants([])
                }
            })
            .catch(function (error) {
                console.log('Server Error response from Published product templates:', error.response)
            })
    }

    //!GET call to get the variant details by product ID
    const getVariantAttributeDetailsByProductId = () => {
        MarketplaceServices.findAllWithoutPage(
            variantAttributesDetailsByProductIdAPI,
            {
                'product-id': parseInt(product_id),
            },
            true
        )
            .then(function (response) {
                console.log(
                    'server response from variants attributes details by product id ## :',
                    variantAttributesDetailsByProductIdAPI,
                    'response',
                    response.data.response_body
                )
                setVariantAttributesDetailsAPIData(response.data.response_body)
                setSelectedVariantDisplayNameData(response.data.response_body?.variant_display_name)
                let APIData = response.data.response_body
                let variantAttributesDetailsArray = []
                let variantAttributesDetailsObject = {}
                APIData &&
                    APIData.attributes &&
                    APIData.attributes.length > 0 &&
                    APIData.attributes.forEach((element, index) => {
                        variantAttributesDetailsObject = {}
                        variantAttributesDetailsObject.index = index
                        variantAttributesDetailsObject.label = element.display_name
                        variantAttributesDetailsArray.push(variantAttributesDetailsObject)
                    })
                APIData &&
                    APIData.attribute_options &&
                    APIData.attribute_options.length > 0 &&
                    APIData.attribute_options.forEach((element, index) => {
                        let filteredObject =
                            variantAttributesDetailsArray &&
                            variantAttributesDetailsArray.length > 0 &&
                            variantAttributesDetailsArray.find((element1) => {
                                return parseInt(element1.index) === parseInt(index)
                            })
                        filteredObject.value = element.display_name
                    })
                setVariantAttributesWithOptionsData(variantAttributesDetailsArray)
                setVariantAttributesDetailsLoading(false)
                setVariantAttributesDetailsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Server Error response from Published product templates:', error.response)
                setVariantAttributesDetailsLoading(false)
                setVariantAttributesDetailsNetworkError(true)
            })
    }
    //! Get call for list of all Generated Product Variants
    const getAllGeneratedProductVariants = () => {
        MarketplaceServices.findAllWithoutPage(
            getAllProductVariants,
            {
                'product-revision-id': productTemplateRevisionId,
            },
            true
        )
            .then(function (response) {
                console.log(
                    'server response from all Generated Product Variants response',
                    response.data.response_body.data[0]
                )
                setAllProductVariants(response.data.response_body.data[0])
                setSelectedVariant(
                    response.data.response_body.data[0].find((element) => parseInt(element.id) === parseInt(variant_id))
                )
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Server Error response from all product templates:', error.response)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }
    //! GET call for Product revision
    const getProductRevision = () => {
        MarketplaceServices.findAllWithoutPage(
            productTemplateRevisionAPI,
            {
                'product-template': parseInt(productTemplateId),
            },
            true
        )
            .then(function (response) {
                let serverResponse = response.data.response_body.data
                serverResponse = sortObjectArrayByKey('revision_number', 'number', serverResponse, 'desc')
                let temp = serverResponse && serverResponse.filter((ele) => ele.product_revision_status === 3)
                dispatch(fnRevisionInfo(temp))

                let selectedRevisionId = temp.filter((ele) => ele.id === parseInt(productTemplateRevisionId))
                if (selectedRevisionId && selectedRevisionId.length > 0) {
                    dispatch(fnRevisionSelectedInfo(selectedRevisionId[0]))
                }
                dispatch(fnRevisionInfo(serverResponse))
            })

            .catch(function (error) {
                console.log('error from product revision====', error.response)
            })
    }
    const handleRowClick = (id, productIdParam, productStatus) => {
        setRowIndex(id)
        variant_id = id
        if (parseInt(productStatus) === 1) {
            navigate(
                `/dashboard/products/variant-attribute-details?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${productIdParam}&vrsts=${productStatus}&vaid=${id}&vrno=${version_number}&m=${mode}&a=${1}&ptrid=${storePTRevisionId}&ptid=${storePTId}&ptname=${storeProductTypeName}&apprid=${approval_id_from_URL}&vact=${totalVariantCountFromURl}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        } else if (parseInt(productStatus) > 1) {
            navigate(
                `/dashboard/products/variant-attribute-details?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${productIdParam}&vrsts=${productStatus}&vaid=${id}&vrno=${version_number}&m=${mode}&a=${0}&ptrid=${storePTRevisionId}&ptid=${storePTId}&ptname=${storeProductTypeName}&apprid=${approval_id_from_URL}&vact=${totalVariantCountFromURl}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        }
    }
    const handleProductMapping = () => {
        MarketplaceServices.findAll(productJourneyMappingAPI, null, true)
            .then(function (response) {
                console.log(
                    'Server response from handleProductMapping,',
                    productJourneyMappingAPI,
                    response.data.response_body
                )
                if (
                    response.data.response_body.data.product_journey_mapping_data &&
                    response.data.response_body.data.product_journey_mapping_data.length > 0
                ) {
                    let temp = response.data.response_body.data.product_journey_mapping_data
                    let localTemp = temp.filter((ele) => ele.product_id === parseInt(product_id))
                    if (localTemp && localTemp.length > 0) {
                        setPropsType('product')
                    } else {
                        setPropsType('product-type')
                    }
                } else {
                    setPropsType('product-type')
                }
            })
            .catch(function (error) {
                console.log('Error Server response from store product types', productJourneyMappingAPI, error.response)
            })
    }
    const handleVariantNameTranslatorModal = () => {
        setLoadingSkelton(true)
        setTranslateModalVisible(true)
        setOnChangeDisableFields(true)
    }
    const translateModalCloseHandler = () => {
        setTranslateModalVisible(false)
        setLoadingSkelton(false)
    }
    useEffect(() => {
        getAllGeneratedProductVariants()
    }, [variant_id, version_status])
    useEffect(() => {
        getVariantDetailsByVersionNumber()
        getVariantAttributeDetailsByProductId()
    }, [product_id])
    useEffect(() => {
        getAllGeneratedProductVariants()
        getProductRevision()
        getVariantDetailsByVersionNumber()
        getVariantAttributeDetailsByProductId()
        setRowIndex(variant_id)
        if (parseInt(mode) === 1) {
            handleProductMapping()
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (isSlotPreviewModalOpen === true) {
            getCallForDeliverySlots(
                dateArraySimplified && dateArraySimplified[0].value,
                dateArraySimplified && dateArraySimplified[0].day
            )
            setDateForTimeSlots(dateArraySimplified && dateArraySimplified[0].value)
        }
    }, [isSlotPreviewModalOpen])

    //! Get call for Delivery Slots
    const getCallForDeliverySlots = (dateProps, dayProps) => {
        setSlotsAPIDataLoading(true)
        const pathParams = {
            'product-id': parseInt(product_id),
            date: String(dateProps),
        }
        MarketplaceServices.findAllWithoutPage(deliverySlotsAPI, pathParams, false)
            .then((response) => {
                console.log(
                    'Server response from getCallForDeliverySlots',
                    dateProps,
                    dayProps,
                    response.data.response_body.slot_data
                )
                if (response.data.response_body.slot_data && response.data.response_body.slot_data.length > 0) {
                    const filteredArrayByDay = response.data.response_body.slot_data
                    if (filteredArrayByDay && filteredArrayByDay.length > 0) {
                        setSlotsAPIData(filteredArrayByDay[0].slots)
                    } else {
                        setSlotsAPIData([])
                    }
                } else {
                    setSlotsAPIData([])
                }

                setSlotsAPIDataLoading(false)
            })
            .catch((error) => {
                console.log('Error response from getCallForDeliverySlots is', error.response)
                setSlotsAPIDataLoading(false)
            })
    }

    const slotDetails =
        listProductAttributeGroupProps &&
        listProductAttributeGroupProps.length > 0 &&
        listProductAttributeGroupProps.filter((ele) => ele.name === 'Slot Details')

    const slotDetailsName =
        slotDetails && slotDetails.length > 0 && slotDetails[0].attribute.filter((ele) => ele.name === 'Slot Name')

    const slotDetailsNameOne = slotDetailsName && slotDetailsName.length > 0 && slotDetailsName[0]?.attribute_values[0]

    const slotDetailsNameTwo = slotDetailsNameOne && slotDetailsNameOne.length > 0 && slotDetailsNameOne[0]?.value

    const slotDetailsDescription =
        slotDetails &&
        slotDetails.length > 0 &&
        slotDetails[0].attribute.filter((ele) => ele.name === 'Slot Description')

    const slotDetailsDescriptionOne =
        slotDetailsDescription && slotDetailsDescription.length > 0 && slotDetailsDescription[0]?.attribute_values[0]

    const slotDetailsDescriptionTwo =
        slotDetailsDescriptionOne && slotDetailsDescriptionOne.length > 0 && slotDetailsDescriptionOne[0]?.value

    const filterSlotsDetailsBasedOnDateTime = (data, dateTimeName) => {
        let temp = []
        if (data.length > 0) {
            let filterData = data.filter((ele) => ele.day_time_name === dateTimeName)
            if (filterData && filterData.length > 0) {
                temp = filterData
                return temp
            } else {
                return temp
            }
        } else {
            return temp
        }
    }

    const convertHoursIn12Format = (data) => {
        const [hours, minutes, seconds] = data.split(':')
        const time12 = new Date(0, 0, 0, hours, minutes, seconds)
        const formattedTime = time12.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })

        return formattedTime
    }
    return (
        <Content className=''>
            <ProductPublishingHeader
                type='variant'
                selectedVariant={selectedVariant}
                isSaveClicked={isSaveClicked}
                saveButtonDisabled={isButtonDisabled}
                setIsSaveClicked={setIsSaveClicked}
                selectedStoreProductTypeRevisionId={storePTRevisionId}
                setStoreProductTypeId={setStoreProductTypeId}
            />
            {isLoading ? (
                <Content className=' bg-white p-3'>
                    <Skeleton
                        active
                        paragraph={{
                            rows: 10,
                        }}
                    />
                </Content>
            ) : isNetWorkError ? (
                <Content className='text-center bg-white p-3'>
                    <Title level={5}>{t('common:network_error')}</Title>
                </Content>
            ) : (
                <Content className='!mt-[0.75rem] p-3'>
                    <Content className='flex gap-2  bg-white !rounded-lg p-3' style={{ boxShadow: '0 0 10px #D9D9D9' }}>
                        <Content className='w-[20%] border-r border-gray-200'>
                            <div className='bg-white'>
                                <>
                                    <Content>
                                        {allProductVariants &&
                                            allProductVariants.length > 0 &&
                                            allProductVariants.map((value) => {
                                                if (
                                                    currentVersionRelatedVariantIds &&
                                                    currentVersionRelatedVariantIds.length > 0 &&
                                                    currentVersionRelatedVariantIds.includes(value.id)
                                                ) {
                                                    return (
                                                        <Row
                                                            key={value.id}
                                                            className={`${
                                                                parseInt(rowIndex) === parseInt(value.id)
                                                                    ? `!flex hover:cursor-pointer ${
                                                                          util
                                                                              .getSelectedLanguageDirection()
                                                                              ?.toUpperCase() === 'RTL'
                                                                              ? '!border-l-2 !border-l-brandPrimaryColor'
                                                                              : '!border-r-2 !border-r-brandPrimaryColor'
                                                                      }`
                                                                    : ' border-slate-300 !flex hover:cursor-pointer'
                                                            }`}
                                                            onClick={() => {
                                                                handleRowClick(
                                                                    value.id,
                                                                    currentVersionRelatedVariants &&
                                                                        currentVersionRelatedVariants.length > 0 &&
                                                                        currentVersionRelatedVariants.find(
                                                                            (element) =>
                                                                                parseInt(element.variant_id) ===
                                                                                parseInt(value.id)
                                                                        )
                                                                        ? currentVersionRelatedVariants &&
                                                                              currentVersionRelatedVariants.length >
                                                                                  0 &&
                                                                              currentVersionRelatedVariants.find(
                                                                                  (element) =>
                                                                                      parseInt(element.variant_id) ===
                                                                                      parseInt(value.id)
                                                                              ).product_id
                                                                        : product_id,
                                                                    currentVersionRelatedVariants &&
                                                                        currentVersionRelatedVariants.length > 0 &&
                                                                        currentVersionRelatedVariants.find(
                                                                            (element) =>
                                                                                parseInt(element.variant_id) ===
                                                                                parseInt(value.id)
                                                                        )
                                                                        ? currentVersionRelatedVariants &&
                                                                              currentVersionRelatedVariants.length >
                                                                                  0 &&
                                                                              currentVersionRelatedVariants.find(
                                                                                  (element) =>
                                                                                      parseInt(element.variant_id) ===
                                                                                      parseInt(value.id)
                                                                              ).product_status
                                                                        : value.variant_status
                                                                )
                                                            }}>
                                                            <Col className='flex  space-y-1 py-2 !w-[85%]'>
                                                                {/* <p
                                                                    className={`p-2 ${
                                                                        parseInt(rowIndex) === parseInt(value.id)
                                                                            ? 'font-semibold hover:cursor-pointer variant-text !mb-0'
                                                                            : 'font-normal !mb-0 hover:cursor-pointer'
                                                                    }`}>
                                                                    {value.variant_name}
                                                                </p> */}
                                                                <Tooltip title={value?.variant_name} placement='right'>
                                                                    <Text
                                                                        className={`p-2 ${
                                                                            parseInt(rowIndex) === parseInt(value.id)
                                                                                ? 'font-semibold hover:cursor-pointer variant-text !mb-0'
                                                                                : 'font-normal !mb-0 hover:cursor-pointer'
                                                                        }`}
                                                                        ellipsis={true}
                                                                        style={{ maxWidth: 300 }}>
                                                                        {value?.variant_name}
                                                                    </Text>
                                                                </Tooltip>
                                                            </Col>
                                                        </Row>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                    </Content>
                                </>
                            </div>
                        </Content>
                        <Content className='w-[80%]'>
                            <Content
                                className={`bg-white rounded-lg custom-box-shadow ${
                                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-3' : 'ml-3'
                                } mb-3 !w-[98%]`}>
                                <Collapse accordion className='bg-white panel-alignment'>
                                    <Panel
                                        header={
                                            // <Title className='!m-0' level={4}>
                                            //     {t('products:options')}
                                            // </Title>
                                            <div className='flex flex-row justify-between pb-2'>
                                                <p className='font-semibold text-lg'>{t('products:options')}</p>
                                            </div>
                                        }>
                                        {variantAttributesDetailsLoading ? (
                                            <Skeleton />
                                        ) : variantAttributesDetailsNetworkError ? (
                                            <p>{t('common:network_error')}</p>
                                        ) : (
                                            <Content className='!w-[50%]'>
                                                {variantAttributesWithOptionsData &&
                                                    variantAttributesWithOptionsData.length > 0 &&
                                                    variantAttributesWithOptionsData.map((element) => {
                                                        return (
                                                            <div className='my-2'>
                                                                <p className='mb-0'>{element.label}</p>
                                                                <Input
                                                                    value={element.value}
                                                                    disabled={true}
                                                                    className='my-2'
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                <Content className='mt-3'>
                                                    <p className='mb-0'>{t('products:variant_display_name')}</p>
                                                    <Input
                                                        value={
                                                            // variantAttributesDetailsAPIData &&
                                                            // variantAttributesDetailsAPIData.variant_display_name
                                                            selectedVariantDisplayNameData
                                                        }
                                                        className='my-2'
                                                        disabled={true}
                                                        addonAfter={
                                                            storeLanguageFromReduxState &&
                                                            storeLanguageFromReduxState.length > 1 &&
                                                            parseInt(version_status) <= 3 ? (
                                                                <TranslationOutlined
                                                                    className='iconsHoverClass'
                                                                    onClick={() => handleVariantNameTranslatorModal()}
                                                                />
                                                            ) : null
                                                        }
                                                    />
                                                </Content>
                                            </Content>
                                        )}
                                    </Panel>
                                </Collapse>
                            </Content>
                            <AttributesDetail
                                selectedTabStoreProductTypeRevisionId={storePTRevisionId}
                                setListProductAttributeGroupProps={setListProductAttributeGroupProps}
                                setIsSlotAttributeValuesPosted={setIsSlotAttributeValuesPosted}
                                type='variant_specific'
                                showVariantTable={false}
                                setButtonDisabled={setIsButtonDisabled}
                                storeProductTypeId={storeProductTypeId}
                                setStoreProductTypeId={setStoreProductTypeId}
                                isSaveClicked={isSaveClicked}
                                setIsSaveClicked={setIsSaveClicked}
                                typeRendering='Product'
                                setIsSlotPreviewModalOpen={setIsSlotPreviewModalOpen}
                            />
                            {parseInt(mode) === 1 ? (
                                <Content
                                    className={`bg-white rounded-lg custom-box-shadow  ${
                                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-3' : 'ml-3'
                                    } mt-1 w-[98%]`}
                                    id='targetLayout'>
                                    <ChooseLayout
                                        type={'product'}
                                        id={parseInt(product_id)}
                                        filterType={'detailspage'}
                                        productTypeId={storeProductTypeId}
                                    />
                                </Content>
                            ) : null}
                            <Content id='targetJourney'>
                                {parseInt(mode) === 0 ? (
                                    <Content
                                        className={`${
                                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                ? 'mr-3'
                                                : 'ml-3'
                                        } !w-[98%]`}>
                                        <DynamicJourneyComponent
                                            type={'product'}
                                            id={parseInt(product_id)}
                                            productTypeId={parseInt(storeProductTypeId)}
                                            action={'read'}
                                        />
                                    </Content>
                                ) : (
                                    <>
                                        {propsType !== undefined ? (
                                            <Content
                                                className={`${
                                                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                        ? 'mr-3'
                                                        : 'ml-3'
                                                }  !mt-3 !w-[98%]`}>
                                                <DynamicJourneyComponent
                                                    type={'product'}
                                                    id={parseInt(product_id)}
                                                    productTypeId={parseInt(storeProductTypeId)}
                                                    action={'read'}
                                                />
                                            </Content>
                                        ) : null}
                                    </>
                                )}
                            </Content>
                        </Content>
                    </Content>
                </Content>
            )}
            <StoreModal
                title={'Translate'}
                isVisible={translateModalVisible}
                okButtonText={null}
                cancelButtonText={null}
                okCallback={null}
                hideCloseButton={false}
                cancelCallback={() => translateModalCloseHandler()}
                isSpin={false}
                width={1000}>
                <Content>
                    <TranslatorModal
                        dataJson={[
                            {
                                order: 1,
                                label: `${t('common:variant')}`,
                                type: 'textbox',
                                value: variantAttributesDetailsAPIData && variantAttributesDetailsAPIData.variant_name,
                            },
                            {
                                order: 2,
                                label: `${t('common:Description')}`,
                                type: 'textarea',
                                value: variantAttributesDetailsAPIData && variantAttributesDetailsAPIData.description,
                            },
                        ]}
                        componentType='variant_name'
                        respectiveId={variant_id}
                        setTranslateModalVisible={setTranslateModalVisible}
                        onChangeDisableFields={onChangeDisableFields}
                        setOnChangeDisableFields={setOnChangeDisableFields}
                        loadingSkelton={loadingSkelton}
                        setLoadingSkelton={setLoadingSkelton}
                        setSelectedVariantDisplayNameData={setSelectedVariantDisplayNameData}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={isSlotPreviewModalOpen}
                cancelCallback={() => setIsSlotPreviewModalOpen(false)}
                okButtonText={null}
                cancelButtonText={null}
                title={t('products:slot_preview')}
                isSpin={false}>
                <Content>
                    <Row>
                        <Text className='font-semibold mb-1'>{slotDetailsNameTwo}</Text>
                    </Row>
                    <Row>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: slotDetailsDescriptionTwo || '',
                            }}
                        />
                    </Row>
                    <Text className=''>{t('products:choose_day')}</Text>

                    <Row className='my-2 h-auto'>
                        {dateArraySimplified &&
                            dateArraySimplified.length > 0 &&
                            dateArraySimplified.map((element, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            getCallForDeliverySlots(element.value, element.day)
                                            setDateForTimeSlots(element.value)
                                        }}
                                        key={index}
                                        className={`border w-[15%] cursor-pointer  !border-black rounded-md m-2 p-2 ${
                                            dateForTimeSlots === element.value ? 'app-btn-primary' : ''
                                        }`}>
                                        <Row>
                                            <Text
                                                className={`${dateForTimeSlots === element.value ? 'text-white' : ''}`}>
                                                {' '}
                                                {t(`products:${element.day?.toLowerCase()}`)}
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Text
                                                className={`${dateForTimeSlots === element.value ? 'text-white' : ''}`}>
                                                {' '}
                                                {element.date}
                                            </Text>
                                        </Row>
                                    </div>
                                )
                            })}
                        <DatePicker
                            placeholder={`${t('products:select_date')}`}
                            placement='bottomRight'
                            format='YYYY-MM-DD'
                            onChange={(date, dateString) => {
                                getCallForDeliverySlots(dateString)
                                setDateForTimeSlots(dateString)
                                setCustomDate(dateString)
                            }}
                            className={`border w-[23%] cursor-pointer  !border-black rounded-md m-2 !mr-0 p-2  ${
                                dateForTimeSlots === customDate ? 'app-btn-primary !text-white' : ''
                            }`}
                        />
                    </Row>

                    <Content className='my-2'>
                        {slotsAPIDataLoading ? (
                            <SkeletonComponent />
                        ) : slotsAPIData && slotsAPIData.length > 0 ? (
                            <>
                                <Row className='mb-1'>
                                    <Text className='w-[100%]'>{t('products:available_time')}</Text>
                                </Row>
                                <Content className=''>
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Morning} alt='Morning' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'morning'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>

                                                            <div className='flex'>
                                                                <img
                                                                    className='mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[22%]'>
                                                <img src={Afternoon} alt='Afternoon' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[78%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'afternoon'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className='mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Evening} alt='Evening' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'evening'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className='mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night')[0].day_time_slots.length >
                                        0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Night} alt='Night' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'night'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className='mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                </Content>
                            </>
                        ) : (
                            <Content className='p-2 my-2 flex flex-col !items-center'>
                                <img src={SlotsMsgIcon} className='!w-[18%] my-3' alt='slotMsgIcon' />
                                <Text className='my-2 opacity-25'>{t('products:no_slots')}</Text>
                            </Content>
                        )}
                    </Content>
                </Content>
            </StoreModal>
        </Content>
    )
}
export default VariantAttributeDetails
