// As per suggestion, from sushil and logo, we are maintaining this structure.
// Because backend is not providing the attibute type name for displaying.
// Once it is provided, we will remove this file from repo.
// Note: User should change only the SettingName. if required.
// User should not change the type unless there is change in the backend key
import TranslateAttributeStrings from '../pages/product_new_options/TranslateAttributeStrings'
const attibuteTypes = [
    {
        type: 'Simple',
        settingsName: <TranslateAttributeStrings translate={'attributes:simple_input_field'} />,
        description: <TranslateAttributeStrings translate={'attributes:field_to_enter_value_for_attribute'} />,
    },
    {
        type: 'Calculative',
        settingsName: <TranslateAttributeStrings translate={'attributes:calculate_value'} />,
        description: <TranslateAttributeStrings translate={'attributes:calculative_description'} />,
    },
    {
        type: 'Journey',
        settingsName: <TranslateAttributeStrings translate={'attributes:journey'} />,
        description: <TranslateAttributeStrings translate={'attributes:journey_attribute_description'} />,
    },
]

export default attibuteTypes
