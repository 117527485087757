import { Layout, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

/**
 *! Components are imported Here.
 */

import DmPagination from '../../components/dmpagination/DmPagination'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import { getGenerateDateAndTime } from '../../util/util'
//! ant design initialization
const { Content } = Layout

//! Global Variables
const storeSettlementHistoryAPI = process.env.REACT_APP_STORE_SETTLEMENT_HISTORY_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE

const History = () => {
    const { t } = useTranslation()
    let { id } = useParams()
    const [settlementHistory, setSettlementHistory] = useState([])
    const [count, setCount] = useState()
    const [pageNumber, setPageNumber] = useState()
    const [pageLimit, setPageLimit] = useState()

    //!Table column for the sales order(Sales Details)
    const tableColumnForHistory = [
        {
            title: `${t('transactions:transaction_id')}`,
            dataIndex: 'key',
            key: 'key',
            render: (text, record) => {
                return <Content className=''>{record.key}</Content>
            },
        },
        {
            title: `${t('transactions:transaction_date')}`,
            dataIndex: 'transactionDate',
            key: 'transactionDate',
            render: (text, record) => {
                return <Content className=''>{record.transactionDate}</Content>
            },
        },
        {
            title: `${t('transactions:payment_type')}`,
            dataIndex: 'paymentType',
            key: 'paymentType',

            render: (text, record) => {
                return <Content className=''>{record.paymentType}</Content>
            },
        },
        {
            title: `${t('transactions:payment_cycle')}`,
            dataIndex: 'paymentCycle',
            key: 'paymentCycle',
            render: (text, record) => {
                return <Content className=''>{record.paymentCycle}</Content>
            },
        },
        {
            title: `${t('transactions:Amount')}`,
            dataIndex: 'transactionAmount',
            key: 'transactionAmount',
            render: (text, record) => {
                return <Content className=''>{record.transactionAmount}</Content>
            },
        },
        {
            title: `${t('transactions:status')}`,
            dataIndex: 'transactionStatus',
            key: 'transactionStatus',
            render: (text, record) => {
                return <Content className=''>{record.transactionStatus}</Content>
            },
        },
    ]

    const getHistoryProcessedData = (settlementHistoryData) => {
        const historyData = []
        settlementHistoryData.forEach((element) => {
            const historyObj = {}
            historyObj['key'] = element.id
            historyObj['transactionDate'] = getGenerateDateAndTime(element.transaction_date, 'D MMMM YYYY')
            historyObj['paymentType'] = element.payment_type
            historyObj['paymentCycle'] = element.payment_cycle
            historyObj['transactionAmount'] = element.transaction_amount
            historyObj['transactionStatus'] =
                element.transaction_status.length > 0 ? element.transaction_status : t('common:not_available')
            historyData.push(historyObj)
        })
        return historyData
    }

    const findAllSettlementHistory = (pageNum, pageLimit) => {
        MarketplaceServices.findByPage(
            storeSettlementHistoryAPI,
            {
                vendor_id: parseInt(id),
            },
            pageNum,
            pageLimit,
            true
        )
            .then(function (response) {
                console.log('response--->', response.data.response_body)
                setCount(response.data.response_body.count)
                setSettlementHistory(getHistoryProcessedData(response.data.response_body.data))
            })
            .catch(function (error) {
                console.log('error--->', error)
                setSettlementHistory([])
            })
    }

    const handlePageNumberChange = (page, pageSize) => {
        setPageNumber(page)
        setPageLimit(pageSize)
    }
    useEffect(() => {
        let pageNum = pageNumber === undefined ? 1 : pageNumber
        let pagelimit = pageLimit === undefined ? itemsPerPageFromEnv : pageLimit
        findAllSettlementHistory(pageNum, pagelimit)
    }, [pageNumber, pageLimit])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content className='!pt-1'>
            <Content className='mb-3'>
                <Table pagination={false} columns={tableColumnForHistory} dataSource={settlementHistory} />
                {count > itemsPerPageFromEnv ? (
                    <Content className=' grid justify-items-end'>
                        <DmPagination
                            currentPage={pageNumber === undefined ? 1 : pageNumber}
                            totalItemsCount={count}
                            defaultPageSize={itemsPerPageFromEnv}
                            pageSize={pageLimit === undefined ? itemsPerPageFromEnv : pageLimit}
                            handlePageNumberChange={handlePageNumberChange}
                            showSizeChanger={true}
                            showTotal={true}
                        />
                    </Content>
                ) : null}
            </Content>
        </Content>
    )
}

export default History
