import { Divider, Layout, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { CopyTextImage, CheckGreen } from '../../constants/media'
import util from '../../util/common'

const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL

const { Content } = Layout
const { Title } = Typography
function Summary({ mainObjectDataForAllActions, summaryData, headerCouponDisplay }) {
    const search = useLocation().search
    const { t } = useTranslation()
    let id = new URLSearchParams(search).get('ref')
    let discountType = new URLSearchParams(search).get('dt')
    let action = new URLSearchParams(search).get('a')
    let couponStatus = new URLSearchParams(search).get('status')
    const [langDirection, setLangDirection] = useState('ltr')
    const [copyMessage, setCopyMessage] = useState(false)

    //! currency symbol
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    console.log('summaryData##', summaryData)
    return (
        <Content className='!p-3'>
            <Title level={4} className='!text-regal-blue font-semibold text-[18px]'>
                {t('coupons:summary')}
            </Title>
            <Divider className='!mt-2' />
            {action === 'add' ? (
                <Content>
                    <Title level={5} className='!text-[#bfbfbf]'>
                        {t('coupons:no_discount_code_yet')}
                    </Title>
                    <Typography className='mt-1 !text-[#bfbfbf]'>{t('coupons:amount_off_products')}</Typography>
                    <Typography className='mt-4 font-semibold text-brandGray1 text-[14px]'>{t('product_types:details')}</Typography>
                    <ul style={{ listStyleType: 'disc' }} className='text-brandGray1 text-[14px] font-normal'>
                        <li className='mx-4'>
                            {' '}
                            <Typography className='mt-1 text-brandGray1 text-[14px] font-normal'>{t('coupons:amount_off_products')}</Typography>
                        </li>
                    </ul>
                </Content>
            ) : (
                <>
                    <Content className='space-y-2 '>
                        {summaryData && summaryData.length > 0 ? (
                            <>
                                <Content className='flex'>
                                    <Content className='flex space-x-1'>
                                        <Title level={4} className='!text-regal-blue font-semibold text-[16px]'>
                                            {headerCouponDisplay}
                                        </Title>
                                        {/* <Tooltip
                                            title={<h6 className='my-auto'>{t('coupons:code_copied')}</h6>}
                                            overlayStyle={{ position: 'fixed' }}
                                            placement='right'
                                            mouseLeaveDelay={0}
                                            open={copyMessage}>
                                            <img
                                                src={copyMessage ? CheckGreen : CopyTextImage}
                                                alt='copyText'
                                                width={12}
                                                height={12}
                                                className='cursor-pointer mx-1 mb-2'
                                                onClick={() => {
                                                    navigator.clipboard.writeText(headerCouponDisplay)
                                                    setCopyMessage(true)
                                                    setTimeout(() => setCopyMessage(false), 2000)
                                                }}
                                            />
                                        </Tooltip> */}
                                    </Content>
                                    {/* <div className={` ${langDirection === 'rtl' ? 'mr-12' : 'ml-12'} `}>
                                        {couponStatus === 'Active' ? (
                                            <Tag
                                                color={'green'}
                                                className='!mt-2 text-center !rounded-full !w-20 font-bold'>
                                                {t('coupons:active')}
                                            </Tag>
                                        ) : (
                                            <Tag
                                                color={'default'}
                                                className='!mt-2 text-center !rounded-full !w-20 text-gray-400 font-bold'>
                                                {t('coupons:in_active')}
                                            </Tag>
                                        )}
                                    </div> */}
                                </Content>
                                <Typography className='text-brandGray1 text-[14px] font-normal'>{t('coupons:amount_off_products')}</Typography>
                            </>
                        ) : null}
                    </Content>
                    <Content className='!mt-4'>
                        {' '}
                        <Typography className='mt-1 font-semibold text-brandGray1 text-[14px]'>{t('product_types:details')}</Typography>
                        <div className='px-4 mt-1 text-brandGray1 text-[14px] font-normal'>
                            {summaryData && summaryData.length > 0 && (
                                <ul style={{ listStyleType: 'disc' }}>
                                    {summaryData[0].category && summaryData[0].category?.length > 0 ? (
                                        <>
                                            <li>
                                                {`${
                                                    summaryData[0].couponDiscountType === 2
                                                        ? ` ${summaryData[0].percentage}% ${t('coupons:off')} `
                                                        : ` ${t('coupons:flat')} ${
                                                              summaryData[0].fixValue
                                                          }  ${t('coupons:off')}`
                                                }  ${summaryData[0].category?.length} ${t('categories:category')}`}
                                            </li>
                                            <li>{t('categories:category')}</li>
                                        </>
                                    ) : (
                                        <>
                                            <li>
                                                {`${
                                                    summaryData[0].couponDiscountType === 2
                                                        ? ` ${summaryData[0].percentage}% ${t('coupons:off')} `
                                                        : `  ${t('coupons:flat')} ${
                                                              summaryData[0].fixValue
                                                          }  ${t('coupons:off')}`
                                                }  ${summaryData[0].product?.length}  ${t('common:product')}`}
                                            </li>
                                            <li>{t('common:product')}</li>
                                        </>
                                    )}
                                    {summaryData[0].minimumAmount === 0 || summaryData[0].minimumAmount ? (
                                        <li>
                                            {`${t('coupons:minimum_purchase_of')} ${currencySymbolFromRedux} ${
                                                summaryData[0].minimumAmount
                                            }`}{' '}
                                        </li>
                                    ) : summaryData[0].minQuantity === 0 || summaryData[0].minQuantity ? (
                                        <li>{`${t('coupons:minimum_purchase_quantity')} ${summaryData[0].minQuantity}`}</li>
                                    ) : null}
                                    {summaryData[0].limitOfUsePerCustomer ? (
                                        <li>{`${summaryData[0].limitOfUsePerCustomer ? t('coupons:limit_of') + ' ' + summaryData[0].limitOfUsePerCustomer + ' ' + t('coupons:use') : ''}`}</li>
                                    ) : null}
                                    {summaryData[0].oncePerCustomer ? (
                                        <li>{`${summaryData[0].oncePerCustomer ? t('coupons:one_per_customer') : ''}`}</li>
                                    ) : null}
                                    {summaryData[0].startDate && summaryData[0].startDate !== null && (
                                        <li>{`${t('coupons:active_from')} ${summaryData[0].startDate} ${summaryData[0].startTime} ${
                                            summaryData[0].endDate && summaryData[0].endDate !== null
                                                ? `${t('coupons:through')} ${summaryData[0].endDate} ${summaryData[0].endTime}`
                                                : t('coupons:onwards')
                                        }`}</li>
                                    )}
                                </ul>
                            )}
                        </div>
                    </Content>
                </>
            )}
        </Content>
    )
}

export default Summary
