import { Button, Col, Input, Layout, Row, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import HeaderForTitle from '../../components/header/HeaderForTitle'

//! Import CSS libraries

const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)

const { Title } = Typography
const { Content } = Layout
const { TextArea } = Input

const CreateRoles = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const [pageAction, setPageAction] = useState()

    const roleFormValidation = () => {}

    useEffect(() => {
        var pathnameString = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length).split('-')
        setPageAction(pathnameString[0])

        window.scrollTo(0, 0)
    }, [])

    return (
        <Content className=''>
            <HeaderForTitle
                title={
                    <Tooltip title={pageAction !== 'add' ? 'Edit' : 'Add'} zIndex={11} placement='bottom'>
                        <Title level={3} className='!font-normal max-w-[800px]' ellipsis>
                            {pageAction === 'add' ? `${t('users_roles:add_role')} ` : 'Edit Roles'}
                        </Title>
                    </Tooltip>
                }
                type={'categories'}
                action={pageAction === 'add' ? 'add' : 'edit'}
                showArrowIcon={true}
                saveFunction={roleFormValidation}
                isVisible={pageAction === 'edit' ? false : true}
                showButtons={pageAction === 'edit' ? true : false}
            />
            <Content className='!min-h-screen mt-[4.5rem] p-3'>
                <Spin tip={t('common:please_wait')} size='large' spinning={false}>
                    <Content className='bg-white p-3'>
                        <Row>
                            <Col span={14} className=''>
                                <Content className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('users_roles:role_name')}
                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                    </Typography>
                                    <Content className='flex'>
                                        <Content className=''>
                                            <Input
                                                disabled={pageAction === 'add' ? false : true}
                                                maxLength={titleMaxLength}
                                                placeholder={t('users_roles:role_name_placeholder')}
                                            />
                                        </Content>
                                    </Content>
                                </Content>
                                <Content className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('common:description')}
                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                    </Typography>
                                    <Content className={`flex ${pageAction === 'edit' ? ' relative' : ''}`}>
                                        <TextArea
                                            disabled={pageAction === 'add' ? false : true}
                                            placeholder={t('users_roles:role_description_placeholder')}
                                            autoSize={true}
                                            rows={1}
                                        />
                                    </Content>
                                </Content>
                                {pageAction === 'add' ? (
                                    <Content className='my-2'>
                                        <Button
                                            onClick={roleFormValidation}
                                            className={`app-btn-primary ml-1
                       `}>
                                            {pageAction === 'edit' ? t('common:update') : t('common:save')}
                                        </Button>
                                    </Content>
                                ) : null}
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </Content>
        </Content>
    )
}

export default CreateRoles
