import { Button, Input, Layout, Row, Spin, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
//! Import CSS libraries
import { useTranslation } from 'react-i18next'
import validator from 'validator'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
const titleMinLength = parseInt(process.env.REACT_APP_TITLE_MIN_LENGTH)
const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)
//! Get all required details from .env file
const getAllAttributeGroupAPI = process.env.REACT_APP_SEGMENT_API

const descriptionMinLength = parseInt(process.env.REACT_APP_DESCRIPTION_MIN_LENGTH)
const descriptionMaxLength = parseInt(process.env.REACT_APP_DESCRIPTION_MAX_LENGTH)
const { Content } = Layout
const { TextArea } = Input

const SegmentForm = ({ setAttributeFormResponse, lngCode, closeSegmentForm }) => {
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [description, setDescription] = useState('')
    const [nameValidation, setNameValidation] = useState(false)
    const [descriptionValidation, setDescriptionValidation] = useState(false)
    const [langDirection, setLangDirection] = useState('ltr')

    //! doing validation for segment form
    const attributeGroupFormValidation = () => {
        let count = 2
        if (name.trim() === '' || description.trim() === '') {
            count--

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
            if (name.trim() === '') {
                setNameValidation(true)
            }
            if (description.trim() === '') {
                setDescriptionValidation(true)
            }
        } else if (!validator.matches(name, /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};'`~:"\\|,.<>\/?]+$/)) {
            count--
            setNameValidation(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:only_char_allowed')}`, 'error'))
        } else if (description.trim() !== '' && name.trim() === '') {
            count--

            MarketplaceToaster.showToast(
                util.getToastObject(
                    `${t(
                        'common:values_for_mandatory_fields'
                    )} with min as ${titleMinLength} and max as ${titleMaxLength}`,
                    'error'
                )
            )
            setNameValidation(true)

            if (
                validator.isLength(description.trim(), {
                    min: descriptionMinLength,
                    max: descriptionMaxLength,
                })
            ) {
                setDescriptionValidation(false)
            } else {
                setDescriptionValidation(true)
            }
        } else if (description.trim() === '' && name.trim() !== '') {
            count--

            MarketplaceToaster.showToast(
                util.getToastObject(
                    `${t(
                        'common:values_for_mandatory_fields'
                    )} with min as ${descriptionMinLength} and max as ${descriptionMaxLength}`,
                    'error'
                )
            )
            setDescriptionValidation(true)
            if (
                validator.isLength(name.trim(), {
                    min: titleMinLength,
                    max: titleMaxLength,
                })
            ) {
                setNameValidation(false)
            } else {
                setNameValidation(true)
            }
        } else if (description.trim() !== '' && name.trim() !== '') {
            if (
                !validator.isLength(name.trim(), {
                    min: titleMinLength,
                    max: titleMaxLength,
                }) &&
                validator.isLength(description.trim(), {
                    min: descriptionMinLength,
                    max: descriptionMaxLength,
                })
            ) {
                count--

                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${t(
                            'common:values_for_mandatory_fields'
                        )} with min as ${titleMinLength} and max as ${titleMaxLength}`,
                        'error'
                    )
                )
                setNameValidation(true)
            } else if (
                !validator.isLength(description.trim(), {
                    min: descriptionMinLength,
                    max: descriptionMaxLength,
                }) &&
                validator.isLength(name.trim(), {
                    min: titleMinLength,
                    max: titleMaxLength,
                })
            ) {
                count--

                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${t(
                            'common:values_for_mandatory_fields'
                        )} with min as ${descriptionMinLength} and max as ${descriptionMaxLength}`,
                        'error'
                    )
                )
                setDescriptionValidation(true)
            } else if (
                !validator.isLength(name.trim(), {
                    min: titleMinLength,
                    max: titleMaxLength,
                }) &&
                !validator.isLength(description.trim(), {
                    min: descriptionMinLength,
                    max: descriptionMaxLength,
                })
            ) {
                count--

                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${t(
                            'common:values_for_mandatory_fields'
                        )} and name length should be min as ${titleMinLength}, max as ${titleMaxLength} and description min as ${descriptionMinLength},max as ${descriptionMaxLength}}`,
                        'error'
                    )
                )
                setNameValidation(true)
                setDescriptionValidation(true)
            }
        }
        if (count === 2) {
            handleSubmitAttributeGroup()
        }
    }

    const discard = () => {
        setAttributeFormResponse(null)
        closeSegmentForm()
        setName('')
        setDescription('')
        setDescriptionValidation(false)
        setNameValidation(false)
    }

    const handleSubmitAttributeGroup = () => {
        setIsLoading(true)
        const serverObject = {
            product_attribute_groups: [
                {
                    name: name,
                    description: description,
                },
            ],
        }
        MarketplaceServices.save(getAllAttributeGroupAPI, serverObject)
            .then(function (response) {
                MarketplaceToaster.showToast(response)
                let groupResponse = response.data.response_body
                groupResponse[0].display_name = response.data.response_body[0].name
                setAttributeFormResponse(groupResponse)
                setAttributeFormResponse(response.data.response_body)
                setName('')
                setDescription('')
                setIsLoading(false)
            })

            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                setAttributeFormResponse(undefined)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <Content>
            <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                <Row className='py-2'>
                    <Typography className='input-label-color flex gap-1'>
                        {t('common:name')}
                        <span className='mandatory-symbol-color text-sm'>*</span>{' '}
                    </Typography>
                    <Input
                        className={`mt-1 ${nameValidation ? 'border-1 border-red-600 hover:border-red-400' : ''}`}
                        value={name}
                        minLength={titleMinLength}
                        maxLength={titleMaxLength}
                        placeholder={t('attribute_groups:enter_attribute_title')}
                        onBlur={(e) => {
                            setName(e.target.value.trim().replace(/\s+/g, ' '))
                        }}
                        onChange={(e) => {
                            setName(e.target.value)
                            setNameValidation(false)
                        }}
                    />
                </Row>
                <Row>
                    <Typography className='input-label-color flex gap-1'>
                        {t('common:description')}
                        <span className='mandatory-symbol-color text-sm '>*</span>{' '}
                    </Typography>
                    <TextArea
                        className={`!pr-[3px] mb-2 mt-1 ${
                            descriptionValidation ? 'border-1 border-red-600 hover:border-red-400' : ''
                        }`}
                        value={description}
                        minLength={descriptionMinLength}
                        maxLength={descriptionMaxLength}
                        autoSize={true}
                        showCount
                        rows={1}
                        placeholder={t('attribute_groups:enter_attribute_description')}
                        onBlur={(e) => {
                            setDescription(
                                e.target.value
                                    ?.split('\n')
                                    .map((line) => line.trim().replace(/\s+/g, ' '))
                                    .join('\n')
                            )
                        }}
                        onChange={(e) => {
                            setDescription(e.target.value)

                            setDescriptionValidation(false)
                        }}
                    />
                </Row>
                <Content className={`text-right mt-3 ${langDirection === 'rtl' ? '!text-left' : ''}`}>
                    <Button onClick={discard} className='app-btn-secondary mr-1'>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        onClick={attributeGroupFormValidation}
                        className={`app-btn-primary ml-1 ${langDirection === 'rtl' ? '!mr-2' : ''}`}>
                        {t('common:save')}
                    </Button>
                </Content>
            </Spin>
        </Content>
    )
}

export default SegmentForm
