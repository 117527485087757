import { Layout, Select, Typography } from 'antd'
import React, { useState } from 'react'
import PLPLayouts from '../../components/Layouts/Constants/PLPLayouts'
import DynamicComponent from '../../components/Layouts/DynamicComponent/DynamicComponent'

const { Content } = Layout
const { Title } = Typography

const PLPLayoutParent = () => {
    const [selectedLayoutName, setSelectedLayoutName] = useState('Choose the layout')
    const [selectedLayoutTitle, setSelectedLayoutTitle] = useState()

    const handleChangePLPLayouts = (value, obj) => {
        console.log('name--->', value)
        setSelectedLayoutName(value)
        setSelectedLayoutTitle(obj.title)
    }
    return (
        <Content className='!mb-5 mt-2'>
            <Title level={5} className='!font-normal !m-0'>
                Personalize the store's product list page by selecting a layout from the drop-down menu
            </Title>
            <Title level={5} className='!font-normal !m-0'>
                 The selected layout can be previewed before saving. Once saved, store's product list page will resemble
                the selected layout.
            </Title>
            <Select value={selectedLayoutName} onChange={handleChangePLPLayouts} className='w-[40%] mt-4'>
                {PLPLayouts &&
                    PLPLayouts.length > 0 &&
                    PLPLayouts.map((e) => (
                        <Select.Option value={e.componentName} title={e.componentTitle}>
                            {e.componentTitle}
                        </Select.Option>
                    ))}
            </Select>
            <Content>
                {selectedLayoutName === 'Choose the layout' ? (
                    <Title level={5} className='!font-normal !m-2'>
                        Please select the layout.
                    </Title>
                ) : (
                    <> {DynamicComponent(selectedLayoutName, selectedLayoutTitle, 345, 'bg-slate-100')}</>
                )}
            </Content>
        </Content>
    )
}

export default PLPLayoutParent
