import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT_VERSIONS

const useUpdateStoreUserConsentVersion = () => {

    const updateStoreUserConsentVersion = async ({ consentVersionId, body }) => {
        const params = { 'store-userconsent-version-id': consentVersionId }
        const res = await MarketplaceServices.update(BASE_URL, body, params)
        console.log(res, 'useUpdateStoreUserConsentVersion')
        return res.data
    }
    return useMutation({ mutationFn: updateStoreUserConsentVersion })
}
export default useUpdateStoreUserConsentVersion
