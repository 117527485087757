import { sortObjectArrayByKey } from '../../../util/util'

const PDPLayouts = [
    {
        componentName: 'PDPLayout1',
        componentTitle: 'Details Page Layout-1',
        isEnabled: true,
    },
    {
        componentName: 'PDPLayout2',
        componentTitle: 'Details Page Layout-2',
        isEnabled: true,
    },
    {
        componentName: 'PDPLayout1',
        componentTitle: 'Default Layout',
        isEnabled: false,
    },
    {
        componentName: 'PDPLayout2',
        componentTitle: 'Classic Layout',
        isEnabled: false,
    },
    {
        componentName: 'PDPLayout3',
        componentTitle: 'Modern Layout',
        isEnabled: false,
    },
    {
        componentName: 'PDPLayout4',
        componentTitle: 'Vintage Layout',
        isEnabled: false,
    },
    {
        componentName: 'PDPLayout5',
        componentTitle: 'Standard Layout',
        isEnabled: false,
    },
]

export default sortObjectArrayByKey(
    'componentTitle',
    'string',
    PDPLayouts.filter((element) => element.isEnabled),
    'asc'
)
