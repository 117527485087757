import { Layout } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { Content } = Layout

const TranscationsPayment = () => {
    const { t } = useTranslation()
    return <Content className='bg-white text-xl p-5'> {t('transactions:payout_history')}</Content>
}

export default TranscationsPayment
