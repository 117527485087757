import { ActionTypes } from '../constants/ActionTypes'

const { RDX_REVISION_INFO, RDX_REVISION_SELECTED_INFO } = ActionTypes

export const fnRevisionInfo = (data) => {
    return {
        type: RDX_REVISION_INFO,
        payload: data,
    }
}

export const fnRevisionSelectedInfo = (data) => {
    return {
        type: RDX_REVISION_SELECTED_INFO,
        payload: data,
    }
}
