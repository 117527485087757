import { Layout, Typography, Button } from 'antd'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import util from '../../util/common'
import './header2.css'

const { Content } = Layout
const { Title } = Typography

function HeaderForTitle({
    title,
    headerContent,
    titleContent,
    type,
    saveFunction,
    cloneFunction,
    showArrowIcon,
    backNavigationPath,
    action,
    previewFunction,
    isVisible,
    showButtons,
    disableSave,
    disableDiscard,
    discardNavigationPath,
}) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [langDirection, setLangDirection] = useState('ltr')

    const onMenuClick = (e) => {
        previewFunction()
    }
    const handleNavigationBack = () => {
        if (backNavigationPath !== undefined && backNavigationPath !== null && backNavigationPath !== '') {
            navigate(backNavigationPath)
        } else {
            navigate(-1)
        }
    }

    const handleDiscardNavigation = () => {
        if (discardNavigationPath !== undefined && discardNavigationPath !== null && discardNavigationPath !== '') {
            navigate(discardNavigationPath)
        } else {
            navigate(-1)
        }
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <Content className='shadow-md'>
            <Content className='fixed !h-auto top-[72px]  !z-10 bg-white flex justify-between headerWidth !px-5 pt-3 pb-1'>
                <Content className={`${showArrowIcon === true ? 'flex items-center' : ''}`}>
                    {showArrowIcon === true ? (
                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? (
                            <ArrowRightOutlined className='!text-xl ml-4 mb-2' onClick={handleNavigationBack} />
                        ) : (
                            <ArrowLeftOutlined className=' !text-xl mr-4 mb-2' onClick={handleNavigationBack} />
                        )
                    ) : null}

                    {title}
                    {titleContent}
                </Content>
                {showArrowIcon === true ? (
                    <>
                        {showButtons === false ? (
                            ''
                        ) : (
                            <Content className='flex align-items-center justify-end'>
                                {' '}
                                {disableDiscard === true ? null : (
                                    <Button className='mx-2 app-btn-secondary' onClick={handleDiscardNavigation}>
                                        {t('common:discard')}
                                    </Button>
                                )}
                                {isVisible !== false ? (
                                    <Button
                                        disabled={disableSave === true ? true : false}
                                        onClick={() => saveFunction()}
                                        className={`${
                                            disableSave === true
                                                ? 'app-btn-primary opacity-50 !h-[32px]'
                                                : 'app-btn-primary !h-[32px]'
                                        }`}>
                                        {action === 'add' ? t('common:save') : t('common:update')}
                                    </Button>
                                ) : null}
                            </Content>
                        )}
                    </>
                ) : null}
            </Content>

            {headerContent !== null && headerContent !== undefined ? (
                <Content className='mt-[5.6rem] bg-white !px-5 pb-3'>{headerContent}</Content>
            ) : null}
        </Content>
    )
}

export default HeaderForTitle
