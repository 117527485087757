import { ActionTypes } from '../constants/ActionTypes'

const { RDX_LAYOUT_INFO } = ActionTypes

export const fnLayoutInfo = (data) => {
    return {
        type: RDX_LAYOUT_INFO,
        payload: data,
    }
}
