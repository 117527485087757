import { ActionTypes } from '../constants/ActionTypes'
const { RDX_VENDOR_SETTLEMENT_INFO, RDX_SELECTED_VENDOR_SETTLEMENT_INFO } = ActionTypes
export const fnVendorSettlementInfo = (data) => {
    return {
        type: RDX_VENDOR_SETTLEMENT_INFO,
        payload: data,
    }
}
export const fnSelectedVendorSettlementInfo = (data) => {
    return {
        type: RDX_SELECTED_VENDOR_SETTLEMENT_INFO,
        payload: data,
    }
}
