import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Typography, Avatar, Input, Col, Row } from 'antd'

import { AdminIcon } from '../../constants/media'
import HeaderForTitle from './HeaderForTitle'

//! Destructure the components
const { Content } = Layout
const { Title, Paragraph } = Typography
const { Search } = Input

const DashboardContentHeader = () => {
    const { t } = useTranslation()

    return (
        <HeaderForTitle
            title={
                <Content className=''>
                    <Row>
                        <Col span={12}>
                            <Title level={3} className='!font-normal'>
                                {t('common:dashboard')}
                            </Title>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                </Content>
            }
            headerContent={
                <Paragraph className='!font-semibold !text-slate-400 !m-0'>
                    {t('common:dashboard_short_desc')}
                </Paragraph>
            }
            showArrowIcon={false}
        />
    )
}

export default DashboardContentHeader
