import { Col, Image, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TiDelete } from 'react-icons/ti'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

const deleteImageAPI = process.env.REACT_APP_IMAGE_PATH_API
const baseURL = process.env.REACT_APP_BASE_URL

const DisplayImage = ({ ImageData, deleteFromServer, setAllImages, size, actionType, fileType, closeButton }) => {
    const { t } = useTranslation()
    let imageWidth = size === 'large' ? 190 : size === 'small' ? 100 : 140

    const [isLoading, setIsLoading] = useState(false)

    const deleteImageFromServer = (value) => {
        MarketplaceServices.remove(deleteImageAPI, {
            image_path: String(value.image_path),
            image_type: String(value.image_type),
        })
            .then((res) => {
                console.log('Server response from deleteImageFromServer is', deleteImageAPI, res.data)
                if (res.status === 202) {
                    MarketplaceToaster.showToast(res)
                    setAllImages(ImageData.filter((element) => element.id !== value.id))
                } else {
                    MarketplaceToaster.showToast(util.getToastObject(`Image deletion failed`, 'warning'))
                }
            })
            .catch((error) => {
                console.log('Error Server response from deleteImageFromServer is', deleteImageAPI, error.response)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const handleDelete = (value) => {
        if (deleteFromServer) {
            deleteImageFromServer(value)
        } else {
            setAllImages(ImageData.filter((element) => element.id !== value.id))
        }
    }

    useEffect(() => {
        if (ImageData && ImageData.length > 0) {
            setIsLoading(false)
        }
    }, [ImageData])

    const handleDownloadFile = (url) => {
        const fileName = url.substring(url.lastIndexOf('/') + 1)
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const blobURL = window.URL.createObjectURL(blob)
                const tempLink = document.createElement('a')
                tempLink.href = blobURL
                tempLink.setAttribute('download', fileName)
                tempLink.click()
            })
            .catch((error) => {
                console.error('Error in downloading file:', error)
            })
    }

    return (
        ImageData &&
        ImageData.length > 0 &&
        ImageData.map((element) => {
            return (
                <Col className=' ' key={element.id}>
                    <div className='!relative'>
                        {fileType === 'image' ? (
                            <>
                                <Spin spinning={isLoading} tip={t('languages:please_wait')} className='!mt-4'>
                                    <>
                                        {closeButton !== false && (
                                            <TiDelete
                                                className='!absolute !cursor-pointer !right-[0px]  z-[1]  !top-[0px] !text-2xl !text-red-600 !shadow-lg  hover:translate-'
                                                onClick={() => handleDelete(element)}
                                            />
                                        )}
                                        <Image width={imageWidth} src={baseURL + element.image_full_path} />
                                    </>
                                </Spin>
                            </>
                        ) : (
                            <Spin spinning={isLoading} tip={t('languages:please_wait')}>
                                <p
                                    className='cursor-pointer underline !text-blue-400'
                                    onClick={() => {
                                        handleDownloadFile(baseURL + element.document_full_path)
                                    }}>
                                    {' '}
                                    {t('categories:download_file')}
                                </p>
                            </Spin>
                        )}
                    </div>
                </Col>
            )
        })
    )
}

export default DisplayImage
