import { Layout } from 'antd'
import React from 'react'
import { Outlet } from 'react-router-dom'
const { Content } = Layout

const Approvals = () => {
    return (
        <Content className='pt-1'>
            <Outlet />
        </Content>
    )
}

export default Approvals
