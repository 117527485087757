/**
 * The below function is to filter the selected products and moving them to the top of the array
 * This function takes the product id's to be placed at the top of the array
 * This function will not return anything, directly it will set the sorted array to the table for the respective product
 * This can be used when we saved, updated, find the products for a specific
 */
export const filterSelectedDataAtTop = (relativeProductsData, productsData, selectedProduct) => {
    /**
     * The below function is to sort the mapped product id's at the top and rest of them appending at the end
     */
    var tempArray = []
    const filteredDataWithRelatedProducts = productsData.filter((element) =>
        relativeProductsData.includes(element.product_id)
    )
    tempArray.push(...filteredDataWithRelatedProducts)
    let filteredDataWithoutRelatedProducts = productsData.filter(
        (element) => !relativeProductsData.includes(element.product_id)
    )
    tempArray.push(...filteredDataWithoutRelatedProducts)
    return tempArray.filter((element) => element.product_id !== selectedProduct)
}
