import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useDispatch, useSelector } from 'react-redux'

import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Dropdown, Image, Layout, Select, Skeleton, Tag, Tooltip, Typography } from 'antd'
import { Link, useNavigate } from 'react-router-dom'

import './header2.css'

import { BackIcon, Collapse, StoreLogo } from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnStoreLogoInfo, fnStoreLogoLoading } from '../../services/redux/actions/ActionContentSetting'
import { fnSelectedLanguage } from '../../services/redux/actions/ActionStoreLanguage'
import { fnUserProfileInfo } from '../../services/redux/actions/ActionUserProfile'

import util from '../../util/common'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'

const { Header, Content } = Layout
const { Text, Paragraph } = Typography
const { Option } = Select

const storeImageAPI = process.env.REACT_APP_STORE_IMAGE_API
const storeUsersAPI = process.env.REACT_APP_USERS_API
const baseURL = process.env.REACT_APP_BASE_URL
const multilingualFunctionalityEnabled = process.env.REACT_APP_IS_MULTILINGUAL_ENABLED
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const Header2 = ({ collapsed, setCollapsed }) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useAuth()
    const [userName, setUserName] = useState()
    const [userRole, setUserRole] = useState('')

    const storeLanguages = useSelector((state) => state.reducerStoreLanguage.storeLanguage)

    const userProfileInfo = useSelector((state) => state.reducerUserProfileInfo.userProfileInfo)

    const selectedLanguageRedux = useSelector((state) => state.reducerSelectedLanguage)
    const defaultLanguageRedux = useSelector((state) => state.reducerDefaultLanguage)
    const storeLogoPath = useSelector((state) => state.reducerStoreLogo.storeLogoInfo)

    const isStoreLogLoading = useSelector((state) => state.reducerStoreLogoLoading.storeLogoLoading)

    const [storeSelectedLngCode, setStoreSelectedLngCode] = useState(
        selectedLanguageRedux?.selectedLanguage?.language_code
    )

    const [defaultLanguageCode, setDefaultLanguageCode] = useState(
        selectedLanguageRedux?.selectedLanguage?.language_code
    )

    const [isStoreLogoLoading, setIsStoreLogoLoading] = useState(true)

    const languageItems = []
    if (storeLanguages && storeLanguages.length > 0) {
        storeLanguages.forEach((element) => {
            const languageItem = {}
            languageItem['value'] = element.language_code
            languageItem['label'] = element.language
            languageItems.push(languageItem)
        })
    }

    const findAllWithoutPageStoreUsers = () => {
        MarketplaceServices.findAllWithoutPage(storeUsersAPI, null, false)
            .then(function (response) {
                console.log('Server response from findAllWithoutPageStoreUsers is', storeUsersAPI, response.data)
                setUserName(response.data.response_body.username)
                setUserRole(response.data.response_body.groups[0]?.name)
                const userName = response.data.response_body.username
                dispatch(fnUserProfileInfo(userName))
            })
            .catch((error) => {
                console.log('Error Server response from findAllWithoutPageStoreUsers is', storeUsersAPI, error.response)
            })
    }

    useEffect(() => {
        if (userProfileInfo || userProfileInfo === null || userProfileInfo === undefined) {
            findAllWithoutPageStoreUsers()
        }
    }, [userProfileInfo])

    useEffect(() => {
        setStoreSelectedLngCode(selectedLanguageRedux?.selectedLanguage?.language_code)
        setDefaultLanguageCode(defaultLanguageRedux?.defaultLanguage?.language_code)
    }, [defaultLanguageRedux, selectedLanguageRedux])

    const userItems = [
        // {
        //     label: `${t('common:profile')}`,
        //     key: 'profile',
        //     icon: <UserOutlined />,
        //     // disabled: true,
        // },
        {
            label: `${t('common:logout')}`,
            key: 'logout',
            icon: <LogoutOutlined />,
            danger: true,
        },
    ]
    const handleMenuClick = (e) => {
        if (e.key === 'logout') {
            return void auth.signoutRedirect()
        }
        if (e.key === 'profile') {
            navigate('dashboard/userprofile?' + MarketplaceAppConfig.getStore(''))
        }
    }

    const handleLanguageClick = (value) => {
        util.setUserSelectedLngCode(value)
        util.setSelectedLngCode(value)
        setStoreSelectedLngCode(value)
        const selectedLng = storeLanguages.find((item) => item.language_code === value)
        dispatch(fnSelectedLanguage(selectedLng))
        document.body.style.direction = util.getSelectedLanguageDirection()?.toLowerCase()
        i18n.changeLanguage(selectedLng?.language_code)
        navigate(0)
    }

    //! store logo from api
    const getStoreLogo = () => {
        dispatch(fnStoreLogoLoading(true))
        MarketplaceServices.findAllWithoutPage(storeImageAPI, null, false)
            .then(function (response) {
                console.log('Server response from getStoreLogo is', storeImageAPI, response.data)
                dispatch(fnStoreLogoLoading(false))
                if (response?.data?.response_body) {
                    dispatch(fnStoreLogoInfo(response.data.response_body?.store_logo_path))
                }
                setIsStoreLogoLoading(false)

                // getAbsoluteStoreLogo(imagePath, storeId);
            })
            .catch(function (error) {
                setIsStoreLogoLoading(false)
                dispatch(fnStoreLogoLoading(false))
                console.log('Error sever Response from getStoreLogo', storeImageAPI, error.response)
            })
    }
    console.log('storeLogoLoading', storeLogoPath, isStoreLogoLoading)
    useEffect(() => {
        // if (storeLogoPath === undefined || storeLogoPath === null) {
        getStoreLogo()
        // }
    }, [])

    return (
        <Content>
            <Header className='fixed !z-20 top-0 p-0 !h-[72px] w-full header'>
                <Content className={`!h-[72px] border-b-[1px] flex flex-row !justify-between items-center px-3 `}>
                    {/* Left content which displays brand logo and other stuffs */}
                    {/* {!isStoreLogoLoading && ( */}
                    <Content className='flex flex-row items-center'>
                        <div
                            className={`${
                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'ml-4 mr-2' : 'ml-2 mr-4'
                            } `}>
                            <Button
                                type='text'
                                icon={
                                    collapsed ? (
                                        <img src={Collapse} alt='collapseIcon' />
                                    ) : (
                                        <img
                                            className={`  ${
                                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                    ? 'rotate-180'
                                                    : ''
                                            }`}
                                            src={BackIcon}
                                            alt='BackButton'
                                        />
                                    )
                                }
                                onClick={() => setCollapsed(!collapsed)}
                            />
                        </div>
                        <div className='flex items-center mx-2'>
                            {isStoreLogLoading ? (
                                <Skeleton
                                    paragraph={{ rows: 1 }}
                                    title={false}
                                    className='flex justify-center w-[120px]'
                                    active
                                />
                            ) : (
                                <Link to='/dashboard'>
                                    <Image
                                        height={40}
                                        preview={false}
                                        src={
                                            storeLogoPath && storeLogoPath.length > 0
                                                ? baseURL + storeLogoPath
                                                : StoreLogo
                                        }
                                        fallback={StoreLogo}
                                        className='cursor-pointer'
                                    />
                                </Link>
                            )}
                        </div>
                        <div className='mx-2 flex items-center'>
                            <Tag className='portalNameTag'>
                                <Text className='!px-2 text-white text-[12px] font-medium leading-5'>
                                    {portalInfo && portalInfo.title.toUpperCase()}
                                </Text>
                            </Tag>
                        </div>
                    </Content>
                    {/* )} */}
                    {/* Center content to display any item if required */}
                    <Content className='flex flex-1'></Content>
                    {/* Right content to display user menus, login icon, language icon and other stuffs */}
                    <Content className='!flex  !justify-end !items-center px-2'>
                        {/* Display user dropdown if user is logged in otherwise display login icon */}
                        {auth.isAuthenticated ? (
                            <div
                                className={`flex !self-end ${
                                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                        ? 'ml-[5px]'
                                        : 'mr-[5px]'
                                }`}>
                                <Avatar className='bg-gray-400 mx-1' size={48} icon={<UserOutlined />} />
                                <Text className={` !flex flex-col`}>
                                    <Text className='font-normal text-sm text-[#637381] leading-[22px] whitespace-nowrap'>
                                        {userName ? userName : userProfileInfo}{' '}
                                    </Text>
                                    <Dropdown
                                        menu={{
                                            items: userItems,
                                            onClick: handleMenuClick,
                                        }}
                                        placement='bottom'
                                        trigger={['click']}
                                        className='cursor-pointer'
                                        overlayStyle={{ position: 'fixed', overflow: 'visible', zIndex: 20, top: 64 }}>
                                        <Text className='text-xs text-[#8899A8] !leading-[20px] font-normal whitespace-nowrap flex flex-row items-center'>
                                            {userRole ? userRole.replace(/-/g, ' ') : ''}{' '}
                                            <DownOutlined className='text-xs mx-1' />
                                        </Text>
                                    </Dropdown>
                                </Text>
                            </div>
                        ) : null}
                        {/* Display language dropdown only if store has more than 1 language. */}
                        {multilingualFunctionalityEnabled === 'true' && languageItems.length > 0 ? (
                            <Select
                                // options={languageItems}
                                bordered={false}
                                placeholder='Select Language'
                                defaultValue={storeSelectedLngCode || defaultLanguageCode}
                                disabled={languageItems.length === 1 ? true : false}
                                onChange={(value) => handleLanguageClick(value)}
                                className='header-select !max-w-[90px]'
                                dropdownStyle={{ position: 'fixed', width: '90' }}
                                key={storeSelectedLngCode}>
                                {languageItems.map((option) => (
                                    <Option key={option.value} value={option.value} className='headerSelectOption'>
                                        {/* <Tooltip
                                            title={option.label}
                                            overlayStyle={{ position: 'fixed' }}
                                            placement='left'> */}
                                        <span className='overflow-hidden whitespace-nowrap'>
                                            {option.value?.toUpperCase()}
                                        </span>
                                        {/* </Tooltip> */}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <></>
                        )}
                    </Content>
                </Content>
            </Header>
        </Content>
    )
}

export default Header2
