import { useMutation } from '@tanstack/react-query'
//import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import http from '../../../services/axios/http-common'

const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT_DISPLAYNAME
const useCreateConsentTranslation = () => {
    const createConsentTranslation = async ({ body }) => {
        //const res = await MarketplaceServices.save(BASE_URL, body)
        const res = await http.post(BASE_URL, body)
        return res
    }
    return useMutation({ mutationFn: createConsentTranslation })
}
export default useCreateConsentTranslation
