import { ActionTypes } from '../constants/ActionTypes'

const { RDX_STORE_SETTINGS_INFO } = ActionTypes

export const fnStoreSettings = (data) => {
    return {
        type: RDX_STORE_SETTINGS_INFO,
        payload: data,
    }
}
