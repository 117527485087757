import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import React from 'react'

function CustomPasswordInput({
    disabled,
    addonBefore,
    placeholder,
    value,
    onChange,
    visibility,
    setVisibility,
    className,
    status,
    maxLength,
    width
}) {
    const handleToggleVisibility = () => {
        setVisibility(!visibility)
    }

    const handleMouseDown = () => {
        setVisibility(true)
    }

    const handleMouseUp = () => {
        setVisibility(false)
    }
    return (
        <div style={{ position: 'relative', display: 'inline-block' }} className={'w-[100%]'}>
            <Input
                disabled={disabled}
                addonBefore={addonBefore}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={visibility ? 'text' : 'password'}
                iconRender={(visibility) => (visibility ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
                className={className}
                status={status}
                maxLength={maxLength}
            />
            {
                <span
                    onClick={handleToggleVisibility}
                    // onMouseDown={handleMouseDown}
                    // onMouseUp={handleMouseUp}
                    // onMouseLeave={handleMouseUp}
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        zIndex: 1,
                    }}>
                    {visibility ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </span>
            }
        </div>
    )
}

export default CustomPasswordInput
