import React from 'react'
import { useTranslation } from 'react-i18next'
import util from '../../util/common'

function TranslateAttributeStrings({ translate, padding }) {
    const { t } = useTranslation()
    return (
        <p className={`${util.getSelectedLanguageDirection()?.toLowerCase() == 'rtl' && padding ? 'mr-5' : ''}`}>
            {t(translate)}
        </p>
    )
}

export default TranslateAttributeStrings
