import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'

const couponAPI = process.env.REACT_APP_COUPONS_API
const useGetAllCoupons = ({ tabId, pageNumber, pageSize, searchParams }) => {
    const getAllCoupons = async () => {
        let params = { status: tabId }
        if (searchParams) {
            params.search = searchParams
        }
        const serverResponse = await MarketplaceServices.findByPage(couponAPI, params, pageNumber, pageSize, true)
        return serverResponse?.data?.response_body
    }
    const key = ['coupons', tabId, pageNumber, pageSize]
    if (searchParams) key.push(searchParams)

    return useQuery({
        queryKey: key,
        queryFn: getAllCoupons,
    })
}
export default useGetAllCoupons
