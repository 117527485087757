import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../services/axios/MarketplaceServices'
const staticAttributesValuesAPI = process.env.REACT_APP_NON_EDITABLE_ATTRIBUTE_VALUES_API
/**
 * !This function is a GET Method to get the generated product variants for a particular product template revision.
 * @param {*} productTemplateRevisionId -> To get the generated variants based on the product_template_revision_id.
 */
//!REST --> GET Method.
const useGetStaticAttributeValues = ({ productTemplateId, productTemplateRevisionId, attributeDetailsUpdated }) => {
    const getProductVariantAttributes = async () => {
        const params = {
            'product-template-id': parseInt(productTemplateId),
            'product-template-revision-id': parseInt(productTemplateRevisionId),
        }
        const res = await MarketplaceServices.findAll(staticAttributesValuesAPI, params)
        return res?.data?.response_body?.data?.[0]
    }
    return useQuery({
        queryKey: ['staticAttributeValues', productTemplateRevisionId, productTemplateId, attributeDetailsUpdated],
        queryFn: getProductVariantAttributes,
        refetchOnWindowFocus: false,
        enabled: !!productTemplateRevisionId && !!productTemplateId,
        retry: false,
    })
}
export default useGetStaticAttributeValues
