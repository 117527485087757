import React, { useEffect, useState } from 'react'
import { Button, Layout, Typography, Row, Table, Input, Select, InputNumber, Checkbox, Col } from 'antd'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { useNavigate } from 'react-router-dom'
import StoreModal from '../../components/storeModal/StoreModal'
import TextArea from 'antd/es/input/TextArea'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { MdModeEditOutline } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'
import MarketplaceMutlitenancy from '../../util/MarketplaceMutlitenancy'
import MarketplaceToaster from '../../util/marketplaceToaster'
import util from '../../util/common'

const couponOffersAPI = process.env.REACT_APP_COUPONS_OFFER_API
const couponRulesAPI = process.env.REACT_APP_COUPONS_RULES_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const { Content } = Layout
const { Title, Text } = Typography

function CouponOffer() {
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [couponOfferErrorMessage, setCouponOfferErrorMessage] = useState('')
    const [isCouponOfferEdit, setIsCouponOfferEdit] = useState(false)
    const [couponsOfferCount, setCouponsOfferCount] = useState(0)
    const [couponsOfferData, setCouponsOfferData] = useState([])
    const [couponRulesData, setCouponsRulesData] = useState([])
    const [couponOrRulesData, setCouponOrRulesData] = useState([])
    const [couponAndRulesData, setCouponAndRulesData] = useState([])
    const [isCouponOfferModalVisible, setIsCouponOfferModalVisible] = useState(false)
    const navigate = useNavigate()
    const [deleteOfferID, setDeleteOfferID] = useState()
    const [editOfferID, setEditOfferID] = useState()
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false)

    const defaultCreateOfferObject = {
        key: uuidv4(),
        id: uuidv4(),
        name: '',
        offerValue: '',
        offerValuePercentage: false,
        couponRuleOr: [],
        couponRuleAnd: [],
        description: '',
    }
    const [couponOffersInfo, setCouponOffersInfo] = useState(defaultCreateOfferObject)

    const couponOfferHandler = (value, action) => {
        let copyOfCouponOffersInfo = { ...couponOffersInfo }
        switch (action) {
            case 'offer_name':
                copyOfCouponOffersInfo.name = value
                break
            case 'offer_value':
                copyOfCouponOffersInfo.offerValue = value
                break
            case 'offer_value_percentage':
                copyOfCouponOffersInfo.offerValuePercentage = value
                break
            case 'coupon_rule_or':
                let copyOfCouponAndRulesData = [...couponAndRulesData]
                let filteredAndRulesData = couponRulesData.filter((couponRuleAnd) => !value.includes(couponRuleAnd.id))
                setCouponAndRulesData(filteredAndRulesData)
                // console.log("couponRulesData", couponRulesData, filteredData);
                copyOfCouponOffersInfo.couponRuleOr = value
                break
            case 'coupon_rule_and':
                let copyOfCouponOrRulesData = [...couponOrRulesData]
                let filteredOrRuleData = couponRulesData.filter((couponRuleOr) => !value.includes(couponRuleOr.id))
                setCouponOrRulesData(filteredOrRuleData)
                copyOfCouponOffersInfo.couponRuleAnd = value
                break
            case 'description':
                copyOfCouponOffersInfo.description = value
                break
            default:
                return
        }
        setCouponOffersInfo(copyOfCouponOffersInfo)
    }

    const destructureCouponOfferData = (record) => {
        var arrayForFull = []
        record &&
            record.length > 0 &&
            record.map((element) => {
                arrayForFull.push({
                    key: element.id,
                    id: element.id,
                    name: element.offer_name,
                    offerValue: element.offer_value,
                    offerValuePercentage: element.offer_value_percentage,
                    description: element.description,
                    couponRuleOr: element.coupon_rule_or,
                    couponRuleAnd: element.coupon_rule_and,
                })
            })
        return arrayForFull
    }

    const findByPageCouponsOffersGetCall = () => {
        MarketplaceServices.findAllWithoutPage(couponOffersAPI, null, false)
            .then(function (res) {
                console.log('res couponss...', res)
                console.log('res.daata', res.data)
                setCouponsOfferCount(res.data.count)
                setCouponsOfferData(destructureCouponOfferData(res.data.data))
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch((error) => {
                // setMsg(error.response.data.message);
                // setStatus(error.response.status);
                console.log('Server Error Response from Approvals GET API Call', error.response)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    const findByPageCouponsRulesGetCall = () => {
        MarketplaceServices.findAllWithoutPage(couponRulesAPI, null, false)
            .then(function (res) {
                setCouponOrRulesData(res.data.data)
                setCouponAndRulesData(res.data.data)
                setCouponsRulesData(res.data.data)
            })
            .catch((error) => {
                // setMsg(error.response.data.message);
                // setStatus(error.response.status);
                console.log('Server Error Response from Approvals GET API Call', error.response)
            })
    }

    const deleteOffer = () => {
        MarketplaceServices.remove(couponOffersAPI, {
            id: deleteOfferID,
        })
            .then(() => {
                // deleting the attribute non-editable value
                let copyOfCouponsOfferData = [...couponsOfferData]
                let removedCouponsOfferData = copyOfCouponsOfferData.filter(({ id }) => id !== deleteOfferID)
                setCouponsOfferData(removedCouponsOfferData)
                // copyOfCouponsRulesData.filter(({id})=> id !== deleteRuleID).length > 0
                // if (copyOfCouponsRulesData.filter(({ id }) => id === response.data.id).length <= 0) {

                // }
                MarketplaceToaster.showToast(util.getToastObject('Coupon deleted successfully', 'success'))
                setIsOpenWarningModal(false)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${
                            error.response.status + ' : ' + error.response.statusText + ',' + 'Failed in rule deletion'
                        }`,
                        'error'
                    )
                )
            })
    }

    const createOffer = () => {
        if (isCouponOfferEdit) {
            let putObject = {
                offer_name: couponOffersInfo.name,
                offer_value: String(couponOffersInfo.offerValue),
                offer_value_percentage: couponOffersInfo.offerValuePercentage,
                coupon_rule_or: couponOffersInfo.couponRuleOr,
                coupon_rule_and: couponOffersInfo.couponRuleAnd,
                description: couponOffersInfo.description,
            }
            MarketplaceServices.update(couponOffersAPI + '/' + editOfferID, putObject, null)
                .then((response) => {
                    let copyOfCouponsOfferData = [...couponsOfferData]
                    copyOfCouponsOfferData.forEach((obj, index) => {
                        if (obj.id === response.data.id) {
                            copyOfCouponsOfferData[index] = {
                                key: response.data.id,
                                id: response.data.id,
                                name: response.data.offer_name,
                                offerValue: response.data.offer_value,
                                offerValuePercentage: response.data.offer_value_percentage,
                                couponRuleOr: response.data.coupon_rule_or,
                                couponRuleAnd: response.data.coupon_rule_and,
                                description: response.data.description,
                            }
                        }
                    })
                    setCouponsOfferData(copyOfCouponsOfferData)
                    setIsCouponOfferModalVisible(false)
                    console.log('response....', response.data.id)

                    MarketplaceToaster.showToast(util.getToastObject('Coupon offer updated successfully', 'success'))
                })
                .catch((error) => {
                    MarketplaceToaster.showToast(
                        util.getToastObject(
                            `${
                                error.response.status +
                                ' : ' +
                                error.response.statusText +
                                ',' +
                                'Failed to update coupon offer'
                            }`,
                            'error'
                        )
                    )
                })
        } else {
            let postObject = {
                offer_name: couponOffersInfo.name,
                offer_value: couponOffersInfo.offerValue,
                offer_value_percentage: couponOffersInfo.offerValuePercentage,
                coupon_rule_or: couponOffersInfo.couponRuleOr,
                coupon_rule_and: couponOffersInfo.couponRuleAnd,
                description: couponOffersInfo.description,
            }
            MarketplaceServices.save(couponOffersAPI, postObject, null)
                .then((response) => {
                    let copyOfCouponsOfferData = [...couponsOfferData]
                    // copyOfCouponsRulesData.filter(({id})=> id === response.data.id).length > 0
                    // if (copyOfCouponsRulesData.filter(({ id }) => id === response.data.id).length <= 0) {

                    // }
                    let obj = {
                        key: response.data.id,
                        id: response.data.id,
                        name: response.data.offer_name,
                        offerValue: response.data.offer_value,
                        offerValuePercentage: response.data.offer_value_percentage,
                        couponRuleOr: response.data.coupon_rule_or,
                        couponRuleAnd: response.data.coupon_rule_and,
                        description: response.data.description,
                    }
                    copyOfCouponsOfferData.push(obj)
                    setCouponsOfferData(copyOfCouponsOfferData)
                    setIsCouponOfferModalVisible(false)
                    console.log('response....', response.data.id)
                    MarketplaceToaster.showToast(util.getToastObject('Coupon offer added successfully', 'success'))
                })
                .catch((error) => {
                    MarketplaceToaster.showToast(
                        util.getToastObject(
                            `${
                                error.response.status +
                                ' : ' +
                                error.response.statusText +
                                ',' +
                                'Failed to add coupon offer'
                            }`,
                            'error'
                        )
                    )
                })
        }
    }

    useEffect(() => {
        findByPageCouponsOffersGetCall()
        findByPageCouponsRulesGetCall()
    }, [])

    const getOfferPercentageControl = (enablePercentage) => {
        if (enablePercentage) {
            return (
                <InputNumber
                    className='w-60'
                    value={couponOffersInfo.offerValue}
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    onChange={(value) => {
                        couponOfferHandler(value, 'offer_value')
                    }}
                />
            )
        } else {
            return (
                <InputNumber
                    className='w-60'
                    value={couponOffersInfo.offerValue}
                    onChange={(value) => couponOfferHandler(value, 'offer_value')}
                />
            )
        }
    }

    const openEditModal = (editId) => {
        setIsCouponOfferModalVisible(true)
        setIsCouponOfferEdit(true)
        setEditOfferID(editId)
        setCouponOffersInfo(couponsOfferData.filter(({ id }) => id == editId)[0])
        console.log(
            'id',
            editId,
            couponsOfferData.filter(({ id }) => id == editId)
        )
    }

    const tableHeader = [
        {
            title: <Content className='whitespace-nowrap'>{'Offer Name'}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            render: (text, record) => {
                return <Content>{record.name}</Content>
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{'Offer Description'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '30%',
            render: (text, record) => {
                return <Content>{record.description}</Content>
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{'Offer Value'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '30%',
            render: (text, record) => {
                return <Content>{record.offerValue}</Content>
            },
        },
        {
            title: `Action`,
            dataIndex: '',
            key: '',
            render: (text, record) => {
                return (
                    <Row className=''>
                        <Col span={8}>
                            <MdModeEditOutline
                                className='mt-[6px] cursor-pointer'
                                onClick={() => openEditModal(record.id)}
                            />
                        </Col>
                        <Col span={8}>
                            <RiDeleteBin6Line
                                className='app-delete-icon mt-[6px] cursor-pointer'
                                onClick={() => {
                                    console.log('first record', record)
                                    setIsOpenWarningModal(true)
                                    setDeleteOfferID(record.id)
                                }}
                            />
                        </Col>
                    </Row>
                )
            },
        },
    ]

    console.log('couponOffersInfo', couponOffersInfo)

    return (
        <Content className='p-1 !min-h-screen'>
            {isLoading ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2 !bg-white'>
                    <SkeletonComponent />
                </Content>
            ) : isNetworkError ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                    <h5>{couponOfferErrorMessage ? couponOfferErrorMessage : `NetWork error has happend`}</h5>
                </Content>
            ) : (
                <Content>
                    <Content className='!bg-white p-2'>
                        <Content>
                            <Row>
                                <Content>Coupon Offers</Content>
                                <Content className='!flex !justify-items-end justify-end !text-right'>
                                    <Button
                                        className='mr-1'
                                        onClick={() =>
                                            navigate(
                                                '/dashboard/coupons/list-coupon-rules?tab=3&' +
                                                    MarketplaceMutlitenancy.getStore('')
                                            )
                                        }>
                                        Create Rule
                                    </Button>
                                    <Button onClick={() => setIsCouponOfferModalVisible(true)}>Create offer</Button>
                                </Content>
                            </Row>
                        </Content>
                        <Content className='pt-3'>
                            {couponsOfferCount === 0 && couponsOfferData && couponsOfferData.length === 0 ? (
                                <Content className='p-0 text-center mt-2 ml-2'>
                                    <Text> {'No offers are avaliable, please create rules and add coupon'}</Text>
                                </Content>
                            ) : (
                                <Content>
                                    <Content className='!max-w-[100%]'>
                                        {/* <DynamicTable tableComponentData={tablePropsData} /> */}
                                        <Table dataSource={couponsOfferData} columns={tableHeader} pagination={false} />
                                    </Content>
                                    {/* TODO:will add based on the requirement */}
                                    {/* {couponsCount > itemsPerPageFromEnv ? (
                <Content className="!grid !justify-items-end">
                  <DmPagination
                    currentPage={
                      parseInt(searchParams.get("page"))
                        ? parseInt(searchParams.get("page"))
                        : 1
                    }
                    totalItemsCount={approvalsTotalCount}
                    defaultPageSize={itemsPerPageFromEnv}
                    pageSize={
                      parseInt(searchParams.get("limit"))
                        ? parseInt(searchParams.get("limit"))
                        : itemsPerPageFromEnv
                    }
                    handlePageNumberChange={handlePageNumberChange}
                    showSizeChanger={true}
                    showTotal={true}
                  />
                </Content>
              ) : null} */}
                                </Content>
                            )}
                        </Content>
                    </Content>
                    {
                        <StoreModal
                            isVisible={isOpenWarningModal}
                            title={'Are you sure, you want to delete ?'}
                            okCallback={() => deleteOffer()}
                            okButtonText={'Delete'}
                            cancelButtonText={'Cancel'}
                            cancelCallback={() => {
                                setIsOpenWarningModal(false)
                            }}
                            isSpin={false}></StoreModal>
                    }
                    {
                        <StoreModal
                            isVisible={isCouponOfferModalVisible}
                            title={isCouponOfferEdit ? 'Edit Offer' : 'Create Offer'}
                            okCallback={() => createOffer()}
                            okButtonText={isCouponOfferEdit ? 'Update' : 'Create'}
                            cancelButtonText={'Cancel'}
                            cancelCallback={() => {
                                setIsCouponOfferModalVisible(false)
                                setCouponOffersInfo(defaultCreateOfferObject)
                            }}
                            isSpin={false}>
                            <Content>
                                <Typography>{'Offer Name'}</Typography>
                                <Input
                                    placeholder={'Enter offer name here'}
                                    className='w-60'
                                    value={couponOffersInfo.name}
                                    onChange={(e) => couponOfferHandler(e.target.value, 'offer_name')}></Input>
                            </Content>
                            <Content className='my-2 flex'>
                                <Content>
                                    <Typography>{'Offer Value'}</Typography>
                                    {getOfferPercentageControl(couponOffersInfo.offerValuePercentage)}
                                </Content>
                                <Content>
                                    <Typography className='pl-[9px]'>{'Enable Offer Percentage'}</Typography>
                                    <Checkbox
                                        className='!ml-[10px]'
                                        value={couponOffersInfo.offerValuePercentage}
                                        checked={couponOffersInfo.offerValuePercentage}
                                        onChange={(e) => couponOfferHandler(e.target.checked, 'offer_value_percentage')}
                                    />
                                </Content>
                            </Content>
                            <Content className='my-2'>
                                <Typography>{'Coupon Rule Or'}</Typography>
                                <Select
                                    className='w-60'
                                    mode='multiple'
                                    allowClear
                                    value={couponOffersInfo.couponRuleOr} // Set the value to the first element
                                    placeholder={'Select Coupon Rules Or'}
                                    onChange={(value) => couponOfferHandler(value, 'coupon_rule_or')}>
                                    {couponOrRulesData.map(({ id, rule_name }) => (
                                        <Select.Option key={`${rule_name}_${id}`} value={id}>
                                            {rule_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Content>
                            <Content className='my-2'>
                                <Typography>{'Coupon Rule And'}</Typography>
                                <Select
                                    className='w-60'
                                    mode='multiple'
                                    allowClear
                                    value={couponOffersInfo.couponRuleAnd} // Set the value to the first element
                                    placeholder={'Select Coupon Rules Off'}
                                    onChange={(value) => couponOfferHandler(value, 'coupon_rule_and')}>
                                    {couponAndRulesData.map(({ id, rule_name }) => (
                                        <Select.Option key={`${rule_name}_${id}`} value={id}>
                                            {rule_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Content>
                            <Content className='my-2'>
                                <Typography>{'Description'}</Typography>
                                <TextArea
                                    className='w-60'
                                    rows={2}
                                    maxLength={555}
                                    placeholder={'Enter offer description here'}
                                    value={couponOffersInfo.description}
                                    onChange={(e) => couponOfferHandler(e.target.value, 'description')}
                                />
                            </Content>
                        </StoreModal>
                    }
                </Content>
            )}
        </Content>
    )
}

export default CouponOffer
