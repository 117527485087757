import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_CATEGORY_SELECTED_INFO,
    RDX_CATEGORY_BY_STATUS,
    RDX_CATEGORY_BY_STATUS_RAW_DATA,
    RDX_REMOVE_CATEGORY_SELECTED_INFO,
    RDX_CATEGORY_SELECTED_SEGMENT_INFO,
    RDX_PARENT_CATEGORY_SELECTED_INFO,
} = ActionTypes

// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods
export const ReducerCategories = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_CATEGORY_BY_STATUS:
            return { ...state, categoryByStatus: payload }
        case RDX_CATEGORY_BY_STATUS_RAW_DATA:
            return { ...state, categoryByStatusRawData: payload }
        case RDX_CATEGORY_SELECTED_INFO:
            return { ...state, categorySelectedInfo: payload }
        case RDX_REMOVE_CATEGORY_SELECTED_INFO:
            delete state.categorySelectedInfo
            return { ...state }
        case RDX_CATEGORY_SELECTED_SEGMENT_INFO:
            return { ...state, categorySelectedSegmentInfo: payload }
        case RDX_PARENT_CATEGORY_SELECTED_INFO:
            return { ...state, parentCategorySelectedInfo: payload }
        default:
            return state
    }
}
