import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT

const useDeleteStoreUserConsent = () => {
    const deleteStoreUserConsent = async ({ userConsentId }) => {
        const params = { 'store-userconsent-id': userConsentId }
        const res = await MarketplaceServices.remove(BASE_URL, params)
        return res.data
    }
    return useMutation({ mutationFn: deleteStoreUserConsent })
}
export default useDeleteStoreUserConsent
