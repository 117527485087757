import { LinkOutlined } from '@ant-design/icons'
import { Button, Col, Empty, Layout, Row, Switch, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { MdEdit } from 'react-icons/md'
import { useAuth } from 'react-oidc-context'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography

const storeVendorApi = process.env.REACT_APP_STORE_VENDOR_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const onboardingUrl = process.env.REACT_APP_ONBOARDING_URL
const vendorStatusAPI = process.env.REACT_APP_VENDOR_STATUS_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const Vendors = () => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal') + ' - ' + String(` ${t('common:vendors')}`))
    const [vendorDataFromApi, setVendorDataFromApi] = useState()
    const [isLoadingVendorList, setIsLoadingVendorList] = useState(true)
    const [isNetworkErrorVendorListData, setIsNetworkErrorVendorListData] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [vendorStatusModalOpen, setVendorStatusModalOpen] = useState(false)
    const [vendorSelected, setVendorSelected] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('')
    const [hideManageOnboardingButton, setHideManageOnboardingButton] = useState(false)
    // get permissions from storage
    const [totalItemsCount, setTotalItemsCount] = useState()
    const [langDirection, setLangDirection] = useState('ltr')
    const permissionValue = util.getPermissionData() || []
    const auth = useAuth()

    const statusChangePutCall = () => {
        let vendorStatusAPIreplace
        vendorStatusAPIreplace = vendorStatusAPI.replace('{vendor_id}', String(vendorSelected))
        let putBody = {
            vendor_status: selectedStatus === 1 ? 2 : 1,
        }

        MarketplaceServices.update(vendorStatusAPIreplace, putBody)
            .then(function (response) {
                setVendorStatusModalOpen(false)
                vendorDataFromApi &&
                    vendorDataFromApi.length > 0 &&
                    vendorDataFromApi.forEach((element) => {
                        if (parseInt(element.id) === parseInt(vendorSelected)) {
                            element.vendor_status = selectedStatus === 1 ? 2 : 1
                        }
                    })
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                setVendorStatusModalOpen(false)
            })
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const VendorTableColumn = [
        {
            title: `${t('common:name')}`,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Row>
                            <Col>
                                <Tooltip title={record.display_name ? record.display_name : '-'} mouseLeaveDelay={0}>
                                    <Text
                                        ellipsis={true}
                                        style={{ maxWidth: 280 }}
                                        className='text-[14px] text-brandGray1 font-normal'>
                                        {record.display_name ? record.display_name : '-'}
                                    </Text>
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                )
            },
        },
        {
            title: `${t('sales_returns:vendor_account_name')}`,
            dataIndex: 'vendor account name',
            key: 'vendor account name',
            render: (text, record) => {
                return (
                    <div>
                        <Row>
                            <Col>
                                <Tooltip title={record.name ? record.name : '-'} mouseLeaveDelay={0}>
                                    <Text
                                        ellipsis={true}
                                        style={{ maxWidth: 280 }}
                                        className='text-[14px] text-brandGray1 font-normal'>
                                        {record.name ? record.name : '-'}
                                    </Text>
                                </Tooltip>
                            </Col>
                        </Row>
                    </div>
                )
            },
        },
        {
            title: `${t('vendors:on_board_date')}`,
            dataIndex: 'created_on',
            key: 'created_on',

            render: (text, record) => {
                return (
                    <div>
                        <Row>
                            <Col>
                                <span className='text-[14px] text-brandGray1 font-normal'>
                                    {getGenerateDateAndTime(record && record.created_on, 'D MMMM YYYY')}
                                </span>
                            </Col>
                        </Row>
                    </div>
                )
            },
        },
        {
            title: `${t('vendors:status')}`,
            dataIndex: 'vendor_status',
            key: 'vendor_status',
            render: (text, record) => {
                return (
                    <div>
                        <Row>
                            <Switch
                                onChange={() => {
                                    if (vendorStatusModalOpen === false) {
                                        setVendorStatusModalOpen(true)
                                    }
                                    setVendorSelected(record.id)
                                    setSelectedStatus(record.vendor_status)
                                }}
                                className={record.vendor_status === 1 ? '!bg-brandPrimaryColor' : '!bg-gray-400'}
                                checked={record.vendor_status === 1 ? true : false}
                            />
                            {/* <Col className={`${langDirection === 'rtl' ? '!mr-1' : '!ml-1'}`}>
                                {record && record.vendor_status === 1
                                    ? `${t('dashboard:active')}`
                                    : `${t('dashboard:inactive')}`}
                            </Col> */}
                        </Row>
                    </div>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: '',
            key: '',
            render: (text, record) => {
                return (
                    <div>
                        <Row>
                            <Col>
                                {' '}
                                <Link
                                    to={{
                                        pathname: '/dashboard/vendors/vendors-details',

                                        search: `?v_id=${record.id}&v_name=${record.name}&${MarketplaceAppConfig.getStore('')}`,
                                    }}
                                    className=''>
                                    <Tooltip
                                        placement='bottom'
                                        title={`${t('common:edit')} ${record.display_name ? record.display_name : '-'}`}
                                        overlayStyle={{ zIndex: 1 }}>
                                        <Text className='!text-sm !font-normal !text-brandPrimaryColor'>
                                            {t('common:edit')}
                                        </Text>
                                    </Tooltip>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )
            },
        },
    ]

    //! get call of vendor lists
    const findByPageVendorList = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(storeVendorApi, null, pageNumber, pageLimit, true)
            .then(function (response) {
                console.log(
                    'Server response from findByPageVendorList is',
                    storeVendorApi,
                    response.data.response_body.data
                )
                setIsLoadingVendorList(false)
                if (response.data.response_body.data.length > 0) {
                    setVendorDataFromApi(response.data.response_body.data)
                    setTotalItemsCount(response.data.response_body.count)
                }
            })
            .catch(function (error) {
                console.log('Server response from findByPageVendorList is', storeVendorApi, error.response)
                setIsLoadingVendorList(false)
                setIsNetworkErrorVendorListData(true)
            })
    }

    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            tab: parseInt(searchParams.get('tab')) ? parseInt(searchParams.get('tab')) : 1,
            page: page,
            limit: pageSize,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (searchParams.get('page') && searchParams.get('limit')) {
            findByPageVendorList(
                parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv
            )
        }
    }, [searchParams])

    useEffect(() => {
        window.scrollTo(0, 0)
        util.setStoreNameWhenWeChangeINUrl()
        setSearchParams({
            page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
            limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }, [])

    useEffect(() => {
        setHideManageOnboardingButton(
            !auth.isAuthenticated ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('UI-manage-onboarding'))
                ? true
                : false
        )
    }, [auth])

    return (
        <Content className=''>
            <>
                <StoreModal
                    title={selectedStatus === 2 ? t('vendors:vendor_activation') : t('vendors:vendor_deactivation')}
                    isSpin={false}
                    okCallback={() => {
                        statusChangePutCall()
                    }}
                    isVisible={vendorStatusModalOpen}
                    okButtonText={selectedStatus === 2 ? t('vendors:activate') : t('vendors:deactivate')}
                    cancelButtonText={t('common:cancel')}
                    noCloseIcon={true}
                    cancelCallback={() => {
                        setVendorStatusModalOpen(false)
                        setVendorSelected('')
                    }}>
                    <Content className='text-sm font-normal text-brandGray1'>
                        {selectedStatus === 2 ? (
                            <div>
                                <p>{t('vendors:vendor_activation_confirmation')}</p>
                                <p>{t('vendors:vendor_activate_message')}</p>
                            </div>
                        ) : (
                            <div>
                                <p>{t('vendors:vendor_deactivation_confirmation')}</p>
                                <p>{t('vendors:vendor_deactivate_message')}</p>
                            </div>
                        )}
                    </Content>
                </StoreModal>
            </>
            <Content className='pt-1'>
                <HeaderForTitle
                    title={
                        <Content className='flex'>
                            <Content className='!w-[80%]'>
                                <Title level={3} className='!font-semibold text-2xl text-regal-blue'>
                                    {t('common:vendors')}
                                </Title>
                            </Content>
                            {hideManageOnboardingButton ? (
                                <Content className=' text-right'>
                                    <Button
                                        className='w-[100%] min-w-max app-btn-secondary'
                                        onClick={() =>
                                            window.open(
                                                onboardingUrl + '?' + MarketplaceAppConfig.getStore(''),
                                                '_blank'
                                            )
                                        }>
                                        <LinkOutlined className='-translate-y-1 text-regal-blue !text-sm' />
                                        <span className='!text-regal-blue !text-sm !font-medium'>
                                            {' '}
                                            {t('vendors:manage_onboarding')}
                                        </span>
                                    </Button>
                                </Content>
                            ) : (
                                ''
                            )}
                        </Content>
                    }
                    headerContent={
                        <Paragraph className='!font-normal !text-brandGray1 !m-0 !text-sm'>
                            {t('common:vendor_short_desc')}
                        </Paragraph>
                    }
                />
            </Content>
            <Content className='p-3 '>
                {isLoadingVendorList ? (
                    <Content className=' bg-white p-3 '>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkErrorVendorListData ? (
                    <Content className='text-center bg-white p-3'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : (
                    <Content className=' bg-white !p-2 !rounded-lg'>
                        {vendorDataFromApi && vendorDataFromApi.length > 0 ? (
                            <Table
                                dataSource={vendorDataFromApi}
                                columns={VendorTableColumn}
                                scroll={{}}
                                pagination={false}
                                rowSelection={false}></Table>
                        ) : (
                            <div className='flex items-center justify-center !bg-white'>
                                <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                                    <Empty description={t('common:no_data_available_to_display')} />
                                </div>
                            </div>
                        )}
                        {totalItemsCount > itemsPerPageFromEnv ? (
                            <Content className=' grid justify-items-end'>
                                <DmPagination
                                    currentPage={
                                        parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                    }
                                    showTotal={true}
                                    totalItemsCount={totalItemsCount}
                                    defaultPageSize={itemsPerPageFromEnv}
                                    pageSize={
                                        parseInt(searchParams.get('limit'))
                                            ? parseInt(searchParams.get('limit'))
                                            : itemsPerPageFromEnv
                                    }
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showQuickJumper={true}
                                />
                            </Content>
                        ) : null}
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default Vendors
