import React, { useState, useEffect } from 'react'
import { Layout, Typography, Spin } from 'antd'
import PLPImages from './PLPImages'
import PLPOverView from './PLPOverView'
import PLPDetails from './PLPDetails'
import { WorkInProgress, ThbsLogo, CategoriesImage, Samsung } from '../../../../constants/media'

const { Content } = Layout

const PLPLayout2 = ({ productId, backgroundColor }) => {
    let temp = [
        {
            id: 1,
            imagePath: WorkInProgress,
        },
        {
            id: 2,
            imagePath: ThbsLogo,
        },
        {
            id: 3,
            imagePath: Samsung,
        },
        {
            id: 4,
            imagePath: WorkInProgress,
        },
        {
            id: 5,
            imagePath: ThbsLogo,
        },
        {
            id: 6,
            imagePath: Samsung,
        },
    ]
    return (
        <Content className={`${'mt-3 p-3  '} ${backgroundColor}`}>
            <Content className='flex'>
                <Content className='w-[100%] p-3'>
                    <PLPOverView />
                </Content>
                <Content className='w-[100%] p-3'>
                    <PLPImages imageData={[]} />
                </Content>
            </Content>
            <Content className='w-[100%] p-3'>
                <PLPDetails />
            </Content>
        </Content>
    )
}

export default PLPLayout2
