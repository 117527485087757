import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_PRODUCT_SELECTED_INFO,
    RDX_PRODUCT_TEMPLATE_SELECTED_INFO,
    RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO,
    RDX_ACTIVE_PRODUCT_TEMPLATES_INFO,
} = ActionTypes

export const fnProductSelectedInfo = (data) => {
    return {
        type: RDX_PRODUCT_SELECTED_INFO,
        payload: data,
    }
}
export const fnProductTemplateSelectedInfo = (data) => {
    return {
        type: RDX_PRODUCT_TEMPLATE_SELECTED_INFO,
        payload: data,
    }
}
export const fnProductTemplateSelectedProductTypesInfo = (data) => {
    return {
        type: RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO,
        payload: data,
    }
}
export const fnActiveProductTemplatesInfo = (data) => {
    return {
        type: RDX_ACTIVE_PRODUCT_TEMPLATES_INFO,
        payload: data,
    }
}
