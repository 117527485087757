import { TranslationOutlined } from '@ant-design/icons'
import { Checkbox, Collapse, Input, InputNumber, Select, Tooltip, Typography } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiInformationLine } from 'react-icons/ri'
import validator from 'validator'
import attributeUnitControlDescription from './AttributeUnitControlDescription'
const { Panel } = Collapse
const emailRegexMaxLength = parseInt(process.env.REACT_APP_EMAIL_REGEX_MAX_LENGTH)
const emailRegexMsgMaxLength = parseInt(process.env.REACT_APP_EMAIL_REGEX_MESSAGE_MAX_LENGTH)
const decimalMinValue = process.env.REACT_APP_DECIMAL_UNIT_MIN_LIMIT
const decimalMaxValue = process.env.REACT_APP_DECIMAL_UNIT_MAX_LIMIT
const advancedUnitMaxValue = process.env.REACT_APP_ADVANCED_UNIT_MAX_LIMIT

function AttributeUnitControl({
    storeLanuage,
    dataType,
    attributeUnit,
    attributeUnitHandler,
    productOptions,
    defaultMin,
    defaultMax,
    attributeExtension,
    setMaxAndminFailed,
    extensionRequired,
    saveButtonClicked,
    langDirection,
}) {
    const { t } = useTranslation()
    const getFormats = () => {
        if (dataType === 5) {
            return ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']
        } else if (dataType === 9) {
            return ['HH:mm:ss', 'HH:mm']
        } else {
            return ['YYYY-MM-DD HH:mm:ss']
        }
    }
    const dateFormats = getFormats()
    const [minMaxErrorMessage, setErrorMessage] = useState('')
    const [isError, setIsError] = useState(false)
    const [minValue, setMinValue] = useState(defaultMin)
    const [maxValue, setMaxValue] = useState(defaultMax)
    const [booleanToStopUseffect, setBooleanToStopUseffect] = useState(true)
    const getAttributeUnitValue = (unitName) => {
        let filteredUnitValue = productOptions.attributes.attribute_unit_value.filter(({ key }) => key === unitName)
        return filteredUnitValue
    }

    const onlyNumbers = (e) => {
        if (
            !(
                e.key === 'Backspace' ||
                e.key === 'Delete' ||
                e.key === 'ArrowLeft' ||
                e.key === 'ArrowRight' ||
                e.key === 'Tab' ||
                (e.key >= '0' && e.key <= '9')
            )
        ) {
            e.preventDefault()
        }
    }

    useEffect(() => {
        if (minValue > maxValue) {
            setErrorMessage(t('attributes:mandatory_fields_min_max'))
            setIsError(true)
            setMaxAndminFailed(true)
        } else if (maxValue == minValue && maxValue !== '' && minValue !== '' && maxValue && minValue) {
            setErrorMessage(t('attributes:mandatory_fields_min_max'))
            setIsError(true)
            setMaxAndminFailed(true)
        } else if (
            (minValue === '' || (typeof minValue == 'object' && minValue === null)) &&
            typeof maxValue == 'number'
        ) {
            setErrorMessage(t('attributes:mandatory_fields_min_max'))
            setIsError(true)
            setMaxAndminFailed(true)
        } else if (
            (maxValue === '' || (typeof maxValue == 'object' && maxValue === null)) &&
            typeof minValue == 'number'
        ) {
            setErrorMessage(t('attributes:mandatory_fields_min_max'))
            setIsError(true)
            setMaxAndminFailed(true)
        } else if (maxValue === 0 && minValue === 0) {
            setErrorMessage(t('attributes:mandatory_fields_min_max'))
            setIsError(true)
            setMaxAndminFailed(true)
        } else {
            setErrorMessage('')
            setIsError(false)
            setMaxAndminFailed(false)
        }
    }, [minValue, maxValue])

    useEffect(() => {
        setBooleanToStopUseffect(false)
        if (booleanToStopUseffect === true) {
            if (productOptions.attributes.attribute_unit_value[0].value[0] === '') {
                if (dateFormats && dateFormats.length > 0) {
                    if (dataType === 9) {
                        attributeUnitHandler(dateFormats[0], 'format')
                    }
                }
            }
        }
    }, [dateFormats])

    const getUnitControl = (control) => {
        switch (control) {
            case 'format':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography level={4} className='!font-semibold mb-2 input-label-color'>
                                {dataType === 14
                                    ? t('attributes:select_date_time_format')
                                    : dataType === 9
                                      ? t('attributes:select_time_format')
                                      : t('attributes:select_date_format')}
                            </Typography>
                            <Tooltip
                                title={
                                    dataType === 9
                                        ? t('attributes:time_format_description')
                                        : attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                              .description
                                }
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <Select
                            value={
                                getAttributeUnitValue('format')[0]?.value != ''
                                    ? getAttributeUnitValue('format')[0].value
                                    : dataType === 14
                                      ? dateFormats && dateFormats[0]
                                      : dataType === 9
                                        ? t('attributes:select_time_format')
                                        : t('attributes:select_date_format')
                            }
                            onChange={(value) => {
                                attributeUnitHandler(value, 'format')
                            }}
                            disabled={dateFormats?.length <= 1 ? true : false}
                            listHeight={100}>
                            {dateFormats.map((format, id) => {
                                return (
                                    <Select.Option key={`${format}_${id}`} value={format}>
                                        {format}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Content>
                )
            case 'ext':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 pl-[0.82rem] input-label-color'>
                                {t('attributes:attribute_extension')}
                                <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <Content
                            className={`p-[0.82rem] border-[1px] ${
                                extensionRequired && saveButtonClicked ? 'border-red-500' : ''
                            }`}>
                            {attributeExtension.map((ext) => {
                                return (
                                    <Checkbox
                                        checked={getAttributeUnitValue('ext')[0].value.includes(ext)}
                                        value={ext}
                                        onChange={(e) => {
                                            attributeUnitHandler(e.target.value, 'ext', e.target.checked)
                                        }}>
                                        {ext}
                                    </Checkbox>
                                )
                            })}
                        </Content>
                    </Content>
                )
            case 'limiter':
                return (
                    <Content className='mr-2'>
                        <Typography className='!font-semibold mb-2' level={4}>
                            {t('attributes:step_value')}{' '}
                        </Typography>
                        <InputNumber className='mb-2'></InputNumber>
                    </Content>
                )
            case 'left':
                return (
                    <Content className='mr-2'>
                        <Typography className='!font-semibold mb-2' level={4}>
                            {' '}
                            {t('attributes:left_message')}{' '}
                        </Typography>
                        <Input placeholder={t('attributes:left_message')} className='mb-2'></Input>
                    </Content>
                )
            case 'right':
                return (
                    <Content className='mr-2'>
                        <Typography className='!font-semibold mb-2' level={4}>
                            {t('attributes:right_message')}
                        </Typography>
                        <Input placeholder={t('attributes:right_message')} className='mb-2'></Input>
                    </Content>
                )
            case 'value_regex':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 input-label-color' level={4}>
                                {t('attributes:value_regex')}{' '}
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <Input
                            value={
                                getAttributeUnitValue('value_regex')[0]?.value
                                    ? getAttributeUnitValue('value_regex')[0].value
                                    : ''
                            }
                            onChange={(e) => {
                                if (
                                    e.target.value.trim() !== '' &&
                                    validator.isLength(e.target.value.trim(), {
                                        min: 0,
                                        max: emailRegexMaxLength,
                                    })
                                ) {
                                    attributeUnitHandler(e.target.value, 'value_regex')
                                } else if (e.target.value.trim() === '')
                                    attributeUnitHandler(e.target.value, 'value_regex')
                            }}
                            placeholder={t('attributes:enter_value_regex')}
                            className='mb-2'
                            suffix={
                                storeLanuage && storeLanuage.length > 0 ? (
                                    <TranslationOutlined></TranslationOutlined>
                                ) : (
                                    ''
                                )
                            }></Input>
                    </Content>
                )
            case 'value_regex_error_message':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 whitespace-nowrap input-label-color'>
                                {t('attributes:value_regex_message')}
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <Input
                            value={
                                getAttributeUnitValue('value_regex_error_message')[0]?.value
                                    ? getAttributeUnitValue('value_regex_error_message')[0].value
                                    : ''
                            }
                            onChange={(e) => {
                                if (
                                    e.target.value.trim() !== '' &&
                                    validator.isLength(e.target.value.trim(), {
                                        min: 0,
                                        max: emailRegexMsgMaxLength,
                                    })
                                ) {
                                    attributeUnitHandler(e.target.value, 'value_regex_error_message')
                                } else if (e.target.value.trim() === '')
                                    attributeUnitHandler(e.target.value, 'value_regex_error_message')
                            }}
                            placeholder={t('attributes:enter_value_regex_message')}
                            className='mb-2'
                            suffix={
                                storeLanuage && storeLanuage.length > 0 ? (
                                    <TranslationOutlined></TranslationOutlined>
                                ) : (
                                    ''
                                )
                            }></Input>
                    </Content>
                )
            case 'decimal':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 input-label-color'>
                                {t('attributes:decimal')}
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <InputNumber
                            className='mb-2'
                            step='1'
                            min={decimalMinValue}
                            max={decimalMaxValue}
                            defaultValue={getAttributeUnitValue('decimal')[0]?.value}
                            onChange={(value) => attributeUnitHandler(value, 'decimal')}
                            onKeyDown={onlyNumbers}></InputNumber>
                    </Content>
                )
            case 'min':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 input-label-color'>
                                {t('attributes:minimum')}
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <InputNumber
                            className='mb-2'
                            defaultValue={
                                getAttributeUnitValue('min')[0]?.value !== ''
                                    ? getAttributeUnitValue('min')[0].value
                                    : defaultMin
                            }
                            onChange={(value) => {
                                attributeUnitHandler(value, 'min')
                                setMinValue(value)
                            }}
                            onKeyDown={onlyNumbers}
                            status={isError ? 'error' : ''}
                            max={advancedUnitMaxValue}
                            min={0}
                            maxLength={advancedUnitMaxValue.toString().length}></InputNumber>
                    </Content>
                )
            case 'max':
                return (
                    <Content className='mr-2'>
                        <Content className='flex items-center'>
                            <Typography className='!font-semibold mb-2 input-label-color'>
                                {t('attributes:maximum')}
                            </Typography>
                            <Tooltip
                                title={
                                    attributeUnitControlDescription.filter(({ name }) => name === control)[0]
                                        .description
                                }
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                className='mx-2 mb-2'>
                                <RiInformationLine className='text-xl mr-2 input-label-color' />
                                {/* <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs px-1' /> */}
                            </Tooltip>
                        </Content>
                        <InputNumber
                            className='mb-2'
                            defaultValue={
                                getAttributeUnitValue('max')[0]?.value !== ''
                                    ? getAttributeUnitValue('max')[0].value
                                    : defaultMax
                            }
                            onKeyDown={onlyNumbers}
                            onChange={(value) => {
                                attributeUnitHandler(value, 'max')
                                setMaxValue(value)
                            }}
                            status={isError ? 'error' : ''}
                            max={advancedUnitMaxValue}
                            min={0}
                            maxLength={advancedUnitMaxValue.toString().length}></InputNumber>
                    </Content>
                )
            default:
                return <></>
        }
    }

    return (
        <Collapse accordion defaultActiveKey={['0']}>
            <Panel
                header={
                    <Typography level={5} className='!m-0'>
                        {t('attributes:advanced_settings')}
                    </Typography>
                }>
                <Content className='flex'>
                    {attributeUnit.length > 0
                        ? attributeUnit.map(({ key }) => {
                              return getUnitControl(key)
                          })
                        : t('attributes:no_advanced_settings')}
                </Content>
                {minMaxErrorMessage !== '' ? (
                    <Content className='font-semibold text-red-600'>{minMaxErrorMessage}</Content>
                ) : (
                    ''
                )}
            </Panel>
        </Collapse>
    )
}

export default AttributeUnitControl
