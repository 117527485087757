import { Button, Layout, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { globeIcon, plusIcon } from '../../constants/media'
const { Content } = Layout
const { Title } = Typography
function LanguageBanner() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <Content className='flex items-center flex-col'>
            <img src={globeIcon} className='!my-2' alt='globeIcon' />
            <Title level={3} className='!mt-1 !mb-[1.25rem]'>
                {t('languages:customers_preferred_language')}
            </Title>
            <p className='w-[80%] text-center !text-[#637381] '>{t('languages:cost_free_process')}</p>
            <Button
                className='app-btn-primary !my-[1.5rem] !flex !justify-items-center'
                onClick={() => navigate('/dashboard/languages/language-settings')}>
                <img src={plusIcon} className='!text-xs !w-3 my-1 mr-2 !items-center' alt='plusIcon' />
                <div className='mr-[10px]'>{t('languages:add_language')}</div>
            </Button>
        </Content>
    )
}

export default LanguageBanner
