import React from 'react'

const DownloadIcon = () => {
    return (
        <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M13.1242 10.0508C12.8617 10.0508 12.6211 10.2695 12.6211 10.5539V12.5008C12.6211 12.6977 12.468 12.8508 12.2711 12.8508H1.72734C1.53047 12.8508 1.37734 12.6977 1.37734 12.5008V10.5539C1.37734 10.2914 1.15859 10.0508 0.874219 10.0508C0.589844 10.0508 0.371094 10.2695 0.371094 10.5539V12.5008C0.371094 13.2445 0.961719 13.8352 1.70547 13.8352H12.2711C13.0148 13.8352 13.6055 13.2445 13.6055 12.5008V10.5539C13.6273 10.2695 13.3867 10.0508 13.1242 10.0508Z'
                fill='#637381'
            />
            <path
                d='M6.64895 10.575C6.73645 10.6625 6.8677 10.7062 6.99895 10.7062C7.1302 10.7062 7.23957 10.6625 7.34895 10.575L10.4771 7.53437C10.6739 7.3375 10.6739 7.03125 10.4989 6.83437C10.3021 6.6375 9.99582 6.6375 9.79895 6.8125L7.50207 9.06562V2.6125C7.50207 2.35 7.28332 2.10938 6.99895 2.10938C6.73645 2.10938 6.49582 2.32813 6.49582 2.6125V9.06562L4.19895 6.83437C4.00207 6.6375 3.69582 6.65937 3.49895 6.83437C3.30207 7.03125 3.32395 7.3375 3.49895 7.53437L6.64895 10.575Z'
                fill='#637381'
            />
        </svg>
    )
}

export default DownloadIcon
