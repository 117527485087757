import {
    FileTextOutlined,
    LineChartOutlined,
    LoadingOutlined,
    ReloadOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Avatar, Badge, Button, Divider, Empty, Progress, Skeleton, Table, Tooltip, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import StatisticsIcon from '../../assets/images/common/statistics.svg'
import DashboardContentHeader from '../../components/header/DashboardContentHeader'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import {
    fnContentSettingInfo,
    fnStoreLogoInfo,
    fnStoreLogoLoading,
} from '../../services/redux/actions/ActionContentSetting'
import RefreshIcon from '../../assets/images/common/refresh.svg'
import util from '../../util/common'
import TopProductGraph from './TopProductGraph'
import OrdersIcon from '../../assets/images/common/ordersIcon.svg'

import { Pie } from '@ant-design/plots'
import { useLocation } from 'react-router-dom'
import InactiveShop from '../../assets/images/common/Inactive_shop.png'
import ProductTemplateIcon from '../../assets/images/common/ProductTemplate.png'
import StoreIcon from '../../assets/images/common/Vector.png'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import TotalConversionsGraph from './TotalConversionsGraph'

function DashboardContent({ setIsLoggedIn }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const auth = useAuth()
    const dispatch = useDispatch()
    const location = useLocation()
    const { Title, Text } = Typography

    const selectedLanguageCode = useSelector(
        (state) =>
            state.reducerSelectedLanguage &&
            state.reducerSelectedLanguage.selectedLanguage &&
            state.reducerSelectedLanguage.selectedLanguage.language_code
    )

    const AccessTokenInfo = sessionStorage.getItem('access_token')

    const realmName = util.getClient()
    let token = auth?.user?.access_token
    const currentLanguageId = useSelector((state) => state?.reducerSelectedLanguage?.selectedLanguage?.id)
    const defaultLanguageId = useSelector((state) => state?.reducerDefaultLanguage?.defaultLanguage?.id)
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const [categoryAPIData, setCategoryAPIData] = useState([])
    const [isCategoryDataLoading, setIsCategoryDataLoading] = useState(true)
    const [isCategoryDataNetworkError, setIsCategoryDataNetworkError] = useState(false)
    const [orderAPIData, setOrderAPIData] = useState([])
    const [isOrderDataLoading, setIsOrderDataLoading] = useState(true)
    const [isOrderDataNetworkError, setIsOrderDataNetworkError] = useState(false)

    const [spinLoading, setSpinLoading] = useState(true)
    const [permissionValue, setGetPermissionsData] = useState(util.getPermissionData() || [])
    const [storeID, setStoreID] = useState()
    const [langDirection, setLangDirection] = useState('ltr')
    // const AccessTokenInfo = sessionStorage.getItem("access_token");
    const [username, setUsername] = useState('')
    const [ordersDatsource, setOrdersDatasource] = useState([])
    const [reloadPerformance, setReloadPerformance] = useState()
    const [maxLimits, setMaxLimits] = useState([])
    const [dashboardData, setDashboardData] = useState()
    const [dashboardDataLoading, setDashboardDataLoading] = useState(true)
    const [dashboardDataNetWorkError, setDashboardDataNetWorkError] = useState(false)
    const [vendorLoader, setVendorLoader] = useState(false)
    //! Global Variables
    const dm4sightBaseURL = process.env.REACT_APP_4SIGHT_BASE_URL
    const dm4sightGetWidgetIdAPI = process.env.REACT_APP_4SIGHT_GETWIDGETID_API
    const dm4sightGetGraphDataAPI = process.env.REACT_APP_4SIGHT_GETGRAPHDATA_API
    const dm4sightClientID = process.env.REACT_APP_4SIGHT_CLIENT_ID
    const productTemplateActiveAPI = process.env.REACT_APP_ACTIVE_PRODUCT_TEMPLATE_API
    const storeLanguageAPI = process.env.REACT_APP_STORE_LANGUAGE_API

    const isLoggedInURL = process.env.REACT_APP_ISLOGGEDIN
    // const getPermissionsUrl = process.env.REACT_APP_PERMISSIONS;
    const getAccessTokenUrl = process.env.REACT_APP_ACCESSTOKEN
    const multilingualFunctionalityEnabled = process.env.REACT_APP_IS_MULTILINGUAL_ENABLED
    // const storeLimitAPI = process.env.REACT_APP_STORE_LIMIT;
    const maxLimitAPI = process.env.REACT_APP_4SIGHT_GETMAXLIMITDETAILS_API
    const storeAdminDashboardAPI = process.env.REACT_APP_STORE_ADMIN_DASHBOARD_DATA_API
    const categoryAPI = process.env.REACT_APP_CATEGORY_API
    const orderListAPI = process.env.REACT_APP_ORDERS_LIST_API
    const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
    const canvasIframeStaticURL = process.env.REACT_APP_CANVAS_IFRAME_STATIC_URL
    const umsBaseUrl = process.env.REACT_APP_USM_BASE_URL
    const getPermissionsUrl = process.env.REACT_APP_USER_PROFILE_API
    const contentSettingAPI = process.env.REACT_APP_CONTENT_SETTING_API
    const storeImageAPI = process.env.REACT_APP_STORE_IMAGE_API
    const dm4sightEnabled = process.env.REACT_APP_4SIGHT_DATA_ENABLED
    const getLastOrdersAPI = process.env.REACT_APP_4SIGHT_GETLASTORDERS_API

    usePageTitle(`${t('common:store_management_portal')} - ${t('common:dashboard')}`)

    let storeId = contentSettingFromRedux ? contentSettingFromRedux[0].store_id : storeID
    const instance = axios.create()
    delete instance.defaults.headers.common['Authorization']

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
                display: 'none',
            }}
            spin
        />
    )

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    const getPermissions = (logginValue) => {
        let baseurl = `${umsBaseUrl}${getPermissionsUrl}`
        MarketplaceServices.findAll(baseurl, null, false)
            .then((res) => {
                console.log('Server response from getPermissions API is', baseurl, res.data)
                setUsername(res.data.response_body.name)
                console.log('permission accessing', res.data.resource_access.res.data['realm-name'] + '-client')
                var realmNameClient = res.data.response_body['realm-name'] + '-client'
                setGetPermissionsData(res.data.response_body.resource_access[`${realmNameClient}`].roles)
                util.setPermissionData(res.data.response_body.resource_access[`${realmNameClient}`].roles)
                setSpinLoading(false)
                setIsLoggedIn(logginValue)
            })
            .catch((err) => {
                console.log('Error Server response from getPermissions API is', baseurl, err.response)
            })
    }
    const getDashBoardData = () => {
        MarketplaceServices.findAllWithoutPage(storeAdminDashboardAPI, null, true)
            .then((response) => {
                console.log(
                    'server response from getDashBoardData is',
                    storeAdminDashboardAPI,
                    response.data.response_body
                )
                setDashboardData(response.data.response_body)
                setDashboardDataLoading(false)
            })
            .catch((error) => {
                console.log(
                    'error Server response from getDashBoardData is--->',
                    storeAdminDashboardAPI,
                    error.response
                )
                setDashboardDataLoading(false)
                setDashboardDataNetWorkError(true)
            })
    }

    const getTopVendorData = async (store_id) => {
        setVendorLoader(true)
        try {
            const getWidgetIdRes = await instance.post(
                dm4sightBaseURL + dm4sightGetWidgetIdAPI,
                {
                    names: ['top_vendors_smp'],
                },
                dm4sightHeaders
            )
            const topVendorsWidgetID = getWidgetIdRes.data.widget_details.data[0].id
            const baseurl =
                dm4sightBaseURL +
                dm4sightGetGraphDataAPI +
                `?widgetId=${topVendorsWidgetID}&filter_col=store_id&filter_val=${store_id}`

            const res = await instance.post(baseurl, {}, dm4sightHeaders)

            const vendorData = res.data.data.data[0].slice(0, 4).map((item, index) => ({
                ...item,
                id: index + 1,
            }))

            const vendorId = vendorData.map((ele) => ele.vendor_id)
            console.log('vendorId', vendorId)

            const dispNames = await instance({
                url:
                    dm4sightBaseURL +
                    `/source/getVendordisplayName?id=${vendorId}&lang_id=${currentLanguageId}&default_lang_id=${defaultLanguageId}`,
                method: 'get',
                headers: {
                    token: token,
                    realmname: realmName,
                    dmClientId: dm4sightClientID,
                    client: 'store',
                },
            })

            let displayNamesData = dispNames.data.data[0]

            //merge both arrays
            let mergedArray = vendorData.map((vendor) => {
                const quantityData = displayNamesData.find((ele) => ele.vendor_id === vendor.vendor_id)
                return { ...vendor, ...quantityData }
            })
            mergedArray = mergedArray.filter((ele) => ele.display_name)
            mergedArray.forEach((obj) => {
                obj.display_name = (
                    <Tooltip title={obj.display_name}>
                        <Text className='!text-[#637381] !font-normal'>
                            {obj.display_name?.length <= 10 ? obj.display_name : obj.display_name.slice(0, 10) + '...'}
                        </Text>
                    </Tooltip>
                )
                // <div className=' !text-[#637381]'></div>
                obj.count_quantity = <div className=' !text-[#637381]'>{obj.count_quantity}</div>
            })
            return mergedArray
        } catch (error) {
            throw new Error('Error fetching top vendor data')
        } finally {
            setVendorLoader(false)
        }
    }
    //! get call of categories lists
    const findByPageCategories = () => {
        MarketplaceServices.findByPage(categoryAPI, null, null, 20, true)
            .then(function (response) {
                console.log('Server response from findByPageCategories is ', categoryAPI, response.data.response_body)
                if (response.data.response_body.category_data.length > 0) {
                    setCategoryAPIData(response.data.response_body.category_data)
                }
                setIsCategoryDataLoading(false)
                setIsCategoryDataNetworkError(false)
            })
            .catch(function (error) {
                console.log('Server Error response from findByPageCategories is', categoryAPI, error)
                setIsCategoryDataLoading(false)
                setIsCategoryDataNetworkError(true)
            })
    }
    //!Get Call for List Orders
    const findByPageOrders = () => {
        MarketplaceServices.findByPage(orderListAPI, null, 1, 10, false)
            .then(function (response) {
                console.log('Server Response from findByPageOrders', orderListAPI, response.data.response_body)
                setOrderAPIData(response.data.response_body.Orders.slice(0, 5))
                setIsOrderDataLoading(false)
                setIsOrderDataNetworkError(false)
            })
            .catch(function (error) {
                console.log('Error ServerResponse from findByPageOrders', orderListAPI, error.response)
                setIsOrderDataLoading(false)
                setIsOrderDataNetworkError(true)
            })
    }
    //! get content Setting Data
    const getContentSetting = () => {
        MarketplaceServices.findAll(contentSettingAPI, null, false)
            .then(function (response) {
                console.log('server Response from getContentSetting API is', contentSettingAPI, response.data)
                setStoreID(response.data.response_body.store_settings_data[0].store_id)
                dispatch(fnContentSettingInfo(response.data.response_body.store_settings_data))
            })
            .catch(function (error) {
                console.log('Error Server Response from getContentSetting is', contentSettingAPI, error.response)
            })
    }
    //! store logo from api
    const getStoreLogo = () => {
        dispatch(fnStoreLogoLoading(true))
        MarketplaceServices.findAllWithoutPage(storeImageAPI, null, false)
            .then(function (response) {
                console.log('Server Response from getStoreLogo API is ', storeImageAPI, response.data)
                dispatch(fnStoreLogoLoading(false))
                if (response?.data?.response_body) {
                    dispatch(fnStoreLogoInfo(response.data.response_body?.store_logo_path))
                }
            })
            .catch(function (error) {
                dispatch(fnStoreLogoLoading(false))
                console.log('Error Server Response from getStoreLogo API is ', storeImageAPI, error.response)
            })
    }

    useEffect(() => {
        if (auth && auth.user && auth.user?.access_token) {
            util.setAuthToken(auth.user?.access_token)
            util.setRefreshToken(auth.user?.refresh_token)
            util.setIsAuthorized(true)
            getPermissions(auth.isAuthenticated)
        } else {
            util.removeAuthToken()
            util.removeIsAuthorized()
        }
    }, [auth])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (spinLoading) {
            getContentSetting()
            getDashBoardData()
            getStoreLogo()
            // findAllStoreLanguages()
            findByPageCategories()
            findByPageOrders()
        }
    }, [spinLoading])

    const salesReturnsTableHeader = [
        {
            title: `${t('dashboard:payment_type')}`,
            dataIndex: 'order_referance_id',
            key: 'order_referance_id',
            ellipsis: true,
            render: (text, record) => {
                return <h1 className='text-black'>{record.payment_type_name}</h1>
            },
        },
        {
            title: `${t('dashboard:order_id')}`,
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <h1
                        className='text-[#000000
        ] items-center'>
                        {record.id}
                    </h1>
                )
            },
        },
        {
            title: `${t('dashboard:payment_status')}`,
            dataIndex: 'payment_status',
            key: 'payment_status',
            ellipsis: true,
            render: (text, record) => {
                return <h1 className='text-black'>{record.payment_status ? 'Success' : 'Not Success'}</h1>
            },
        },
    ]

    const categoryTableHeader = [
        {
            title: `${t('common:name')}`,
            dataIndex: 'name',
            key: 'name',
            width: '50%',
            ellipsis: true,
            render: (text, record) => {
                // return <h1 className="text-black">{record.name}</h1>;
                return (
                    <Content>
                        <Tooltip title={record ? record.display_name : '-'} overlayStyle={{ zIndex: 1 }}>
                            <Text ellipsis={true} style={{ width: 250 }}>
                                {record.display_name ? record.display_name : '-'}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },
        {
            title: `${t('dashboard:total_product_templates')}`,
            dataIndex: 'status',
            key: 'status',
            width: '30%',
            ellipsis: true,
            render: (text, record) => {
                return <h1 className='text-black'>{record && record.total_product_templates}</h1>
            },
        },
    ]

    const vendorDataColumns = [
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('sales_returns:vendor_name')}
                </Title>
            ),
            dataIndex: 'display_name',
            key: 'display_name',
            ellipsis: true,

            render: (text, record) => {
                return <h1 className='!text-[023047]'>{record && record.display_name}</h1>
            },
        },
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('common:orders')}
                </Title>
            ),
            dataIndex: 'orders',
            key: 'orders',
            ellipsis: true,
            render: (text, record) => {
                return <h1 className='!text-[023047]'>{record && record.count_quantity}</h1>
            },
        },
    ]

    const {
        data: topVendorData,
        isLoading: isLoadingVendors,
        isRefetching: isRefetchingVendors,
    } = useQuery({
        queryKey: ['topVendorData', storeId],
        queryFn: () => getTopVendorData(storeId),
        enabled: dm4sightEnabled === 'true',
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24, //24hrs in milliseconds
    })

    const categoryStatus = [
        {
            id: 1,
            value: `${t('dashboard:active')}`,
        },
        {
            id: 2,
            value: `${t('dashboard:inactive')}`,
        },
    ]

    const dm4sightHeaders = {
        headers: {
            token: token,
            realmname: realmName,
            dmClientId: dm4sightClientID,
            client: 'store',
        },
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    //! get call of product templates
    // const findAllProductTemplates = () => {
    //   let temp = {
    //     "product-template-status": "all",
    //   };
    //   MarketplaceServices.findAll(productTemplateActiveAPI, temp, true)
    //     .then(function (response) {
    //       console.log(
    //         "Server response from findAllProductTemplates API is",
    //         // productTemplateActiveAPI,
    //         response.data.response_body?.product_template_data?.filter(
    //           (ele) => ele.product_status_display_name === "InActive"
    //         )
    //       );

    //       // console.log(
    //       //   "response.data.response_body",
    //       //   response.data.response_body?.product_template_data?.filter(
    //       //     (ele) => ele.product_status_display_name === "InActive"
    //       //   )
    //       // );
    //       // setProductCount(response.data.response_body.count);
    //       // let data = getParentChildrenDataForProductParentTemplate(
    //       //   response.data.response_body.product_template_data
    //       // );
    //       // setListProductTemplatesCopy(data[0].localProductTemplateData);
    //       // setIsLoadingListProductTemplates(false);
    //     })
    //     .catch(function (error) {
    //       console.log(
    //         "Error Server response from findAllProductTemplates API is",
    //         productTemplateActiveAPI,
    //         error.response
    //       );
    //       // setIsLoadingListProductTemplates(false);
    //       // setIsNetworkErrorListProductTemplates(true);
    //     });
    // };

    useEffect(() => {
        if (auth && auth.user && auth.user?.access_token) {
            util.setAuthToken(auth.user?.access_token)
            util.setRefreshToken(auth.user?.refresh_token)
            util.setIsAuthorized(true)
            getPermissions(auth.isAuthenticated)
        } else {
            util.removeAuthToken()
            util.removeIsAuthorized()
        }
    }, [auth])

    const getLastOrders = async () => {
        try {
            const response = await instance({
                url: dm4sightBaseURL + getLastOrdersAPI,
                method: 'get',
                headers: {
                    token: token,
                    realmname: realmName,
                    dmClientId: dm4sightClientID,
                    client: 'store',
                },
            })

            let resLastOrders = response.data.data?.[0]
            resLastOrders = resLastOrders.reverse()

            let updatedData = resLastOrders
                ? resLastOrders.map((item, index) => {
                      // item.created_on is a Unix timestamp in milliseconds
                      return {
                          date: (
                              <div className=' !text-[#637381]'>
                                  {new Date(item.created_on).toString().split(' ').slice(0, 5).join(' ')}
                              </div>
                          ),
                          order_id: <div className='font-semibold !text-[#FB8500]'>{item.order_id || 'null'}</div>,

                          customer_name: <div className=' !text-[#637381]'>{item.customer_name || 'null'}</div>,
                          amount: <div className=' !text-[#637381]'>{item.amount || 0}</div>,
                          order_status: item.status ? (
                              <span className='flex items-center justify-start gap-2'>
                                  <div
                                      className=' !text-[#637381]'
                                      style={{
                                          backgroundColor:
                                              item.status === 'Fulfilled'
                                                  ? '#52C41A'
                                                  : item.status === 'InProgress'
                                                    ? '#1890FF'
                                                    : item.status === 'Received'
                                                      ? '#13C2C2'
                                                      : item.status === 'Cancelled'
                                                        ? '#F5222D'
                                                        : item.status === 'Refund'
                                                          ? '#FAAD14'
                                                          : item.status === 'Return' && '#8C8C8C',
                                          width: '6px',
                                          height: '6px',
                                          borderRadius: '10px',
                                          border: '1px',
                                      }}></div>
                                  <div className=' !text-[#637381]'>
                                      {item.status === 'Fulfilled'
                                          ? t('common:fulfilled')
                                          : item.status === 'InProgress'
                                            ? t('common:in_progress')
                                            : item.status === 'Received'
                                              ? t('common:received')
                                              : item.status === 'Cancelled'
                                                ? t('coupler:cancelled')
                                                : item.status === 'Refund'
                                                  ? t('coupler:refund')
                                                  : item.status === 'Return' && t('common:return')}
                                  </div>{' '}
                              </span>
                          ) : (
                              'null'
                          ),

                          payment_type: (
                              <div className=' !text-[#637381]'>
                                  {item.online_payment_connector == null && item.amount == 0
                                      ? 'NA'
                                      : item.online_payment_connector == null && item.amount > 0
                                        ? 'COD'
                                        : item.online_payment_connector}
                              </div>
                          ),
                      }
                  })
                : []

            setOrdersDatasource(updatedData)
        } catch (err) {
            console.log('err')
        }
    }

    useEffect(() => {
        getLastOrders()
        getMaxLimitDetails()
    }, [])

    const getMaxLimitDetails = async () => {
        try {
            const response = await instance({
                url: dm4sightBaseURL + maxLimitAPI,
                method: 'get',
                headers: {
                    token: token,
                    realmname: realmName,
                    dmClientId: dm4sightClientID,
                    client: 'store',
                },
            })
            console.log('resf', response)
            // let resLastOrders = response
            setMaxLimits(response.data.data?.[0]?.[0])

            // setOrdersDatasource(updatedData);
        } catch (err) {
            console.log('err')
        }
    }

    let dashboardStats = [
        {
            id: '1',
            title: `${t('dashboard:store')}`,
            value: '00',
            bgStyle: 'bg-primary',
        },
        {
            id: '2',
            title: `${t('common:vendors')}`,
            value: '00',
            bgStyle: 'bg-info',
        },
        {
            id: '3',
            title: `${t('common:categories')}`,
            value: '00',
            bgStyle: 'bg-warning',
        },
        {
            id: '4',
            title: `${t('common:products')}`,
            value: '00',
            bgStyle: 'bg-danger',
        },
    ]

    const salesOrdersTableHeader = [
        {
            title: `${t('dashboard:order_id')}`,
            dataIndex: 'order_id',
            key: 'order_id',
            ellipsis: true,

            render: (text, record) => {
                return (
                    <h1
                        className='text-[#000000
        ] items-center'>
                        {record.order_id}
                    </h1>
                )
            },
        },
        {
            title: `${t('dashboard:payment_type')}`,
            dataIndex: 'reference_id',
            key: 'reference_id',
            ellipsis: true,

            render: (text, record) => {
                return <h1 className='text-black'>{record.payment_type}</h1>
            },
        },
        {
            title: `${t('dashboard:order_status')}`,
            dataIndex: 'order_status',
            key: 'order_status',
            ellipsis: true,

            render: (text, record) => {
                return <h1 className='text-black'>{record.order_status_display_name}</h1>
            },
        },
    ]

    const productsStatusData = [
        {
            type: t('common:draft'),
            value: dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Draft')?.[0]?.count,
        },
        {
            type: t('dashboard:awaiting'),
            value: dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Submitted')?.[0]?.count,
        },
        {
            type: t('common:rejected'),
            value: dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Rejected')?.[0]?.count,
        },

        {
            type: t('common:unpublished'),
            value: dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Unpublished')?.[0]?.count,
        },
    ]
    const ProductTemplatesStatusData = [
        {
            type: t('common:draft'),
            value: dashboardData?.template_revision_data?.template_revisions?.filter(
                (ele) => ele.status === 'Draft'
            )?.[0]?.count,
        },
        {
            type: t('dashboard:awaiting'),
            value: dashboardData?.template_revision_data?.template_revisions?.filter(
                (ele) => ele.status === 'Submitted'
            )?.[0]?.count,
        },
        {
            type: t('common:rejected'),
            value: dashboardData?.template_revision_data?.template_revisions?.filter(
                (ele) => ele.status === 'Rejected'
            )?.[0]?.count,
        },

        {
            type: t('product_types:deprecated'),
            value: dashboardData?.template_revision_data?.template_revisions?.filter(
                (ele) => ele.status === 'Deprecated'
            )?.[0]?.count,
        },
    ]

    const productsConfig = {
        appendPadding: 10,
        data: productsStatusData,
        angleField: 'value',
        colorField: 'type',
        // content: "{value}",
        radius: 1,
        innerRadius: 0.6,
        legend: {
            layout: 'vertical',
            position: 'left',
            //  langDirection == "rtl" ? "right" : "left",
            offsetX: 10,

            style: {
                marginLeft: '320px',
                paddingLeft: '234px',
            },

            // custom: true,
            // items: [
            //     {
            //         value: 'Draft',
            //         name:
            //             'Draft - ' +
            //                 dashboardData?.products_data?.products?.filter((ele) => ele.status === 'Draft')?.[0]
            //                     ?.count || 0,

            //         marker: { style: { fill: '#FCC32A' } },
            //     },
            //     {
            //         value: 'Rejected',
            //         name:
            //             'Rejected - ' +
            //             dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Rejected')?.[0]?.count,
            //         marker: { style: { fill: '#1890FF' } },
            //     },

            //     {
            //         value: 'Awaiting',
            //         name:
            //             'Awaiting - ' +
            //             dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Submitted')?.[0]?.count,
            //         marker: { style: { fill: '#A0D911' } },
            //     },

            //     {
            //         value: 'Deprecated',
            //         name:
            //             'Deprecated - ' +
            //             dashboardData?.products_data?.products?.filter((ele) => ele.status == 'Unpublished')?.[0]
            //                 ?.count,
            //         marker: { style: { fill: '#D4380D' } },
            //     },
            // ],
        },
        label: {
            type: 'outer',
            content: '{value}',
            style: {
                fontSize: 14,
            },
            layout: [{ type: 'pie-spider' }],
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
        statistic: {
            title: false,
            content: {
                style: {
                    marginLeft: '-27%',
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '20',
                    width: '0px',
                },
            },
        },
        color: ['#1890FF', '#FAAD14', '#FF4D4F', '#000000'],
    }
    const productTemplatesConfig = {
        appendPadding: 10,
        data: ProductTemplatesStatusData,
        angleField: 'value',
        colorField: 'type',
        // content: "{value}",
        radius: 1,
        innerRadius: 0.6,

        legend: {
            layout: 'vertical',
            position: 'left',
            //  langDirection == "rtl" ? "right" : "left",
            offsetX: 10,

            style: {
                marginLeft: '320px',
                paddingLeft: '234px', // Adjust the margin according to your needs
            },

            // custom: true,
            // items: [
            //     {
            //         value: 'Draft',
            //         name:
            //             'Draft - ' +
            //                 dashboardData?.template_revision_data?.template_revisions?.filter(
            //                     (ele) => ele.status === 'Draft'
            //                 )?.[0]?.count || 0,

            //         marker: { style: { fill: '#FCC32A' } },
            //     },
            //     {
            //         value: 'Rejected',
            //         name:
            //             'Rejected - ' +
            //             dashboardData?.template_revision_data?.template_revisions?.filter(
            //                 (ele) => ele.status === 'Rejected'
            //             )?.[0]?.count,
            //         marker: { style: { fill: '#1890FF' } },
            //     },

            //     {
            //         value: 'Awaiting',
            //         name:
            //             'Awaiting - ' +
            //             dashboardData?.template_revision_data?.template_revisions?.filter(
            //                 (ele) => ele.status === 'Submitted'
            //             )?.[0]?.count,
            //         marker: { style: { fill: '#A0D911' } },
            //     },

            //     {
            //         value: 'Deprecated',
            //         name:
            //             'Deprecated - ' +
            //             dashboardData?.template_revision_data?.template_revisions?.filter(
            //                 (ele) => ele.status === 'Deprecated'
            //             )?.[0]?.count,
            //         marker: { style: { fill: '#D4380D' } },
            //     },
            // ],
        },
        label: {
            type: 'outer',
            content: '{value}',
            style: {
                fontSize: 14,
            },
            layout: [{ type: 'pie-spider' }],
        },
        interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '20',
                    width: '0px',
                },
            },
        },
        color: ['#1890FF', '#FAAD14', '#FF4D4F', '#000000'],
    }

    // const orderTableData = [
    //   {
    //     key: 1,
    //     reference_id: 1,
    //     order_id: 100,
    //     order_status: `${t("dashboard:placed")}`,
    //   },
    //   {
    //     key: 2,
    //     reference_id: 2,
    //     order_id: 101,
    //     order_status: `${t("dashboard:placed")}`,
    //   },
    //   {
    //     key: 3,
    //     reference_id: 3,
    //     order_id: 102,
    //     order_status: `${t("dashboard:delivered")}`,
    //   },
    //   {
    //     key: 4,
    //     reference_id: 4,
    //     order_id: 103,
    //     order_status: `${t("dashboard:initiated")}`,
    //   },
    //   {
    //     key: 5,
    //     reference_id: 5,
    //     order_id: 104,
    //     order_status: `${t("dashboard:delivered")}`,
    //   },
    // ];

    // useEffect(() => {
    //   if (AccessTokenInfo) {
    //     // if (!contentSettingFromRedux || contentSettingFromRedux.length === 0) {
    //     // getContentSetting();
    //     // }
    //   }
    // }, [AccessTokenInfo]);

    const ordersColumns = [
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('transactions:date')}
                </Title>
            ),
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('transactions:line_item_id')}
                </Title>
            ),
            dataIndex: 'order_id',
            key: 'order_id',
        },
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('transactions:customer_name')}
                </Title>
            ),
            dataIndex: 'customer_name',
            key: 'customer_name',
        },
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('transactions:amount')}
                </Title>
            ),
            dataIndex: 'amount',
            key: 'amount',
        },

        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('common:status')}
                </Title>
            ),
            dataIndex: 'order_status',
            key: 'order_status',
        },
        {
            title: (
                <Title className='!text-[#023047]' level={5}>
                    {t('dashboard:payment_type')}
                </Title>
            ),
            dataIndex: 'payment_type',
            key: 'payment_type',
        },
    ]

    return (
        // <Content>
        //     <DashboardContentHeader />
        //     <Content className='p-3'>
        //         <Content className='flex'>
        //             {dashboardDataLoading ? (
        //                 <Content className='shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl'>
        //                     <Skeleton paragraph={{ rows: 4 }} />
        //                 </Content>
        //             ) : dashboardDataNetWorkError ? (
        //                 <div className='shadow-sm!w-[30%] p-3 bg-[#FFFFFF] rounded-2xl'>
        //                     <p>{t('common:network_error')}</p>
        //                 </div>
        //             ) : dashboardData ? (
        //                 <Content className='shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl '>
        //                     <div>
        //                         <Content className='flex justify-between align-items-center [w-100%]'>
        //                             <Text className='!text-base'>{t('common:product_templates')}</Text>
        //                             {dashboardData &&
        //                             dashboardData.product_template_data.total_product_template === 0 ? null : (
        //                                 <Button className='app-btn-link' type='link'>
        //                                     <p
        //                                         className={`!text-md  hover:cursor-pointer ${
        //                                             langDirection === 'rtl' ? '!float-left' : '!float-right'
        //                                         }`}
        //                                         onClick={() =>
        //                                             navigate(
        //                                                 '/dashboard/product-template/list-product-templates?' +
        //                                                     MarketplaceAppConfig.getStore('')
        //                                             )
        //                                         }>
        //                                         {t('dashboard:view_all')}
        //                                     </p>
        //                                 </Button>
        //                             )}
        //                         </Content>

        //                         <CustomerGraph
        //                             productTemplateData={dashboardData && dashboardData.product_template_data}
        //                         />
        //                     </div>
        //                 </Content>
        //             ) : null}
        //             {dashboardDataLoading ? (
        //                 <Content className='shadow-sm p-3 !mx-2 !w-[34%] bg-[#FFFFFF] rounded-2xl'>
        //                     <Skeleton paragraph={{ rows: 4 }} />
        //                 </Content>
        //             ) : dashboardDataNetWorkError ? (
        //                 <div className='shadow-sm p-3 !w-[34%] !mx-2 bg-[#FFFFFF] rounded-2xl'>
        //                     <p>{t('common:network_error')}</p>
        //                 </div>
        //             ) : dashboardData ? (
        //                 <Content className='shadow-sm p-3 !w-[34%] !mx-2 bg-[#FFFFFF] rounded-2xl'>
        //                     <div className='flex justify-between align-items-center'>
        //                         <Text className='!text-base'>{t('dashboard:product_overview')}</Text>
        //                         {dashboardData && dashboardData.products_data.total_products === 0 ? null : (
        //                             <Button className='app-btn-link' type='link'>
        //                                 <p
        //                                     className={`!text-md  hover:cursor-pointer ${
        //                                         langDirection === 'rtl' ? '!float-left' : '!float-right'
        //                                     }`}
        //                                     onClick={() =>
        //                                         navigate(
        //                                             '/dashboard/products/list-products?tab=0?' +
        //                                                 MarketplaceAppConfig.getStore('')
        //                                         )
        //                                     }>
        //                                     {t('dashboard:view_all')}
        //                                 </p>
        //                             </Button>
        //                         )}
        //                     </div>
        //                     <VisitorGraph ProductsData={dashboardData && dashboardData.products_data} />
        //                 </Content>
        //             ) : null}
        //             {dashboardDataLoading ? (
        //                 <Content className='shadow-sm p-3  !w-[30%] bg-[#FFFFFF] rounded-2xl'>
        //                     <Skeleton paragraph={{ rows: 4 }} />
        //                 </Content>
        //             ) : dashboardDataNetWorkError ? (
        //                 <div className='shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl'>
        //                     <p>{t('common:network_error')}</p>
        //                 </div>
        //             ) : dashboardData ? (
        //                 <Content
        //                     className='shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl'
        //                     // style={{
        //                     //   width: 313,
        //                     //   height: 236,
        //                     // }}
        //                 >
        //                     <div className='flex justify-between align-items-center'>
        //                         <Text className='!text-base'>{t('dashboard:vendor_overview')}</Text>
        //                         {dashboardData && dashboardData.vendor_data.total_vendors === 0 ? null : (
        //                             <Button className='app-btn-link' type='link'>
        //                                 <p
        //                                     className={`!text-md  hover:cursor-pointer ${
        //                                         langDirection === 'rtl' ? '!float-left' : '!float-right'
        //                                     }`}
        //                                     onClick={() =>
        //                                         navigate(
        //                                             '/dashboard/vendors/list-vendors?page=1&limit=20?' +
        //                                                 MarketplaceAppConfig.getStore('')
        //                                         )
        //                                     }>
        //                                     {t('dashboard:view_all')}
        //                                 </p>
        //                             </Button>
        //                         )}
        //                     </div>
        //                     <Row className='mt-2'>
        //                         <Col>
        //                             <Text
        //                                 className='!text-4xl px-2'
        //                                 style={{
        //                                     color: '#1A5692',
        //                                 }}>
        //                                 {dashboardData && dashboardData.vendor_data.total_vendors}
        //                             </Text>{' '}
        //                         </Col>
        //                         <Col>
        //                             <Text className='text-base !text-black px-2 block font-bold mt-2'>
        //                                 {t('common:vendors')}
        //                             </Text>
        //                         </Col>
        //                     </Row>
        //                     <VendorGraph className='mr-3' vendorData={dashboardData && dashboardData.vendor_data} />
        //                 </Content>
        //             ) : null}
        //         </Content>
        //         <Content className='flex !max-w-[100%] !relative gap-2'>
        //             <Content className='!mt-5 flex w-[32%]'>
        //                 <Content className={'shadow-sm p-3  bg-[#FFFFFF] rounded-2xl'}>
        //                     <div className='flex justify-between align-items-center'>
        //                         <Text className='!text-base'>{t('dashboard:recent_orders')}</Text>
        //                         {orderAPIData && orderAPIData.length > 0 ? (
        //                             <Button className='app-btn-link' type='link'>
        //                                 <p
        //                                     className={`!text-md  hover:cursor-pointer ${
        //                                         langDirection === 'rtl' ? '!float-left' : '!float-right'
        //                                     }`}
        //                                     onClick={() =>
        //                                         parseInt(orderTabId) === 1
        //                                             ? navigate(
        //                                                   '/dashboard/orders/sales-orders?page=1&limit=20?' +
        //                                                       MarketplaceAppConfig.getStore('')
        //                                               )
        //                                             : navigate(
        //                                                   '/dashboard/orders/sales-returns?page=1&limit=20?' +
        //                                                       MarketplaceAppConfig.getStore('')
        //                                               )
        //                                     }>
        //                                     {t('dashboard:view_all')}
        //                                 </p>
        //                             </Button>
        //                         ) : null}
        //                     </div>
        //                     <Content className='!mt-4'>
        //                         <Content>
        //                             {isOrderDataLoading ? (
        //                                 <Skeleton paragraph={{ rows: 6 }} />
        //                             ) : isOrderDataNetworkError ? (
        //                                 <div>
        //                                     <p>{t('common:network_error')}</p>
        //                                 </div>
        //                             ) : orderAPIData && orderAPIData.length > 0 ? (
        //                                 <Table
        //                                     className='py-2'
        //                                     dataSource={orderAPIData}
        //                                     columns={
        //                                         parseInt(orderTabId) === 1
        //                                             ? salesOrdersTableHeader
        //                                             : salesReturnsTableHeader
        //                                     }
        //                                     pagination={false}
        //                                 />
        //                             ) : (
        //                                 <Content>
        //                                     <Empty description={t('dashboard:orders_not_available')} />
        //                                 </Content>
        //                             )}
        //                         </Content>
        //                     </Content>
        //                 </Content>
        //             </Content>
        //             <Content className={'shadow-sm p-3 !mt-5 bg-[#FFFFFF] rounded-2xl w-[33%] '}>
        //                 <div className='flex justify-between align-items-center'>
        //                     <Text className='!text-base'>{t('dashboard:top_categories')}</Text>
        //                     {dashboardData && dashboardData.category_data.top_category.length > 0 ? (
        //                         <Button className='app-btn-link' type='link'>
        //                             <p
        //                                 className={`!text-md  hover:cursor-pointer ${
        //                                     langDirection === 'rtl' ? '!float-left' : '!float-right'
        //                                 }`}
        //                                 onClick={() =>
        //                                     navigate(
        //                                         '/dashboard/categories/list-category?' +
        //                                             MarketplaceAppConfig.getStore('')
        //                                     )
        //                                 }>
        //                                 {t('dashboard:view_all')}
        //                             </p>
        //                         </Button>
        //                     ) : null}
        //                 </div>
        //                 <Content className='!mt-5'>
        //                     {dashboardDataLoading ? (
        //                         <Skeleton paragraph={{ rows: 6 }} />
        //                     ) : dashboardDataNetWorkError ? (
        //                         <div>
        //                             <p>{t('common:network_error')}</p>
        //                         </div>
        //                     ) : dashboardData && dashboardData.category_data.top_category.length > 0 ? (
        //                         <Content>
        //                             <Table
        //                                 className='py-2 rounded-none'
        //                                 dataSource={
        //                                     dashboardData &&
        //                                     dashboardData.category_data &&
        //                                     dashboardData.category_data.top_category
        //                                 }
        //                                 columns={categoryTableHeader}
        //                                 pagination={false}
        //                             />
        //                         </Content>
        //                     ) : (
        //                         <Content className='-mt-3'>
        //                             <Empty description={t('dashboard:no_categories_available')} />
        //                         </Content>
        //                     )}
        //                 </Content>
        //             </Content>
        //         </Content>

        //         <Content className=' !max-w-[100%] !relative'>
        //             <Content className='flex'>
        //                 <Content className='relative shadow-sm p-3 !ml-0 !mt-5 bg-[#FFFFFF] rounded-2xl w-[75%]'>
        //                     <TopProductGraph storeID={storeID} />
        //                 </Content>

        //                 <Content className='shadow-sm p-3 !ml-2 !mt-5 bg-[#FFFFFF] rounded-2xl w-[25%]'>
        //                     <Title level={4} className='!m-0 !text-black'>
        //                         {t('dashboard:top_vendors')}
        //                     </Title>

        //                     <Content className='!mt-5'>
        //                         {isLoadingVendors || isRefetchingVendors ? (
        //                             <Skeleton active paragraph={{ rows: 6 }} />
        //                         ) : topVendorData?.length === 0 || (!isLoadingVendors && !topVendorData) ? (
        //                             <Content className='mt-5'>
        //                                 <Empty description={t('dashboard:no_vendor_available')} />
        //                             </Content>
        //                         ) : (
        //                             topVendorData &&
        //                             topVendorData?.length > 0 && (
        //                                 <Content>
        //                                     <Table
        //                                         className='py-2 rounded-none'
        //                                         dataSource={topVendorData && topVendorData}
        //                                         columns={vendorDataColumns}
        //                                         pagination={false}
        //                                     />
        //                                 </Content>
        //                             )
        //                         )}
        //                     </Content>
        //                 </Content>
        //             </Content>
        //         </Content>

        //         <Content className='hidden'>
        //             <iframe
        //                 id='canvasIframe'
        //                 src={canvasIframeStaticURL.replace('{realmName}', util.getClient('lower'))}
        //                 title='journey canvas'
        //                 width='100%'
        //                 height='627'
        //                 loading='eager'
        //             />
        //         </Content>
        //     </Content>
        // </Content>

        <Content>
            <DashboardContentHeader />
            <Content className='mb-2'>
                <HeaderForTitle
                    title={
                        <Content className='flex z-20 pb-2 !justify-between '>
                            <Content className='!w-[77%] flex flex-column gap-1'>
                                <Content className='flex items-center gap-1'>
                                    <Title level={3} type='secondary' className='m-0 !text-[#8899A8]'>
                                        {t('messages:hello')}
                                        {', '}
                                    </Title>

                                    <Title level={3} type='secondary' className='m-0 !text-[#023047]'>
                                        {username ? username.slice(0, 1).toUpperCase() + username.slice(1) : ''}
                                    </Title>
                                </Content>

                                <Text className='text-[#8899A8] !font-normal'>
                                    {t('messages:dashboard_welcome_message')}
                                </Text>
                            </Content>
                            <Content className={' !w-[40%] flex flex-col justify-center items-baseline ml-[65px]'}>
                                <Text className='!text-base !font-semibold !text-[#637381] flex gap-1 items-center mt-3'>
                                    <Badge status='success' />
                                    {t('dashboard:active')} {t('common:products')}
                                </Text>
                                <Content className='flex items-end gap-1'>
                                    <Title
                                        style={{ color: '#023047', margin: 0, padding: 0 }}
                                        className='whitespace-nowrap'
                                        level={2}>
                                        {dashboardData && dashboardData?.products_data?.products?.[2]?.count}{' '}
                                    </Title>
                                    <Text level={5} className='text-zinc-400 !font-normal '>
                                        {' '}
                                        {t('dashboard:of')} {maxLimits && maxLimits?.product_limit}{' '}
                                        {t('common:products')} ({t('dashboard:max_allowed')})
                                    </Text>
                                </Content>
                                {dashboardData && (
                                    <Progress
                                        className='mt-0 pt-0'
                                        strokeColor={'#4A2D73'}
                                        style={{ width: '90%', margin: 0, padding: 0 }}
                                        percent={
                                            (dashboardData?.products_data?.products?.[2]?.count /
                                                maxLimits?.product_limit) *
                                            100
                                        }
                                        size='small'
                                        showInfo={false}
                                    />
                                )}
                            </Content>

                            <Divider className='h-auto' type='vertical' />

                            <Content className='!w-[30%]  pl-4 flex flex-col justify-center'>
                                <Content>
                                    <Text className='!text-base !font-semibold !text-[#637381] flex gap-1 items-center mt-3'>
                                        <Badge status='default' />
                                        {t('dashboard:inactive')} {t('common:products')}
                                    </Text>
                                </Content>

                                <Content className='flex items-center gap-1'>
                                    <Title style={{ color: '#00000073' }} className='whitespace-nowrap' level={2}>
                                        {
                                            dashboardData?.products_data?.products?.filter(
                                                (ele) => ele.status == 'Draft'
                                            )?.[0]?.count
                                        }{' '}
                                    </Title>
                                </Content>
                            </Content>
                        </Content>
                    }
                />
            </Content>
            <Content className='p-3'>
                <Content className='flex'>
                    {/* {dashboardDataLoading ? (
        <Content className="shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl">
          <Skeleton paragraph={{ rows: 4 }} />
        </Content>
      ) : dashboardDataNetWorkError ? (
        <div className="shadow-sm!w-[30%] p-3 bg-[#FFFFFF] rounded-2xl">
          <p>{t("common:something_went_wrong")}</p>
        </div>
      ) : dashboardData ? (
        <Content className="shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl ">
          <div>
            <Content className="flex justify-between align-items-center [w-100%]">
              <Text className="!text-base">
                {t("common:product_templates")}
              </Text>
              {dashboardData &&
              dashboardData.product_template_data.total_product_template ===
                0 ? null : (
                <Button className="app-btn-link" type="link">
                  <p
                    className={`!text-md  hover:cursor-pointer ${
                      langDirection == "rtl"
                        ? "!float-left"
                        : "!float-right"
                    }`}
                    onClick={() =>
                      navigate(
                        "/dashboard/product-template/list-product-templates?" +
                          MarketplaceAppConfig.getStore("")
                      )
                    }
                  >
                    {t("dashboard:view_all")}
                  </p>
                </Button>
              )}
            </Content>

            <CustomerGraph
              productTemplateData={
                dashboardData && dashboardData.product_template_data
              }
            />
          </div>
        </Content>
      ) : null} */}
                    {/* {dashboardDataLoading ? (
        <Content className="shadow-sm p-3 !mx-2 !w-[34%] bg-[#FFFFFF] rounded-2xl">
          <Skeleton paragraph={{ rows: 4 }} />
        </Content>
      ) : dashboardDataNetWorkError ? (
        <div className="shadow-sm p-3 !w-[34%] !mx-2 bg-[#FFFFFF] rounded-2xl">
          <p>{t("common:something_went_wrong")}</p>
        </div>
      ) : dashboardData ? (
        <Content className="shadow-sm p-3 !w-[34%] !mx-2 bg-[#FFFFFF] rounded-2xl">
          <div className="flex justify-between align-items-center">
            <Text className="!text-base">
              {t("dashboard:product_overview")}
            </Text>
            {dashboardData &&
            dashboardData.products_data.total_products === 0 ? null : (
              <Button className="app-btn-link" type="link">
                <p
                  className={`!text-md  hover:cursor-pointer ${
                    langDirection == "rtl" ? "!float-left" : "!float-right"
                  }`}
                  onClick={() =>
                    navigate(
                      "/dashboard/products/list-products?tab=0?" +
                        MarketplaceAppConfig.getStore("")
                    )
                  }
                >
                  {t("dashboard:view_all")}
                </p>
              </Button>
            )}
          </div>
          <VisitorGraph
            ProductsData={dashboardData && dashboardData.products_data}
          />
        </Content>
      ) : null} */}
                    {/* {dashboardDataLoading ? (
        <Content className="shadow-sm p-3  !w-[30%] bg-[#FFFFFF] rounded-2xl">
          <Skeleton paragraph={{ rows: 4 }} />
        </Content>
      ) : dashboardDataNetWorkError ? (
        <div className="shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl">
          <p>{t("common:something_went_wrong")}</p>
        </div>
      ) : dashboardData ? (
        <Content
          className="shadow-sm p-3 !w-[30%] bg-[#FFFFFF] rounded-2xl"
          // style={{
          //   width: 313,
          //   height: 236,
          // }}
        >
          <div className="flex justify-between align-items-center">
            <Text className="!text-base">
              {t("dashboard:vendor_overview")}
            </Text>
            {/* {console.log("dashboardData", dashboardData)} */}
                    {/* {dashboardData &&
            dashboardData.vendor_data.total_vendors === 0 ? null : (
              <Button className="app-btn-link" type="link">
                <p
                  className={`!text-md  hover:cursor-pointer ${
                    langDirection == "rtl" ? "!float-left" : "!float-right"
                  }`}
                  onClick={() =>
                    navigate(
                      "/dashboard/vendors/list-vendors?page=1&limit=20?" +
                        MarketplaceAppConfig.getStore("")
                    )
                  }
                >
                  {t("dashboard:view_all")}
                </p>
              </Button>
            )}
          </div>
          <Row className="mt-2">
            <Col>
              <Text
                className="!text-4xl px-2"
                style={{
                  color: "#1A5692",
                }}
              >
                {dashboardData && dashboardData.vendor_data.total_vendors}
              </Text>{" "}
            </Col>
            <Col>
              <Text className="text-base !text-black px-2 block font-bold mt-2">
                {t("common:vendors")}
              </Text>
            </Col>
          </Row>
          <VendorGraph
            className="mr-3"
            vendorData={dashboardData && dashboardData.vendor_data}
          />
        </Content>
      ) : null} */}
                    {/* */}
                    {/* <Content className="shadow-sm p-3 !bg-[#1A5692] rounded-2xl">
        <div className="flex space-x-3">
          <Text className="text-sm !text-[#FFFFFF]">
            {t("dashboard:recent_approval_requests")}
          </Text>
          <p
            className="text-sm text-[#FCC32A] hover:cursor-pointer !float-right"
            onClick={() =>
              navigate(
                "/dashboard/approvals/list-approvals?tab=product_template&status=0&page=1&limit=20"
              )
            }
          >
            {t("dashboard:view_all")}
          </p>
        </div>

        <Content className="flex space-x-2">
          <Text className="text-md !text-[#FFFFFF]">
            {t("dashboard:recent_approval_requests")}
          </Text>
          <p
            className="text-md text-[#FCC32A] hover:cursor-pointer !float-right !ml-3"
            onClick={() => navigate("")}
          >
            {t("dashboard:view_all")}
          </p>
        </Content>

        <div className="mt-2">
          <Content className="flex">
            <Button
              type="primary"
              shape="square"
              icon={
                <GroupOutlined
                  style={{ color: "black", verticalAlign: "0px" }}
                />
              }
              size="large"
              style={{
                borderRadius: "0px",
                backgroundColor: "#87e8de",
              }}
            />
            <Content className="ml-1">
              <Text className="text-md !text-[#FFFFFF] font-medium">
                BT-Connect
              </Text>
              <Badge className="ml-1" count={1 ? 1.1 : 0} />
              <Text className="text-xs !text-[#FFFFFF] ml-3">04/27/23</Text>
            </Content>
            <p
              className="text-sm text-[#FCC32A] hover:cursor-pointer !float-right"
              onClick={() => navigate("")}
            >
              {t("dashboard:view")}
            </p>
          </Content>
        </div>
        <Divider className="bg-[#87e8de] !m-0" />
        <div>
          <Content className="flex mt-1">
            <Button
              type="primary"
              shape="square"
              icon={
                <GroupOutlined
                  style={{ color: "black", verticalAlign: "0px" }}
                />
              }
              size="large"
              style={{
                borderRadius: "0px",
                backgroundColor: "#87e8de",
              }}
            />
            <Content className="ml-1">
              <Text className="text-md !text-[#FFFFFF] font-medium">
                Microsoft Azure
              </Text>
              <Badge className="ml-1" count={1 ? 2.2 : 0} />
              <Text className="!text-xs !text-[#FFFFFF] ">04/27/23</Text>
            </Content>
            <p
              className="text-sm text-[#FCC32A] hover:cursor-pointer !float-right"
              onClick={() => navigate("")}
            >
              {t("dashboard:view")}
            </p>
          </Content>
        </div>
        <Divider className="bg-[#87e8de] !mt-1 !mb-1" />
        <div>
          <Content className="flex">
            <Button
              type="primary"
              shape="square"
              icon={
                <UngroupOutlined
                  style={{ color: "#87e8de", verticalAlign: "0px" }}
                />
              }
              size="large"
              style={{
                borderRadius: "0px",
                backgroundColor: "#002329",
              }}
            />
            <Content className="ml-1">
              <Text className="text-md !text-white font-medium">
                Play Station S
              </Text>
              <Badge className="ml-1" count={1 ? 1.5 : 0} />
              <Text className="!text-xs !text-[#FFFFFF] mt-0 ml-2">
                04/27/23
              </Text>
            </Content>
            <p
              className="text-sm text-[#FCC32A] hover:cursor-pointer !float-right"
              onClick={() => navigate("")}
            >
              {t("dashboard:view")}
            </p>
          </Content>
        </div>
      </Content> */}
                    {/* </Content> */}
                    {/* <Content className="flex !max-w-[100%] !relative gap-2">
      {/* <Content className="shadow-sm p-3 !mt-3 bg-[#FFFFFF] rounded-2xl !mr-3 !max-w-[75%] !relative">
        <Content className="grid grid-cols-2 gap-x-72">
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Typography.Title
                  level={5}
                  className="bg-[000000]"
                  style={{ fontSize: "16px" }}
                >
                  {t("dashboard:top_products")}
                </Typography.Title>
                <DownOutlined
                  style={{
                    color: "#000000",
                    paddingBottom: "11px",
                    fontSize: "9.6px",
                  }}
                />
              </Space>
            </a>
          </Dropdown>
          <Content>
            <Button
              type="text"
              buttonStyle="solid"
              onClick={() => setActive(1)}
              style={
                active === 1
                  ? { backgroundColor: "#1A5692", color: "#FFFFFF" }
                  : { backgroundColor: "#FFFFFF", color: "#0a0a0a" }
              }
            >
              {t("dashboard:daily")}
            </Button>
            <Button
              type="text"
              buttonStyle="solid"
              onClick={() => setActive(2)}
              style={
                active === 2
                  ? { backgroundColor: "#1A5692", color: "#FFFFFF" }
                  : { backgroundColor: "#FFFFFF", color: "#0a0a0a" }
              }
            >
              {t("dashboard:weekly")}
            </Button>
            <Button
              type="text"
              buttonStyle="solid"
              onClick={() => setActive(3)}
              style={
                active === 3
                  ? { backgroundColor: "#1A5692", color: "#FFFFFF" }
                  : { backgroundColor: "#FFFFFF", color: "#0a0a0a" }
              }
            >
              {t("dashboard:monthly")}
            </Button>
          </Content>
        </Content>
        <TopProductGraph />
      </Content> */}
                    {/* <Content className="shadow-sm p-3 !mt-5 bg-[#FFFFFF] rounded-2xl !max-w-[25%] !relative">
        <div className="flex justify-between align-items-center">
          <Text className="text-base !text-[#000000] p-2">
            {t("dashboard:top_vendors")}
          </Text>
          {dashboardData && dashboardData.vendor_order_data.length > 0 ? (
            <Button className="app-btn-link" type="link">
              <p
                className={`!text-md  hover:cursor-pointer ${
                  langDirection == "rtl" ? "!float-left" : "!float-right"
                }`}
                onClick={() =>
                  navigate(
                    "/dashboard/vendors?page=1&limit=20?" +
                      MarketplaceAppConfig.getStore("")
                  )
                }
              >
                {t("dashboard:view_all")}
              </p>
            </Button>
          ) : null}
        </div>
       
        <Content className="!mt-5">
          {dashboardDataLoading ? (
            <Skeleton paragraph={{ rows: 6 }} />
          ) : dashboardDataNetWorkError ? (
            <div>
              <p>{t("common:something_went_wrong")}</p>
            </div>
          ) : dashboardData &&
            dashboardData.vendor_order_data.length > 0 ? (
            <Content>
              <Table
                className="py-2 rounded-none"
                dataSource={
                  dashboardData && dashboardData.vendor_order_data
                }
                columns={vendorDataColumns}
                pagination={false}
              />
            </Content>
          ) : (
            <Content className="-mt-3">
              <Empty description={t("dashboard:no_vendor_available")} />
            </Content>
          )}
        </Content>
      </Content> */}
                    {/* <Content className="!mt-5 flex w-[32%]">
      <Content
        className={`shadow-sm p-3  bg-[#FFFFFF] rounded-2xl ${
          langDirection == "rtl" ? "" : ""
        }`}
      >
        <div className="flex justify-between align-items-center">
          <Text className="!text-base">{t("dashboard:recent_orders")}</Text>
          {orderAPIData && orderAPIData.length > 0 ? (
            <Button className="app-btn-link" type="link">
              <p
                className={`!text-md  hover:cursor-pointer ${
                  langDirection == "rtl" ? "!float-left" : "!float-right"
                }`}
                onClick={() =>
                  parseInt(orderTabId) === 1
                    ? navigate(
                        "/dashboard/orders/sales-orders?page=1&limit=20?" +
                          MarketplaceAppConfig.getStore("")
                      )
                    : navigate(
                        "/dashboard/orders/sales-returns?page=1&limit=20?" +
                          MarketplaceAppConfig.getStore("")
                      )
                }
              >
                {t("dashboard:view_all")}
              </p>
            </Button>
          ) : null}
        </div>
        <Content className="!mt-4">
          {/* <DmTabAntDesign
              tabType={"line"}
              tabBarPosition={"top"}
              tabData={orderTabData}
              handleTabChangeFunction={(value) => tabId(value)}
            /> */}
                    {/* <Content>
            {isOrderDataLoading ? (
              <Skeleton paragraph={{ rows: 6 }} />
            ) : isOrderDataNetworkError ? (
              <div>
                <p>{t("common:something_went_wrong")}</p>
              </div>
            ) : orderAPIData && orderAPIData.length > 0 ? (
              <Table
                className="py-2"
                dataSource={orderAPIData}
                columns={
                  parseInt(orderTabId) === 1
                    ? salesOrdersTableHeader
                    : salesReturnsTableHeader
                }
                pagination={false}
              />
            ) : (
              <Content className="">
                {/* <p>{t("dashboard:orders_not_available")}</p> */}
                    {/* <Empty
                  className="" */}
                    {/* //               description={t("dashboard:orders_not_available")} */}
                    {/* //             />
    //           </Content>
    //         )}
    //       </Content> */}
                    {/* //     </Content>
    //   </Content> */}
                    {/* // </Content>  */}
                    {/* <Content */}
                    {/* //   className={`shadow-sm p-3 !mt-5 bg-[#FFFFFF] rounded-2xl w-[33%] ${ */}
                    {/* //     langDirection == "rtl" ? "" : ""
    //   }`}
    // >
    //   <div className="flex justify-between align-items-center">
    //     <Text className="!text-base">{t("dashboard:top_categories")}</Text>
    //     {dashboardData && */}
                    {/* //     dashboardData.category_data.top_category.length > 0 ? (
    //       <Button className="app-btn-link" type="link">
    //         <p
    //           className={`!text-md  hover:cursor-pointer ${
    //             langDirection == "rtl" ? "!float-left" : "!float-right"
    //           }`} */}
                    {/* //           onClick={() => */}
                    {/* //             navigate(
    //               "/dashboard/categories/list-category?" +
    //                 MarketplaceAppConfig.getStore("")
    //             )
    //           }
    //         >
    //           {t("dashboard:view_all")}
    //         </p>
    //       </Button>
    //     ) : null}
    //   </div> */}
                    {/* //   <Content className="!mt-5">
    //     {dashboardDataLoading ? (
    //       <Skeleton paragraph={{ rows: 6 }} />
    //     ) : dashboardDataNetWorkError ? (
    //       <div>
    //         <p>{t("common:something_went_wrong")}</p>
    //       </div>
    //     ) : dashboardData &&
    //       dashboardData.category_data.top_category.length > 0 ? (
    //       <Content>
    //         <Table
    //           className="py-2 rounded-none"
    //           dataSource={
    //             dashboardData &&
    //             dashboardData.category_data &&
    //             dashboardData.category_data.top_category
    //           } */}
                    {/* //           columns={categoryTableHeader}
    //           pagination={false}
    //         />
    //       </Content>
    //     ) : (
    //       <Content className="-mt-3">
    //         {/* <p>{t("dashboard:category_not_available")}</p> */}
                    {/* //         <Empty description={t("dashboard:no_categories_available")} />
    //       </Content>
    //     )}
    //   </Content>
    // </Content> */}
                    {/* </Content> */}

                    <Content
                        // hidden={dm4sightEnabled === "true" ? false : true}
                        className=' !max-w-[100%] !relative'>
                        <Content className='relative shadow-sm p-[24px] m-1 !mt-5 bg-[#FFFFFF] rounded-2xl w-[99.5%]'>
                            <Title style={{ color: '#023047' }} level={3} type='secondary' className='!m-0 flex gap-2 '>
                                <img
                                    className='w-8 p-1.5  flex items-center justify-center bg-[#EC8B23] rounded	'
                                    src={StatisticsIcon}
                                    alt=''
                                />
                                {t('dashboard:statistics')}
                            </Title>
                            <Divider style={{ width: 'calc(100% + 48px)', marginLeft: '-24px' }} />
                            {dashboardDataLoading ? (
                                <Skeleton rows={'8'} />
                            ) : dashboardData ? (
                                <Content className='flex'>
                                    <Content className='!w-[35%]'>
                                        <Text className='!text-base mb-2 font-semibold text-[#8899A8] flex gap-1 items-center'>
                                            {/* <FileTextOutlined style={{ color: '#52c41a' }} /> */}
                                            <Badge status='success' />
                                            {t('dashboard:active')} {t('common:products')}
                                        </Text>

                                        <Content className='flex items-end gap-1'>
                                            <Title style={{ color: '#023047', margin: 0, padding: 0 }} level={2}>
                                                {dashboardData && dashboardData?.products_data?.products?.[2]?.count}{' '}
                                            </Title>
                                            <Text level={5} className='text-zinc-400 !font-normal '>
                                                {' '}
                                                {t('dashboard:of')} {maxLimits && maxLimits?.product_limit}{' '}
                                                {t('common:products')} ({t('dashboard:max_allowed')})
                                            </Text>
                                        </Content>

                                        {dashboardData && (
                                            <Progress
                                                strokeColor='#52C41A'
                                                style={{ width: '90%', margin: 0, padding: 0 }}
                                                percent={
                                                    (dashboardData?.products_data?.products?.[2]?.count /
                                                        maxLimits?.product_limit) *
                                                    100
                                                }
                                                size='small'
                                                showInfo={false}
                                            />
                                        )}

                                        <Content className='mt-0'>
                                            {/* <Content className="mt-1"> */}
                                            <Content className='mt-0' style={{ height: 120 }}>
                                                <Pie {...productsConfig} />
                                            </Content>
                                            {/* </Content> */}
                                        </Content>
                                    </Content>

                                    <Content className='!w-[35%]'>
                                        <Text className='!text-base mb-2 font-semibold text-[#8899A8]  flex gap-1 items-center'>
                                            {/* <img src={ProductTemplateIcon} className='' alt='ProductTemplateIcon' /> */}
                                            <Badge status='success' />
                                            {t('dashboard:active')} {t('common:product_templates')}
                                        </Text>

                                        <Content className='flex items-end gap-1'>
                                            <Title style={{ color: '#023047', margin: 0, padding: 0 }} level={2}>
                                                {dashboardData &&
                                                    dashboardData?.product_template_data?.product_templates[0]?.count}
                                            </Title>
                                            <Text level={5} className='text-zinc-400 !font-normal '>
                                                {' '}
                                                {t('dashboard:of')} {maxLimits && maxLimits?.product_template_limit}{' '}
                                                {t('common:product_templates')} ({t('dashboard:max_allowed')})
                                            </Text>
                                        </Content>

                                        {dashboardData && (
                                            <Progress
                                                strokeColor='#52C41A'
                                                style={{ width: '90%' }}
                                                percent={
                                                    (dashboardData?.product_template_data?.product_templates[0]?.count /
                                                        maxLimits?.product_template_limit) *
                                                    100
                                                }
                                                size='small'
                                                showInfo={false}
                                            />
                                        )}

                                        <Content className='mt-0' style={{ height: 120 }}>
                                            {/* <Content className="mt-0"> */}
                                            <Content className='mt-0' style={{ height: 120 }}>
                                                <Pie {...productTemplatesConfig} />
                                            </Content>
                                            {/* </Content> */}
                                        </Content>
                                    </Content>

                                    <Content className=' !w-[30%]'>
                                        <Text className='!text-base font-semibold mb-2 text-[#8899A8]  flex gap-1 items-center'>
                                            {/* <img src={StoreIcon} className='store__icon' alt='Store' /> */}
                                            <Badge status='success' />
                                            {t('dashboard:active')} {t('common:vendors')}
                                        </Text>

                                        <Content className='flex items-end gap-1'>
                                            <Title style={{ color: '#023047', margin: 0, padding: 0 }} level={2}>
                                                {dashboardData?.vendor_data?.active_vendors}
                                            </Title>
                                            <Text level={5} className='text-zinc-400 !font-normal flex gap-1 '>
                                                {' '}
                                                {t('dashboard:of')} {maxLimits && maxLimits?.vendor_limit}{' '}
                                                {t('common:vendors')} ({t('dashboard:max_allowed')})
                                            </Text>
                                        </Content>

                                        {dashboardData && (
                                            <Progress
                                                strokeColor='#52C41A'
                                                style={{ width: '90%' }}
                                                percent={
                                                    (dashboardData?.vendor_data?.active_vendors /
                                                        maxLimits?.vendor_limit) *
                                                    100
                                                }
                                                size='small'
                                                showInfo={false}
                                            />
                                        )}
                                        <Text className='!text-base mb-2 font-semibold text-[#8899A8]  flex gap-1 items-center'>
                                            {/* <img src={ProductTemplateIcon} className='' alt='ProductTemplateIcon' /> */}
                                            <Badge status='default' />
                                            {t('dashboard:inactive')} {t('common:vendors')}
                                        </Text>

                                        <Title style={{ color: '#8899A8', margin: 0, padding: 0 }} level={2}>
                                            {dashboardData?.vendor_data?.inactive_vendors}{' '}
                                        </Title>
                                        {/* <Text className='!text-md mb-2 text-zinc-400 flex gap-1 items-center'>
                                            <img src={InactiveShop} className='store__icon' alt='Store' />
                                            {dashboardData?.vendor_data?.inactive_vendors} {t('dashboard:inactive')}{' '}
                                            {t('dashboard:vendors')}
                                        </Text> */}
                                    </Content>
                                </Content>
                            ) : (
                                <Empty />
                            )}
                        </Content>

                        <Content className='w-[99.5%] m-1 mt-4  bg-[#FFFFFF] shadow-sm rounded-2xl'>
                            <div className='flex justify-between p-[24px] pb-0 mb-0'>
                                <Title level={3} type='secondary' className='!m-0  flex gap-2 !text-[#023047]'>
                                    <Avatar
                                        shape='square'
                                        className='flex items-center justify-center bg-cyan-500	'
                                        icon={<LineChartOutlined />}
                                    />
                                    {t('dashboard:performance')}
                                </Title>
                                <div className='flex items-center gap-1'>
                                    <Text type='secondary'> {t('dashboard:last_update')}:</Text>
                                    <Text className='!text-[var(--mp-text-gray1)] font-semibold'>
                                        {t('dashboard:today')}, {sessionStorage.getItem('updated_time')}
                                    </Text>
                                    <Button
                                        className='flex items-center justify-center !text-regal-blue hover:!text-regal-blue !font-semibold '
                                        type='text'
                                        onClick={() => {
                                            setReloadPerformance(Math.random())
                                            getTopVendorData(storeID)
                                        }}
                                        icon={<img src={RefreshIcon} alt='' />}>
                                        {t('dashboard:refresh')}
                                    </Button>
                                </div>
                            </div>

                            <Divider />

                            <Content className='p-[24px] !mt-5 bg-[#FFFFFF]  rounded-2xl'>
                                <TotalConversionsGraph reloadPerformance={reloadPerformance} />
                            </Content>
                            <Divider style={{ width: '95%', margin: '0px auto' }} />
                            <Content className='flex bg-[#FFFFFF] p-[24px] shadow-sm rounded-2xl'>
                                <Content className='w-[70%] '>
                                    <TopProductGraph reloadPerformance={reloadPerformance} storeID={storeID} />
                                </Content>
                                <Divider className='ml-4 mr-4 h-auto' type='vertical' />

                                <Content
                                    // hidden={dm4sightEnabled === "true" ? false : true}
                                    className='!ml-3 bg-[#FFFFFF] rounded-2xl w-[30%]'>
                                    <Title level={4} className='!m-0 !text-[#8899A8]'>
                                        {t('dashboard:top_vendors')}
                                    </Title>

                                    <Content className='!mt-2'>
                                        {isLoadingVendors || vendorLoader ? (
                                            <Skeleton active paragraph={{ rows: 6 }} />
                                        ) : topVendorData?.length == 0 || (!isLoadingVendors && !topVendorData) ? (
                                            <Content className='mt-5'>
                                                <Empty description={t('dashboard:no_vendor_available')} />
                                            </Content>
                                        ) : (
                                            topVendorData &&
                                            topVendorData?.length > 0 && (
                                                <Content>
                                                    <Table
                                                        className='py-2 rounded-none'
                                                        dataSource={topVendorData}
                                                        columns={vendorDataColumns}
                                                        pagination={false}
                                                    />
                                                </Content>
                                            )
                                        )}
                                    </Content>
                                </Content>
                            </Content>
                        </Content>

                        <Content>
                            <Content className='relative shadow-sm p-[24px] m-1 !mt-5 bg-[#FFFFFF] rounded-2xl w-[99.5%]'>
                                <Title
                                    style={{ color: '#023047' }}
                                    level={3}
                                    type='secondary'
                                    className='!m-0 mt-1 flex gap-2'>
                                    <img
                                        className='w-8 p-1.5  flex items-center justify-center bg-[#3054EB] rounded	'
                                        src={OrdersIcon}
                                        alt=''
                                    />

                                    {t('common:orders')}
                                </Title>
                                <Divider style={{ width: 'calc(100% + 48px)', marginLeft: '-24px' }} />
                                <Table dataSource={ordersDatsource} pagination={false} columns={ordersColumns} />
                            </Content>
                        </Content>
                    </Content>

                    {/* <Content className="hidden">
        <iframe
          id="canvasIframe"
          src={canvasIframeStaticURL.replace(
            "{realmName}",
            util.getClient("lower")
          )}
          title="journey canvas"
          width="100%"
          height="627"
          loading="eager"
        />
      </Content> */}
                </Content>
            </Content>
        </Content>
    )
}
export default DashboardContent
