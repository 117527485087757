//! Import libraries
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons'
// import AddIcon from '@mui/icons-material/Add'
import { Button, Divider, Empty, Input, Layout, Row, Space, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { fnCategorySelectedInfo } from '../../services/redux/actions/ActionsCategories'

//! Import user defined components
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
//! Import user defined functions
import { usePageTitle } from '../../hooks/usePageTitle'
import { getParentChildrenDataForCategories } from '../../util/util'

//! Import CSS libraries

//! Import user images
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { MdEdit } from 'react-icons/md'
import { CategoriesImage } from '../../constants/media'
import { AddIcon, DownArrow } from '../../constants/media'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

//! Redux Related Imports

//! Get all required details from .env file
const categoryAPI = process.env.REACT_APP_CATEGORY_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

//! Destructure the components
const { Content } = Layout
const { Text, Title, Paragraph } = Typography
const { Search } = Input

const ListCategories = () => {
    const { t } = useTranslation()
    usePageTitle(`${t('common:store_management_portal')} - ${t('common:categories')}`)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [categoryList, setCategoryList] = useState()
    const [isLoadingCategoryList, setIsLoadingCategoryList] = useState(true)
    const [isNetworkErrorCategoryList, setIsNetworkErrorCategoryList] = useState(false)
    const [totalCategoriesCount, setTotalCategoriesCount] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [categoryId, setCategoryId] = useState()
    const [sendDataLoading, setSendDataLoading] = useState(false)
    const [showCategoryList, setShowCategoryList] = useState(false)
    const [showAddCategoryBtn, setShowAddCategoryBtn] = useState(false)
    const permissionsData = util.getPermissionData() || []
    const [langDirection, setLangDirection] = useState('ltr')
    const [showDeleteCategoryBtn, setShowDeleteCategoryBtn] = useState(false)
    const [isSearchTriggered, setIsSearchTriggered] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [isTableDataWithoutSearchKeyEmpty, setIsTableDataWithoutSearchKeyEmpty] = useState(true)
    const tableHeader = [
        {
            title: <Content className='ml-6'>{t('common:title')}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content>
                        <Tooltip title={record.displayName} overlayStyle={{ zIndex: 1 }} placement='bottom'>
                            <Text
                                ellipsis={true}
                                className={` ${record.children ? '!max-w-[290px] !py-[5px] !text-brandGray1 text-[14px] font-normal' : '!max-w-[290px] !py-[5px] ml-[25px] !text-brandGray1 text-[14px] font-normal'}`}>
                                {record.displayName}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:published_products')}`,
            dataIndex: '',
            key: '',
            width: '20%',
            render: (text, record) => {
                return <p className='!text-brandGray1 text-[14px] font-normal'>{record.publishedProducts}</p>
            },
        },
        {
            title: `${t('categories:sub_categories')}`,
            dataIndex: '',
            key: '',
            width: '20%',
            render: (text, record) => {
                return <p className='!text-brandGray1 text-[14px] font-medium'>{record.SubCategories}</p>
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: '',
            key: '',
            width: '35%',
            render: (text, record) => {
                return (
                    <Row justify='space-between'>
                        <Space size='small'>
                            {/* show/hide add/post option based on permission */}
                            {showAddCategoryBtn && (
                                <Link
                                    to={{
                                        pathname: '/dashboard/categories/add-category',
                                        search: `?pid=${record.id}`,
                                    }}>
                                    <Text className='text-[14px] font-medium app-btn-text '>
                                        {t('categories:add_category')}
                                    </Text>
                                </Link>
                            )}

                            {/* Vertical line divider */}
                            {showAddCategoryBtn && (
                                <Divider type='vertical' className='border-[#0000000F] border-[1px] h-[12px]' />
                            )}

                            {/* show/hide edit option based on permission */}
                            <Link
                                to={{
                                    pathname: `/dashboard/categories/edit-category`,
                                    search: `?cid=${record.id}&${MarketplaceAppConfig.getStore('')}`,
                                }}>
                                <Text className='app-btn-text text-[14px] font-medium'>{t('common:edit')}</Text>
                            </Link>

                            {/* show/hide delete option based on permission */}
                            <Divider type='vertical' className='border-[#0000000F] border-[1px] h-[12px]' />
                            {showDeleteCategoryBtn &&
                                (record.deletable === false ? (
                                    <div className='flex '>
                                        <Tooltip
                                            placement='bottom'
                                            title={
                                                record.deleteMessage.toLowerCase() === 'type1'
                                                    ? `${t('categories:type_one_msg')}`
                                                    : record.deleteMessage.toLowerCase() === 'type2'
                                                      ? `${t('categories:type_two_msg')}`
                                                      : `${t('categories:type_three_msg')}`
                                            }
                                            overlayStyle={{ zIndex: 1 }}>
                                            <Text disabled type='secondary' className='font-medium'>
                                                {t('common:delete')}
                                            </Text>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    <Text
                                        className='text-[#FF4D4F] text-[14px] font-medium cursor-pointer'
                                        onClick={() => openModal(record.id)}>
                                        {t('common:delete')}
                                    </Text>
                                ))}
                        </Space>
                    </Row>
                )
            },
        },
    ]

    //! get call of categories lists
    const findAllCategoryLists = (searchKey) => {
        let params = {}
        if (searchKey) {
            params['search'] = searchKey
            setIsSearchTriggered(true)
        }
        setIsLoadingCategoryList(true)
        MarketplaceServices.findAll(categoryAPI, params, true)
            .then(function (response) {
                console.log('Server response from findAllCategoryLists is', categoryAPI, response.data.response_body)
                setTotalCategoriesCount(response.data.response_body.count)
                if (response.data.response_body.category_data.length > 0) {
                    const parentChildrenData = getParentChildrenDataForCategories(
                        response.data.response_body.category_data
                    )
                    if (parentChildrenData.length > 0) {
                        setCategoryList(parentChildrenData)
                    }
                }
                if (
                    (searchKey === '' || searchKey === undefined) &&
                    response?.data?.response_body?.category_data?.length <= 0
                ) {
                    setIsTableDataWithoutSearchKeyEmpty(true)
                } else {
                    setIsTableDataWithoutSearchKeyEmpty(false)
                }
                setIsLoadingCategoryList(false)
            })
            .catch(function (error) {
                console.log('Server Error response findAllCategoryLists is', categoryAPI, error.response)
                setIsLoadingCategoryList(false)
                setIsNetworkErrorCategoryList(true)
            })
    }

    //! delete the category
    const removeCategory = () => {
        setSendDataLoading(true)
        MarketplaceServices.remove(categoryAPI, {
            id: categoryId,
        })
            .then(function (response) {
                console.log('Server response from removeCategory API is', categoryAPI, response.data)
                setSendDataLoading(false)
                setIsModalVisible(false)
                findAllCategoryLists()
                MarketplaceToaster.showToast(response)
            })
            .catch(function (error) {
                console.log('error Server response from removeCategory API is', categoryAPI, error)
                setSendDataLoading(false)
                setIsModalVisible(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const handleSearch = (searchKey) => {
        setSearchValue(searchKey)
        if (searchKey?.trim()) {
            findAllCategoryLists(searchKey?.trimStart())
        } else {
            if (isSearchTriggered) {
                findAllCategoryLists()
                setIsSearchTriggered(false)
            }
        }
    }
    const handleInputChange = (event) => {
        const trimmed = String(event.target.value)
        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
        setSearchValue(trimmedUpdate)
        if (event.target.value === '') {
            if (isSearchTriggered) {
                findAllCategoryLists()
                setIsSearchTriggered(false)
            }
        }
    }

    //! closing the delete popup model
    const closeModal = () => {
        setIsModalVisible(false)
    }

    //! opening the delete popup model
    const openModal = (categoryId) => {
        setIsModalVisible(true)
        setCategoryId(categoryId)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        util.setStoreNameWhenWeChangeINUrl()
        dispatch(fnCategorySelectedInfo())
        if (permissionsData && permissionsData.includes('UI-create-edit-category')) {
            setShowAddCategoryBtn(true)
        }
        //show/hide delete btn based on permissions
        if (permissionsData && permissionsData.includes('UI-delete-category')) {
            setShowDeleteCategoryBtn(true)
        }
        //show or hide category list based on permissions
        if (permissionsData && permissionsData.includes('UI-list-category')) {
            setShowCategoryList(true)
            findAllCategoryLists()
        } else {
            setIsLoadingCategoryList(false)
            setShowCategoryList(false)
        }
    }, [])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const customButton = (
        <Button type='primary' disabled={searchValue?.trim() === '' ? true : false} icon={<SearchOutlined />} />
    )

    return (
        <Content>
            <Content>
                <HeaderForTitle
                    title={
                        <div className='flex items-center justify-between'>
                            <div className='w-4/5'>
                                <Title level={3} className='!font-semibold !text-regal-blue !text-[24px]'>
                                    {t('common:categories')}
                                </Title>
                            </div>
                            <div className={`${langDirection === 'rtl' ? 'text-left' : 'text-right'}`}>
                                {showAddCategoryBtn && (
                                    <Button
                                        className='app-btn-primary mt-2 h-[32px] flex items-center'
                                        onClick={() =>
                                            navigate(
                                                '/dashboard/categories/add-category?' +
                                                    MarketplaceAppConfig.getStore(''),
                                                {
                                                    state: {
                                                        action: 'add',
                                                        type: 'category',
                                                        title: 'Category',
                                                    },
                                                }
                                            )
                                        }>
                                        <PlusOutlined />
                                        {t('categories:add_category')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    }
                    headerContent={
                        <div className='pb-2'>
                            <Paragraph className='text-brandGray1 text-[14px] font-normal !m-0'>
                                {t('categories:note')}
                            </Paragraph>
                        </div>
                    }
                />
            </Content>
            <Content className='!min-h-screen !p-3 '>
                <div className='bg-white rounded-lg shadow-brandShadow p-4'>
                    {!isNetworkErrorCategoryList ? (
                        <div className='flex justify-end'>
                            {!isTableDataWithoutSearchKeyEmpty ? (
                                <Search
                                    placeholder={t('common:please_enter_search_text_here')}
                                    onSearch={handleSearch}
                                    onChange={handleInputChange}
                                    value={searchValue}
                                    suffix={null}
                                    maxLength={searchMaxLength}
                                    enterButton={customButton}
                                    allowClear
                                    className='!justify-end !w-[30%]'
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {isLoadingCategoryList ? (
                        <Content className=' bg-white p-3'>
                            <SkeletonComponent />
                        </Content>
                    ) : isNetworkErrorCategoryList ? (
                        <Content className='text-center p-3 !bg-white'>
                            <h1 level={5}>{t('common:network_error')}</h1>
                        </Content>
                    ) : !showCategoryList ? (
                        <Content className='p-0 text-center  ml-2'>
                            <Text strong> {t('common:access_permission_required')}</Text>
                        </Content>
                    ) : (
                        <Content>
                            {totalCategoriesCount === 0 && isSearchTriggered && searchValue?.length > 0 ? (
                                <Content className='text-center font-semibold ml-2 mt-3 bg-white p-3'>
                                    <Empty description={t('common:not_able_to_find_searched_details')} />
                                </Content>
                            ) : (
                                <Table
                                    dataSource={categoryList}
                                    columns={tableHeader}
                                    pagination={false}
                                    expandable={{
                                        childrenColumnName: 'children',
                                        expandIcon: ({ expanded, record, onExpand }) =>
                                            record.children ? (
                                                langDirection === 'rtl' ? (
                                                    <img
                                                        src={DownArrow}
                                                        className={`transition-transform transform !ml-5 cursor-pointer ${
                                                            expanded ? 'rotate-270' : 'rotate-90'
                                                        }`}
                                                        alt='expandIcon'
                                                        onClick={(e) => onExpand(record, e)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={DownArrow}
                                                        className={`transition-transform transform mr-5 cursor-pointer ${
                                                            expanded ? 'rotate-270' : '-rotate-90'
                                                        }`}
                                                        alt='expandIcon'
                                                        onClick={(e) => onExpand(record, e)}
                                                    />
                                                )
                                            ) : null,
                                    }}
                                    className='!mt-3'
                                />
                            )}
                        </Content>
                    )}
                </div>
            </Content>
            <StoreModal
                isVisible={isModalVisible}
                okButtonText={`${t('common:delete')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={`${t('common:delete')}`}
                okCallback={() => removeCategory()}
                cancelCallback={() => closeModal()}
                isSpin={sendDataLoading}>
                {<div>{t('categories:you_want_to_del_cat')}</div>}
            </StoreModal>
        </Content>
    )
}

export default ListCategories
