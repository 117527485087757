import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Radio, Row, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { GetAttributesControls } from '../../util/AttributeControls'
import MarketplaceToaster from '../../util/marketplaceToaster'
import './ServiceProductAttributeModal.css'

const { Group } = Radio
const { Content } = Layout
const { Paragraph } = Typography

const templateSlotsAPI = process.env.REACT_APP_TEMPLATE_SLOTS_API

const ServiceProductAttributeModal = ({
    slotData,
    productTemplateSelectedSlotId,
    setIsPreviewModalOpen,
    setProductTemplateSelectedSlotId,
    revisionSelectedInfo,
    slotMappingId,
    setSlotMappingId,
}) => {
    const { t } = useTranslation()
    const [serviceProductSlots, setServiceProductSlots] = useState([])

    const [getSlotGroupAPIDataFilteredPopup, setGetSlotGroupAPIDataFilteredPopup] = useState([])
    const [isUpLoading, setIsUpLoading] = useState(false)

    useEffect(() => {
        if (slotData && slotData.length > 0) {
            let radioBtnArray = []
            for (var i = 0; i < slotData.length; i++) {
                let temp = {}
                temp['value'] = slotData[i].id
                temp['label'] = slotData[i].display_name
                radioBtnArray.push(temp)
            }
            setServiceProductSlots(radioBtnArray)
            if (productTemplateSelectedSlotId !== undefined) {
                let filterPreview = slotData.filter((ele) => ele.id === productTemplateSelectedSlotId)

                if (filterPreview && filterPreview.length > 0) {
                    setGetSlotGroupAPIDataFilteredPopup(filterPreview)
                }
            }
            if (slotData.length === 1) {
                setGetSlotGroupAPIDataFilteredPopup(slotData)
                //setProductTemplateSelectedSlotId(slotData[0].id);
            } else {
                setGetSlotGroupAPIDataFilteredPopup(slotData[0])
                //setProductTemplateSelectedSlotId(slotData[0].id);
            }
        }
    }, [slotData])

    const handleChange = (value) => {
        setProductTemplateSelectedSlotId(value)
    }
    //! Post Call to Map Templates with Slots
    const postCallForTemplateMappingWithSlots = () => {
        const postBodyOne = {
            slot_group_id: productTemplateSelectedSlotId ? productTemplateSelectedSlotId : serviceProductSlots[0].value,
            product_template_revision_id: revisionSelectedInfo && revisionSelectedInfo.id,
        }
        MarketplaceServices.save(templateSlotsAPI, postBodyOne, null)
            .then((response) => {
                setIsPreviewModalOpen(false)

                console.log('Response from post call of Template and Slot', response.data)
                let tempData = response.data.response_body
                setProductTemplateSelectedSlotId(tempData && tempData.slot_group_id)
                setSlotMappingId(tempData && tempData.id)

                MarketplaceToaster.showToast(response)
                setIsUpLoading(false)
            })
            .catch((error) => {
                console.log('error Response from Post call of product template mapping with Slot Group', error.response)
                setIsUpLoading(false)
                // toast(error.response, {
                //   position: toast.POSITION.TOP_RIGHT,
                //   type: "error",
                //   autoClose: 10000,
                // });

                MarketplaceToaster.showToast(error)
            })
    }

    //! Put Call to Map Template with Slots
    const putCallForTemplateMappingWithSlots = () => {
        const putBodyOne = {
            slot_group_id: parseInt(productTemplateSelectedSlotId),
            product_template_revision_id: revisionSelectedInfo && revisionSelectedInfo.id,
        }

        MarketplaceServices.update(templateSlotsAPI, putBodyOne, {
            mapping_id: parseInt(slotMappingId),
        })
            .then((response) => {
                console.log('Response from PUT call of Template and Slot', response.data)

                setIsPreviewModalOpen(false)

                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                console.log('error response from put call of product Template mapping with Slot Group', error.response)

                MarketplaceToaster.showToast(error)
            })
    }

    const onClickSave = () => {
        if (slotMappingId === undefined) {
            setIsUpLoading(true)
            postCallForTemplateMappingWithSlots()
        } else {
            putCallForTemplateMappingWithSlots()
        }
    }

    return (
        <div>
            <Spin tip='Please wait!' size='large' spinning={isUpLoading}>
                <Content className='min-h-[500px]'>
                    <Content className='mb-3'>
                        <Group
                            options={serviceProductSlots}
                            onChange={handleChange}
                            className='custom-btn'
                            disabled={
                                (revisionSelectedInfo && revisionSelectedInfo.product_revision_status !== 1) ||
                                serviceProductSlots?.length == 1
                                    ? true
                                    : false
                            }
                            // defaultValue={
                            //   defaultSlotIDConfigured ? defaultSlotIDConfigured : slotId
                            // }
                            value={
                                productTemplateSelectedSlotId
                                    ? productTemplateSelectedSlotId
                                    : serviceProductSlots &&
                                      serviceProductSlots.length > 0 &&
                                      serviceProductSlots[0].value
                            }
                            optionType='button'
                        />
                    </Content>
                    {getSlotGroupAPIDataFilteredPopup &&
                        getSlotGroupAPIDataFilteredPopup.length > 0 &&
                        getSlotGroupAPIDataFilteredPopup.map((element, index) => (
                            <>
                                <Paragraph className='my-0 py-0'>{element.display_description}</Paragraph>
                                {element.attribute.map((item, index) => (
                                    <Content key={index} className='mb-[1.5rem] break-all'>
                                        <Row>
                                            <Col className='max-w-[80%]'>
                                                <h5 className='!my-0 !font-semibold'>
                                                    {item.display_name}
                                                    <span
                                                        className={`${
                                                            item.mandatory === true ? 'text-red-600 text-sm ml-1' : null
                                                        }`}>
                                                        {item.mandatory === true ? '* ' : ''}
                                                    </span>
                                                </h5>
                                            </Col>
                                            <Col className='!-translate-y-[5px] !ml-[7px] '>
                                                <Tooltip
                                                    // overlayStyle={{
                                                    //   zIndex: 1,
                                                    // }}
                                                    autoAdjustOverflow={true}
                                                    placement='top'
                                                    title={
                                                        <>
                                                            {item.hide_to_customer === true ||
                                                            item.is_static === true ||
                                                            item.is_variant_attribute === true ||
                                                            item.mandatory === true ||
                                                            item.order_specific === true ||
                                                            item.revisable === true ||
                                                            item.sensible === true ||
                                                            item.unique === true ||
                                                            item.varient_specific === true ||
                                                            item.attribute_type ? (
                                                                <>
                                                                    <p className='mb-0'>{`${t(
                                                                        'messages:was_selected_while_creating'
                                                                    )}`}</p>
                                                                    {item.attribute_type !== null &&
                                                                    item.attribute_type !== undefined ? (
                                                                        <p className='mb-0'>
                                                                            {item.attribute_type_displayname}
                                                                        </p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.hide_to_customer === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:Hide-to-Customer'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.is_static === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:Static'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.is_variant_attribute === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:Mark-as-Variant'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.mandatory === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:mandatory'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.order_specific === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:Order-Specific'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.revisable === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:revisable'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.sensible === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:sensitive'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.unique === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:unique'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {item.varient_specific === true ? (
                                                                        <p className='mb-0'>{`${t(
                                                                            'attributes:variant_specific'
                                                                        )}`}</p>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            ) : null}
                                                        </>
                                                    }>
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        <Content className='mt-1'>
                                            <Row>
                                                {item.attribute_option && item.attribute_option.length > 0 ? (
                                                    <Col span={20}>
                                                        <GetAttributesControls
                                                            optionType={item.datatype_id}
                                                            attributeType={item.attribute_type}
                                                            optionValues={item.attribute_option}
                                                            sensitive={item.sensible}
                                                            isEditable={true}
                                                            nonEditableValue={null}
                                                            attribute_unit_values={item.attribute_unit_values}
                                                            placeholder={item.placeholder}
                                                        />
                                                    </Col>
                                                ) : (
                                                    <Col span={20}>
                                                        <GetAttributesControls
                                                            optionType={item.datatype_id}
                                                            attributeType={item.attribute_type}
                                                            optionValues={[]}
                                                            sensitive={item.sensible}
                                                            isEditable={true}
                                                            attribute_unit_values={item.attribute_unit_values}
                                                            placeholder={item.placeholder}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Content>
                                    </Content>
                                ))}
                            </>
                        ))}
                </Content>
                {revisionSelectedInfo && revisionSelectedInfo.product_revision_status === 1 ? (
                    <Content className='flex !justify-end space-x-2'>
                        <Button
                            className='app-btn-secondary '
                            key='back'
                            onClick={() => {
                                setIsPreviewModalOpen(false)
                            }}>
                            {t('common:cancel')}
                        </Button>
                        <Button
                            className='app-btn-primary'
                            key='submit'
                            onClick={() => onClickSave()}
                            disabled={
                                slotMappingId != undefined && serviceProductSlots && serviceProductSlots.length == 1
                                    ? true
                                    : false
                            }>
                            {productTemplateSelectedSlotId ? t('common:update') : t('common:save')}
                        </Button>
                    </Content>
                ) : null}
            </Spin>
        </div>
    )
}

export default ServiceProductAttributeModal
