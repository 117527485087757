import { useQuery } from '@tanstack/react-query'
import MarketplaceAppConfig from '../util/MarketplaceMutlitenancy'
import MarketplaceServices from '../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_STORE_ADMIN_CONSENT

const useGetStoreAdminConsent = () => {
    const getStoreAdminConsent = async () => {
        const storeName = MarketplaceAppConfig.getStore('onlyClient')
        const params = { 'store-name': storeName }
        const res = await MarketplaceServices.findAll(BASE_URL, params)
        return res?.data?.response_body?.store_userconsent_data
    }
    return useQuery({
        queryKey: ['admin', 'policies'],
        queryFn: getStoreAdminConsent,
        refetchOnWindowFocus: false,
    })
}
export default useGetStoreAdminConsent
