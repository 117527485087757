import { ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Layout, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import HeaderForTitle from '../../../components/header/HeaderForTitle'
import StoreModal from '../../../components/storeModal/StoreModal'
import util from '../../../util/common'
import EmptyAttributeGroup from '../../AttributeGroups/EmptyAttributeGroup'
import ListProductVariantAttributes from './ListProductVariantAttributes'
import ListVariantAttributes from './ListVariantAttributes'
import { useDispatch } from 'react-redux'
import { fnAttributeDetailsUpdated } from '../../../services/redux/actions/ActionsAPICall'
import { createJourneyIcon } from '../../../constants/media'

const { Title } = Typography
const { Content } = Layout
function AddVariantAttributes() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const search = useLocation().search
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    let productTemplateRevisionStatus = new URLSearchParams(search).get('ptrss')
    let productTemplateStatus = new URLSearchParams(search).get('ptss')

    const [showModal, setShowModal] = useState(false)
    const [currentProductVariantAttributeArray, setCurrentProductVariantAttributeArray] = useState([])
    const [productVariantAttributesData, setProductVariantAttributesData] = useState([])
    const [langDirection, setLangDirection] = useState('ltr')
    const [variantCount, setVariantCount] = useState(0)
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const closeModal = () => {
        setShowModal(false)
        setCurrentProductVariantAttributeArray([])
    }
    useEffect(() => {
        dispatch(fnAttributeDetailsUpdated(false))
        window.scrollTo(0, 0)
    }, [])
    return (
        <Content className=''>
            <Content>
                <HeaderForTitle
                    title={
                        <Content className='flex  pb-3 justify-between'>
                            <div className='flex gap-3'>
                                {langDirection === 'rtl' ? (
                                    <ArrowRightOutlined
                                        className='!text-sm text-regal-blue'
                                        onClick={() => navigate(-1)}
                                    />
                                ) : (
                                    <ArrowLeftOutlined
                                        className=' !text-sm text-regal-blue'
                                        onClick={() => navigate(-1)}
                                    />
                                )}
                                <p className='text-2xl font-semibold text-regal-blue'>{t('layouts:variant_details')}</p>
                            </div>

                            <div className='flex gap-2'>
                                <div>
                                    {variantCount === 6 ? (
                                        ''
                                    ) : (
                                        <EmptyAttributeGroup
                                            productTemplateId={productTemplateId}
                                            productTemplateRevisionId={productTemplateRevisionId}
                                            productTemplateStatus={productTemplateStatus}
                                            productTemplateRevisionStatus={productTemplateRevisionStatus}
                                            Type={'Product_Revision'}
                                            Choices={'Variant'}
                                            page={'add_variant'}
                                        />
                                    )}
                                </div>
                                <div
                                    onClick={() => {
                                        setShowModal(true)
                                        dispatch(fnAttributeDetailsUpdated(false))
                                    }}
                                    className={`flex flex-row  border-[1px] border-[#D9D9D9]  rounded-md px-4 py-2 cursor-pointer hover:border-[#fb8500]  ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pr-4' : '!pl-4'}`}>
                                    <img src={createJourneyIcon} width={14} height={14} />
                                    <p
                                        className={`font-medium text-sm text-regal-blue  ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!mr-2' : '!ml-2'}`}>
                                        {t('product_template:add_from_existing_variant_attributes')}
                                    </p>
                                </div>
                            </div>
                        </Content>
                    }
                />
            </Content>
            <Content className='!min-h-screen p-3 mt-[6.2rem] '>
                {/* <Content className='bg-white p-3 rounded-md'> */}
                        {/* <Content className='!w-[50%] !inline-block'>
                            <p className='mt-3 pl-2 !font-normal text-sm text-[#8899A8]'>
                                {t('product_template:variant_attributes_info')}
                            </p>
                        </Content> */}
                    <Content className='!mt-3'>
                        <ListProductVariantAttributes
                            productVariantAttributesData={productVariantAttributesData}
                            setProductVariantAttributesData={setProductVariantAttributesData}
                            page={'add_variant'}
                        />
                    </Content>
                {/* </Content> */}
                <StoreModal
                    isVisible={showModal}
                    title={t('product_template:add_from_existing_variant_attributes')}
                    okButtonText={null}
                    cancelButtonText={null}
                    cancelCallback={() => closeModal()}
                    width={600}
                    isSpin={false}
                    hideCloseButton={true}
                    destroyOnClose={true}>
                    <Content className='mt-3'>
                        <ListVariantAttributes
                            productVariantAttributesData={productVariantAttributesData}
                            setShowModal={setShowModal}
                            variantCount={variantCount}
                            setVariantCount={setVariantCount}
                            currentProductVariantAttributeArray={currentProductVariantAttributeArray}
                            setCurrentProductVariantAttributeArray={setCurrentProductVariantAttributeArray}
                        />
                    </Content>
                </StoreModal>
            </Content>
        </Content>
    )
}

export default AddVariantAttributes
