import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT

const useGetStoreUserConsent = ({ userType }) => {
    const getStoreUserConsent = async () => {
        const params = {}
        if (userType) params['user-type'] = userType
        const res = await MarketplaceServices.findAllWithoutPage(BASE_URL, params)
        //console.log(res, 'useGetStoreUserConsent')
        return res?.data?.response_body
    }
    return useQuery({
        queryKey: ['StoreUserConsent', userType ?? ''],
        queryFn: getStoreUserConsent,
        refetchOnWindowFocus: false,
        retry: false,
    })
}
export default useGetStoreUserConsent
