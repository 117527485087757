import { Button, Col, Input, Layout, Row, Select, Skeleton, Spin, Tooltip, Typography, Switch, Image } from 'antd'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import validator from 'validator'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { InfoIcon } from '../../constants/media'
import StoreModal from '../../components/storeModal/StoreModal'
//! Import CSS libraries

const userAPI = process.env.REACT_APP_USERS_API
const usersAllAPI = process.env.REACT_APP_USERS_ALL_API

const groupsAPI = process.env.REACT_APP_GROUPS_API
const userNameMinLength = process.env.REACT_APP_USERNAME_MIN_LENGTH
const userNameMaxLength = process.env.REACT_APP_USERNAME_MAX_LENGTH
const nameMinLength = process.env.REACT_APP_NAME_MIN_LENGTH
const nameMaxLength = process.env.REACT_APP_NAME_MAX_LENGTH
const emailMaxLength = process.env.REACT_APP_EMAIL_MAX_LENGTH
const emailRegexPattern = process.env.REACT_APP_REGEX_PATTERN_EMAIL
const updateUserStatusAPI = process.env.REACT_APP_USER_STATUS_API
const updateUserPreferenceAPI = process.env.REACT_APP_UPDATE_OWNER_PREFERENCE
const useInfoAPI = process.env.REACT_APP_USER_PROFILE_API

const { Title } = Typography
const { Content } = Layout

const CreateUsers = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const search = useLocation().search
    const defaultStatus = new URLSearchParams(search).get('default')
    const id = new URLSearchParams(search).get('id')
    const storeRole = new URLSearchParams(search).get('role')

    const { pathname } = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [pageAction, setPageAction] = useState()
    const [selectRole, setSelectRole] = useState()
    const [groupsServerData, setGroupsServerData] = useState([])
    const [userName, setUserName] = useState('')
    const [emailId, setEmailId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userStatus, setUserStatus] = useState(false)
    const [invalidUserName, setInvalidUserName] = useState(false)
    const [invalidEmailId, setInvalidEmailId] = useState(false)
    const [invalidRole, setInvalidRole] = useState(false)
    const [roleSelectData, setRoleSelectData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [isUserDetailFetching, setIsUserDetailFetching] = useState(false)
    const [currentFirstName, setCurrentFirstName] = useState('')
    const [currentLastName, setCurrentLastName] = useState('')
    const [currentRole, setCurrentRole] = useState('')
    const [currentEmailId, setCurrentEmailId] = useState('')
    const [currentUser, setCurrentUser] = useState('')
    const [isDefault, setIsDefault] = useState('')
    const [showUserEnableDisableModal, setShowUserEnableDisableModal] = useState(false)
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
    const [deleteModalLoading, setDeleteModalLoading] = useState(false)
    const [changeSwitchStatus, setChangeSwitchStatus] = useState()
    const [userInfoName, setUserInfoName] = useState('')
    const [primaryStatusModalOpen, setPrimaryStatusModalOpen] = useState(false)
    const [userPrimaryStatus, setUserPrimaryStatus] = useState()
    const [primaryStatusUpdateLoading, setPrimaryStatusUpdateLoading] = useState(false)

    //Get call of groups
    const findAllGroupLists = () => {
        MarketplaceServices.findAll(groupsAPI, { is_marketplace_role: true }, true)
            .then(function (response) {
                console.log('groupsList get call response-->', response.data.response_body)
                setGroupsServerData(response.data.response_body)
            })
            .catch(function (error) {
                console.log('groupsList get error call response-->', error)
            })
    }

    //!Post call of user to server
    const handlePostUsers = () => {
        setIsLoading(true)
        let dataObject = {}
        dataObject['username'] = userName
        dataObject['email'] = emailId
        dataObject['status'] = true
        if (firstName !== '') {
            dataObject['firstname'] = firstName
        }
        if (lastName !== '') {
            dataObject['lastname'] = lastName
        }
        if (selectRole !== undefined && selectRole !== '') {
            dataObject['groups_mapping'] = selectRole
        }
        MarketplaceServices.save(userAPI, dataObject, null)
            .then(function (response) {
                console.log('server response of user post call', response)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                navigate(-1)
            })
            .catch((error) => {
                console.log('server error response of user post call')
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
                if (error.response?.status === 400) {
                    if (error.response?.data?.response_code === 'UMS-000002-04') {
                        setInvalidEmailId(true)
                        setInvalidUserName(true)
                    } else if (error.response?.data?.response_code === 'UMS-000002-18') {
                        // userName
                        setInvalidUserName(true)
                        // setInvalidEmailId(false)
                    } else if (error.response?.data?.response_code === 'UMS-000002-19') {
                        // email
                        setInvalidEmailId(true)
                        // setInvalidUserName(false)
                    }
                }
            })
    }

    // validation of user form
    const userFormValidation = () => {
        // "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
        // const emailRegex = /^[a-zA-Z0-9_.+-]{3,64}@[A-Za-z\-]{3,255}\.[A-Za-z]{2,3}$/;
        const emailRegex = new RegExp(emailRegexPattern)
        const userNameRegex = /^[A-Za-z0-9_\- ]+$/

        let count = 3
        if (userName === '' || emailId === '' || selectRole === undefined) {
            count--
            if (emailId === '') {
                setInvalidEmailId(true)
            }
            if (userName === '') {
                setInvalidUserName(true)
            }
            if (selectRole === undefined) {
                setInvalidRole(true)
            }
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (userNameRegex.test(userName) === false) {
            count--
            setInvalidUserName(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('users_roles:please_enter_valid_username')}`, 'error')
            )
        } else if (userNameRegex.test(userName) === true && userName.length < userNameMinLength) {
            count--
            setInvalidUserName(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('users_roles:username_must_contain_minimum_characters')}`, 'error')
            )
        } else if (emailRegex.test(emailId) === false) {
            count--
            setInvalidEmailId(true)

            MarketplaceToaster.showToast(
                util.getToastObject(`${t('users_roles:please_enter_the_valid_email_address')}`, 'error')
            )
        }
        if (count === 3) {
            handlePostUsers()
        }
    }

    //!Put call of user to server
    const handlePutUsers = () => {
        setIsLoading(true)
        let dataObject = {}
        dataObject['firstname'] = firstName
        dataObject['lastname'] = lastName
        dataObject['email'] = emailId

        if (selectRole && userName !== currentUser) {
            dataObject['groups_mapping'] = [selectRole]
        }

        MarketplaceServices.update(userAPI, dataObject, {
            user_name: userName,
        })
            .then(function (response) {
                console.log('server response of user put call', response)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                navigate(-1)
            })
            .catch((error) => {
                console.log('server error response of user put call')
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
                if (error.response?.status === 400) {
                    if (error.response?.data?.response_code === 'UMS-000002-04') {
                        setInvalidEmailId(true)
                        setInvalidUserName(true)
                    } else if (error.response?.data?.response_code === 'UMS-000002-16') {
                        // userName
                        setInvalidUserName(true)
                        setInvalidEmailId(false)
                    } else if (error.response?.data?.response_code === 'UMS-000002-17') {
                        // email
                        setInvalidEmailId(true)
                        setInvalidUserName(false)
                    }
                }
            })
    }

    const userFormValidationEdit = () => {
        // const emailRegex = /^[a-zA-Z0-9_.+-]{3,64}@[A-Za-z\-]{3,255}\.[A-Za-z]{2,3}$/;
        const emailRegex = new RegExp(emailRegexPattern)
        let count = 1
        if (emailId === '') {
            count--
            if (emailId === '') {
                setInvalidEmailId(true)
            }
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (emailRegex.test(emailId) === false) {
            count--
            setInvalidEmailId(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('users_roles:please_enter_the_valid_email_address')}`, 'error')
            )
        } else if (selectRole === undefined) {
            count--
            setInvalidRole(true)
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        }
        if (count === 1) {
            handlePutUsers()
        }
    }

    //handle change of role select
    const handleChangeRole = (value) => {
        setSelectRole(value)
        setInvalidRole(false)
    }

    //useEffect to form the data for the role dropdown
    useEffect(() => {
        var roleObject = {}
        var roleDropdownArray = []
        groupsServerData &&
            groupsServerData.length > 0 &&
            groupsServerData.forEach((element) => {
                roleObject = {}
                roleObject.label = String(element.name).replaceAll('-', ' ')
                roleObject.value = element.name
                roleDropdownArray.push(roleObject)
            })
        setRoleSelectData(roleDropdownArray)
    }, [groupsServerData])

    //Get call of users
    const findAllUsersLists = (userId) => {
        setIsUserDetailFetching(true)
        MarketplaceServices.findAll(usersAllAPI, null, false)
            .then(function (response) {
                setIsUserDetailFetching(false)
                if (response.data && response.data.response_body && response.data.response_body.users) {
                    let selectedUserDetails = response.data.response_body.users.filter(({ id }) => id === userId)
                    console.log(
                        'usersList get call response-->',
                        response.data.response_body,
                        'userId',
                        userId,
                        selectedUserDetails
                    )
                    if (selectedUserDetails.length > 0) {
                        setUserName(selectedUserDetails[0].username)
                        setFirstName(selectedUserDetails[0].firstName)
                        setLastName(selectedUserDetails[0].lastName)
                        setEmailId(selectedUserDetails[0].email)
                        setSelectRole(selectedUserDetails[0].groups[0].name)
                        setCurrentFirstName(selectedUserDetails[0].firstName)
                        setCurrentLastName(selectedUserDetails[0].lastName)
                        setCurrentRole(selectedUserDetails[0].groups[0].name)
                        setCurrentEmailId(selectedUserDetails[0].email)
                        setUserStatus(selectedUserDetails[0].enabled)
                        setUserPrimaryStatus(selectedUserDetails[0]?.attributes?.is_default_owner[0])
                    }
                }
            })
            .catch(function (error) {
                setIsUserDetailFetching(false)
                console.log('usersList get error call response-->', error)
            })
    }

    const getCurrentUserDetails = () => {
        MarketplaceServices.findAll(useInfoAPI, null, false)
            .then((res) => {
                console.log('get access token res', res?.data?.response_body)
                setUserInfoName(res?.data?.response_body?.preferred_username)
            })
            .catch((err) => {
                console.log('get access token err', err)
            })
    }

    //Get call of users
    const findUsersLists = (userInfoName) => {
        MarketplaceServices.findAll(usersAllAPI, { username: userInfoName }, false)
            .then(function (response) {
                console.log('usersList get success call responses', response)
                if (response?.data?.response_body?.users[0]?.attributes) {
                    let userInfo = response?.data?.response_body?.users[0]?.attributes?.is_default_owner[0]
                    console.log('userInfo', userInfo)
                    setIsDefault(userInfo)
                }
            })
            .catch(function (error) {
                console.log('usersList get error call response-->', error)
            })
    }

    //Enable ad disable user from server
    const enableDisableUserFromServer = () => {
        setDeleteModalLoading(true)
        MarketplaceServices.update(
            updateUserStatusAPI,
            {
                status: changeSwitchStatus === false ? false : true,
            },
            { user_name: userName }
        )
            .then(function (response) {
                console.log('update server response of user enable', response)
                MarketplaceToaster.showToast(response)
                setDeleteModalLoading(false)
                setShowUserEnableDisableModal(false)
                setUserStatus(changeSwitchStatus)
            })
            .catch((error) => {
                console.log('update server response of user enable')
                MarketplaceToaster.showToast(error.response)
                setDeleteModalLoading(false)
            })
    }

    //Delete call of user frm server
    const removeUser = () => {
        setDeleteModalLoading(true)
        MarketplaceServices.remove(userAPI, {
            'user-name': userName,
        })
            .then(function (response) {
                console.log('delete response of user', response)
                setDeleteModalLoading(false)
                MarketplaceToaster.showToast(response)
                // findAllUsersLists()
                navigate(-1)
                setShowDeleteUserModal(false)
            })
            .catch(function (error) {
                console.log('delete error response of user', error)
                MarketplaceToaster.showToast(error.response)
                setDeleteModalLoading(false)
            })
    }

    //Enable ad disable user from server
    const updatePrimaryUser = () => {
        setPrimaryStatusUpdateLoading(true)
        MarketplaceServices.update(updateUserPreferenceAPI, {
            user_id: id,
        })
            .then(function (response) {
                console.log('update server response of primary enable', response)
                setUserPrimaryStatus('True')
                MarketplaceToaster.showToast(response)
                setPrimaryStatusUpdateLoading(false)
                setPrimaryStatusModalOpen(false)
                setSearchParams({
                    id: searchParams.get('id'),
                    uname: searchParams.get('uname'),
                    default: 'True',
                })
            })
            .catch((error) => {
                console.log('update server response of primary enable', error)
                MarketplaceToaster.showToast(error.response)
                setPrimaryStatusUpdateLoading(false)
            })
    }

    //User Delete modal open function
    const openUserDeleteModal = () => {
        setShowDeleteUserModal(true)
    }

    //User enable modal open function
    const openUserEnableDisableModal = (checked) => {
        setShowUserEnableDisableModal(true)
        setChangeSwitchStatus(checked)
    }

    // primary user Modal function
    const openPrimaryUserModal = (checked) => {
        setPrimaryStatusModalOpen(true)
    }
    //UseEffect to set page action edit or save
    useEffect(() => {
        var pathnameString = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length).split('-')
        setPageAction(pathnameString[0])
        findAllGroupLists()
        if (pathnameString[0] !== 'add') {
            findAllUsersLists(searchParams.get('id'))
            setCurrentUser(searchParams.get('uname'))
        }
        window.scrollTo(0, 0)
        findAllGroupLists()
        getCurrentUserDetails()
    }, [])

    useEffect(() => {
        if (userInfoName !== '') {
            findUsersLists(userInfoName)
        }
    }, [userInfoName])

    return (
        <Content className=''>
            <HeaderForTitle
                title={
                    <Row className=' w-full !items-center'>
                        <Content className='flex justify-start'>
                            <Tooltip title={pageAction !== 'add' ? userName : undefined} zIndex={11} placement='bottom'>
                                <div className='!font-normal max-w-[800px]' ellipsis>
                                    {pageAction === 'add' ? (
                                        <label className='font-semibold text-2xl mb-2 text-regal-blue'>
                                            {t('users_roles:add_user')}{' '}
                                        </label>
                                    ) : (
                                        <Content className='flex gap-2 '>
                                            <div className='font-semibold text-2xl mb-2 text-regal-blue'>
                                                {userName}
                                            </div>
                                            {defaultStatus === 'True' ? (
                                                <span className='bg-black text-white border px-1 !mb-3 mt-[6px]  '>
                                                    {t('users_roles:primary_account')}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </Content>
                                    )}
                                </div>
                            </Tooltip>
                        </Content>
                        <div className='flex justify-end'>
                            {pageAction !== 'add' ? (
                                <Content className='flex gap-2 '>
                                    <Content className='gap-1 flex mt-1'>
                                        <label className='text-brandGray2 font-normal '>{t('common:status')}</label>
                                        {defaultStatus === 'True' ? (
                                            <Tooltip
                                                placement='bottomRight'
                                                title={
                                                    <>
                                                        {isDefault === 'False' ? (
                                                            <div>{t('users_roles:standard_user_info')}</div>
                                                        ) : defaultStatus === 'True' && isDefault === 'True' ? (
                                                            <>
                                                                <div className='font-semibold text-sm'>
                                                                    {t('users_roles:make_primary_account')}
                                                                </div>
                                                                <div>{t('users_roles:primary_content')}</div>
                                                            </>
                                                        ) : null}
                                                    </>
                                                }
                                                className=' '>
                                                <Image
                                                    src={InfoIcon}
                                                    alt='InfoIcon'
                                                    className='!w-[14px] !text-center  !mb-0 cursor-pointer'
                                                    preview={false}
                                                />{' '}
                                            </Tooltip>
                                        ) : null}
                                        :
                                        <Switch
                                            disabled={
                                                currentUser === userName ||
                                                // isDefault === 'False' ||
                                                defaultStatus === 'True'
                                                    ? true
                                                    : false
                                            }
                                            className={
                                                userStatus === true
                                                    ? '!bg-[var(--mp-primary-background-color)]'
                                                    : '!bg-gray-400'
                                            }
                                            checked={userStatus}
                                            onChange={openUserEnableDisableModal}
                                        />
                                    </Content>
                                    {storeRole === 'Store-Manager' ? null : (
                                        <Content className='gap-1 flex mt-1'>
                                            <label className='text-brandGray2 font-normal '>
                                                {t('users_roles:primary_user')}
                                            </label>
                                            <Tooltip
                                                placement='bottomRight'
                                                title={
                                                    <>
                                                        {storeRole === 'Store-Manager' ? (
                                                            `${t('users_roles:store_manager_info')}`
                                                        ) : (
                                                            <>
                                                                {isDefault === 'False' ? (
                                                                    <div>{t('users_roles:non_primary_info')}</div>
                                                                ) : defaultStatus === 'True' && isDefault === 'True' ? (
                                                                    <div>{t('users_roles:primary_content_info')}</div>
                                                                ) : isDefault === 'True' &&
                                                                  defaultStatus === 'False' &&
                                                                  !userStatus ? (
                                                                    <div>
                                                                        {t(
                                                                            'users_roles:deactive_status_primary_message'
                                                                        )}
                                                                    </div>
                                                                ) : isDefault === 'True' &&
                                                                  defaultStatus === 'False' ? (
                                                                    <div>
                                                                        {t('users_roles:primary_content_information')}
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </>
                                                }
                                                className=' '>
                                                <Image
                                                    src={InfoIcon}
                                                    alt='InfoIcon'
                                                    className='!w-[14px] !text-center '
                                                    preview={false}
                                                />{' '}
                                            </Tooltip>
                                            :
                                            <Switch
                                                disabled={
                                                    currentUser === userName ||
                                                    isDefault === 'False' ||
                                                    storeRole === 'Store-Manager' ||
                                                    defaultStatus === 'True' ||
                                                    userStatus === false
                                                        ? true
                                                        : false
                                                }
                                                checked={userPrimaryStatus === 'True' ? true : false}
                                                onChange={openPrimaryUserModal}
                                                className={
                                                    userPrimaryStatus === 'True'
                                                        ? '!bg-[var(--mp-primary-background-color)]'
                                                        : '!bg-gray-400'
                                                }
                                            />
                                        </Content>
                                    )}
                                    <Button
                                        className='app-btn-delete mb-2'
                                        onClick={() => openUserDeleteModal()}
                                        disabled={
                                            currentUser === userName ||
                                            // isDefault === 'False' ||
                                            defaultStatus === 'True'
                                                ? true
                                                : false
                                        }>
                                        {t('users_roles:delete_user')}
                                    </Button>
                                </Content>
                            ) : null}
                        </div>
                    </Row>
                }
                type={'categories'}
                action={pageAction === 'add' ? 'add' : 'edit'}
                showArrowIcon={true}
                saveFunction={userFormValidation}
                isVisible={pageAction === 'edit' ? false : true}
                showButtons={false}
                backNavigationPath={'/dashboard/user-access-control/list-user-roles'}
            />
            <Content className='!min-h-screen mt-[5.8rem] p-3'>
                {isUserDetailFetching ? (
                    <Content className='bg-white'>
                        <Skeleton
                            active
                            paragraph={{
                                rows: 10,
                            }}
                            className='p-3'></Skeleton>
                    </Content>
                ) : (
                    <Spin tip={t('common:please_wait')} size='large' spinning={isLoading}>
                        <Content className='bg-white p-3 flex flex-col shadow-brandShadow rounded-md'>
                            <Content className='w-[50%]'>
                                <Content className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('users_roles:user_name')}
                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                    </Typography>
                                    <Content>
                                        <Input
                                            className={`${
                                                invalidUserName
                                                    ? 'border-red-400  border-[1px] rounded-lg border-solid focus:border-red-400 hover:border-red-400'
                                                    : ' border-solid border-[#C6C6C6]'
                                            }`}
                                            value={userName}
                                            disabled={pageAction !== 'add' ? true : false}
                                            onChange={(e) => {
                                                const alphaWithoutSpaces = /^[a-zA-Z0-9]+$/
                                                if (
                                                    e.target.value !== '' &&
                                                    validator.matches(e.target.value, alphaWithoutSpaces)
                                                ) {
                                                    setUserName(String(e.target.value).toLowerCase())
                                                    setInvalidUserName(false)
                                                } else if (e.target.value === '') {
                                                    setUserName(e.target.value)
                                                }
                                            }}
                                            onBlur={(e) => {
                                                setUserName(e.target.value.trim().replace(/\s+/g, ' '))
                                            }}
                                            maxLength={userNameMaxLength}
                                            placeholder={t('users_roles:user_name_placeholder')}
                                        />
                                    </Content>
                                </Content>
                                <Content>
                                    <Content>
                                        <Typography className='input-label-color mb-2 flex gap-1'>
                                            {t('users_roles:first_name')}
                                        </Typography>
                                        <Input
                                            autoComplete='off'
                                            value={firstName}
                                            onChange={(e) => {
                                                const { value } = e.target
                                                const regex = /^[a-zA-Z]*$/ // only allow letters
                                                if (regex.test(value)) {
                                                    setFirstName(e.target.value)
                                                }
                                            }}
                                            disabled={pageAction !== 'add' && defaultStatus === 'True' ? true : false}
                                            minLength={nameMinLength}
                                            maxLength={nameMaxLength}
                                            placeholder={t('users_roles:enter_first_name')}
                                        />
                                    </Content>
                                </Content>
                                <Content className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('users_roles:last_name')}
                                    </Typography>

                                    <Input
                                        autoComplete='off'
                                        disabled={pageAction !== 'add' && defaultStatus === 'True' ? true : false}
                                        value={lastName}
                                        onChange={(e) => {
                                            const { value } = e.target
                                            const regex = /^[a-zA-Z]*$/ // only allow letters
                                            if (regex.test(value)) {
                                                setLastName(e.target.value)
                                            }
                                        }}
                                        minLength={nameMinLength}
                                        maxLength={nameMaxLength}
                                        placeholder={t('users_roles:enter_last_name')}
                                    />
                                </Content>
                                <Content className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('users_roles:email')}
                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                    </Typography>

                                    <Content>
                                        <Input
                                            autoComplete='off'
                                            className={`${
                                                invalidEmailId
                                                    ? 'border-red-400  border-[1px] rounded-lg border-solid focus:border-red-400 hover:border-red-400'
                                                    : ' border-solid border-[#C6C6C6]'
                                            }`}
                                            value={emailId}
                                            disabled={pageAction !== 'add' && defaultStatus === 'True' ? true : false}
                                            onChange={(e) => {
                                                if (
                                                    validator.matches(
                                                        e.target.value.trim(),
                                                        /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};'`~:"\\|,.<>\/?]+$/
                                                    )
                                                ) {
                                                    setEmailId(e.target.value.toLowerCase())
                                                    setInvalidEmailId(false)
                                                } else if (e.target.value === '') {
                                                    setEmailId(e.target.value.toLowerCase())
                                                    setInvalidEmailId(false)
                                                }
                                            }}
                                            onBlur={(e) => {
                                                setEmailId(e.target.value.trim().replace(/\s+/g, ' '))
                                            }}
                                            maxLength={emailMaxLength}
                                            placeholder={t('users_roles:enter_email')}
                                        />
                                    </Content>
                                </Content>
                                <Content className='flex my-3'>
                                    <Content>
                                        <Typography className='input-label-color mb-2 flex gap-1'>
                                            {t('users_roles:role')}
                                            <span className='mandatory-symbol-color text-sm '>*</span>
                                        </Typography>
                                        <Content>
                                            <Select
                                                disabled={
                                                    pageAction === 'edit'
                                                        ? userName === currentUser || defaultStatus === 'True'
                                                        : false
                                                }
                                                style={{
                                                    width: '100%',
                                                }}
                                                allowClear
                                                status={invalidRole ? 'error' : ''}
                                                placeholder={t('users_roles:select_a_role')}
                                                value={selectRole}
                                                onChange={handleChangeRole}
                                                options={roleSelectData}
                                            />
                                        </Content>
                                    </Content>
                                </Content>
                            </Content>
                            <Content className='flex gap-2 my-2'>
                                <Button
                                    onClick={pageAction !== 'add' ? userFormValidationEdit : userFormValidation}
                                    className={`app-btn-primary`}
                                    disabled={
                                        pageAction === 'add'
                                            ? userName !== '' ||
                                              emailId !== '' ||
                                              firstName !== '' ||
                                              lastName !== '' ||
                                              userStatus ||
                                              selectRole
                                                ? false
                                                : true
                                            : firstName !== currentFirstName ||
                                                lastName !== currentLastName ||
                                                emailId !== currentEmailId ||
                                                selectRole !== currentRole
                                              ? false
                                              : true
                                    }>
                                    {pageAction === 'edit' ? t('common:update') : t('common:save')}
                                </Button>
                                <Button
                                    className='app-btn-secondary'
                                    onClick={() => navigate('/dashboard/user-access-control/list-user-roles')}>
                                    {t('common:discard')}
                                </Button>
                            </Content>
                        </Content>
                    </Spin>
                )}
            </Content>

            <StoreModal
                isVisible={showDeleteUserModal}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={''}
                okCallback={() => removeUser()}
                cancelCallback={() => setShowDeleteUserModal(false)}
                isSpin={deleteModalLoading}>
                {<div> {t('users_roles:are_you_sure_you_want_delete_the_user')}</div>}
            </StoreModal>
            <StoreModal
                isVisible={showUserEnableDisableModal}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={''}
                okCallback={() => enableDisableUserFromServer()}
                cancelCallback={() => setShowUserEnableDisableModal(false)}
                isSpin={deleteModalLoading}>
                {
                    <div>
                        {userStatus === true ? (
                            <p>{t('users_roles:are_you_sure_you_want_disable_status')}</p>
                        ) : (
                            <p>{t('users_roles:are_you_sure_you_want_enable_status')}</p>
                        )}
                    </div>
                }
            </StoreModal>
            <StoreModal
                isVisible={primaryStatusModalOpen}
                okButtonText={`${t('common:yes')}`}
                cancelButtonText={`${t('common:no')}`}
                title={<div className=''>{t('users_roles:warning_heading')}</div>}
                okCallback={() => updatePrimaryUser()}
                cancelCallback={() => setPrimaryStatusModalOpen(false)}
                isSpin={primaryStatusUpdateLoading}>
                {
                    <div>
                        <p className='mb-2'>
                            {t('users_roles:making')} {userName} {t('users_roles:primary_status_modal_confirmation')}
                        </p>
                        <p>{t('users_roles:primary_confirmation_message')}</p>
                    </div>
                }
            </StoreModal>
        </Content>
    )
}

export default CreateUsers
