import React from 'react'

const DashboardIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M13.2188 1.0625H17.1875C18.25 1.0625 19.125 1.9375 19.125 3.03125V6.96875C19.125 8.03125 18.25 8.90625 17.1875 8.90625H13.2188C12.1562 8.90625 11.2812 8.03125 11.2812 6.96875V3C11.2812 1.9375 12.1562 1.0625 13.2188 1.0625ZM17.1875 7.5625C17.5 7.5625 17.75 7.3125 17.75 7V3.03125C17.75 2.71875 17.5 2.46875 17.1875 2.46875H13.2188C12.9062 2.46875 12.6562 2.71875 12.6562 3.03125V7C12.6562 7.3125 12.9062 7.5625 13.2188 7.5625H17.1875ZM2.8125 1.0625H6.78125C7.875 1.0625 8.75 1.9375 8.71875 3.03125V6.96875C8.71875 8.03125 7.84375 8.90625 6.78125 8.90625H2.8125C1.75 8.90625 0.875 8.03125 0.875 6.96875V3C0.875 1.9375 1.75 1.0625 2.8125 1.0625ZM6.78125 7.5625C7.09375 7.5625 7.34375 7.3125 7.34375 7V3.03125C7.34375 2.71875 7.09375 2.46875 6.78125 2.46875H2.8125C2.5 2.46875 2.25 2.71875 2.25 3.03125V7C2.25 7.3125 2.5 7.5625 2.8125 7.5625H6.78125ZM6.78125 11.0312H2.8125C1.75 11.0312 0.875 11.9062 0.875 12.9687V16.9375C0.875 18 1.75 18.875 2.8125 18.875H6.78125C7.84375 18.875 8.71875 18 8.71875 16.9375V13C8.75 11.9062 7.875 11.0312 6.78125 11.0312ZM7.34375 16.9687C7.34375 17.2812 7.09375 17.5312 6.78125 17.5312H2.8125C2.5 17.5312 2.25 17.2812 2.25 16.9687V13C2.25 12.6875 2.5 12.4375 2.8125 12.4375H6.78125C7.09375 12.4375 7.34375 12.6875 7.34375 13V16.9687ZM13.2188 11.0312H17.1875C18.25 11.0312 19.125 11.9062 19.125 13V16.9375C19.125 18 18.25 18.875 17.1875 18.875H13.2188C12.1562 18.875 11.2812 18 11.2812 16.9375V12.9687C11.2812 11.9062 12.1562 11.0312 13.2188 11.0312ZM17.1875 17.5312C17.5 17.5312 17.75 17.2812 17.75 16.9687V13C17.75 12.6875 17.5 12.4375 17.1875 12.4375H13.2188C12.9062 12.4375 12.6562 12.6875 12.6562 13V16.9687C12.6562 17.2812 12.9062 17.5312 13.2188 17.5312H17.1875Z'
                fill='#8899A8'
            />
        </svg>
    )
}

export default DashboardIcon
