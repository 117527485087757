//!React Library Imports
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//!Ant Design Library Imports
import { Input, Layout, Spin, Table, Tooltip, Typography, Image } from 'antd'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { ProductDefaultImage, ChevronUp } from '../../constants/media'
import { getParentChildrenDataForCategories } from '../../util/util'
import util from '../../util/common'

const categoryAPI = process.env.REACT_APP_CATEGORY_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE

//!Destructing of the Imports.
const { Title, Text, Paragraph } = Typography
const { Content } = Layout
const { Search } = Input

const AddCategoryToDiscount = ({
    selectedCategory,
    setSelectedCategory,
    setSaveButtonDisabledForModal,
    mainObjectDataForAllActions,
    triggerUseEffect,
}) => {
    const { t } = useTranslation()
    const [isLoadingCategory, setIsLoadingCategory] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [langDirection, setLangDirection] = useState('ltr')
    const [allCategory, setAllCategory] = useState([])
    const [tableData, setTableData] = useState([])
    const [doSearchAPICall, setDoSearchAPICall] = useState(false)

    const columns = [
        {
            title: `${t('common:categories')}`,
            dataIndex: 'category_name',
            key: 'category_name',
            width: '70%',
            render: (text, record) => {
                return (
                    <Content className='flex items-center'>
                        <Image
                            src={
                                record && record?.category_media_full_path
                                    ? record.category_media_full_path
                                    : ProductDefaultImage
                            }
                            width={40}
                            height={40}></Image>
                        <Content className={` ${langDirection === 'rtl' ? '!mr-3' : '!ml-3'} `}>
                            <Text
                                ellipsis={{
                                    tooltip: {
                                        title: record && record.displayName,
                                        mouseLeaveDelay: 0,
                                    },
                                }}
                                style={{ maxWidth: 400 }}
                                className='!text-[14px]'>
                                {record && record.displayName}{' '}
                            </Text>
                        </Content>
                    </Content>
                )
            },
        },
        {
            title: `${t('coupons:published_product')}`,
            dataIndex: 'publishedProducts',
            key: 'publishedProducts',
            width: '30%',
            render: (text, record) => {
                return (
                    <Tooltip overlayStyle={{ zIndex: 1 }} title={record && record.displayDescription}>
                        <Text ellipsis={true} style={{ maxWidth: 250 }} className='!text-[14px]'>
                            {record && record.publishedProducts}
                            <span> {t('common:products')}</span>
                        </Text>
                    </Tooltip>
                )
            },
        },
    ]

    const findallCategory = () => {
        setIsLoadingCategory(true)
        let params = {}

        if (searchValue) {
            params['search'] = searchValue.trim()
        }

        MarketplaceServices.findAll(categoryAPI, params, true)
            .then(function (response) {
                console.log('server response from findallCategory API call is:', categoryAPI, response.data)
                if (response?.data?.response_body?.category_data) {
                    const parentChildrenData = getParentChildrenDataForCategories(
                        response.data.response_body.category_data
                    )
                    let removeAlreadyMappedCategory = parentChildrenData?.filter(
                        (item) => !selectedCategory?.includes(item.key)
                    )
                    if (removeAlreadyMappedCategory) {
                        setTableData(removeAlreadyMappedCategory)
                    } else {
                        setTableData(parentChildrenData)
                    }
                    setAllCategory(parentChildrenData)
                }
                setIsLoadingCategory(false)
            })
            .catch(function (error) {
                setIsLoadingCategory(false)
                console.log('Error server response from findallCategory API call is: ', categoryAPI, error.response)
            })
    }
    const onRowSelectChange = (newSelectedRowKeys) => {
        setSelectedCategory(newSelectedRowKeys)
        setSaveButtonDisabledForModal(false)
    }
    const onSearch = (searchValue) => {
        setDoSearchAPICall(!doSearchAPICall)
    }
    const handleOnChangeSearch = (event) => {
        let searchText = event.target.value
        if (searchText == '') {
            setSearchValue('')
            setDoSearchAPICall(!doSearchAPICall)
        } else {
            let trimmedSearchText = searchText.replace(/\s+/g, ' ')
            setSearchValue(trimmedSearchText)
        }
    }
    useEffect(() => {
        findallCategory()
    }, [doSearchAPICall])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <Content>
            {' '}
            <Content className='bg-white !p-3 text-right'>
                <Search
                    placeholder={t('coupons:input_search_text')}
                    onSearch={(e) => onSearch(e)}
                    style={{
                        width: 232,
                    }}
                    allowClear
                    onChange={(e) => handleOnChangeSearch(e)}
                    value={searchValue}
                />
                <Content className='mt-4'>
                    <Spin spinning={isLoadingCategory} size='large'>
                        <Table
                            rowSelection={{
                                selectedRowKeys: selectedCategory,
                                preserveSelectedRowKeys: true,
                                onChange: onRowSelectChange,
                            }}
                            // defaultExpandedRowKeys={selectedCategory}
                            loading={isLoadingCategory}
                            dataSource={tableData}
                            columns={columns}
                            expandable={{
                                childrenColumnName: 'children',
                                expandIcon: ({ expanded, record, onExpand }) =>
                                    record.children ? (
                                        langDirection === 'rtl' ? (
                                            <img
                                                src={ChevronUp}
                                                className={`transition-transform transform !ml-5 cursor-pointer ${
                                                    expanded ? 'rotate-270' : 'rotate-90'
                                                }`}
                                                alt='expandIcon'
                                                onClick={(e) => onExpand(record, e)}
                                            />
                                        ) : (
                                            <img
                                                src={ChevronUp}
                                                className={`transition-transform transform mr-5 cursor-pointer ${
                                                    expanded ? 'rotate-270' : '-rotate-90'
                                                }`}
                                                alt='expandIcon'
                                                onClick={(e) => onExpand(record, e)}
                                            />
                                        )
                                    ) : null,
                            }}
                            scroll={{}}
                            // pagination={false}
                            pagination={{
                                pageSize: itemsPerPageFromEnv,
                                showSizeChanger: false,
                                pageSizeOptions: false,
                                hideOnSinglePage: true,
                            }}
                        />
                    </Spin>
                </Content>
            </Content>
        </Content>
    )
}

export default AddCategoryToDiscount
