import { Layout, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DynamicComponent from '../../components/Layouts/DynamicComponent/DynamicComponent'

const { Content } = Layout
const { Title } = Typography

const SelectedLayoutViewModalViewer = ({ selectedLayoutName, selectedLayoutTitle }) => {
    const { t } = useTranslation()
    return (
        <Content className='mt-3 p-3'>
            <Title level={4} className='!font-normal'>
                {t('categories:paragraph5')}
            </Title>
            {DynamicComponent(selectedLayoutName, selectedLayoutTitle, 345, 'bg-slate-100')}
        </Content>
    )
}

export default SelectedLayoutViewModalViewer
