import Cookies from 'js-cookie'
import util from '../../util/common'
export const SetupInterceptors = (http) => {
    let storeName = window.sessionStorage.getItem('client')
    http.interceptors.request.use(
        // `req` is the Axios request config, so you can modify the `headers`.
        // Automatically sets the authorization header in every request because of this request interceptor
        (req) => {
            // debugger;
            req.headers.authorization = util.getAuthToken()
            return req
        },
        (error) => {
            // debugger;
            return Promise.reject(error)
        }
    )

    http.interceptors.response.use(
        function (response) {
            // debugger;
            // console.log("SetupInterceptors-response", response);
            return response
        },
        function (error) {
            // debugger;
            // let isAuthenticated = sessionStorage.getItem("smp_is_authorized");
            let isAuthenticated = Cookies.get(storeName + '_' + 'smp_is_authorized')

            const originalConfig = error.config
            if (error.response) {
                if (error.response.status === 401 && !originalConfig._retry && String(isAuthenticated) === 'true') {
                    originalConfig._retry = true
                    Cookies.set(storeName + '_' + 'smp_is_authorized', false)
                    window.location.replace(window.location.origin + '/logout')
                    return Promise.reject(error)
                }
                if (error.response.status === 'ANOTHER_STATUS_CODE') {
                    // Do something
                    return Promise.reject(error.response.data)
                }
            }
            return Promise.reject(error)
        }
    )
}

export default SetupInterceptors
