//!Importing Libraries
import { Button, Layout, Radio, Spin, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

//! Importing user defined functions

//! Importing user defined components

import { useTranslation } from 'react-i18next'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import GetAttributesGroup from './GetAttributeGroup'
//! Destructuring imported Libraries
const { Group } = Radio
const { Content } = Layout

//! reading from .env file
const productTemplateMappingAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_MAPPING_API
const storeProductTypeUpdateRevisionAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_UPDATE_API

const AttributeGroups = ({ seletedTemplateData, setResponseFromGroupAttribute, viewType, type, permission }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [revisionValue, setRevisionValue] = useState(null)
    const [revisionRadioFilterData, setRevisionRadioFilterData] = useState([])
    const [attributesProps, setAttributesProps] = useState()
    const [selectedRevisionNumber, setSelectedRevisionNumber] = useState()
    const [storeProductTypePutCallData, setStoreProductTypePutCallData] = useState([])

    const productTemplateSelectedInfoReduxData = useSelector(
        (state) => state.reducerProducts.productTemplateSelectedInfo
    )

    const [disableUpdateButton, setDisableUpdateButton] = useState(true)
    const [langDirection, setLangDirection] = useState('ltr')

    const revisionChange = (value) => {
        console.log('value----->',value)
        setRevisionValue(value)
        setDisableUpdateButton(false)
        if (revisionRadioFilterData.length > 0) {
            const filterRevisionNumber = revisionRadioFilterData.filter((ele) => ele.value === value)
            if (filterRevisionNumber && filterRevisionNumber.length > 0) {
                setSelectedRevisionNumber(filterRevisionNumber[0].revisionNumber)
            }
        }
        setAttributesProps({
            revisionId: parseInt(value),
            revisionType: seletedTemplateData.type,
            isParentHierarchy: false,
            isCoreAttribute: type === 'ProductRevision' ? false : true,
            isAttributeGroupNameVisible: false,
            isAttributeData: null,
            isStaticData: null,
            isDispatch: false,
            isAttributeDisabled: true,
            isEditOutlinedVisible: false,
            isMultiLingualIconVisible: false,
        })
        let copyStoreProductTypePutCallData = [...storeProductTypePutCallData]

        let index =
            copyStoreProductTypePutCallData &&
            copyStoreProductTypePutCallData.findIndex(
                (ele) => ele.store_product_type === parseInt(seletedTemplateData.selectedProductTypeId)
            )
        if (index !== -1) {
            copyStoreProductTypePutCallData[index]['store_product_type_revision'] = parseInt(value)
            setStoreProductTypePutCallData(copyStoreProductTypePutCallData)
        }
    }

    const handleSaveClick = () => {
        validateProductMappingPostField()
    }
    //! validation for postProductTemplateMappingAPI
    const validateProductMappingPostField = () => {
        if (seletedTemplateData && seletedTemplateData.type === 'ProductRevision') {
            if (seletedTemplateData && seletedTemplateData.action === 'Add') {
                postProductTemplateMappingAPI()
            } else {
                putProductTemplateMappingAPI()
            }
        } else if (seletedTemplateData && seletedTemplateData.type === 'StoreProductTypeRevision') {
            if (seletedTemplateData && seletedTemplateData.action === 'Edit') {
                putStoreProductTypeRevisionMapping()
            } else {
            }
        }
    }

    //! POST call for ProductTemplateMapping
    const postProductTemplateMappingAPI = () => {
        setIsLoading(true)
        const postBody = {
            product_template_id:
                productTemplateSelectedInfoReduxData && productTemplateSelectedInfoReduxData[0].productTemplateId,
            product_template_revision_id:
                productTemplateSelectedInfoReduxData && productTemplateSelectedInfoReduxData[0].id,

            parent_template_id: seletedTemplateData && seletedTemplateData.productTemplateId,
            mapping_revision_id: revisionValue,
        }
        console.log('postBody', postBody)

        MarketplaceServices.save(productTemplateMappingAPI, postBody)
            .then((response) => {
                console.log('Success Response of postProductTemplateMappingAPI', response)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                setDisableUpdateButton(true)
                const tempObject = {
                    template_mapping_id: response.data.response_body.id,
                    parent_product_template: response.data.response_body.parent_template_id,
                    mapping_revision_id: response.data.response_body.mapping_revision_id,
                    mapping_revision_id__revision_number: selectedRevisionNumber,
                }
                setResponseFromGroupAttribute([tempObject])
            })
            .catch((error) => {
                console.log('Error Response of postProductTemplateMappingAPI', error)
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
                setResponseFromGroupAttribute('error')
            })
    }

    //! Put call for ProductTemplateMapping
    const putProductTemplateMappingAPI = () => {
        setIsLoading(true)
        const temp = {
            parent_template_id: seletedTemplateData && seletedTemplateData.productTemplateId,
            mapping_revision_id: parseInt(revisionValue),
        }

        let tempParams = {
            template_mapping_id: seletedTemplateData && seletedTemplateData.mappingId,
        }
        MarketplaceServices.update(productTemplateMappingAPI, temp, tempParams)
            .then((response) => {
                console.log('Success Response of putProductTemplateMappingAPI', response)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                setDisableUpdateButton(true)
                const tempObject = {
                    template_mapping_id: response.data.response_body[0].id,
                    parent_product_template: response.data.response_body[0].parent_template_id,
                    mapping_revision_id: response.data.response_body[0].mapping_revision_id,
                    mapping_revision_id__revision_number: selectedRevisionNumber,
                }
                setResponseFromGroupAttribute([tempObject])
            })
            .catch((error) => {
                console.log('Error Response  are in catch Block of putProductTemplateMappingAPI', error)
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
                setResponseFromGroupAttribute('error')
            })
    }

    const putStoreProductTypeRevisionMapping = () => {
        setIsLoading(true)
        let updateProductTypeRevisionAPI = storeProductTypeUpdateRevisionAPI.replace(
            '{product_template_revision_id}',
            String(seletedTemplateData && seletedTemplateData.productTemplateId)
        )
        const temp = {
            store_product_type: storeProductTypePutCallData,
        }
        console.log('putBody', temp)

        MarketplaceServices.update(updateProductTypeRevisionAPI, temp)
            .then((response) => {
                console.log('Success Response of putProductTemplateMappingAPI', response.data)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                setDisableUpdateButton(true)
                const tempObject = {
                    storeProductType: response.data.response_body.store_product_type,
                    revision_number: selectedRevisionNumber,
                    revision_id: revisionValue,
                }
                setResponseFromGroupAttribute([tempObject])
            })
            .catch((error) => {
                console.log('Error Response  are in catch Block of putProductTemplateMappingAPI', error.response)
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
                setResponseFromGroupAttribute('error')
            })
    }

    useEffect(() => {
        if (seletedTemplateData && seletedTemplateData.revisionData) {
            if (seletedTemplateData && seletedTemplateData.revisionData.length > 0) {
                const seletedTemplateDataArray = []
                for (let index = 0; index < seletedTemplateData.revisionData.length; index++) {
                    const element = {}
                    element['id'] = seletedTemplateData.revisionData[index].revision_id
                    element['revisionNumber'] = seletedTemplateData.revisionData[index].revision_number
                    element['label'] = 'V' + Number(seletedTemplateData.revisionData[index].revision_number).toFixed(1)
                    element['value'] = seletedTemplateData.revisionData[index].revision_id
                    seletedTemplateDataArray.push(element)
                }
                setRevisionRadioFilterData(seletedTemplateDataArray)
                if (type === 'ProductRevision') {
                    setDisableUpdateButton(false)
                } else {
                    setDisableUpdateButton(true)
                }
                setRevisionValue(seletedTemplateData.selectedRevision)
                if (seletedTemplateDataArray.length > 0) {
                    const filterRevisionNumber = seletedTemplateDataArray.filter(
                        (ele) => ele.value === seletedTemplateData.selectedRevision
                    )
                    if (filterRevisionNumber && filterRevisionNumber.length > 0) {
                        setSelectedRevisionNumber(filterRevisionNumber[0].revisionNumber)
                    }
                }
                setAttributesProps({
                    revisionId: seletedTemplateData.selectedRevision,
                    revisionType: seletedTemplateData.type,
                    isParentHierarchy: false,
                    isCoreAttribute: type === 'ProductRevision' ? false : true,
                    isAttributeGroupNameVisible: false,
                    isAttributeData: null,
                    isStaticData: null,
                    isDispatch: false,
                    isAttributeDisabled: true,
                    isEditOutlinedVisible: false,
                    isMultiLingualIconVisible: false,
                })
            } else {
                setRevisionRadioFilterData([])
            }
        }
        if (seletedTemplateData && seletedTemplateData.storeProductTypePutData) {
            const storeProductTypePutData = []
            for (var i = 0; i < seletedTemplateData.storeProductTypePutData.length; i++) {
                const element = {}
                element['store_product_type'] = seletedTemplateData.storeProductTypePutData[i].store_product_type_id
                element['store_product_type_revision'] =
                    seletedTemplateData.storeProductTypePutData[i].store_product_type_revision_id
                storeProductTypePutData.push(element)
            }
            setStoreProductTypePutCallData(storeProductTypePutData)
        }
    }, [seletedTemplateData])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    return (
        <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
            <Content>
                <p className='mb-2 text-md'>{t('product_types:choose_a_version')}</p>
                <Content className='mb-3'>
                    <Select
                        onChange={revisionChange}
                        value={revisionValue}
                        options={revisionRadioFilterData}
                        style={{
                            width: 120,
                        }}
                    />
                </Content>
                <Content className='mb-3'>
                    {revisionValue !== null ? (
                        <GetAttributesGroup publishingProductsPropsData={attributesProps} viewType='modal' />
                    ) : (
                        <></>
                    )}
                </Content>
                <Content className={`text-${langDirection === 'rtl' ? 'left' : 'right'} mt-4`}>
                    <Button
                        className={`app-btn-secondary !mr-2 ${langDirection === 'rtl' ? 'ml-2' : ''}`}
                        onClick={() => {
                            setResponseFromGroupAttribute(null)
                        }}>
                        {t('common:discard')}
                    </Button>
                    <Button
                        disabled={
                            seletedTemplateData?.action === 'Add' ||
                            seletedTemplateData.selectedRevision != revisionValue
                                ? false
                                : true
                        }
                        className={`app-btn-primary`}
                        onClick={() => {
                            handleSaveClick()
                        }}>
                        {seletedTemplateData && seletedTemplateData.action === 'Add'
                            ? `${t('common:save')}`
                            : `${t('common:update')}`}
                    </Button>
                </Content>
            </Content>
        </Spin>
    )
}

export default AttributeGroups
