import React from 'react'

const ProgressBar = ({ count, total }) => {
    const progress = (count / total) * 100

    const progressBarStyle = {
        width: '150px',
        height: '5px',
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        overflow: 'hidden',
    }

    const progressStyle = {
        width: `${progress}%`,
        backgroundColor: '#4A2D73',
        height: '100%',
        borderRadius: '4px',
        transition: 'width 0.3s ease-in-out',
    }

    return (
        <div className='flex justify-center items-center'>
            <div style={progressBarStyle}>
                <div style={progressStyle}></div>
            </div>
        </div>
    )
}

export default ProgressBar
