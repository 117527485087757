import { useEffect, useState } from 'react'
import MarketplaceServices from '../services/axios/MarketplaceServices'
import productListsDataProcessor from '../constants/dataProcessor/productsListDataProcessor'

const listProductsAPI = process.env.REACT_APP_LIST_PRODUCTS_API
const useGetActiveProductsList = (searchKey, additionalParams) => {
    const [loading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [apiData, setAPIData] = useState([])
    const [apiProcessedData, setAPIProcessedData] = useState([])
    const [searchKeyTriggered, setSearchKeyTriggered] = useState(false)
    const [totalProducts, setTotalProducts] = useState()
    useEffect(() => {
        let params = {}
        params = { ...additionalParams }
        params['product-status'] = 3
        if (searchKey) {
            params['search'] = String(searchKey)
            setSearchKeyTriggered(true)
        }
        setIsLoading(true)
        MarketplaceServices.findAll(listProductsAPI, params, true)
            .then(function (response) {
                console.log(
                    'Server response from findAllProductLists Hook is',
                    listProductsAPI,
                    response.data.response_body
                )
                setAPIData(response.data.response_body)
                setTotalProducts(response.data.response_body.count)
                setAPIProcessedData(productListsDataProcessor(response.data.response_body.product_data))
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Server Error response from findAllProductLists hook ', listProductsAPI, error.response)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }, [searchKey, additionalParams])

    return {
        loading,
        isNetworkError,
        apiData,
        apiProcessedData,
        searchKeyTriggered,
        totalProducts
    }
}
export default useGetActiveProductsList
