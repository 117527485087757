import { EyeOutlined, TranslationOutlined, UndoOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Divider, Input, Layout, Row, Select, Space, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnContentSettingInfo, fnStoreLogoInfo } from '../../services/redux/actions/ActionContentSetting'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import TranslatorModal from '../product-category/TranslatorModal'
import Preview from './Preview'
import StoreImages from './StoreImages'
import StoreJourney from './StoreJourney'
import { useAuth } from 'react-oidc-context'
import { MdInfo } from 'react-icons/md'

const { Content } = Layout
const { Title, Paragraph } = Typography

const storeSettingAPI = process.env.REACT_APP_STORE_FRONT_SETTINGS_API
const storeImagesAPI = process.env.REACT_APP_STORE_IMAGES_API
const storeBannerImageAPI = process.env.REACT_APP_STORE_BANNER_IMAGES_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const storeSettingsRestoreFactorAPI = process.env.REACT_APP_STORE_FRONT_SETTINGS_RESTORE_FACTOR_API
const storeCurrencyAPI = process.env.REACT_APP_STORE_CURRENCY_API
const currencyAPI = process.env.REACT_APP_CHANGE_CURRENCY_API
const vendorAPI = process.env.REACT_APP_GET_VENDOR_API
const StoreSettings = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const auth = useAuth()
    const permissionValue = util.getPermissionData() || []
    usePageTitle(t('common:store_management_portal') + ' - ' + String(` ${t('common:store_settings')}`))

    //! storeSettingsData
    const storeSettingsInfo = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const [currencySymbol, setCurrencySymbol] = useState('')
    const [currencyIsoCode, setCurrencyIsoCode] = useState('')

    const [pageBackgroundColor, setPageBackgroundColor] = useState('#EBEBEB')
    const [pageBgColor, setPageBgColor] = useState('#EBEBEB')
    const [foreGroundColor, setForeGroundColor] = useState('#333333')
    const [pageFgColor, setPageFgColor] = useState('#333333')
    const [buttonPrimaryBackgroundColor, setButtonPrimaryBackgroundColor] = useState('#000000')
    const [btnPrimaryBgColor, setbtnPrimaryBgColor] = useState('#000000')
    const [buttonSecondaryBackgroundColor, setButtonSecondaryBackgroundColor] = useState('#000000')
    const [btnSecondaryBgColor, setbtnSecondaryBgColor] = useState('#000000')
    // const [buttonTeritaryBackgroundColor, setButtonTeritaryBackgroundColor] = useState('#000000')
    // const [btnTeritaryBgColor, setbtnTeritaryBgColor] = useState('#000000')
    const [buttonPrimaryForegroundColor, setButtonPrimaryForegroundColor] = useState('#000000')
    const [btnPrimaryFgColor, setbtnPrimaryFgColor] = useState('#000000')
    const [buttonSecondaryForegroundColor, setButtonSecondaryForegroundColor] = useState('#000000')
    const [btnSecondaryFgColor, setbtnSecondaryFgColor] = useState('#000000')
    const [buttonTeritaryForegroundColor, setButtonTeritaryForegroundColor] = useState('#000000')
    const [btnTeritaryFgColor, setbtnTeritaryFgColor] = useState('#000000')
    const [footerBackgroundColor, setFooterBackgroundColor] = useState('#000000')
    const [footerBgColor, setFooterBgColor] = useState('#000000')
    const [footerForegroundColor, setFooterForegroundColor] = useState('#000000')
    const [footerFgColor, setFooterFgColor] = useState('#000000')
    const [headerBackgroundColor, setHeaderBackgroundColor] = useState('#000000')
    const [headerBgColor, setHeaderBgColor] = useState('#000000')
    const [headerForegroundColor, setHeaderForegroundColor] = useState('#000000')
    const [headerFgColor, setHeaderFgColor] = useState('#000000')
    const [isLoading, setIsLoading] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [imagesUpload, setImagesUpload] = useState([])
    const [getImageData, setGetImageData] = useState([])
    const [validStoreLogo, setValidStoreLogo] = useState(false)
    const [isUpLoading, setIsUpLoading] = useState(false)
    const [copyImageOfStoreSettingsPageTheme, setCopyImageOfStoreSettingsPageTheme] = useState()
    const [copyImageOfStoreHeaderSetting, setCopyImageOfStoreHeaderSetting] = useState()
    const [copyImageOfStoreFooterSetting, setCopyImageOfStoreFooterSetting] = useState()
    const [imageOfStoreSettingsPageTheme, setImageOfStoreSettingsPageTheme] = useState()
    const [imageOfStoreHeaderSettings, setImageOfStoreHeaderSettings] = useState()
    const [imageOfStoreFooterSettings, setImageOfStoreFooterSettings] = useState()
    const [bannerAbsoluteImage, setBannerAbsoluteImage] = useState([])
    const [isCurrencyLoading, setIsCurrencyLoading] = useState(false)
    const [currencyOnChange, setCurrencyOnChange] = useState(false)
    const [filteredCurrencyData, setFilteredCurrencyData] = useState([])
    const [currencyData, setCurrencyData] = useState([])
    const [vendorsList, setVendorsList] = useState([])
    const [colorCodeValidation, setColorCodeValidation] = useState({
        pageBgColorValidation: false,
        pageTextColorValidation: false,
        primaryBgValidation: false,
        secondaryBgValidation: false,
        tertiaryBgValidation: false,
        primaryTextValidation: false,
        secondaryTextValidation: false,
        tertiaryTextValidation: false,
        headerBgValidation: false,
        headerTextValidation: false,
        footerBgValidation: false,
        footerTextValidation: false,
    })
    const [onChangeValues, setOnChangeValues] = useState(false)
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)
    const [resetModalOpen, setResetModalOpen] = useState(false)
    const [resetLoader, setResetLoader] = useState(false)
    const [storeId, setStoreId] = useState()
    const [storeSettingsData, setStoreSettingsData] = useState([])
    const [loadingSkelton, setLoadingSkelton] = useState(false)

    //! images get call loading and network variables
    const [imagesLoading, setImagesLoading] = useState(true)
    const [imagesNetWorkError, setImagesNetWorkError] = useState(false)

    //! store currency get call loading and network variables

    const [storeCurrencyLoading, setStoreCurrencyLoading] = useState(true)
    const [storeCurrencyNetWorkError, setStoreCurrencyNetWorkError] = useState(false)

    //! store page theme get call loading and network variables

    const [storePageThemeLoading, setStorePageThemeLoading] = useState(true)
    const [storePageThemeNetWorkError, setStorePageThemeNetWorkError] = useState(false)

    const [langDirection, setLangDirection] = useState(util.getSelectedLanguageDirection() || 'LTR')
    const [selectedTab, setSelectedTab] = useState(0)

    const [currencyChangeFLag, setCurrencyChangeFlag] = useState(false)
    const [selectedCurrency, setSelectedCurrency] = useState(null)

    const mainTabData = [
        {
            key: 0,
            label: <p className={`!mb-0 ${langDirection === 'RTL' ? 'ml-7' : ''}`}>{t('store_settings:media')}</p>,
            value: 0,
        },
        {
            key: 1,
            label: <p className='!mb-0'>{t('store_settings:currency')}</p>,
            value: 1,
        },
        {
            key: 2,
            label: <p className='!mb-0'>{t('store_settings:theme')}</p>,
            value: 2,
        },
        {
            key: 3,
            label: <p className='!mb-0'>{t('product_types:product_type_journey_label')}</p>,
            value: 3,
        },
    ]

    const handleMainTabChange = (tabKey) => {
        setSelectedTab(tabKey)
    }

    //! get call of  getStoreSettingApi
    const findAllWithoutPageStoreSettingApi = () => {
        MarketplaceServices.findAllWithoutPage(storeSettingAPI)
            .then(function (response) {
                console.log('Get response of Store setting--->', response.data.response_body.store_settings_data)
                setStorePageThemeLoading(false)
                setStoreSettingsData(response.data.response_body.store_settings_data)
                setStoreId(response.data.response_body.store_settings_data[0].store_id)
                setCopyImageOfStoreSettingsPageTheme(
                    response.data.response_body.store_settings_data[0].store_page_settings[0]
                )
                setImageOfStoreSettingsPageTheme(
                    response.data.response_body.store_settings_data[0].store_page_settings[0]
                )
                setCopyImageOfStoreHeaderSetting(
                    response.data.response_body.store_settings_data[0].store_header_settings[0]
                )
                setImageOfStoreHeaderSettings(
                    response.data.response_body.store_settings_data[0].store_header_settings[0]
                )
                setCopyImageOfStoreFooterSetting(
                    response.data.response_body.store_settings_data[0].store_footer_settings[0]
                )
                setImageOfStoreFooterSettings(
                    response.data.response_body.store_settings_data[0].store_footer_settings[0]
                )
                setCurrencySymbol(response.data.response_body.store_settings_data[0].store_currency[0].symbol)
                setCurrencyIsoCode(response?.data?.response_body?.store_settings_data[0]?.store_currency[0]?.iso_code)

                setPageBackgroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].bg_color
                )
                setPageBgColor(response.data.response_body.store_settings_data[0].store_page_settings[0].bg_color)
                setForeGroundColor(response.data.response_body.store_settings_data[0].store_page_settings[0].fg_color)
                setPageFgColor(response.data.response_body.store_settings_data[0].store_page_settings[0].fg_color)
                setButtonPrimaryBackgroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_primary_bg_color
                )
                setbtnPrimaryBgColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_primary_bg_color
                )
                setButtonPrimaryForegroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_primary_fg_color
                )
                setbtnPrimaryFgColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_primary_fg_color
                )
                setButtonSecondaryBackgroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_secondary_bg_color
                )
                setbtnSecondaryBgColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_secondary_bg_color
                )
                setButtonSecondaryForegroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_secondary_fg_color
                )
                setbtnSecondaryFgColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_secondary_fg_color
                )
                // setButtonTeritaryBackgroundColor(
                //     response.data.response_body.store_settings_data[0].store_page_settings[0].btn_tertiary_bg_color
                // )
                // setbtnTeritaryBgColor(
                //     response.data.response_body.store_settings_data[0].store_page_settings[0].btn_tertiary_bg_color
                // )
                setButtonTeritaryForegroundColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_tertiary_fg_color
                )
                setbtnTeritaryFgColor(
                    response.data.response_body.store_settings_data[0].store_page_settings[0].btn_tertiary_fg_color
                )
                setHeaderBackgroundColor(
                    response.data.response_body.store_settings_data[0].store_header_settings[0].bg_color
                )
                setHeaderBgColor(response.data.response_body.store_settings_data[0].store_header_settings[0].bg_color)
                setHeaderForegroundColor(
                    response.data.response_body.store_settings_data[0].store_header_settings[0].fg_color
                )
                setHeaderFgColor(response.data.response_body.store_settings_data[0].store_header_settings[0].fg_color)
                setFooterBackgroundColor(
                    response.data.response_body.store_settings_data[0].store_footer_settings[0].bg_color
                )
                setFooterBgColor(response.data.response_body.store_settings_data[0].store_footer_settings[0].bg_color)
                setFooterForegroundColor(
                    response.data.response_body.store_settings_data[0].store_footer_settings[0].fg_color
                )
                setFooterFgColor(response.data.response_body.store_settings_data[0].store_footer_settings[0].fg_color)
            })
            .catch((error) => {
                console.log('error response from store settings API', error)
                setStorePageThemeLoading(false)
                setStorePageThemeNetWorkError(true)
                if (error.response === undefined) {
                    setPageBackgroundColor('#EBEBEB')
                    setButtonPrimaryBackgroundColor('#000000')
                    setButtonSecondaryBackgroundColor('#000000')
                    // setButtonTeritaryBackgroundColor('#000000')
                    setButtonPrimaryForegroundColor('#000000')
                    setButtonSecondaryForegroundColor('#000000')
                    setButtonTeritaryForegroundColor('#000000')
                    setForeGroundColor('#333333')
                    setFooterBackgroundColor('#000000')
                    setFooterForegroundColor('#000000')
                    setHeaderForegroundColor('#000000')
                    setHeaderBackgroundColor('#000000')
                }
            })
    }

    //! get call for store Settings Restore Factor API
    const updateStoreSettingsRestoreApi = () => {
        // setIsLoading(true);
        setResetLoader(true)
        MarketplaceServices.update(storeSettingsRestoreFactorAPI)
            .then((response) => {
                setIsLoading(false)
                setOnChangeValues(false)

                console.log(
                    'success response  for Store Settings Restore Factory ',
                    storeSettingsRestoreFactorAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                setColorCodeValidation(false)
                setResetModalOpen(false)
                setResetLoader(false)
                setCopyImageOfStoreSettingsPageTheme(response.data.response_body.store_page_settings[0])
                setImageOfStoreSettingsPageTheme(response.data.response_body.store_page_settings[0])
                setCopyImageOfStoreHeaderSetting(response.data.response_body.store_header_settings[0])
                setImageOfStoreHeaderSettings(response.data.response_body.store_header_settings[0])
                setCopyImageOfStoreFooterSetting(response.data.response_body.store_footer_settings[0])
                setImageOfStoreFooterSettings(response.data.response_body.store_footer_settings[0])
                setPageBackgroundColor(response.data.response_body.store_page_settings[0].bg_color)
                setPageBgColor(response.data.response_body.store_page_settings[0].bg_color)
                setForeGroundColor(response.data.response_body.store_page_settings[0].fg_color)
                setPageFgColor(response.data.response_body.store_page_settings[0].fg_color)
                setButtonPrimaryBackgroundColor(response.data.response_body.store_page_settings[0].btn_primary_bg_color)
                setbtnPrimaryBgColor(response.data.response_body.store_page_settings[0].btn_primary_bg_color)
                setButtonPrimaryForegroundColor(response.data.response_body.store_page_settings[0].btn_primary_fg_color)
                setbtnPrimaryFgColor(response.data.response_body.store_page_settings[0].btn_primary_fg_color)
                setButtonSecondaryBackgroundColor(
                    response.data.response_body.store_page_settings[0].btn_secondary_bg_color
                )
                setbtnSecondaryBgColor(response.data.response_body.store_page_settings[0].btn_secondary_bg_color)
                setButtonSecondaryForegroundColor(
                    response.data.response_body.store_page_settings[0].btn_secondary_fg_color
                )
                setbtnSecondaryFgColor(response.data.response_body.store_page_settings[0].btn_secondary_fg_color)
                // setButtonTeritaryBackgroundColor(
                //     response.data.response_body.store_page_settings[0].btn_tertiary_bg_color
                // )
                // setbtnTeritaryBgColor(response.data.response_body.store_page_settings[0].btn_tertiary_bg_color)
                setButtonTeritaryForegroundColor(
                    response.data.response_body.store_page_settings[0].btn_tertiary_fg_color
                )
                setbtnTeritaryFgColor(response.data.response_body.store_page_settings[0].btn_tertiary_fg_color)
                setHeaderBackgroundColor(response.data.response_body.store_header_settings[0].bg_color)
                setHeaderBgColor(response.data.response_body.store_header_settings[0].bg_color)
                setHeaderForegroundColor(response.data.response_body.store_header_settings[0].fg_color)
                setHeaderFgColor(response.data.response_body.store_header_settings[0].fg_color)
                setFooterBackgroundColor(response.data.response_body.store_footer_settings[0].bg_color)
                setFooterBgColor(response.data.response_body.store_footer_settings[0].bg_color)
                setFooterForegroundColor(response.data.response_body.store_footer_settings[0].fg_color)
                setFooterFgColor(response.data.response_body.store_footer_settings[0].fg_color)
            })
            .catch((error) => {
                // setIsLoading(false);
                setResetLoader(false)
                console.log('ERROR response  for Store Settings Restore Factory ', storeSettingsRestoreFactorAPI, error)
            })
    }

    //! post call for store settings
    const saveStoreSettingsCall = () => {
        const postBody = {
            store_page_settings: [
                {
                    bg_color: pageBackgroundColor,
                    fg_color: foreGroundColor,
                    btn_primary_bg_color: buttonPrimaryBackgroundColor,
                    btn_secondary_bg_color: buttonSecondaryBackgroundColor,
                    btn_primary_fg_color: buttonPrimaryForegroundColor,
                    btn_secondary_fg_color: buttonSecondaryForegroundColor,
                    btn_tertiary_fg_color: buttonTeritaryForegroundColor,
                },
            ],
            store_header_settings: [
                {
                    bg_color: headerBackgroundColor,
                    fg_color: headerForegroundColor,
                },
            ],
            store_footer_settings: [
                {
                    bg_color: footerBackgroundColor,
                    fg_color: footerForegroundColor,
                },
            ],
        }
        setIsLoading(true)
        MarketplaceServices.save(storeSettingAPI, postBody)
            .then((response) => {
                console.log('Server Success Response From storeSettingPostCall', response.data.response_body)
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
                setOnChangeValues(false)
                setCopyImageOfStoreSettingsPageTheme(response.data.response_body.store_page_settings[0])
                setImageOfStoreSettingsPageTheme(response.data.response_body.store_page_settings[0])
                setCopyImageOfStoreHeaderSetting(response.data.response_body.store_header_settings[0])
                setImageOfStoreHeaderSettings(response.data.response_body.store_header_settings[0])
                setCopyImageOfStoreFooterSetting(response.data.response_body.store_footer_settings[0])
                setImageOfStoreFooterSettings(response.data.response_body.store_footer_settings[0])
                setPageBackgroundColor(response.data.response_body.store_page_settings[0].bg_color)
                setPageBgColor(response.data.response_body.store_page_settings[0].bg_color)
                setForeGroundColor(response.data.response_body.store_page_settings[0].fg_color)
                setPageFgColor(response.data.response_body.store_page_settings[0].fg_color)
                setButtonPrimaryBackgroundColor(response.data.response_body.store_page_settings[0].btn_primary_bg_color)
                setbtnPrimaryBgColor(response.data.response_body.store_page_settings[0].btn_primary_bg_color)
                setButtonPrimaryForegroundColor(response.data.response_body.store_page_settings[0].btn_primary_fg_color)
                setbtnPrimaryFgColor(response.data.response_body.store_page_settings[0].btn_primary_fg_color)
                setButtonSecondaryBackgroundColor(
                    response.data.response_body.store_page_settings[0].btn_secondary_bg_color
                )
                setbtnSecondaryBgColor(response.data.response_body.store_page_settings[0].btn_secondary_bg_color)
                setButtonSecondaryForegroundColor(
                    response.data.response_body.store_page_settings[0].btn_secondary_fg_color
                )
                setbtnSecondaryFgColor(response.data.response_body.store_page_settings[0].btn_secondary_fg_color)
                // setButtonTeritaryBackgroundColor(
                //     response.data.response_body.store_page_settings[0].btn_tertiary_bg_color
                // )
                // setbtnTeritaryBgColor(response.data.response_body.store_page_settings[0].btn_tertiary_bg_color)
                setButtonTeritaryForegroundColor(
                    response.data.response_body.store_page_settings[0].btn_tertiary_fg_color
                )
                setbtnTeritaryFgColor(response.data.response_body.store_page_settings[0].btn_tertiary_fg_color)
                setHeaderBackgroundColor(response.data.response_body.store_header_settings[0].bg_color)
                setHeaderBgColor(response.data.response_body.store_header_settings[0].bg_color)
                setHeaderForegroundColor(response.data.response_body.store_header_settings[0].fg_color)
                setHeaderFgColor(response.data.response_body.store_header_settings[0].fg_color)
                setFooterBackgroundColor(response.data.response_body.store_footer_settings[0].bg_color)
                setFooterBgColor(response.data.response_body.store_footer_settings[0].bg_color)
                setFooterForegroundColor(response.data.response_body.store_footer_settings[0].fg_color)
                setFooterFgColor(response.data.response_body.store_footer_settings[0].fg_color)
            })
            .catch((error) => {
                console.log('Error Response From storeSettingPostCall', error)
                setIsLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //! validations of store settings API
    const validatePostStoreSetting = () => {
        if (
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.bg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.bg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_primary_bg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_primary_bg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_primary_fg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_primary_fg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_secondary_bg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_secondary_bg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_secondary_fg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_secondary_fg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_tertiary_bg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_tertiary_bg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.btn_tertiary_fg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.btn_tertiary_fg_color) &&
            (imageOfStoreSettingsPageTheme && imageOfStoreSettingsPageTheme.fg_color) ===
                (copyImageOfStoreSettingsPageTheme && copyImageOfStoreSettingsPageTheme.fg_color) &&
            (imageOfStoreHeaderSettings && imageOfStoreHeaderSettings.bg_color) ===
                (copyImageOfStoreHeaderSetting && copyImageOfStoreHeaderSetting.bg_color) &&
            (imageOfStoreHeaderSettings && imageOfStoreHeaderSettings.fg_color) ===
                (copyImageOfStoreHeaderSetting && copyImageOfStoreHeaderSetting.fg_color) &&
            (imageOfStoreFooterSettings && imageOfStoreFooterSettings.bg_color) ===
                (copyImageOfStoreFooterSetting && copyImageOfStoreFooterSetting.bg_color) &&
            (imageOfStoreFooterSettings && imageOfStoreFooterSettings.fg_color) ===
                (copyImageOfStoreFooterSetting && copyImageOfStoreFooterSetting.fg_color) &&
            imagesUpload.length === 0
        ) {
            MarketplaceToaster.showToast(util.getToastObject(`${t('languages:no_changes_were_detected')}`, 'info'))
        } else if (
            colorCodeValidation.pageBgColorValidation === true ||
            colorCodeValidation.pageTextColorValidation === true ||
            colorCodeValidation.primaryBgValidation === true ||
            colorCodeValidation.secondaryBgValidation === true ||
            colorCodeValidation.tertiaryBgValidation === true ||
            colorCodeValidation.primaryTextValidation === true ||
            colorCodeValidation.secondaryTextValidation === true ||
            colorCodeValidation.tertiaryTextValidation === true ||
            colorCodeValidation.headerBgValidation === true ||
            colorCodeValidation.headerTextValidation === true ||
            colorCodeValidation.footerBgValidation === true ||
            colorCodeValidation.footerTextValidation === true
        ) {
            MarketplaceToaster.showToast(util.getToastObject(`${t('store_settings:color_validation')}`, 'error'))
        } else {
            saveStoreSettingsCall()
        }
    }

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    //! get call of store images
    const findAllWithoutPageStoreImagesApi = () => {
        MarketplaceServices.findAllWithoutPage(storeImagesAPI)
            .then(function (response) {
                console.log('Get response of Store setting Images--->', response.data.response_body)
                setImagesLoading(false)

                let data = []
                data.push(response.data.response_body)
                setGetImageData(data)
            })
            .catch((error) => {
                setImagesLoading(false)
                setImagesNetWorkError(true)
                console.log('error response from images--->', error)
                setGetImageData([])
            })
    }

    //! post call of store images
    const saveStoreLogoImageCall = () => {
        const formData = new FormData()
        if (imagesUpload && imagesUpload.length > 0) {
            for (var i = 0; i < imagesUpload.length; i++) {
                if (imagesUpload[i].type === 'store_logo') {
                    formData.append('store_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'banner_images') {
                    let localBannerImagesUpload = imagesUpload[i].imageValue
                    for (var j = 0; j < localBannerImagesUpload.length; j++) {
                        formData.append('banner_images', localBannerImagesUpload[j])
                    }
                } else if (imagesUpload[i].type === 'search_logo') {
                    formData.append('search_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'customer_logo') {
                    formData.append('customer_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'cart_logo') {
                    formData.append('cart_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'wishlist_logo') {
                    formData.append('wishlist_logo', imagesUpload[i].imageValue)
                }
            }
        }
        setIsUpLoading(true)
        MarketplaceServices.save(storeImagesAPI, formData)
            .then((response) => {
                console.log('Server Success Response From storeImagePostCall', response.data.response_body)
                if (response.data.response_body && response.data.response_body.store_logo_path) {
                    dispatch(fnStoreLogoInfo(response.data.response_body.store_logo_path))
                }
                MarketplaceToaster.showToast(response)
                setIsUpLoading(false)
                setIsLoading(false)
                setGetImageData([response.data.response_body])
                setImagesUpload([])
            })
            .catch((error) => {
                console.log('Error response from store images post call', error)
                setIsUpLoading(false)
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (getImageData && getImageData.length > 0) {
            findAllWithoutPageStoreBannerImageApi()
        }
    }, [getImageData])

    //!put call of store images
    const updateStoreLogoImageCall = () => {
        const formData = new FormData()
        if (imagesUpload && imagesUpload.length > 0) {
            for (var i = 0; i < imagesUpload.length; i++) {
                if (imagesUpload[i].type === 'store_logo') {
                    formData.append('store_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'banner_images') {
                    let localBannerImagesUpload = imagesUpload[i].imageValue
                    for (var j = 0; j < localBannerImagesUpload.length; j++) {
                        formData.append('banner_images', localBannerImagesUpload[j])
                    }
                } else if (imagesUpload[i].type === 'search_logo') {
                    formData.append('search_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'customer_logo') {
                    formData.append('customer_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'cart_logo') {
                    formData.append('cart_logo', imagesUpload[i].imageValue)
                } else if (imagesUpload[i].type === 'wishlist_logo') {
                    formData.append('wishlist_logo', imagesUpload[i].imageValue)
                }
            }
        }
        setIsUpLoading(true)
        MarketplaceServices.update(storeImagesAPI, formData)
            .then((response) => {
                console.log('API endpoint', storeImagesAPI, 'Server Success Response From storeImagePutCall', response)
                if (response.data.response_body && response.data.response_body.store_logo_path) {
                    dispatch(fnStoreLogoInfo(response.data.response_body.store_logo_path))
                }
                MarketplaceToaster.showToast(response)
                setGetImageData([response.data.response_body])
                setImagesUpload([])
                setIsUpLoading(false)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log('error response from the store images put call', error)
                MarketplaceToaster.showToast(error.response)
                setIsUpLoading(false)
                setIsLoading(false)
            })
    }

    const postImageOnClickSave = () => {
        if (Object.keys(getImageData[0]).length > 0) {
            updateStoreLogoImageCall()
        } else {
            saveStoreLogoImageCall()
        }
    }

    //! get call for store banner images
    const findAllWithoutPageStoreBannerImageApi = () => {
        MarketplaceServices.findAllWithoutPage(storeBannerImageAPI)
            .then(function (response) {
                console.log('Server Response from getstoreBannerImageApi Function: ', response.data.response_body)
                setBannerAbsoluteImage(response.data.response_body)
            })
            .catch((error) => {
                console.log('Server error from banner images  Function ', error.response)
            })
    }

    const translateModalCloseHandler = () => {
        setTranslateModalVisible(false)
        setLoadingSkelton(false)
    }

    //!get call of list currency
    const findByPageCurrencyData = () => {
        MarketplaceServices.findAllWithoutPage(currencyAPI, null, false)
            .then(function (response) {
                console.log('server Success response from currency API call', response.data.response_body.data)
                setStoreCurrencyLoading(false)
                currencyDataProcessor(response.data.response_body.data)
            })
            .catch((error) => {
                setStoreCurrencyLoading(false)
                setStoreCurrencyNetWorkError(true)
                console.log('server error response from currency API call', error.response)
            })
    }

    //!get call of list currency
    const findAllWithoutCurrencyDataByChange = (value) => {
        setIsCurrencyLoading(true)
        MarketplaceServices.findAllWithoutPage(currencyAPI, { currency_code: value }, false)
            .then(function (response) {
                setIsCurrencyLoading(false)
                console.log('server Success response from currency API call', response.data.response_body.data)
                if (response && response.data && response.data.response_body.data.length > 0) {
                    setCurrencyData(response.data.response_body.data)
                    setCurrencySymbol(response.data.response_body.data[0].symbol)
                }
            })
            .catch((error) => {
                setIsCurrencyLoading(false)
                console.log('server error response from currency API call', error.response)
            })
    }

    //! put call for store currency  API
    const updateStoreCurrencyApi = () => {
        setIsCurrencyLoading(true)
        MarketplaceServices.update(
            storeCurrencyAPI,
            {
                currency_id: currencyData && currencyData.length > 0 && currencyData[0].id,
            },
            null
        )
            .then((response) => {
                setIsCurrencyLoading(false)
                setCurrencyOnChange(false)
                setCurrencyIsoCode(response?.data?.response_body?.iso_code)
                let updatedCurrencyData = storeSettingsInfo
                updatedCurrencyData[0].store_currency[0] = response.data.response_body
                dispatch(fnContentSettingInfo(updatedCurrencyData))
                console.log(
                    'success response  for Store currency update API ',
                    storeCurrencyAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                setIsCurrencyLoading(false)
                MarketplaceToaster.showToast(error.response)
                console.log('ERROR response  for Store Settings Restore Factory ', storeSettingsRestoreFactorAPI, error)
            })
    }

    const currencyDataProcessor = (currencyProcessorData) => {
        let localCurrencyData = []
        if (currencyProcessorData && currencyProcessorData.length > 0) {
            for (var i = 0; i < currencyProcessorData.length; i++) {
                const temp = {}
                temp['label'] = currencyProcessorData[i].currency_name
                temp['value'] = currencyProcessorData[i].iso_currency_code
                temp['id'] = currencyProcessorData[i].id
                temp['no_of_decimal'] = currencyProcessorData[i].no_of_decimal
                temp['minimum_amount'] = currencyProcessorData[i].minimum_amount
                temp['unit_price_name'] = currencyProcessorData[i].unit_price_name
                temp['symbol'] = currencyProcessorData[i].symbol
                temp['unit_conversion'] = currencyProcessorData[i].unit_conversion
                temp['iso_currency_code'] = currencyProcessorData[i].iso_currency_code
                temp['currency_name'] = currencyProcessorData[i].currency_name
                localCurrencyData.push(temp)
                setFilteredCurrencyData(localCurrencyData)
            }
            return localCurrencyData
        } else {
            return localCurrencyData
        }
    }

    const closeResetWaringModal = () => {
        setResetModalOpen(false)
    }

    const findAllVendorListAPICall = () => {
        MarketplaceServices.findAllWithoutPage(vendorAPI, null, false)
            .then(function (response) {
                console.log('server Success response from currency API call', response.data.response_body.data)
                setVendorsList(response.data.response_body.data)
            })
            .catch((error) => {
                console.log('server error response from currency API call', error.response)
            })
    }

    const currencyChangeConsent = (val) => {
        setCurrencyChangeFlag(true)
        setSelectedCurrency(val)
    }

    const closeCurrencyChangeConsentModal = () => {
        setCurrencyChangeFlag(false)
        setSelectedCurrency(null)
    }

    const handleCurrencyChange = () => {
        setCurrencyChangeFlag(false)
        findAllWithoutCurrencyDataByChange(selectedCurrency)

        if (selectedCurrency !== currencyIsoCode) {
            setCurrencyOnChange(true)
        } else {
            setCurrencyOnChange(false)
        }
    }

    useEffect(() => {
        const currencyDisplayData = filteredCurrencyData.filter((ele) => ele.symbol === currencySymbol)

        if (currencyDisplayData && currencyDisplayData.length > 0) {
            setCurrencyData(currencyDisplayData)
        }
    }, [filteredCurrencyData, currencySymbol])
    useEffect(() => {
        window.scroll(0, 0)
        findAllWithoutPageStoreSettingApi()
        findByPageCurrencyData()
        findAllWithoutPageStoreImagesApi()
        findAllVendorListAPICall()
    }, [])

    return (
        <>
            <Content>
                <HeaderForTitle
                    title={
                        <Content>
                            <Title level={3} className='!font-semibold'>
                                {t('common:store_settings')}
                            </Title>
                        </Content>
                    }
                    headerContent={
                        <Content>
                            <Paragraph className='!font-normal !text-[#637381] !m-0'>
                                {t('store_settings:store_settings_short_desc')}
                            </Paragraph>
                            <Content>
                                <Content className='!h-[38px] !mt-2 -mb-2'>
                                    <DmTabAntDesign
                                        tabData={mainTabData}
                                        tabBarPosition={'top'}
                                        defaultSelectedTabKey={'0'}
                                        handleTabChangeFunction={handleMainTabChange}
                                        tabType='line'
                                    />
                                </Content>
                            </Content>
                        </Content>
                    }
                />
                <Content className='p-3'>
                    {selectedTab === 0 && (
                        <Spin tip='Please wait!' size='large' spinning={isUpLoading}>
                            <Content className='bg-white p-3 !rounded-md'>
                                {/* <div>
                                    <label className='text-[20px] mb-2 font-bold'>
                                        {t('store_settings:store_name')}
                                    </label>
                                </div>
                                <div>
                                    <Input
                                        className='mb-3 !w-96'
                                        disabled={true}
                                        addonAfter={
                                            <TranslationOutlined
                                                onClick={() => {
                                                    setLoadingSkelton(true)
                                                    setTranslateModalVisible(true)
                                                    setOnChangeDisableFields(true)
                                                }}
                                                className='iconsHoverClass'
                                            />
                                        }
                                        value={storeSettingsInfo && storeSettingsInfo[0].store_display_name}
                                    />
                                </div> */}

                                <label className='text-[20px] mb-2 font-bold'>{t('store_settings:media')}</label>
                                {imagesLoading ? (
                                    <Content className=' bg-white p-3'>
                                        <SkeletonComponent />
                                    </Content>
                                ) : imagesNetWorkError ? (
                                    <Content className='text-center !mt-10 !mb-2 bg-white p-3'>
                                        <h1 level={5}>{t('common:network_error')}</h1>
                                    </Content>
                                ) : (
                                    <div>
                                        <Content className='mb-4'>
                                            <StoreImages
                                                title={`${t('store_settings:store_logo')}`}
                                                type={'store_logo'}
                                                imagesUpload={imagesUpload}
                                                setImagesUpload={setImagesUpload}
                                                getImageData={getImageData && getImageData[0]}
                                                isSingleUpload={true}
                                                validStoreLogo={validStoreLogo}
                                                setValidStoreLogo={setValidStoreLogo}
                                                InfoCircleText={`${t('store_settings:store_logo_info')}`}
                                            />
                                        </Content>
                                        <Content>
                                            <StoreImages
                                                title={`${t('store_settings:banner_logo')}`}
                                                type={'banner_images'}
                                                imagesUpload={imagesUpload}
                                                bannerAbsoluteImage={bannerAbsoluteImage}
                                                setImagesUpload={setImagesUpload}
                                                isSingleUpload={false}
                                                InfoCircleText={`${t('store_settings:banner_logo_info')}`}
                                            />
                                        </Content>
                                        <Content className='mt-4'>
                                            {imagesUpload && imagesUpload.length > 0 ? (
                                                <Button
                                                    className={'app-btn-primary'}
                                                    onClick={() => {
                                                        if (imagesUpload && imagesUpload.length > 0) {
                                                            postImageOnClickSave()
                                                        } else {
                                                            toast(`${t('languages:no_changes_were_detected')}`, {
                                                                position: toast.POSITION.TOP_RIGHT,
                                                                type: 'info',
                                                                autoClose: 10000,
                                                            })
                                                            MarketplaceToaster.showToast(
                                                                util.getToastObject(
                                                                    `${t('languages:no_changes_were_detected')}`,
                                                                    'info'
                                                                )
                                                            )
                                                        }
                                                    }}>
                                                    {t('common:save')}
                                                </Button>
                                            ) : null}
                                        </Content>
                                    </div>
                                )}
                            </Content>
                        </Spin>
                    )}

                    {selectedTab === 1 && (
                        <Spin tip='Please wait!' size='large' spinning={isCurrencyLoading}>
                            <Content className='bg-white p-3 rounded-lg'>
                                <Content className='pb-3'>
                                    <Alert
                                        icon={<MdInfo className='!text-center !w-[21px] !h-[21px]' />}
                                        message={
                                            <div className='font-medium text-[14px] leading-[22px]'>
                                                {t('store_settings:currency_information')}
                                            </div>
                                        }
                                        description={
                                            <div className='font-normal'>
                                                {t('store_settings:currency_information_line1')}
                                                {/* <li>{t('store_settings:currency_information_line2')}</li> */}
                                            </div>
                                        }
                                        type='info'
                                        showIcon
                                    />
                                </Content>
                                <label className='text-[20px] font-bold !text-center mb-2'>
                                    {t('store_settings:currency')}
                                </label>
                                {storeCurrencyLoading ? (
                                    <Content className=' bg-white p-3'>
                                        <SkeletonComponent />
                                    </Content>
                                ) : storeCurrencyNetWorkError ? (
                                    <Content className='text-center !mt-10 !mb-2 bg-white p-3'>
                                        <h1 level={5}>{t('common:network_error')}</h1>
                                    </Content>
                                ) : (
                                    <div>
                                        <Content>
                                            {!auth.isAuthenticated ||
                                            (auth.isAuthenticated &&
                                                permissionValue &&
                                                permissionValue.length > 0 &&
                                                permissionValue.includes('UI-list-approvals')) ? (
                                                <Col span={8}>
                                                    <label className='text-[14px] mb-2 ml-1 input-label-color'>
                                                        {t('store_settings:choose_store_currency')}
                                                    </label>
                                                    <Select
                                                        disabled={vendorsList?.length > 0 ? true : false}
                                                        showSearch={false}
                                                        className='w-100'
                                                        placeholder={t('store_settings:please_choose_a_store_currency')}
                                                        value={
                                                            currencyData &&
                                                            currencyData.length > 0 &&
                                                            currencyData[0].currency_name
                                                        }
                                                        onChange={(e) => {
                                                            currencyChangeConsent(e)
                                                        }}
                                                        options={filteredCurrencyData}
                                                    />
                                                </Col>
                                            ) : null}
                                        </Content>

                                        <Row className='!flex w-[100%]' justify='space-between' align='middle'>
                                            <Title level={5} className='font-normal my-2'>
                                                {t('store_settings:currency_details')}
                                            </Title>
                                        </Row>

                                        {currencyData && currencyData.length > 0 ? (
                                            <>
                                                <Row className='w-[80%]'>
                                                    <Col
                                                        className={` justify-items-start  !inline-block   ${
                                                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                                ? 'text-right '
                                                                : 'text-left'
                                                        }`}>
                                                        <p className='text-brandGray1 my-3 '>
                                                            {t('store_settings:currency_code')}
                                                        </p>
                                                        <p className='text-brandGray1 my-3 '>
                                                            {t('store_settings:conversation')}
                                                        </p>
                                                        <p className='text-brandGray1  my-3 '>
                                                            {t('store_settings:unit_price_name')}
                                                        </p>
                                                        <p className='text-brandGray1  my-3 '>
                                                            {t('store_settings:min_amount')}
                                                        </p>
                                                        <p className='text-brandGray1  my-3 '>
                                                            {t('store_settings:currency_symbol')}
                                                        </p>
                                                        <p className='text-brandGray1  my-3 '>
                                                            {t('store_settings:no_of_decimals')}
                                                        </p>
                                                    </Col>
                                                    <Col
                                                        className={`${
                                                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                                ? 'mr-6 w-[4%] !inline-block '
                                                                : 'ml-6 w-[4%] !inline-block '
                                                        }`}>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                        <p className='!font-semibold my-3 !text-brandGray1'>
                                                            <span>:</span>
                                                        </p>
                                                    </Col>
                                                    <Col className='bg w-[15%] !inline-block'>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].iso_currency_code !== null
                                                                ? currencyData[0].iso_currency_code
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].unit_conversion !== null
                                                                ? currencyData[0].unit_conversion
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].unit_price_name !== null
                                                                ? currencyData[0].unit_price_name
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].minimum_amount !== null
                                                                ? currencyData[0].minimum_amount
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].symbol !== null
                                                                ? currencyData[0].symbol
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                        <p className='!font-semibold my-3'>
                                                            {currencyData[0].no_of_decimal !== null
                                                                ? currencyData[0].no_of_decimal
                                                                : `${t('store_settings:not_available')}`}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : null}
                                        <div>
                                            <Row className='gap-2 !mt-2'>
                                                {!auth.isAuthenticated ||
                                                (auth.isAuthenticated &&
                                                    permissionValue &&
                                                    permissionValue.length > 0 &&
                                                    permissionValue.includes('UI-list-approvals')) ? (
                                                    <Col>
                                                        {vendorsList?.length > 0 ? null : (
                                                            <Button
                                                                className='app-btn-primary '
                                                                onClick={() => updateStoreCurrencyApi()}
                                                                disabled={!currencyOnChange}>
                                                                {t('common:save')}
                                                            </Button>
                                                        )}
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </div>
                                    </div>
                                )}
                            </Content>
                        </Spin>
                    )}
                    {selectedTab === 2 && (
                        <Spin tip='Please wait!' size='large' spinning={isLoading}>
                            <Content className='bg-white p-3 rounded-lg'>
                                <Row className='!mb-4'>
                                    <Content className='flex justify-between w-full'>
                                        <div className=''>
                                            <div>
                                                <label className='text-[20px]  mt-2 font-bold select-none'>
                                                    {t('store_settings:theme')}
                                                </label>
                                            </div>
                                            <div>
                                                <label className='text-[20px]  mt-2 font-semibold select-none'>
                                                    {t('store_settings:page_theme')}
                                                </label>
                                            </div>
                                        </div>
                                        {storePageThemeLoading === false && storePageThemeNetWorkError === false ? (
                                            <div className='flex gap-2'>
                                                <Button
                                                    className='app-btn-secondary !text-end'
                                                    onClick={() => setResetModalOpen(true)}>
                                                    {t('store_settings:reset')}
                                                </Button>
                                                <Button
                                                    className='app-btn-secondary flex justify-center items-center'
                                                    onClick={() => openModal()}>
                                                    <EyeOutlined className='' /> {t('store_settings:preview')}
                                                </Button>
                                            </div>
                                        ) : null}
                                    </Content>
                                    <StoreModal
                                        isVisible={isModalOpen}
                                        title={`${t('store_settings:sample_preview_page_for_store_front')}`}
                                        width={1000}
                                        cancelCallback={() => closeModal()}
                                        isSpin={false}
                                        className='!h-96'>
                                        <Preview
                                            headerBackgroundColor={headerBackgroundColor}
                                            headerForegroundColor={headerForegroundColor}
                                            footerBackgroundColor={footerBackgroundColor}
                                            footerForegroundColor={footerForegroundColor}
                                            pageBackgroundColor={pageBackgroundColor}
                                            foreGroundColor={foreGroundColor}
                                            buttonPrimaryBackgroundColor={buttonPrimaryBackgroundColor}
                                            buttonSecondaryBackgroundColor={buttonSecondaryBackgroundColor}
                                            //buttonTeritaryBackgroundColor={buttonTeritaryBackgroundColor}
                                            buttonPrimaryForegroundColor={buttonPrimaryForegroundColor}
                                            buttonSecondaryForegroundColor={buttonSecondaryForegroundColor}
                                            buttonTeritaryForegroundColor={buttonTeritaryForegroundColor}
                                            getImageData={getImageData}
                                        />
                                    </StoreModal>
                                </Row>
                                {storePageThemeLoading ? (
                                    <Content className=' bg-white p-3'>
                                        <SkeletonComponent />
                                    </Content>
                                ) : storePageThemeNetWorkError ? (
                                    <Content className='text-center !mt-10 !mb-2 bg-white p-3'>
                                        <h1 level={5}>{t('common:network_error')}</h1>
                                    </Content>
                                ) : (
                                    <div>
                                        <Divider className='!my-4' />
                                        {/* <Row className='mt-2'>
                                            <Col span={8} className='mr-2 '>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:background_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        value={pageBackgroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['pageBgColorValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setPageBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['pageBgColorValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setPageBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setPageBackgroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['bg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                        className='w-9 p-0'
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={pageBackgroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setPageBackgroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['pageBgColorValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setPageBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['pageBgColorValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setPageBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setPageBackgroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['bg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <UndoOutlined
                                                                        onClick={() => {
                                                                            setPageBackgroundColor(pageBgColor)
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['pageBgColorValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.pageBgColorValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col span={8} className='ml-1'>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:text_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        value={foreGroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['pageTextColorValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setForeGroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['pageTextColorValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setForeGroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setForeGroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['fg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                        className='w-9 p-0'
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={foreGroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setForeGroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['pageTextColorValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setForeGroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['pageTextColorValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setForeGroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['fg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <UndoOutlined
                                                                        onClick={() => {
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['pageTextColorValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                            setForeGroundColor(pageFgColor)
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.pageTextColorValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                        </Row> */}
                                        <Row className='mt-4'>
                                            <Col span={8} className='mr-2 '>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:primary_button_background_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonPrimaryBackgroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['primaryBgValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonPrimaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['primaryBgValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonPrimaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_primary_bg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonPrimaryBackgroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonPrimaryBackgroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['primaryBgValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonPrimaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['primaryBgValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonPrimaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setButtonPrimaryBackgroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_primary_bg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <svg
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setButtonPrimaryBackgroundColor(
                                                                                btnPrimaryBgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['primaryBgValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                        width='14'
                                                                        height='14'
                                                                        viewBox='0 0 14 14'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'>
                                                                        <g clip-path='url(#clip0_477_32633)'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                clip-rule='evenodd'
                                                                                d='M3.62125 1.98956L3.36282 1.11997C3.29822 0.929086 3.12594 0.801832 2.93212 0.780625C2.7383 0.759412 2.54449 0.886672 2.45835 1.07755L0.821674 4.74678C0.735533 4.91645 0.778604 5.12854 0.92935 5.27701C1.01549 5.36185 1.1447 5.42548 1.27391 5.42548C1.33852 5.42548 1.40312 5.40427 1.46773 5.38306L5.21485 3.79235C5.40866 3.70752 5.51634 3.51663 5.51634 3.32575C5.49481 3.13486 5.36559 2.94398 5.17178 2.90156L4.40608 2.67736C4.61497 2.56081 4.83266 2.45794 5.05791 2.36984C6.18101 1.93055 7.42338 1.88519 8.57642 2.24139C9.72947 2.59759 10.723 3.33365 11.3901 4.32598C12.0573 5.31831 12.3575 6.5065 12.2403 7.69104C12.1231 8.87558 11.5957 9.98435 10.7466 10.8312C9.89744 11.678 8.77836 12.2113 7.57719 12.3416C6.37602 12.4719 5.1659 12.1912 4.15003 11.5467C3.13415 10.9021 2.37437 9.93296 1.99825 8.80192C1.91599 8.55455 1.64569 8.41969 1.39452 8.50071C1.14334 8.58173 1.00641 8.84794 1.08868 9.09531C1.53318 10.432 2.4311 11.5774 3.63168 12.3391C4.83226 13.1008 6.26239 13.4326 7.68196 13.2786C9.10152 13.1246 10.4241 12.4943 11.4276 11.4935C12.4311 10.4927 13.0544 9.18237 13.1929 7.78246C13.3314 6.38254 12.9766 4.97832 12.1882 3.80556C11.3997 2.6328 10.2256 1.76292 8.86288 1.34196C7.50019 0.921003 6.03194 0.974602 4.70463 1.49376C4.32942 1.64052 3.97193 1.82196 3.63636 2.03446C3.63012 2.01827 3.6251 2.00322 3.62125 1.98956Z'
                                                                                fill='black'
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id='clip0_477_32633'>
                                                                                <rect
                                                                                    width='14'
                                                                                    height='14'
                                                                                    fill='white'
                                                                                    transform='translate(0 0.000488281)'
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.primaryBgValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col span={8} className='ml-1'>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:secondary_button_background_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonSecondaryBackgroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['secondaryBgValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonSecondaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['secondaryBgValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonSecondaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setButtonSecondaryBackgroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_secondary_bg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonSecondaryBackgroundColor}
                                                            className='w-[150px]'
                                                            maxLength={7}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonSecondaryBackgroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['secondaryBgValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonSecondaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['secondaryBgValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonSecondaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }

                                                                // setButtonSecondaryBackgroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_secondary_bg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <svg
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setButtonSecondaryBackgroundColor(
                                                                                btnSecondaryBgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['secondaryBgValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                        width='14'
                                                                        height='14'
                                                                        viewBox='0 0 14 14'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'>
                                                                        <g clip-path='url(#clip0_477_32633)'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                clip-rule='evenodd'
                                                                                d='M3.62125 1.98956L3.36282 1.11997C3.29822 0.929086 3.12594 0.801832 2.93212 0.780625C2.7383 0.759412 2.54449 0.886672 2.45835 1.07755L0.821674 4.74678C0.735533 4.91645 0.778604 5.12854 0.92935 5.27701C1.01549 5.36185 1.1447 5.42548 1.27391 5.42548C1.33852 5.42548 1.40312 5.40427 1.46773 5.38306L5.21485 3.79235C5.40866 3.70752 5.51634 3.51663 5.51634 3.32575C5.49481 3.13486 5.36559 2.94398 5.17178 2.90156L4.40608 2.67736C4.61497 2.56081 4.83266 2.45794 5.05791 2.36984C6.18101 1.93055 7.42338 1.88519 8.57642 2.24139C9.72947 2.59759 10.723 3.33365 11.3901 4.32598C12.0573 5.31831 12.3575 6.5065 12.2403 7.69104C12.1231 8.87558 11.5957 9.98435 10.7466 10.8312C9.89744 11.678 8.77836 12.2113 7.57719 12.3416C6.37602 12.4719 5.1659 12.1912 4.15003 11.5467C3.13415 10.9021 2.37437 9.93296 1.99825 8.80192C1.91599 8.55455 1.64569 8.41969 1.39452 8.50071C1.14334 8.58173 1.00641 8.84794 1.08868 9.09531C1.53318 10.432 2.4311 11.5774 3.63168 12.3391C4.83226 13.1008 6.26239 13.4326 7.68196 13.2786C9.10152 13.1246 10.4241 12.4943 11.4276 11.4935C12.4311 10.4927 13.0544 9.18237 13.1929 7.78246C13.3314 6.38254 12.9766 4.97832 12.1882 3.80556C11.3997 2.6328 10.2256 1.76292 8.86288 1.34196C7.50019 0.921003 6.03194 0.974602 4.70463 1.49376C4.32942 1.64052 3.97193 1.82196 3.63636 2.03446C3.63012 2.01827 3.6251 2.00322 3.62125 1.98956Z'
                                                                                fill='black'
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id='clip0_477_32633'>
                                                                                <rect
                                                                                    width='14'
                                                                                    height='14'
                                                                                    fill='white'
                                                                                    transform='translate(0 0.000488281)'
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.secondaryBgValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col span={7} className='ml-2'>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:tertiary_button_text_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonTeritaryForegroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['tertiaryTextValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonTeritaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['tertiaryTextValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonTeritaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setButtonTeritaryForegroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_tertiary_fg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonTeritaryForegroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonTeritaryForegroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['tertiaryTextValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonTeritaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['tertiaryTextValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonTeritaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setButtonTeritaryForegroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_tertiary_fg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <svg
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setButtonTeritaryForegroundColor(
                                                                                btnTeritaryFgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['tertiaryTextValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                        width='14'
                                                                        height='14'
                                                                        viewBox='0 0 14 14'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'>
                                                                        <g clip-path='url(#clip0_477_32633)'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                clip-rule='evenodd'
                                                                                d='M3.62125 1.98956L3.36282 1.11997C3.29822 0.929086 3.12594 0.801832 2.93212 0.780625C2.7383 0.759412 2.54449 0.886672 2.45835 1.07755L0.821674 4.74678C0.735533 4.91645 0.778604 5.12854 0.92935 5.27701C1.01549 5.36185 1.1447 5.42548 1.27391 5.42548C1.33852 5.42548 1.40312 5.40427 1.46773 5.38306L5.21485 3.79235C5.40866 3.70752 5.51634 3.51663 5.51634 3.32575C5.49481 3.13486 5.36559 2.94398 5.17178 2.90156L4.40608 2.67736C4.61497 2.56081 4.83266 2.45794 5.05791 2.36984C6.18101 1.93055 7.42338 1.88519 8.57642 2.24139C9.72947 2.59759 10.723 3.33365 11.3901 4.32598C12.0573 5.31831 12.3575 6.5065 12.2403 7.69104C12.1231 8.87558 11.5957 9.98435 10.7466 10.8312C9.89744 11.678 8.77836 12.2113 7.57719 12.3416C6.37602 12.4719 5.1659 12.1912 4.15003 11.5467C3.13415 10.9021 2.37437 9.93296 1.99825 8.80192C1.91599 8.55455 1.64569 8.41969 1.39452 8.50071C1.14334 8.58173 1.00641 8.84794 1.08868 9.09531C1.53318 10.432 2.4311 11.5774 3.63168 12.3391C4.83226 13.1008 6.26239 13.4326 7.68196 13.2786C9.10152 13.1246 10.4241 12.4943 11.4276 11.4935C12.4311 10.4927 13.0544 9.18237 13.1929 7.78246C13.3314 6.38254 12.9766 4.97832 12.1882 3.80556C11.3997 2.6328 10.2256 1.76292 8.86288 1.34196C7.50019 0.921003 6.03194 0.974602 4.70463 1.49376C4.32942 1.64052 3.97193 1.82196 3.63636 2.03446C3.63012 2.01827 3.6251 2.00322 3.62125 1.98956Z'
                                                                                fill='black'
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id='clip0_477_32633'>
                                                                                <rect
                                                                                    width='14'
                                                                                    height='14'
                                                                                    fill='white'
                                                                                    transform='translate(0 0.000488281)'
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.tertiaryTextValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            {/* <Col span={7} className='ml-2'>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:tertiary_button_background_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonTeritaryBackgroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['tertiaryBgValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonTeritaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['tertiaryBgValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonTeritaryBackgroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setButtonTeritaryBackgroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_tertiary_bg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonTeritaryBackgroundColor}
                                                            className='w-[150px]'
                                                            maxLength={7}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonTeritaryBackgroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['tertiaryBgValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonTeritaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['tertiaryBgValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonTeritaryBackgroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setButtonTeritaryBackgroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_tertiary_bg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <UndoOutlined
                                                                        onClick={() => {
                                                                            setButtonTeritaryBackgroundColor(
                                                                                btnTeritaryBgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['tertiaryBgValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.tertiaryBgValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col> */}
                                        </Row>
                                        <Row className='mt-4'>
                                            <Col span={8} className='mr-2 '>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:primary_button_text_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonPrimaryForegroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['primaryTextValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonPrimaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['primaryTextValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonPrimaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setButtonPrimaryForegroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_primary_fg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonPrimaryForegroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonPrimaryForegroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['primaryTextValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonPrimaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['primaryTextValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonPrimaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setButtonPrimaryForegroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_primary_fg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <svg
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setButtonPrimaryForegroundColor(
                                                                                btnPrimaryFgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['primaryTextValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                        width='14'
                                                                        height='14'
                                                                        viewBox='0 0 14 14'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'>
                                                                        <g clip-path='url(#clip0_477_32633)'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                clip-rule='evenodd'
                                                                                d='M3.62125 1.98956L3.36282 1.11997C3.29822 0.929086 3.12594 0.801832 2.93212 0.780625C2.7383 0.759412 2.54449 0.886672 2.45835 1.07755L0.821674 4.74678C0.735533 4.91645 0.778604 5.12854 0.92935 5.27701C1.01549 5.36185 1.1447 5.42548 1.27391 5.42548C1.33852 5.42548 1.40312 5.40427 1.46773 5.38306L5.21485 3.79235C5.40866 3.70752 5.51634 3.51663 5.51634 3.32575C5.49481 3.13486 5.36559 2.94398 5.17178 2.90156L4.40608 2.67736C4.61497 2.56081 4.83266 2.45794 5.05791 2.36984C6.18101 1.93055 7.42338 1.88519 8.57642 2.24139C9.72947 2.59759 10.723 3.33365 11.3901 4.32598C12.0573 5.31831 12.3575 6.5065 12.2403 7.69104C12.1231 8.87558 11.5957 9.98435 10.7466 10.8312C9.89744 11.678 8.77836 12.2113 7.57719 12.3416C6.37602 12.4719 5.1659 12.1912 4.15003 11.5467C3.13415 10.9021 2.37437 9.93296 1.99825 8.80192C1.91599 8.55455 1.64569 8.41969 1.39452 8.50071C1.14334 8.58173 1.00641 8.84794 1.08868 9.09531C1.53318 10.432 2.4311 11.5774 3.63168 12.3391C4.83226 13.1008 6.26239 13.4326 7.68196 13.2786C9.10152 13.1246 10.4241 12.4943 11.4276 11.4935C12.4311 10.4927 13.0544 9.18237 13.1929 7.78246C13.3314 6.38254 12.9766 4.97832 12.1882 3.80556C11.3997 2.6328 10.2256 1.76292 8.86288 1.34196C7.50019 0.921003 6.03194 0.974602 4.70463 1.49376C4.32942 1.64052 3.97193 1.82196 3.63636 2.03446C3.63012 2.01827 3.6251 2.00322 3.62125 1.98956Z'
                                                                                fill='black'
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id='clip0_477_32633'>
                                                                                <rect
                                                                                    width='14'
                                                                                    height='14'
                                                                                    fill='white'
                                                                                    transform='translate(0 0.000488281)'
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.primaryTextValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                            <Col span={8} className='ml-1'>
                                                <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                    {t('store_settings:secondary_button_text_color')}
                                                </label>
                                                <Content className='flex gap-2'>
                                                    <Input
                                                        type='color'
                                                        className='w-9 p-0'
                                                        value={buttonSecondaryForegroundColor}
                                                        onChange={(e) => {
                                                            const patternName = /^#([A-Fa-f0-9]{6})$/
                                                            if (patternName.test(e.target.value) === false) {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['secondaryTextValidation'] = true
                                                                setColorCodeValidation(temp)
                                                                setButtonSecondaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            } else {
                                                                let temp = { ...colorCodeValidation }
                                                                temp['secondaryTextValidation'] = false
                                                                setColorCodeValidation(temp)
                                                                setButtonSecondaryForegroundColor(e.target.value)
                                                                setOnChangeValues(true)
                                                            }
                                                            // setButtonSecondaryForegroundColor(e.target.value);
                                                            let temp = { ...copyImageOfStoreSettingsPageTheme }
                                                            temp['btn_secondary_fg_color'] = e.target.value
                                                            setCopyImageOfStoreSettingsPageTheme(temp)
                                                        }}
                                                    />
                                                    <Space.Compact className=''>
                                                        <Input
                                                            value={buttonSecondaryForegroundColor}
                                                            maxLength={7}
                                                            className='w-[150px]'
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value
                                                                // Allow only numeric input
                                                                const numericValue = inputValue
                                                                    .replace(/[^a-f0-9#]/gi, '')
                                                                    .substring(0, 7)
                                                                setButtonSecondaryForegroundColor(numericValue)
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(numericValue) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['secondaryTextValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setButtonSecondaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['secondaryTextValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setButtonSecondaryForegroundColor(numericValue)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setButtonSecondaryForegroundColor(e.target.value);
                                                                let temp = {
                                                                    ...copyImageOfStoreSettingsPageTheme,
                                                                }
                                                                temp['btn_secondary_fg_color'] = numericValue
                                                                setCopyImageOfStoreSettingsPageTheme(temp)
                                                            }}
                                                            addonAfter={
                                                                <Tooltip
                                                                    title={t(
                                                                        'store_settings:reset_to_the_original_value'
                                                                    )}>
                                                                    <svg
                                                                        className='cursor-pointer'
                                                                        onClick={() => {
                                                                            setButtonSecondaryForegroundColor(
                                                                                btnSecondaryFgColor
                                                                            )
                                                                            let temp = { ...colorCodeValidation }
                                                                            temp['secondaryTextValidation'] = false
                                                                            setColorCodeValidation(temp)
                                                                        }}
                                                                        width='14'
                                                                        height='14'
                                                                        viewBox='0 0 14 14'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'>
                                                                        <g clip-path='url(#clip0_477_32633)'>
                                                                            <path
                                                                                fill-rule='evenodd'
                                                                                clip-rule='evenodd'
                                                                                d='M3.62125 1.98956L3.36282 1.11997C3.29822 0.929086 3.12594 0.801832 2.93212 0.780625C2.7383 0.759412 2.54449 0.886672 2.45835 1.07755L0.821674 4.74678C0.735533 4.91645 0.778604 5.12854 0.92935 5.27701C1.01549 5.36185 1.1447 5.42548 1.27391 5.42548C1.33852 5.42548 1.40312 5.40427 1.46773 5.38306L5.21485 3.79235C5.40866 3.70752 5.51634 3.51663 5.51634 3.32575C5.49481 3.13486 5.36559 2.94398 5.17178 2.90156L4.40608 2.67736C4.61497 2.56081 4.83266 2.45794 5.05791 2.36984C6.18101 1.93055 7.42338 1.88519 8.57642 2.24139C9.72947 2.59759 10.723 3.33365 11.3901 4.32598C12.0573 5.31831 12.3575 6.5065 12.2403 7.69104C12.1231 8.87558 11.5957 9.98435 10.7466 10.8312C9.89744 11.678 8.77836 12.2113 7.57719 12.3416C6.37602 12.4719 5.1659 12.1912 4.15003 11.5467C3.13415 10.9021 2.37437 9.93296 1.99825 8.80192C1.91599 8.55455 1.64569 8.41969 1.39452 8.50071C1.14334 8.58173 1.00641 8.84794 1.08868 9.09531C1.53318 10.432 2.4311 11.5774 3.63168 12.3391C4.83226 13.1008 6.26239 13.4326 7.68196 13.2786C9.10152 13.1246 10.4241 12.4943 11.4276 11.4935C12.4311 10.4927 13.0544 9.18237 13.1929 7.78246C13.3314 6.38254 12.9766 4.97832 12.1882 3.80556C11.3997 2.6328 10.2256 1.76292 8.86288 1.34196C7.50019 0.921003 6.03194 0.974602 4.70463 1.49376C4.32942 1.64052 3.97193 1.82196 3.63636 2.03446C3.63012 2.01827 3.6251 2.00322 3.62125 1.98956Z'
                                                                                fill='black'
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id='clip0_477_32633'>
                                                                                <rect
                                                                                    width='14'
                                                                                    height='14'
                                                                                    fill='white'
                                                                                    transform='translate(0 0.000488281)'
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </Tooltip>
                                                            }
                                                        />
                                                    </Space.Compact>
                                                </Content>
                                                {colorCodeValidation.secondaryTextValidation === true ? (
                                                    <p className='text-red-600 text-sm'>
                                                        {t('store_settings:please_enter_valid_hexadecimal_code')} <br />
                                                        {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                    </p>
                                                ) : null}
                                            </Col>
                                        </Row>

                                        {/* <Content>
                                            <label className='text-[20px] mb-2 mt-4 font-bold select-none'>
                                                {t('store_settings:store_header_setting')}
                                            </label>
                                            <Row className='mt-2'>
                                                <Col span={8} className='mr-2 '>
                                                    <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                        {t('store_settings:background_color')}
                                                    </label>
                                                    <Content className='flex gap-2'>
                                                        <Input
                                                            type='color'
                                                            className='w-9 p-0'
                                                            value={headerBackgroundColor}
                                                            onChange={(e) => {
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(e.target.value) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['headerBgValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setHeaderBackgroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['headerBgValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setHeaderBackgroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setHeaderBackgroundColor(e.target.value);
                                                                let temp = { ...copyImageOfStoreHeaderSetting }
                                                                temp['bg_color'] = e.target.value
                                                                setCopyImageOfStoreHeaderSetting(temp)
                                                            }}
                                                        />
                                                        <Space.Compact className=''>
                                                            <Input
                                                                value={headerBackgroundColor}
                                                                maxLength={7}
                                                                className='w-[150px]'
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value
                                                                    // Allow only numeric input
                                                                    const numericValue = inputValue
                                                                        .replace(/[^a-f0-9#]/gi, '')
                                                                        .substring(0, 7)
                                                                    setHeaderBackgroundColor(numericValue)
                                                                    const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                    if (patternName.test(numericValue) === false) {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['headerBgValidation'] = true
                                                                        setColorCodeValidation(temp)
                                                                        setHeaderBackgroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    } else {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['headerBgValidation'] = false
                                                                        setColorCodeValidation(temp)
                                                                        setHeaderBackgroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    }
                                                                    // setHeaderBackgroundColor(e.target.value);
                                                                    let temp = { ...copyImageOfStoreHeaderSetting }
                                                                    temp['bg_color'] = numericValue
                                                                    setCopyImageOfStoreHeaderSetting(temp)
                                                                }}
                                                                addonAfter={
                                                                    <Tooltip
                                                                        title={t(
                                                                            'store_settings:reset_to_the_original_value'
                                                                        )}>
                                                                        <UndoOutlined
                                                                            onClick={() => {
                                                                                setHeaderBackgroundColor(headerBgColor)
                                                                                let temp = { ...colorCodeValidation }
                                                                                temp['headerBgValidation'] = false
                                                                                setColorCodeValidation(temp)
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </Space.Compact>
                                                    </Content>
                                                    {colorCodeValidation.headerBgValidation === true ? (
                                                        <p className='text-red-600 text-sm'>
                                                            {t('store_settings:please_enter_valid_hexadecimal_code')}{' '}
                                                            <br />
                                                            {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                        </p>
                                                    ) : null}
                                                </Col>
                                                <Col span={8} className='ml-1'>
                                                    <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                        {' '}
                                                        {t('store_settings:text_color')}
                                                    </label>
                                                    <Content className='flex gap-2'>
                                                        <Input
                                                            type='color'
                                                            className='w-9 p-0'
                                                            value={headerForegroundColor}
                                                            onChange={(e) => {
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(e.target.value) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['headerTextValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setHeaderForegroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['headerTextValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setHeaderForegroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                }
                                                                let temp = { ...copyImageOfStoreHeaderSetting }
                                                                temp['fg_color'] = e.target.value
                                                                setCopyImageOfStoreHeaderSetting(temp)
                                                            }}
                                                        />
                                                        <Space.Compact className=''>
                                                            <Input
                                                                value={headerForegroundColor}
                                                                className='w-[150px]'
                                                                maxLength={7}
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value
                                                                    // Allow only numeric input
                                                                    const numericValue = inputValue
                                                                        .replace(/[^a-f0-9#]/gi, '')
                                                                        .substring(0, 7)
                                                                    setHeaderForegroundColor(numericValue)
                                                                    const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                    if (patternName.test(numericValue) === false) {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['headerTextValidation'] = true
                                                                        setColorCodeValidation(temp)
                                                                        setHeaderForegroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    } else {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['headerTextValidation'] = false
                                                                        setColorCodeValidation(temp)
                                                                        setHeaderForegroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    }
                                                                    // setHeaderForegroundColor(e.target.value);
                                                                    let temp = { ...copyImageOfStoreHeaderSetting }
                                                                    temp['fg_color'] = numericValue
                                                                    setCopyImageOfStoreHeaderSetting(temp)
                                                                }}
                                                                addonAfter={
                                                                    <Tooltip
                                                                        title={t(
                                                                            'store_settings:reset_to_the_original_value'
                                                                        )}>
                                                                        <UndoOutlined
                                                                            onClick={() => {
                                                                                setHeaderForegroundColor(headerFgColor)
                                                                                let temp = { ...colorCodeValidation }
                                                                                temp['headerTextValidation'] = false
                                                                                setColorCodeValidation(temp)
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </Space.Compact>
                                                    </Content>
                                                    {colorCodeValidation.headerTextValidation === true ? (
                                                        <p className='text-red-600 text-sm'>
                                                            {t('store_settings:please_enter_valid_hexadecimal_code')}{' '}
                                                            <br />
                                                            {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                        </p>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </Content> */}
                                        {/* <Content>
                                            <label className='text-[20px] mb-2 mt-4 font-bold select-none'>
                                                {t('store_settings:store_footer_setting')}
                                            </label>
                                            <Row className='mt-2'>
                                                <Col span={8} className='mr-2 '>
                                                    <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                        {t('store_settings:background_color')}
                                                    </label>
                                                    <Content className='flex gap-2'>
                                                        <Input
                                                            type='color'
                                                            className='w-9 p-0'
                                                            value={footerBackgroundColor}
                                                            onChange={(e) => {
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(e.target.value) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['footerBgValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setFooterBackgroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['footerBgValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setFooterBackgroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                }
                                                                // setFooterBackgroundColor(e.target.value);
                                                                let temp = { ...copyImageOfStoreFooterSetting }
                                                                temp['bg_color'] = e.target.value
                                                                setCopyImageOfStoreFooterSetting(temp)
                                                            }}
                                                        />
                                                        <Space.Compact className=''>
                                                            <Input
                                                                value={footerBackgroundColor}
                                                                className='w-[150px]'
                                                                maxLength={7}
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value
                                                                    // Allow only numeric input
                                                                    const numericValue = inputValue
                                                                        .replace(/[^a-f0-9#]/gi, '')
                                                                        .substring(0, 7)
                                                                    setFooterBackgroundColor(numericValue)
                                                                    const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                    if (patternName.test(numericValue) === false) {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['footerBgValidation'] = true
                                                                        setColorCodeValidation(temp)
                                                                        setFooterBackgroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    } else {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['footerBgValidation'] = false
                                                                        setColorCodeValidation(temp)
                                                                        setFooterBackgroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    }
                                                                    // setFooterBackgroundColor(e.target.value);
                                                                    let temp = { ...copyImageOfStoreFooterSetting }
                                                                    temp['bg_color'] = numericValue
                                                                    setCopyImageOfStoreFooterSetting(temp)
                                                                }}
                                                                addonAfter={
                                                                    <Tooltip
                                                                        title={t(
                                                                            'store_settings:reset_to_the_original_value'
                                                                        )}>
                                                                        <UndoOutlined
                                                                            onClick={() => {
                                                                                setFooterBackgroundColor(footerBgColor)
                                                                                let temp = { ...colorCodeValidation }
                                                                                temp['footerBgValidation'] = false
                                                                                setColorCodeValidation(temp)
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </Space.Compact>
                                                    </Content>
                                                    {colorCodeValidation.footerBgValidation === true ? (
                                                        <p className='text-red-600 text-sm'>
                                                            {t('store_settings:please_enter_valid_hexadecimal_code')}{' '}
                                                            <br />
                                                            {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                        </p>
                                                    ) : null}
                                                </Col>
                                                <Col span={8} className='ml-1'>
                                                    <label className='text-[13px] mb-2 ml-1 select-none input-label-color'>
                                                        {t('store_settings:text_color')}
                                                    </label>
                                                    <Content className='flex gap-2'>
                                                        <Input
                                                            type='color'
                                                            className='w-9 p-0'
                                                            value={footerForegroundColor}
                                                            onChange={(e) => {
                                                                const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                if (patternName.test(e.target.value) === false) {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['footerTextValidation'] = true
                                                                    setColorCodeValidation(temp)
                                                                    setFooterForegroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                } else {
                                                                    let temp = { ...colorCodeValidation }
                                                                    temp['footerTextValidation'] = false
                                                                    setColorCodeValidation(temp)
                                                                    setFooterForegroundColor(e.target.value)
                                                                    setOnChangeValues(true)
                                                                }
                                                                let temp = { ...copyImageOfStoreFooterSetting }
                                                                temp['fg_color'] = e.target.value
                                                                setCopyImageOfStoreFooterSetting(temp)
                                                            }}
                                                        />
                                                        <Space.Compact className=''>
                                                            <Input
                                                                value={footerForegroundColor}
                                                                className='w-[150px]'
                                                                maxLength={7}
                                                                onChange={(e) => {
                                                                    const inputValue = e.target.value
                                                                    // Allow only numeric input
                                                                    const numericValue = inputValue
                                                                        .replace(/[^a-f0-9#]/gi, '')
                                                                        .substring(0, 7)
                                                                    setFooterForegroundColor(numericValue)
                                                                    const patternName = /^#([A-Fa-f0-9]{6})$/
                                                                    if (patternName.test(numericValue) === false) {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['footerTextValidation'] = true
                                                                        setColorCodeValidation(temp)
                                                                        setFooterForegroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    } else {
                                                                        let temp = { ...colorCodeValidation }
                                                                        temp['footerTextValidation'] = false
                                                                        setColorCodeValidation(temp)
                                                                        setFooterForegroundColor(numericValue)
                                                                        setOnChangeValues(true)
                                                                    }
                                                                    // setFooterForegroundColor(e.target.value);
                                                                    let temp = { ...copyImageOfStoreFooterSetting }
                                                                    temp['fg_color'] = numericValue
                                                                    setCopyImageOfStoreFooterSetting(temp)
                                                                }}
                                                                addonAfter={
                                                                    <Tooltip
                                                                        title={t(
                                                                            'store_settings:reset_to_the_original_value'
                                                                        )}>
                                                                        <UndoOutlined
                                                                            onClick={() => {
                                                                                setFooterForegroundColor(footerFgColor)
                                                                                let temp = { ...colorCodeValidation }
                                                                                temp['footerTextValidation'] = false
                                                                                setColorCodeValidation(temp)
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                }
                                                            />
                                                        </Space.Compact>
                                                    </Content>
                                                    {colorCodeValidation.footerTextValidation === true ? (
                                                        <p className='text-red-600 text-sm'>
                                                            {t('store_settings:please_enter_valid_hexadecimal_code')}{' '}
                                                            <br />
                                                            {t('store_settings:ex_ffffff_for_white_000000_for_black')}
                                                        </p>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                        </Content> */}
                                        <Content className='mt-4'>
                                            <Row className='gap-2'>
                                                <Col>
                                                    <Button
                                                        className={'app-btn-primary '}
                                                        disabled={!onChangeValues}
                                                        onClick={() => {
                                                            validatePostStoreSetting()
                                                        }}>
                                                        {t('common:save')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Content>
                                    </div>
                                )}
                            </Content>
                        </Spin>
                    )}
                    {selectedTab === 3 && (
                        <Content>
                            <StoreJourney />
                        </Content>
                    )}
                </Content>
            </Content>
            <StoreModal
                title={`${t('common:translate')}`}
                isVisible={translateModalVisible}
                okButtonText={null}
                cancelButtonText={null}
                okCallback={null}
                hideCloseButton={false}
                cancelCallback={() => translateModalCloseHandler()}
                isSpin={false}
                width={1000}>
                <Content>
                    <TranslatorModal
                        dataJson={[
                            {
                                order: 1,
                                label: t('store_settings:enter_store_name'),
                                type: 'textbox',
                                value: storeSettingsInfo && storeSettingsInfo[0].store_name,
                            },
                        ]}
                        componentType='store'
                        respectiveId={parseInt(storeId)}
                        setTranslateModalVisible={setTranslateModalVisible}
                        storeSettingsData={storeSettingsData}
                        setStoreSettingsData={setStoreSettingsData}
                        onChangeDisableFields={onChangeDisableFields}
                        setOnChangeDisableFields={setOnChangeDisableFields}
                        loadingSkelton={loadingSkelton}
                        setLoadingSkelton={setLoadingSkelton}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={resetModalOpen}
                okButtonText={t('common:yes')}
                title={t('store_settings:reset_default')}
                okCallback={() => updateStoreSettingsRestoreApi()}
                cancelCallback={() => {
                    closeResetWaringModal()
                }}
                isSpin={resetLoader}
                // width={'400px'}
                hideCloseButton={true}
                >
                {
                    <div className='text-brandGray2'>
                        <p className='!mb-0'>{t('store_settings:restore_settings_warning_msg')}</p>
                        <p>{t('store_settings:restore_settings_modal_msg')}</p>
                    </div>
                }
            </StoreModal>
            <StoreModal
                isVisible={currencyChangeFLag}
                okButtonText={t('common:yes')}
                title={t('store_settings:change_currency_details')}
                okCallback={() => handleCurrencyChange()}
                cancelCallback={() => {
                    closeCurrencyChangeConsentModal()
                }}
                isSpin={resetLoader}
                width={'400px'}
                hideCloseButton={true}>
                {
                    <div>
                        <p className='!mb-0'>{t('store_settings:change_currency_consent_line1')}</p>
                        <p>{t('store_settings:change_currency_consent_line2')}</p>
                    </div>
                }
            </StoreModal>
        </>
    )
}

export default StoreSettings
