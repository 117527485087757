import { Button, Col, Divider, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import util from '../../util/common'
import Salesdetails from './Salesdetails'

import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'

const { Content } = Layout
const { Title } = Typography

const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL

const Overview = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [langDirection, setLangDirection] = useState('ltr')

    let selectedVendorSettlementInfoRedux = useSelector(
        (state) => state.reducerSelectedVendorSettlemetInfo.selectedVendorSettlementInfo
    )
    let paymentCycleInfoRedux = useSelector((state) => state.reducerPaymentCycleInfo.paymentCycleInfo)
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol
    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    const { id } = useParams()
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <>
            {selectedVendorSettlementInfoRedux && paymentCycleInfoRedux && paymentCycleInfoRedux.length > 0 ? (
                <div className=' bg-white'>
                    {selectedVendorSettlementInfoRedux ? (
                        <Row>
                            <Col className={`w-[60%]  ${langDirection === 'rtl' ? '!pl-3' : '!pr-3'}`}>
                                <Content className=' border-[1px] border-[#bbbbbb] !w-[100%] !h-[100%] bg-white p-3 cursor-pointer'>
                                    <Content>
                                        <span className='!text-gray-500'>{t('transactions:total_sales')}</span>
                                    </Content>
                                    <Content>
                                        <span className='!font-semibold !text-black !text-2xl'>
                                            {currencySymbolFromRedux}{' '}
                                            {selectedVendorSettlementInfoRedux &&
                                                selectedVendorSettlementInfoRedux.total_amount}{' '}
                                        </span>
                                    </Content>
                                </Content>
                            </Col>
                            <Col className='!w-[40%]'>
                                <Row>
                                    <Col className='!w-[50%] !pr-3'>
                                        <Content className=' border-[1px] border-[#bbbbbb] !w-[100%] !h-[100%] bg-white p-1 cursor-pointer'>
                                            <Title level={5} className='!m-0'>
                                                {t('transactions:outstanding_payment')}
                                            </Title>
                                            <Title level={4} className='!m-0 !text-red-400'>
                                                {currencySymbolFromRedux} &nbsp;
                                                {selectedVendorSettlementInfoRedux &&
                                                    selectedVendorSettlementInfoRedux.vendor_outstanding_amount}
                                            </Title>
                                        </Content>
                                    </Col>
                                    <Col className='!w-[50%]'>
                                        <Content
                                            className={`border-[1px] border-[#bbbbbb] !w-[100%] !h-[100%] bg-white p-2 cursor-pointer ${
                                                langDirection === 'rtl' ? '!mr-2' : ''
                                            }`}>
                                            <Title level={5} className='!m-0'>
                                                {t('transactions:store_commission')}
                                            </Title>
                                            <Title level={4} className='!m-0 !text-green-400'>
                                                {currencySymbolFromRedux} &nbsp;
                                                {selectedVendorSettlementInfoRedux &&
                                                    selectedVendorSettlementInfoRedux.total_store_commission}{' '}
                                            </Title>
                                        </Content>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col className={`!w-[60%] ${langDirection === 'rtl' ? '!pl-3' : '!pr-3'}`}>
                            {selectedVendorSettlementInfoRedux ? (
                                <Content className={`border-2 border-zinc-200`}>
                                    <Content>
                                        <Salesdetails type='overview' />
                                    </Content>
                                    {selectedVendorSettlementInfoRedux &&
                                    selectedVendorSettlementInfoRedux.line_items &&
                                    selectedVendorSettlementInfoRedux.line_items.length > 0 ? (
                                        <Content className='!p-3'>
                                            <Button type='link' className='app-btn-link'>
                                                <p
                                                    className='text-[14px] !mb-0 font-semibold'
                                                    role={'button'}
                                                    onClick={() => {
                                                        window.scrollTo(0, 0)
                                                        navigate(
                                                            `/dashboard/transactions/vendor-details/particular-vendor/${id}/salesdetails?` +
                                                                MarketplaceAppConfig.getStore('')
                                                        )
                                                    }}>
                                                    {t('transactions:view_all_sales_details')}
                                                </p>
                                            </Button>
                                        </Content>
                                    ) : null}
                                </Content>
                            ) : null}
                        </Col>
                        <Col className='!w-[40%] pl-2'>
                            {paymentCycleInfoRedux && paymentCycleInfoRedux.length > 0 ? (
                                <Content className='mt-3'>
                                    <Content className='!grid !grid-flow-col'>
                                        <Content
                                            className={`!grid !justify-items-start ${
                                                langDirection === 'rtl' ? '!mr-3.5' : ''
                                            }`}>
                                            <p className=''>
                                                {' '}
                                                {t('transactions:payment_cycle')} {''} : {''}
                                                {paymentCycleInfoRedux &&
                                                    paymentCycleInfoRedux.length > 0 &&
                                                    paymentCycleInfoRedux[0].payment_cycle}
                                            </p>
                                        </Content>
                                    </Content>
                                    <Divider type='horizontal' />
                                </Content>
                            ) : null}
                        </Col>
                    </Row>
                </div>
            ) : null}
        </>
    )
}

export default Overview
