import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_STORE_USER_VERSION_DETAILS

const useGetUserConsentVersionDetails = ({ storeUserConsentId }) => {
    const getUserConsentVersionDetails = async () => {
        const params = {}
        if (storeUserConsentId) params['store-userconsent-id'] = storeUserConsentId
        const res = await MarketplaceServices.findAllWithoutPage(BASE_URL, params)
        //console.log(res, 'useGetUserConsentVersionDetails')
        return res?.data?.response_body
    }
    return useQuery({
        queryKey: ['StoreUserConsentVersionDetails', storeUserConsentId],
        queryFn: getUserConsentVersionDetails,
        refetchOnWindowFocus: false,
        retry: false,
    })
}
export default useGetUserConsentVersionDetails
