import { Empty, Layout, Skeleton, Tabs, Typography, Row, Col, Anchor, Tooltip } from 'antd'
import { useEffect, useState,useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useGetStoreAdminConsent from '../../hooks/useGetStoreAdminConsent'
import { useLocation } from 'react-router-dom'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import ReactQuill from 'react-quill'
import { getGenerateDateAndTime } from '../../util/util'
import util from '../../util/common'
import { EmptySVG } from '../../constants/media'
const { Content } = Layout
const { Text } = Typography
const { Link } = Anchor

const ListPolicies = ({ searchParams, setSearchParams }) => {
    const search = useLocation().search
    const subTabData = new URLSearchParams(search).get('subtab')

    const { t } = useTranslation()
    const [policiesTab, setPoliciesTab] = useState([])
    const [policyLink, setPolicyLink] = useState(null)
    const policiesRef = useRef(null)
    const [isLastPolicyRendered, setIsLastPolicyRendered] = useState(false)

    const { data: storeAdminConsent, status: storeAdminStatus } = useGetStoreAdminConsent()

    
    function getOffsetTop(element, container) {
        if (!element.getClientRects().length) {
            return 0
        }

        const rect = element.getBoundingClientRect()

        if (rect.width || rect.height) {
            if (container === window) {
                container = element.ownerDocument.documentElement
                return rect.top - container.clientTop
            }
            return rect.top - container.getBoundingClientRect().top
        }

        return rect.top
    }

    useEffect(() => {
        if (searchParams.get('subtab')) window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        if (storeAdminStatus === 'success') {
            const tempTabData = []
            storeAdminConsent?.forEach((consent) => {
                if (consent?.version_details?.consent_display_name) {
                    tempTabData.push({
                        key: `${String(consent?.id)}`,
                        title: consent?.version_details?.consent_display_name,
                        href: `#${String(consent?.id)}`,
                    })
                }
            })
            if (tempTabData?.length > 0) setPoliciesTab(tempTabData)
        }
    }, [storeAdminConsent, storeAdminStatus])

    useEffect(() => {
        if (subTabData !== undefined && subTabData !== null && isLastPolicyRendered) {
            setPolicyLink(subTabData)
            handleClick(subTabData)
        } else {
            setPolicyLink(storeAdminConsent && storeAdminConsent[0]?.id)
        }
    }, [subTabData, storeAdminConsent, isLastPolicyRendered])

    const handleClick = (id) => {
        const element = document.getElementById(id)
        setPolicyLink(id)

        const headerOffset = 140

        policiesRef?.current?.scrollTo({
            behavior: 'smooth',
            top: element ? element.offsetTop - headerOffset : 0,
        })
    }
    const getCurrentContainer = () => {
        return document.getElementById(`policies-container`)
    }
    const getInternalCurrentAnchor = (policies) => {
        const policySections = []
        const container = getCurrentContainer()
        policies?.forEach((policy) => {
            const target = document.getElementById(policy?.id)
            if (target) {
                const top = getOffsetTop(target, container)
                if (top <= 140) {
                    policySections.push({ policy, top })
                }
            }
        })

        if (policySections.length) {
            const maxSection = policySections.reduce((prev, curr) => (curr.top > prev.top ? curr : prev))
            return maxSection.policy
        }
        return ''
    }
    const handleScroll = (data) => {
        const currentActivePolicy = getInternalCurrentAnchor(data)
        setPolicyLink(currentActivePolicy?.id)
    }
    useEffect(() => {
        const scrollContainer = getCurrentContainer()
        scrollContainer?.addEventListener('scroll', () => handleScroll(storeAdminConsent))
        return () => {
            scrollContainer?.removeEventListener('scroll', handleScroll)
        }
    }, [isLastPolicyRendered])

    return (
        <Content className=' w-full  h-full '>
            {storeAdminStatus === 'pending' && (
                <Skeleton
                    active
                    paragraph={{
                        rows: 6,
                    }}
                    className='p-3 w-full'></Skeleton>
            )}
            {storeAdminStatus === 'success' && (
                <>
                    <div
                        className={`${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : ''} !text-xl !font-medium border-b p-3`}>
                        {t('policies:policies')}
                    </div>
                    {policiesTab?.length > 0 && storeAdminConsent?.length > 0 ? (
                         <div className='flex p-3 w-[100%]'>
                         <div
                             className=' w-[80%] pb-[30vw] max-h-screen overflow-y-auto'
                             ref={policiesRef}
                             id='policies-container'>
                             {storeAdminConsent && storeAdminConsent.length > 0
                                 ? storeAdminConsent?.map((data, index) => {
                                       if (index == storeAdminConsent?.length - 1 && !isLastPolicyRendered) {
                                           setIsLastPolicyRendered(true)
                                       }
                                       return (
                                           <Content id={String(data?.id)} className='w-[100%]'>
                                               <Text
                                                   className='!text-lg !font-semibold mb-3 w-[90%] '
                                                   ellipsis={{
                                                       tooltip: {
                                                           title: data?.version_details?.consent_display_name,
                                                           mouseLeaveDelay: 0,
                                                       },
                                                   }}>
                                                   {data?.version_details?.consent_display_name}
                                               </Text>
                                               <div className={` !text-sm !font-semibold mb-2`}>
                                                   {t('policies:last_updated')}:{' '}
                                                   {getGenerateDateAndTime(data?.updated_on, 'D MMMM YYYY')}
                                               </div>
                                               <ReactQuill
                                                   value={data?.version_details?.consent_display_description}
                                                   modules={{ toolbar: false }}
                                                   readOnly
                                                   className='mb-3 mr-2 text-base editor'
                                               />
                                           </Content>
                                       )
                                   })
                                 : null}
                         </div>

                         <div
                             className='!w-[20%] pl-3'
                             style={{
                                 position: 'sticky',
                                 top: '150px',
                             }}>
                             {storeAdminConsent && storeAdminConsent.length > 0
                                 ? storeAdminConsent?.map((data, index) => {
                                       return (
                                           <Content
                                               className='cursor-pointer relative '
                                               onClick={() => handleClick(data?.id)}>
                                               <Text
                                                   className={`w-[90%] font-medium text-sm !pl-2 !py-1 !border-l-2 !border-[#e1e0e0] ${
                                                       policyLink == data.id ? ' !text-[#FB8500]' : 'text-[#637381] '
                                                   }`}
                                                   ellipsis={{
                                                       tooltip: {
                                                           title: data?.version_details?.consent_display_name,
                                                           mouseLeaveDelay: 0,
                                                       },
                                                   }}>
                                                   {data?.version_details?.consent_display_name}
                                               </Text>
                                               {policyLink == data.id && (
                                                   <div className=' absolute top-[10px] left-[-3px]'>
                                                       <div className=' !w-2 !h-2 rounded-full bg-white !border-[2px] !border-solid !border-[#FB8500]'></div>
                                                   </div>
                                               )}
                                           </Content>
                                       )
                                   })
                                 : null}
                         </div>
                     </div>
                    ) : (
                        <div className='  flex flex-col items-center justify-center my-4'>
                            <img src={EmptySVG} alt='no_policies_available' />
                            <p className='mt-4'>{t('policies:no_policies_available')}</p>
                            {/* <Empty description={t('policies:no_policies_available')} /> */}
                        </div>
                    )}
                </>
            )}

            {storeAdminStatus === 'error' && (
                <p className=' !text-black !text-opacity-80 pt-5 text-center'>{t('common:network_error')}</p>
            )}
        </Content>
    )
}
export default ListPolicies
