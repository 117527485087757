import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'

const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT
const useCreateNewUserConsent = () => {
    const createNewUserConsent = async ({ body }) => {
        const res = await MarketplaceServices.save(BASE_URL, body)
        return res
    }
    return useMutation({ mutationFn: createNewUserConsent })
}
export default useCreateNewUserConsent
