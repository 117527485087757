import React from 'react'
import { Layout, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Content } = Layout
const LoadingMarkup = () => {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54,
            }}
            spin
        />
    )

    return (
        <Layout className='h-[100vh]'>
            <Content className='grid justify-items-center align-items-center h-full'>
                <Spin indicator={antIcon} />
            </Content>
        </Layout>
    )
}

export default LoadingMarkup
