import { Input, Layout, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StoreModal from '../../../components/storeModal/StoreModal'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useUpdateApprovalStatus from '../hooks/useUpdateApprovalStatus'

const { Content } = Layout
const { TextArea } = Input
function RejectModal({
    showRejectModal,
    setShowRejectModal,
    typeChoice,
    revisionId,
    putApprovalId,
    refetchApprovalData,
}) {
    const { t } = useTranslation()
    const [rejectReason, setRejectReason] = useState(null)
    const { mutate: updateApprovalStatus, status: updatedApprovalStatus } = useUpdateApprovalStatus()
    /**
     * ?To Construct  the object for the rejection put object for Reject Put Call.
     */
    const constructRejectPutObject = () => {
        let putObject = {}
        putObject.type_choice = typeChoice
        putObject.revision_id = parseInt(revisionId)
        putObject.revision_status = 4
        if (rejectReason) {
            putObject.approval_reason = rejectReason
        }
        updateApprovalStatus(
            { putApprovalId: putApprovalId, putBody: putObject },
            {
                onSuccess: (response) => {
                    refetchApprovalData()
                    MarketplaceToaster.showToast(response)
                    setShowRejectModal(false)
                },
                onError: (err) => {
                    MarketplaceToaster.showToast(err.response)
                },
            }
        )
    }
    return (
        <div>
            {' '}
            <StoreModal
                isVisible={showRejectModal}
                title={t('approvals:rejection_request_submission')}
                okCallback={() => constructRejectPutObject()}
                okButtonText={t('common:submit')}
                cancelButtonText={t('common:cancel')}
                cancelCallback={() => setShowRejectModal(false)}
                isSpin={updatedApprovalStatus === 'pending' ? true : false}>
                <Content className='mb-4'>
                    <Typography className='input-label-color mb-2'>{t('approvals:reject_reason')}</Typography>
                    <TextArea
                        showCount
                        maxLength={225}
                        autoSize={true}
                        value={rejectReason}
                        placeholder={t('approvals:please_enter_rejection_submission_request_message')}
                        onChange={(e) => setRejectReason(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                const trimmed = rejectReason.trim()
                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                setRejectReason(trimmedUpdate)
                            }, 300)
                        }}
                        className='!pr-[3px]'
                    />
                </Content>
            </StoreModal>
        </div>
    )
}

export default RejectModal
