// Note: As per discussion with sushil and logo, we are maintaining this static structure
// For grouping the datatype under four categories, ie (Intractive Content, Text Based Content, Uploads Content, Calculative Content)
// Because backend is not supporting this grouping currently,
// Once grouping is supported, we will remove this file from repository.
import { ChoiceChip, Dropdown, MultipleSwitch, RadioButton } from './media'
import TranslateAttributeStrings from '../pages/product_new_options/TranslateAttributeStrings'

const attibuteDataTypeSettingsForVariant = [
    {
        key: 1,
        label: <TranslateAttributeStrings translate={'attributes:interactive_content'} />,
        selected: true,
        dataTypes: [
            {
                dataTypeId: 2,
                image: RadioButton,
            },
            {
                dataTypeId: 3,
                image: ChoiceChip,
            },
            {
                dataTypeId: 7,
                image: Dropdown,
            },
            {
                dataTypeId: 8,
                image: MultipleSwitch,
            },
        ],
    },
]

export default attibuteDataTypeSettingsForVariant
