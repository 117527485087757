import { Tabs, Typography, Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import ListPolicies from './ListPolicies'
import UserProfile from './UserProfile'

const { Title } = Typography
const USER_PROFILE_TABS_OPTIONS = {
    PROFILE_INFORMATION: 'profile_information',
    POLICIES: 'policies',
}

const MyProfile = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        if (!searchParams.get('tab')) {
            setSearchParams({
                tab: USER_PROFILE_TABS_OPTIONS.PROFILE_INFORMATION,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        }
    }, [searchParams, setSearchParams])

    const myProfileTabData = [
        {
            key: USER_PROFILE_TABS_OPTIONS.PROFILE_INFORMATION,
            label: `${t('common:profile_information')}`,
            value: 0,
        },
        {
            key: USER_PROFILE_TABS_OPTIONS.POLICIES,
            label: `${t('policies:policies')}`,
            value: 1,
        },
    ]
    const handelMyProfileTabChange = (tabKey) => {
        setSearchParams({
            tab: tabKey,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }
    return (
        <div>
            <HeaderForTitle
                title={
                    <div className=''>
                        <Title level={3} className='!font-medium'>
                            {t('common:my_profile')}
                        </Title>
                    </div>
                }
                headerContent={<div className='pt-1 !text-[#637381]'>{t('user_profile:profile_note')}</div>}
            />
            <div className='  px-6 pb-6 mt-4'>
                <Row className=' bg-white rounded shadow-brandShadow flex gap-4 '>
                    <Col span={4} className=' py-4 h-full '>
                        <Tabs
                            items={myProfileTabData}
                            tabPosition={'left'}
                            defaultActiveKey={USER_PROFILE_TABS_OPTIONS.PROFILE_INFORMATION}
                            activeKey={searchParams.get('tab') || USER_PROFILE_TABS_OPTIONS.PROFILE_INFORMATION}
                            onTabClick={handelMyProfileTabChange}
                            type='line'
                            className=' !h-full'
                        />
                    </Col>
                    <Col span={19} className=' border rounded-lg my-6 '>
                        {searchParams.get('tab') === USER_PROFILE_TABS_OPTIONS.PROFILE_INFORMATION && <UserProfile />}
                        {searchParams.get('tab') === USER_PROFILE_TABS_OPTIONS.POLICIES && (
                            <ListPolicies searchParams={searchParams} setSearchParams={setSearchParams} />
                        )}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default MyProfile
