import { Layout, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import LanguageDocUpload from './LanguageDocUpload'
import LanguageForm from './LanguageForm'
import LanguageHeaderAction from './LanguageHeaderAction'

function LanguageSettings() {
    const { t } = useTranslation()
    const { Content } = Layout
    const { Title } = Typography
    const [languageCode, setLanguageCode] = useState()
    const [languageStatus, setLanguageStatus] = useState()
    const [languageId, setLanguageId] = useState()
    const [languageName, setLanguageName] = useState()
    const [languageDefault, setLanguageDefault] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        setLanguageCode(searchParams.get('c'))
        setLanguageStatus(searchParams.get('s'))
        setLanguageId(searchParams.get('k'))
        setLanguageName(searchParams.get('n'))
        setLanguageDefault(searchParams.get('d'))
    }, [searchParams])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content className='pt-1'>
            <Content>
                <HeaderForTitle
                    title={
                        <Content className='flex mb-[7px]'>
                            {/* <Content> */}
                            {/**
                             * Below is the part responsible for displaying the title of the page in a header.
                             * If @languageName is there, which means it is a edit page so it will display the languageName for the edit page.
                             * If @languageName is undefined or null means it is a add page.So it will show "Add a language"
                             */}
                            <Tooltip
                                title={languageName}
                                overlayStyle={{ zIndex: 11, position: 'fixed' }}
                                placement='bottom'>
                                <Title level={3} className='!font-semibold !m-[unset] max-w-[300px]' ellipsis={true}>
                                    {languageName && languageName !== undefined && languageName !== null
                                        ? languageName
                                        : t('languages:add_a_language')}
                                </Title>
                            </Tooltip>
                        </Content>
                    }
                    titleContent={
                        languageId && languageCode && languageStatus !== null ? (
                            <LanguageHeaderAction
                                languageId={languageId}
                                languageCode={languageCode}
                                languageStatus={languageStatus}
                                languageDefault={languageDefault}></LanguageHeaderAction>
                        ) : null
                    }
                    backNavigationPath={`/dashboard/languages?${MarketplaceAppConfig.getStore('')}`}
                    showArrowIcon={true}
                    showButtons={false}
                />
            </Content>
            <Content className='p-3 !mt-[5.5rem] !min-h-screen'>
                <Content className='!bg-white rounded-lg p-3 shadow-brandShadow'>
                    <Content className='w-[75%]'>
                        {/**
                         * This particular container is to display the required language related fields
                         * From this component we are passing @languageCode as props.
                         * If @languageCode is undefined or null, then it is a add page for language.
                         * If @languageCode is present, then it is a edit page.
                         * ##NOTE##
                         * In this parent component we are not validating whether the @languageCode is valid or exists in the server(Child page has to validate)
                         */}
                        <LanguageForm
                            languageCode={languageCode}
                            languageId={languageId}
                            setLanguageName={setLanguageName}
                            languageStatus={languageStatus}
                            setLanguageStatus={setLanguageStatus}
                            languageName={languageName}
                        />
                        {/**
                         * This particular container is to display the file upload for the language
                         * From this component we are passing @languageCode as props.
                         * If @languageCode is undefined or null, then it is a add page for language.
                         * If @languageCode is present, then it is a edit page.
                         * ##NOTE##
                         * In this parent component we are not validating whether the @languageCode is valid or exists in the server(Child page has to validate)
                         */}
                        <LanguageDocUpload langCode={languageCode}></LanguageDocUpload>
                    </Content>
                </Content>
            </Content>
        </Content>
    )
}

export default LanguageSettings
