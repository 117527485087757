import { ActionTypes } from '../constants/ActionTypes'

const { RDX_STORE_AVAILABLE_INFO, RDX_STORE_SELECTED_INFO } = ActionTypes

// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods

export const ReducerStoreAvailable = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_STORE_AVAILABLE_INFO:
            return { ...state, storeAvailableInfo: payload }
        default:
            return state
    }
}

export const ReducerStoreSelected = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_STORE_SELECTED_INFO:
            return { ...state, storeSelectedInfo: payload }
        default:
            return state
    }
}
