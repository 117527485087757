/**
 * The below function is to process the products API data for the dropdown
 * This function will accept the data
 * This function will return data with an array of Object {key, value, label}
 * In this we handled for the empty validation as well. When we pass empty data it will return empty array.
 */
export const processAllPublishedProductsDropdown = (data) => {
    let localData = []
    if (data && data.length > 0) {
        for (var i = 0; i < data.length; i++) {
            let temp = {}
            temp['key'] = data[i].product_id
            temp['value'] = data[i].product_id
            temp['label'] = data[i].product_name
            localData.push(temp)
        }
        return localData
    } else {
        return []
    }
}
