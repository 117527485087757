import { Button, Col, Input, Layout, Radio, Row, Spin, Typography, Segmented } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
const titleMinLength = process.env.REACT_APP_TITLE_MIN_LENGTH
const titleMaxLength = process.env.REACT_APP_TITLE_MAX_LENGTH
const languageAPI = process.env.REACT_APP_STORE_LANGUAGE_API
const languageCodeMinLength = process.env.REACT_APP_LANGUAGE_CODE_MIN_LENGTH
const languageCodeMaxLength = process.env.REACT_APP_LANGUAGE_CODE_MAX_LENGTH

const LanguageForm = ({
    languageCode,
    languageId,
    setLanguageName,
    languageStatus,
    setLanguageStatus,
    languageName,
}) => {
    const navigate = useNavigate()
    const [txtLanguage, setTxtLanguage] = useState('')
    const [txtLanguageCode, setTxtLanguageCode] = useState('')
    const [scriptDirection, setScriptDirection] = useState('LTR')
    const [defaultTxtLanguage, setDefaultTxtLanguage] = useState('')
    const [defaultTxtLanguageCode, setDefaultTxtLanguageCode] = useState('')
    const [defaultScriptDirection, setDefaultScriptDirection] = useState('LTR')
    const [isEditLanguageFieldEmpty, setIsEditLanguageFieldEmpty] = useState(false)
    const [isEditLanguageCodeFieldEmpty, setIsEditLanguageCodeFieldEmpty] = useState(false)
    const [onChangeValues, setOnChangeValues] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isHelperTextVisible, setIsHelperTextVisible] = useState(false)

    const { t } = useTranslation()
    const { Content } = Layout
    const { Title } = Typography

    const selectedLanguage = useSelector((state) => state.reducerSelectedLanguage.selectedLanguage)

    //If langCode is undefined then its add else its edit
    //When it is edit make an API call to get language details for langCode
    //if edit set txtLanguage and txtLanguageCode and scriptDirection
    //if edit change the button from save to update
    //handleServerCall function
    //! Language post function
    const handleServerCall = () => {
        const postBody = {}
        postBody['language'] = txtLanguage.trim()
        postBody['language_code'] = txtLanguageCode.trim()?.toLowerCase()
        postBody['writing_script_direction'] = scriptDirection
        setIsLoading(true)

        languageCode === undefined || languageCode === null
            ? MarketplaceServices.save(languageAPI, postBody)
                  .then((res) => {
                      console.log('Server response from handleServerCall is', languageAPI, res.data.response_body)
                      MarketplaceToaster.showToast(res)
                      if (res.status === 201) {
                          if (res.data) {
                              setOnChangeValues(false)
                              navigate(
                                  `/dashboard/languages/language-settings?${MarketplaceAppConfig.getStore(
                                      ''
                                  )}&k=${res.data.response_body[0].id}&n=${
                                      res.data.response_body[0].language
                                  }&c=${res.data.response_body[0].language_code}&s=${
                                      res.data.response_body[0].status
                                  }&d=${res.data.response_body[0].is_default === false ? 0 : 1}`
                              )
                              setLanguageName(res.data.response_body[0].language)
                              setLanguageStatus(res.data.response_body[0].status)
                          }
                          // disabling spinner
                          setIsLoading(false)
                      }
                  })
                  .catch((error) => {
                      // disabling spinner
                      console.log(
                          'error server  response of post language API handleServerCall',
                          languageAPI,
                          error.response
                      )
                      setIsLoading(false)
                      MarketplaceToaster.showToast(error.response)
                  })
            : MarketplaceServices.update(languageAPI, postBody, { _id: languageId })
                  .then((res) => {
                      console.log('Server response from Language API put call response', res)
                      MarketplaceToaster.showToast(res)
                      if (res.status === 200) {
                          if (res.data) {
                              navigate(
                                  `/dashboard/languages/language-settings?${MarketplaceAppConfig.getStore(
                                      ''
                                  )}&k=${res.data.response_body[0].id}&n=${res.data.response_body[0].language}&c=${
                                      res.data.response_body[0].language_code
                                  }&s=${languageStatus}&d=${res.data.response_body[0].is_default === false ? 0 : 1}`
                              )
                              setLanguageName(res.data.response_body[0].language)
                              setDefaultScriptDirection(res.data.response_body[0].writing_script_direction)
                              setDefaultTxtLanguage(res.data.response_body[0].language)
                              setDefaultTxtLanguageCode(res.data.response_body[0].language_code)
                              if (
                                  parseInt(selectedLanguage && selectedLanguage.id) ===
                                  parseInt(res.data.response_body[0].id)
                              ) {
                                  if (
                                      selectedLanguage &&
                                      selectedLanguage.writing_script_direction !==
                                          res.data.response_body[0].writing_script_direction
                                  ) {
                                      setTimeout(function () {
                                          navigate(0)
                                      }, 300)
                                  }
                              }
                          }
                          setOnChangeValues(false)
                          // disabling spinner
                          setIsLoading(false)
                      }
                  })
                  .catch((error) => {
                      // disabling spinner
                      setIsLoading(false)
                      console.log(
                          'error Server response of put language API handleServerCall',
                          languageAPI,
                          error.response
                      )
                      MarketplaceToaster.showToast(error.response)
                  })
    }

    //!Validation for language post call
    const validateLanguageFieldEmptyOrNot = () => {
        let validValues = 2
        if (txtLanguage.trim() === '' && txtLanguageCode.trim() === '') {
            setIsEditLanguageFieldEmpty(true)
            setIsEditLanguageCodeFieldEmpty(true)
            validValues--
            MarketplaceToaster.showToast(util.getToastObject(`${t('languages:mandatory_fields')}`, 'error'))
        } else if (txtLanguage.trim() === '' && txtLanguageCode.trim() !== '') {
            setIsEditLanguageFieldEmpty(true)
            validValues--
            MarketplaceToaster.showToast(util.getToastObject(`${t('languages:mandatory_fields')}`, 'error'))
        } else if (txtLanguageCode.trim() === '' && txtLanguage.trim() !== '') {
            setIsEditLanguageCodeFieldEmpty(true)
            validValues--
            MarketplaceToaster.showToast(util.getToastObject(`${t('languages:mandatory_fields')}`, 'error'))
        } else if (
            validator.isLength(txtLanguage.trim(), {
                min: titleMinLength,
                max: titleMaxLength,
            }) === false
        ) {
            validValues--
            setIsEditLanguageFieldEmpty(true)
            MarketplaceToaster.showToast(
                util.getToastObject(
                    `${t('languages:language_must_contain_minimum_of')} ${titleMinLength}, ${t(
                        'languages:maximum_of'
                    )} ${titleMaxLength} ${t('languages:characters')}`,
                    'error'
                )
            )
        } else if (
            validator.isLength(txtLanguageCode.trim(), {
                min: languageCodeMinLength,
                max: languageCodeMaxLength,
            }) === false
        ) {
            validValues--
            setIsEditLanguageCodeFieldEmpty(true)
            MarketplaceToaster.showToast(
                util.getToastObject(
                    `${t('languages:language_code_must_contain_minimum_of')} ${languageCodeMinLength}, ${t(
                        'languages:maximum_of'
                    )} ${languageCodeMaxLength} ${t('messages:characters')}`,
                    'error'
                )
            )
        }
        if (validValues === 2) {
            handleServerCall()
        }
    }

    //! handle script direction change
    const handleScriptDirectionChange = (value) => {
        setScriptDirection(value)
    }

    //!get call of list language
    const findAllLanguageData = () => {
        setIsLoading(true)
        MarketplaceServices.findAll(languageAPI, null, true)
            .then(function (response) {
                setIsLoading(false)
                console.log(
                    'server response from findAllLanguageData API is',
                    languageAPI,
                    response.data.response_body.data
                )
                let serverLanguageData = response.data.response_body.data
                if (serverLanguageData && serverLanguageData.length > 0) {
                    const filteredLanguageData = serverLanguageData.filter((ele) => ele.language_code === languageCode)

                    setTxtLanguage(filteredLanguageData[0].language)
                    setTxtLanguageCode(filteredLanguageData[0].language_code)
                    setScriptDirection(filteredLanguageData[0].writing_script_direction)
                    setDefaultTxtLanguage(filteredLanguageData[0].language)
                    setDefaultTxtLanguageCode(filteredLanguageData[0].language_code)
                    setDefaultScriptDirection(filteredLanguageData[0].writing_script_direction)
                }
            })
            .catch((error) => {
                console.log(' error server response from findAllLanguageData API is', languageAPI, error)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (languageCode !== undefined && languageCode !== null) {
            findAllLanguageData()
        }
        if (languageCode !== undefined && languageName !== undefined) {
            setIsEditLanguageCodeFieldEmpty(false)
            setIsEditLanguageFieldEmpty(false)
        }
    }, [languageCode, languageName])
    return (
        <Content>
            <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                <Content className='border rounded-lg'>
                    <Content className='p-3 border-b'>
                        <Title level={4} className='!m-0'>
                            {t('languages:language_details')}
                        </Title>
                    </Content>
                    <Content className='p-3 w-[50%]'>
                        <Content className='mb-3'>
                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                {t('languages:language')}
                                <span className='mandatory-symbol-color text-sm !text-center ml-1'>*</span>
                            </Typography>

                            <Input
                                placeholder={t('languages:enter_language_name')}
                                value={txtLanguage}
                                minLength={titleMinLength}
                                maxLength={titleMaxLength}
                                className={`${
                                    isEditLanguageFieldEmpty
                                        ? 'border-red-400 !border-[0.5px] border-solid focus:border-red-400 hover:border-red-400'
                                        : ''
                                }`}
                                disabled={parseInt(languageStatus) === 1 ? true : false}
                                onChange={(e) => {
                                    if (e.target.value !== '' && validator.isAlpha(e.target.value)) {
                                        setTxtLanguage(e.target.value)
                                        setOnChangeValues(true)
                                    } else if (e.target.value === '') {
                                        setTxtLanguage(e.target.value)
                                        setOnChangeValues(true)
                                    }
                                    setIsEditLanguageFieldEmpty(false)
                                }}
                                onBlur={() => {
                                    const trimmed = txtLanguage.trim()
                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                    setTxtLanguage(trimmedUpdate)
                                }}
                            />
                        </Content>
                        <Content className='mb-3'>
                            <Content className=''>
                                <Typography className='mb-2 flex gap-1 input-label-color'>
                                    {t('languages:language_code')}
                                    <span className='mandatory-symbol-color text-sm text-center '>*</span>
                                </Typography>

                                <Input
                                    placeholder={t('languages:enter_code')}
                                    value={txtLanguageCode}
                                    minLength={languageCodeMinLength}
                                    maxLength={languageCodeMaxLength}
                                    className={`${
                                        isEditLanguageCodeFieldEmpty
                                            ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400'
                                            : ''
                                    }`}
                                    disabled={parseInt(languageStatus) === 1 ? true : false}
                                    onInput={(e) => {
                                        const lowerCaseLettersRegex = /^[a-z]+$/
                                        setIsHelperTextVisible(!lowerCaseLettersRegex.test(e.nativeEvent.data))
                                    }}
                                    onChange={(e) => {
                                        const languageCodeRegex = /^[a-z\-]+$/
                                        if (
                                            e.target.value !== '' &&
                                            validator.matches(e.target.value, languageCodeRegex)
                                        ) {
                                            setTxtLanguageCode(e.target.value)
                                            setOnChangeValues(true)
                                        } else if (e.target.value === '') {
                                            setTxtLanguageCode(e.target.value)
                                            setOnChangeValues(true)
                                        }
                                        setIsEditLanguageCodeFieldEmpty(false)
                                    }}
                                    onBlur={() => {
                                        const trimmed = txtLanguageCode.trim()
                                        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                        setTxtLanguageCode(trimmedUpdate)
                                    }}
                                />
                                {isHelperTextVisible ? (
                                    <div className='text-brandRed'>{t("languages:language_code_helper_text")}</div>
                                ) : null}
                            </Content>
                        </Content>
                        <Content>
                            <Content className='mb-3'>
                                <Typography className='!mb-2 input-label-color'>
                                    {t('languages:script_direction')}
                                </Typography>
                                {/* <Radio.Group
                                    optionType='button'
                                    className='w-full'
                                    value={scriptDirection}
                                    disabled={parseInt(languageStatus) === 1 ? true : false}
                                    onChange={(e) => {
                                        handleScriptDirectionChange(e.target.value)
                                        if (e.target.value !== defaultScriptDirection) {
                                            setOnChangeValues(true)
                                        }
                                    }}>
                                    <Radio.Button className='w-[50%] text-center' value='LTR'>
                                        {t('languages:left_to_right')}
                                    </Radio.Button>
                                    <Radio.Button className='w-[50%] text-center' value='RTL'>
                                        {t('languages:right_to_left')}
                                    </Radio.Button>
                                </Radio.Group> */}
                                <Segmented
                                    options={[
                                        {
                                            value: 'LTR',
                                            label: t('languages:left_to_right'),
                                        },
                                        {
                                            value: 'RTL',
                                            label: t('languages:right_to_left'),
                                        },
                                    ]}
                                    block={true}
                                    value={scriptDirection}
                                    // onChange={(e) => {
                                    //     handleScriptDirectionChange(e.target.value)
                                    //     if (e.target.value !== defaultScriptDirection) {
                                    //         setOnChangeValues(true)
                                    //     }
                                    // }}
                                    onChange={(value) => {
                                        handleScriptDirectionChange(value)
                                        if (value !== defaultScriptDirection) {
                                            setOnChangeValues(true)
                                        }
                                    }}
                                    disabled={parseInt(languageStatus) === 1 ? true : false} // style={{ width: '300px' }}
                                />
                            </Content>
                        </Content>
                    </Content>
                </Content>

                {(txtLanguageCode === '' && txtLanguage === '') ||
                onChangeValues === false ||
                (txtLanguage === defaultTxtLanguage &&
                    txtLanguageCode === defaultTxtLanguageCode &&
                    scriptDirection === defaultScriptDirection) ? null : (
                    <Row className='!flex !gap-2'>
                        <Col>
                            <Button
                                className={'app-btn-primary !mt-5'}
                                onClick={() => {
                                    if (
                                        txtLanguage === defaultTxtLanguage &&
                                        txtLanguageCode === defaultTxtLanguageCode &&
                                        scriptDirection === defaultScriptDirection
                                    ) {
                                        MarketplaceToaster.showToast(
                                            util.getToastObject(`${t('languages:no_changes_were_detected')}`, 'warning')
                                        )
                                    } else {
                                        validateLanguageFieldEmptyOrNot()
                                    }
                                }}>
                                {languageCode !== undefined && languageCode !== null
                                    ? `${t('common:update')}`
                                    : `${t('common:save')}`}
                            </Button>
                        </Col>
                        <Col className=''>
                            <Button
                                onClick={() => {
                                    if (languageCode === undefined || languageCode === null) {
                                        setTxtLanguageCode('')
                                        setTxtLanguage('')
                                        setIsEditLanguageFieldEmpty(false)
                                        setIsEditLanguageCodeFieldEmpty(false)
                                    } else {
                                        setTxtLanguage(defaultTxtLanguage)
                                        setTxtLanguageCode(defaultTxtLanguageCode)
                                        setScriptDirection(defaultScriptDirection)
                                        setIsEditLanguageFieldEmpty(false)
                                        setIsEditLanguageCodeFieldEmpty(false)
                                    }
                                }}
                                className={'app-btn-secondary !mt-5'}>
                                {t('common:cancel')}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Spin>
        </Content>
    )
}

export default LanguageForm
