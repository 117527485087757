import React from 'react'
import { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'

// this for setting image size in ui;
Quill.register('modules/imageResize', ImageResize)

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
    <svg viewBox='0 0 18 18'>
        <polygon className='ql-fill ql-stroke' points='6 10 4 12 2 10 6 10' />
        <path className='ql-stroke' d='M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9' />
    </svg>
)
// Redo button icon component for Quill editor
const CustomRedo = () => (
    <svg viewBox='0 0 18 18'>
        <polygon className='ql-fill ql-stroke' points='12 10 14 12 16 10 12 10' />
        <path className='ql-stroke' d='M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5' />
    </svg>
)

// Undo and redo functions for Custom Toolbar
function undoChange() {
    this.quill.history.undo()
}
function redoChange() {
    this.quill.history.redo()
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['small', 'medium', 'large']
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font')
// Font.whitelist = [
//   'inconsolata', 'roboto', 'mirza', 'arial','cursive',
// ];
Font.whitelist = [
    'Georgia',
    'Arial',
    'Verdana',
    'sans-serif',
    'cursive',
    'fantasy',
    'monospace',
    'serif',
    'Times',
    'Lucida',
]
Quill.register(Font, true)
// Modules object for setting up the Quill editor
export const modules = {
    toolbar: {
        container: '#toolbar',
        handlers: {
            undo: undoChange,
            redo: redoChange,
        },
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize'],
    },
}

// Formats objects for setting up the Quill editor
export const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'align',
    'strike',
    'script',
    'blockquote',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'code-block',
]

export const QuillToolbar = () => (
    <div id='toolbar'>
        <span className='ql-formats'>
            <button className='ql-bold' />
            <button className='ql-italic' />
            <button className='ql-underline' />
            <button className='ql-strike' />
        </span>
        <span className='ql-formats'>
            <select className='ql-font' defaultValue='0'>
                <option>Select font</option>

                <option value='Georgia'>Georgia</option>
                <option value='Arial'>Arial</option>
                <option value='Verdana'>Verdana</option>
                <option value='sans-serif'>Sans-serif</option>
                <option value='cursive'>Cursive</option>
                <option value='Lucida'>Lucida</option>
                <option value='fantasy'>Fantasy</option>
                <option value='monospace'>Monospace</option>
                <option value='serif'>Serif</option>
                <option value='Times'>Times</option>
            </select>
            <select className='ql-size'>
                <option value='small'>Small</option>
                <option value='medium'>Medium</option>
                <option value='large'>Large</option>
            </select>
            <select className='ql-header'>
                <option value='1'>Heading 1</option>
                <option value='2'>Heading 2</option>
                <option value='3'>Heading 3</option>
                <option value='4'>Heading 4</option>
                <option value='5'>Heading 5</option>
                <option value='6'>Heading 6</option>
                <option value=''>Normal</option>
            </select>
        </span>
        <span className='ql-formats'>
            <button className='ql-list' value='ordered' />
            <button className='ql-list' value='bullet' />
            <button className='ql-indent' value='-1' />
            <button className='ql-indent' value='+1' />
        </span>
        <span className='ql-formats'>
            <button className='ql-script' value='super' />
            <button className='ql-script' value='sub' />
            <button className='ql-blockquote' />
            {/* <button className="ql-direction" /> */}
        </span>
        <span className='ql-formats'>
            <select className='ql-align' />
            <select className='ql-color' />
            <select className='ql-background' />
        </span>
        <span className='ql-formats'>
            <button className='ql-link' />
            <button className='ql-image' />
            <button className='ql-video' />
        </span>
        <span className='ql-formats'>
            {/* <button className="ql-formula" /> */}
            <button className='ql-code-block' />
            {/* <button className="ql-clean" /> */}
        </span>
        <span className='ql-formats'>
            <button className='ql-undo'>
                <CustomUndo />
            </button>
            <button className='ql-redo'>
                <CustomRedo />
            </button>
        </span>
    </div>
)

export default QuillToolbar
