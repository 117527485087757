import React from 'react'
import { Skeleton } from 'antd'

const PDPOverView = () => {
    return (
        <div>
            <Skeleton />
        </div>
    )
}

export default PDPOverView
