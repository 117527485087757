import { useMutation } from "@tanstack/react-query";
import MarketplaceServices from "../../../services/axios/MarketplaceServices";

const couponAPI = process.env.REACT_APP_COUPONS_API

const useCreateCoupons=()=>{
    const createCoupons=async({data})=>{
        const serverResponse=await MarketplaceServices.save(couponAPI,data,null)
        return serverResponse

    }
    return useMutation({
        mutationFn:createCoupons
    })

}
export default useCreateCoupons;