import { Col, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'

const { Content } = Layout
const { Title, Text } = Typography
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL

const TranscationVendorDetails = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const location = useLocation()
    const [rowIndex, setRowIndex] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams()
    let vendorSettlementInfoRedux = useSelector((state) => state.reducerVendorSettlementInfo.vendorSettlementInfo)

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    // console.log("contentSettingFromRedux#", contentSettingFromRedux);
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol
    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        console.log('location', location.pathname.split('/'))
        let locationName = location.pathname.split('/')[6]
        if (
            vendorSettlementInfoRedux &&
            vendorSettlementInfoRedux.vendors_list &&
            vendorSettlementInfoRedux.vendors_list.length > 0 &&
            locationName === undefined
        ) {
            navigate(
                `particular-vendor/${
                    vendorSettlementInfoRedux.vendors_list[0].vendor_id
                }/overview?${MarketplaceAppConfig.getStore('')}`
            )
        }
    }, [vendorSettlementInfoRedux, location.pathname])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <>
            {vendorSettlementInfoRedux ? (
                <Content className='flex pt-0 pb-3 !min-h-screen'>
                    <Content className='border-1 border-zinc-200 !w-[25%] h-fit'>
                        <Content className='p-3 border-b-1 !bg-white border-slate-200'>
                            <Title level={4}> {t('transactions:all_vendors')}</Title>
                        </Content>
                        <Content className='p-3 !overflow-y-auto'>
                            {vendorSettlementInfoRedux &&
                            vendorSettlementInfoRedux.vendors_list &&
                            vendorSettlementInfoRedux.vendors_list.length > 0 ? (
                                vendorSettlementInfoRedux.vendors_list.map((value, index) => {
                                    return (
                                        <Row
                                            key={value.vendor_id}
                                            className={`${
                                                parseInt(rowIndex) === parseInt(index)
                                                    ? '!bg-gray-300  p-1 hover:cursor-pointer'
                                                    : '!bg-white border-b border-slate-300 p-1 hover:cursor-pointer'
                                            }`}
                                            onClick={() => {
                                                setRowIndex(index)
                                                navigate(
                                                    `particular-vendor/${
                                                        value.vendor_id
                                                    }/overview?${MarketplaceAppConfig.getStore('')}`
                                                )
                                            }}>
                                            <Col className='flex'>
                                                <Content className='w-[80%]'>
                                                    <p
                                                        className={`p-2 ${
                                                            parseInt(rowIndex) === parseInt(value.vendor_id)
                                                                ? '!font-semibold hover:cursor-pointer text-[14px] !mb-0'
                                                                : '!font-semibold hover:cursor-pointer'
                                                        }`}>
                                                        {value.vendor__name}
                                                    </p>
                                                    <p className='text-pink-600 ml-3 font-semibold'>
                                                        {' '}
                                                        {currencySymbolFromRedux} {value.totalamount}
                                                    </p>
                                                </Content>
                                            </Col>
                                        </Row>
                                    )
                                })
                            ) : (
                                <Text className='flex justify-center'>{t('vendors:no_vendors')}</Text>
                            )}
                            {/* <p>View More</p> */}
                        </Content>
                    </Content>
                    <Content className='!w-[80%] !py-0 pr-0 pl-3'>
                        <Outlet />
                    </Content>
                </Content>
            ) : (
                <Content className='flex justify-center items-center'>
                    <p className='text-[20px]'> {t('transactions:details_are_not_available')}</p>
                </Content>
            )}
        </>
    )
}

export default TranscationVendorDetails
