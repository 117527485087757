import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Col, Input, InputNumber, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import StoreModal from '../../components/storeModal/StoreModal'

import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnSelectedVendorSettlementInfo } from '../../services/redux/actions/ActionsVendorSettlement'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

const { Content } = Layout
const { Title } = Typography
const { TextArea } = Input
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const vendorApi = process.env.REACT_APP_VENDOR_SETTLEMENT_DETAILS_API
const vendorPaymentSettlementAPI = process.env.REACT_APP_VENDOR_PAYMENT_SETTLEMENT_API
const vendorSettlementDataAPI = process.env.REACT_APP_VENDOR_SETTLEMENT_DATA_API
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL

const TranscationsParticularVendor = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { id } = useParams()
    const { pathname } = useLocation()
    const pathNameSplittedArray = pathname.split('/')
    const [langDirection, setLangDirection] = useState('ltr')

    const tabData = [
        {
            key: 1,
            label: t('transactions:overview'),
        },
        {
            key: 2,
            label: <div className={`${langDirection === 'rtl' ? '!mr-4' : ''}`}>{t('transactions:sales')}</div>,
        },
        {
            key: 3,
            label: t('transactions:returns'),
        },
        {
            key: 5,
            label: t('transactions:settings'),
        },
    ]

    const [payNowModalOpen, setPayNowModalOpen] = useState(false)
    const [adjustAndPayModalOpen, setAdjustAndPayModalOpen] = useState(false)
    const [reasonForAdjustment, setReasonForAdjustment] = useState()
    const [adjustedValue, setAdjustedValue] = useState()
    const [vendorSettlementDetailsAPIData, setVendorSettlementDetailsAPIData] = useState()

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol
    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI
    let paymentCycleInfoRedux = useSelector((state) => state.reducerPaymentCycleInfo.paymentCycleInfo)
    const handleTabChange = (id) => {
        if (id === 1) {
            navigate('overview')
        } else if (id === 2) {
            navigate('salesdetails')
        } else if (id === 3) {
            navigate('returns')
        } else if (id === 4) {
            navigate('history')
        } else {
            navigate('settings')
        }
    }
    const toggleAdjustAndPayModal = () => {
        setAdjustAndPayModalOpen(!adjustAndPayModalOpen)
    }
    const togglePayNowModal = () => {
        setPayNowModalOpen(!payNowModalOpen)
    }
    //! get call of vendor lists
    const findByPageVendorSettlementDetails = (id, pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(
            vendorApi,
            {
                vendor_id: parseInt(id),
            },
            1,
            5,
            null
        )
            .then(function (response) {
                console.log('Server response from findByPageVendorSettlementDetails API is', vendorApi, response.data)
                setVendorSettlementDetailsAPIData(response.data.response_body)
                dispatch(fnSelectedVendorSettlementInfo(response.data.response_body))
                // setIsLoadingVendorList(false);
            })
            .catch(function (error) {
                console.log(
                    'Error Server response from findByPageVendorSettlementDetails API is',
                    vendorApi,
                    error.response
                )
                dispatch(fnSelectedVendorSettlementInfo({}))
                setVendorSettlementDetailsAPIData([])
            })
    }

    useEffect(() => {
        navigate(`overview?${MarketplaceAppConfig.getStore('')}`)
        // console.log("id", id);
        findByPageVendorSettlementDetails(id, 1, itemsPerPageFromEnv)
    }, [id])

    const payNowPostCall = () => {
        //!TODO: currenncy is hardcoded
        let requestObject = {
            currency: 'eur',
        }
        MarketplaceServices.save(vendorPaymentSettlementAPI, requestObject, {
            vendor_id: parseInt(id),
        })
            .then((response) => {
                console.log('Server response from payNowPostCall is', vendorPaymentSettlementAPI, response.data)
                setPayNowModalOpen(!payNowModalOpen)
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                console.log('error Server response from payNowPostCall is', vendorPaymentSettlementAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const onChangeAdjustNumber = (value) => {
        setAdjustedValue(value)
    }
    const adjustAndPayPostCall = () => {
        //!TODO: currenncy is hardcoded
        let requestObject = {
            amount: adjustedValue,
            currency: 'eur',
        }
        MarketplaceServices.save(vendorPaymentSettlementAPI, requestObject, {
            vendor_id: parseInt(id),
        })
            .then((response) => {
                console.log('Server response from adjustAndPayPostCall is', vendorPaymentSettlementAPI, response.data)
                setAdjustAndPayModalOpen(!adjustAndPayModalOpen)
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                console.log('error Server response from adjustAndPayPostCall is', vendorPaymentSettlementAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const findAllWithoutPageDownLoadReport = (vid) => {
        MarketplaceServices.findMedia(vendorSettlementDataAPI, {
            vendor_id: parseInt(vid),
        })
            .then(function (response) {
                console.log(
                    'Server response from findAllWithoutPageDownLoadReport API call is',
                    vendorSettlementDataAPI,
                    response.data
                )
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'VendorReport.csv'
                alink.click()
            })
            .catch((error) => {
                console.log(
                    'error server response from findAllWithoutPageDownLoadReport API call is',
                    vendorSettlementDataAPI,
                    error.response
                )
            })
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <>
            {vendorSettlementDetailsAPIData && paymentCycleInfoRedux && paymentCycleInfoRedux.length > 0 ? (
                <Content className={`bg-white p-3 ${langDirection == 'rtl' ? '!mr-4' : ''}`}>
                    <Content className='!flex'>
                        <Content className=''>
                            <p className='text-xl mr-1'>
                                {vendorSettlementDetailsAPIData && vendorSettlementDetailsAPIData.vendor_name}
                            </p>
                        </Content>
                        <Content className=' flex !justify-end'>
                            <Button
                                shape='default'
                                className='mr-3 app-btn-primary'
                                onClick={() => findAllWithoutPageDownLoadReport(id)}>
                                {t('transactions:download_reports')}
                            </Button>
                        </Content>
                    </Content>
                    <Content className='mt-1'>
                        <DmTabAntDesign
                            tabType={'line'}
                            tabBarPosition={'top'}
                            tabData={tabData}
                            activeKey={
                                pathNameSplittedArray &&
                                pathNameSplittedArray.length > 0 &&
                                pathNameSplittedArray[6] === 'overview'
                                    ? 1
                                    : pathNameSplittedArray &&
                                        pathNameSplittedArray.length > 0 &&
                                        pathNameSplittedArray[6] === 'salesdetails'
                                      ? 2
                                      : pathNameSplittedArray &&
                                          pathNameSplittedArray.length > 0 &&
                                          pathNameSplittedArray[6] === 'returns'
                                        ? 3
                                        : pathNameSplittedArray &&
                                            pathNameSplittedArray.length > 0 &&
                                            pathNameSplittedArray[6] === 'history'
                                          ? 4
                                          : pathNameSplittedArray &&
                                              pathNameSplittedArray.length > 0 &&
                                              pathNameSplittedArray[6] === 'settings'
                                            ? 5
                                            : 1
                            }
                            handleTabChangeFunction={(value) => handleTabChange(value)}
                        />
                    </Content>
                    <Content>
                        <Outlet />
                    </Content>
                    <Content>
                        <StoreModal
                            isVisible={adjustAndPayModalOpen}
                            title={t('transactions:adjust_and_pay')}
                            okCallback={() => adjustAndPayPostCall()}
                            okButtonText={t('transactions:adjust_and_pay')}
                            cancelButtonText={t('common:discard')}
                            cancelCallback={toggleAdjustAndPayModal}
                            isSpin={false}
                            width={600}>
                            <Content className='!flex !flex-col !justify-center !items-center'>
                                <Content className='my-3'>
                                    <Row>
                                        <Col>
                                            <span className='!mr-1'> {t('transactions:adjust_to')} </span>
                                            <InputNumber
                                                value={adjustedValue}
                                                className='!ml-1'
                                                onChange={onChangeAdjustNumber}
                                            />
                                        </Col>
                                    </Row>
                                </Content>
                                <Content className='my-3'>
                                    <Row>
                                        <Col>
                                            <span className='!mr-1'> {t('transactions:reason_for_adjustment')}</span>
                                        </Col>
                                        <Col>
                                            <TextArea
                                                // showCount
                                                maxLength={225}
                                                value={reasonForAdjustment}
                                                placeholder={`${t('transactions:make_it_draft_request_message')}`}
                                                autoSize={true}
                                                onChange={(e) => setReasonForAdjustment(e.target.value)}
                                                // className="!min-h-[120px]"
                                                className='!ml-1'
                                                // style={{ height: 120 }}
                                            />
                                        </Col>
                                    </Row>
                                </Content>
                            </Content>
                        </StoreModal>
                    </Content>
                    <Content>
                        <StoreModal
                            isVisible={payNowModalOpen}
                            // title={"Adjust & Pay"}
                            okCallback={() => payNowPostCall()}
                            okButtonText={`${t('common:yes')}`}
                            cancelButtonText={`${t('common:no')}`}
                            cancelCallback={togglePayNowModal}
                            // isSpin={isMakeItDraftUploading}
                            isSpin={false}
                            width={500}>
                            <Content className='!flex !flex-col !justify-center !items-center'>
                                <Content className='my-3'>
                                    <Row>
                                        <Col>
                                            <span className='!mr-1 !mt-1'>
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'yellow',
                                                        fontSize: '150%',
                                                        marginTop: '3px',
                                                    }}
                                                />
                                            </span>
                                        </Col>
                                        <Col className='!ml-3'>
                                            <Content>
                                                <Title level={4}>
                                                    {t('transactions:are_you_sure_to_make_the_payment')}
                                                </Title>
                                                <p>
                                                    {t('transactions:will_make_a_payment_of')} {currencySymbolFromRedux}{' '}
                                                    {vendorSettlementDetailsAPIData &&
                                                    vendorSettlementDetailsAPIData.vendor_outstanding_amount
                                                        ? vendorSettlementDetailsAPIData.vendor_outstanding_amount
                                                        : '-'}{' '}
                                                    to{' '}
                                                    {vendorSettlementDetailsAPIData &&
                                                        vendorSettlementDetailsAPIData.vendor_name}
                                                </p>
                                            </Content>
                                        </Col>
                                    </Row>
                                </Content>
                            </Content>
                        </StoreModal>
                    </Content>
                </Content>
            ) : (
                <Content className='flex justify-center items-center'>
                    <p className='text-[20px]'> {t('transactions:details_are_not_available')}</p>
                </Content>
            )}
        </>
    )
}

export default TranscationsParticularVendor
