import { Layout, Row, Skeleton, Steps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'

const getOrderStatusAPI = process.env.REACT_APP_ORDER_STATUS_API
/**
 * Ant design components destructuring
 */
const { Content } = Layout
const { Text } = Typography
const { Step } = Steps

function OrderJourney({ order_item_id }) {
    const [orderJourneyItems, setOrderJourneyItems] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const { t } = useTranslation()

    const getProcessedOrderStatus = (serverData) => {
        let processedOrderStatus = []
        if (serverData && serverData.journey_data) {
            const journeyData = serverData.journey_data
            for (let index = 0; index < journeyData.length; index++) {
                if (journeyData.length === 1) {
                    return journeyData[index]
                } else {
                    if (index !== journeyData.length - 1) {
                        //Take only completed journeys
                        const allJourneys = journeyData[index]
                        const completedJourneys = allJourneys.filter(
                            (element) => element.status.toUpperCase() === 'COMPLETED'
                        )
                        processedOrderStatus = [...processedOrderStatus, ...completedJourneys]
                    } else {
                        //Take all journeys
                        processedOrderStatus = [...processedOrderStatus, ...journeyData[index]]
                    }
                }
            }
        }

        return processedOrderStatus
    }

    /**
     * API call to get the order status based on  order_item_id.
     * This function is responsible for the order journey items.
     */
    const getOrderStatus = (order_item_id) => {
        MarketplaceServices.findAllWithoutPage(
            getOrderStatusAPI,
            {
                order_line_item_id: order_item_id,
            },
            false
        )
            .then(function (response) {
                console.log(
                    'Server Response from get call of getOrderStatus',
                    getOrderStatusAPI,
                    response.data.response_body.Response
                )
                const processedOrderStatus = getProcessedOrderStatus(response.data.response_body.Response)
                var finalArray = []
                var dropDownArray = []
                var eachItem = {}
                var eachItemForDropDown = {}
                let notCompletedCount = 0
                processedOrderStatus.forEach((item) => {
                    eachItem = {}
                    eachItemForDropDown = {}
                    eachItem.title = item.name
                    eachItemForDropDown.title = item.name
                    eachItemForDropDown.value = item.name
                    eachItemForDropDown.description = item.ETA
                    if (String(item.status).toUpperCase() === 'COMPLETED') {
                        eachItem.status = 'finish'
                        eachItemForDropDown.disabled = true
                        notCompletedCount = 0
                    } else if (String(item.status).toUpperCase() === 'NOT COMPLETED') {
                        if (notCompletedCount === 0) {
                            eachItem.status = 'process'
                        } else {
                            eachItem.status = 'awaiting'
                        }
                        eachItemForDropDown.disabled = false
                        notCompletedCount++
                    } else {
                        eachItem.status = 'wait'
                    }
                    finalArray.push(eachItem)
                    dropDownArray.push(eachItemForDropDown)
                })
                setOrderJourneyItems(finalArray)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Error Server Response from getOrderStatus', getOrderStatusAPI, error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    useEffect(() => {
        getOrderStatus(order_item_id)
    }, [])
    return (
        <Content className='w-max h-full'>
            {isLoading ? (
                <Content className='bg-white p-3'>
                    <Skeleton paragraph={{ rows: 3 }} />
                </Content>
            ) : isNetworkError ? (
                <Content className='text-center bg-white p-3'>
                    <h5 className='!flex !justify-center'>{t('common:network_error')}</h5>
                </Content>
            ) : (
                <>
                    {orderJourneyItems && orderJourneyItems.length > 0 ? (
                        <Content
                            className={`${
                                orderJourneyItems && orderJourneyItems.length > 2 ? '!min-w-[1300px]' : '!min-w-[650px]'
                            }`}>
                            <Row key='1'>
                                <Steps direction='horizontal' percent={50}>
                                    {orderJourneyItems &&
                                        orderJourneyItems.length > 0 &&
                                        orderJourneyItems.map((item, index) => (
                                            <Step
                                                status={item.status}
                                                key={index}
                                                title={
                                                    <Text
                                                        ellipsis={true}
                                                        className='!text-[14px] !mt-0 !pt-0 !pb-[6px]'>
                                                        {t(`coupler:${util.getCouplerMultilingualKey(item.title)}`)}
                                                    </Text>
                                                }
                                            />
                                        ))}
                                </Steps>
                            </Row>
                        </Content>
                    ) : (
                        <Content>
                            <p className='!font-semibold !mb-0 text-[16px] !ml-5'>{t('sales_orders:order_progress')}</p>
                        </Content>
                    )}
                </>
            )}
        </Content>
    )
}

export default OrderJourney
