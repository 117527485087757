import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnPaymentCycleInfo } from '../../services/redux/actions/ActionsPaymentCycle'
import { fnVendorSettlementInfo } from '../../services/redux/actions/ActionsVendorSettlement'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import TranscationsOutlet from './TranscationsOutlet'
const { Content } = Layout
const { Title, Text, Paragraph } = Typography
const vendorSettlementDetailsAPI = process.env.REACT_APP_VENDOR_DETAILS_API
const getVendorPaymentCycleAPI = process.env.REACT_APP_PAYMENT_CYCLE_API
const vendorPaymentSettlementAPI = process.env.REACT_APP_VENDOR_PAYMENT_SETTLEMENT_API
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const vendorSettlementDataAPI = process.env.REACT_APP_VENDOR_SETTLEMENT_DATA_API

const Transcations = () => {
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const [vendorSettlementDetails, setVendorSettlementDetails] = useState()
    const [payNowModalOpen, setPayNowModalOpen] = useState(false)

    let selectedVendorSettlementInfoRedux = useSelector(
        (state) => state.reducerSelectedVendorSettlemetInfo.selectedVendorSettlementInfo
    )
    const [langDirection, setLangDirection] = useState('ltr')

    let vendorSettlementInfoRedux = useSelector((state) => state.reducerVendorSettlementInfo.vendorSettlementInfo)

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    // console.log("contentSettingFromRedux#", contentSettingFromRedux);
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol
    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI
    const findByPageSettlementDetailsList = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(vendorSettlementDetailsAPI, null, pageNumber, pageLimit, false)
            .then(function (response) {
                console.log(
                    'Server response from findByPageSettlementDetailsList is',
                    vendorSettlementDetailsAPI,
                    response.data.response_body
                )
                setVendorSettlementDetails(response.data.response_body)
                dispatch(fnVendorSettlementInfo(response.data.response_body))
            })
            .catch(function (error) {
                dispatch(fnVendorSettlementInfo({}))
                console.log(
                    'Server Error response from findByPageSettlementDetailsList is',
                    vendorSettlementDetailsAPI,
                    error.response
                )
            })
    }

    const findAllWithoutPageVendorPaymentCycle = () => {
        MarketplaceServices.findAllWithoutPage(getVendorPaymentCycleAPI, null, false)
            .then(function (response) {
                console.log(
                    'Server response from findAllWithoutPageVendorPaymentCycle is',
                    getVendorPaymentCycleAPI,
                    response.data
                )
                dispatch(fnPaymentCycleInfo(response.data.response_body))
            })
            .catch((error) => {
                console.log(
                    'server error response from findAllWithoutPageVendorPaymentCycle',
                    getVendorPaymentCycleAPI,
                    error.response
                )
                dispatch(fnPaymentCycleInfo([]))
            })
    }

    useEffect(() => {
        findByPageSettlementDetailsList()
        findAllWithoutPageVendorPaymentCycle()
    }, [])

    const findAllWithoutPageDownLoadReport = () => {
        MarketplaceServices.findMedia(vendorSettlementDataAPI, null)
            .then(function (response) {
                console.log(
                    'Server response from findAllWithoutPageDownLoadReport is',
                    vendorSettlementDataAPI,
                    response.data
                )
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'StoreRevenue.csv'
                alink.click()
            })
            .catch((error) => {
                console.log(
                    'error Server response from findAllWithoutPageDownLoadReport is',
                    vendorSettlementDataAPI,
                    error.response
                )
            })
    }

    const togglePayNowModal = () => {
        setPayNowModalOpen(!payNowModalOpen)
    }

    const payNowPostCall = () => {
        const vendorName = selectedVendorSettlementInfoRedux.vendor_name
        const vendorId = vendorSettlementInfoRedux.vendors_list.find(
            (element) => element.vendor__name === vendorName
        ).vendor_id
        // console.log("selectedVendorInfoRedux", vendorSettlementInfoRedux, vendorId);
        //!TODO: currenncy is hardcoded
        let requestObject = {
            currency: 'eur',
        }
        MarketplaceServices.save(vendorPaymentSettlementAPI, requestObject, {
            vendor_id: vendorId,
        })
            .then((response) => {
                console.log('server Response from payNowPostCall is', vendorPaymentSettlementAPI, response.data)

                setPayNowModalOpen(!payNowModalOpen)
                MarketplaceToaster.showToast(response)
                // toast(`${t("transactions:payment_successful")}`, {
                //   position: toast.POSITION.TOP_RIGHT,
                //   type: "success",
                //   autoClose: 10000,
                // });
            })
            .catch((error) => {
                console.log('Error Server response from payNowPostCall is', vendorPaymentSettlementAPI, error.response)
                MarketplaceToaster.showToast(error.response)
            })
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content className=''>
            <HeaderForTitle
                title={
                    <Title level={3} className='!font-normal'>
                        {t('common:transactions')}
                    </Title>
                }
                headerContent={
                    <Paragraph className='!font-semibold !text-slate-400'>
                        {t('common:transaction_short_desc')}
                    </Paragraph>
                }
            />
            <Content className='p-3 !min-h-screen'>
                <Content className=''>
                    <Content className='flex'>
                        <Content
                            className={`border-[1px] border-[#bbbbbb]  bg-white p-3  w-[100%] ${
                                langDirection === 'rtl' ? '!ml-4' : '!mr-4'
                            }`}>
                            <Title level={5} className='!m-0 !text-slate-400'>
                                {t('transactions:upcoming_payout')}
                            </Title>
                            {/* <Title level={4} className="!m-0">
                {paymentCycleInfoRedux &&
                  paymentCycleInfoRedux.length > 0 &&
                  paymentCycleInfoRedux[0].payment_due_date}
              </Title> */}
                            <Title level={4} className='!mt-1 text-2xl'>
                                {selectedVendorSettlementInfoRedux && selectedVendorSettlementInfoRedux.vendor_name}
                                <span className='text-pink-500 ml-2'>
                                    {currencySymbolFromRedux}{' '}
                                    {selectedVendorSettlementInfoRedux
                                        ? // selectedVendorSettlementInfoRedux.length > 0
                                          selectedVendorSettlementInfoRedux.vendor_outstanding_amount
                                        : 0}
                                </span>
                            </Title>
                            {/* <Button
              className="block !mt-2 app-btn-primary"
              onClick={() => togglePayNowModal()}
            >
              {t("transactions:pay_now")}
            </Button> */}
                        </Content>
                        <Content className=' border-[1px] border-[#bbbbbb]  bg-white p-3 w-[100%]'>
                            <Title level={5} className='!m-0 !text-slate-400'>
                                {t('transactions:store_revenue')}
                            </Title>
                            <Title level={4} className='!m-0 !text-green-400'>
                                {currencySymbolFromRedux}{' '}
                                {vendorSettlementDetails && vendorSettlementDetails.total_store_commission}
                            </Title>
                            <Text className='!mt-1'>
                                {t('transactions:all_outstanding_payment')}
                                <span className='text-pink-500 ml-2'>
                                    {currencySymbolFromRedux}{' '}
                                    {vendorSettlementDetails && vendorSettlementDetails.total_outstanding_amount}
                                </span>
                            </Text>
                            <Button
                                className='app-btn-primary block !mt-2  '
                                onClick={() => findAllWithoutPageDownLoadReport()}>
                                {t('transactions:download_reports')}
                            </Button>
                        </Content>
                        <StoreModal
                            isVisible={payNowModalOpen}
                            // title={"Adjust & Pay"}
                            okCallback={() => payNowPostCall()}
                            okButtonText={`${t('common:yes')}`}
                            cancelButtonText={`${t('common:no')}`}
                            cancelCallback={togglePayNowModal}
                            // isSpin={isMakeItDraftUploading}
                            isSpin={false}
                            width={500}>
                            <Content className='!flex !flex-col !justify-center !items-center'>
                                <Content className='my-3'>
                                    <Row>
                                        <Col>
                                            <span className='!mr-1 !mt-1'>
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'yellow',
                                                        fontSize: '150%',
                                                        marginTop: '3px',
                                                    }}
                                                />
                                            </span>
                                        </Col>
                                        <Col className='!ml-3'>
                                            <Content>
                                                <Title level={4}>
                                                    {' '}
                                                    {t('transactions:are_you_sure_to_make_the_payment')}
                                                </Title>
                                                <p>
                                                    {t('transactions:will_make_a_payment_of')} {currencySymbolFromRedux}{' '}
                                                    {selectedVendorSettlementInfoRedux &&
                                                    selectedVendorSettlementInfoRedux.vendor_outstanding_amount
                                                        ? selectedVendorSettlementInfoRedux &&
                                                          selectedVendorSettlementInfoRedux.vendor_outstanding_amount
                                                        : ' '}{' '}
                                                    {'-'} to{' '}
                                                    {selectedVendorSettlementInfoRedux &&
                                                        selectedVendorSettlementInfoRedux.vendor_name}
                                                </p>
                                            </Content>
                                        </Col>
                                    </Row>
                                </Content>
                            </Content>
                        </StoreModal>
                    </Content>
                </Content>
                <Content className='!min-h-screen'>
                    <TranscationsOutlet />
                </Content>
            </Content>
        </Content>
    )
}

export default Transcations
