import { ActionTypes } from '../constants/ActionTypes'

const { RDX_ROLE_AVAILABLE_INFO, RDX_ROLE_SELECTED_INFO } = ActionTypes

// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods

export const ReducerRoleAvailable = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_ROLE_AVAILABLE_INFO:
            return { ...state, roleAvailableInfo: payload }
        default:
            return state
    }
}

export const ReducerRoleSelected = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_ROLE_SELECTED_INFO:
            return { ...state, roleSelectedInfo: payload }
        default:
            return state
    }
}
