import { ConfigProvider, Layout, Spin } from 'antd'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import './core-ui/app.css'
import './core-ui/buttons.css'
import './core-ui/footer.css'
import './core-ui/header.css'

import Home from './pages/home/Home'

import Header2 from './components/header/Header2'
import DashboardContent from './pages/dashboard/DashboardContent'
import ProductTypes from './pages/products/ProductTypes'
// import PageNotFound from "./pages/404/PageNotFound";
import { LoadingOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useAuth } from 'react-oidc-context'
import PDPLayout1 from './components/Layouts/ProductDetails/PDPLayout1/PDPLayout1'
import LogOut from './components/LogOut'
import { useFavicon } from './hooks/useFavicon'
import useUserLanguage from './hooks/useUserLanguage'
import CreateLayout from './pages-2.0/Layout/CreateLayout'
import ListLayout from './pages-2.0/Layout/ListLayout'
import NewParentLayout from './pages-2.0/ProductTemplates/NewParentLayout'
import ProductTemplates from './pages-2.0/ProductTemplates/ProductTemplates'
import ProductPublishingEditNew from './pages-2.0/Products/ProductPublishingEditNew'
import ProductsListingNewDesign from './pages-2.0/Products/ProductsListingNewDesign'
import VariantAttributeDetailsLatest from './pages-2.0/Products/VariantAttributeDetailsNewLatest'
import RelatedProducts from './pages-2.0/RelatedProducts/RelatedProducts'
import SFSettings from './pages-2.0/SFSettings/SFSettings'
import Language from './pages-2.0/StoreLanguage/Language'
import LanguageSettings from './pages-2.0/StoreLanguage/LanguageSettings'
import PaymentList from './pages-2.0/StorePayment/PaymentList'
import StoreSettings from './pages-2.0/StoreSetting/StoreSettings'
import MyProfile from './pages-2.0/StoreUsers/MyProfile'
import StoreUser from './pages-2.0/StoreUsers/StoreUser'
import AddVariantAttributes from './pages-2.0/VariantAttributes/components/AddVariantAttributes'
import Approvals from './pages-2.0/approvals/Approvals'
import ListApprovalsNew from './pages-2.0/approvals/ListApprovalsNew'
import ListOrders from './pages-2.0/orders/ListOrders'
import ListSalesReturns from './pages-2.0/orders/ListSalesReturns'
import Policies from './pages-2.0/policies/Policies'
import AddCategories from './pages-2.0/product-category/AddCategories'
import ListCategories from './pages-2.0/product-category/ListCategories'
import AddAttributeGroups from './pages-2.0/productattributegroups/AddAttributeGroups'
import ListAttributeGroups from './pages-2.0/productattributegroups/ListAttributeGroups'
import History from './pages-2.0/transactions/History'
import Overview from './pages-2.0/transactions/Overview'
import Returns from './pages-2.0/transactions/Returns'
import Salesdetails from './pages-2.0/transactions/Salesdetails'
import Settings from './pages-2.0/transactions/Settings'
import Settlement from './pages-2.0/transactions/Settlement'
import TranscationVendorDetails from './pages-2.0/transactions/TranscationVendorDetails'
import Transcations from './pages-2.0/transactions/Transcations'
import TranscationsParticularVendor from './pages-2.0/transactions/TranscationsParticularVendor'
import TranscationsPayment from './pages-2.0/transactions/TranscationsPayment'
import CreateRoles from './pages-2.0/usersandroles/CreateRoles'
import CreateUsers from './pages-2.0/usersandroles/CreateUsers'
import UserAccessControl from './pages-2.0/usersandroles/UserAccessControl'
import VendorDetails from './pages-2.0/vendors/VendorDetails'
import Vendors from './pages-2.0/vendors/Vendors'
import CouponComponent from './pages/coupons/Coupon'
import CouponOfferComponent from './pages/coupons/CouponOffer'
import CouponPage from './pages/coupons/CouponPage'
import CouponRulesComponent from './pages/coupons/CouponRules'
import CreateDiscount from './pages/coupons/CreateDiscount'
import StoreProductTypes from './pages/products/StoreProductTypes'
import Sidebar from './pages/sidebar/Sidebar'
import MarketplaceServices from './services/axios/MarketplaceServices'
import util from './util/common'
import OrderDetails from './pages-2.0/orders/OrderDetails'
import { usePageTitle } from './hooks/usePageTitle'
import { useTranslation } from 'react-i18next'

const umsBaseUrl = process.env.REACT_APP_USM_BASE_URL
const getPermissionsUrl = process.env.REACT_APP_USER_PROFILE_API
// const getPermissionsUrl = process.env.REACT_APP_PERMISSIONS;
const { Content } = Layout

const App = () => {
    const auth = useAuth()
    

    const { isUserLanguageLoading } = useUserLanguage()
    const [permissionData, setPermissionData] = useState()

    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [collapsed, setCollapsed] = useState(false)

    //! Set App Favicon using useFavicon custom hook
    useFavicon()
    
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal'))

    const getPermissions = () => {
        let baseurl = `${umsBaseUrl}${getPermissionsUrl}`
        MarketplaceServices.findAll(baseurl, null, false)
            .then((res) => {
                console.log('Server response from getPermissions is', baseurl, res)
                var realmNameClient = res.data.response_body['realm-name'] + '-client'
                setPermissionData(res.data.response_body.resource_access[`${realmNameClient}`].roles)
                util.setPermissionData(res.data.response_body.resource_access[`${realmNameClient}`].roles)
            })
            .catch((err) => {
                console.log('Error server response from getPermissions', baseurl, err.response)
            })
    }

    useEffect(() => {
        let storeName = window.sessionStorage.getItem('client')
        console.log('storeName---->', storeName)
        if (auth && auth.user && auth.user?.access_token && storeName != '') {
            Cookies.set(storeName + '_' + 'smp_access_token', auth?.user?.access_token)
            Cookies.set(storeName + '_' + 'smp_refresh_token', auth.user?.refresh_token)
            Cookies.set(storeName + '_' + 'smp_is_authorized', true)
            getPermissions()
        }
    }, [auth])

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54,
            }}
            spin
        />
    )

    switch (auth.activeNavigator) {
        case 'signinSilent':
            return <div></div>
        case 'signoutRedirect':
            return <div></div>
    }
    if (auth.isLoading || isUserLanguageLoading) {
        return (
            <Layout className='h-[100vh]'>
                <Content className='grid justify-items-center align-items-center h-full'>
                    <Spin indicator={antIcon} />
                </Content>
            </Layout>
        )
    }

    if (auth.error) {
        return void auth.signoutRedirect()
    }
    return (
        <ConfigProvider
            theme={{
                components: {
                    Radio: {
                        colorTextDisabled: '#808080',
                    },
                    Checkbox: {
                        colorTextDisabled: '#808080',
                    },
                    Input: {
                        colorTextDisabled: '#808080',
                    },
                    DatePicker: {
                        colorTextDisabled: '#808080',
                    },
                    Dropdown: {
                        colorTextDisabled: '#808080',
                    },
                    Select: {
                        colorTextDisabled: '#808080',
                    },
                    Table: {
                        headerColor: '#023047',
                    },
                    Tabs: {
                        itemSelectedColor: 'var(--mp-primary-background-color)',
                        inkBarColor: 'var(--mp-primary-background-color)',
                        itemColor: 'var(--mp-table-text-color)',
                        itemHoverColor: 'var(--mp-primary-background-color)',
                        titleFontSizeLG: 24,
                    },
                },
            }}>
            <Router>
                <ToastContainer
                    rtl={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? true : false}
                    position={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'top-left' : 'top-right'}
                />
                <Header2 collapsed={collapsed} setCollapsed={setCollapsed} />
                <Layout className='bg-[#F4F4F4] !mt-10 w-full'>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/home' element={<Home />} />
                        <Route path='/logout' element={<LogOut />} />
                        {auth.isAuthenticated ? (
                            <Route
                                path='/dashboard'
                                element={<Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />}>
                                <Route
                                    path=''
                                    element={
                                        <DashboardContent
                                            setIsLoggedIn={setIsLoggedIn}
                                            isLoggedIn={isLoggedIn}
                                            permission={permissionData}
                                        />
                                    }
                                />
                                <Route
                                    path='settings/product-types'
                                    element={<ProductTypes permission={permissionData} />}
                                />
                                <Route
                                    path='settings/store-product-types'
                                    element={<StoreProductTypes permission={permissionData} />}
                                />
                                <Route path='languages' element={<Language permission={permissionData} />} />
                                <Route path='languages/language-settings' element={<LanguageSettings />} />
                                <Route path='payment' element={<PaymentList />} />
                                <Route path='store-settings' element={<StoreSettings />} />
                                <Route path='store-front' element={<SFSettings permission={permissionData} />} />
                                <Route path='list-layout' element={<ListLayout permission={permissionData} />} />
                                <Route path='add-layout' element={<CreateLayout permission={permissionData} />} />
                                <Route path='edit-layout' element={<CreateLayout permission={permissionData} />} />

                                <Route
                                    path='list-attributegroups'
                                    element={<ListAttributeGroups permission={permissionData} />}
                                />
                                <Route
                                    path='list-attributegroups/add-attributegroups'
                                    element={<AddAttributeGroups permission={permissionData} />}
                                />
                                {/* <Route
                                    path='list-attributegroups/edit-attributegroups'
                                    element={<AddAttributeGroups permission={permissionData} />}
                                /> */}
                                <Route path='categories'>
                                    <Route
                                        path='list-category'
                                        element={<ListCategories permission={permissionData} />}
                                    />
                                    <Route
                                        path='add-category'
                                        element={<AddCategories permission={permissionData} />}
                                    />
                                    <Route
                                        path='edit-category'
                                        element={<AddCategories permission={permissionData} />}
                                    />
                                </Route>

                                {/* <Route path="products" element={<Inventory />} /> */}

                                <Route path='product-template'>
                                    <Route
                                        path='list-product-templates'
                                        element={<ProductTemplates permission={permissionData} />}
                                    />
                                    <Route
                                        path='edit-product-template'
                                        element={<NewParentLayout permission={permissionData} />}
                                    />
                                    <Route
                                        path='show-publishing-product'
                                        element={<ProductPublishingEditNew permission={permissionData} />}
                                    />
                                    <Route
                                        path='add-variant-attribute'
                                        element={<AddVariantAttributes permission={permissionData} />}
                                    />
                                    <Route
                                        path='variant-attribute-details'
                                        element={<VariantAttributeDetailsLatest permission={permissionData} />}
                                    />
                                </Route>
                                <Route
                                    path='products/edit-product'
                                    element={<ProductPublishingEditNew permission={permissionData} />}></Route>
                                <Route
                                    path='products/variant-attribute-details'
                                    element={<VariantAttributeDetailsLatest permission={permissionData} />}
                                />
                                <Route path='settlement' element={<Settlement />} />
                                <Route path='storeuser' element={<StoreUser permission={permissionData} />} />
                                <Route path='userprofile' element={<MyProfile permission={permissionData} />} />
                                <Route path='orders'>
                                    <Route
                                        path='list-orders'
                                        element={<ListSalesReturns permission={permissionData} />}
                                    />
                                    <Route
                                        path='order-details'
                                        element={<OrderDetails permission={permissionData} />}
                                    />
                                </Route>

                                <Route
                                    path='orders/sales-orders'
                                    element={<ListOrders showHeader={true} permission={permissionData} />}
                                />
                                <Route
                                    path='orders/sales-returns'
                                    element={<ListSalesReturns permission={permissionData} />}
                                />
                                <Route path='vendors'>
                                    <Route path='list-vendors' element={<Vendors permission={permissionData} />} />
                                    <Route
                                        path='vendors-details'
                                        element={<VendorDetails permission={permissionData} />}
                                    />
                                </Route>
                                <Route path='transactions' element={<Transcations permission={permissionData} />}>
                                    <Route
                                        path='vendor-details'
                                        element={<TranscationVendorDetails permission={permissionData} />}>
                                        <Route path='particular-vendor/:id' element={<TranscationsParticularVendor />}>
                                            <Route path='overview' element={<Overview />} />
                                            <Route path='salesdetails' element={<Salesdetails />} />
                                            <Route path='settings' element={<Settings />} />
                                            <Route path='history' element={<History />} />
                                            <Route path='returns' element={<Returns />} />
                                        </Route>
                                    </Route>
                                    <Route
                                        path='payment-history'
                                        element={<TranscationsPayment permission={permissionData} />}
                                    />
                                </Route>
                                <Route path={'products/list-products'} element={<ProductsListingNewDesign />} />

                                <Route
                                    path={'relative-products/list-relative-products'}
                                    element={<RelatedProducts permission={permissionData} />}
                                />
                                <Route path='layout' element={<PDPLayout1 />} />

                                <Route path='approvals' element={<Approvals permission={permissionData} />}>
                                    <Route
                                        path='list-approvals'
                                        element={<ListApprovalsNew permission={permissionData} />}
                                    />
                                </Route>
                                <Route path='coupons' element={<CouponPage permission={permissionData} />}>
                                    <Route
                                        path='list-coupon-offers'
                                        element={<CouponOfferComponent permission={permissionData} />}
                                    />
                                    <Route
                                        path='list-coupon-rules'
                                        element={<CouponRulesComponent permission={permissionData} />}
                                    />
                                    <Route
                                        path='list-coupons'
                                        element={<CouponComponent permission={permissionData} />}
                                    />
                                </Route>
                                <Route path='coupons/create-discount' element={<CreateDiscount />} />
                                <Route path='coupons/edit-discount' element={<CreateDiscount />} />
                                <Route
                                    path='coupons/list-coupons'
                                    element={<CouponPage permission={permissionData} />}
                                />
                                <Route path='coupon/create-discount' element={<CreateDiscount />} />
                                <Route
                                    path='coupons/list-coupons'
                                    element={<CouponPage permission={permissionData} />}
                                />
                                <Route path='user-access-control'>
                                    <Route path='list-user-roles' element={<UserAccessControl />} />
                                    <Route path='add-user' element={<CreateUsers />} />
                                    <Route path='edit-user' element={<CreateUsers />} />
                                    <Route path='add-roles' element={<CreateRoles />} />
                                    <Route path='edit-roles' element={<CreateRoles />} />
                                </Route>
                                <Route path='policies' element={<Policies />} />
                            </Route>
                        ) : (
                            <>{void auth.signinRedirect()}</>
                        )}
                    </Routes>
                </Layout>
            </Router>
        </ConfigProvider>
    )
}

export default App
