import { Button, Col, Layout, Row, Upload } from 'antd'

import { UploadOutlined } from '@ant-design/icons'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
const { Content } = Layout
const uploadImageAPI = process.env.REACT_APP_IMAGES_API
const uploadDocumentAPI = process.env.REACT_APP_STORE_DOCUMENT_API
const maxFileSizeDefined = process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD
const minFileSizeDefined = process.env.REACT_APP_MIN_FILE_SIZE_UPLOAD
const supportedExtensionsFromEnv = process.env.REACT_APP_IMAGES_EXTENSIONS

const maxFileSize = maxFileSizeDefined.replace(/MB/g, '')

const AddImage = forwardRef((props, ref) => {
    const { t } = useTranslation()

    //! if filetype is "image" then validate extension from .env file otherwise from props {props.supportedExtensions}
    const supportedExtensions =
        props.fileType === 'image'
            ? props.supportedExtensions && props.supportedExtensions.length > 1
                ? props.supportedExtensions
                : supportedExtensionsFromEnv
            : props.supportedExtensions
    const [imageExtension, setImageExtension] = useState('')
    const [fileValue, setFileValue] = useState()
    const [formattedExtension, setFormattedExtension] = useState()
    useImperativeHandle(ref, () => ({
        getSave(value) {
            if (value === 'save') {
                validation()
            } else {
                handleClearInputFields()
            }
        },
    }))

    const uploadImage = (apiEndPoint, formData) => {
        MarketplaceServices.save(apiEndPoint, formData, null)
            .then((res) => {
                console.log('response', res)
                console.log('response From Doc Upload', res.data)
                const tempData = getFinalData([res.data.response_body])
                props.setUploadedImageData(tempData)
                props.handleSaveImgae()
                props.setSpinnerForAddImage(false)
                MarketplaceToaster.showToast(res)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                props.setSpinnerForAddImage(false)
                console.log('error response ', error.response)
            })
    }

    //  for valid `123syntax
    const getFinalData = (postResponse) => {
        console.log('postResponse Data-- ', postResponse)

        var finalData = []

        switch (props.fileType) {
            case 'image':
                postResponse &&
                    postResponse.length > 0 &&
                    postResponse.forEach((value) => {
                        let obj = {
                            default_ar: '',
                            default_size_name: value.default_size_name,
                            extension: value.extension,
                            id: value.id,
                            image_path: value.image_path,
                            image_full_path: value.image_full_path,
                            image_type: value.image_type,
                            specific_to: value.specific_to,
                            status: value.status,
                            store_id: value.store_id,
                            visibility: value.visibility,
                        }
                        finalData.push(obj)
                    })
                break
            case 'document':
                postResponse &&
                    postResponse.length > 0 &&
                    postResponse.forEach((value) => {
                        let obj = {
                            id: value.id,
                            store_id: value.store_id,
                            document_path: value.document_path,
                            document_type: value.document_type,
                            document_full_path: value.document_full_path,
                            file_name: value.file_name,
                            extension: value.extension,
                            status: value.status,
                            specific_to: value.specific_to,
                            visibility: value.visibility,
                        }
                        finalData.push(obj)
                    })
                break

            default:
                break
        }

        return finalData
    }

    const validation = () => {
        const formData = new FormData()
        var count = 3
        var apiEndPoint = ''

        switch (props.fileType) {
            case 'image':
                if (fileValue && fileValue.length > 0) {
                    formData.append(
                        'original_image',
                        props.selectionOption === 'multiple' ? fileValue : fileValue[0].originFileObj
                    )
                } else {
                    count -= 1
                    props.setSpinnerForAddImage(false)

                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:select_image_file')}`, 'warning'))
                }
                formData.append('image_type', props.actionType[0])
                if (fileValue && fileValue.length > 0) {
                    if (
                        supportedExtensions
                            .trim()
                            .split(/,\s*/)
                            .includes(String(`.${imageExtension.toLowerCase()}`))
                    ) {
                        formData.append('extension', imageExtension)
                    } else {
                        count -= 1
                        props.setSpinnerForAddImage(false)

                        MarketplaceToaster.showToast(
                            util.getToastObject(`${t('common:invalid_file_extension')}`, 'error')
                        )
                        // Resetting when extension is invalid
                        setFileValue()
                        props.setIsFileSelected(false)
                    }
                }
                apiEndPoint = uploadImageAPI
                if (count === 3) {
                    uploadImage(apiEndPoint, formData)
                }
                break
            case 'document':
                var count2 = 3
                if (fileValue && fileValue.length > 0) {
                    formData.append(
                        'original_document',
                        props.selectionOption === 'multiple' ? fileValue : fileValue[0].originFileObj
                    )
                } else {
                    count2 -= 1
                    props.setSpinnerForAddImage(false)

                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:select_document_file')}`, 'warning'))
                }
                if (fileValue && fileValue.length > 0) {
                    // formData.append("store_id", storeId);
                    formData.append('document_type', props.actionType[0])
                    // formData.append("extension", imageExtension);
                    apiEndPoint = uploadDocumentAPI
                    formData.append('extension', imageExtension)
                }
                //! latest comment
                if (fileValue && fileValue.length > 0) {
                    if (
                        supportedExtensions
                            .trim()
                            .split(/,\s*/)
                            .includes(String(`.${imageExtension}`))
                    ) {
                        formData.append('extension', imageExtension)
                    } else {
                        count2 -= 1
                        props.setSpinnerForAddImage(false)

                        MarketplaceToaster.showToast(
                            util.getToastObject(`${t('common:invalid_file_extension')}`, 'error')
                        )
                        // Resetting when extension is invalid
                        setFileValue()
                        props.setIsFileSelected(false)
                    }
                }
                if (count2 === 3) {
                    uploadImage(apiEndPoint, formData)
                }
                break
            default:
                break
        }
    }

    const handleClearInputFields = () => {
        setImageExtension('')
    }

    const handleImageClick = (file) => {
        // Convert the base64 image data to a Blob
        const byteCharacters = atob(file.thumbUrl.split(',')[1])
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], { type: file.type })

        // Create a blob URL
        const imageUrl = URL.createObjectURL(blob)
        // Open the image in a new tab
        window.open(imageUrl, '_blank')
    }
    //function that  handles file upload input tag
    const handleUploadFile = (e) => {
        console.log('---File Selected To Upload=-------', e)
        if (e.fileList.length > 0) {
            props.setIsFileSelected(true)
        } else {
            props.setIsFileSelected(false)
        }

        if (e.fileList.length > 0) {
            let file = e.file
            var arr = file.name.split('.')
            var extension = arr.pop()
            if (
                !supportedExtensions
                    .trim()
                    .split(/,\s*/)
                    .includes(String(`.${extension.toLowerCase()}`)) &&
                props.selectionOption !== 'multiple'
            ) {
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:invalid_file_extension')}`, 'error'))
                // Resetting when extension is invalid
                setFileValue([])
                props.setIsFileSelected(false)
            } else if (e.file.size <= maxFileSize * 1000000) {
                if (props.selectionOption === 'multiple') {
                    setFileValue(e.fileList)
                } else {
                    let file = e.file
                    setFileValue(e.fileList)
                    let arr = file.name.split('.')
                    var lastExtensionValue = arr.pop() //! Get last element (value after last dot)
                    // const fileExtension = finalFile.name.split(".").pop();
                    setImageExtension(lastExtensionValue)
                    console.log('Extension of Image---- ', lastExtensionValue)
                }
            } else {
                if (e.fileList.length > 0) {
                    let fileType = props.fileType
                    MarketplaceToaster.showToast(
                        util.getToastObject(
                            `${t('common:choose_image', {
                                fileType,
                                maxFileSizeDefined,
                            })}`,
                            'warning'
                        )
                    )
                    setFileValue([])
                    props.setIsFileSelected(false)
                }
            }
        } else {
            setFileValue([])
        }
    }

    const formatExtension = () => {
        // Split the string into an array
        let extensionsArray = supportedExtensions.split(',')

        // Add "and" before the last word if there are multiple extensions
        if (extensionsArray.length > 1) {
            extensionsArray[extensionsArray.length - 1] = 'and ' + extensionsArray[extensionsArray.length - 1]
        }

        // Join the array back into a string
        let resultString = extensionsArray.join(', ')
        setFormattedExtension(resultString)
    }

    useEffect(() => {
        if (supportedExtensions) {
            formatExtension()
        }
    }, [supportedExtensions])

    return (
        <>
            <Layout className=''>
                <Content className='bg-white '>
                    <Row className=''>
                        <Col span={24}>
                            <Content>
                                {/* Division for name */}
                                <Content>
                                    <Row className='flex space-y-2'>
                                        <Col span={24} className='!mt-3'>
                                            <Upload
                                                beforeUpload={() => {
                                                    return false
                                                }}
                                                afterUpload={() => {
                                                    return false
                                                }}
                                                showUploadList={true}
                                                accept={supportedExtensions}
                                                maxCount={1}
                                                listType={props.fileType === 'image' ? 'picture' : 'text'}
                                                multiple={props.selectionOption === 'multiple' ? true : false}
                                                onChange={(e) => handleUploadFile(e)}
                                                className='app-btn-secondary'
                                                fileList={fileValue}
                                                onPreview={handleImageClick}>
                                                <Button
                                                    className={'app-btn-secondary flex items-center'}
                                                    icon={<UploadOutlined />}>
                                                    {t('common:click_to_upload')}
                                                </Button>
                                            </Upload>
                                            {props.fileType === 'image' ? (
                                                <p
                                                    style={{
                                                        marginTop: '8px',
                                                        color: 'rgba(0, 0, 0, 0.45)',
                                                    }}>
                                                    {t('common:upload_instruction_image', {
                                                        formattedExtension,
                                                        minFileSizeDefined,
                                                        maxFileSizeDefined,
                                                    })}
                                                </p>
                                            ) : (
                                                <div
                                                    style={{
                                                        marginTop: '8px',
                                                        color: 'rgba(0, 0, 0, 0.45)',
                                                    }}>
                                                    <p>
                                                        {t('common:supported_formats') + ': '}
                                                        <span className='font-semibold'>
                                                            {supportedExtensions.split(',').join(', ')}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        {t('common:maximum_file_size') + ': '}
                                                        <span className='font-semibold'>{maxFileSizeDefined}</span>
                                                    </p>
                                                </div>
                                            )}

                                            {/* <label className="text-[13px]">
                        {t("categories:please_select_file")}
                      </label>
                      <input
                        type="file"
                        name="filename"
                        className="ml-2"
                        onChange={(e) => handleUploadFile(e)}
                        accept={supportedExtensions}
                        multiple={
                         props.selectionOption === "multiple" ? true : false
                        }
                      /> */}
                                        </Col>
                                    </Row>
                                </Content>
                            </Content>

                            {/* <Content className="  mt-3">
                <Row className="flex justify-end items-end ">
                  <Col className="mr-2">
                    <Button onClick={handleClearInputFields}>Cancel</Button>
                  </Col>
                  <Col>
                    <Button onClick={validation}>Upload Image</Button>
                  </Col>
                </Row>
              </Content> */}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    )
})

export default AddImage
