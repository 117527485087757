import { Button, Col, Input, Layout, Row, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

//! Import CSS libraries
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import './AddAttributeGroups.css'

const getAllAttributeGroupAPI = process.env.REACT_APP_SEGMENT_API
// const displayNameOfAttributeGroup = process.env.REACT_APP_PRODUCT_SEGMENT_DISPLAY_NAME_API

const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)
const descriptionMaxLength = parseInt(process.env.REACT_APP_DESCRIPTION_MAX_LENGTH)

const { Title } = Typography
const { Content } = Layout
const { TextArea } = Input

const AddAttributeGroups = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [nameValidation, setNameValidation] = useState(false)
    const [descriptionValidation, setDescriptionValidation] = useState(false)
    const [pageAction, setPageAction] = useState()
    const [onChangeValues, setOnChangeValues] = useState(false)

    // const byDefaultLanguageCode = useSelector(
    //     (state) =>
    //         state.reducerDefaultLanguage &&
    //         state.reducerDefaultLanguage.defaultLanguage &&
    //         state.reducerDefaultLanguage.defaultLanguage.language_code
    // )

    const [attribuetGroupDetails, setAttributeGroupDetails] = useState()

    const handleDiscardClick = () => {
        setOnChangeValues(false)
        setTimeout(() => {
            let copyOfAttributeGroupDetails = { ...attribuetGroupDetails }
            copyOfAttributeGroupDetails.groupName = ''
            copyOfAttributeGroupDetails.groupDescription = ''
            setAttributeGroupDetails(copyOfAttributeGroupDetails)
            navigate('/dashboard/list-attributegroups?' + MarketplaceAppConfig.getStore(''))
        }, 200)
    }

    const attributeGroupFormValidation = () => {
        let count = 2
        if (attribuetGroupDetails.groupName.trim() === '' && attribuetGroupDetails.groupDescription.trim() === '') {
            count--
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
            setNameValidation(true)
            setDescriptionValidation(true)
        } else if (
            attribuetGroupDetails.groupDescription.trim() !== '' &&
            attribuetGroupDetails.groupName.trim() === ''
        ) {
            count--
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
            setNameValidation(true)
        } else if (
            attribuetGroupDetails.groupDescription.trim() === '' &&
            attribuetGroupDetails.groupName.trim() !== ''
        ) {
            count--
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
            setDescriptionValidation(true)
        }
        if (count === 2) {
            saveAttributeGroup()
        }
    }
    const saveAttributeGroup = () => {
        setIsLoading(true)
        const serverObject = {
            product_attribute_groups: [
                {
                    name: attribuetGroupDetails.groupName,
                    description: attribuetGroupDetails.groupDescription?.trim()?.replace(/\s+/g, ' '),
                },
            ],
        }
        MarketplaceServices.save(getAllAttributeGroupAPI, serverObject)
            .then(function (response) {
                console.log(
                    'Server response from saveAttributeGroup is',
                    getAllAttributeGroupAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response, 'success')
                // let dataObjects = {
                //     product_attribute_group_names: [
                //         {
                //             product_attribute_group: response.data.response_body[0].id,
                //             language_code: String(byDefaultLanguageCode),
                //             display_name: response.data.response_body[0].name,
                //             description: response.data.response_body[0].description,
                //         },
                //     ],
                // }
                // saveMultilingual(displayNameOfAttributeGroup, dataObjects)
                let copyOfAttributeGroupDetails = { ...attribuetGroupDetails }
                copyOfAttributeGroupDetails.groupName = ''
                copyOfAttributeGroupDetails.groupDescription = ''
                setAttributeGroupDetails(copyOfAttributeGroupDetails)
                navigate('/dashboard/list-attributegroups?' + MarketplaceAppConfig.getStore(''))
                // defaultObjectForAttributeGroup[0].groupName = response.data.response_body[0].name
                // defaultObjectForAttributeGroup[0].groupDescription = response.data.response_body[0].description
                // console.log('best one for ever...', defaultObjectForAttributeGroup)
                // dispatch(fnAttributeGroupDetails(defaultObjectForAttributeGroup))
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log('Error Server response from saveAttributeGroup is', getAllAttributeGroupAPI, error)
                MarketplaceToaster.showToast(error.response, 'error')
                setIsLoading(false)
            })
    }

    // const saveMultilingual = (api, dataObject) => {
    //     MarketplaceServices.save(api, dataObject)
    //         .then(function (response) {
    //             console.log('Server response from saveMultilingual is---->', api, response.data)
    //             navigate('/dashboard/list-attributegroups?' + MarketplaceAppConfig.getStore(''))
    //         })
    //         .catch((error) => {
    //             console.log('error response from saveMultilingual is', api, error)
    //         })
    // }
    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])
    return (
        <div className=''>
            <HeaderForTitle
                title={
                    <Tooltip
                        title={pageAction !== 'add' ? attribuetGroupDetails && attribuetGroupDetails.groupName : ''}
                        zIndex={11}
                        placement='bottom'>
                        <Title level={3} className='!font-semibold !text-2xl !leading-[30px] !text-regal-blue' ellipsis>
                            {t('attribute_groups:add_attribute_group')}
                        </Title>
                    </Tooltip>
                }
                type={'categories'}
                showArrowIcon={true}
                backNavigationPath={'/dashboard/list-attributegroups'}
                showButtons={false}
            />
            <div className='!mb-0 mt-[6rem] p-3 '>
                <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                    <div className='!p-6 bg-white shadow-brandShadow rounded-md h-[68vh]'>
                        <Row>
                            <Col span={14} className=''>
                                <div className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('attribute_groups:attribute_group_title')}
                                        <span className='mandatory-symbol-color text-sm'>*</span>
                                    </Typography>
                                    <Content className='flex'>
                                        <Content className=''>
                                            <Input
                                                maxLength={titleMaxLength}
                                                placeholder={t('attribute_groups:enter_attribute_title')}
                                                onBlur={(e) => {
                                                    let copyOfAttributeGroupDetails = {
                                                        ...attribuetGroupDetails,
                                                    }
                                                    copyOfAttributeGroupDetails.groupName = e.target.value
                                                        .trim()
                                                        .replace(/\s+/g, ' ')
                                                    setAttributeGroupDetails(copyOfAttributeGroupDetails)
                                                }}
                                                className={`${
                                                    nameValidation
                                                        ? 'border-1 border-red-600 hover:border-red-400'
                                                        : ' border-solid border-[#C6C6C6]'
                                                }`}
                                                value={attribuetGroupDetails && attribuetGroupDetails.groupName}
                                                onChange={(e) => {
                                                    if (e.target.value === '') {
                                                        setOnChangeValues(false)
                                                    } else {
                                                        setOnChangeValues(true)
                                                    }
                                                    let copyOfAttributeGroupDetails = {
                                                        ...attribuetGroupDetails,
                                                    }
                                                    if (
                                                        validator.matches(
                                                            e.target.value.trim(),
                                                            /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};'`~:"\\|,.<>\/?]+$/
                                                        )
                                                    ) {
                                                        copyOfAttributeGroupDetails.groupName = e.target.value
                                                        setAttributeGroupDetails(copyOfAttributeGroupDetails)
                                                        setNameValidation(false)
                                                    } else if (e.target.value === '') {
                                                        copyOfAttributeGroupDetails.groupName = e.target.value
                                                        setAttributeGroupDetails(copyOfAttributeGroupDetails)
                                                        setNameValidation(false)
                                                    }
                                                }}
                                            />
                                        </Content>
                                    </Content>
                                </div>
                                <div className='my-3'>
                                    <Typography className='input-label-color mb-2 flex gap-1'>
                                        {t('common:description')}
                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                    </Typography>
                                    <div className={`flex`}>
                                        <TextArea
                                            placeholder={t('attribute_groups:enter_attribute_description')}
                                            autoSize={true}
                                            rows={1}
                                            maxLength={descriptionMaxLength ? descriptionMaxLength : 500}
                                            showCount
                                            className={`custom-textarea ${
                                                descriptionValidation
                                                    ? 'border-1 border-red-600 hover:border-red-400'
                                                    : ''
                                            } 
                      ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pl-[3px]' : '!pr-[3px]'}`}
                                            value={attribuetGroupDetails && attribuetGroupDetails.groupDescription}
                                            onBlur={(e) => {
                                                setTimeout(() => {
                                                    let copyOfAttributeGroupDetails = {
                                                        ...attribuetGroupDetails,
                                                    }
                                                    copyOfAttributeGroupDetails.groupDescription = e.target.value
                                                        .trim()
                                                        .replace(/\s+/g, ' ')
                                                    setAttributeGroupDetails(copyOfAttributeGroupDetails)
                                                }, 300)
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value === '') {
                                                    setOnChangeValues(false)
                                                } else {
                                                    setOnChangeValues(true)
                                                }
                                                let copyOfAttributeGroupDetails = {
                                                    ...attribuetGroupDetails,
                                                }
                                                // copyOfAttributeGroupDetails.groupName = e.target.value;
                                                copyOfAttributeGroupDetails.groupDescription = e.target.value
                                                setAttributeGroupDetails(copyOfAttributeGroupDetails)
                                                // setDescription(e.target.value);

                                                setDescriptionValidation(false)
                                            }}
                                        />
                                    </div>
                                </div>
                                {
                                    <Content className='my-2'>
                                        <Button
                                            disabled={onChangeValues === true ? false : true}
                                            onClick={() => attributeGroupFormValidation()}
                                            className={`app-btn-primary mx-1 `}>
                                            {t('common:save')}
                                        </Button>
                                        <Button className='mx-1 app-btn-secondary' onClick={() => handleDiscardClick()}>
                                            {t('common:discard')}
                                        </Button>
                                    </Content>
                                }
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>
        </div>
    )
}

export default AddAttributeGroups
