import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import { useMutation } from '@tanstack/react-query'
const segmentAPI = process.env.REACT_APP_SEGMENT_API

const useDeleteAttributeGroup = () => {
    const deleteAttributeGroup = async ({ attributeGroupId }) => {
        const params = {
            _id: attributeGroupId,
        }
        const res = await MarketplaceServices.remove(segmentAPI, params)
        return res
    }
    return useMutation({ mutationFn: deleteAttributeGroup })
}

export default useDeleteAttributeGroup