import { useMutation } from "@tanstack/react-query";
import MarketplaceServices from "../../../services/axios/MarketplaceServices";
const couponAPI = process.env.REACT_APP_COUPONS_API

const useUpdateCoupons=()=>{
    const updateCoupons=async ({couponId,data})=>{
        const params = { coupon_id: couponId };
        const serverResponse=await MarketplaceServices.update(couponAPI,data,params)
        return serverResponse
    }
return useMutation({
    mutationFn:updateCoupons
})

}
export default useUpdateCoupons