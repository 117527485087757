import { ActionTypes } from '../constants/ActionTypes'

const { RDX_ATTRIBUTE_GROUP_DETAILS } = ActionTypes

const initialState = {
    attribuetGroupDetails: [
        {
            groupName: '',
            groupDescription: '',
        },
        {
            groupName: '',
            groupDescription: '',
        },
    ],
}
export const ReducerAttributeGroupDetails = (state = initialState, { type, payload }) => {
    switch (type) {
        case RDX_ATTRIBUTE_GROUP_DETAILS:
            return { ...state, attribuetGroupDetails: payload }
        default:
            return state
    }
}
