import TranslateAttributeStrings from './TranslateAttributeStrings'
const attributeUnitControlDescription = [
    {
        name: 'min',
        description: <TranslateAttributeStrings translate={'attributes:min_allow_val_for_this_attr'} />,
    },
    {
        name: 'max',
        description: <TranslateAttributeStrings translate={'attributes:max_allow_val_for_this_attr'} />,
    },
    {
        name: 'format',
        description: <TranslateAttributeStrings translate={'attributes:format_attr_unit_desc'} />,
    },
    {
        name: 'decimal',
        description: <TranslateAttributeStrings translate={'attributes:decimal_attr_unit_desc'} />,
    },
    {
        name: 'limiter',
        description: <TranslateAttributeStrings translate={'attributes:to_inc_step_of_slider_control'} />,
    },
    {
        name: 'left',
        description: <TranslateAttributeStrings translate={'attributes:show_left_mess_for_slider_contr'} />,
    },
    {
        name: 'right',
        description: <TranslateAttributeStrings translate={'attributes:show_right_mess_for_slider_contr'} />,
    },
    {
        name: 'value_regex',
        description: <TranslateAttributeStrings translate={'attributes:val_reg_attr_unit_desc'} />,
    },
    {
        name: 'value_regex_error_message',
        description: <TranslateAttributeStrings translate={'attributes:val_reg_mess_attr_unit_desc'} />,
    },
    {
        name: 'ext',
        description: <TranslateAttributeStrings translate={'attributes:ext_attr_unit_desc'} />,
    },
]

export default attributeUnitControlDescription
