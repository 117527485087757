import { Empty, Layout, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

/**
 *! Components are imported Here.
 */
import { useTranslation } from 'react-i18next'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'
//! ant design initialization
const { Content } = Layout
const { Title } = Typography

//! Global Variables
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const vendorApi = process.env.REACT_APP_VENDOR_SETTLEMENT_DETAILS_API

function Salesdetails({ type, permission }) {
    // usePageTitle(portalInfo.title + " - Sales Orders");
    const { t } = useTranslation()
    let { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [destructuredLineItems, setDestructuredLineItems] = useState([])
    const [pageNumber, setPageNumber] = useState()
    const [pageLimit, setPageLimit] = useState()
    const [salesData, setSalesData] = useState([])
    const [totalCount, setTotalCount] = useState()

    let selectedVendorSettlementInfoRedux = useSelector(
        (state) => state.reducerSelectedVendorSettlemetInfo.selectedVendorSettlementInfo
    )

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    // console.log("contentSettingFromRedux#", contentSettingFromRedux);
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol
    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    // console.log("currencySymbolFromRedux#", currencySymbolFromRedux);

    //!Table column for the sales order(Sales Details)
    const tableColumnForSalesDetails = [
        {
            title: `${t('transactions:order_id')}`,
            dataIndex: 'order_id',
            key: 'order_id',
            ellipsis: true,
            // width: "10%",
            // ...getColumnSearchProps("id"),
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p
                            // className="!text-[#1677ff] hover:text-[#4096ff] text-[14px] !mb-0"
                            // role={"button"}
                            className='!mb-0'
                            // onClick={() => showDrawer(record)}
                        >
                            {record.order_id}{' '}
                        </p>
                    </Content>
                )
            },
        },
        {
            title: `${t('transactions:order_item_id')}`,
            dataIndex: 'order_item_id',
            key: 'order_item_id',
            ellipsis: true,
            // width: "10%",
            // ...getColumnSearchProps("id"),
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p
                            // className="!text-[#1677ff] hover:text-[#4096ff] text-[14px] !mb-0"
                            // role={"button"}
                            className='!mb-0'
                            // onClick={() => showDrawer(record)}
                        >
                            {record.order_item_id}{' '}
                        </p>
                    </Content>
                )
            },
        },
        {
            title: `${t('transactions:customer_name')}`,
            dataIndex: 'order_customer',
            key: 'order_customer',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return (
                    <Content className='text-[14px]'>
                        <p className='!mb-0'>{record.customerName ? record.customerName : t('common:not_available')}</p>
                    </Content>
                )
            },
        },
        {
            title: `${t('transactions:date')}`,
            dataIndex: 'date_of_order',
            key: 'date_of_order',
            ellipsis: true,
            // width: "20%",
            // showSorterTooltip: false,
            // sorter: (name1, name2) =>
            //   name1.order_date.localeCompare(name2.order_date),
            // sortDirections: ["descend", "ascend"],
            render: (text, record) => {
                return <Content className=''>{getGenerateDateAndTime(record.order_date, 'D MMMM YYYY')}</Content>
            },
        },
        {
            title: `${t('transactions:amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!mb-0'>{record.amount ? record.amount : t('common:not_available')}</p>
                    </Content>
                )
            },
        },
        {
            title: t('transactions:my_earning'),
            dataIndex: 'my_earning',
            key: 'my_earning',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!m-0 !text-green-400'>
                            {record.vendor_earnings ? record.vendor_earnings : t('common:not_available')}
                        </p>
                    </Content>
                )
            },
        },
        {
            title: t('transactions:store_commission'),
            dataIndex: 'store_commission',
            key: 'store_commission',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!m-0 !text-red-400'>
                            {record.store_commission ? record.store_commission : t('common:not_available')}
                        </p>
                    </Content>
                )
            },
        },
    ]
    //!Table column for the overview
    const tableColumnForOverview = [
        {
            title: `${t('transactions:order_id_and_customer_name')}`,
            dataIndex: 'order_id',
            key: 'order_id',
            // width: "20%",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='font-bold'>{record.order_id}</p>
                        <p className='font-semibold'>{record.customerName ? record.customerName : '-'}</p>
                    </Content>
                )
            },
        },
        {
            title: `${t('transactions:amount_and_date')}`,
            dataIndex: 'order_customer',
            key: 'order_customer',
            // width: "10%",
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className='text-[14px]'>
                        <p className='!mb-0 font-bold'>
                            {currencySymbolFromRedux} {record.amount ? record.amount : '-'}
                        </p>
                        <p className='font-semibold'>{getGenerateDateAndTime(record.order_date, 'D MMMM YYYY')}</p>
                    </Content>
                )
            },
        },
    ]

    const handlePageNumberChange = (page, pageSize) => {
        window.scrollTo(0, 250)
        setPageNumber(page)
        setPageLimit(pageSize)
    }
    //!  Get Calls are used in this UseEffect
    useEffect(() => {
        // window.scrollTo(0, 0);
        setIsLoading(true)
    }, [searchParams])
    const destructureLineItems = (data) => {
        const tempArrayOne = []
        data &&
            data.length > 0 &&
            data.forEach((element, index) => {
                var tempOrderId = element.order_id
                var tempOrderItemId = element.order_item_id
                var tempOrderCustomerName = element.order__customer_name
                var tempDate = element.order__date_of_order
                var tempAmount = element.order_item__amount
                var tempVendorEarnings = element.vendor_earnings
                var tempStoreCommission = element.store_commision

                var tempOrderStore = element.order__store
                var tempId = element.id
                var tempVendorName = element.vendor_name

                tempArrayOne &&
                    tempArrayOne.push({
                        key: index,
                        id: tempId,
                        order_id: tempOrderId,
                        order_item_id: tempOrderItemId,
                        customerName: tempOrderCustomerName,
                        order_date: tempDate,
                        amount: tempAmount,
                        vendor_earnings: tempVendorEarnings,
                        store_commission: tempStoreCommission,

                        vendor_name: tempVendorName,
                        order_store: tempOrderStore,
                    })
            })
        return tempArrayOne
    }
    useEffect(() => {
        setIsLoading(false)
        setDestructuredLineItems(destructureLineItems(selectedVendorSettlementInfoRedux.line_items))
    }, [selectedVendorSettlementInfoRedux])
    useEffect(() => {
        if (type !== 'overview') {
            let pageNum = pageNumber === undefined ? 1 : pageNumber
            let pageLim = pageLimit === undefined ? itemsPerPageFromEnv : pageLimit
            findByPageVendorSettlementDetails(pageNum, pageLim)
        }
    }, [pageNumber, pageLimit])

    const findByPageVendorSettlementDetails = (pageNum, pageLimit) => {
        setIsLoading(true)
        MarketplaceServices.findByPage(
            vendorApi,
            {
                vendor_id: parseInt(id),
            },
            pageNum,
            pageLimit,
            null
        )
            .then(function (response) {
                console.log(
                    'Server response from findByPageVendorSettlementDetails is',
                    vendorApi,
                    response.data.response_body.line_items
                )
                setTotalCount(response.data.response_body.count)
                setSalesData(destructureLineItems(response.data.response_body.line_items))
                setIsLoading(false)
                // setIsLoadingVendorList(false);
                setIsNetworkError(false)
            })
            .catch(function (error) {
                MarketplaceToaster.showToast(error.response)
                // console.log(
                //   "Server Error response from product templates",
                //   vendorApi,
                //   error.response
                // );
                setIsNetworkError(true)
                setIsLoading(false)
            })
    }

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])
    return (
        <Content className='!pt-1'>
            {type !== 'overview' ? (
                <Content className='float-left'>
                    <Title level={3} className='!font-semibold'>
                        {/* {t("sidebar:sales_returns")} */}
                        {t('transactions:sales_details')}
                    </Title>
                </Content>
            ) : null}
            <Content className={`${type === 'overview' ? '!m-0' : '!p-3'}`}>
                {isLoading ? (
                    <Content className=' bg-white p-3'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Content className=' bg-white p-3'>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${t('common:network_error')}`} />{' '}
                    </Content>
                ) : (
                    <Content className='mb-3'>
                        <Table
                            pagination={false}
                            columns={type === 'overview' ? tableColumnForOverview : tableColumnForSalesDetails}
                            dataSource={
                                type === 'overview'
                                    ? destructuredLineItems &&
                                      destructuredLineItems.length > 0 &&
                                      destructuredLineItems.slice(0, 5)
                                    : salesData
                            }
                        />
                        {totalCount > itemsPerPageFromEnv && type !== 'overview' ? (
                            <Content className=' grid justify-items-end'>
                                <DmPagination
                                    currentPage={pageNumber === undefined ? 1 : pageNumber}
                                    totalItemsCount={totalCount}
                                    defaultPageSize={itemsPerPageFromEnv}
                                    pageSize={pageLimit === undefined ? itemsPerPageFromEnv : pageLimit}
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showTotal={true}
                                />
                            </Content>
                        ) : null}
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default Salesdetails
