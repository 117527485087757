import React, { useEffect, useState } from 'react'
import { Button, Layout, Typography, Row, Table, Input, Select, InputNumber, Col } from 'antd'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import StoreModal from '../../components/storeModal/StoreModal'
import { v4 as uuidv4 } from 'uuid'
import { validatingModule, validatingFields, validatiors, validatingTargets } from './CreateRulesConstant'
import TextArea from 'antd/es/input/TextArea'
import { toast } from 'react-toastify'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { MdModeEditOutline } from 'react-icons/md'
import MarketplaceToaster from '../../util/marketplaceToaster'
import util from '../../util/common'
const couponRulesAPI = process.env.REACT_APP_COUPONS_RULES_API
const publishedProductVersionsAPI = process.env.REACT_APP_PRODUCT_PUBLISH_VERSION_API
const productTypeAPI = process.env.REACT_APP_PRODUCT_TYPE_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const { Content } = Layout
const { Title, Text } = Typography

function CouponRules() {
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [couponRulesErrorMessage, setCouponRulesErrorMessage] = useState('')
    const [couponsRulesCount, setCouponsRulesCount] = useState(0)
    const [couponsRulesData, setCouponsRulesData] = useState([])
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
    const [publishedProductsAPIData, setPublishedProductAPIData] = useState([])
    const [storeProductTypes, setStoreProductTypes] = useState([])
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false)
    const [deleteRuleID, setDeleteRuleID] = useState()
    const [validatingControl, setValidatingControl] = useState('Amount')

    // const [selectedValidatingModule, setSelectedValidatingModule] = useState("Order");
    const defaultCreateRuleObject = {
        key: uuidv4(),
        id: uuidv4(),
        name: '',
        rulesValidatingModule: 'Order',
        rulesValidatingfield: '',
        rulesValidator: '',
        rulesValidatingTarget: '',
        rulesErrorMsg: '',
        rulesDescription: '',
    }
    const [couponRulesInfo, setCouponRulesInfo] = useState(defaultCreateRuleObject)

    const couponRulesHandler = (value, action) => {
        let copyOfCouponRulesInfo = { ...couponRulesInfo }
        switch (action) {
            case 'rules_name':
                copyOfCouponRulesInfo.name = value
                break
            case 'validating_module':
                copyOfCouponRulesInfo.rulesValidatingModule = value
                copyOfCouponRulesInfo.rulesValidatingfield = ''
                copyOfCouponRulesInfo.rulesValidatingTarget = ''
                break
            case 'validating_field':
                copyOfCouponRulesInfo.rulesValidatingfield = value
                copyOfCouponRulesInfo.rulesValidatingTarget = ''
                break
            case 'validator':
                copyOfCouponRulesInfo.rulesValidator = value
                break
            case 'validating_targets':
                copyOfCouponRulesInfo.rulesValidatingTarget = value
                break
            case 'rules_error':
                copyOfCouponRulesInfo.rulesErrorMsg = value
                break
            case 'description':
                copyOfCouponRulesInfo.rulesDescription = value
                break
            default:
                return
        }
        setCouponRulesInfo(copyOfCouponRulesInfo)
    }

    const destructureCouponRulesData = (record) => {
        var arrayForFull = []
        record &&
            record.length > 0 &&
            record.map((element) => {
                arrayForFull.push({
                    key: element.id,
                    id: element.id,
                    name: element.rule_name,
                    rulesValidatingModule: element.validating_module,
                    rulesValidatingfield: element.validating_field,
                    rulesValidator: element.validator,
                    rulesValidatingTarget: element.validating_target,
                    rulesErrorMsg: element.rule_error,
                    rulesDescription: element.description,
                })
            })
        return arrayForFull
    }

    const getProductTypes = () => {
        MarketplaceServices.findAllWithoutPage(productTypeAPI, null, true)
            .then(function (response) {
                //TODO: Remove below code once we get status as boolean
                const serverData = response.data.StoreProductTypeRevision.map(
                    ({ store_product_type }) => store_product_type
                )
                // console.log("serverData:", serverData);
                setStoreProductTypes(serverData)
            })
            .catch(function (error) {
                console.log('error', error)
            })
    }

    const getProductPublishingDetails = () => {
        MarketplaceServices.findAll(
            publishedProductVersionsAPI,
            {
                'product-status': 3,
                'page-number': -1,
            },
            true
        )
            .then(function (response) {
                console.log(
                    'server response from active product template ## :',
                    response.data[0].produt_publish_version
                )
                // setTotalItemsCount(50);
                setPublishedProductAPIData(response.data[0].produt_publish_version)
            })
            .catch(function (error) {
                console.log('Server Error response from Published product templates:', error.response)
            })
    }

    const getValidatingTargetControl = (controlType) => {
        switch (controlType) {
            case 'Store Product Type':
                return (
                    <Select
                        className='w-60'
                        mode='multiple'
                        allowClear
                        value={couponRulesInfo.rulesValidatingTarget == '' ? [] : couponRulesInfo.rulesValidatingTarget} // Set the value to the first element
                        placeholder={'Select validating targets'}
                        onChange={(value) => couponRulesHandler(value, 'validating_targets')}>
                        {storeProductTypes.map(({ id, name }) => (
                            <Select.Option key={`${name}_${id}`} value={id}>
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                )
            case 'Product':
                return (
                    <Select
                        className='w-60'
                        mode='multiple'
                        allowClear
                        value={couponRulesInfo.rulesValidatingTarget == '' ? [] : couponRulesInfo.rulesValidatingTarget} // Set the value to the first element
                        placeholder={'Select validating targets'}
                        onChange={(value) => couponRulesHandler(value, 'validating_targets')}>
                        {publishedProductsAPIData.map(({ id, product_name }) => (
                            <Select.Option key={`${product_name}_${id}`} value={id}>
                                {product_name}
                            </Select.Option>
                        ))}
                    </Select>
                )
            case 'Quantity':
                return (
                    <InputNumber
                        className='w-60'
                        value={couponRulesInfo.rulesValidatingTarget}
                        onChange={(value) => couponRulesHandler(value, 'validating_targets')}
                    />
                )
            case 'Amount':
                return (
                    <InputNumber
                        className='w-60'
                        value={couponRulesInfo.rulesValidatingTarget}
                        onChange={(value) => couponRulesHandler(value, 'validating_targets')}
                    />
                )
            default:
                return (
                    <InputNumber
                        className='w-60'
                        value={couponRulesInfo.rulesValidatingTarget}
                        onChange={(value) => couponRulesHandler(value, 'validating_targets')}
                    />
                )
        }
    }

    const deleteRule = () => {
        MarketplaceServices.remove(couponRulesAPI + '/delete', {
            'rule-id': deleteRuleID,
        })
            .then(() => {
                // deleting the attribute non-editable value
                let copyOfCouponsRulesData = [...couponsRulesData]
                let removedCouponRules = copyOfCouponsRulesData.filter(({ id }) => id !== deleteRuleID)
                setCouponsRulesData(removedCouponRules)
                // copyOfCouponsRulesData.filter(({id})=> id !== deleteRuleID).length > 0
                // if (copyOfCouponsRulesData.filter(({ id }) => id === response.data.id).length <= 0) {

                // }

                MarketplaceToaster.showToast(util.getToastObject('Coupon deleted successfully', 'success'))
                setIsOpenWarningModal(false)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${
                            error.response.status + ' : ' + error.response.statusText + ',' + 'Failed in rule deletion'
                        }`,
                        'error'
                    )
                )
            })
    }

    const createRule = () => {
        let postObject = {
            rule_name: couponRulesInfo.name,
            validating_module: couponRulesInfo.rulesValidatingModule,
            validating_field: couponRulesInfo.rulesValidatingfield,
            validator: couponRulesInfo.rulesValidator,
            validating_target: couponRulesInfo.rulesValidatingTarget,
            rule_error: couponRulesInfo.rulesErrorMsg,
            description: couponRulesInfo.rulesDescription,
        }
        MarketplaceServices.save(couponRulesAPI, postObject, null)
            .then((response) => {
                let copyOfCouponsRulesData = [...couponsRulesData]
                // copyOfCouponsRulesData.filter(({id})=> id === response.data.id).length > 0
                // if (copyOfCouponsRulesData.filter(({ id }) => id === response.data.id).length <= 0) {

                // }
                let obj = {
                    key: response.data.id,
                    id: response.data.id,
                    name: response.data.rule_name,
                    rulesValidatingModule: response.data.validating_module,
                    rulesValidatingfield: response.data.validating_field,
                    rulesValidator: response.data.validator,
                    rulesValidatingTarget: response.data.validating_target,
                    rulesErrorMsg: response.data.rule_error,
                    rulesDescription: response.data.description,
                }
                copyOfCouponsRulesData.push(obj)
                setCouponsRulesData(copyOfCouponsRulesData)
                setIsCreateModalVisible(false)
                console.log('response....', response.data.id)

                MarketplaceToaster.showToast(util.getToastObject('Coupon rule added successfully', 'success'))
            })
            .catch((error) => {
                MarketplaceToaster.showToast(
                    util.getToastObject(
                        `${error.response.status + ' : ' + error.response.statusText + ',' + 'Failed to add rule'}`,
                        'error'
                    )
                )
            })
    }

    const findByPageCouponsRulesGetCall = () => {
        MarketplaceServices.findAllWithoutPage(couponRulesAPI, null, false)
            .then(function (res) {
                console.log('res couponss...', res)
                console.log('res.daata', res.data)
                setCouponsRulesCount(res.data.count)
                setCouponsRulesData(destructureCouponRulesData(res.data.data))
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch((error) => {
                // setMsg(error.response.data.message);
                // setStatus(error.response.status);
                console.log('Server Error Response from Approvals GET API Call', error.response)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    useEffect(() => {
        findByPageCouponsRulesGetCall()
        getProductPublishingDetails()
        getProductTypes()
    }, [])

    console.log('couponRulesInfo', couponRulesInfo, validatingControl)

    const tableHeader = [
        {
            title: <Content className='whitespace-nowrap'>{'Coupon Rules Name'}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className='!max-w-[90%] !relative'>
                        <Content className='!absolute !max-w-[100%]'>{record.name}</Content>
                    </Content>
                )
            },
        },
        // {
        //   title: <Content className="whitespace-nowrap">{"Coupon Validating Module"}</Content>,
        //   dataIndex: "id",
        //   key: "id",
        //   width: "35%",
        //   render: (text, record) => {
        //     return (
        //       <Content className="!max-w-[90%] !relative">
        //         <Content className="!absolute !max-w-[100%]">
        //           {record.rulesValidatingModule}
        //         </Content>
        //       </Content>
        //     );
        //   },
        // },
        {
            title: <Content className='whitespace-nowrap'>{'Coupon Validator'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className='!max-w-[90%] !relative'>
                        <Content className='!absolute !max-w-[100%]'>{record.rulesValidator}</Content>
                    </Content>
                )
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{'Coupon Validating Target'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='!max-w-[90%] !relative'>
                        <Content className='!absolute !max-w-[100%]'>{record.rulesValidatingTarget}</Content>
                    </Content>
                )
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{'Coupon Error message'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='!max-w-[90%] !relative'>
                        {/* <Content className="!absolute !max-w-[100%]"> */}
                        {record.rulesErrorMsg}
                        {/* </Content> */}
                    </Content>
                )
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{'Rules Description'}</Content>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='!max-w-[90%] !relative'>
                        {/* <Content className="!absolute !max-w-[100%]"> */}
                        {record.rulesDescription}
                        {/* </Content> */}
                    </Content>
                )
            },
        },
        {
            title: `Action`,
            dataIndex: '',
            key: '',
            render: (text, record) => {
                return (
                    <Row className=''>
                        <Col span={8}>
                            <MdModeEditOutline className='mt-[6px]' />
                        </Col>
                        <Col span={8}>
                            <RiDeleteBin6Line
                                className='app-delete-icon mt-[6px] cursor-pointer'
                                onClick={() => {
                                    console.log('first record', record)
                                    setIsOpenWarningModal(true)
                                    setDeleteRuleID(record.id)
                                }}
                            />
                        </Col>
                    </Row>
                )
            },
        },
    ]

    return (
        <Content className='p-1 !min-h-screen'>
            {isLoading ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2 !bg-white'>
                    <SkeletonComponent />
                </Content>
            ) : isNetworkError ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                    <h5>{couponRulesErrorMessage ? couponRulesErrorMessage : `NetWork error has happend`}</h5>
                </Content>
            ) : (
                <Content>
                    <Content className='!bg-white p-2'>
                        <Content>
                            <Row>
                                <Content>Coupon Rules</Content>
                                <Content className='!flex !justify-items-end justify-end !text-right'>
                                    <Button className='mr-1' onClick={() => setIsCreateModalVisible(true)}>
                                        Create Rule
                                    </Button>
                                </Content>
                            </Row>
                        </Content>
                        <Content className='pt-3'>
                            {couponsRulesCount === 0 && couponsRulesData && couponsRulesData.length === 0 ? (
                                <Content className='p-0 text-center mt-2 ml-2'>
                                    <Text> {'No coupon rules are created, please create a rules'}</Text>
                                </Content>
                            ) : (
                                <Content>
                                    <Content className='!max-w-[100%]'>
                                        {/* <DynamicTable tableComponentData={tablePropsData} /> */}
                                        <Table dataSource={couponsRulesData} columns={tableHeader} pagination={false} />
                                    </Content>
                                    {/* TODO:will add based on the requirement */}
                                    {/* {couponsCount > itemsPerPageFromEnv ? (
                <Content className="!grid !justify-items-end">
                  <DmPagination
                    currentPage={
                      parseInt(searchParams.get("page"))
                        ? parseInt(searchParams.get("page"))
                        : 1
                    }
                    totalItemsCount={approvalsTotalCount}
                    defaultPageSize={itemsPerPageFromEnv}
                    pageSize={
                      parseInt(searchParams.get("limit"))
                        ? parseInt(searchParams.get("limit"))
                        : itemsPerPageFromEnv
                    }
                    handlePageNumberChange={handlePageNumberChange}
                    showSizeChanger={true}
                    showTotal={true}
                  />
                </Content>
              ) : null} */}
                                </Content>
                            )}
                        </Content>
                    </Content>
                </Content>
            )}
            {
                <StoreModal
                    isVisible={isOpenWarningModal}
                    title={'Are you sure, you want to delete ?'}
                    okCallback={() => deleteRule()}
                    okButtonText={'Delete'}
                    cancelButtonText={'Cancel'}
                    cancelCallback={() => {
                        setIsOpenWarningModal(false)
                    }}
                    isSpin={false}></StoreModal>
            }
            {
                <StoreModal
                    isVisible={isCreateModalVisible}
                    title={'Create Rule'}
                    okCallback={() => createRule()}
                    okButtonText={'Create'}
                    cancelButtonText={'Cancel'}
                    cancelCallback={() => {
                        setIsCreateModalVisible(false)
                        setCouponRulesInfo(defaultCreateRuleObject)
                    }}
                    isSpin={false}>
                    <Content>
                        <Content>
                            <Typography>{'Rules Name'}</Typography>
                            <Input
                                placeholder={'Enter rules name here'}
                                className='w-60'
                                value={couponRulesInfo.name}
                                onChange={(e) => couponRulesHandler(e.target.value, 'rules_name')}></Input>
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Validating Module'}</Typography>
                            <Select
                                className='w-60'
                                value={couponRulesInfo.rulesValidatingModule} // Set the value to the first element
                                placeholder={'Select Validating Module'}
                                onChange={(value) => couponRulesHandler(value, 'validating_module')}>
                                {validatingModule.map(({ id, labelName, value }) => (
                                    <Select.Option key={`${labelName}_${id}`} value={value}>
                                        {labelName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Validating Field'}</Typography>
                            <Select
                                className='w-60'
                                // value={couponRulesInfo.rulesValidatingfield || validatingFields.map((item) => {
                                //   return item[couponRulesInfo.rulesValidatingModule] && item[couponRulesInfo.rulesValidatingModule][0].value
                                // })}
                                value={couponRulesInfo.rulesValidatingfield}
                                // Set the value to the first element
                                placeholder={'Select Validating Field'}
                                onChange={(value) => {
                                    couponRulesHandler(value, 'validating_field')
                                    setValidatingControl(value)
                                }}>
                                {validatingFields.map((item) => {
                                    {
                                        return (
                                            item[couponRulesInfo.rulesValidatingModule] &&
                                            item[couponRulesInfo.rulesValidatingModule].map(
                                                ({ id, labelName, value }) => (
                                                    <Select.Option key={`${labelName}_${id}`} value={value}>
                                                        {labelName}
                                                    </Select.Option>
                                                )
                                            )
                                        )
                                    }
                                })}
                            </Select>
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Validator'}</Typography>
                            <Select
                                className='w-60'
                                value={couponRulesInfo.rulesValidator} // Set the value to the first element
                                placeholder={'Select Validator'}
                                onChange={(value) => couponRulesHandler(value, 'validator')}>
                                {validatiors.map(({ id, labelName, value }) => (
                                    <Select.Option key={`${labelName}_${id}`} value={value}>
                                        {labelName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Validating Targets'}</Typography>
                            {getValidatingTargetControl(validatingControl)}
                            {/* <Select
                className="w-60"
                value={couponRulesInfo.rulesValidatingTarget} // Set the value to the first element
                placeholder={"Select validating targets"}
                onChange={(value) => couponRulesHandler(value, "validating_targets")}
              >
                {validatingTargets.map(({ id, labelName, value }) => (
                  <Select.Option key={`${labelName}_${id}`} value={value}>
                    {labelName}
                  </Select.Option>
                ))}
              </Select> */}
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Rules Error Message'}</Typography>
                            <Input
                                className='w-60'
                                placeholder={'Enter rules error message here'}
                                value={couponRulesInfo.rulesErrorMsg}
                                onChange={(e) => couponRulesHandler(e.target.value, 'rules_error')}></Input>
                        </Content>
                        <Content className='my-2'>
                            <Typography>{'Description'}</Typography>
                            <TextArea
                                className='w-60'
                                rows={2}
                                maxLength={555}
                                placeholder={'Enter rules description here'}
                                value={couponRulesInfo.rulesDescription}
                                onChange={(e) => couponRulesHandler(e.target.value, 'description')}
                            />
                            {/* <Input
                className="w-60"
                placeholder={"Enter rules description here"}
                value={couponRulesInfo.rulesDescription}
                onChange={(e) => couponRulesHandler(e.target.value, "description")}
              ></Input> */}
                        </Content>
                    </Content>
                </StoreModal>
            }
        </Content>
    )
}

export default CouponRules
