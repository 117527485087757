import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_CATEGORY_SELECTED_INFO,
    RDX_CATEGORY_BY_STATUS,
    RDX_CATEGORY_BY_STATUS_RAW_DATA,
    RDX_REMOVE_CATEGORY_SELECTED_INFO,
    RDX_CATEGORY_SELECTED_SEGMENT_INFO,
    RDX_PARENT_CATEGORY_SELECTED_INFO,
} = ActionTypes

export const fnCategoryByStatus = (data) => {
    return {
        type: RDX_CATEGORY_BY_STATUS,
        payload: data,
    }
}

export const fnCategoryByStatusRawData = (data) => {
    return {
        type: RDX_CATEGORY_BY_STATUS_RAW_DATA,
        payload: data,
    }
}

export const fnCategorySelectedInfo = (data) => {
    return {
        type: RDX_CATEGORY_SELECTED_INFO,
        payload: data,
    }
}

export const fnRemoveCategorySelectedInfo = () => {
    return {
        type: RDX_REMOVE_CATEGORY_SELECTED_INFO,
    }
}

export const fnCategorySelectedSegmentInfo = (data) => {
    return {
        type: RDX_CATEGORY_SELECTED_SEGMENT_INFO,
        payload: data,
    }
}

export const fnParentCategorySelectedInfo = (data) => {
    return {
        type: RDX_PARENT_CATEGORY_SELECTED_INFO,
        payload: data,
    }
}
