import { ActionTypes } from '../constants/ActionTypes'

const { RDX_CONTENT_SETTING_INFO, RDX_STORE_LOGO_INFO, RDX_STORE_LOGO_LOADING } = ActionTypes

export const fnContentSettingInfo = (data) => {
    return {
        type: RDX_CONTENT_SETTING_INFO,
        payload: data,
    }
}

export const fnStoreLogoInfo = (data) => {
    return {
        type: RDX_STORE_LOGO_INFO,
        payload: data,
    }
}

export const fnStoreLogoLoading = (data) => {
    return {
        type: RDX_STORE_LOGO_LOADING,
        payload: data,
    }
}
