import { CheckCircleFilled } from '@ant-design/icons'
import { Button, Checkbox, Layout, Space, Switch, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import StoreModal from '../../components/storeModal/StoreModal'
import { crossIcon } from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

import { fnSelectedLanguage, fnStoreLanguage } from '../../services/redux/actions/ActionStoreLanguage'
const { Content } = Layout
const { Title } = Typography
const languageAPI = process.env.REACT_APP_STORE_LANGUAGE_API
const languageEditStatusAPI = process.env.REACT_APP_STORE_LANGUAGE_STATUS_API

function LanguageHeaderAction({ languageId, languageCode, languageStatus, languageDefault }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [isDeleteLanguageModalOpen, setIsDeleteLanguageModalOpen] = useState(false)
    const [isLanguageDeleting, setIsLanguageDeleting] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [switchStatus, setSwitchStatus] = useState(parseInt(languageStatus) === 2 ? false : true)
    const [changeSwitchStatus, setChangeSwitchStatus] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isMakeAsDefault, setIsMakeAsDefault] = useState(parseInt(languageDefault) === 1 ? true : false)
    const [defaultChecked, setDefaultChecked] = useState(false)
    const [warningLanguageDefaultModal, setWarningLanguageDefaultModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const storeLanguages = useSelector(
        (state) => state.reducerStoreLanguage && state.reducerStoreLanguage.storeLanguage
    )
    const selectedLanguage = useSelector((state) => state.reducerSelectedLanguage.selectedLanguage)
    useEffect(() => {
        setSwitchStatus(parseInt(languageStatus) === 2 ? false : true)
    }, [languageStatus])

    useEffect(() => {
        setIsMakeAsDefault(parseInt(languageDefault) === 1 ? true : false)
    }, [languageDefault])

    // closing the delete warning model pop up
    const closeModal = () => {
        setIsModalOpen(false)
    }

    // opening the delete warning model pop up
    const openModal = (e) => {
        setIsModalOpen(true)
    }

    // opening the default lang warning model pop up
    const openLanguageDefaultWaringModal = (e) => {
        setWarningLanguageDefaultModal(true)
        setDefaultChecked(e)
    }
    // closing the default lang warning model pop up
    const closeLanguageDefaultWaringModal = () => {
        setWarningLanguageDefaultModal(false)
    }

    const updateLanguageStatus = async () => {
        const reqBody = {
            status: changeSwitchStatus === true ? 1 : 2,
        }
        setIsLoading(true)
        MarketplaceServices.update(languageEditStatusAPI, reqBody, {
            language_id: languageId,
        })
            .then((response) => {
                setSwitchStatus(changeSwitchStatus)
                closeModal()
                setIsLoading(false)
                let availableLanguages = storeLanguages

                if (response.data && response.data.response_body) {
                    if (parseInt(response.data && response.data.response_body[0].status) === 1) {
                        availableLanguages.push(response.data.response_body[0])
                        dispatch(fnStoreLanguage(availableLanguages))
                    } else if (parseInt(response.data && response.data.response_body[0].status) === 2) {
                        dispatch(
                            fnStoreLanguage(
                                availableLanguages &&
                                    availableLanguages.length > 0 &&
                                    availableLanguages.filter(
                                        (ele) =>
                                            parseInt(ele.id) !==
                                            parseInt(response.data && response.data.response_body[0].id)
                                    )
                            )
                        )

                        if (
                            selectedLanguage &&
                            selectedLanguage.language_code === response.data.response_body[0].language_code
                        ) {
                            dispatch(
                                fnSelectedLanguage(
                                    availableLanguages &&
                                        availableLanguages.length > 0 &&
                                        availableLanguages.filter((ele) => ele.is_default)[0]
                                )
                            )

                            util.setUserSelectedLngCode(
                                availableLanguages &&
                                    availableLanguages.length > 0 &&
                                    availableLanguages.filter((ele) => ele.is_default)[0].language_code
                            )
                            setTimeout(function () {
                                navigate(0)
                            }, 2000)
                        }
                    }
                }

                setSearchParams({
                    k: searchParams.get('k'),
                    n: searchParams.get('n'),
                    c: searchParams.get('c'),
                    s: changeSwitchStatus === true ? 1 : 2,
                    d: searchParams.get('d'),
                    store: searchParams.get('store'),
                })
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                setIsLoading(false)
                closeModal()
                MarketplaceToaster.showToast(error.response)
            })
    }

    const makeAsDefaultLanguage = () => {
        setIsLoading(true)
        const reqBody = {
            is_default: defaultChecked,
        }
        MarketplaceServices.update(languageEditStatusAPI, reqBody, {
            language_id: languageId,
        })
            .then((response) => {
                MarketplaceToaster.showToast(response)
                setIsMakeAsDefault(defaultChecked)
                closeLanguageDefaultWaringModal()
                setIsLoading(false)
                setSearchParams({
                    k: searchParams.get('k'),
                    n: searchParams.get('n'),
                    c: searchParams.get('c'),
                    s: searchParams.get('s'),
                    d: defaultChecked ? 1 : 0,
                    store: searchParams.get('store'),
                })
                setTimeout(() => {
                    navigate(0)
                }, 2000)
            })
            .catch((error) => {
                setIsLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const onChange = (checked) => {
        setChangeSwitchStatus(checked)
        setIsModalOpen(true)
    }

    // closing the delete popup model
    const closeDeleteModal = () => {
        setIsDeleteLanguageModalOpen(false)
    }

    // opening the delete popup model
    const openDeleteModal = (id) => {
        setIsDeleteLanguageModalOpen(true)
    }

    //!delete function of language
    const removeLanguage = () => {
        let availableLanguages = storeLanguages
        setIsLanguageDeleting(true)
        MarketplaceServices.remove(languageAPI, { _id: languageId })
            .then((response) => {
                console.log('response from delete===>', response.data, languageId)
                if (response.status === 200 || response.status === 201) {
                    setIsDeleteLanguageModalOpen(false)
                }
                setShowSuccessModal(true)
                // disabling spinner
                setIsLanguageDeleting(false)
                // MarketplaceToaster.showToast(response)
                dispatch(
                    fnStoreLanguage(
                        availableLanguages &&
                            availableLanguages.length > 0 &&
                            availableLanguages.filter((ele) => parseInt(ele.id) !== parseInt(languageId))
                    )
                )
            })
            .catch((error) => {
                // disabling spinner
                setIsLanguageDeleting(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    return (
        <Content className='!flex !gap-3 items-center  '>
            <Content className='flex mr-2 '>
                <Content>
                    <StoreModal
                        isVisible={isModalOpen}
                        okButtonText={t('common:proceed')}
                        title={
                            changeSwitchStatus
                                ? t('languages:language_activation_confirmation')
                                : t('languages:language_deactivation_confirmation')
                        }
                        cancelButtonText={t('common:cancel')}
                        okCallback={() => updateLanguageStatus()}
                        cancelCallback={() => closeModal()}
                        isSpin={isLoading}
                        hideCloseButton={false}>
                        {changeSwitchStatus ? (
                            <div>
                                <p className='!m-0 !p-0'>{t('languages:language_activation_confirmation_message')}</p>
                                {/* <p className='!m-0 !p-0'>{t('languages:are_you_sure_you_like_to_proceed')}</p> */}
                            </div>
                        ) : (
                            <div>
                                <p>{t('languages:language_deactivation_confirmation_message')}</p>
                            </div>
                        )}
                    </StoreModal>
                    <Content className='flex gap-1 justify-end '>
                        <Typography className=''> {t('languages:status_label')} </Typography>
                        <Space direction='vertical'>
                            <Switch
                                className={switchStatus === true ? '!bg-orange-500' : '!bg-gray-400'}
                                disabled={isMakeAsDefault}
                                checked={switchStatus}
                                onChange={onChange}
                                onClick={() => {
                                    openModal(switchStatus)
                                }}
                                checkedChildren={t('dashboard:active')}
                                unCheckedChildren={t('dashboard:inactive')}
                            />
                        </Space>
                    </Content>
                </Content>
            </Content>
            <Content className='contents'>
                <Checkbox
                    className='orange-checkbox'
                    checked={isMakeAsDefault}
                    onChange={(e) => {
                        openLanguageDefaultWaringModal(e.target.checked)
                    }}
                    disabled={switchStatus && !isMakeAsDefault ? false : true}></Checkbox>
                <Typography> {t('languages:default_language_label')}</Typography>
                <StoreModal
                    isVisible={warningLanguageDefaultModal}
                    okButtonText={t('common:proceed')}
                    cancelButtonText={t('common:cancel')}
                    title={t('languages:default_language')}
                    width='450px'
                    okCallback={() => makeAsDefaultLanguage()}
                    cancelCallback={() => {
                        closeLanguageDefaultWaringModal()
                        setIsMakeAsDefault(false)
                    }}
                    isSpin={isLoading}
                    hideCloseButton={false}>
                    {
                        <div>
                            <p>{t('messages:default_language_confirmation')}</p>
                            {/* <p>{t('languages:default_language_warning')}</p> */}
                        </div>
                    }
                </StoreModal>
            </Content>
            {!isMakeAsDefault ? (
                <Button
                    className='app-btn-danger gap-2 flex justify-items-center items-center'
                    onClick={() => {
                        openDeleteModal(languageId)
                    }}>
                    {/* <img src={crossIcon} alt='plusIconWithAddLanguage' className='' /> */}
                    <svg width='10' height='14' viewBox='0 0 10 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fill-rule='evenodd'
                            clip-rule='evenodd'
                            d='M7.01172 1.92422H8.69609C9.33047 1.92422 9.85547 2.44922 9.85547 3.06172V3.71797C9.85547 4.17734 9.57109 4.59297 9.15547 4.76797L8.78359 12.0305C8.73984 12.9273 7.99609 13.6273 7.09922 13.6273H2.85547C1.95859 13.6273 1.21484 12.9273 1.17109 12.0305L0.821094 4.78984C0.405469 4.61484 0.121094 4.22109 0.121094 3.73984V3.08359C0.121094 2.44922 0.646094 1.92422 1.28047 1.92422H2.96484V1.55234C2.96484 0.896094 3.48984 0.371094 4.14609 0.371094H5.83047C6.48672 0.371094 7.01172 0.896094 7.01172 1.55234V1.92422ZM4.16797 1.35547C4.05859 1.35547 3.97109 1.44297 3.97109 1.55234H3.99297V1.92422H6.04922V1.55234C6.04922 1.44297 5.96172 1.35547 5.85234 1.35547H4.16797ZM1.30234 2.90859C1.19297 2.90859 1.12734 2.99609 1.12734 3.08359V3.73984C1.12734 3.84922 1.21484 3.91484 1.30234 3.91484H8.69609C8.80547 3.91484 8.87109 3.82734 8.87109 3.73984V3.08359C8.87109 2.97422 8.78359 2.90859 8.69609 2.90859H1.30234ZM2.17734 11.9648C2.19922 12.3586 2.50547 12.643 2.87734 12.643H7.12109C7.49297 12.643 7.79922 12.3367 7.84297 11.9648L8.17109 4.87734H1.84922L2.17734 11.9648ZM4.49609 7.58984C4.49609 7.30547 4.73672 7.08672 4.99922 7.08672C5.26172 7.08672 5.50234 7.30547 5.50234 7.58984V10.368C5.50234 10.6523 5.26172 10.8711 4.99922 10.8711C4.71484 10.8711 4.49609 10.6305 4.49609 10.368V7.58984ZM6.74922 7.52422C6.46484 7.50234 6.24609 7.69922 6.22422 7.98359L6.09297 9.90859C6.07109 10.1711 6.26797 10.4117 6.55234 10.4336H6.59609C6.85859 10.4336 7.07734 10.2367 7.07734 9.97422L7.20859 8.04922C7.20859 7.76484 7.01172 7.54609 6.74922 7.52422ZM2.76797 8.04922C2.74609 7.78672 2.96484 7.54609 3.22734 7.52422C3.51172 7.50234 3.75234 7.69922 3.75234 7.98359L3.90547 9.90859C3.92734 10.1711 3.70859 10.4117 3.44609 10.4336H3.40234C3.16172 10.4336 2.94297 10.2367 2.92109 9.97422L2.76797 8.04922Z'
                            fill='white'
                        />
                    </svg>

                    {t('messages:delete_language')}
                </Button>
            ) : null}
            <StoreModal
                isVisible={isDeleteLanguageModalOpen}
                okButtonText={t('common:delete')}
                cancelButtonText={t('common:cancel')}
                title={t('messages:delete_language')}
                okCallback={() => removeLanguage()}
                cancelCallback={() => closeDeleteModal()}
                width='450px'
                isSpin={isLanguageDeleting}
                hideCloseButton={false}>
                {
                    <div>
                        <p>{t('languages:remove_language_confirmation')}</p>
                        {/* <p>{t('languages:remove_language_confirmation_message')}</p> */}
                    </div>
                }
            </StoreModal>
            <StoreModal
                isVisible={showSuccessModal}
                okButtonText={null}
                hideCloseButton={false}
                cancelButtonText={null}
                isSpin={false}>
                <Content className='flex flex-col justify-center items-center'>
                    <CheckCircleFilled className=' text-[#52c41a] text-[30px]' />
                    <Title level={4} className='!mt-5 !mb-0'>
                        {t('languages:language_deleted_successfully')}
                    </Title>
                    <Content className='mt-3'>
                        <Button
                            className='app-btn-primary'
                            onClick={() => navigate(`/dashboard/languages?${MarketplaceAppConfig.getStore('')}`)}>
                            {t('common:close')}
                        </Button>
                    </Content>
                </Content>
            </StoreModal>
        </Content>
    )
}

export default LanguageHeaderAction
