import { PlusOutlined } from '@ant-design/icons'
import { Button, Skeleton, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import attribDataTypeResponse from '../../constants/AttributeDataTypeStaticResponse'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnAttributeDetailsUpdated } from '../../services/redux/actions/ActionsAPICall'
import { fnNewlyCreatedStaticAttributeData } from '../../services/redux/actions/ActionsAttributes'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'
import ProductAddOptionValues from './ProductAddOptionValues'
import ProductChooseOptions from './ProductChooseOptions'
import { getDefaultValueControlType } from './ProductOptionDefaultValueUtil'
import './productoption.css'
import StoreModal from '../../components/storeModal/StoreModal'
import {
    useGetProductAttributes,
    useGetProductOptionAttributes,
    useGetStaticAttributeValues,
} from './attributeHooks/allAttributeHooks'
import { createJourneyIcon } from '../../constants/media'
//! Destructure the components
const attributesAPI = process.env.REACT_APP_ATTRIBUTES_API
const attributeOptionAPI = process.env.REACT_APP_ATTRIBUTE_OPTION_API
const attributeDisplayNameAPI = process.env.REACT_APP_ATTRIBUTE_DISPLAYNAME_API
const attributeOptionDisplayNameAPI = process.env.REACT_APP_ATTRIBUTE_OPTION_DISPLAYNAME_API
const productVariantAttributesAPI = process.env.REACT_APP_PRODUCT_VARIANTS_ATTRIBUTES_API
const atttriuteNonEditableValuesAPI = process.env.REACT_APP_NON_EDITABLE_ATTRIBUTE_VALUES_API
const getAllAttributeGroupAPI = process.env.REACT_APP_SEGMENT_API
const decimalMinValue = process.env.REACT_APP_DECIMAL_UNIT_MIN_LIMIT

function ProductAddOptions({
    segmentId,
    isEditable,
    isToggleVisible,
    revisionId,
    lngsCode,
    defaultLngsCode,
    type,
    Choices,
    storeId,
    setAttributePosted,
    productTemplateStatus,
    productTemplateRevisionStatus,
    attributeId,
    setIsAttributeModalVisible,
    isCore,
    page,
}) {
    const { t } = useTranslation()
    const [isAddOptionsButtonEnabled, setIsAddOptionsButtonEnabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isAttributeLoading, setIsAttributeLoading] = useState(true)
    const [isAddOptionValuesFormEnabled, setIsAddOptionValuesFormEnabled] = useState(false)
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedDataType, setSelectedDataType] = useState('')
    const [selectedRegEx, setSelectedRegEx] = useState('')
    const [dataTypeGroup, setDataTypeGroup] = useState('')
    const [attributeUnit, setAttributeUnit] = useState()
    const [minimumNoOfFields, setMinimumNoOfFields] = useState('')
    const [maximumNoOfFields, setMaximumNoOfFields] = useState('')
    const [attributeTypes, setAttributeTypes] = useState('')
    const [regExExceptionMessage, setRegExExceptionMessage] = useState('')
    const [isEditting, setIsEditting] = useState()
    const [attributeDataType, setAttributeDataType] = useState(attribDataTypeResponse)
    const [productAttributes, setProductAttributes] = useState([])
    const [isAttributionCreationModalVisible, setIsAttributionCreationModalVisible] = useState(false)
    const [editedOptionValueName, setEditedOptionValueName] = useState()
    const [productAttribOptionValues, setProductAttribOptionValues] = useState([])
    const [productAttribOptionValueResponse, setProductAttribOptionValueResponse] = useState()
    const [attributeNonEditableValues, setAttributeNonEditableValues] = useState([])
    const [selectedAttributeType, setSelectedAttributeType] = useState('Simple')
    const [attributeExtension, setAttributeExtension] = useState()
    const [attributeFileType, setAttributeFileType] = useState()
    const [attributeGroupData, setAttributeGroupData] = useState([])
    const [calculativeExpression, setCalculativeExpression] = useState(undefined)
    const [calculativeExpressionEdit, setCalculativeExpressionEdit] = useState(undefined)
    const [tabId, setTabId] = useState(1)
    const [phoneCode, setPhoneCode] = useState('+91')
    const [attributeNameAlreadyExist, setAttributeNameAlreadyExist] = useState(false)

    // React Query calls
    // const {staticAttributeData, staticAttributeApiError} = useGetStaticAttributeValues(attributeId, isEditable);
    // const {attributeData, attributeApiError} = useGetProductAttributes(type, revisionId, attributeId, isEditable);
    // const {productOptionAttributeData, productOptionAttributeApiError} = useGetProductOptionAttributes(attributeId, isEditable);

    // console.log("all the api calls...", staticAttributeData, attributeData, productOptionAttributeData, isEditable)

    const dispatch = useDispatch()
    // get permissions from storage
    const permissionValue = util.getPermissionData() || []
    // get Call for segment data
    useEffect(() => {
        MarketplaceServices.findAll(getAllAttributeGroupAPI, null, false)

            .then(function (response) {
                console.log(
                    'Server response from  MarketplaceServices.findAll(getAllAttributeGroupAPI, null, false)',
                    getAllAttributeGroupAPI,
                    response.data.response_body
                )
                setAttributeGroupData(response.data.response_body.data.product_attribute_groups)
            })
            .catch(function (error) {
                console.log(
                    'Error Server response from  MarketplaceServices.findAll(getAllAttributeGroupAPI, null, false)',
                    getAllAttributeGroupAPI,
                    error
                )
            })
    }, [isAddOptionsButtonEnabled, isAddOptionValuesFormEnabled])

    useEffect(() => {
        if (isEditable) {
            setIsEditting(true)
        }
    }, [isEditable])

    const postVariantAttributes = (attributeId) => {
        if (attributeId !== undefined && attributeId !== null) {
            MarketplaceServices.save(
                productVariantAttributesAPI,
                {
                    product_template_revision: parseInt(revisionId),
                    attribute: [parseInt(attributeId)],
                },
                null
            )
                .then(function (response) {
                    console.log(
                        'Server Response from postVariantAttributes API is',
                        productVariantAttributesAPI,
                        response.data.response_body
                    )
                    MarketplaceToaster.showToast(response)
                    dispatch(fnAttributeDetailsUpdated(true))
                })
                .catch(function (error) {
                    console.log(
                        'Error Server response from postVariantAttributes is',
                        productVariantAttributesAPI,
                        error
                    )
                })
        }
    }

    const closeAttributeCreationModal = () => {
        setIsAttributionCreationModalVisible(false)
    }
    const closeAttributeOptionValueModal = (type, action, isEdited) => {
        if (type === 'edit' && action === 'success') {
            dispatch(fnAttributeDetailsUpdated(true))
        } else {
            if (isEditting && !isEdited) {
                dispatch(fnAttributeDetailsUpdated(true))
            } else {
                setIsAttributeModalVisible(0)
            }
        }
    }

    //  will update the option values to the server
    const UpdateAttributeOptionValuesToServer = (
        prodOptionValues,
        productOption,
        productAttribValue,
        isNonEditableField
    ) => {
        let optionValueCopy = prodOptionValues
        let attribPutObject = ''
        if (optionValueCopy.attribute_options.length > 0) {
            optionValueCopy.attribute_options = optionValueCopy.attribute_options.filter(
                ({ option_name }) => option_name !== ''
            )
            // Removing the isInvalid property from the payload, as it is added for regex validation
            let filteredAttribOptionValues = optionValueCopy.attribute_options.map(({ id, attribute, option_name }) => {
                return {
                    id: id,
                    attribute: attribute,
                    option_name:
                        selectedDataType === 16
                            ? option_name[0].image_full_path
                            : selectedDataType === 18 || selectedDataType === 19 || selectedDataType === 15
                              ? option_name[0].document_full_path
                              : option_name,
                }
            })
            attribPutObject = {
                attribute_options: filteredAttribOptionValues,
            }
        } else {
            if (optionValueCopy.attribute_options.length <= 0) {
                attribPutObject = {
                    attribute_options: [],
                }
            }
        }

        MarketplaceServices.update(attributeOptionAPI, attribPutObject, {
            'attribute-id': parseInt(productOption.id),
        })
            .then((response) => {
                console.log(
                    'Server response from UpdateAttributeOptionValuesToServer API is',
                    attributeOptionAPI,
                    response.data.response_body
                )
                if (productAttribValue && productAttribValue.length > 0 && isNonEditableField) {
                    let attributeOptionValue = attributeValueResponseProcessor(
                        productOption,
                        response.data.response_body,
                        attributeNonEditableValues
                    )
                    // if attribute non-editable value is editted or posted new values during edit.
                    let existingNonEditableAttributeValue = attributeNonEditableValues.filter(
                        ({ attribute }) => attribute === productAttribValue[0].attribute_id
                    )
                    if (
                        existingNonEditableAttributeValue.length > 0 &&
                        (attributeOptionValue.dataGroup === 'Single' ||
                            attributeOptionValue.dataGroup === 'Url' ||
                            attributeOptionValue.dataGroup === 'Range')
                    ) {
                        setIsLoading(true)
                        updateNonEditableValueToServer(
                            existingNonEditableAttributeValue[0].attribute,
                            attributeOptionValue.dataGroup,
                            productAttribValue[0].attribute_value,
                            existingNonEditableAttributeValue[0].id,
                            productOption,
                            attributeOptionValue.attribute_options
                        )
                    } else if (
                        existingNonEditableAttributeValue.length > 0 &&
                        attributeOptionValue.dataGroup === 'Upload'
                    ) {
                        setIsLoading(true)
                        updateNonEditableValueToServer(
                            existingNonEditableAttributeValue[0].attribute,
                            attributeOptionValue.dataGroup,
                            productAttribValue[0].attribute_value,
                            existingNonEditableAttributeValue[0].id,
                            productOption,
                            attributeOptionValue.attribute_options
                        )
                    } else if (
                        existingNonEditableAttributeValue.length > 0 &&
                        attributeOptionValue.dataGroup === 'Optional'
                    ) {
                        let nonEditableValueExist = []
                        productAttribValue.forEach(({ attribute_option }) => {
                            existingNonEditableAttributeValue[0].attribute_option.forEach((value) => {
                                if (value === attribute_option) {
                                    nonEditableValueExist.push(value)
                                }
                            })
                        })
                        if (nonEditableValueExist.length <= 0) {
                            updateNonEditableValueToServer(
                                existingNonEditableAttributeValue[0].attribute,
                                attributeOptionValue.dataGroup,
                                productAttribValue[0].attribute_option,
                                existingNonEditableAttributeValue[0].id,
                                productOption,
                                attributeOptionValue.attribute_options
                            )
                        } else if (
                            (selectedDataType === 1 || selectedDataType === 3) &&
                            existingNonEditableAttributeValue.length > 0
                        ) {
                            let attributeIds = []
                            productAttribValue.forEach((obj) => {
                                if (obj.attributeValueSelected) {
                                    attributeIds.push(obj.attribute_option)
                                }
                            })
                            updateNonEditableValueToServer(
                                existingNonEditableAttributeValue[0].attribute,
                                attributeOptionValue.dataGroup,
                                attributeIds,
                                existingNonEditableAttributeValue[0].id,
                                productOption,
                                attributeOptionValue.attribute_options
                            )
                        } else {
                            const copyOfAttribOptionValue = deepCopy(productAttribOptionValues)
                            // Deleting The existing productOption and updating the edited productOtion
                            let attributeOptionValue = attributeValueResponseProcessor(
                                productOption,
                                response.data.response_body,
                                attributeNonEditableValues
                            )
                            copyOfAttribOptionValue.forEach((obj, i) => {
                                if (obj.attributeID === attributeOptionValue.attributeID) {
                                    copyOfAttribOptionValue[i] = attributeOptionValue
                                }
                            })
                            setProductAttribOptionValues(copyOfAttribOptionValue)
                        }
                    } else if (existingNonEditableAttributeValue.length <= 0) {
                        let optionAttributeValue =
                            selectedDataType === 16
                                ? productAttribValue[0].attribute_value[0].image_full_path
                                : selectedDataType === 18 || selectedDataType === 19 || selectedDataType === 15
                                  ? productAttribValue[0].attribute_value[0].document_full_path
                                  : productAttribValue[0].attribute_value
                        // let optionAttributeValue = productAttribValue[0].attribute_value;
                        let attributeID = productAttribValue[0]?.attribute_id
                        let noneditableID = selectedDataType === 1 || selectedDataType === 3 ? [] : ''
                        if (
                            selectedDataType !== 1 &&
                            selectedDataType !== 3 &&
                            attributeOptionValue.dataGroup === 'Optional'
                        ) {
                            noneditableID =
                                attributeOptionValue.dataGroup === 'Optional'
                                    ? productAttribValue[0].attribute_option
                                    : ''
                        } else if (
                            (selectedDataType === 1 || selectedDataType === 3) &&
                            attributeOptionValue.dataGroup === 'Optional'
                        ) {
                            productAttribValue.forEach((obj) => {
                                if (obj.attributeValueSelected) {
                                    noneditableID.push(obj.attribute_option)
                                }
                            })
                        }
                        addNon_editable_Attribute_Values(
                            optionAttributeValue,
                            noneditableID,
                            attributeID,
                            attributeOptionValue.dataGroup,
                            productOption,
                            attributeOptionValue.attribute_options,
                            undefined,
                            undefined
                        )
                    }
                } else {
                    const copyOfAttribOptionValue = deepCopy(productAttribOptionValues)
                    let attributeOptionValue
                    // Deleting the non-editable values, if non-editable preference is unchecked..
                    if (productAttribValue && productAttribValue.length > 0 && !isNonEditableField) {
                        let copyofNonEditable = [...attributeNonEditableValues]
                        let filteredAttributeValue = copyofNonEditable.filter(
                            ({ attribute }) => attribute !== productOption.id
                        )
                        attributeOptionValue = attributeValueResponseProcessor(
                            productOption,
                            response.data.response_body,
                            filteredAttributeValue
                        )
                    } else {
                        attributeOptionValue = attributeValueResponseProcessor(
                            productOption,
                            response.data.response_body,
                            attributeNonEditableValues
                        )
                    }
                    // Deleting The existing productOption and updating the edited productOtion
                    copyOfAttribOptionValue.forEach((obj, i) => {
                        if (obj.attributeID === attributeOptionValue.attributeID) {
                            copyOfAttribOptionValue[i] = attributeOptionValue
                        }
                    })
                    setProductAttribOptionValues(copyOfAttribOptionValue)
                    closeAttributeOptionValueModal('edit', 'success')
                }
                MarketplaceToaster.showToast(response)
                // disabling spinner
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(
                    'Error Server response from UpdateAttributeOptionValuesToServer is',
                    attributeOptionAPI,
                    error
                )
                setIsLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const updateNonEditableValueToServer = (
        attribID,
        dataGroup,
        nonAttributeValue,
        attributeNonEditableValueID,
        attributeOption,
        attributeValueResponse
    ) => {
        let payload = {}
        if (dataGroup === 'Single' || dataGroup === 'Url' || dataGroup === 'Range') {
            payload = {
                static_attribute_value: [
                    {
                        attribute: attribID,
                        attribute_value:
                            selectedDataType === 12 ? phoneCode + '-' + nonAttributeValue : nonAttributeValue,
                    },
                ],
            }
        } else if (dataGroup === 'Upload') {
            payload = {
                static_attribute_value: [
                    {
                        attribute: attribID,
                        attribute_value: nonAttributeValue,
                    },
                ],
            }
        } else {
            payload = {
                static_attribute_value: [
                    {
                        attribute: attribID,
                        attribute_option:
                            selectedDataType === 1 || selectedDataType === 3 ? nonAttributeValue : [nonAttributeValue],
                    },
                ],
            }
            console.log('we have to make put call for the non editable attribute value', payload)
        }
        MarketplaceServices.update(atttriuteNonEditableValuesAPI, payload, {
            _id: attributeNonEditableValueID,
        })

            .then((response) => {
                console.log(
                    'Server response from updateNonEditableValueToServer is',
                    atttriuteNonEditableValuesAPI,
                    response.data.response_body[0][0]
                )
                let attributenonEditablePutResponse = response.data.response_body[0][0]
                closeAttributeOptionValueModal('edit', 'success')
                const copyOfnonEditableAttributeValue = [...attributeNonEditableValues]
                if (dataGroup === 'Optional') {
                    // Deleting The existing non-editable attribute_option for optinal type and updating the edited non-editable attribute option
                    copyOfnonEditableAttributeValue.forEach((obj, i) => {
                        if (obj.id === attributenonEditablePutResponse.id) {
                            obj.attribute_option = attributenonEditablePutResponse.attribute_option
                        }
                    })
                } else {
                    // Deleting The existing non-editable attribute_value for single type and updating the edited non-editable attribute_value
                    copyOfnonEditableAttributeValue.forEach((obj, i) => {
                        if (obj.id === attributenonEditablePutResponse.id) {
                            obj.attribute_value = attributenonEditablePutResponse.attribute_value
                        }
                    })
                }
                let attributeOptionValue = attributeValueResponseProcessor(
                    attributeOption,
                    attributeValueResponse,
                    copyOfnonEditableAttributeValue
                )
                if (isEditting) {
                    let copyofproductAttribute = [...productAttributes]
                    let copyofproductAyyributeValue = deepCopy(productAttribOptionValues)
                    let attributeExist = copyofproductAttribute.filter(({ id }) => id === attributeOption.id)
                    if (attributeExist.length <= 0) {
                        setProductAttributes([...productAttributes, attributeOption])
                    }
                    copyofproductAyyributeValue.forEach((obj, index) => {
                        if (obj.attributeID === attributeOptionValue.attributeID) {
                            copyofproductAyyributeValue[index] = attributeOptionValue
                        }
                    })
                    setProductAttribOptionValues(copyofproductAyyributeValue)
                } else {
                    setProductAttributes([...productAttributes, attributeOption])
                    setProductAttribOptionValues([...productAttribOptionValues, attributeOptionValue])
                }
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(
                    'Error server response from updateNonEditableValueToServer is',
                    atttriuteNonEditableValuesAPI,
                    error
                )

                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
            })
    }

    const updateAttributeValues = (optionValueId, attributeID, nonEditableValueId) => {
        let nonEditableAttributeValuePutObject = {
            static_attribute_value: [
                {
                    attribute: attributeID,
                    attribute_option:
                        selectedDataType === 1 || selectedDataType === 3 ? optionValueId : [optionValueId],
                },
            ],
        }
        MarketplaceServices.update(atttriuteNonEditableValuesAPI, nonEditableAttributeValuePutObject, {
            _id: nonEditableValueId,
        })
            .then((response) => {
                console.log(
                    'Server response from updateAttributeValues is',
                    atttriuteNonEditableValuesAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                dispatch(fnAttributeDetailsUpdated(true))
            })
            .catch((error) => {
                console.log('Server error response from updateAttributeValues is', atttriuteNonEditableValuesAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const addAttributeValues = (optionValueId, attributeID) => {
        let nonEditableAttributeValuePostObject = {
            static_attribute_value: [
                {
                    attribute: attributeID,
                    attribute_option:
                        selectedDataType === 1 || selectedDataType === 3 ? optionValueId : [optionValueId],
                },
            ],
        }
        MarketplaceServices.save(atttriuteNonEditableValuesAPI, nonEditableAttributeValuePostObject, null)
            .then((response) => {
                console.log(
                    'Server response from addAttributeValues is',
                    atttriuteNonEditableValuesAPI,
                    response.data.response_body
                )
                dispatch(fnAttributeDetailsUpdated(true))
            })
            .catch((error) => {
                console.log('Server error response from addAttributeValues is', atttriuteNonEditableValuesAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const addAttribute = (
        isOptionValueFieldEditted,
        productOption,
        isInherited,
        productOptionValues,
        productAttribValue,
        isNonEditableField,
        groupData,
        isNonEditableValueEdited,
        action
    ) => {
        // this is for put call for the attribute option and attribute value..
        if (isEditting) {
            // if attribute option is edited.
            if (productOption.is_attribute_option_edited) {
                let attributeObject = {
                    id: productOption.id,
                    name: productOption.option_Name,
                    mandatory: productOption.attributes.mandatory,
                    is_static: productOption.attributes.is_static,
                    revisable: productOption.attributes.revisable,
                    sensible: productOption.attributes.sensible,
                    unique: productOption.attributes.unique,
                    hide_to_customer: productOption.attributes.hide_to_customer,
                    varient_specific: productOption.attributes.varient_specific,
                    is_variant_attribute: productOption.attributes.is_variant_attribute,
                    order_specific: productOption.attributes.order_specific,
                    comparable: productOption.attributes.comparable,
                    help_text: productOption.attributes.help_text,
                    placeholder: productOption.attributes.placeholder,
                    datatype: productOption.attributes.datatype.id,
                    parent_attribute_value: 'false',
                    attribute_unit_value: attributePostUnitValuePostProcessor(
                        productOption.attributes.attribute_unit_value
                    ),
                    attribute_type: productOption.attributes.attribute_type,
                    product_attribute_group: productOption.attributes.product_attribute_group,
                }
                // Payload for the put call
                const attributePutObject = {
                    belongs_to: type,
                    belonging_id: revisionId,
                    attributes: [attributeObject],
                }
                // showing spinner untill the call is completed
                setIsLoading(true)
                MarketplaceServices.update(attributesAPI, attributePutObject, null)

                    .then((response) => {
                        console.log(
                            'Server response from addAttribute API is',
                            attributesAPI,
                            response.data.response_body
                        )
                        MarketplaceToaster.showToast(response)
                        const copyOfProductAttributes = [...productAttributes]
                        if (
                            productOption.is_attribute_option_edited &&
                            productOptionValues &&
                            (productOptionValues.dataGroup === 'Optional' ||
                                productOptionValues.dataGroup === 'Single' ||
                                productOptionValues.dataGroup === 'Range' ||
                                productOptionValues.dataGroup === 'Url' ||
                                productOptionValues.dataGroup === 'Upload') &&
                            productOptionValues.attribute_options.length >= 0
                        ) {
                            let copyofproductAyyributeValue = deepCopy(productAttribOptionValues)
                            let copyofNonEditable = [...attributeNonEditableValues]
                            setProductAttributes(copyOfProductAttributes)
                            let processedAttributeResponse = attibuteDataProcessor(response.data.response_body, true)
                            let attributeOptionValue
                            if (!processedAttributeResponse[0].attributes.is_static) {
                                let filteredAttributeValue = copyofNonEditable.filter(
                                    ({ attribute }) => attribute !== processedAttributeResponse[0].id
                                )
                                attributeOptionValue = attributeValueResponseProcessor(
                                    processedAttributeResponse[0],
                                    productOptionValues.attribute_options,
                                    filteredAttributeValue
                                )
                                copyofproductAyyributeValue.forEach((obj, index) => {
                                    if (obj.attributeID === attributeOptionValue.attributeID) {
                                        copyofproductAyyributeValue[index] = attributeOptionValue
                                    }
                                })
                                setProductAttribOptionValues(copyofproductAyyributeValue)
                            } else {
                                // if attribute non-editable value is  posted new values during edit.
                                if (productAttribValue && productAttribValue.length > 0 && isNonEditableField) {
                                    let existingNonEditableAttributeValue = attributeNonEditableValues.filter(
                                        ({ attribute }) => attribute === productAttribValue[0].attribute_id
                                    )
                                    if (existingNonEditableAttributeValue.length <= 0) {
                                        let optionAttributeValue =
                                            selectedDataType === 16
                                                ? productAttribValue[0].attribute_value[0].image_full_path
                                                : selectedDataType === 18 ||
                                                    selectedDataType === 19 ||
                                                    selectedDataType === 15
                                                  ? productAttribValue[0].attribute_value[0].document_full_path
                                                  : productAttribValue[0].attribute_value
                                        let attributeID = productAttribValue[0]?.attribute_id
                                        let noneditableID = selectedDataType === 1 || selectedDataType === 3 ? [] : ''
                                        if (
                                            selectedDataType !== 1 &&
                                            selectedDataType !== 3 &&
                                            productOptionValues.dataGroup === 'Optional'
                                        ) {
                                            noneditableID =
                                                productOptionValues.dataGroup === 'Optional'
                                                    ? productAttribValue[0].attribute_option
                                                    : ''
                                        } else if (
                                            (selectedDataType === 1 || selectedDataType === 3) &&
                                            productOptionValues.dataGroup === 'Optional'
                                        ) {
                                            productAttribValue.forEach((obj) => {
                                                if (obj.attributeValueSelected) {
                                                    noneditableID.push(obj.attribute_option)
                                                }
                                            })
                                        }
                                        let isNonEditableIdNumbers = true
                                        if (selectedDataType == 1 || selectedDataType == 3) {
                                            isNonEditableIdNumbers =
                                                noneditableID.filter((value) => typeof value === 'string').length > 0
                                                    ? false
                                                    : true
                                        } else {
                                            isNonEditableIdNumbers =
                                                typeof noneditableID === 'string' && noneditableID != '' ? false : true
                                        }
                                        if (isNonEditableIdNumbers) {
                                            addNon_editable_Attribute_Values(
                                                optionAttributeValue,
                                                noneditableID,
                                                attributeID,
                                                productOptionValues.dataGroup,
                                                productOption,
                                                productOptionValues.attribute_options,
                                                undefined,
                                                undefined
                                            )
                                        }
                                    } else {
                                        attributeOptionValue = attributeValueResponseProcessor(
                                            processedAttributeResponse[0],
                                            productOptionValues.attribute_options,
                                            attributeNonEditableValues
                                        )
                                        copyofproductAyyributeValue.forEach((obj, index) => {
                                            if (obj.attributeID === attributeOptionValue.attributeID) {
                                                copyofproductAyyributeValue[index] = attributeOptionValue
                                            }
                                        })
                                        setProductAttribOptionValues(copyofproductAyyributeValue)
                                    }
                                } else {
                                    attributeOptionValue = attributeValueResponseProcessor(
                                        processedAttributeResponse[0],
                                        productOptionValues.attribute_options,
                                        attributeNonEditableValues
                                    )
                                    copyofproductAyyributeValue.forEach((obj, index) => {
                                        if (obj.attributeID === attributeOptionValue.attributeID) {
                                            copyofproductAyyributeValue[index] = attributeOptionValue
                                        }
                                    })
                                    setProductAttribOptionValues(copyofproductAyyributeValue)
                                }
                            }
                        } else {
                            // This call will reset the is_attribute_option_edited
                            setProductAttributes(copyOfProductAttributes)
                        }

                        const newlyAddedAttribOptionValues = productOptionValues.attribute_options.filter(
                            ({ id }) => typeof id == 'string'
                        )

                        if (!isNonEditableValueEdited && !newlyAddedAttribOptionValues.length > 0) {
                            closeAttributeOptionValueModal('edit', 'success')
                        }
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        console.log('Error server response from addAttribute is', attributesAPI, error)
                        MarketplaceToaster.showToast(error.response)
                        setIsLoading(false)
                    })
            }
            // ------------------------------------------------------------------
            // if attribute non-editable value is editted new values during edit.
            if (productAttribValue && productAttribValue.length > 0 && isNonEditableField && isNonEditableValueEdited) {
                let existingNonEditableAttributeValue = attributeNonEditableValues.filter(
                    ({ attribute }) => attribute === productAttribValue[0].attribute_id
                )
                if (
                    existingNonEditableAttributeValue.length > 0 &&
                    (productOptionValues.dataGroup === 'Single' ||
                        productOptionValues.dataGroup === 'Url' ||
                        productOptionValues.dataGroup === 'Range')
                ) {
                    setIsLoading(true)
                    updateNonEditableValueToServer(
                        existingNonEditableAttributeValue[0].attribute,
                        productOptionValues.dataGroup,
                        productAttribValue[0].attribute_value,
                        existingNonEditableAttributeValue[0].id,
                        productOption,
                        productOptionValues.attribute_options
                    )
                } else if (existingNonEditableAttributeValue.length > 0 && productOptionValues.dataGroup === 'Upload') {
                    let optionAttributeValue =
                        selectedDataType === 16
                            ? productAttribValue[0].attribute_value[0].image_full_path
                            : selectedDataType === 18 || selectedDataType === 19 || selectedDataType === 15
                              ? productAttribValue[0].attribute_value[0].document_full_path
                              : productAttribValue[0].attribute_value

                    setIsLoading(true)
                    updateNonEditableValueToServer(
                        existingNonEditableAttributeValue[0].attribute,
                        productOptionValues.dataGroup,
                        optionAttributeValue,
                        existingNonEditableAttributeValue[0].id,
                        productOption,
                        productOptionValues.attribute_options
                    )
                } else if (
                    existingNonEditableAttributeValue.length > 0 &&
                    productOptionValues.dataGroup === 'Optional' &&
                    selectedDataType !== 1 &&
                    selectedDataType !== 3 &&
                    productOptionValues &&
                    productOptionValues.attribute_options.length > 0
                ) {
                    let nonEditableValueExist = []
                    productAttribValue.forEach(({ attribute_option }) => {
                        existingNonEditableAttributeValue[0].attribute_option.forEach((value) => {
                            if (value === attribute_option) {
                                nonEditableValueExist.push(value)
                            }
                        })
                    })
                    if (
                        nonEditableValueExist.length <= 0 &&
                        typeof productAttribValue[0].attribute_option != 'string'
                    ) {
                        updateNonEditableValueToServer(
                            existingNonEditableAttributeValue[0].attribute,
                            productOptionValues.dataGroup,
                            productAttribValue[0].attribute_option,
                            existingNonEditableAttributeValue[0].id,
                            productOption,
                            productOptionValues.attribute_options
                        )
                    }
                } else if (
                    (selectedDataType === 1 || selectedDataType === 3) &&
                    existingNonEditableAttributeValue.length > 0 &&
                    productOptionValues &&
                    productOptionValues.attribute_options.length > 0
                ) {
                    let attributeIds = []
                    productAttribValue.forEach((obj) => {
                        if (obj.attributeValueSelected) {
                            attributeIds.push(obj.attribute_option)
                        }
                    })
                    const newlyAddedAttributeValues = attributeIds.filter((value) => typeof value == 'string')
                    if (newlyAddedAttributeValues.length <= 0) {
                        updateNonEditableValueToServer(
                            existingNonEditableAttributeValue[0].attribute,
                            productOptionValues.dataGroup,
                            attributeIds,
                            existingNonEditableAttributeValue[0].id,
                            productOption,
                            productOptionValues.attribute_options
                        )
                    }
                }
            }

            // ------------------------------------------------------------------
            // if attribute option value is editted or posted new values during edit.
            if (
                productOptionValues &&
                productOptionValues.dataGroup === 'Optional' &&
                productOptionValues.attribute_options.length > 0
            ) {
                // Splitting the existing attribute option values by using type of id, ie. "number"
                const existingAttribOptionValues = productOptionValues.attribute_options.filter(
                    ({ id }) => typeof id == 'number'
                )
                // Splitting the newly added attribute option values
                const newlyAddedAttribOptionValues = productOptionValues.attribute_options.filter(
                    ({ id }) => typeof id == 'string'
                )
                // passing only edited values , will have this if required. for now will comment the below logic
                // Here we are doing the put call for the existing attrib option values if they are editted.
                if (
                    existingAttribOptionValues.length > 0 &&
                    existingAttribOptionValues.filter(
                        ({ isAttributeOptionValueEdited }) => isAttributeOptionValueEdited
                    ).length > 0
                ) {
                    let copyofProductOptionValues = productOptionValues
                    copyofProductOptionValues.attribute_options = existingAttribOptionValues
                    UpdateAttributeOptionValuesToServer(
                        copyofProductOptionValues,
                        productOption,
                        productAttribValue,
                        isNonEditableField
                    )
                }
                // Here we are doing the post call for the newly added attrib option values.
                if (newlyAddedAttribOptionValues.length > 0) {
                    // showing spinner untill the call is completed
                    setIsLoading(true)
                    const postObject = {
                        attribute_options: newlyAddedAttribOptionValues.map(({ attribute, option_name }) => {
                            return {
                                attribute: productOption.id,
                                option_name: option_name,
                            }
                        }),
                    }
                    MarketplaceServices.save(attributeOptionAPI, postObject, null)

                        .then((response) => {
                            console.log(
                                'Server response from post call is is',
                                attributeOptionAPI,
                                response.data.response_body
                            )
                            let attributeOptionValue = attributeValueResponseProcessor(
                                productOption,
                                response.data.response_body
                            )
                            if (
                                productOptionValues.dataGroup === 'Optional' &&
                                isNonEditableField &&
                                productOption.attributes.is_static &&
                                productAttribValue &&
                                productAttribValue.length > 0
                            ) {
                                let existingNonEditableAttributeValueID =
                                    attributeNonEditableValues.length > 0
                                        ? attributeNonEditableValues.filter(
                                              ({ attribute }) => attribute === productAttribValue[0].attribute_id
                                          )[0].id
                                        : false
                                let postAttributeValuesId
                                // Below code is used only for the checkbox and switch as they have the mutiselect feature
                                if (selectedDataType === 1 || selectedDataType === 3) {
                                    let attributeIds = []
                                    productAttribValue.forEach((obj) => {
                                        if (obj.attributeValueSelected) {
                                            attributeIds.push(obj.attribute_option)
                                        }
                                    })
                                    // In the form of numbers....
                                    const existingAttriuteValues = attributeIds.filter(
                                        (value) => typeof value == 'number'
                                    )
                                    // In the form of strings newly added attribute values and checked oness.......
                                    let newlyAddedAttributeValue = []
                                    productAttribValue.forEach((obj) => {
                                        if (obj.attributeValueSelected) {
                                            if (typeof obj.attribute_option == 'string')
                                                newlyAddedAttributeValue.push(obj)
                                        }
                                    })

                                    let filteredIdsFromNewlyAddedAttributeValues = []
                                    newlyAddedAttributeValue.forEach(({ attribute_value }) => {
                                        attributeOptionValue.attribute_options.forEach(({ option_name, id }) => {
                                            if (option_name == attribute_value) {
                                                filteredIdsFromNewlyAddedAttributeValues.push(id)
                                            }
                                        })
                                    })
                                    postAttributeValuesId = [
                                        ...filteredIdsFromNewlyAddedAttributeValues,
                                        ...existingAttriuteValues,
                                    ]
                                } else {
                                    let attributeValueType =
                                        typeof productAttribValue[0].attribute_option == 'string' ? true : false
                                    if (attributeValueType) {
                                        let filterid = []
                                        productAttribValue.forEach(({ attribute_value }) => {
                                            attributeOptionValue.attribute_options.forEach(({ option_name, id }) => {
                                                if (option_name == attribute_value) {
                                                    filterid.push(id)
                                                }
                                            })
                                        })
                                        postAttributeValuesId = filterid[0]
                                    } else {
                                        postAttributeValuesId = productAttribValue[0].attribute_option
                                    }
                                }
                                if (existingNonEditableAttributeValueID) {
                                    updateAttributeValues(
                                        postAttributeValuesId,
                                        attributeOptionValue.attributeID,
                                        existingNonEditableAttributeValueID
                                    )
                                } else {
                                    addAttributeValues(postAttributeValuesId, attributeOptionValue.attributeID)
                                }
                            }
                            if (!productOption.attributes.is_static) {
                                dispatch(fnAttributeDetailsUpdated(true))
                            }
                            MarketplaceToaster.showToast(response)
                        })
                        .catch((error) => {
                            console.log('Error Server response from post call is is', attributeOptionAPI, error)

                            // hide spinner as the call complete
                            setIsLoading(false)
                            MarketplaceToaster.showToast(error.response)
                        })
                }
            } else {
                if (productOptionValues && productOptionValues.attribute_options.length > 0) {
                    let copyOfAttribOptionValues = deepCopy(productAttribOptionValues)
                    // Making call even if option values are empty
                    if (productOptionValues.attribute_options.length <= 0) {
                        UpdateAttributeOptionValuesToServer(productOptionValues, productOption)
                    } else if (selectedDataType == 16 || selectedDataType == 18 || selectedDataType == 19) {
                        UpdateAttributeOptionValuesToServer(productOptionValues, productOption)
                    }
                    // updating the latest attribute option value, since few of the attributes are deleted
                    copyOfAttribOptionValues.forEach((obj) => {
                        if (obj.attributeName === productOptionValues.attributeName) {
                            obj.attribute_options = productOptionValues.attribute_options
                        }
                    })
                    setProductAttribOptionValues(copyOfAttribOptionValues)
                } else if (productAttribValue && productAttribValue.length > 0) {
                    console.log('will be doing put call for attribute values,,,,', productAttribValue)
                }
            }

            //  No changes detected.......
            if (
                !productOption.is_attribute_option_edited &&
                !isNonEditableValueEdited &&
                action === 'edit' &&
                calculativeExpressionEdit === undefined &&
                !(
                    productOptionValues &&
                    productOptionValues.dataGroup === 'Optional' &&
                    productOptionValues.attribute_options.filter(
                        ({ isAttributeOptionValueEdited }) => isAttributeOptionValueEdited
                    ).length > 0
                )
            ) {
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
        } else {
            // enabling spinner
            setIsLoading(true)
            // This is for post call
            let optionValueCopy = productOptionValues
            optionValueCopy.attribute_options = optionValueCopy.attribute_options.filter(
                ({ option_name }) => option_name !== ''
            )
            let attributeObject = {
                name: productOption.attributes.name,
                mandatory: productOption.attributes.mandatory,
                is_static: productOption.attributes.is_static,
                revisable: productOption.attributes.revisable,
                sensible: productOption.attributes.sensible,
                unique: productOption.attributes.unique,
                hide_to_customer: productOption.attributes.hide_to_customer,
                varient_specific: productOption.attributes.varient_specific,
                is_variant_attribute: productOption.attributes.is_variant_attribute,
                order_specific: productOption.attributes.order_specific,
                comparable: productOption.attributes.comparable,
                help_text: productOption.attributes.help_text,
                placeholder: productOption.attributes.placeholder,
                datatype: productOption.attributes.datatype,
                attribute_unit_value: attributePostUnitValuePostProcessor(
                    productOption.attributes.attribute_unit_value
                ),
                attribute_type: productOption.attributes.attribute_type,
                product_attribute_group: productOption.attributes.product_attribute_group,
            }
            const productAttributePostObject = {
                belongs_to: type,
                belonging_id: revisionId,
                attributes: [attributeObject],
            }
            MarketplaceServices.save(attributesAPI, productAttributePostObject, null)
                .then((response) => {
                    MarketplaceToaster.showToast(response)
                    let processedAttributeResponse = attibuteDataProcessor(response.data.response_body, true)
                    const attributeDisplayNamePostObject = {
                        attribute_languages: [
                            {
                                attribute: processedAttributeResponse[0].id,
                                language_code: String(defaultLngsCode),
                                display_name: attributeObject.name,
                            },
                        ],
                    }
                    // this call will be made only, when there is option values
                    if (optionValueCopy.attribute_options.length > 0 && dataTypeGroup === 'Optional') {
                        addProductOptionValuesToServer(
                            processedAttributeResponse,
                            productOption,
                            optionValueCopy,
                            productAttribValue,
                            isNonEditableField,
                            productOption.attributes.product_attribute_group,
                            response.data.response_body[0],
                            groupData
                        )
                    } // This call will be made for attribute value for datagroup of single, only when the non-editable field is checked...
                    else if (
                        (dataTypeGroup === 'Single' || dataTypeGroup === 'Url' || dataTypeGroup === 'Range') &&
                        isNonEditableField
                    ) {
                        let optionAttributeValue = productOptionValues.non_editableattribute_value
                        let attributeID = processedAttributeResponse[0]?.id
                        addNon_editable_Attribute_Values(
                            optionAttributeValue,
                            '',
                            attributeID,
                            dataTypeGroup,
                            processedAttributeResponse[0],
                            undefined,
                            attributeDisplayNamePostObject,
                            attributeDisplayNameAPI
                        )
                    } else if (dataTypeGroup === 'Upload' && isNonEditableField) {
                        let attributeID = processedAttributeResponse[0]?.id
                        let optionAttributeValue =
                            selectedDataType === 16
                                ? productOptionValues.non_editableattribute_value[0].image_full_path
                                : productOptionValues.non_editableattribute_value[0].document_full_path
                        addNon_editable_Attribute_Values(
                            optionAttributeValue,
                            '',
                            attributeID,
                            dataTypeGroup,
                            processedAttributeResponse[0],
                            undefined,
                            attributeDisplayNamePostObject,
                            attributeDisplayNameAPI
                        )
                    } else {
                        setIsLoading(false)
                        setAttributePosted(true)
                        // Todo: will remove later
                        if (Choices !== 'Variant') {
                            dispatch(fnAttributeDetailsUpdated(true))
                        }
                        let attributeOptionValue = attributeValueResponseProcessor(
                            processedAttributeResponse[0],
                            undefined
                        )
                        closeAttributeCreationModal()
                        setProductAttributes([...productAttributes, processedAttributeResponse[0]])
                        setProductAttribOptionValues([...productAttribOptionValues, attributeOptionValue])
                    }
                })
                .catch((error) => {
                    console.log('Server Error Response from attributesAPI post call is', attributesAPI, error)
                    setIsLoading(false)
                    MarketplaceToaster.showToast(error?.response)
                    if(error?.response?.data?.response_code === "PMS-000120-14"){
                        setAttributeNameAlreadyExist(true)
                    }
                })
        }
        setIsAttributeLoading(false)
    }

    const addProductOptionValuesToServer = (
        attribResponse,
        attribOption,
        attributeOptionValue,
        prodAttribValue,
        isNonEditable,
        attribueGroupId,
        nonProcessedResponse,
        groupData
    ) => {
        let tempFilteredArray = []
        let attribValues = []
        // pushing attribute Option Value to array
        attribValues.push(attributeOptionValue)
        const filteredAttribOptionValue = attribValues.map(({ attribute_options }) => {
            return attribute_options.map((optionValue) => {
                return {
                    attribute: optionValue.attribute,
                    option_name: optionValue.option_name,
                }
            })
        })
        filteredAttribOptionValue.forEach((obj, i) => {
            tempFilteredArray = tempFilteredArray.concat(filteredAttribOptionValue[i])
        })

        tempFilteredArray.forEach((product) => {
            if (attribResponse.filter((obj) => obj.option_Name === product.attribute).length > 0) {
                // converting the attribute containing name into id, for posting the data to server.
                product.attribute = attribResponse.filter((obj) => obj.option_Name === product.attribute)[0].id
                if (selectedDataType === 16) {
                    product.option_name = product.option_name[0].image_full_path
                } else if (selectedDataType === 18 || selectedDataType === 19 || selectedDataType === 15) {
                    product.option_name = product.option_name[0].document_full_path
                }
            }
        })
        // Creating the option values post object

        const postObjectOfAttribOptionValues = {
            // Checking against the type of the attribute is number, to make sure the attribute are passed as number only
            attribute_options: tempFilteredArray.filter((prod) => typeof prod.attribute == 'number'),
        }

        MarketplaceServices.save(attributeOptionAPI, postObjectOfAttribOptionValues, null)
            .then((attributeValueResponse) => {
                console.log(
                    'Server response from addProductOptionValuesToServer api is',
                    attributeOptionAPI,
                    attributeValueResponse
                )
                // Making the server data alway false before setting, because we are adding new values....always
                let attributeOption = attribResponse[0]
                attributeOption.is_serverData = true
                let attributeOptionValue = attributeValueResponseProcessor(
                    attributeOption,
                    attributeValueResponse.data.response_body
                )
                // attribute option displayname payload below
                let attributeOptionDisplayNameValues = attributeValueResponseProcessor(
                    attributeOption,
                    attributeValueResponse.data.response_body
                ).attribute_options.map(({ id, option_name }) => {
                    return {
                        display_name: option_name,
                        description: 'default description',
                        attribute_option: id,
                        language_code: String(defaultLngsCode),
                    }
                })
                const attributeOptionDisplayNamePostObject = {
                    option_name: attributeOptionDisplayNameValues,
                }
                console.log(
                    'Porcessed',
                    attributeOption,
                    attributeValueResponseProcessor(attributeOption, attributeValueResponse.data.response_body),
                    'unprocessed',
                    attributeOptionValue
                )
                // This call will be made for attribute value, for datagroup of optional, only when the non-editable field is checked...
                if (dataTypeGroup == 'Optional' && isNonEditable) {
                    let optionValueAttributeID = selectedDataType === 1 || selectedDataType === 3 ? [] : ''
                    let attributeID = attributeOption?.id
                    let optionAttributeValue
                    if (selectedDataType !== 1 && selectedDataType !== 3) {
                        optionAttributeValue = prodAttribValue[0].attribute_value
                        optionValueAttributeID = attributeOptionValue.attribute_options.filter(
                            ({ option_name }) => option_name === optionAttributeValue
                        )[0]?.id
                    } else {
                        prodAttribValue.forEach(({ attribute_value }) => {
                            attributeOptionValue.attribute_options.forEach((obj) => {
                                if (obj.option_name == attribute_value) {
                                    optionValueAttributeID.push(obj.id)
                                }
                            })
                        })
                    }
                    addNon_editable_Attribute_Values(
                        optionAttributeValue,
                        optionValueAttributeID,
                        attributeID,
                        dataTypeGroup,
                        attributeOption,
                        attributeValueResponse.data.response_body,
                        attributeOptionDisplayNamePostObject,
                        attributeOptionDisplayNameAPI
                    )
                } else {
                    // Todo: will remove later
                    if (Choices !== 'Variant') {
                        dispatch(fnAttributeDetailsUpdated(true))
                    }
                    setIsLoading(false)
                }
                if (Choices === 'Variant') {
                    postVariantAttributes(attributeOption.id)
                }
                setProductAttributes([...productAttributes, attributeOption])
                setProductAttribOptionValues([...productAttribOptionValues, attributeOptionValue])
                // closing the attribute creation modal, once the attribute are added succesfully
                closeAttributeCreationModal()
                MarketplaceToaster.showToast(attributeValueResponse)
            })
            .catch((error) => {
                console.log(
                    'Error Server response from post call addProductOptionValuesToServer is',
                    attributeOptionAPI,
                    error
                )
                setIsLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    // below function will do post call for attribute value if non-ediable fields are checked..
    const addNon_editable_Attribute_Values = (
        attributeValue,
        optionValueId,
        attributeID,
        dataTypeGroup,
        attributeOption,
        attributeValueResponse,
        displayNamePostObject,
        displayNameAPI
    ) => {
        let nonEditableAttributeValuePostObject
        if (dataTypeGroup === 'Single' || dataTypeGroup === 'Url' || dataTypeGroup === 'Range') {
            nonEditableAttributeValuePostObject = {
                static_attribute_value: [
                    {
                        attribute: attributeID,
                        attribute_value: selectedDataType === 12 ? phoneCode + '-' + attributeValue : attributeValue,
                    },
                ],
            }
            console.log(
                'POST JSON for is_static fields for single',
                attributeValue,
                attributeID,
                nonEditableAttributeValuePostObject
            )
        } else if (dataTypeGroup === 'Upload') {
            nonEditableAttributeValuePostObject = {
                static_attribute_value: [
                    {
                        attribute: attributeID,
                        attribute_value: attributeValue,
                    },
                ],
            }
            console.log(
                'POST JSON for is_static fields for upload',
                attributeValue,
                attributeID,
                nonEditableAttributeValuePostObject
            )
        } else {
            nonEditableAttributeValuePostObject = {
                static_attribute_value: [
                    {
                        attribute: attributeID,
                        attribute_option:
                            selectedDataType === 1 || selectedDataType === 3 ? optionValueId : [optionValueId],
                    },
                ],
            }
            console.log(
                'POST JSON for is_static fields for optional',
                attributeValue,
                optionValueId,
                attributeID,
                nonEditableAttributeValuePostObject
            )
        }
        MarketplaceServices.save(atttriuteNonEditableValuesAPI, nonEditableAttributeValuePostObject, null)
            .then((response) => {
                console.log(
                    'Server response from addNon_editable_Attribute_Values is ',
                    atttriuteNonEditableValuesAPI,
                    response.data.response_body
                )
                let attributeNonEditableValuesCopy = [...attributeNonEditableValues]
                attributeNonEditableValuesCopy.push(response.data.response_body[0][0])
                dispatch(fnNewlyCreatedStaticAttributeData(response.data.response_body[0][0]))
                let attributeOptionValue = attributeValueResponseProcessor(
                    attributeOption,
                    attributeValueResponse,
                    attributeNonEditableValuesCopy
                )
                console.log(
                    'Post response,,,of noneditable',
                    attributeNonEditableValuesCopy,
                    attributeOption,
                    'optionVaueResponse',
                    productAttribOptionValueResponse,
                    'productATtribute',
                    productAttributes,
                    'OptionVlaues',
                    attributeOptionValue
                )
                setAttributeNonEditableValues(attributeNonEditableValuesCopy)

                if (isEditting) {
                    let copyofproductAttribute = [...productAttributes]
                    let copyofproductAyyributeValue = [...productAttribOptionValues]
                    let attributeExist = copyofproductAttribute.filter(({ id }) => id === attributeOption.id)
                    if (attributeExist.length <= 0) {
                        setProductAttributes([...productAttributes, attributeOption])
                    }
                    copyofproductAyyributeValue.forEach((obj, index) => {
                        if (obj.attributeID === attributeOptionValue.attributeID) {
                            copyofproductAyyributeValue[index] = attributeOptionValue
                        }
                    })
                    setProductAttribOptionValues(copyofproductAyyributeValue)
                } else {
                    setProductAttributes([...productAttributes, attributeOption])
                    setProductAttribOptionValues([...productAttribOptionValues, attributeOptionValue])
                }
                if (isEditable) {
                    closeAttributeOptionValueModal('edit', 'success')
                } else {
                    // Todo: will remove later
                    if (Choices !== 'Variant') {
                        dispatch(fnAttributeDetailsUpdated(true))
                    }
                }

                closeAttributeCreationModal()
                // disabling spinner
                setIsLoading(false)
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                // disabling spinner
                setIsLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const openChooseOptionWindow = () => {
        // Closing the optionValuefrom, since there is a change in control
        setIsAddOptionValuesFormEnabled(false)
        // Will open the choose option window for adding different control
        setIsAddOptionsButtonEnabled(false)
        setIsAttributionCreationModalVisible(true)
        dispatch(fnAttributeDetailsUpdated(false))
    }

    const closeChooseOptionWindow = () => {
        setIsAddOptionsButtonEnabled(true)
    }

    const openAddOptionValuesForm = (
        option,
        datatype,
        regex,
        regexExceptionMessage,
        dataGroup,
        attribUnit,
        minimumfields,
        maximumfields,
        attributeTypes,
        attributeExt,
        attributeFileType,
        selectedtabId
    ) => {
        setSelectedDataType(datatype)
        setSelectedOption(option)
        setSelectedRegEx(regex)
        setDataTypeGroup(dataGroup)
        setAttributeUnit(attribUnit)
        setMinimumNoOfFields(minimumfields)
        setMaximumNoOfFields(maximumfields)
        setRegExExceptionMessage(regexExceptionMessage)
        setAttributeTypes(attributeTypes)
        setAttributeExtension(attributeExt)
        setAttributeFileType(attributeFileType)
        setTabId(selectedtabId)
        // Closing the choose option window, since there is no change in control and it has been selected
        setIsAddOptionsButtonEnabled(true)
        // Will open the form for adding option values
        setIsAddOptionValuesFormEnabled(true)
    }

    // This function will return the "Name" of the data type for the type being passed
    const getDataTypeName = (datatype) => {
        let filterAttribTypes = attributeDataType.filter((obj) => obj.id === datatype)
        return filterAttribTypes[0].name
    }

    // This function will be check the prefix and suffix fields and their value's, if both there return's true.
    // Other wise it will return false....
    const checkPrefixSuffixValueandFields = (attrinuteUnitValue, attributeKey) => {
        if (attrinuteUnitValue.length > 0) {
            let filteredAttributeUnit = attrinuteUnitValue.filter(({ key }) => key === attributeKey)
            if (filteredAttributeUnit.length > 0) {
                if (filteredAttributeUnit[0]?.value[0] !== '') {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const checkForUnitValue = (unitValue, dataType, dataTypeSet) => {
        console.log('selectedDataType from checkForUnitValue are', selectedDataType, dataType.id)
        if (dataTypeSet) {
            setSelectedDataType(dataType.id)
        }
        let values = []
        const checkIfExist = (key, value) => {
            return unitValue.some((obj) => obj[key] === value)
        }
        if (dataType.id === 1 || dataType.id === 2 || dataType.id === 3 || dataType.id === 7 || dataType.id === 8) {
            if (!checkIfExist('key', 'prefix_displayname')) {
                values.push({
                    key: 'prefix_displayname',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'prefix_displayname')[0])
            }

            if (!checkIfExist('key', 'suffix_displayname')) {
                values.push({
                    key: 'suffix_displayname',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'suffix_displayname')[0])
            }

            if (!checkIfExist('key', 'default_selection')) {
                values.push({
                    key: 'default_selection',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'default_selection')[0])
            }
        } else if (dataType.id === 14 || dataType.id === 5 || dataType.id === 9) {
            if (!checkIfExist('key', 'format')) {
                values.push({
                    key: 'format',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'format')[0])
            }
        } else if (dataType.id === 11) {
            if (!checkIfExist('key', 'value_regex')) {
                values.push({
                    key: 'value_regex',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'value_regex')[0])
            }

            if (!checkIfExist('key', 'value_regex_error_message')) {
                values.push({
                    key: 'value_regex_error_message',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'value_regex_error_message')[0])
            }
        } else if (dataType.id === 25) {
            if (!checkIfExist('key', 'decimal')) {
                values.push({
                    key: 'decimal',
                    value: [decimalMinValue],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'decimal')[0])
            }
        } else if (dataType.id === 23) {
            if (!checkIfExist('key', 'min')) {
                values.push({
                    key: 'min',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'min')[0])
            }

            if (!checkIfExist('key', 'max')) {
                values.push({
                    key: 'max',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'max')[0])
            }
        } else if (dataType.id === 24) {
            if (!checkIfExist('key', 'min')) {
                values.push({
                    key: 'min',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'min')[0])
            }

            if (!checkIfExist('key', 'max')) {
                values.push({
                    key: 'max',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'max')[0])
            }
        } else if (dataType.id === 26) {
            if (!checkIfExist('key', 'min')) {
                values.push({
                    key: 'min',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'min')[0])
            }

            if (!checkIfExist('key', 'max')) {
                values.push({
                    key: 'max',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'max')[0])
            }

            if (!checkIfExist('key', 'decimal')) {
                values.push({
                    key: 'decimal',
                    value: [decimalMinValue],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'decimal')[0])
            }
        } else if (dataType.id === 6) {
            if (!checkIfExist('key', 'min_value')) {
                values.push({
                    key: 'min_value',
                    value: ['0'],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'min_value')[0])
            }

            if (!checkIfExist('key', 'max_value')) {
                values.push({
                    key: 'max_value',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'max_value')[0])
            }

            if (!checkIfExist('key', 'limiter')) {
                values.push({
                    key: 'limiter',
                    value: ['1'],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'limiter')[0])
            }

            if (!checkIfExist('key', 'left')) {
                values.push({
                    key: 'left',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'left')[0])
            }

            if (!checkIfExist('key', 'right')) {
                values.push({
                    key: 'right',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'right')[0])
            }
        } else if (
            dataType.id === 15 ||
            dataType.id === 18 ||
            dataType.id === 19 ||
            dataType.id === 16 ||
            dataType.id === 17 ||
            dataType.id === 29
        ) {
            if (!checkIfExist('key', 'ext')) {
                values.push({
                    key: 'ext',
                    value: [''],
                })
            } else {
                values.push(unitValue.filter((obj) => obj.key === 'ext')[0])
            }
        }
        return values
    }

    //  Todo: Once we get the proper data group for the float-limit, interger-limit and date will remove this function
    const manipulateDataGroup = (dataObj) => {
        let copyofObj = { ...dataObj }
        copyofObj.data_type_group = 'Single'
        return copyofObj
    }

    // this function will destructure the response for our component for the attribute option
    const attibuteDataProcessor = (data, dataTypeSet) => {
        let destructuredData = []
        data.forEach((obj) => {
            destructuredData.push({
                id: obj.id,
                optionType: getDataTypeName(obj.datatype.id),
                option_Name: obj.name,
                is_serverData: true,
                is_attribute_option_edited: false,
                is_AttributeFieldEmpty: false,
                attributes: {
                    name: obj.name,
                    mandatory: obj.mandatory,
                    sensible: obj.sensible,
                    revisable: obj.revisable,
                    is_static: obj.is_static,
                    unique: obj.unique,
                    datatype: obj.datatype.id === 5 ? manipulateDataGroup(obj.datatype) : obj.datatype,
                    hide_to_customer: obj.hide_to_customer,
                    varient_specific: obj.varient_specific,
                    // TODO : static for now, will enable once we get is_variant_attribute preference from server
                    is_variant_attribute: obj.is_variant_attribute,
                    order_specific: obj.order_specific,
                    comparable: obj.comparable,
                    help_text: obj.help_text,
                    placeholder: obj.placeholder,
                    attribute_tag: obj.attribute_tag,
                    attribute_unit_value: checkForUnitValue(
                        obj.datatype.attribute_unit_value,
                        obj.datatype,
                        dataTypeSet
                    ),
                    attribute_type: obj.attribute_type,
                    product_attribute_group: obj.product_attribute_group,
                },
                attribute_displayname: obj.display_name,
                // Newly added for enabling and disabling the suffix and prefix field, checking if it has prefix and suffix fields
                attributeValuePrefix: checkPrefixSuffixValueandFields(
                    obj.datatype.attribute_unit_value,
                    'prefix_displayname'
                ),
                attributeValueSuffix: checkPrefixSuffixValueandFields(
                    obj.datatype.attribute_unit_value,
                    'suffix_displayname'
                ),
                isNonEditableValueEdited: false,
                maxLengthForName: 100,
            })
        })
        return destructuredData
    }

    const getAttributeID = (attrinuteUnit, attributeOption, optionValueid) => {
        let defaultValue = attrinuteUnit.filter(({ key }) => key === 'default_selection')[0]?.value
        let defaultValueId = []
        if (defaultValue) {
            defaultValue.forEach((defvalue) => {
                attributeOption.forEach((obj) => {
                    if (obj.option_name === defvalue) {
                        defaultValueId.push(obj.id)
                    }
                })
            })
        }
        // Filtering id, before sending. only when we get mutiple id.
        let filteredID = defaultValueId.filter((value) => value === optionValueid)
        if (filteredID.length == 0) {
            return ['']
        }
        return filteredID
    }
    // This function will return attribute value of datagroupsingle
    const getNonEditableAttributeValue = (data_type_group, attributeID, attributeNonValuesRespone, dataType) => {
        let nonEditableAttribValue = ''
        if (attributeNonValuesRespone && attributeNonValuesRespone.length > 0) {
            if (data_type_group !== 'Optional' && data_type_group !== 'Upload' && attributeID) {
                if (
                    dataType === 12 &&
                    attributeNonValuesRespone.filter(({ attribute }) => attribute == attributeID)[0]
                        ?.attribute_value !== ''
                ) {
                    let response = attributeNonValuesRespone.filter(({ attribute }) => attribute == attributeID)[0]
                        ?.attribute_value
                    let phoneNumberValue = response
                        ? response?.split('-')
                        : attributeNonValuesRespone
                              .filter(({ attribute }) => attribute == attributeID)[0]
                              ?.attribute_value_encrypt?.split('-')

                    nonEditableAttribValue = phoneNumberValue[1]
                    setPhoneCode(phoneNumberValue[0])
                } else {
                    nonEditableAttribValue = attributeNonValuesRespone.filter(
                        ({ attribute }) => attribute == attributeID
                    )[0]?.attribute_value
                }
                console.log(
                    'non editable value from the server...',
                    attributeNonValuesRespone.filter(({ attribute }) => attribute == attributeID)[0]?.attribute_value
                )
            } else if (data_type_group === 'Url' && attributeID) {
                nonEditableAttribValue = attributeNonValuesRespone.filter(
                    ({ attribute }) => attribute == attributeID
                )[0]?.attribute_value
            } else if (data_type_group === 'Upload' && attributeID) {
                nonEditableAttribValue = attributeNonValuesRespone.filter(
                    ({ attribute }) => attribute == attributeID
                )[0]?.attribute_value
            } else {
                nonEditableAttribValue = false
                attributeNonValuesRespone.forEach((nonEditableValue) => {
                    if (nonEditableValue.attribute_option.length > 0) {
                        if (
                            nonEditableValue.attribute_option.filter((nonattribid) => nonattribid === attributeID)[0] ==
                            undefined
                        ) {
                            return nonEditableAttribValue
                        } else {
                            nonEditableAttribValue = true
                        }
                    }
                })
            }
        }
        return nonEditableAttribValue
    }
    //  The processor is used to process attribute option values received from the server, for the development purpose
    const attributeOptionValueDataProcessor = (
        optionValueResponse,
        attributePostResponse,
        nonEditableValueResponse,
        attributeId
    ) => {
        let productAttribValues = optionValueResponse.map((obj, index) => {
            return obj &&
                obj.data &&
                obj.data.response_body &&
                obj.data.response_body.attribute_options.length > 0 &&
                obj.status === 200
                ? {
                      attributeName: obj.data.response_body.attribute_id
                          ? attributePostResponse.filter(
                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                            )[0].option_Name
                          : attributePostResponse[index].option_Name,
                      attributeID: obj.data.response_body.attribute_id
                          ? attributePostResponse.filter(
                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                            )[0].id
                          : attributePostResponse[index].id,
                      attribute_options:
                          obj.data.response_body.attribute_options.length > 0
                              ? obj.data.response_body.attribute_options.map(({ option_name, id, display_name }) => {
                                    return {
                                        id: id,
                                        attribute: attributePostResponse.filter(
                                            (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                        )[0]?.option_Name,
                                        option_name: option_name,
                                        isInValidField: false,
                                        isAttributeOptionValueEdited: false,
                                        isAttributeOptionValueFieldEmpty: false,
                                        defaultValue: getAttributeID(
                                            attributePostResponse.filter(
                                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                            )[0].attributes.attribute_unit_value,
                                            obj.data.response_body.attribute_options,
                                            id
                                        ),
                                        defaultValueType: getDefaultValueControlType(
                                            attributePostResponse.filter(
                                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                            )[0].attributes.datatype.id
                                        ),
                                        selectedNonEditableValue: getNonEditableAttributeValue(
                                            attributePostResponse.filter(
                                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                            )[0].attributes.datatype.data_type_group,
                                            id,
                                            nonEditableValueResponse,
                                            obj.data.response_body.attribute_id
                                                ? attributePostResponse.filter(
                                                      (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                                  )[0].attributes.datatype.id
                                                : attributePostResponse[index].attributes.datatype.id
                                        ),
                                        maxLengthForOption: 255,
                                        option_displayname: display_name,
                                    }
                                })
                              : [],
                      datatype: obj.data.response_body.attribute_id
                          ? attributePostResponse.filter(
                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                            )[0].attributes.datatype.id
                          : attributePostResponse[index].attributes.datatype.id,
                      dataGroup: obj.data.response_body.attribute_id
                          ? attributePostResponse.filter(
                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                            )[0].attributes.datatype.data_type_group
                          : attributePostResponse[index].attributes.datatype.data_type_group,
                      non_editableattribute_value: obj.data.response_body.attribute_id
                          ? ''
                          : getNonEditableAttributeValue(
                                attributePostResponse[index].attributes.datatype.data_type_group,
                                attributePostResponse[index].id,
                                nonEditableValueResponse,
                                obj.data.response_body.attribute_id
                                    ? attributePostResponse.filter(
                                          (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                                      )[0].attributes.datatype.id
                                    : attributePostResponse[index].attributes.datatype.id
                            ),
                      isNonEditable: obj.data.response_body.attribute_id
                          ? attributePostResponse.filter(
                                (attribRes) => attribRes.id == obj.data.response_body.attribute_id
                            )[0].attributes.is_static
                          : attributePostResponse[index].attributes.is_static,
                  }
                : {
                      // TODO: will remove once, when we get the empty attribute option array for all the datatypes for optionValues
                      attributeName:
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].option_Name,
                      attributeID: attributeId,
                      attribute_options: [],
                      datatype:
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].attributes
                              .datatype.id,
                      dataGroup:
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].attributes
                              .datatype.data_type_group,
                      non_editableattribute_value:
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                          getNonEditableAttributeValue(
                              attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].attributes
                                  .datatype.data_type_group,
                              attributeId,
                              nonEditableValueResponse,
                              attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                                  attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].attributes
                                      .datatype.id
                          ),
                      isNonEditable:
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0] &&
                          attributePostResponse.filter((attribRes) => attribRes.id == attributeId)[0].attributes
                              .is_static,
                  }
        })
        return productAttribValues
    }

    const attributeValueResponseProcessor = (attribResponse, attribValueResponse, nonEditableValues) => {
        let productAttribValues = {
            attributeName: attribResponse.option_Name,
            attributeID: attribResponse.id,
            attribute_options:
                attribValueResponse != undefined
                    ? attribValueResponse.map(({ option_name, id }) => {
                          return {
                              id: id,
                              attribute: attribResponse.option_Name,
                              option_name: option_name,
                              isInValidField: false,
                              isAttributeOptionValueEdited: false,
                              isAttributeOptionValueFieldEmpty: false,
                              defaultValue: getAttributeID(
                                  attribResponse.attributes.attribute_unit_value,
                                  attribValueResponse,
                                  id
                              ),
                              defaultValueType: getDefaultValueControlType(selectedDataType),
                              selectedNonEditableValue: getNonEditableAttributeValue(
                                  attribResponse.attributes.datatype.data_type_group,
                                  id,
                                  nonEditableValues,
                                  attribResponse.attributes.datatype.id
                              ),
                              maxLengthForOption: 255,
                          }
                      })
                    : [],
            datatype: attribResponse.attributes.datatype.id,
            dataGroup: attribResponse.attributes.datatype.data_type_group,
            non_editableattribute_value: getNonEditableAttributeValue(
                attribResponse.attributes.datatype.data_type_group,
                attribResponse.id,
                nonEditableValues,
                attribResponse.attributes.datatype.id
            ),
            isNonEditable: attribResponse.attributes.is_static,
        }
        return productAttribValues
    }

    //  this processor is used to prepare the payload for the attribute unit
    const attributePostUnitValuePostProcessor = (attributeValues) => {
        let processAttributeUnitValues = []
        if (attributeValues.length > 0) {
            attributeValues.forEach((obj) => {
                if (
                    obj.key === 'prefix_displayname' ||
                    obj.key === 'suffix_displayname' ||
                    obj.key === 'default_selection' ||
                    obj.key === 'format' ||
                    obj.key === 'value_regex' ||
                    obj.key === 'value_regex_error_message' ||
                    obj.key === 'decimal' ||
                    obj.key === 'min' ||
                    obj.key === 'max' ||
                    obj.key === 'max_value' ||
                    obj.key === 'min_value' ||
                    obj.key === 'limiter' ||
                    obj.key === 'left' ||
                    obj.key === 'right' ||
                    obj.key === 'ext'
                ) {
                    let compValue = typeof obj.value[0] === 'object' ? '' : obj.value[0]
                    if (compValue !== '') {
                        processAttributeUnitValues.push(obj)
                    }
                }
            })
        } else {
            return processAttributeUnitValues
        }
        return processAttributeUnitValues
    }

    const getNonEditableAttributeValuesFromServer = () => {
        setIsAttributeLoading(true)
        MarketplaceServices.findAll(
            atttriuteNonEditableValuesAPI,
            {
                'attribute-id': attributeId,
            },
            false
        )

            .then((response) => {
                console.log(
                    'Server response from getNonEditableAttributeValuesFromServer ',
                    atttriuteNonEditableValuesAPI,
                    response.data.response_body
                )
                setAttributeNonEditableValues(response.data.response_body.data[0])
                // disabling spinner
                setIsLoading(false)
                getProductsAttributesfromServer(response.data.response_body.data[0])
            })
            .catch((error) => {
                console.log(
                    'error Server response from getNonEditableAttributeValuesFromServer ',
                    atttriuteNonEditableValuesAPI,
                    error
                )
                MarketplaceToaster.showToast(error.response)
                if (error.response.statusText === 'Not Found') {
                    setAttributeNonEditableValues([])
                }
                getProductsAttributesfromServer([])
                // disabling spinner
                setIsLoading(false)
            })
    }

    const getProductsAttributesfromServer = (nonEditableValueResponse) => {
        MarketplaceServices.findAllWithoutPage(
            attributesAPI,
            {
                'belongs-to': type,
                'belonging-id': revisionId,
                'page-limit': 1000,
                attribute_id: attributeId,
            },
            false
        )
            .then((response) => {
                // setIsLoading(false)
                console.log(
                    'Server response from getProductsAttributesfromServer API is',
                    attributesAPI,
                    response.data.response_body
                )
                if (
                    response.data.response_body.attributes_data &&
                    response.data.response_body.attributes_data.length > 0
                ) {
                    let allAttributes = []
                    response.data.response_body.attributes_data.forEach((obj) => {
                        obj.attributes.forEach((attribute) => {
                            allAttributes.push(attribute)
                        })
                    })
                    setProductAttributes(attibuteDataProcessor(allAttributes, true))
                    if (attributeId != undefined) {
                        getProductAttributeOptionValuesfromServer(
                            attibuteDataProcessor(allAttributes, true),
                            nonEditableValueResponse
                        )
                    }
                } else {
                    setIsAttributeLoading(false)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                setIsAttributeLoading(false)
            })
    }

    const getProductAttributeOptionValuesfromServer = async (prodAttributes, nonEditableValueResponse) => {
        const responses = []
        await MarketplaceServices.findAll(
            attributeOptionAPI,
            {
                'attribute-id': attributeId,
            },
            false
        )
            .then((response) => {
                console.log(
                    'Server Response from getProductAttributeOptionValuesfromServer is',
                    attributeOptionAPI,
                    response.data.response_body
                )
                responses.push(response)
            })
            .catch((error) => {
                console.log(
                    'Server error response from getProductAttributeOptionValuesfromServer is',
                    attributeOptionAPI,
                    error
                )
                MarketplaceServices.showToast(error.response)
                responses.push(error.response)
            })
        setProductAttribOptionValueResponse(responses)
        setProductAttribOptionValues(
            attributeOptionValueDataProcessor(responses, prodAttributes, nonEditableValueResponse, attributeId)
        )
        setIsAttributeLoading(false)
        setIsLoading(false)
    }

    useEffect(() => {
        if (revisionId && isEditable && attributeId) {
            getNonEditableAttributeValuesFromServer()
        }
    }, [revisionId, attributeId])

    useEffect(() => {
        setAttributeDataType(attribDataTypeResponse)
    }, [type])

    useEffect(() => {
        if (productAttributes.length > 0) {
            if (productAttributes.filter(({ id }) => id === attributeId)[0]) {
                let dataType = productAttributes.filter(({ id }) => id === attributeId)[0]
                let extension = attributeDataType.filter(({ id }) => id === dataType.attributes.datatype.id)[0]
                    .extension
                let fileType = attributeDataType.filter(({ id }) => id === dataType.attributes.datatype.id)[0].file_type
                if (fileType) {
                    setAttributeFileType(fileType)
                }
                if (extension) {
                    setAttributeExtension(extension)
                }
                setSelectedOption(productAttributes.filter(({ id }) => id === attributeId)[0].optionType)
                setSelectedDataType(productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype.id)

                setEditedOptionValueName(productAttributes.filter(({ id }) => id === attributeId)[0].option_Name)

                setSelectedRegEx(productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype.regex)

                setRegExExceptionMessage(
                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype
                        .regex_exception_message
                )
                setDataTypeGroup(
                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype.data_type_group
                )

                setMinimumNoOfFields(
                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype
                        .minimum_number_values
                )

                setMaximumNoOfFields(
                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype
                        .maximum_number_values
                )
            }
        }
    }, [productAttributes])

    return (
        <div>
            <StoreModal
                isVisible={isAttributionCreationModalVisible}
                okButtonText={null}
                cancelButtonText={null}
                width={1050}
                hideCloseButton={false}
                isSpin={false}
                removePadding={true}
                cancelCallback={() => closeAttributeCreationModal()}>
                <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                    {isAddOptionValuesFormEnabled && (
                        <ProductAddOptionValues
                            closeAddOptionValuesForm={closeAttributeCreationModal}
                            openChooseOptionWindow={openChooseOptionWindow}
                            selectedOption={selectedOption}
                            setProductMenus={addAttribute}
                            prodMenu={[]}
                            isEditting={isEditting}
                            editedOption={undefined}
                            setIsEditting={setIsEditting}
                            selectedDataType={selectedDataType}
                            prodAttrib={productAttributes}
                            prodValues={[]}
                            editedOptionValueName={editedOptionValueName}
                            selectedRegEx={selectedRegEx}
                            regExExceptionMessage={regExExceptionMessage}
                            dataGroup={dataTypeGroup}
                            minimumNoOfFields={minimumNoOfFields}
                            maximumNoOfFields={maximumNoOfFields}
                            attribType={attributeTypes}
                            selectedAttributeType={selectedAttributeType}
                            setSelectedAttributeType={setSelectedAttributeType}
                            attributeUnit={attributeUnit}
                            attributeTagData={[]}
                            segementId={segmentId}
                            attributeNonEditableValues={attributeNonEditableValues}
                            setAttributeNonEditableValues={setAttributeNonEditableValues}
                            setProductAttribOptionValues={setProductAttribOptionValues}
                            attributeExtension={attributeExtension}
                            attributeFileType={attributeFileType}
                            type={type}
                            attributeGroupData={attributeGroupData}
                            defaultLngsCode={defaultLngsCode}
                            setCalculativeExpression={setCalculativeExpression}
                            setCalculativeExpressionEdit={setCalculativeExpressionEdit}
                            calculativeExpression={calculativeExpression}
                            calculativeExpressionEdit={calculativeExpressionEdit}
                            revisionId={revisionId}
                            attibuteDataProcessor={attibuteDataProcessor}
                            tabId={tabId}
                            isCore={isCore}
                            phoneCode={phoneCode}
                            setPhoneCode={setPhoneCode}
                            choices={Choices}
                            attributeNameAlreadyExist={attributeNameAlreadyExist}
                            getNonEditableAttributeValuesFromServer={
                                getNonEditableAttributeValuesFromServer
                            }></ProductAddOptionValues>
                    )}
                </Spin>
                {!isAddOptionsButtonEnabled && (
                    <ProductChooseOptions
                        closeChooseOptionWindow={closeChooseOptionWindow}
                        openAddOptionValuesForm={openAddOptionValuesForm}
                        closeAttributeCreationModal={closeAttributeCreationModal}
                        attributeDataType={attributeDataType}
                        type={type}
                        Choices={Choices}></ProductChooseOptions>
                )}
            </StoreModal>
            {productTemplateRevisionStatus &&
            parseInt(productTemplateRevisionStatus) === 1 &&
            !isEditable &&
            permissionValue &&
            permissionValue.includes('UI-create-edit-product-template') ? (
                <div
                    className={`flex flex-row w-max border-[1px] border-[#D9D9D9] rounded-md py-2 cursor-pointer hover:border-[#fb8500] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pr-5' : '!pl-5'}  ${page === 'add_variant' ? 'app-btn-primary !text-white' : 'text-regal-blue'}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        openChooseOptionWindow()
                    }}>
                    {/* <img src={createJourneyIcon} width={14} height={14} /> */}
                    <PlusOutlined className='font-semibold'></PlusOutlined>
                    <p
                        className={`font-medium text-sm  mx-2`}>
                        {Choices === 'Variant'
                            ? t('products:add_variant_attributes')
                            : t('attributes:add_more_attributes')}
                    </p>
                </div>
            ) : isAttributeLoading && isEditable ? (
                <div className='mt-3 ml-3'>
                    <Skeleton
                        active
                        paragraph={{
                            rows: 6,
                        }}
                        className='py-3 px-3'></Skeleton>
                </div>
            ) : (
                <>
                    {attributeId &&
                    isEditable &&
                    selectedDataType != '' &&
                    productAttributes.filter(({ id }) => id === attributeId).length > 0 ? (
                        <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                            <ProductAddOptionValues
                                closeAddOptionValuesForm={closeAttributeOptionValueModal}
                                openChooseOptionWindow={openChooseOptionWindow}
                                selectedOption={productAttributes.filter(({ id }) => id === attributeId)[0].optionType} //filter
                                setProductMenus={addAttribute} // a
                                prodMenu={productAttributes} // a
                                isEditting={true} // a
                                editedOption={attributeId} // geting from p
                                setIsEditting={setIsEditting} // a
                                selectedDataType={
                                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype.id
                                } //filter
                                isSeverData={true} // a
                                prodAttrib={productAttributes} // a
                                prodValues={productAttribOptionValues} // a
                                editedOptionValueName={
                                    productAttributes.filter(({ id }) => id === attributeId)[0].option_Name
                                } // filter
                                selectedRegEx={
                                    selectedDataType == 14 || selectedDataType == 12 || selectedDataType == 10
                                        ? attribDataTypeResponse.filter(({ id }) => id === selectedDataType)[0].regex
                                        : productAttributes.filter(({ id }) => id === attributeId)[0].attributes
                                              .datatype.regex
                                } // filter
                                regExExceptionMessage={
                                    selectedDataType == 12 || selectedDataType == 10
                                        ? attribDataTypeResponse.filter(({ id }) => id === selectedDataType)[0]
                                              .regex_exception_message
                                        : productAttributes.filter(({ id }) => id === attributeId)[0].attributes
                                              .datatype.regex_exception_message
                                } // filter
                                dataGroup={
                                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype
                                        .data_type_group
                                } // filter
                                minimumNoOfFields={
                                    productAttributes.filter(({ id }) => id === attributeId)[0].attributes.datatype
                                        .minimum_number_values
                                } // filter
                                maximumNoOfFields={
                                    attribDataTypeResponse.filter(({ id }) => id === selectedDataType)[0]
                                        .maximum_number_values
                                } // filter
                                attribType={
                                    attribDataTypeResponse.filter(({ id }) => id === selectedDataType)[0].attribute_type
                                } // ?
                                selectedAttributeType={productAttributes.filter(({ id }) => id === attributeId)[0]?.attributes?.attribute_type}
                                setSelectedAttributeType={setSelectedAttributeType}
                                attributeUnit={attributeUnit} // ?
                                attributeTagData={[]} // ?
                                segementId={segmentId} // ?
                                attributeNonEditableValues={
                                    attributeNonEditableValues // a
                                }
                                setAttributeNonEditableValues={
                                    setAttributeNonEditableValues // a
                                }
                                setProductAttribOptionValues={
                                    setProductAttribOptionValues // a
                                }
                                attributeExtension={attributeExtension} // ?
                                attributeFileType={attributeFileType} // ?
                                type={type} // geting from p
                                attributeGroupData={attributeGroupData} // geting from p
                                defaultLngsCode={defaultLngsCode} // geting from p
                                setCalculativeExpression={setCalculativeExpression}
                                setCalculativeExpressionEdit={setCalculativeExpressionEdit}
                                calculativeExpression={calculativeExpression}
                                calculativeExpressionEdit={calculativeExpressionEdit}
                                revisionId={revisionId}
                                attibuteDataProcessor={attibuteDataProcessor}
                                tabId={tabId}
                                isCore={isCore}
                                phoneCode={phoneCode}
                                setPhoneCode={setPhoneCode}
                                choices={Choices}
                                attributeNameAlreadyExist={attributeNameAlreadyExist}
                                getNonEditableAttributeValuesFromServer={
                                    getNonEditableAttributeValuesFromServer
                                }></ProductAddOptionValues>
                        </Spin>
                    ) : (
                        ''
                    )}
                </>
            )}
        </div>
    )
}

export default ProductAddOptions
