import { ActionTypes } from '../constants/ActionTypes'

const { RDX_EDIT_SEGMENT_INFO } = ActionTypes

// selectedVendors is reducer here it will check the actionType what we are getting
// if it matches it will return the payload to action methods
export const ReducerSegment = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_EDIT_SEGMENT_INFO:
            return { ...state, editSegmentInfo: payload }
        default:
            return state
    }
}
