import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const productVariantsAPI = process.env.REACT_APP_PRODUCT_VARIANT_API
/**
 * !This function is a GET Method to get the generated product variants for a particular product template revision.
 * @param {*} productTemplateRevisionId -> To get the generated variants based on the product_template_revision_id.
 */
//!REST --> GET Method.
const useGetProductVariants = ({ productTemplateRevisionId }) => {
    const getProductVariantAttributes = async () => {
        const params = {
            'product-revision-id': parseInt(productTemplateRevisionId),
        }
        const res = await MarketplaceServices.findAllWithoutPage(productVariantsAPI, params)
        return res?.data?.response_body?.data?.[0]
    }
    return useQuery({
        queryKey: ['variants', productTemplateRevisionId],
        queryFn: getProductVariantAttributes,
        refetchOnWindowFocus: false,
        enabled: !!productTemplateRevisionId,
        retry: false,
    })
}
export default useGetProductVariants
