import TranslateVersionStatus from '../pages-2.0/version-status/TranslateVersionStatus'

const RevisionStatus = {
    1: [
        {
            position: 'right',
            id: 2,
            value: <TranslateVersionStatus translate={'product_types:submit_for_approval'} />,
        },
        {
            position: 'left',
            id: 1,
            value: <TranslateVersionStatus translate={'product_types:draft'} />,
        },
    ],
    // 2: [{
    //     position: "right",
    //     id: 3,
    //     value: "Approve"
    // }, {
    //     position: "right",
    //     id: 4,
    //     value: "Reject"
    // }, {
    //     position: "left",
    //     id: 2,
    //     value: "Awaiting Approval"
    // }],
    2: [
        {
            position: 'right',
            id: 2,
            value: '',
        },
        {
            position: 'left',
            id: 9,
            value: <TranslateVersionStatus translate={'common:submitted_for_approval'} />,
        },
    ],
    3: [
        {
            position: 'right',
            id: 5,
            value: <TranslateVersionStatus translate={'product_types:deprecated'} />,
        },
        {
            position: 'left',
            id: 3,
            value: <TranslateVersionStatus translate={'common:approved'} />,
        },
    ],
    4: [
        {
            position: 'left',
            id: 4,
            value: <TranslateVersionStatus translate={'product_types:rejected'} />,
        },
    ],
    5: [
        {
            position: 'right',
            id: 6,
            value: <TranslateVersionStatus translate={'common:retire'} />,
        },
        {
            position: 'left',
            id: 5,
            value: <TranslateVersionStatus translate={'product_types:deprecated'} />,
        },
    ],
    6: [
        {
            position: 'left',
            id: 6,
            value: <TranslateVersionStatus translate={'product_types:retired'} />,
        },
    ],
    7: [
        {
            position: 'right',
            id: 3,
            value: <TranslateVersionStatus translate={'common:publish'} />,
        },
        {
            position: 'left',
            id: 1,
            value: <TranslateVersionStatus translate={'product_types:draft'} />,
        },
    ],
}
export default RevisionStatus
