import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import { useLocation } from 'react-router-dom'
const segmentAPI = process.env.REACT_APP_SEGMENT_API

const useGetAttributegroups = () => {
    const search = useLocation().search
    let page = new URLSearchParams(search).get('page')
    let limit = new URLSearchParams(search).get('limit')
    const getAllAttributeGroups = async () => {
        const res = await MarketplaceServices.findByPage(segmentAPI, null, page, limit, true)
        return {
            TotalCount: res.data.response_body.count,
            Groups: res.data.response_body.data.product_attribute_groups,
        }
    }
    return useQuery({
        queryKey: ['attribute-groups', page, limit],
        queryFn: getAllAttributeGroups,
    })
}

export default useGetAttributegroups
