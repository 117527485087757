import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_MAKE_API_CALL,
    RDX_API_SUCCESS_RESPONSE,
    RDX_MAKE_API_CALL2,
    RDX_CALL_PUBLISHING_API,
    RDX_SERVICE_PRODUCT_TIME_VALIDATION,
    RDX_ATTRIBUTE_DETAILS_UPDATED,
    RDX_ATTRIBUTE_DETAILS_TRANSLATED,
} = ActionTypes

export const fnMakeAPICall = (data) => {
    return {
        type: RDX_MAKE_API_CALL,
        payload: data,
    }
}

export const fnGetAPICallSuccessResponse = (data) => {
    return {
        type: RDX_API_SUCCESS_RESPONSE,
        payload: data,
    }
}

export const fnMakeAPICall2 = (data) => {
    return {
        type: RDX_MAKE_API_CALL2,
        payload: data,
    }
}

export const fnMakePublishingAPICall = (data) => {
    return {
        type: RDX_CALL_PUBLISHING_API,
        payload: data,
    }
}

export const fnServiceProductTimeValidation = (data) => {
    return {
        type: RDX_SERVICE_PRODUCT_TIME_VALIDATION,
        payload: data,
    }
}

export const fnAttributeDetailsUpdated = (data) => {
    return {
        type: RDX_ATTRIBUTE_DETAILS_UPDATED,
        payload: data,
    }
}
export const fnAttributeDetailsTranslated = (data) => {
    return {
        type: RDX_ATTRIBUTE_DETAILS_TRANSLATED,
        payload: data,
    }
}
