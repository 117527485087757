import React from 'react'

const ListAltIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M1.875 1.09375H3.84375C4.5625 1.09375 5.15625 1.6875 5.1875 2.40625V4.40625C5.1875 5.15625 4.5625 5.75 3.84375 5.75H1.875C1.125 5.75 0.53125 5.125 0.53125 4.40625V2.4375C0.53125 1.6875 1.15625 1.09375 1.875 1.09375ZM1.96875 4.3125H3.75V2.5H1.96875V4.3125ZM18.75 4.125H8.03125C7.65625 4.125 7.3125 3.8125 7.3125 3.40625C7.3125 3 7.65625 2.6875 8.03125 2.6875H18.75C19.1562 2.6875 19.4688 3 19.4688 3.40625C19.4688 3.8125 19.125 4.125 18.75 4.125ZM3.84375 7.5H1.875C1.15625 7.5 0.53125 8.09375 0.53125 8.84375V10.8125C0.53125 11.5312 1.125 12.1562 1.875 12.1562H3.84375C4.5625 12.1562 5.1875 11.5625 5.1875 10.8125V8.84375C5.15625 8.09375 4.5625 7.5 3.84375 7.5ZM3.75 10.7187H1.96875V8.90625H3.75V10.7187ZM8.03125 9.125H18.75C19.125 9.125 19.4688 9.46875 19.4688 9.84375C19.4688 10.2188 19.125 10.5313 18.75 10.5313H8.03125C7.625 10.5313 7.3125 10.25 7.3125 9.84375C7.3125 9.4375 7.65625 9.125 8.03125 9.125ZM3.84375 14.2813H1.875C1.15625 14.2813 0.53125 14.875 0.53125 15.625V17.5938C0.53125 18.3125 1.125 18.9375 1.875 18.9375H3.84375C4.5625 18.9375 5.1875 18.3438 5.1875 17.5938V15.625C5.15625 14.875 4.5625 14.2813 3.84375 14.2813ZM3.75 17.5H1.96875V15.6875H3.75V17.5ZM8.03125 15.875H18.75C19.125 15.875 19.4688 16.1875 19.4688 16.5937C19.4688 17 19.125 17.3125 18.75 17.3125H8.03125C7.625 17.3125 7.3125 17 7.3125 16.5937C7.3125 16.1875 7.65625 15.875 8.03125 15.875Z'
                fill='#8899A8'
            />
        </svg>
    )
}

export default ListAltIcon
