import { ActionTypes } from '../constants/ActionTypes'

const { RDX_STORE_PRODUCT_TYPES, RDX_PRODUCT_TYPE_DETAILS } = ActionTypes
// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods
export const ReducerProductTypes = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_STORE_PRODUCT_TYPES:
            return { ...state, storeProductTypes: payload }
        default:
            return state
    }
}

const initialState = {
    productTypeDetails: [
        {
            productTypeName: '',
            productTypeDescription: '',
        },
        {
            productTypeName: '',
            productTypeDescription: '',
        },
    ],
}
export const ReducerProductDetails = (state = initialState, { type, payload }) => {
    switch (type) {
        case RDX_PRODUCT_TYPE_DETAILS:
            return { ...state, productTypeDetails: payload }
        default:
            return state
    }
}
