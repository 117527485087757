// As per suggestion, from sushil and logo, we are maintaining this structure.
// Because backend is not providing the perference names for displaying.
// Once it is provided, we will remove this file from repo.

// Note: User should change only the SettingName. (isEnable and isMandtory) to true or false, if required.
// User should not change the type unless there is change in the backend key.

import TranslateAttributeStrings from '../pages/product_new_options/TranslateAttributeStrings'
const attibutePrefernce = [
    {
        type: 'mandatory',
        settingsName: <TranslateAttributeStrings translate={'attributes:mandatory'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:mandatory_when_product_published'} />,
    },
    {
        type: 'sensible',
        settingsName: <TranslateAttributeStrings translate={'attributes:sensitive'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:sensitive_field_encrypt_storage_display'} />,
    },
    {
        type: 'revisable',
        settingsName: <TranslateAttributeStrings translate={'attributes:allow_version'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:allow_version_preference_description'} />,
    },
    {
        type: 'is_static',
        settingsName: <TranslateAttributeStrings translate={'attributes:static'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:static_value'} />,
    },
    {
        type: 'unique',
        settingsName: <TranslateAttributeStrings translate={'attributes:unique'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:unique_preference_description'} />,
    },
    {
        type: 'hide_to_customer',
        settingsName: <TranslateAttributeStrings translate={'attributes:hide_to_customer'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:hide_to_customer_pref_desc'} />,
    },
    {
        type: 'varient_specific',
        settingsName: <TranslateAttributeStrings translate={'attributes:variant_specific'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:variant_specific_pref_desc'} />,
    },
    {
        type: 'is_variant_attribute',
        settingsName: <TranslateAttributeStrings translate={'attributes:mark_as_variant'} />,
        isEnable: false,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:value_create_variant'} />,
    },
    {
        type: 'order_specific',
        settingsName: <TranslateAttributeStrings translate={'attributes:order_specific'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:order_specific_pref_desc'} />,
    },
    {
        type: 'attribute_type',
        settingsName: <TranslateAttributeStrings translate={'attributes:attribute_type'} />,
        isEnable: true,
        isMandtory: true,
        description: <TranslateAttributeStrings translate={'attributes:attr_type_pref_desc'} />,
    },
    {
        type: 'comparable',
        settingsName: <TranslateAttributeStrings translate={'attributes:comparable'} />,
        isEnable: true,
        isMandtory: false,
        description: <TranslateAttributeStrings translate={'attributes:comparable_pref_desc'} />,
    },
]

export default attibutePrefernce
