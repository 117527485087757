//! Import libraries
import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Input, Layout, Row, Skeleton, Spin, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//! Import user defined components
import { SearchOutlined } from '@ant-design/icons'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
//! Import CSS libraries
import useGetProductTemplateList from '../../hooks/useGetProductTemplateList'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { Empty1SVG, EmptySVG } from '../../constants/media'

//! Destructure the components
const { Content } = Layout

//! Get all required details from .env file
const categoryAPI = process.env.REACT_APP_CATEGORY_API
const productTemplateListAPI = process.env.REACT_APP_PRODUCT_API
const removeProductTemplateMapping = process.env.REACT_APP_CATEGORY_REMOVE_PRODUCT_TEMPLATE_MAPPING_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

const { Text } = Typography
const { Search } = Input

const AddProductTemplate = ({ getSelectedCategoryData }) => {
    const { t } = useTranslation()
    const [addProductTemplateModalVisible, setAddProductTemplateModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [removeProductTemplateId, setRemoveProductTemplateId] = useState()
    const [alreadyMappedProductTemplatesAPIData, setAlreadyMappedProductTemplatesAPIData] = useState([])
    const [alreadyMappedProductTemplates, setAlreadyMappedProductTemplates] = useState([])
    const [productTemplatesForPutCall, setProductTemplatesForPutCall] = useState([])
    const [langDirection, setLangDirection] = useState('ltr')
    const permissionsData = util.getPermissionData() || []
    const [editPermissions, setEditPermissions] = useState(
        permissionsData && permissionsData.includes('UI-create-edit-category') ? false : true
    )
    const [searchKey, setSearchKey] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const { loading, isNetworkError, apiData, apiProcessedData, searchKeyTriggered, setSearchKeyTriggered } =
        useGetProductTemplateList(searchKey)
    const [isAllProductTemplatesLoading, setIsAllProductTemplatesLoading] = useState(true)
    const [isAllProductTemplatesNetworkError, setIsAllProductTemplatesNetworkError] = useState(false)
    const [allProductTemplatesData, setAllProductTemplatesData] = useState([])
    const [modalTableData, setModalTableData] = useState([])
    const [count, setCount] = useState(0)

    const allProductTemplatesTableColumns = [
        {
            title: <Content className=''>{t('product_template:product_template')}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '70%',
            render: (text, record) => {
                return (
                    <Text
                        className='text-brandGray1 !w-[300px]'
                        ellipsis={{
                            tooltip: {
                                title: record?.product_template?.display_name,
                                mouseLeaveDelay: 0,
                                mouseEnterDelay: 0.5,
                            },
                        }}>
                        {record?.product_template?.display_name || t('common:not_applicable')}
                    </Text>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    // <p
                    //     className='app-btn-link'
                    //     disabled={productTemplatesForPutCall?.includes(record?.product_template?.id)}
                    //     onClick={() => {
                    //         if (!productTemplatesForPutCall?.includes(record?.product_template?.id)) {
                    //             setCount(count + 1)
                    //             handleAddNewProducts(record?.product_template?.id)
                    //         }
                    //     }}>
                    //     {t('categories:add_to_category')}
                    // </p>

                    <Content className=''>
                        {productTemplatesForPutCall.length > 0 &&
                        productTemplatesForPutCall.filter((item) => item === record?.product_template?.id).length >
                            0 ? (
                            <p
                                className='text-brandRed font-medium cursor-pointer'
                                onClick={() => {
                                    setCount(count - 1)
                                    handleRemoveAddedProducts(record?.product_template?.id)
                                }}>
                                {t('common:remove')}
                            </p>
                        ) : (
                            <p
                                className='app-btn-link font-medium '
                                onClick={() => {
                                    setCount(count + 1)
                                    handleAddNewProducts(record?.product_template?.id)
                                }}>
                                {t('common:add')}
                            </p>
                        )}
                    </Content>
                )
            },
        },
    ]

    const mappedProductTemplatesTableColumns = [
        {
            title: <Content className=''>{t('product_template:product_template')}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '70%',
            render: (text, record) => {
                return (
                    <Text
                        className='text-brandGray1 !w-[300px]'
                        ellipsis={{
                            tooltip: {
                                title: record?.product_template?.display_name,
                                mouseLeaveDelay: 0,
                                mouseEnterDelay: 0.5,
                            },
                        }}>
                        {record?.product_template?.display_name || t('common:not_applicable')}
                    </Text>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    record && (
                        <div
                            onClick={() => {
                                deleteModal(record?.product_template?.id)
                            }}
                            className=' font-medium !text-brandRed cursor-pointer'>
                            {t('common:remove')}
                        </div>
                    )
                )
            },
        },
    ]

    //! onclick function for add to category in table
    const handleAddNewProducts = (productTemplateId) => {
        setProductTemplatesForPutCall([...productTemplatesForPutCall, productTemplateId])
    }
    const handleRemoveAddedProducts = (productTemplateId) => {
        setProductTemplatesForPutCall(
            productTemplatesForPutCall?.filter((element) => !(parseInt(element) === parseInt(productTemplateId)))
        )
    }

    //Get all product template details
    const getAllProductTemplatesData = () => {
        setIsAllProductTemplatesLoading(true)
        MarketplaceServices.findAll(productTemplateListAPI, {}, true)
            .then(function (response) {
                console.log(
                    'Server response from findAllProductTemplateLists Hook is',
                    productTemplateListAPI,
                    response.data.response_body
                )
                setAllProductTemplatesData(response?.data?.response_body?.StoreProductRevision)
                setIsAllProductTemplatesLoading(false)
                setIsAllProductTemplatesNetworkError(false)
            })
            .catch(function (error) {
                console.log(
                    'Server Error response from findAllProductTemplateLists hook ',
                    productTemplateListAPI,
                    error.response
                )
                setAllProductTemplatesData([])
                setIsAllProductTemplatesLoading(false)
                setIsAllProductTemplatesNetworkError(true)
            })
    }

    //!Get the mapped product template
    const findAllMappedProductTemplates = () => {
        MarketplaceServices.findAll(categoryAPI, {
            category_id: getSelectedCategoryData && getSelectedCategoryData.id,
        })
            .then(function (response) {
                console.log(
                    'Server response from getMappedProduct template for category API is',
                    categoryAPI,
                    response.data.response_body.category_data.product_template
                )
                setAlreadyMappedProductTemplatesAPIData(response.data.response_body.category_data.product_template)
                setProductTemplatesForPutCall(response.data.response_body.category_data.product_template)
            })
            .catch((error) => {
                console.log('error Server response from onSaveClick API is', categoryAPI, error.response)
            })
    }

    //! edit category data put call
    const onSaveClick = () => {
        setSearchKey('')
        setSearchValue('')
        setIsLoading(true)
        setCount(0)
        MarketplaceServices.update(
            categoryAPI,
            { product_template: productTemplatesForPutCall },
            {
                category_id: getSelectedCategoryData && getSelectedCategoryData.id,
            }
        )
            .then(function (response) {
                console.log(
                    'Server response from onSaveClick API is',
                    categoryAPI,
                    response.data.response_body.product_template
                )
                setAlreadyMappedProductTemplatesAPIData(response.data.response_body.product_template)
                setProductTemplatesForPutCall(response.data.response_body.product_template)
                setAddProductTemplateModalVisible(false)
                MarketplaceToaster.showToast(response)

                setIsLoading(false)
            })
            .catch((error) => {
                console.log('error Server response from onSaveClick API is', categoryAPI, error.response)
                MarketplaceToaster.showToast(error.response)
                setIsLoading(false)
            })
    }

    const removeProductTemplatesMapping = () => {
        setIsLoading(true)
        const removeTemplateArray = [removeProductTemplateId]
        // Creating the data object with ProductTemplateCategory key as required by the API
        const dataObject = {
            product_template: removeTemplateArray,
        }

        MarketplaceServices.remove(removeProductTemplateMapping, dataObject, {
            id: getSelectedCategoryData && getSelectedCategoryData.id,
        })
            .then(function (response) {
                console.log('Server response from updateProductMapping is', response.data)
                setAddProductTemplateModalVisible(false)
                findAllMappedProductTemplates()
                MarketplaceToaster.showToast(response)
                setDeleteModalVisible(!deleteModalVisible)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log('error Server response from removeProductTemplatesMapping is', categoryAPI, error.response)
                MarketplaceToaster.showToast(error.response)
                setDeleteModalVisible(!deleteModalVisible)
                setIsLoading(false)
            })
    }

    //! show delete modal popup
    const deleteModal = (productId) => {
        setDeleteModalVisible(!deleteModalVisible)
        setRemoveProductTemplateId(productId)
    }

    // !discard in modal table
    const handleDiscard = () => {
        setCount(0)
        setAddProductTemplateModalVisible(false)
        setProductTemplatesForPutCall(alreadyMappedProductTemplatesAPIData)
        setSearchKey('')
        setSearchValue('')
    }

    const handleSearchChange = (searchKey) => {
        if (searchKey?.trim()) {
            setSearchValue(searchKey)
            setSearchKey(searchKey)
        }
    }
    const handleInputChange = (event) => {
        const trimmed = String(event.target.value)
        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
        setSearchValue(trimmedUpdate)
        if (event.target.value === '') {
            setSearchKey('')
        }
    }
    const customButton = (
        <Button type='primary' disabled={searchValue?.trim() === '' ? true : false} icon={<SearchOutlined />} />
    )

    useEffect(() => {
        if (alreadyMappedProductTemplatesAPIData && apiProcessedData && allProductTemplatesData) {
            let removeTheProductTemplatesWhichAreAlreadyMapped = apiProcessedData.filter(
                (item) => !alreadyMappedProductTemplatesAPIData.includes(item.key)
            )
            setModalTableData(removeTheProductTemplatesWhichAreAlreadyMapped)
            let filteredData = allProductTemplatesData.filter((item) =>
                alreadyMappedProductTemplatesAPIData.includes(item?.product_template?.id)
            )
            if (filteredData && filteredData.length > 0) {
                setAlreadyMappedProductTemplates(filteredData)
            } else {
                setAlreadyMappedProductTemplates([])
            }
        }
    }, [alreadyMappedProductTemplatesAPIData, allProductTemplatesData, apiProcessedData])
    useEffect(() => {
        findAllMappedProductTemplates()
        getAllProductTemplatesData()
    }, [])
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <Content className='p-3  bg-white '>
            <div className='flex mb-3  items-center justify-between'>
                <p className='font-semibold text-lg'>{t('common:product_templates')}</p>
                <Button
                    disabled={editPermissions}
                    className=' app-btn-secondary'
                    onClick={() => setAddProductTemplateModalVisible(true)}>
                    <PlusOutlined className='!align-[2px] !p-0 !m-0' />
                    {t('product_template:add_product_templates')}
                </Button>
            </div>
            {isAllProductTemplatesLoading ? (
                <Content className=''>
                    <Skeleton
                        active
                        paragraph={{
                            rows: 4,
                        }}
                        className='p-3'
                    />
                </Content>
            ) : isAllProductTemplatesNetworkError ? (
                <Content className='text-center p-3 !bg-white'>
                    <h1 level={5}>{t('common:network_error')}</h1>
                </Content>
            ) : (
                <>
                    {alreadyMappedProductTemplatesAPIData && alreadyMappedProductTemplatesAPIData.length > 0 ? (
                        <Table
                            columns={mappedProductTemplatesTableColumns}
                            dataSource={alreadyMappedProductTemplates}
                            pagination={{
                                pageSize: itemsPerPageFromEnv,
                                showSizeChanger: false,
                                pageSizeOptions: false,
                                hideOnSinglePage: true,
                            }}
                        />
                    ) : (
                        <div className=' mt-5 gap-4 flex flex-col items-center'>
                            <img src={Empty1SVG} alt='empty' />
                            <p className='font-semibold text-sm text-brandGray2'>
                                {t('product_template:product_template_message')}
                            </p>
                            <Button
                                disabled={editPermissions}
                                className=' app-btn-secondary'
                                onClick={() => setAddProductTemplateModalVisible(true)}>
                                <PlusOutlined className='!align-[2px] !p-0 !m-0' />
                                {t('product_template:add_product_templates')}
                            </Button>
                        </div>
                    )}
                </>
            )}
            <Content>
                {addProductTemplateModalVisible && (
                    <StoreModal
                        title={t('categories:add_product_templates')}
                        hideCloseButton={true}
                        isVisible={addProductTemplateModalVisible}
                        width={850}
                        cancelButtonText={t('common:discard')}
                        okButtonText={t('common:save')}
                        cancelCallback={() => handleDiscard()}
                        okCallback={() => onSaveClick()}
                        isOkButtonDisabled={parseInt(count) === 0 ? true : false}
                        isSpin={isLoading}
                        isScroll={true}>
                        {/* <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}> */}
                        <Content className='!flex !justify-end mt-3 mr-3'>
                            <Search
                                placeholder={t('common:please_enter_search_text_here')}
                                onSearch={handleSearchChange}
                                onChange={handleInputChange}
                                value={searchValue}
                                suffix={null}
                                maxLength={searchMaxLength}
                                enterButton={customButton}
                                allowClear
                                className='!justify-end !w-[35%]'
                            />
                        </Content>
                        <Content className='mt-3 mr-3'>
                            {loading ? (
                                <Skeleton
                                    active
                                    paragraph={{
                                        rows: 4,
                                    }}
                                    className='p-3'
                                />
                            ) : isNetworkError ? (
                                <Content className='text-center !mb-2 pt-2 bg-white p-3'>
                                    <h1 level={5}>{t('common:network_error')}</h1>
                                </Content>
                            ) : (
                                <>
                                    {apiProcessedData && apiProcessedData.length > 0 ? (
                                        <Table
                                            columns={allProductTemplatesTableColumns}
                                            dataSource={modalTableData}
                                            pagination={{
                                                pageSize: itemsPerPageFromEnv,
                                                showSizeChanger: false,

                                                hideOnSinglePage: true,
                                                showQuickJumper: true,
                                            }}
                                        />
                                    ) : searchKeyTriggered ? (
                                        <Content className='!text-center !mt-6 font-bold'>
                                            <Text>{t('common:not_able_to_find_searched_details')}</Text>
                                        </Content>
                                    ) : (
                                        <Content className='!text-center !mt-6 font-bold'>
                                            <Text>{t('categories:unable_to_find_a_match')}</Text>
                                        </Content>
                                    )}
                                </>
                            )}
                        </Content>
                        {/* </Spin> */}
                    </StoreModal>
                )}
                <StoreModal
                    title={t('common:warning')}
                    isVisible={deleteModalVisible}
                    okButtonText={t('common:yes')}
                    // cancelButtonText={t('common:cancel')}
                    cancelCallback={() => setDeleteModalVisible(!deleteModalVisible)}
                    okCallback={() => removeProductTemplatesMapping()}
                    isSpin={isLoading}>
                    <p>{t('categories:are_you_sure_you_want_to_remove_the_product_template')}</p>
                </StoreModal>
            </Content>
        </Content>
    )
}
export default AddProductTemplate
