import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ProductAddOptions from '../../pages/product_new_options/ProductAddOptions'
//! Get all required details from .env file

const EmptyAttributeGroup = ({
    productTemplateId,
    productTemplateRevisionId,
    productTemplateStatus,
    productTemplateRevisionStatus,
    Type,
    Choices,
    page,
}) => {
    const [attributePosted, setAttributePosted] = useState(false)
    const byDefaultLanguageCode = useSelector(
        (state) =>
            state.reducerDefaultLanguage &&
            state.reducerDefaultLanguage.defaultLanguage &&
            state.reducerDefaultLanguage.defaultLanguage.language_code
    )
    return (
        <ProductAddOptions
            revisionId={parseInt(productTemplateRevisionId)}
            type={Type}
            Choices={Choices}
            setAttributePosted={setAttributePosted}
            defaultLngsCode={byDefaultLanguageCode}
            productTemplateStatus={productTemplateStatus}
            productTemplateRevisionStatus={productTemplateRevisionStatus}
            page={page}
        />
    )
}

export default EmptyAttributeGroup
