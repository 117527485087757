import { UserOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Input, Layout, Progress, Row, Select, Skeleton, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import validator from 'validator'
import TextRichEditor from '../../components/textRichEditor/TextRichEditor'

import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'

//! Destructure the components

const { Content } = Layout
const { Title } = Typography

const productPublishingDisplayNameAPI = process.env.REACT_APP_PRODUCT_PUBLISHING_DISPLAY_NAMES_API
const productAttributesPostAPI = process.env.REACT_APP_PRODUCT_PUBLISH_ATTRIBUTE_VALUES

// ! input fields properties
const titleMaxLength = process.env.REACT_APP_TITLE_MAX_LENGTH

const AttributeValuesTranslatorModal = ({
    dataJsonForAttributeValue,
    setTranslateModalVisible,
    respectiveId,
    onChangeDisableFields,
    setOnChangeDisableFields,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const displayNameDataJsonForAttributeValue = dataJsonForAttributeValue
    const [loadingSkelton, setLoadingSkelton] = useState(false)
    const [storeLanguages, setStoreLanguages] = useState()
    const [defaultLanguageName, setDefaultLanguageName] = useState()
    const [isLoadingStoreLanguage, setIsLoadingStoreLanguage] = useState(false)
    const [selectedLanguageFromDropDown, setSelectedLanguageFromDropDown] = useState()
    const [allLanguages, setAllLanguages] = useState()
    const [invalidName, setInvalidName] = useState(false)
    const [isInputFieldsValidationModalOpen, setIsInputFieldsValidationModalOpen] = useState(false)
    const [commonDataContainingAllObject, setCommonDataContainingAllObject] = useState()
    const [displayNameApiData, setDisplayNameApiData] = useState([])
    const [textRichDataForDisplayName, setTextRichDataForDisplayName] = useState()
    const [textRichDataForDetails, setTextRichDataForDetails] = useState()
    const [languagesWithInvalidData, setLanguagesWithInvalidData] = useState()
    const [dataForAttributesValueDetailsUpdate, setDataForAttributesValueDetailsUpdate] = useState()
    const [alreadyTranslatedCount, setAlreadyTranslatedCount] = useState(0)
    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    const getStoreLanguageData = () => {
        const StoreLanguageResponse = storeLanguageFromReduxState

        setStoreLanguages(StoreLanguageResponse)
        const defaultLanguageCode =
            StoreLanguageResponse &&
            StoreLanguageResponse.length > 0 &&
            StoreLanguageResponse.filter((element) => element.is_default)[0].language_code

        displayNameDataJsonForAttributeValue &&
            displayNameDataJsonForAttributeValue.length > 0 &&
            getAttributesValueDisplayNameData(StoreLanguageResponse, defaultLanguageCode)

        setDefaultLanguageName(
            StoreLanguageResponse &&
                StoreLanguageResponse.length > 0 &&
                StoreLanguageResponse.filter((element) => element.is_default)
        )

        setAllLanguages(StoreLanguageResponse)
        setSelectedLanguageFromDropDown(defaultLanguageCode)
    }

    const getAttributesValueDisplayNameData = (StoreLanguageResponse, defaultLanguageCode) => {
        MarketplaceServices.findByPage(
            productPublishingDisplayNameAPI,
            {
                'product-id': parseInt(respectiveId),
            },
            1,
            1000,
            false
        )
            .then(function (response) {
                setIsLoadingStoreLanguage(false)
                console.log('edit selected get displayname response : ', response.data.response_body.display_names)
                if (response.data) {
                    var tempArray
                    var displayNameData
                    tempArray = deepCopy(response.data.response_body.display_names)
                    setDisplayNameApiData(tempArray)
                    var dataWithoutDefaultLanguage = tempArray
                    displayNameData =
                        dataWithoutDefaultLanguage &&
                        dataWithoutDefaultLanguage.length > 0 &&
                        dataWithoutDefaultLanguage.filter(
                            (ele) =>
                                parseInt(ele.attribute_id) ===
                                parseInt(
                                    displayNameDataJsonForAttributeValue &&
                                        displayNameDataJsonForAttributeValue.length > 0 &&
                                        displayNameDataJsonForAttributeValue[0].attributeId
                                )
                        )
                    if (displayNameData && displayNameData.length > 0) {
                    } else {
                        displayNameData = []
                    }
                    setAlreadyTranslatedCount(displayNameData.length)
                    var finalObjectWithAllData = []
                    var dataOfDefaultLanguage =
                        response.data.display_names &&
                        response.data.display_names.length > 0 &&
                        response.data.display_names.filter(
                            (ele) =>
                                parseInt(ele.attribute_id) ===
                                parseInt(
                                    displayNameDataJsonForAttributeValue &&
                                        displayNameDataJsonForAttributeValue.length > 0 &&
                                        displayNameDataJsonForAttributeValue[0].attributeId
                                )
                        )
                    setCommonDataContainingAllObject(
                        displayNameData &&
                            displayNameData.length > 0 &&
                            displayNameData.filter(
                                (ele) =>
                                    parseInt(ele.attribute_id) ===
                                    parseInt(
                                        displayNameDataJsonForAttributeValue &&
                                            displayNameDataJsonForAttributeValue.length > 0 &&
                                            displayNameDataJsonForAttributeValue[0].attributeId
                                    )
                            )
                    )
                    for (let i = 0; i < StoreLanguageResponse.length; i++) {
                        let storeLanguageDataIsAvailableInDisplayNameData =
                            displayNameData &&
                            displayNameData.length > 0 &&
                            displayNameData.filter(
                                (ele) => ele.language_code === StoreLanguageResponse[i].language_code
                            )

                        if (
                            storeLanguageDataIsAvailableInDisplayNameData &&
                            storeLanguageDataIsAvailableInDisplayNameData.length > 0
                        ) {
                        } else {
                            finalObjectWithAllData.push({
                                attribute_id: parseInt(
                                    displayNameDataJsonForAttributeValue &&
                                        displayNameDataJsonForAttributeValue[0].attributeId
                                ),
                                language_code: StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                display_value: '',
                                // description: "",
                            })
                        }
                    }
                    if (dataOfDefaultLanguage && dataOfDefaultLanguage.length > 0) {
                        let removeDefaultLangAddedList =
                            finalObjectWithAllData &&
                            finalObjectWithAllData.length > 0 &&
                            finalObjectWithAllData.filter((ele) => ele.language_code !== defaultLanguageCode)
                        setCommonDataContainingAllObject([
                            ...displayNameData,
                            ...dataOfDefaultLanguage,
                            ...removeDefaultLangAddedList,
                        ])
                    } else {
                        setCommonDataContainingAllObject([...displayNameData, ...finalObjectWithAllData])
                    }
                }
                setLoadingSkelton(false)
            })
            .catch(function (error) {
                setIsLoadingStoreLanguage(false)
                console.log('edit selected get displayname error response', error.response)
                setLoadingSkelton(false)
            })
    }

    const attributesValueUpdatePutCall = () => {
        let putBody = {}
        let productAttributeGroupId
        let dataType
        let value
        let attributeId
        if (displayNameDataJsonForAttributeValue && displayNameDataJsonForAttributeValue.length > 0) {
            productAttributeGroupId = displayNameDataJsonForAttributeValue[0].attributeGroupId
            dataType = displayNameDataJsonForAttributeValue[0].optionType
            attributeId = displayNameDataJsonForAttributeValue[0].attributeId
            if (displayNameDataJsonForAttributeValue[0].type === 'textrich') {
                value = textRichDataForDetails
            } else {
                value = dataForAttributesValueDetailsUpdate[0].value
            }
        }

        // Payload for the put call
        const attributePutObject = [
            {
                product_attribute_group_id: parseInt(productAttributeGroupId),
                product_attribute_group_data: [
                    {
                        attribute_id: parseInt(attributeId),
                        data_type: parseInt(dataType),
                        value: value,
                    },
                ],
            },
        ]
        putBody['attribute_data'] = attributePutObject

        MarketplaceServices.update(productAttributesPostAPI, putBody, {
            'product-id': parseInt(respectiveId),
        })
            .then(function (response) {
                console.log('response of attribute Values update###--->', response.data)
                if (response && response.data && response.data.product_attribute_values) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`Attribute values updated successfully`, 'success')
                    )
                }
                setTranslateModalVisible(false)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
            })
    }
    //! doing post call for category display name

    const postMultilingualDetails = (
        postBody,
        putCallWillHappen,
        finalDataForPutCall,
        changedDataForPutCall,
        callWithDefaultDetails
    ) => {
        let postDisplayNameApiEndPoint
        let finalPostBody
        let tempArray = postBody

        postDisplayNameApiEndPoint = productPublishingDisplayNameAPI
        finalPostBody = {
            product_id: parseInt(respectiveId),
            attribute_data: tempArray,
        }
        MarketplaceServices.save(postDisplayNameApiEndPoint, finalPostBody, null)
            .then((response) => {
                console.log('add category postMultilingualDetails', response.data)
                if (putCallWillHappen) {
                    if (callWithDefaultDetails) {
                        if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                            putMultilingualDetails(finalDataForPutCall, 200)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                        putMultilingualDetails(changedDataForPutCall, 200)
                        setIsLoadingStoreLanguage(true)
                    }
                } else {
                    MarketplaceToaster.showToast(response)
                    setIsLoadingStoreLanguage(false)
                    setTranslateModalVisible(false)
                    setTimeout(function () {
                        navigate(0)
                    }, 500)
                }
            })
            .catch((error) => {
                if (putCallWillHappen) {
                    if (callWithDefaultDetails) {
                        if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                            putMultilingualDetails(finalDataForPutCall, 400)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                        putMultilingualDetails(changedDataForPutCall, 400)
                        setIsLoadingStoreLanguage(true)
                    }
                } else {
                    MarketplaceToaster.showToast(error.response)
                    setIsLoadingStoreLanguage(false)
                    setTranslateModalVisible(false)
                }

                if (error.response) {
                    console.log('error in DisplayName Post call', error.response.data)
                }
            })
    }

    //! doing put call for  display  name
    const putMultilingualDetails = (changedDataForPutCall, postApiStatus) => {
        let putDisplayNameApiEndPoint
        let finalPutBody
        let putParams
        putDisplayNameApiEndPoint = productPublishingDisplayNameAPI
        let onlyRequiredKeyPair =
            changedDataForPutCall &&
            changedDataForPutCall.length > 0 &&
            changedDataForPutCall.map(({ id, display_value }) => ({
                id,
                display_value,
            }))

        finalPutBody = { product_publishing_displaynames: onlyRequiredKeyPair }
        putParams = {
            product_id: parseInt(respectiveId),
        }

        MarketplaceServices.update(putDisplayNameApiEndPoint, finalPutBody, putParams)
            .then((response) => {
                console.log('put category postMultilingualDetails', response.data)

                setIsLoadingStoreLanguage(false)
                setTranslateModalVisible(false)
                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(response)
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                }
                setTimeout(function () {
                    navigate(0)
                }, 500)
            })
            .catch((error) => {
                console.log('error in Display Put call', error.response.data)
                setIsLoadingStoreLanguage(false)
                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                } else {
                    MarketplaceToaster.showToast(error.response)
                }
            })
    }

    const validationOfDataForPutCall = (tempArrDataWithDisplayNameId) => {
        var finalPutBody = []
        for (let i = 0; i < displayNameApiData.length; i++) {
            const id = displayNameApiData[i].id
            const localData =
                tempArrDataWithDisplayNameId &&
                tempArrDataWithDisplayNameId.find((element) => parseInt(element.id) === parseInt(id))
            let isChanged = false
            let obj = {}
            obj['id'] = displayNameApiData[i].id
            obj['language_code'] = displayNameApiData[i].language_code
            if (displayNameApiData[i] && localData) {
                if (displayNameApiData[i].display_value !== localData.display_value) {
                    obj['display_value'] = localData.display_value
                    isChanged = true
                }
                if (isChanged) {
                    finalPutBody.push(obj)
                }
            }
        }
        return finalPutBody
    }
    const InputValuesValidationFromAllLanguages = (
        commonDataContainingAllObject,
        callWithDefaultDetails,
        finalDataForPostCall,
        finalDataForPutCall
    ) => {
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        var tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        var postCallWillHappen =
            ((finalDataForPostCall && finalDataForPostCall.length > 0) ||
                (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0)) === true
                ? true
                : false
        var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)
        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            if (callWithDefaultDetails) {
                //! defaultDisplayName, defaultDescription
                var putCallWillHappen =
                    ((changedDataForPutCall && changedDataForPutCall.length > 0) ||
                        (finalDataForPutCall && finalDataForPutCall.length > 0)) === true
                        ? true
                        : false
                postMultilingualDetails(
                    finalDataForPostCall,
                    putCallWillHappen,
                    finalDataForPutCall,
                    changedDataForPutCall,
                    callWithDefaultDetails
                )
                setIsLoadingStoreLanguage(true)
            } else {
                postMultilingualDetails(
                    tempArrDataWithCategoryId,
                    putCallWillHappen,
                    finalDataForPutCall,
                    changedDataForPutCall,
                    callWithDefaultDetails
                )
                setIsLoadingStoreLanguage(true)
            }
        }

        //  mainCall
        if (!postCallWillHappen) {
            if (callWithDefaultDetails) {
                if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                    putMultilingualDetails(finalDataForPutCall, 200)
                    setIsLoadingStoreLanguage(true)
                }
            } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                putMultilingualDetails(changedDataForPutCall, 200)
                setIsLoadingStoreLanguage(true)
            }
        }
        var checkPrevAttrValue =
            displayNameDataJsonForAttributeValue &&
            displayNameDataJsonForAttributeValue.length > 0 &&
            displayNameDataJsonForAttributeValue[0].value
        var latestAttrValue
        if (
            displayNameDataJsonForAttributeValue &&
            displayNameDataJsonForAttributeValue.length > 0 &&
            displayNameDataJsonForAttributeValue[0].type === 'textrich'
        ) {
            latestAttrValue = textRichDataForDetails
        } else {
            latestAttrValue =
                dataForAttributesValueDetailsUpdate &&
                dataForAttributesValueDetailsUpdate.length > 0 &&
                dataForAttributesValueDetailsUpdate[0].value
        }
        if (checkPrevAttrValue !== latestAttrValue) {
            setIsLoadingStoreLanguage(true)
            attributesValueUpdatePutCall()
        }

        if (
            (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) ||
            (changedDataForPutCall && changedDataForPutCall.length > 0)
        ) {
        } else {
            //! Now check for Default Details Changes
            if (checkPrevAttrValue !== latestAttrValue) {
            } else {
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
        }
    }

    const inputFieldsValidationForApiCalls = (tempArrDataWithDisplayNameId, tempArrDataWithCategoryId) => {
        console.log('tempArrDataWithDisplayNameIdInValidation', tempArrDataWithDisplayNameId)
        var temp = []
        tempArrDataWithDisplayNameId &&
            tempArrDataWithDisplayNameId.length > 0 &&
            tempArrDataWithDisplayNameId.forEach((ele) => {
                if (ele.display_value === '') {
                    temp.push(ele.language_code)
                }
            })
        tempArrDataWithCategoryId &&
            tempArrDataWithCategoryId.length > 0 &&
            tempArrDataWithCategoryId.forEach((ele) => {
                if (ele.display_value === '') {
                    temp.push(ele.language_code)
                }
            })
        setLanguagesWithInvalidData(temp)
        return temp
    }

    const handleLanguageChange = (val) => {
        setSelectedLanguageFromDropDown(val)
    }
    const handleInputFieldsValidationModalClose = () => {
        setIsInputFieldsValidationModalOpen(false)
    }
    const handleTranslatorDiscard = () => {
        setTranslateModalVisible(false)
    }
    const handleAttributeValuesChangeFromAllLanguages = (val, byDefault) => {
        setInvalidName(false)
        var temp = deepCopy(commonDataContainingAllObject)
        if (byDefault) {
            let objIndex = temp.findIndex((obj) => obj.language_code === defaultLanguageName[0].language_code)
            temp[objIndex].display_value = val
            setCommonDataContainingAllObject(temp)
        } else {
            let objIndex = temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
            temp[objIndex].display_value = val
            setCommonDataContainingAllObject(temp)
        }
    }

    useEffect(() => {
        if (onChangeDisableFields) {
            setLoadingSkelton(true)
            setCommonDataContainingAllObject([])
            setTimeout(function () {
                getStoreLanguageData()
            }, 500)
        }
        setDataForAttributesValueDetailsUpdate(displayNameDataJsonForAttributeValue)
        if (dataJsonForAttributeValue && dataJsonForAttributeValue.length > 0) {
            if (dataJsonForAttributeValue[0].type === 'textrich') {
                setTextRichDataForDetails(dataJsonForAttributeValue[0].value)
            }
        }
    }, [])
    // translateModalVisible

    const handleInputFieldsValidationModalSave = () => {
        setIsInputFieldsValidationModalOpen(false)
        var callWithDefaultDetails = true
        const tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        var finalDataForPostCall = deepCopy(tempArrDataWithCategoryId)
        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = finalDataForPostCall.findIndex((obj) => obj.language_code === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (finalDataForPostCall && finalDataForPostCall[objIndex].display_value === '') {
                            finalDataForPostCall[objIndex].display_value =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].display_value
                        }
                    }
                })
        }
        var finalDataForPutCall
        if (tempArrDataWithDisplayNameId && tempArrDataWithDisplayNameId.length > 0) {
            var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)
            finalDataForPutCall = deepCopy(changedDataForPutCall)
        }
        var dataWithDefaultLanguage =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code === defaultLanguageName[0].language_code)
        var dataWithoutDefaultLanguage =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code !== defaultLanguageName[0].language_code)
        if (dataWithoutDefaultLanguage && dataWithoutDefaultLanguage.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = dataWithoutDefaultLanguage.findIndex((obj) => String(obj.language_code) === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (
                            dataWithoutDefaultLanguage[objIndex] &&
                            dataWithoutDefaultLanguage[objIndex].display_value === ''
                        ) {
                            dataWithoutDefaultLanguage[objIndex].display_value =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].display_value
                        }
                    }
                })
        }

        if (
            dataWithoutDefaultLanguage &&
            dataWithoutDefaultLanguage.length > 0 &&
            dataWithDefaultLanguage &&
            dataWithDefaultLanguage.length > 0
        ) {
            finalDataForPutCall = [...dataWithoutDefaultLanguage, ...dataWithDefaultLanguage]
        } else if (dataWithoutDefaultLanguage && dataWithoutDefaultLanguage.length > 0) {
            finalDataForPutCall = [...dataWithoutDefaultLanguage]
        } else if (dataWithDefaultLanguage && dataWithDefaultLanguage.length > 0) {
            finalDataForPutCall = [...dataWithDefaultLanguage]
        }
        InputValuesValidationFromAllLanguages(
            commonDataContainingAllObject,
            callWithDefaultDetails,
            finalDataForPostCall,
            finalDataForPutCall
        )
    }
    const handleSaveButton = () => {
        //!  checking commonObject is created or not
        if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
            //! checking weather default language is empty or not
            const defaultLanguageDisplayName =
                commonDataContainingAllObject &&
                commonDataContainingAllObject.length > 0 &&
                commonDataContainingAllObject.filter(
                    (value) => value.language_code === defaultLanguageName[0].language_code
                )[0].display_value

            if (defaultLanguageDisplayName !== '') {
                const tempArrDataWithDisplayNameId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return o.hasOwnProperty('id')
                    })

                const tempArrDataWithCategoryId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return !o.hasOwnProperty('id')
                    })
                var resultForInputFieldsValidationForApiCalls = inputFieldsValidationForApiCalls(
                    tempArrDataWithDisplayNameId,
                    tempArrDataWithCategoryId
                )
                if (resultForInputFieldsValidationForApiCalls && resultForInputFieldsValidationForApiCalls.length > 0) {
                    setIsInputFieldsValidationModalOpen(true)
                } else {
                    let callWithDefaultDetails = false
                    InputValuesValidationFromAllLanguages(commonDataContainingAllObject, callWithDefaultDetails)
                }
            } else {
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('languages:provide_details_for_default_language')}`, 'error')
                )
                setInvalidName(true)
                setSelectedLanguageFromDropDown(
                    defaultLanguageName && defaultLanguageName.length > 0 && defaultLanguageName[0].language_code
                )
                if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
                    const getTheInfoOfDefaultLang =
                        commonDataContainingAllObject &&
                        commonDataContainingAllObject.length > 0 &&
                        commonDataContainingAllObject.filter(
                            (ele) => ele.language_code === defaultLanguageName[0].language_code
                        )

                    if (
                        getTheInfoOfDefaultLang &&
                        getTheInfoOfDefaultLang.length > 0 &&
                        getTheInfoOfDefaultLang[0].display_value === ''
                    ) {
                        setInvalidName(true)
                    }
                }
            }
        } else {
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:something_went_wrong')}`, 'error'))
        }
    }

    // detailsUpdate
    const handleAttributeValueDetailsChange = (val) => {
        let tempObj = deepCopy(dataForAttributesValueDetailsUpdate)
        if (tempObj && tempObj.length > 0) {
            tempObj[0].value = val
            setDataForAttributesValueDetailsUpdate(tempObj)
        }
    }

    //! function to handle TextRichEditor component change
    const handleChangeWithLanguageCodes = (val) => {
        setInvalidName(false)
        var temp = deepCopy(commonDataContainingAllObject)
        let objIndex = temp && temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
        if (val === '' || val === '<p><br></p>') {
            if (temp[objIndex].display_value === '') {
                temp[objIndex].display_value = ''
                setCommonDataContainingAllObject(temp)
            }
        } else {
            if (temp) {
                temp[objIndex].display_value = val
                setCommonDataContainingAllObject(temp)
            }
        }
    }
    console.log('commonDataContainingAllObject#', commonDataContainingAllObject)
    return (
        <Content>
            {loadingSkelton ? (
                <Skeleton paragraph={{ rows: 6 }} className='p-3' />
            ) : (
                <Spin tip='Please wait!' size='large' spinning={isLoadingStoreLanguage}>
                    <StoreModal
                        title={t('languages:language_with_invalid_inputs')}
                        isVisible={isInputFieldsValidationModalOpen}
                        okButtonText={t('common:enter_translations')}
                        cancelButtonText={`${t('common:proceed_without_translations')}`}
                        okCallback={() => handleInputFieldsValidationModalClose()}
                        cancelCallback={() => handleInputFieldsValidationModalSave()}
                        isSpin={false}
                        width={700}
                        hideCloseButton={false}>
                        <Content>
                            <div className=' mt-2 space-y-2'>
                                <p className=''>{t('product_template:the_multi_lingual_details_for')}</p>
                                <ul
                                    className={
                                        languagesWithInvalidData && languagesWithInvalidData.length > 5
                                            ? '!overflow-y-auto !h-24'
                                            : ''
                                    }>
                                    {languagesWithInvalidData &&
                                        languagesWithInvalidData.length > 0 &&
                                        languagesWithInvalidData.map((ele, index) => {
                                            return (
                                                <>
                                                    {
                                                        <li className='!font-semibold'>
                                                            {index + 1}
                                                            {'. '}
                                                            {storeLanguages &&
                                                                storeLanguages.length > 0 &&
                                                                storeLanguages.filter(
                                                                    (val) => val.language_code === ele
                                                                )[0].language}
                                                        </li>
                                                    }
                                                </>
                                            )
                                        })}
                                </ul>
                                <p className=''>{t('product_template:click_continue_to_save')}</p>
                            </div>
                        </Content>
                    </StoreModal>
                    {/* <Row className='!space-x-5'>
                        <Col>
                            <Title level={4} className='!font-medium'>
                                {t('attributes:attribute_values')}
                            </Title>
                        </Col>
                    </Row>

                    <Divider /> */}
                    <div className='flex gap-3  w-full'>
                        <div className='!w-[300px] my-3 '>
                            {/* <Content className='flex'>
                                <h3 className='!font-medium mt-1 '>{t('attributes:attribute_value_details')}</h3>{' '}
                                <span> </span>
                            </Content> */}

                            <p>
                                <span className='font-normal opacity-40'>{t('common:translate_from')} </span>
                                <span className='font-medium'>{t('common:base_language')}</span>
                            </p>

                            {displayNameDataJsonForAttributeValue &&
                                displayNameDataJsonForAttributeValue.length > 0 &&
                                displayNameDataJsonForAttributeValue.map((data) => (
                                    <>
                                        {(data.type === 'text' || data.type === 'textname') && (
                                            <Content className='my-4' key={data.attributeId}>
                                                <Typography className='input-label-color'>{data.titileName}</Typography>
                                                <Content className='mt-2'>
                                                    <Tooltip
                                                        placement='bottom'
                                                        title={
                                                            dataForAttributesValueDetailsUpdate &&
                                                            dataForAttributesValueDetailsUpdate.length > 0
                                                                ? dataForAttributesValueDetailsUpdate[0]?.value
                                                                : undefined
                                                        }>
                                                        <Input
                                                            placeholder={
                                                                data.placeholder.toLowerCase() === 'false'
                                                                    ? ''
                                                                    : data.placeholder
                                                            }
                                                            disabled={true}
                                                            className='w-[100%] border-solid border-[#C6C6C6]'
                                                            prefix={data.type === 'textname' ? <UserOutlined /> : null}
                                                            value={
                                                                dataForAttributesValueDetailsUpdate &&
                                                                dataForAttributesValueDetailsUpdate.length > 0
                                                                    ? dataForAttributesValueDetailsUpdate[0].value
                                                                    : null
                                                            }
                                                            onChange={(e) =>
                                                                handleAttributeValueDetailsChange(e.target.value)
                                                            }
                                                            maxLength={100}
                                                        />
                                                    </Tooltip>
                                                </Content>
                                            </Content>
                                        )}
                                        {data.type === 'textrich' && (
                                            <Content className='my-3' key={data.attributeId}>
                                                <Typography className='input-label-color'>{data.titileName}</Typography>

                                                <Content className='!opacity-70 !pointer-events-none !cursor-none !hover:pointer-events-none !hover:cursor-none'>
                                                    <TextRichEditor
                                                        data={textRichDataForDetails}
                                                        className='w-[100%] pl-[6px]'
                                                        setData={setTextRichDataForDetails}
                                                        editable={false}
                                                        placeholder={
                                                            data.placeholder.toLowerCase() === 'false'
                                                                ? ''
                                                                : data.placeholder
                                                        }
                                                    />
                                                </Content>
                                            </Content>
                                        )}
                                    </>
                                ))}
                        </div>
                        <div className='border-r !border-brandGray'></div>
                        <div className='flex-grow my-3'>
                            <Content className='flex gap-2 items-center justify-between'>
                                <div className='flex items-center mb-2'>
                                    <p className='font-normal opacity-40 whitespace-nowrap'>{t('common:translate_to')}</p>
                                    <Select
                                        value={selectedLanguageFromDropDown}
                                        onChange={handleLanguageChange}
                                        className='border-0 translator-select h-[22px] !mx-2 w-28'
                                        listHeight={138}>
                                        {allLanguages &&
                                            allLanguages.length > 0 &&
                                            allLanguages.map((e) => (
                                                <Select.Option
                                                    className='translatorSelectOption'
                                                    value={e.language_code}
                                                    title={e.language}
                                                    key={e.language_code}>
                                                    {e.language}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                    {defaultLanguageName &&
                                    defaultLanguageName.length > 0 &&
                                    selectedLanguageFromDropDown === defaultLanguageName[0].language_code ? (
                                        <p className='!font-semibold whitespace-nowrap  !opacity-60'>
                                            {t('attributes:default_language')}
                                        </p>
                                    ) : null}
                                </div>
                                <div className='flex'>
                                    <Progress
                                        className='w-[140px] !m-0 px-2'
                                        percent={(alreadyTranslatedCount / allLanguages?.length) * 100}
                                        showInfo={false}
                                        size={'small'}
                                    />
                                    <p className='font-normal text-sm'>
                                        {alreadyTranslatedCount} {t('common:of')} {allLanguages?.length}{' '}
                                        {t('common:languages_translated')}
                                    </p>
                                </div>
                            </Content>
                            {displayNameDataJsonForAttributeValue &&
                                displayNameDataJsonForAttributeValue.length > 0 &&
                                displayNameDataJsonForAttributeValue.map((data) => (
                                    <>
                                        {(data.type === 'text' || data.type === 'textname') && (
                                            <Content className='mt-2' key={data.attributeId}>
                                                <Typography className='input-label-color'>
                                                    {data.titileName}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>
                                                <Content className='mt-2'>
                                                    <Input
                                                        placeholder={
                                                            data.placeholder.toLowerCase() === 'false'
                                                                ? ''
                                                                : data.placeholder
                                                        }
                                                        className={`w-[100%]  ${
                                                            invalidName &&
                                                            defaultLanguageName &&
                                                            defaultLanguageName.length > 0 &&
                                                            selectedLanguageFromDropDown ===
                                                                defaultLanguageName[0].language_code
                                                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                                : ' border-[#C6C6C6] border-solid'
                                                        }`}
                                                        prefix={data.type === 'textname' ? <UserOutlined /> : null}
                                                        value={
                                                            allLanguages && allLanguages.length > 0
                                                                ? commonDataContainingAllObject &&
                                                                  commonDataContainingAllObject[0] &&
                                                                  commonDataContainingAllObject.length > 0 &&
                                                                  commonDataContainingAllObject.filter(
                                                                      (el) =>
                                                                          el.language_code ===
                                                                          selectedLanguageFromDropDown
                                                                  )[0].display_value
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            if (validator.matches(e.target.value.trim(), /^\S.*$/)) {
                                                                handleAttributeValuesChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )
                                                                setOnChangeDisableFields(false)
                                                            } else if (e.target.value === '') {
                                                                handleAttributeValuesChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )
                                                                setOnChangeDisableFields(false)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var temp = deepCopy(commonDataContainingAllObject)
                                                            let objIndex = temp.findIndex(
                                                                (obj) =>
                                                                    obj.language_code === selectedLanguageFromDropDown
                                                            )
                                                            temp[objIndex].display_value = e.target.value
                                                                .trim()
                                                                .replace(/\s+/g, ' ')
                                                            setCommonDataContainingAllObject(temp)
                                                        }}
                                                        maxLength={parseInt(titleMaxLength)}
                                                    />
                                                </Content>
                                            </Content>
                                        )}{' '}
                                        {data.type === 'textrich' && (
                                            <Content className='mt-3' key={data.attributeId}>
                                                <Typography className='input-label-color'>
                                                    {data.titileName}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>

                                                <Content className=''>
                                                    <TextRichEditor
                                                        data={
                                                            allLanguages && allLanguages.length > 0
                                                                ? commonDataContainingAllObject &&
                                                                  commonDataContainingAllObject[0] &&
                                                                  commonDataContainingAllObject.length > 0 &&
                                                                  commonDataContainingAllObject.filter(
                                                                      (el) =>
                                                                          el.language_code ===
                                                                          selectedLanguageFromDropDown
                                                                  )[0].display_value
                                                                : null
                                                        }
                                                        className={`w-[100%] pl-[6px] ${
                                                            invalidName &&
                                                            defaultLanguageName &&
                                                            defaultLanguageName.length > 0 &&
                                                            selectedLanguageFromDropDown ===
                                                                defaultLanguageName[0].language_code
                                                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                                : ' border-[#C6C6C6] border-solid'
                                                        }`}
                                                        setData={setTextRichDataForDisplayName}
                                                        editable={false}
                                                        placeholder={
                                                            data.placeholder.toLowerCase() === 'false'
                                                                ? ''
                                                                : data.placeholder
                                                        }
                                                        handleChangeWithLanguageCodes={handleChangeWithLanguageCodes}
                                                    />
                                                </Content>
                                            </Content>
                                        )}
                                    </>
                                ))}
                        </div>
                    </div>
                    <Content
                        className={` -mb-3 w-full  ${
                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'text-left ' : 'text-right'
                        }`}>
                        <Button
                            className={` app-btn-secondary ${
                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!ml-2' : '!mr-2'
                            }`}
                            onClick={() => handleTranslatorDiscard()}>
                            {t('common:discard')}
                        </Button>
                        <Button
                            className='app-btn-primary ml-2'
                            onClick={() => handleSaveButton()}
                            disabled={
                                !onChangeDisableFields
                                    ? allLanguages && allLanguages.length > 0
                                        ? (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_value === null) ||
                                          (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_value === '')
                                            ? true
                                            : false
                                        : true
                                    : true
                            }>
                            {t('common:save')}
                        </Button>
                    </Content>
                </Spin>
            )}
        </Content>
    )
}

export default AttributeValuesTranslatorModal
