import { Button, Checkbox, Input, Spin, Switch, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ConsentPreview } from '../../../constants/media'
import util from '../../../util/common'
import useGetUserConsentByStore from '../hooks/useGetUserConsentByStore'
import useUpdateConsentOrder from '../hooks/useUpdateConsentOrder'
import useUpdateLeadInLine from '../hooks/useUpdateLeadInLine'

const { Text } = Typography
const { TextArea } = Input
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const PreviewAndCustomize = ({ selectedTab, closeModal, refetchUserConsent }) => {
    const { t } = useTranslation()
    const {
        data: userConsents,
        status: userConsentsStatus,
        refetch: refetchUserConsents,
    } = useGetUserConsentByStore({
        userType: selectedTab,
    })
    const [leadInLine, setLeadInLine] = useState('')
    const [reorderList, setReorderList] = useState([])
    const [draggedItem, setDraggedItem] = useState(null)
    const [draggedOverItem, setDraggedOverItem] = useState(null)
    const [isListReordered, setIsListReordered] = useState(false)
    const [explicit, setExplicit] = useState(false)

    const { mutateAsync: updateLeadInLine, status: UpdateConsentLeadStatus } = useUpdateLeadInLine()
    const { mutateAsync: updateConsentOrder, status: updateConsentsOrderStatus } = useUpdateConsentOrder()
    const handelLeadInLine = (e) => {
        setLeadInLine(e.target.value)
    }

    useEffect(() => {
        if (userConsents?.store_userconsent_data?.length > 0 && userConsentsStatus === 'success') {
            setLeadInLine(userConsents?.leading_line || 'I have read and agreed to the following policies.')
            setExplicit(userConsents?.explicit)
            const tempReorderList = []
            let userConsentInformationFromServer = userConsents?.store_userconsent_data
            userConsentInformationFromServer &&
                userConsentInformationFromServer.length > 0 &&
                userConsentInformationFromServer.forEach((consentDetails) => {
                    consentDetails?.version_details?.forEach((consent) => {
                        tempReorderList.push({
                            key: consent?.store_user_consent_id,
                            name: consent?.consent_display_name,
                            ordering: consentDetails?.ordering,
                        })
                    })
                })
            tempReorderList.sort((a, b) => a.ordering - b.ordering)
            if (tempReorderList?.length > 0) setReorderList(tempReorderList)
        }
    }, [userConsents, userConsentsStatus])

    const handleListDragStart = (e, index) => {
        console.log('handleListDragStart', index)
        setDraggedItem(index)
    }
    const handelListDragEnter = (e, index) => {
        setDraggedOverItem(index)
    }

    const handleListDrop = (e, comp) => {
        console.log('draggedItem', draggedItem, comp?.key)
        const updatedFormItems = reorderList?.slice()
        if (draggedItem !== -1 && draggedOverItem !== -1) {
            // Swap positions
            ;[updatedFormItems[draggedItem], updatedFormItems[draggedOverItem]] = [
                updatedFormItems[draggedOverItem],
                updatedFormItems[draggedItem],
            ]
            setReorderList(updatedFormItems)
            setIsListReordered(true)
        }
    }

    const handelSave = () => {
        const updateLeadInLineAndExplicit = async () => {
            if (leadInLine?.trim() !== userConsents?.leading_line || explicit !== userConsents?.explicit) {
                const body = {
                    // store: userConsents?.store_userconsent_data?.[0]?.store_id,
                    user_type: selectedTab,
                    language_code: String(util.getUserSelectedLngCode()).toLowerCase(),
                    leading_line: leadInLine?.trim(),
                    explicit: !!explicit,
                }
                await updateLeadInLine(
                    { body },
                    {
                        onSuccess: (response) => {
                            console.log('response', response)
                            // refetchUserConsent()
                            toast(response?.response_body?.message || t('policies:leading_line_updated_successfully'), {
                                type: 'success',
                            })
                        },
                        onError: (err) => {
                            toast(err?.response?.data?.message || t('policies:error_updating_leading_line'), {
                                type: 'error',
                            })
                            Promise.reject()
                        },
                    }
                )
            }
        }
        const updateConsentOrderFunc = async () => {
            if (reorderList?.length > 0 && isListReordered) {
                const body = { user_consent_order: [] }
                reorderList?.forEach((consent, index) => {
                    body.user_consent_order.push({
                        store_userconsent_id: consent.key,
                        ordering: index + 1,
                    })
                })
                console.log(body, 'body')
                await updateConsentOrder(
                    { body },
                    {
                        onSuccess: (response) => {
                            console.log('response', response)
                            // refetchUserConsent()
                            toast(response?.response_body?.message || t('policies:order_updated_successfully'), {
                                type: 'success',
                            })
                        },
                        onError: (err) => {
                            toast(err?.response?.data?.message || t('policies:error_updating_order'), {
                                type: 'error',
                            })
                            Promise.reject()
                        },
                    }
                )
            }
        }
        Promise.allSettled([updateLeadInLineAndExplicit(), updateConsentOrderFunc()]).then((values) => {
            const checkIfPromiseResolved = []
            values?.forEach((value) => checkIfPromiseResolved.push(value?.status))
            if (!checkIfPromiseResolved?.includes('rejected')) {
                setTimeout(() => {
                    refetchUserConsent()
                    refetchUserConsents()
                    closeModal()
                }, [300])
            }
        })
    }

    const handelExplicitChange = (checked) => {
        setExplicit(checked)
    }
    return (
        <>
            {userConsentsStatus === 'pending' && (
                <div className=' w-full flex justify-center items-center h-[100px]'>
                    <Spin size='large' />
                </div>
            )}
            {userConsentsStatus === 'success' && (
                <>
                    <div className=' w-full flex '>
                        <div className=' shrink-0'>
                            <p className=' !text-black font-bold !text-opacity-40 py-4 '>{t('policies:preview')}</p>
                            <div className=' relative w-[600px] h-[507px] '>
                                <img src={ConsentPreview} alt='ConsentPreview' />
                                <div
                                    className=' absolute w-full flex bg-[#F5F5F5]  items-start gap-x-2 top-[255px] !text-[13px] left-[0px] drop-shadow-md max-h-[104px] overflow-y-auto'
                                    style={{ padding: '15px 100px' }}>
                                    {explicit ? <Checkbox /> : null}
                                    <div className={` ${explicit ? 'w-[90%]' : ' w-full'} `}>
                                        <span className=' mr-1 input-label-color'>{leadInLine?.trim()}</span>
                                        {reorderList?.length > 0 &&
                                            reorderList?.map((list, index) => {
                                                return (
                                                    <span key={list?.key} className=''>
                                                        <Text
                                                            ellipsis={{
                                                                tooltip: {
                                                                    title: list?.name,
                                                                    mouseLeaveDelay: 0,
                                                                    mouseEnterDelay: 0.5,
                                                                },
                                                            }}
                                                            className=' text-[13px] '>
                                                            <span className=' text-black'>
                                                                {index !== 0 ? ', ' : ''}
                                                            </span>
                                                            {list?.name}
                                                        </Text>
                                                    </span>
                                                )
                                            })}
                                    </div>
                                </div>
                                <div className=' absolute bottom-0 w-full bg-[#F5F5F5] py-2 px-[18px]'>
                                    <div className=' !text-xs flex items-center pb-2 gap-y-2 gap-x-8 flex-wrap justify-center max-h-[48px] overflow-y-auto'>
                                        {reorderList?.length > 0 &&
                                            reorderList?.map((list, index) => {
                                                return (
                                                    <div className=' max-w-[260px]' key={list?.key}>
                                                        <Text
                                                            ellipsis={{
                                                                tooltip: {
                                                                    title: list?.name,
                                                                    mouseLeaveDelay: 0,
                                                                    mouseEnterDelay: 0.5,
                                                                },
                                                            }}
                                                            className='  text-xs  '>
                                                            {list?.name}
                                                        </Text>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    <p className=' text-xs text-black'>
                                        {t('common:copy_right_message')}
                                        &nbsp;- {t('common:store_management_portal')} - {t('common:version')}{' '}
                                        {portalInfo.version}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className=' p-[1px] mx-6   self-stretch  bg-black !opacity-5' />
                        <div className=' w-full'>
                            <p className=' !text-black font-bold !text-opacity-40 py-3 '>
                                {t('policies:customisation')}
                            </p>
                            <p className=' !text-black font-normal !text-opacity-40 mb-2  '>
                                {t('policies:consent_explicit')}
                            </p>
                            <Switch
                                checked={explicit}
                                onChange={handelExplicitChange}
                                className={` mb-4 ${explicit ? '!bg-brandPrimaryColor' : '!bg-gray-400'}`}
                            />
                            <p className=' !text-black font-normal !text-opacity-40 pt-2 '>
                                {t('policies:lead_in_line')} <span className=' text-red-500'>*</span>
                            </p>
                            <div className=' pt-2'>
                                <TextArea
                                    className='w-full !pr-[3px]'
                                    showCount
                                    maxLength={100}
                                    autoSize
                                    onChange={handelLeadInLine}
                                    value={leadInLine}
                                />
                            </div>
                            <div className=' pt-6'>
                                <p className=' !text-base text-black !text-opacity-80 font-medium'>
                                    {t('policies:order_policies')}
                                </p>
                                <div className=' mt-2 w-[280px] h-[300px] overflow-y-auto '>
                                    {reorderList?.length > 0 &&
                                        reorderList?.map((list, index) => {
                                            return (
                                                <div
                                                    key={list?.key}
                                                    className=' flex !py-5 items-center gap-x-4 px-4 border-b  cursor-move'
                                                    onDragStart={(e) => {
                                                        handleListDragStart(e, index)
                                                    }}
                                                    onDragEnter={(e) => {
                                                        handelListDragEnter(e, index)
                                                    }}
                                                    onDragEnd={(e) => {
                                                        e.stopPropagation()
                                                        handleListDrop(e, index)
                                                    }}
                                                    draggable>
                                                    <div className=' shrink-0'>
                                                        <svg
                                                            width='16'
                                                            height='17'
                                                            viewBox='0 0 16 17'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M15.0003 2.21191H1.00028C0.921708 2.21191 0.857422 2.2762 0.857422 2.35477V3.49763C0.857422 3.5762 0.921708 3.64049 1.00028 3.64049H15.0003C15.0789 3.64049 15.1431 3.5762 15.1431 3.49763V2.35477C15.1431 2.2762 15.0789 2.21191 15.0003 2.21191ZM15.0003 13.3548H1.00028C0.921708 13.3548 0.857422 13.4191 0.857422 13.4976V14.6405C0.857422 14.7191 0.921708 14.7833 1.00028 14.7833H15.0003C15.0789 14.7833 15.1431 14.7191 15.1431 14.6405V13.4976C15.1431 13.4191 15.0789 13.3548 15.0003 13.3548ZM15.0003 7.78334H1.00028C0.921708 7.78334 0.857422 7.84763 0.857422 7.9262V9.06906C0.857422 9.14763 0.921708 9.21192 1.00028 9.21192H15.0003C15.0789 9.21192 15.1431 9.14763 15.1431 9.06906V7.9262C15.1431 7.84763 15.0789 7.78334 15.0003 7.78334Z'
                                                                fill='black'
                                                                fillOpacity='0.45'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className=' w-[90%]'>
                                                        <Text
                                                            className=' !text-sm text-black !text-opacity-80 !mb-0'
                                                            ellipsis={{
                                                                tooltip: {
                                                                    title: list?.name,
                                                                    mouseLeaveDelay: 0,
                                                                    placement: 'right',
                                                                    autoAdjustOverflow: false,
                                                                    overlayClassName: 'max-w-[200px]',
                                                                    mouseEnterDelay: 0.5,
                                                                },
                                                            }}>
                                                            {list?.name}
                                                        </Text>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' flex justify-end'>
                        <Button className='app-btn-secondary mx-2' onClick={() => closeModal()}>
                            {t('common:cancel')}
                        </Button>
                        <Button
                            className='app-btn-primary'
                            loading={updateConsentsOrderStatus === 'pending' || UpdateConsentLeadStatus === 'pending'}
                            onClick={handelSave}
                            disabled={!leadInLine?.trim()}>
                            {updateConsentsOrderStatus === 'pending' || UpdateConsentLeadStatus === 'pending'
                                ? ''
                                : t('common:publish')}
                        </Button>
                    </div>
                </>
            )}
        </>
    )
}
export default PreviewAndCustomize
