import { CheckCircleFilled, CloseOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Card, Layout, Spin, Typography, Upload } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StoreModal from '../../components/storeModal/StoreModal'
import { codeJsonIcon, tableIcon } from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
const storeLanguageKeysUploadAPI = process.env.REACT_APP_UPLOAD_LANGUAGE_TRANSLATION_CSV
const LanguageDownloadApiCsv = process.env.REACT_APP_DOWNLOAD_LANGUAGE_TRANSLATION_CSV
const LanguageDownloadApiZip = process.env.REACT_APP_DOWNLOAD_LANGUAGE_TRANSLATION_ZIP
const uploadBackendKeysUploadAPI = process.env.REACT_APP_UPLOAD_STORE_BACKEND_MESSAGE_DETAILS
const downloadBackendKeysAPI = process.env.REACT_APP_DOWNLOAD_STORE_BACKEND_MESSAGE_DETAILS
const LanguageDownloadAPI = process.env.REACT_APP_DOWNLOAD_LANGUAGE_TRANSLATION_CSV

function LanguageDocUpload({ langCode }) {
    const [chooseDownloadModalVisible, setChooseDownloadModalVisible] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [isBEKeysUploadModalOpen, setIsBEKeysUploadModalOpen] = useState(false)
    const [isSpinning, setIsSpinning] = useState(false)
    const [isSpinningForBEUpload, setIsSpinningForBEUpload] = useState(false)
    const [isUploadSpinning, setIsUploadSpinning] = useState(false)
    const { t } = useTranslation()
    const { Content } = Layout
    const { Title, Text } = Typography

    const uploadStoreLanguageKeys = (languageFile) => {
        setIsUploadSpinning(true)
        const formData = new FormData()
        if (languageFile) {
            formData.append('language_file', languageFile)
            formData.append('language_code', langCode)
        }
        console.log('formBody', formData)
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1])
        }
        let storeLanguageKeysPOSTBody = {
            language_code: langCode,
            language_file: languageFile,
        }
        console.log('postBody', storeLanguageKeysPOSTBody)
        MarketplaceServices.save(storeLanguageKeysUploadAPI, formData, null, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(function (response) {
                setIsUploadSpinning(false)
                setShowSuccessModal(true)
            })
            .catch((error) => {
                setIsUploadSpinning(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const uploadStoreBackendKeys = (languageFile) => {
        setIsSpinningForBEUpload(true)
        const formData = new FormData()
        if (languageFile) {
            formData.append('language_file', languageFile)
            formData.append('language_code', langCode)
        }
        console.log('formBody', formData)
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1])
        }
        let storeLanguageKeysPOSTBody = {
            language_code: langCode,
            language_file: languageFile,
        }
        console.log('postBody', storeLanguageKeysPOSTBody)
        MarketplaceServices.save(uploadBackendKeysUploadAPI, formData, null, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(function (response) {
                setIsSpinningForBEUpload(false)
                setIsBEKeysUploadModalOpen(true)
            })
            .catch((error) => {
                setIsSpinningForBEUpload(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const handleFileChange = (file) => {
        if (file.status !== 'removed') {
            uploadStoreLanguageKeys(file)
        }
    }

    const handleChangeForBackendKeysUpload = (file) => {
        if (file.status !== 'removed') {
            uploadStoreBackendKeys(file)
        }
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const closeKeysUploadModal = () => {
        setIsBEKeysUploadModalOpen(false)
    }

    const downloadCSV = () => {
        setIsSpinning(true)
        MarketplaceServices.findMedia(LanguageDownloadApiCsv, {
            'is-format': 2,
            language_code: langCode,
        })
            .then(function (response) {
                setIsSpinning(false)
                console.log('Server Response from DocumentTemplateDownload Function: ', response.data)
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'key_value_format.csv'
                alink.click()
                setChooseDownloadModalVisible(false)
                MarketplaceToaster.showToast(util.getToastObject(t('languages:download_successful'), 'success'))
            })
            .catch((error) => {
                setIsSpinning(false)
                console.log('error', error)
                MarketplaceToaster.showToast(util.getToastObject(t('languages:unable_to_upload'), 'error'))
                console.log('Server error from DocumentTemplateDownload Function ', error.response)
            })
    }

    const downloadZIP = () => {
        setIsSpinning(true)
        MarketplaceServices.findMedia(LanguageDownloadApiZip, {
            language_code: langCode,
        })
            .then(function (response) {
                setIsSpinning(false)
                console.log('Server Response from DocumentTemplateDownload Function: ', response.data)
                MarketplaceToaster.showToast(util.getToastObject(t('languages:download_successful'), 'success'))
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'key_value_format.zip'
                alink.click()
                setChooseDownloadModalVisible(false)
            })
            .catch((error) => {
                setIsSpinning(false)
                MarketplaceToaster.showToast(util.getToastObject(t('languages:unable_to_upload'), 'error'))
                console.log('Server error from DocumentTemplateDownload Function ', error.response)
            })
    }

    //! get call of get document template API
    const findAllSupportDocumentTemplateDownload = (isFormat, languageCode) => {
        setIsSpinning(true)
        MarketplaceServices.findMedia(LanguageDownloadAPI, {
            'is-format': isFormat,
            language_code: languageCode,
        })
            .then(function (response) {
                setIsSpinning(false)
                console.log('Server Response from DocumentTemplateDownload Function: ', response.data)
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'key_value_format.csv'
                alink.click()

                MarketplaceToaster.showToast(util.getToastObject(t('languages:download_successful'), 'success'))
            })
            .catch((error) => {
                setIsSpinning(false)
                console.log('Server error from DocumentTemplateDownload Function ', error.response)
                MarketplaceToaster.showToast(util.getToastObject(t('languages:unable_to_upload'), 'error'))
            })
    }

    const downloadBEKeysFile = (isFormat, languageCode) => {
        setIsSpinningForBEUpload(true)
        MarketplaceServices.findMedia(downloadBackendKeysAPI, {
            'is-format': isFormat,
            language_code: languageCode,
        })
            .then(function (response) {
                setIsSpinningForBEUpload(false)
                console.log('Server Response from DocumentTemplateDownload Function: ', response.data)
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'message_format.csv'
                alink.click()

                MarketplaceToaster.showToast(util.getToastObject(t('languages:download_successful'), 'success'))
            })
            .catch((error) => {
                setIsSpinningForBEUpload(false)
                MarketplaceToaster.showToast(error.response)
                console.log('Server error from DocumentTemplateDownload Function ', error.response)
            })
    }

    return (
        <Content className=''>
            <div className='font-semibold text-lg leading-[26px] text-regal-blue pb-2 pt-4 px-1 '>
                {t('languages:support_document')}
            </div>
            <p className='text-brandGray2 px-1'>{t('languages:language_document_data')}</p>
            <Content className='!flex border rounded-lg mt-3'>
                <Content className=''>
                    <Content className='flex justify-between border-b p-3'>
                        <Content>
                            <Text className='text-base font-semibold'>{t('languages:frontend_keys')}</Text>
                        </Content>
                        <Button
                            className='app-btn-secondary flex gap-1 !items-center'
                            onClick={() => findAllSupportDocumentTemplateDownload(1, null)}>
                            <DownloadOutlined />
                            {t('languages:get_frontend_support_template')}
                        </Button>
                    </Content>
                    <Content className='p-3'>
                        <Content className='py-2'>
                            <p>{t('languages:frontend_document')}</p>
                        </Content>
                        <Content className='flex gap-3'>
                            <Spin spinning={isUploadSpinning} tip={t('common:please_wait')}>
                                <Upload
                                    beforeUpload={() => {
                                        return false
                                    }}
                                    afterUpload={() => {
                                        return false
                                    }}
                                    showUploadList={false}
                                    disabled={langCode !== undefined ? false : true}
                                    accept='.csv'
                                    maxCount={1}
                                    onChange={(e) => handleFileChange(e.file)}
                                    openFileDialogOnClick={langCode !== undefined && langCode !== null ? true : false}
                                    className='app-btn-secondary'>
                                    <Button
                                        className='flex app-btn-secondary justify-center items-center mt-2'
                                        disabled={langCode !== undefined && langCode !== null ? false : true}>
                                        <UploadOutlined />
                                        {t('languages:click_to_upload')}
                                    </Button>
                                </Upload>
                            </Spin>
                            <Button
                                type='text '
                                className={`mt-2 app-btn-text flex items-center cursor-pointer ${
                                    langCode !== undefined && langCode !== null ? '' : '!opacity-25'
                                }`}
                                onClick={() => setChooseDownloadModalVisible(true)}
                                disabled={langCode !== undefined && langCode !== null ? false : true}>
                                <DownloadOutlined />
                                {t('languages:download_current_document')}
                            </Button>
                        </Content>
                    </Content>
                </Content>
            </Content>

            <Content className='mt-3 !flex gap-4 border rounded-lg'>
                <Content className=''>
                    <Content className='flex justify-between border-b p-3'>
                        <Content>
                            <Text className='text-base font-semibold'>{t('languages:backend_keys')}</Text>
                        </Content>
                        <Button
                            className='app-btn-secondary flex gap-1 !items-center !mb-1'
                            onClick={() => downloadBEKeysFile(1, null)}>
                            <DownloadOutlined />
                            {t('languages:get_backend_support_template')}
                        </Button>
                    </Content>

                    <Content className='p-3'>
                        <Content className='py-2'>
                            <p>{t('languages:backend_document')}</p>
                        </Content>
                        <Content className='flex gap-3'>
                            <Spin spinning={isSpinningForBEUpload} tip={t('common:please_wait')}>
                                <Upload
                                    beforeUpload={() => {
                                        return false
                                    }}
                                    afterUpload={() => {
                                        return false
                                    }}
                                    showUploadList={false}
                                    disabled={langCode !== undefined ? false : true}
                                    accept='.csv'
                                    maxCount={1}
                                    onChange={(e) => handleChangeForBackendKeysUpload(e.file)}
                                    className='app-btn-secondary'
                                    openFileDialogOnClick={langCode !== undefined && langCode !== null ? true : false}>
                                    <Button
                                        className={'app-btn-secondary flex justify-center items-center mt-2'}
                                        disabled={langCode !== undefined && langCode !== null ? false : true}>
                                        <UploadOutlined />
                                        {t('languages:click_to_upload')}
                                    </Button>
                                </Upload>
                            </Spin>
                            <Button
                                type='text'
                                className={`mt-2 app-btn-text flex items-center cursor-pointer ${
                                    langCode !== undefined && langCode !== null ? '' : '!opacity-25'
                                }`}
                                onClick={() => downloadBEKeysFile(2, langCode)}
                                disabled={langCode !== undefined && langCode !== null ? false : true}>
                                <DownloadOutlined />
                                {t('languages:download_current_document')}
                            </Button>
                        </Content>
                    </Content>
                </Content>
            </Content>
            <StoreModal
                isVisible={showSuccessModal}
                okButtonText={null}
                hideCloseButton={false}
                cancelButtonText={null}
                isSpin={false}>
                <Content className='flex flex-col justify-center items-center'>
                    <CheckCircleFilled className=' text-[#52c41a] text-[80px]' />
                    <Title level={3} className='!mt-5 !mb-1 whitespace-nowrap'>
                        {t('languages:uploaded_successfully')}
                    </Title>
                    <Text>{t('languages:upload_success_message')}</Text>
                    <Content className='mt-3 flex gap-2'>
                        <Button className='app-btn-primary' onClick={() => closeSuccessModal()}>
                            {t('common:close')}
                        </Button>
                        <Upload
                            showUploadList={false}
                            maxCount={1}
                            accept='.csv'
                            beforeUpload={() => {
                                return false
                            }}
                            afterUpload={() => {
                                return false
                            }}
                            onChange={(e) => {
                                setShowSuccessModal(false)
                                handleFileChange(e.file)
                            }}>
                            <Button className={'flex items-center'} icon={<UploadOutlined />}>
                                {t('languages:upload_again')}
                            </Button>
                        </Upload>
                    </Content>
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={isBEKeysUploadModalOpen}
                okButtonText={null}
                hideCloseButton={false}
                cancelButtonText={null}
                isSpin={false}>
                <Content className='flex flex-col justify-center items-center'>
                    <CheckCircleFilled className=' text-[#52c41a] text-[80px]' />
                    <Title level={3} className='!mt-5 !mb-1 whitespace-nowrap'>
                        {t('languages:uploaded_successfully')}
                    </Title>
                    <Text>{t('languages:upload_success_message')}</Text>
                    <Content className='mt-3'>
                        <Button className='app-btn-primary mr-2' onClick={() => closeKeysUploadModal()}>
                            {t('common:close')}
                        </Button>
                        <Upload
                            showUploadList={false}
                            maxCount={1}
                            accept='.csv'
                            beforeUpload={() => {
                                return false
                            }}
                            afterUpload={() => {
                                return false
                            }}
                            onChange={(e) => {
                                setIsBEKeysUploadModalOpen(false)
                                handleChangeForBackendKeysUpload(e.file)
                            }}>
                            <Button className={'flex items-center'} icon={<UploadOutlined />}>
                                {t('languages:upload_again')}
                            </Button>
                        </Upload>
                    </Content>
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={chooseDownloadModalVisible}
                okButtonText={null}
                hideCloseButton={false}
                cancelButtonText={null}
                cancelCallback={() => setChooseDownloadModalVisible(false)}
                isSpin={false}>
                <Spin spinning={isSpinning} tip={t('common:please_wait')}>
                    <Content className='flex justify-between items-center'>
                        <Title level={4}>{t('languages:choose_download_format')}</Title>
                        <CloseOutlined
                            role={'button'}
                            className='mb-[5px]'
                            onClick={() => setChooseDownloadModalVisible(false)}></CloseOutlined>
                    </Content>
                    <Content className='my-2'>
                        {/* <Typography>{t('languages:choose_download_format')}</Typography> */}
                        <Content className='mt-3 flex gap-2'>
                            <Card onClick={() => downloadCSV()} className='w-[147px] cursor-pointer'>
                                <Content className='flex flex-col items-center'>
                                    <img alt='table icon' src={tableIcon} />
                                    <p>{t('languages:csv_format')}</p>
                                </Content>
                            </Card>
                            <Card onClick={() => downloadZIP()} className='w-[147px] cursor-pointer'>
                                <Content className='flex flex-col items-center'>
                                    <img src={codeJsonIcon} alt='json icon' />
                                    <p>{t('languages:json_format')}</p>
                                </Content>
                            </Card>
                        </Content>
                    </Content>
                </Spin>
            </StoreModal>
        </Content>
    )
}

export default LanguageDocUpload
