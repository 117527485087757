import { Layout, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import PaymentMethod from './PaymentMethod'
import PaymentType from './PaymentType'

//! ant design initialization
const { Title } = Typography
const { Content } = Layout

//! Global variables
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const PaymentList = () => {
    const { t } = useTranslation()

    usePageTitle(t('common:store_management_portal') + ' - ' + String(`${t('payment:payment_settings')}`))
    const [searchParams, setSearchParams] = useSearchParams()

    //!tab data
    const paymentTabData = [
        {
            key: 0,
            label: `${t('payment:payment_method')}`,
            value: 0,
        },
        {
            key: 1,
            label: (
                <span className={`${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : ''}`}>
                    {t('payment:payment_type')}
                </span>
            ),
            value: 1,
        },
    ]

    //! tab handle change
    const handlePaymentTabChange = (status) => {
        setSearchParams({
            tab: status,
            page: 1,
            limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content>
            <HeaderForTitle
                title={
                    <Title level={3} className='!font-semibold'>
                        {t('payment:payment_settings')}
                    </Title>
                }
                headerContent={
                    <Content className='!h-10 !mt-2'>
                        <DmTabAntDesign
                            tabData={paymentTabData}
                            tabBarPosition={'top'}
                            defaultSelectedTabKey={'0'}
                            activeKey={parseInt(searchParams.get('tab')) === 1 ? 1 : 0}
                            handleTabChangeFunction={handlePaymentTabChange}
                            tabType='line'
                        />
                    </Content>
                }
            />
            <Content className='!p-4'>
                {parseInt(searchParams.get('tab')) === 1 ? <PaymentType /> : <PaymentMethod />}
            </Content>
        </Content>
    )
}

export default PaymentList
