import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'

const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT_VERSIONS
const useCreateNewUserConsentVersion = () => {
    const createNewUserConsentVersion = async ({ body }) => {
        const res = await MarketplaceServices.save(BASE_URL, body)
        return res
    }
    return useMutation({ mutationFn: createNewUserConsentVersion })
}
export default useCreateNewUserConsentVersion
