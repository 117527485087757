//! data processor for productLists
const productListsDataProcessor = (productLists) => {
    let localData = []
    let temp = {}
    if (productLists && productLists.length > 0) {
        for (var i = 0; i < productLists.length; i++) {
            temp = { key: productLists[i].product_id, ...productLists[i] }
            // temp["key"] = productLists[i].product_id;
            // temp["revisionId"] = productLists[i].product_id;
            // temp["id"] = productLists[i].product_id;
            // temp["name"] = productLists[i].product_name;
            // temp["storeId"] = productLists[i].store_id;
            // temp["productStatus"] = productLists[i].product_status;
            // temp["StoreProductTypeName"] = productLists[i].product_type_name;
            localData.push(temp)
        }
        return localData
    } else {
        return localData
    }
}
export default productListsDataProcessor
