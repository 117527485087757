import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Col, Input, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import util from '../../../util/common'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import { getNextRevisionNumber } from '../../../util/util'

const { Content } = Layout
function VersionCreation({ consent, integerCount, setIntegerCount, floatCount, setFloatCount }) {
    const { t } = useTranslation()
    const [updateRevisionNumber, setUpdateRevisionNumber] = useState()
    const changeLeftValue = (action) => {
        const nextRevisionNum = getNextRevisionNumber(consent?.version_number)
        const leftValue = parseInt(String(updateRevisionNumber).split('.')[0])
        const rightValue = parseInt(String(updateRevisionNumber).split('.')[1])
        const leftNextValue = parseInt(String(nextRevisionNum).split('.')[0])
        if (action === 'increase') {
            if (integerCount === '') {
                setIntegerCount(leftValue)
            } else if (parseInt(integerCount) < 999) {
                if (parseInt(integerCount) < leftValue && parseInt(integerCount) === leftNextValue) {
                    setIntegerCount(parseInt(integerCount) + 1)
                    if (parseInt(floatCount) < rightValue) {
                        setFloatCount(rightValue)
                    }
                } else {
                    if (parseInt(integerCount) < leftNextValue) {
                        setIntegerCount(leftNextValue)
                    } else setIntegerCount(parseInt(integerCount) + 1)
                }
            } else {
                MarketplaceToaster.showToast(util.getToastObject(`${t('policies:revision_max_limit')}`, 'error'))
            }
        } else {
            if (floatCount < rightValue) {
                setFloatCount(rightValue)
            }
            if (parseInt(integerCount) > leftNextValue) {
                setIntegerCount(parseInt(integerCount) - 1)
            } else {
                setIntegerCount(leftNextValue)
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('policies:revision_min_limit')} ${nextRevisionNum}`, 'error')
                )
            }
        }
    }

    const changeRightValue = (action) => {
        const nextRevisionNum = getNextRevisionNumber(consent?.version_number)
        const leftValue = parseInt(String(updateRevisionNumber).split('.')[0])
        const rightValue = parseInt(String(updateRevisionNumber).split('.')[1])
        const leftNextValue = parseInt(String(nextRevisionNum).split('.')[0])
        const rightNextValue = parseInt(String(nextRevisionNum).split('.')[1])
        if (action === 'increase') {
            if (floatCount === '') {
                setFloatCount(rightValue)
            } else if (parseInt(floatCount) < 9) {
                if (parseInt(integerCount) < leftValue && parseInt(floatCount) < rightValue) {
                    setIntegerCount(leftValue)
                    setFloatCount(rightValue)
                } else setFloatCount(parseInt(floatCount) + 1)
            } else {
                if (parseInt(integerCount) < 999) {
                    setFloatCount(0)
                    setIntegerCount(parseInt(integerCount) + 1)
                } else {
                    setFloatCount(9)

                    MarketplaceToaster.showToast(util.getToastObject(`${t('policies:revision_max_limit')}`, 'error'))
                }
            }
        } else {
            if (parseInt(integerCount) > parseInt(leftNextValue)) {
                if (parseInt(floatCount) > 0) {
                    setFloatCount(parseInt(floatCount) - 1)
                } else {
                    setFloatCount(9)
                    setIntegerCount(parseInt(integerCount) - 1)
                }
            } else if (parseInt(integerCount) === parseInt(leftNextValue)) {
                setIntegerCount(leftNextValue)
                if (parseInt(floatCount) > parseInt(rightNextValue)) {
                    setFloatCount(parseInt(floatCount) - 1)
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('policies:revision_min_limit')} ${nextRevisionNum}`, 'error')
                    )
                }
            } else {
                if (parseInt(integerCount) < leftNextValue) {
                    setIntegerCount(leftNextValue)
                    setFloatCount(rightNextValue)
                } else setFloatCount(rightNextValue)

                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('policies:revision_min_limit')} ${nextRevisionNum}`, 'error')
                )
            }
        }
    }

    const handleRevisionTextChanged = (e, type) => {
        let value = e.target.value
        const validNumberRegex = /^[0-9]*$/
        if (validNumberRegex.test(value)) {
            if (type === 'integerCount') setIntegerCount(value)
            else setFloatCount(value)
        }
    }
    const formatVersion = (version) => {
        if (version) {
            if (Number.isInteger(version)) {
                return version.toString() + '.0'
            } else {
                return version.toString()
            }
        }
    }
    useEffect(() => {
        setIntegerCount(parseInt(String(formatVersion(consent?.version_number)).split('.')[0]))
        setFloatCount(parseInt(String(formatVersion(consent?.version_number)).split('.')[1]) + 1)
    }, [])
    return (
        <Content>
            <Row className='pb-4'>
                <p className='text-[#666666]'>{t('policies:add_version_desc')}</p>
            </Row>
            <Row>
                <Col span={4} className='pt-2'>
                    <Typography className='text-[#000]'>{t('common:version')}</Typography>
                </Col>
                <Col span={5}>
                    <div className='flex'>
                        <Input
                            className={`text-lg pl-5 ${integerCount === '' ? 'border-red-500' : ''}`}
                            maxLength={3}
                            value={integerCount}
                            onChange={(e) => handleRevisionTextChanged(e, 'integerCount')}
                            style={{ borderRadius: '5px 0px 0px 5px' }}
                        />
                        <div className='flex flex-col'>
                            <div
                                className={`border-1 border-[#ccc] hover:border-[#666] cursor-pointer px-1 ${
                                    integerCount === '' ? 'border-red-500' : ''
                                }`}
                                style={{ borderRadius: '0px 5px 0px 0px' }}
                                onClick={() => changeLeftValue('increase')}>
                                <UpOutlined className='text-xs' />
                            </div>
                            <div
                                className={`border-1 border-[#ccc] hover:border-[#666] cursor-pointer px-1 ${
                                    integerCount === '' ? 'border-red-500' : ''
                                }`}
                                style={{ borderRadius: '0px 0px 5px 0px' }}
                                onClick={() => changeLeftValue('decrease')}>
                                <DownOutlined className='text-xs' />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={1} className='pt-3 ml-3.5'>
                    <span>.</span>
                </Col>
                <Col span={5}>
                    <div className='flex'>
                        <Input
                            className={`text-lg pl-5 ${floatCount === '' ? 'border-red-500' : ''}`}
                            maxLength={1}
                            value={floatCount}
                            onChange={(e) => handleRevisionTextChanged(e, 'floatCount')}
                            style={{ borderRadius: '5px 0px 0px 5px' }}
                        />
                        <div className='flex flex-col'>
                            <div
                                className={`border-1 border-[#ccc] hover:border-[#666] cursor-pointer  px-1 ${
                                    floatCount === '' ? 'border-red-500' : ''
                                }`}
                                style={{ borderRadius: '0px 5px 0px 0px' }}
                                onClick={() => changeRightValue('increase')}>
                                <UpOutlined className='text-xs' />
                            </div>
                            <div
                                className={`border-1 border-[#ccc] hover:border-[#666] cursor-pointer  px-1 ${
                                    floatCount === '' ? 'border-red-500' : ''
                                }`}
                                style={{ borderRadius: '0px 0px 5px 0px' }}
                                onClick={() => changeRightValue('decrease')}>
                                <DownOutlined className='text-xs' />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Content>
    )
}

export default VersionCreation
