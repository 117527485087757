import { Tag } from 'antd'
import TranslateAttributeStrings from '../../pages/product_new_options/TranslateAttributeStrings'

const ProductTabData = [
    {
        id: 1,
        value: (
            <Tag color='red'>
                <TranslateAttributeStrings translate={'dashboard:inactive'} />
            </Tag>
        ),
        status: true,
    },
    {
        id: 2,
        value: (
            <Tag color='green'>
                <TranslateAttributeStrings translate={'common:active'} />
            </Tag>
        ),
        status: true,
    },
    {
        id: 3,
        value: (
            <Tag color='green'>
                <TranslateAttributeStrings translate={'product_template:sandbox_published'} />
            </Tag>
        ),
        status: true,
    },
    {
        id: 4,
        value: (
            <Tag color='green'>
                <TranslateAttributeStrings translate={'product_template:published'} />
            </Tag>
        ),
        status: true,
    },
    {
        id: 5,
        value: (
            <Tag color='green'>
                <TranslateAttributeStrings translate={'product_template:unpublished'} />
            </Tag>
        ),
        status: true,
    },
]

export default ProductTabData.filter((element) => element.status)
