import util from '../../util/common'
import http from './http-common'

const itemPerPage = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)

const findAll = (baseURL, pathParams, isLngSpecific) => {
    //If params is null, don't consider params
    //If requiredLngRecord is true, set selected language by default

    let configParams = {}
    configParams['page-number'] = -1
    if (isLngSpecific) {
        configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()
    }
    if (pathParams !== null) {
        configParams = { ...pathParams, ...configParams }
    }

    return http.get(baseURL, { params: configParams })
}

const findAllWithoutPage = (baseURL, pathParams) => {
    //If params is null, don't consider params
    //If requiredLngRecord is true, set selected language by default

    let configParams = {}
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()

    if (pathParams !== null) {
        configParams = { ...pathParams, ...configParams }
    }

    return http.get(baseURL, { params: configParams })
}

const findByPage = (baseURL, pathParams, page, limit) => {
    //If params is null, don't consider params
    //If page is null, set 1 by default
    //If requiredLngRecord is true, set selected language by default
    page = page === null ? 1 : page
    limit = limit === null ? itemPerPage : limit

    let configParams = {}
    configParams['page-number'] = page
    configParams['page-limit'] = limit
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()
    if (pathParams !== null) {
        configParams = { ...pathParams, ...configParams }
    }

    return http.get(baseURL, { params: configParams })
}

const findBlobImage = (baseURL, pathParams) => {
    return http.get(baseURL, { params: pathParams }, { responseType: 'blob' })
}

const save = (baseURL, requestBody, pathParams) => {
    let configParams = {}
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()
    if (pathParams !== null) configParams = { ...pathParams, ...configParams }

    return http.post(baseURL, requestBody, { params: configParams })
}

const update = (baseURL, requestBody, pathParams) => {
    let configParams = {}
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()
    if (pathParams !== null) configParams = { ...pathParams, ...configParams }

    return http.put(baseURL, requestBody, { params: configParams })
}

const remove = (baseURL, pathParams, payload = {}) => {
    let configParams = {}
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()
    if (pathParams !== null) configParams = { ...pathParams, ...configParams }
    // added condition to support payload 
    if (pathParams && Object.keys(pathParams).length > 0 && payload && Object.keys(payload).length > 0) {
        return http.delete(baseURL, {
            params: payload,
            data: configParams,
        })
    } else {
        return http.delete(baseURL, { params: configParams })
    }
}

const removeAll = (baseURL) => {
    let configParams = {}
    configParams['language-code'] = String(util.getUserSelectedLngCode()).toLowerCase()

    return http.delete(baseURL)
}

const findMedia = (baseURL, pathParams) => {
    //If params is null, don't consider params

    let configParams = {}

    if (pathParams !== null) {
        configParams = { ...pathParams, ...configParams }
    }

    return http.get(baseURL, { params: configParams, responseType: 'blob' })
}

const MarketplaceServices = {
    findAll,
    findAllWithoutPage,
    findByPage,
    findBlobImage,
    save,
    update,
    remove,
    removeAll,
    findMedia,
}

export default MarketplaceServices
