import { ActionTypes } from '../constants/ActionTypes'

const { RDX_VENDORTYPE_INFO, RDX_VENDORTYPE_SELECTED_INFO } = ActionTypes

export const ReducerVendorType = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_VENDORTYPE_SELECTED_INFO:
            return { ...state, vendorTypeSelectedInfo: payload }
        case RDX_VENDORTYPE_INFO:
            return { ...state, vendorTypeInfo: payload }
        default:
            return state
    }
}
