import { Skeleton } from 'antd'
import React from 'react'

const { Image, Button } = Skeleton

const PLPCards = () => {
    return (
        <div className='flex justify-between p-3 mt-2'>
            {' '}
            <div>
                <Image className='!w-52 !h-48' />
                <Skeleton className='mt-2 !w-48' />
                <div className='flex'>
                    <Button block={false} className='!h-6 !w-16 mt-2 ' />
                    <Button block={false} className='!h-6 !w-16 mt-2 !ml-10' />
                </div>
            </div>
            <div>
                <Image className='!w-52 !h-48  !ml-3' />
                <Skeleton className='mt-2 !ml-6 !w-48' />
                <div className='flex'>
                    <Button block={false} className='!h-6 !w-16 mt-2 !ml-3' />
                    <Button block={false} className='!h-6 !w-16 mt-2 !ml-10' />
                </div>
            </div>
            <div className='ml-2'>
                <Image className='!w-52 !h-48 !ml-3' />
                <Skeleton className='mt-2 !ml-6 !w-48' />
                <div className='flex'>
                    <Button block={false} className='!h-6 !w-16 mt-2 !ml-3' />
                    <Button block={false} className='!h-6 !w-16 mt-2 !ml-10' />
                </div>
            </div>
        </div>
    )
}

export default PLPCards
