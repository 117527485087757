import { CloseOutlined, EditOutlined, HolderOutlined, InboxOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row, Skeleton, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import useGetProductVariantAttributes from '../hooks/useGetProductVariantAttributes'
// import { fnAttributeDetailsTranslated, fnAttributeDetailsUpdated } from '../../../services/redux/Actions/ActionsAPICall'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import StoreModal from '../../../components/storeModal/StoreModal'
import useGetStaticAttributeValues from '../../../hooks/useGetStaticAttributeValues'
import ProductAddOptions from '../../../pages/product_new_options/ProductAddOptions'
import { fnAttributeDetailsTranslated, fnAttributeDetailsUpdated } from '../../../services/redux/actions/ActionsAPICall'
import { GetAttributesControls } from '../../../util/AttributeControls'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useRemoveVariantAttributes from '../hooks/useRemoveVariantAttribute'
import { trashCanSVG } from '../../../pages/product_new_options/trashCan'
const { Content } = Layout
const { Text } = Typography

function ListProductVariantAttributes({ productVariantAttributesData, setProductVariantAttributesData, page }) {
    const { t } = useTranslation()
    const search = useLocation().search
    const dispatch = useDispatch()
    const [langDirection, setLangDirection] = useState('ltr')
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    let productTemplateStatus = new URLSearchParams(search).get('ptrss')
    const revisionSelectedInfo = useSelector((state) => state.reducerRevisionInfo.revisionSelectedInfo)
    const productTemplateSelectedInfo = useSelector((state) => state.reducerProducts.productTemplateSelectedInfo)
    const attributeDetailsUpdated = useSelector((state) => state.reducerAPICalls.isAttributeDetailsUpdated)
    const {
        data: productVariantAttributesAPIData,
        status: productVariantAttributesStatus,
        refetch: refetchProductVariantAttributes,
    } = useGetProductVariantAttributes({
        productTemplateRevisionId: revisionSelectedInfo?.id,
        attributeDetailsUpdated: attributeDetailsUpdated,
    })
    const { data: nonEditableValues, refetch: refetchStaticAttributeValues } = useGetStaticAttributeValues({
        productTemplateId: productTemplateId,
        productTemplateRevisionId: productTemplateRevisionId,
        attributeDetailsUpdated: attributeDetailsUpdated,
    })
    const [attributeEditId, setAttributeEditId] = useState()
    const [isAttributeModalVisible, setIsAttributeModalVisible] = useState(0)
    const [enableRemoveAndEdit, setEnableRemoveAndEdit] = useState(
        revisionSelectedInfo && parseInt(revisionSelectedInfo.product_revision_status) < 2 ? true : false
    )
    const { mutate: removeVariantAttribute, status: removeVariantAttributeStatus } = useRemoveVariantAttributes()
    const [removeVariantConfirmationModal, setRemoveVariantConfirmationModal] = useState(false)
    const [removeVariantAttributeID, setRemoveVariantAttributeID] = useState()

    /**
     * !This function is to handle  the removal the attribute as a variant attribute for a particular product template revision.
     * @param {*} attributeId -> the attribute id which we are passing to add that attribute as a variant attribute.
     * @param {*} productTemplateRevisionId -> this is the product template revision id we are passing to link the attribute as a variant attribute to the passed product template revisionid.
     */
    const handleRemoveVariantAttribute = () => {
        removeVariantAttribute(
            { attributeId: removeVariantAttributeID, productTemplateRevisionId: productTemplateRevisionId },
            {
                onSuccess: (response) => {
                    refetchProductVariantAttributes()
                    refetchStaticAttributeValues()
                    console.log(response)
                    MarketplaceToaster.showToast(response)
                    setRemoveVariantConfirmationModal(false)
                    setRemoveVariantAttributeID()
                },
                onError: (err) => {
                    MarketplaceToaster.showToast(err.response)
                    setRemoveVariantConfirmationModal(false)
                    setRemoveVariantAttributeID()
                },
            }
        )
    }
    /**
     * !The function is to set the current editing attribute id and opens the modal to edit that attribute related fields such as name,help_text,options and other settings like mandatory,static and etc...,
     * @param {*} id -> This is the attribute id to open that particular modal with already available data.
     */
    const handleEditAttribute = (id) => {
        setAttributeEditId(id)
    }
    /**
     * !The function is to get the static values for the datatypes which have options.
     * @param {*} attributeId -> This will filter the static values array based on the attribute id and returns the static option.
     * @returns the static option based on the attribute id.
     */
    const getNonEditableOptionsForAttribute = (attributeId) => {
        let attributeOptions = []
        if (nonEditableValues && nonEditableValues.length > 0) {
            const attributeValueData = nonEditableValues.filter((item) => item.attribute === attributeId)
            if (attributeValueData && attributeValueData.length > 0) {
                attributeOptions = attributeValueData[0].attribute_option
            }
        }
        return attributeOptions
    }

    const editIcon = (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_4090_61293)'>
                <path
                    d='M13.3882 2.84355C12.6882 2.0998 11.9445 1.35605 11.2007 0.63418C11.0476 0.481055 10.8726 0.393555 10.6757 0.393555C10.4789 0.393555 10.282 0.45918 10.1507 0.612305L1.90386 8.79356C1.77261 8.9248 1.68511 9.07793 1.61949 9.23106L0.416363 12.9061C0.350738 13.0811 0.394488 13.2561 0.481988 13.3873C0.591363 13.5186 0.744488 13.6061 0.941363 13.6061H1.02886L4.76949 12.3592C4.94449 12.2936 5.09761 12.2061 5.20699 12.0748L13.4101 3.89355C13.5414 3.7623 13.6289 3.56543 13.6289 3.36855C13.6289 3.17168 13.5414 2.99668 13.3882 2.84355ZM4.50699 11.3967C4.48511 11.4186 4.46324 11.4186 4.44136 11.4404L1.61949 12.3811L2.56011 9.55918C2.56011 9.5373 2.58199 9.51543 2.60386 9.49356L8.61949 3.4998L10.5226 5.40293L4.50699 11.3967ZM11.2007 4.70293L9.29761 2.7998L10.632 1.46543C11.2664 2.07793 11.9007 2.73418 12.5132 3.36855L11.2007 4.70293Z'
                    fill='#023047'
                />
            </g>
            <defs>
                <clipPath id='clip0_4090_61293'>
                    <rect width='14' height='14' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )

    /**
     * !The below useEffect will be triggered when the revisionSelectedInfo is changed.
     * ?It will call the API's with that selected revision ID.
     */
    useEffect(() => {
        if (attributeDetailsUpdated || attributeDetailsUpdated === undefined) {
            setIsAttributeModalVisible(0)
        }
        if (revisionSelectedInfo) {
            if (parseInt(revisionSelectedInfo.product_revision_status) < 2) {
                setEnableRemoveAndEdit(true)
            }
        } else {
            if (parseInt(productTemplateStatus) < 2) {
                setEnableRemoveAndEdit(true)
            } else {
                setEnableRemoveAndEdit(false)
            }
        }
    }, [revisionSelectedInfo, attributeDetailsUpdated])
    useEffect(() => {
        if (productVariantAttributesAPIData) {
            if (
                productVariantAttributesAPIData.length === 0 ||
                productVariantAttributesAPIData?.[0] === 'No data Found for Product_revision_id'
            ) {
                setProductVariantAttributesData([])
            } else {
                setProductVariantAttributesData(productVariantAttributesAPIData.product_attribute_group_data)
            }
        } else {
            setProductVariantAttributesData([])
        }
    }, [productVariantAttributesAPIData])
    console.log('====>', revisionSelectedInfo)
    return (
        <Content className=''>
            {productVariantAttributesStatus === 'pending' || removeVariantAttributeStatus === 'pending' ? (
                <Skeleton paragraph={{ rows: 3 }} className='p-3' />
            ) : productVariantAttributesStatus === 'error' ? (
                <h5 className='!flex !justify-center'>{t('common:network_error')}</h5>
            ) : (
                <>
                    {productVariantAttributesAPIData &&
                    productVariantAttributesAPIData?.product_attribute_group_data &&
                    productVariantAttributesAPIData?.product_attribute_group_data.length > 0 &&
                    productVariantAttributesAPIData?.product_attribute_group_data?.[0] !==
                        'No data Found for Product_revision_id' ? (
                        <>
                            {productVariantAttributesAPIData.product_attribute_group_data &&
                                productVariantAttributesAPIData.product_attribute_group_data.length > 0 &&
                                productVariantAttributesAPIData.product_attribute_group_data.map((element) => (
                                    <Content
                                        className={`bg-white ${page === 'add_variant' ? 'p-3 rounded-lg custom-box-shadow mb-4' : 'mt-2'}`}>
                                        <span className='text-[16px] pl-2 font-medium'>{element.display_name}</span>
                                        <Content className='!mt-2'>
                                            {element.attribute &&
                                                element.attribute.length > 0 &&
                                                element.attribute.map((element1) => (
                                                    <Content>
                                                        <Content className='!flex items-center'>
                                                            <span className='mx-2 !mt-[1px]'>
                                                                {/* <HolderOutlined /> */}
                                                                <svg
                                                                    width='16'
                                                                    height='10'
                                                                    viewBox='0 0 16 10'
                                                                    fill='none'
                                                                    xmlns='http://www.w3.org/2000/svg'>
                                                                    <path
                                                                        d='M15.0008 4.4502H1.00078C0.700781 4.4502 0.425781 4.7002 0.425781 5.0252C0.425781 5.3252 0.675781 5.6002 1.00078 5.6002H15.0008C15.3008 5.6002 15.5758 5.3502 15.5758 5.0252C15.5758 4.7002 15.3008 4.4502 15.0008 4.4502Z'
                                                                        fill='#8899A8'
                                                                    />
                                                                    <path
                                                                        d='M15.0008 8.7002H1.00078C0.700781 8.7002 0.425781 8.9502 0.425781 9.2752C0.425781 9.6002 0.675781 9.8502 1.00078 9.8502H15.0008C15.3008 9.8502 15.5758 9.6002 15.5758 9.2752C15.5758 8.9502 15.3008 8.7002 15.0008 8.7002Z'
                                                                        fill='#8899A8'
                                                                    />
                                                                    <path
                                                                        d='M1.00078 1.30039H15.0008C15.3008 1.30039 15.5758 1.05039 15.5758 0.72539C15.5758 0.40039 15.3258 0.150391 15.0008 0.150391H1.00078C0.700781 0.150391 0.425781 0.40039 0.425781 0.72539C0.425781 1.05039 0.700781 1.30039 1.00078 1.30039Z'
                                                                        fill='#8899A8'
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <Tooltip
                                                                title={element1 && element1.display_name}
                                                                overlayStyle={{
                                                                    zIndex: 1,
                                                                }}
                                                                placement={langDirection === 'rtl' ? 'left' : 'right'}>
                                                                <Text
                                                                    ellipsis={true}
                                                                    className='!max-w-[100px] !text-base input-label-color'>
                                                                    {element1 && element1.display_name}
                                                                </Text>
                                                            </Tooltip>
                                                        </Content>
                                                        <Content className='my-2 !flex justify-between'>
                                                            <div className='mx-8'>
                                                                <GetAttributesControls
                                                                    optionType={element1.datatype_id}
                                                                    optionValues={element1.attribute_option}
                                                                    isEditable={true}
                                                                    nonEditableValue={getNonEditableOptionsForAttribute(
                                                                        element1.attribute_id
                                                                    )}
                                                                    placeholder={element1.placeholder}
                                                                    attribute_unit_values={
                                                                        element1.attribute_unit_values
                                                                    }
                                                                    viewTypeForDropDown={'page'}
                                                                />
                                                            </div>
                                                            <div className='!h-max flex'>
                                                                {enableRemoveAndEdit ? (
                                                                    <>
                                                                        <Button
                                                                            type='link'
                                                                            onClick={() => {
                                                                                setIsAttributeModalVisible(-1)
                                                                                dispatch(
                                                                                    fnAttributeDetailsUpdated(false)
                                                                                )
                                                                                dispatch(
                                                                                    fnAttributeDetailsTranslated(false)
                                                                                )
                                                                                handleEditAttribute(
                                                                                    element1.attribute_id
                                                                                )
                                                                            }}>
                                                                            {editIcon}
                                                                        </Button>
                                                                        <Button
                                                                            type='link'
                                                                            onClick={() => {
                                                                                setRemoveVariantConfirmationModal(true)
                                                                                setRemoveVariantAttributeID(
                                                                                    element1.attribute_id
                                                                                )
                                                                            }}>
                                                                            {trashCanSVG}
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                        </Content>
                                                    </Content>
                                                ))}
                                        </Content>
                                    </Content>
                                ))}
                        </>
                    ) : (
                        <Content className='!flex !flex-col !justify-center !items-center'>
                            <InboxOutlined className='mt-3 text-[#8899A8]' />
                            <p className='text-sm text-[#8899A8] font-normal my-2'>
                                {t('product_template:no_attributes_added')}
                            </p>
                        </Content>
                    )}
                </>
            )}
            {isAttributeModalVisible === -1 ? (
                <StoreModal
                    hideCloseButton={false}
                    isVisible={true}
                    width={1050}
                    isSpin={false}
                    okButtonText={null}
                    cancelButtonText={null}
                    removePadding={true}>
                    <Spin tip='Please wait!' size='large' spinning={false}>
                        <ProductAddOptions
                            revisionId={parseInt(productTemplateRevisionId)}
                            type={'Product_Revision'}
                            productTemplateStatus={productTemplateStatus}
                            productTemplateRevisionStatus={
                                productTemplateSelectedInfo && productTemplateSelectedInfo[0].productRevisionStatus
                            }
                            attributeId={attributeEditId}
                            isEditable={true}
                            setIsAttributeModalVisible={setIsAttributeModalVisible}
                            Choices={'Variant'}
                        />
                    </Spin>
                </StoreModal>
            ) : (
                ''
            )}
            {removeVariantConfirmationModal && (
                <StoreModal
                    isVisible={removeVariantConfirmationModal}
                    title={`${t('product_template:remove_variant_confirmation')}`}
                    okCallback={() => handleRemoveVariantAttribute()}
                    cancelCallback={() => setRemoveVariantConfirmationModal()}
                    okButtonText={`${t('common:ok')}`}
                    cancelButtonText={`${t('common:cancel')}`}
                    isSpin={false}>
                    <Content className='mb-4'>
                        <p className='!mb-0'>{t('product_template:are_you_sure_want_to_remove_variant_attribute')}?</p>
                    </Content>
                </StoreModal>
            )}
        </Content>
    )
}

export default ListProductVariantAttributes
