import TranslateVersionStatus from '../pages-2.0/version-status/TranslateVersionStatus'

const CategoryProductRevisiontitle = [
    {
        id: 1,
        title: <TranslateVersionStatus translate={'product_types:draft'} />,
        status: true,
    },
    {
        id: 2,
        title: <TranslateVersionStatus translate={'product_types:submit_for_approval'} />,
        status: true,
    },
    {
        id: 3,
        title: <TranslateVersionStatus translate={'common:approve'} />,
        status: true,
    },
    {
        id: 4,
        title: <TranslateVersionStatus translate={'product_types:rejected'} />,
        status: true,
    },
    {
        id: 5,
        title: <TranslateVersionStatus translate={'product_types:deprecated'} />,
        status: true,
    },
    {
        id: 6,
        title: <TranslateVersionStatus translate={'product_types:retired'} />,
        status: true,
    },
    {
        id: 7,
        title: <TranslateVersionStatus translate={'product_types:approval'} />,
        status: true,
    },
]
export default CategoryProductRevisiontitle.filter((element) => element.status)
