/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout, Typography, Spin } from 'antd'
import PDPLayout1 from '../ProductDetails/PDPLayout1/PDPLayout1'
import PDPLayout2 from '../ProductDetails/PDPLayout2/PDPLayout2'
import PLPLayout1 from '../ProductListing/PLPLayout1/PLPLayout1'
import PLPLayout2 from '../ProductListing/PLPLayout2/PLPLayout2'

const { Title, Text } = Typography

const Components = {
    PDPLayout1: PDPLayout1,
    PDPLayout2: PDPLayout2,
    PLPLayout1: PLPLayout1,
    PLPLayout2: PLPLayout2,
}

const DisplayMessage = () => {
    const { t } = useTranslation()
    return null
    // <Title level={5} className="mt-2 !font-normal">
    //   {t("layouts:no_preview")}
    // </Title>
}
export default (LayoutName, LayoutTitle, ProductId, BackgroundColor) => {
    if (typeof Components[LayoutName] !== 'undefined') {
        // return React.createElement(Components[LayoutName], {
        //   key: LayoutName,
        //   productId: ProductId,
        //   backgroundColor: BackgroundColor,
        // });
    }
    return React.createElement(() => DisplayMessage(), { key: LayoutName })
}
