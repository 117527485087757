import { ActionTypes } from '../constants/ActionTypes'

const { RDX_TEST_REDUX_IMPLEMENTATION, RDX_TEST_REMOVE_REDUX_IMPLEMENTATION } = ActionTypes

export const ReducerTest = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_TEST_REDUX_IMPLEMENTATION:
            return { ...state, testReduxImplementation: payload }
        case RDX_TEST_REMOVE_REDUX_IMPLEMENTATION:
            delete state.testReduxImplementation
            return { ...state }
        default:
            return state
    }
}
