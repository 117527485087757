import { ActionTypes } from '../constants/ActionTypes'

const { RDX_LAYOUT_INFO } = ActionTypes

export const ReducerLayoutInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_LAYOUT_INFO:
            return { ...state, layoutInfo: payload }
        default:
            return state
    }
}
