import { Button, Layout, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import { PlusOutlined } from '@ant-design/icons'
import { usePageTitle } from '../../hooks/usePageTitle'
const { Content } = Layout
const { Title, Text, Paragraph } = Typography

const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const couponTypeAPI = process.env.REACT_APP_COUPONS_TYPES_API
function CouponPage() {
    const search = useLocation().search
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [searchParams, setSearchParams] = useSearchParams()
    const [isCouponModalVisible, setIsCouponModalVisible] = useState(false)
    const [selectedId, setSelectedId] = useState(1)
    const [couponTypeGetCallData, setCouponTypeGetCallData] = useState()
    const [langDirection, setLangDirection] = useState('ltr')
    usePageTitle(`${t('common:store_management_portal')} - ${t('coupons:discounts')}`)


    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toUpperCase())
        }
    }, [])

    const tabDataForCoupons = [
        {
            key: 3,
            label: <p className={`!mb-0 text-[14px] !font-normal ${langDirection === 'RTL' ? 'mx-4' : ''}`}>{t('common:all')}</p>,
            value: 3,
        },
        {
            key: 1,
            label: <p className='!mb-0 text-[14px] !font-normal whitespace-nowrap'> {t('common:active')}</p>,
            value: 1,
        },
        {
            key: 0,
            label: <p className='!mb-0 text-[14px] !font-normal'> {t('languages:inactive')}</p>,
            value: 0,
        },
        {
            key: 2,
            label: <p className='!mb-0 text-[14px] !font-normal'> {t('coupons:expired')}</p>,
            value: 2,
        },
    ]

    let bydefaultSelectedTab = new URLSearchParams(search).get('tab')

    const handleTabChange = (statusId) => {
        console.log('statusIdCheck', statusId)
        if (parseInt(statusId) === 0) {
            navigate('/dashboard/coupons/list-coupons?tab=0&page=1&limit=20&' + MarketplaceAppConfig.getStore(''))
        } else if (parseInt(statusId) === 1) {
            navigate('/dashboard/coupons/list-coupons?tab=1&page=1&limit=20&' + MarketplaceAppConfig.getStore(''))
        }
        else if (parseInt(statusId) === 2) {
            navigate('/dashboard/coupons/list-coupons?tab=2&page=1&limit=20&' + MarketplaceAppConfig.getStore(''))
        }

        else {
            navigate('/dashboard/coupons/list-coupons?tab=3&page=1&limit=20&' + MarketplaceAppConfig.getStore(''))
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        setSearchParams({
            tab:
                parseInt(searchParams.get('tab')) && parseInt(searchParams.get('tab')) <= 3
                    ? parseInt(searchParams.get('tab'))
                    : 3,
            page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
            limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }, [])

    const onChange = (e) => {
        setSelectedId(e.target.value)
    }

    const createDiscount = () => {
        // const selectedOption =
        //   couponTypeGetCallData &&
        //   couponTypeGetCallData.find(
        //     (option) => option.coupon_choice === selectedId
        //   );
        // if (selectedOption) {
        //   const selectedName = selectedOption.coupon_type;
        navigate(`/dashboard/coupons/create-discount?a=${'add'}&${MarketplaceAppConfig.getStore('')}`)
        // setIsCouponModalVisible(false);
        // }
    }

    //! GET call for list of coupon discount type
    const findByPageCouponsTypesGetCall = () => {
        MarketplaceServices.findAllWithoutPage(couponTypeAPI, null, false)
            .then(function (res) {
                console.log('from discount type', res)
                setCouponTypeGetCallData(res.data.response_body)
            })
            .catch((error) => {
                console.log('Server Error Response from Approvals GET API Call', error.response)
            })
    }
    useEffect(() => {
        // findByPageCouponsTypesGetCall();
    }, [])

    return (
        <Content>
            <Content>
                <HeaderForTitle
                    title={
                        <Content className='flex'>
                            <Content className='!w-[80%]'>
                                <Title level={3} className='!font-semibold !text-regal-blue !text-[24px]'>
                                    {t('coupons:discounts')}
                                </Title>
                                {/* <Paragraph className="font-semibold text-slate-400 !m-0">
                  {"Discounts description will come here..."}
                </Paragraph> */}
                                <div className='!h-11'>
                                    <DmTabAntDesign
                                        tabData={tabDataForCoupons}
                                        handleTabChangeFunction={handleTabChange}
                                        defaultSelectedTabKey={0}
                                        activeKey={parseInt(bydefaultSelectedTab)}
                                        tabBarPosition={'top'}
                                        tabType={'line'}
                                    />
                                </div>
                            </Content>
                            <Content className={`!w-[8%] ${langDirection === 'RTL' ? 'text-left' : 'text-right'}`}>
                                <Button className='app-btn-primary flex items-center' onClick={() => createDiscount()}>
                                    <PlusOutlined />
                                    {t('coupons:create_coupon')}
                                </Button>
                            </Content>
                        </Content>
                    }
                />
            </Content>
            {/* <Content className="!mt-[6rem] !p-[1rem]">
        <DmTabAntDesign
          tabData={tabDataForCoupons}
          handleTabChangeFunction={handleTabChange}
          defaultSelectedTabKey={1}
          activeKey={parseInt(bydefaultSelectedTab)}
          tabBarPosition={"top"}
          tabType={"line"}
        />
        <Outlet />
      </Content> */}
            <Content className='!mt-[7rem] !p-[1rem]'>
                <Outlet />
            </Content>

            {/* <StoreModal
        isVisible={isCouponModalVisible}
        title={"Choose Discounts Type"}
        okCallback={() => createDiscount()}
        okButtonText={"Create"}
        cancelButtonText={null}
        cancelCallback={() => {
          setIsCouponModalVisible(false);
          setSelectedId(1);
        }}
        isSpin={false}
      >
        <Content className="p-3">
          <Radio.Group onChange={onChange} value={selectedId}>
            <Space direction="vertical">
              {couponTypeGetCallData &&
                couponTypeGetCallData.length > 0 &&
                couponTypeGetCallData.map((option) => (
                  <Radio
                    key={option.coupon_choice}
                    value={option.coupon_choice}
                  >
                    {option.coupon_type}
                  </Radio>
                ))}
            </Space>
          </Radio.Group>
        </Content>
      </StoreModal> */}
        </Content>
    )
}

export default CouponPage
