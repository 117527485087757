//! Import libraries
import { Badge, Button, Col, Dropdown, Layout, Space, Tag, Tooltip, Typography } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
//! Import user defined components
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { DownloadIconDisable, plusIcon, starIcon } from '../../constants/media'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import DynamicTable from '../tablelayoutcomponent/DynamicTable'

import { DownOutlined } from '@ant-design/icons'
import { MdEdit } from 'react-icons/md'
import MarketplaceToaster from '../../util/marketplaceToaster'
import LanguageBanner from './LanguageBanner'
const { Title, Text, Paragraph } = Typography
const { Content } = Layout

const languageAPI = process.env.REACT_APP_STORE_LANGUAGE_API
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const LanguageDownloadAPI = process.env.REACT_APP_DOWNLOAD_LANGUAGE_TRANSLATION_CSV
const downloadBackendKeysAPI = process.env.REACT_APP_DOWNLOAD_STORE_BACKEND_MESSAGE_DETAILS
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const Language = () => {
    const { t } = useTranslation()

    usePageTitle(t('common:store_management_portal') + ' - ' + String(` ${t('common:language_settings')}`))
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [languageData, setLanguageData] = useState([])
    const [isNetworkErrorLanguage, setIsNetworkErrorLanguage] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [totalLanguageCount, setTotalLanguageCount] = useState()
    const [showAddLanguageBtn, setShowAddLanguageBtn] = useState(false)
    const permissionsData = util.getPermissionData() || []

    const StarIcon = () => {
        return (
            <>
                <img src={starIcon} className='mr-1 flex !items-center' alt='starIcon' />
            </>
        )
    }

    const columns = [
        {
            title: `${t('languages:language')}`,
            dataIndex: 'language',
            key: 'language',
            width: '30%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className='flex gap-1'>
                        <Tooltip overlayStyle={{ zIndex: 1 }} title={record.language} placement='bottom'>
                            <Text
                                className={` !text-[#637381] ${record.is_default ? '!max-w-[215px]' : '!max-w-[290px]'} `}
                                ellipsis={true}>
                                {record.language}
                            </Text>
                        </Tooltip>
                        {record.is_default ? (
                            <Tag className='inline-flex items-center rounded-xl' color='#FB8500'>
                                {t('languages:default_language')}
                            </Tag>
                        ) : (
                            ''
                        )}
                    </Content>
                )
            },
        },
        {
            title: `${t('languages:code')}`,
            dataIndex: 'language_code',
            key: 'language_code',
            width: '12%',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip overlayStyle={{ zIndex: 1 }} title={record.language_code}>
                            <Text className='max-w-xs !text-[#637381]' ellipsis={{ tooltip: record.language_code }}>
                                {record.language_code}
                            </Text>
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: `${t('languages:script_direction')}`,
            dataIndex: 'writing_script_direction',
            key: 'writing_script_direction',
            ellipsis: true,
            width: '15%',
            render: (text, record) => {
                return (
                    <>
                        {record.writing_script_direction === 'LTR' ? (
                            <Tag color='success'> {t('languages:left_to_right')}</Tag>
                        ) : (
                            <Tag color='blue'>{t('languages:right_to_left')}</Tag>
                        )}
                    </>
                )
            },
        },
        {
            title: `${t('languages:status')}`,
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (text, record) => {
                return (
                    <>
                        <Text>
                            {parseInt(record.status) === 2 ? (
                                <Badge status='default' text={`${t('languages:inactive_label')}`} />
                            ) : (
                                <Badge status='success' text={`${t('languages:active_label')}`} />
                            )}
                        </Text>
                    </>
                )
            },
        },
        {
            title: `${t('languages:support_document')}`,
            dataIndex: 'lang_support_docs',
            key: 'lang_support_docs',
            width: '23%',
            // align: 'center',
            render: (text, record) => {
                return (
                    <Content className='flex whitespace-nowrap items-center'>
                        {record.lang_support_docs_path !== null ? (
                            <Button
                                type='text'
                                className='app-btn-text flex gap-1 justify-center items-center'
                                onClick={() => {
                                    findAllSupportDocumentTemplateDownload(2, record.language_code)
                                }}>
                                <DownloadOutlined />
                                <span
                                    className={
                                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!mr-1' : '!ml-1'
                                    }>
                                    {t('languages:download_document')}
                                </span>
                            </Button>
                        ) : (
                            <Button
                                type='text'
                                className='app-btn-text flex gap-1 justify-center items-center cursor-not-allowed'>
                                <img
                                    src={DownloadIconDisable}
                                    className='!text-xs !w-3  !items-center'
                                    alt='downloadIcon'
                                />
                                {t('languages:download_document')}
                            </Button>
                        )}
                    </Content>
                )
            },
        },
        {
            title: `${t('languages:action')}`,
            dataIndex: '',
            key: '',
            width: '8%',
            align: 'center',
            render: (text, record) => {
                return (
                    <Col className='whitespace-nowrap flex justify-center items-center '>
                        <Tooltip mouseLeaveDelay={0} title={`${t('common:edit')} ${record.language}`}>
                            <Button
                                type='text'
                                className='app-btn-icon app-edit-icon svgIcon'
                                onClick={() => {
                                    navigate(
                                        `/dashboard/languages/language-settings?${MarketplaceAppConfig.getStore(
                                            ' '
                                        )}&k=${record.id}&n=${record.language}&c=${
                                            record.language_code
                                        }&s=${record.status}&d=${record.is_default === false ? 0 : 1}`
                                    )
                                }}>
                                <svg
                                    width='14'
                                    height='15'
                                    viewBox='0 0 14 15'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M13.3882 3.34453C12.6882 2.60078 11.9445 1.85703 11.2007 1.13516C11.0476 0.982031 10.8726 0.894531 10.6757 0.894531C10.4789 0.894531 10.282 0.960156 10.1507 1.11328L1.90386 9.29453C1.77261 9.42578 1.68511 9.57891 1.61949 9.73203L0.416363 13.407C0.350738 13.582 0.394488 13.757 0.481988 13.8883C0.591363 14.0195 0.744488 14.107 0.941363 14.107H1.02886L4.76949 12.8602C4.94449 12.7945 5.09761 12.707 5.20699 12.5758L13.4101 4.39453C13.5414 4.26328 13.6289 4.06641 13.6289 3.86953C13.6289 3.67266 13.5414 3.49766 13.3882 3.34453ZM4.50699 11.8977C4.48511 11.9195 4.46324 11.9195 4.44136 11.9414L1.61949 12.882L2.56011 10.0602C2.56011 10.0383 2.58199 10.0164 2.60386 9.99453L8.61949 4.00078L10.5226 5.90391L4.50699 11.8977ZM11.2007 5.20391L9.29761 3.30078L10.632 1.96641C11.2664 2.57891 11.9007 3.23516 12.5132 3.86953L11.2007 5.20391Z'
                                        fill='#637381'
                                    />
                                </svg>
                            </Button>
                        </Tooltip>
                    </Col>
                )
            },
        },
    ]

    const items = [
        {
            key: 1,
            label: `${t('languages:get_frontend_support_template')}`,
        },
        {
            key: 2,
            label: `${t('languages:get_backend_support_template')}`,
        },
    ]

    const languageListData = (filteredData) => {
        const tempArray = []
        if (filteredData && filteredData.length > 0) {
            filteredData &&
                filteredData.length > 0 &&
                filteredData.forEach((element, index) => {
                    var Id = element.id
                    var Language = element.language
                    var LanguageCode = element.language_code
                    var Writing_script_direction = element.writing_script_direction
                    var Native_name = element.native_name
                    var Lang_support_docs = element.lang_support_docs
                    var Language_document_path = element.lang_support_docs_path
                    var Dm_language_regex = element.language_regex
                    var is_default = element.is_default
                    var status = element.status
                    tempArray &&
                        tempArray.push({
                            key: index,
                            id: Id,
                            language: Language,
                            language_code: LanguageCode,
                            writing_script_direction: Writing_script_direction,
                            native_name: Native_name,
                            lang_support_docs: Lang_support_docs,
                            dm_language_regex: Dm_language_regex,
                            lang_support_docs_path: Language_document_path,
                            is_default: is_default,
                            status: status,
                        })
                })
            return tempArray
        } else {
            return tempArray
        }
    }
    //!get call of list language
    const findByPageLanguageData = (page, limit) => {
        // enabling spinner
        setIsLoading(true)
        MarketplaceServices.findByPage(languageAPI, null, page, limit)
            .then(function (response) {
                console.log(
                    'server Success response from findByPageLanguageData is',
                    languageAPI,
                    response.data.response_body
                )
                setIsLoading(false)
                setIsNetworkErrorLanguage(false)
                if (response?.data?.response_body?.data) {
                    setLanguageData(languageListData(response.data.response_body.data))
                }
                setTotalLanguageCount(response.data.response_body.count)
            })
            .catch((error) => {
                console.log('server error response from findByPageLanguageData', languageAPI, error.response)
                setIsLoading(false)
                setIsNetworkErrorLanguage(true)
                // MarketplaceToaster.showToast(error.response);
                if (error && error.response && error.response.status === 401) {
                    MarketplaceToaster.showToast(util.getToastObject(`Session expired`, 'error'))
                } else {
                    if (error.response.data.response_body.message === 'That page contains no results') {
                        setSearchParams({
                            page: 1,
                            limit: parseInt(searchParams.get('limit')),
                        })
                    }
                }
            })
    }

    //! get call of get document template API
    const findAllSupportDocumentTemplateDownload = (formatOption, langCode) => {
        MarketplaceServices.findMedia(LanguageDownloadAPI, {
            'is-format': formatOption,
            language_code: langCode,
        })
            .then(function (response) {
                console.log(
                    'Server Response from findAllSupportDocumentTemplateDownload',
                    LanguageDownloadAPI,
                    response.data
                )
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'key_value_format.csv'
                alink.click()
            })
            .catch((error) => {
                console.log('Server error from findAllSupportDocumentTemplateDownload', LanguageDownloadAPI, error)
            })
    }

    const ProductSortingOption = [
        {
            sortType: 'asc',
            sortKey: 'id',
            title: 'Title A-Z',
            default: true,
        },
        {
            sortType: 'desc',
            sortKey: 'id',
            title: 'Title Z-A',
            default: false,
        },
    ]

    //!dynamic table data
    const tablePropsData = {
        table_header: columns,
        table_content: languageData,
        search_settings: {
            is_enabled: false,
            search_title: 'Search by language',
            search_data: ['language'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: 'filter by',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: ProductSortingOption,
        },
    }
    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : pageLimit,
        })
    }

    const downloadBEKeysFile = () => {
        MarketplaceServices.findMedia(downloadBackendKeysAPI, {
            'is-format': 1,
        })
            .then(function (response) {
                console.log('Server Response from DocumentTemplateDownload Function: ', response.data)
                const fileURL = window.URL.createObjectURL(response.data)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'message_format.csv'
                alink.click()
            })
            .catch((error) => {
                console.log('Server error from DocumentTemplateDownload Function ', error.response)
            })
    }

    const handleOnclickForDownloadDocument = (e) => {
        parseInt(e.key) === 1 ? findAllSupportDocumentTemplateDownload(1, null) : downloadBEKeysFile()
    }

    useEffect(() => {
        findByPageLanguageData(
            searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
            searchParams.get('limit') ? parseInt(searchParams.get('limit')) : pageLimit
        )
        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        //show and hide add language btn
        if (permissionsData && permissionsData.includes('UI-create-edit-language')) {
            setShowAddLanguageBtn(true)
        }
    }, [])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content>
            <Content>
                <HeaderForTitle
                    title={
                        <Content className='flex'>
                            <Content className=''>
                                <Title level={3} className='!font-medium'>
                                    {t('common:language_settings')}
                                </Title>
                            </Content>
                            <Content className=' text-right'>
                                {showAddLanguageBtn ? (
                                    <>
                                        <Content className='!flex gap-2 items-center !justify-end'>
                                            <Dropdown
                                                className='app-btn-link'
                                                placement='bottomRight'
                                                arrow
                                                menu={{
                                                    items,
                                                    onClick: handleOnclickForDownloadDocument,
                                                }}>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        {t('languages:download_support_document_template')}
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                            <Button
                                                className='app-btn-primary !flex gap-2 align-items-center justify-center'
                                                onClick={() => navigate('/dashboard/languages/language-settings')}>
                                                <img
                                                    src={plusIcon}
                                                    alt='plusIconWithAddLanguage'
                                                    className='!text-xs !w-3 !items-center'
                                                />
                                                <div className='mr-[10px]'>{t('languages:add_language')}</div>
                                            </Button>
                                        </Content>
                                    </>
                                ) : (
                                    ''
                                )}
                            </Content>
                        </Content>
                    }
                    headerContent={
                        <Paragraph className='!font-normal !text-[#637381]  !m-0'>
                            {t('common:lang_short_desc')}
                        </Paragraph>
                    }
                />
            </Content>
            <Content className='p-3 !min-h-screen'>
                {isLoading ? (
                    <Content className=' bg-white p-3 '>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkErrorLanguage ? (
                    <Content className='p-3 text-center mb-3 bg-[#F4F4F4]'>
                        <h5>{t('common:network_error')}</h5>
                    </Content>
                ) : (
                    <>
                        <Content className='bg-white p-3 shadow-brandShadow rounded-lg'>
                            <DynamicTable tableComponentData={tablePropsData} />
                            {totalLanguageCount && totalLanguageCount >= pageLimit ? (
                                <Content className=' grid justify-items-end'>
                                    <DmPagination
                                        currentPage={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
                                        presentPage={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
                                        totalItemsCount={totalLanguageCount}
                                        pageLimit={pageLimit}
                                        pageSize={
                                            searchParams.get('limit') ? parseInt(searchParams.get('limit')) : pageLimit
                                        }
                                        handlePageNumberChange={handlePageNumberChange}
                                        showSizeChanger={true}
                                        showTotal={true}
                                        showQuickJumper={true}
                                    />
                                </Content>
                            ) : null}
                        </Content>
                        {totalLanguageCount && totalLanguageCount === 1 && languageData[0].language_code ? (
                            <Content className='bg-white mt-4 p-2'>
                                <LanguageBanner></LanguageBanner>
                            </Content>
                        ) : null}
                    </>
                )}
            </Content>
        </Content>
    )
}

export default Language
