import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    DeleteOutlined,
    InfoCircleTwoTone,
    PlusOutlined,
    TranslationOutlined,
} from '@ant-design/icons'
import { Alert, Button, Checkbox, Col, Divider, Input, Layout, Radio, Row, Select, Tooltip, Typography } from 'antd'
import { TrashCan } from '../../constants/media'
import { Content } from 'antd/es/layout/layout'
import axios from 'axios'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { v4 as uuidv4 } from 'uuid'
import validator from 'validator'
import StoreModal from '../../components/storeModal/StoreModal'
import attibutePrefernce from '../../constants/AttributePreference'
import attibuteTypes from '../../constants/AttributeTypes'
import AttributeTranslatorModal from '../../pages-2.0/AttributeGroups/AttributeTranslatorModal'
import ListImageAndAddImage from '../../pages/media/ListImageAndAddImage'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import {
    deepCopy,
    generateObjectFromUrl,
    generateObjectFromUrlWithFullImagePath,
    getDateFromCurrentFormatToNewFormat,
    getImageUrl,
    testValueByRegexPattern,
} from '../../util/util'
import { validatePositiveNumber } from '../../util/validation'
import SegmentForm from '../segments/SegmentForm'
import AttributeUnitControl from './AttributeUnitControl'
import { getDefaultValueControlType } from './ProductOptionDefaultValueUtil'
import { RiInformationLine } from 'react-icons/ri'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { fnAttributeDetailsUpdated } from '../../services/redux/actions/ActionsAPICall'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import AttributeOptionControls from './AttributeOptionControls'
import StaticAttributeControls from './StaticAttributeControls'
import './productoption.css'
import { trashCanSVG } from './trashCan'
const { Text } = Typography
const attributeOptionAPI = process.env.REACT_APP_ATTRIBUTE_OPTION_API
const atttriuteNonEditableValuesAPI = process.env.REACT_APP_NON_EDITABLE_ATTRIBUTE_VALUES_API
const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)

const prefixMinLength = parseInt(process.env.REACT_APP_ATTRIBUTE_PREFIX_MIN_LENGTH)
const prefixMaxLength = parseInt(process.env.REACT_APP_ATTRIBUTE_PREFIX_MAX_LENGTH)
const suffixMinLength = parseInt(process.env.REACT_APP_ATTRIBUTE_SUFFIX_MIN_LENGTH)
const suffixMaxLength = parseInt(process.env.REACT_APP_ATTRIBUTE_SUFFIX_MAX_LENGTH)
const decimalMinValue = process.env.REACT_APP_DECIMAL_UNIT_MIN_LIMIT

function ProductAddOptionValues({
    attributeTagData,
    closeAddOptionValuesForm,
    openChooseOptionWindow,
    selectedOption,
    setProductMenus,
    prodMenu,
    isEditting,
    editedOption,
    setIsEditting,
    selectedDataType,
    prodAttrib,
    prodValues,
    editedOptionValueName,
    isSeverData,
    selectedRegEx,
    regExExceptionMessage,
    dataGroup,
    attributeUnit,
    segementId,
    minimumNoOfFields,
    maximumNoOfFields,
    attribType,
    attributeNonEditableValues,
    setAttributeNonEditableValues,
    setProductAttribOptionValues,
    attributeExtension,
    attributeFileType,
    type,
    attributeGroupData,
    defaultLngsCode,
    setCalculativeExpression,
    calculativeExpression,
    revisionId,
    attibuteDataProcessor,
    setCalculativeExpressionEdit,
    calculativeExpressionEdit,
    tabId,
    isCore,
    phoneCode,
    setPhoneCode,
    selectedAttributeType,
    setSelectedAttributeType,
    choices,
    getNonEditableAttributeValuesFromServer,
    attributeNameAlreadyExist,
}) {
    // filtering the journey type for the varinat attribute, as it not in scope for the variant
    let attributeType = choices === 'Variant' ? attribType.filter((value) => value !== 'Journey') : attribType
    const { t } = useTranslation()
    const [deleteOptionValueInfo, setDeleteOptionValueInfo] = useState(false)
    const [optionValueDeletionId, setOptionValueDeletionId] = useState([])
    const [deleteOptionvalueId, setDeleteOptionvalueId] = useState()
    const [deleteOptionvalue, setDeleteOptionvalue] = useState()
    const [isOptionValueDeletionModalOpen, setIsOptionValueDeletionModalOpen] = useState(false)
    const [isDeleteOptionValuesWarningModal, setIsDeleteOptionValuesWarningModal] = useState(false)
    const [openImageModel, setOpenImageModel] = useState(false)
    const [imageData, setImageData] = useState('')
    const [isAttributeValueSelected, setIsAttributeValueSelected] = useState(true)
    const [deleteAttributeValue, setDeletAttributeValue] = useState(false)
    const [isPrefixFieldEmpty, setIsPrefixFieldEmpty] = useState(false)
    const [isSufixFieldEmpty, setIsSufixFieldEmpty] = useState(false)
    const [isAttributeGroupEmpty, setIsAttributeGroupEmpty] = useState(false)
    const [isSegmentForm, setIsSegmentForm] = useState(false)
    const [productAttributeGroupData, setProductAttributeGroupData] = useState(
        attributeGroupData === undefined ? [] : attributeGroupData
    )
    const [responseFromAttributeGroupForm, setResponseFromAttributeGroupForm] = useState()

    // This const is added to highlight the attribute type dropdown, when user not selecting any option
    const [attributeTypeFieldEmpty, setAttributeTypeFieldEmpty] = useState(false)
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [attributeNameDetailsForTranslater, setAttributeNameDetailsForTranslater] = useState()
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)

    const [attributeOptionNameDetailsForTranslater, setAttributeOptionNameDetailsForTranslater] = useState()
    const [disableStatic, setDisableStatic] = useState(false)
    const [disableRevisable, setDisableRevisable] = useState(false)
    const [disableMandatory, setDisableMandatory] = useState(false)
    const [disableSensible, setDisableSensible] = useState(false)
    const [langDirection, setLangDirection] = useState('ltr')

    const dispatch = useDispatch()

    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    const attributeDetailsTranslated = useSelector((state) => state.reducerAPICalls.isAttributeDetailsTranslated)
    const [maxAndminFailed, setMaxAndminFailed] = useState(false)
    const [isExtensionRequired, setIsExtensionRequired] = useState(true)
    const [saveButtonClicked, setSaveButtonClicked] = useState(false)
    const [isPhoneCodeNotValid, setIsPhoneCodeNotValid] = useState(false)
    const [invalidAttributeValue, setInvalidAttributeValue] = useState(false)
    const [isAttributeOptionDeleted, setIsAttributeOptionDeleted] = useState(false)
    const [isMultilingualCallSuccess, setIsMultilingualCallSuccess] = useState(false)
    const [isOptionValueFieldEditted, setIsOptionValueFieldEditted] = useState(false)
    const [attributeTypeValue, setAttributeTypeValue] = useState('Simple')

    let copyOfOptionValues = JSON.parse(JSON.stringify(prodValues))
    const closeOptionValueDeleteModal = () => {
        setIsOptionValueDeletionModalOpen(false)
    }
    const closeDeleteOptionValuesWarningModal = () => {
        setproductOptionValues(copyOfOptionValues.filter((obj) => obj.attributeName === editedOptionValueName)[0])
        const defaultAttributeValues = getAttributeValues(
            copyOfOptionValues.filter((obj) => obj.attributeName === editedOptionValueName)[0]
        )
        setProductAttributeValues(defaultAttributeValues)
        setOptionValueDeletionId([])
        setIsDeleteOptionValuesWarningModal(false)
    }

    // this useEffect will be triggered to notify to select the attribute type
    useEffect(() => {
        // resetting the phone code
        if (!isEditting) {
            setPhoneCode('+91')
        }
    }, [tabId, isEditting])

    // The useffect will be triggered when the multilingual post call is sucess
    useEffect(() => {
        if (isMultilingualCallSuccess) {
            getNonEditableAttributeValuesFromServer()
        }
    }, [isMultilingualCallSuccess])

    // disabling the revisable and static, when attribute type is journey during edit
    useEffect(() => {
        if (isEditting && selectedAttributeType === 'Journey') {
            setDisableRevisable(true)
            setDisableStatic(true)
        }
    }, [isEditting, selectedAttributeType])

    useEffect(() => {
        let optionsCopy = { ...productOptions }
        if (attributeTypeValue === 'Journey') {
            optionsCopy.attributes.revisable = false
            optionsCopy.attributes.is_static = false
            setDisableMandatory(false)
            setDisableRevisable(false)
            setDisableStatic(false)
            setProductOptions(optionsCopy)
        }
    }, [attributeTypeValue])

    const validatePositiveFloatNumber = (e, regex, max, decimal) => {
        const key = e.keyCode || e.which
        const keyChar = String.fromCharCode(key)
        const input = e.target
        const cursorPosition = input.selectionStart

        const beforeCursor = input.value.substring(0, cursorPosition)
        const afterCursor = input.value.substring(cursorPosition)

        const value = beforeCursor + keyChar + afterCursor
        console.log('alpha', !regex.test(value))
        if (
            (!regex.test(value) && key !== 8 && key !== 46) ||
            keyChar === ' ' ||
            (input.value.indexOf('.') !== -1 && keyChar === '.') ||
            (input.value.indexOf('.') !== -1 && value.split('.')[1].length > decimal) ||
            parseFloat(value) > parseFloat(max)
        ) {
            e.preventDefault()
        }
    }

    // Below processor is used to process the arribute unit recevied from the server
    // They are used as the default fields for the attriute option for creating prefix and suffix textfield
    const attributeUnitprocessor = () => {
        let proccessedAttribUnits
        if (selectedDataType === 24) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      return {
                          key: key,
                          value: [''],
                      }
                  })
                : []
        } else if (selectedDataType === 26) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      if (key == 'min') {
                          return {
                              key: key,
                              value: [''],
                          }
                      } else if (key == 'decimal') {
                          return {
                              key: key,
                              value: [decimalMinValue],
                          }
                      } else {
                          return {
                              key: key,
                              value: [''],
                          }
                      }
                  })
                : []
        } else if (selectedDataType === 25) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      if (key == 'decimal') {
                          return {
                              key: key,
                              value: [decimalMinValue],
                          }
                      } else {
                          return {
                              key: key,
                              value: [''],
                          }
                      }
                  })
                : []
        } else if (selectedDataType === 6) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      if (key == 'min_value') {
                          return {
                              key: key,
                              value: ['0'],
                          }
                      } else if (key == 'limiter') {
                          return {
                              key: key,
                              value: ['1'],
                          }
                      } else {
                          return {
                              key: key,
                              value: [''],
                          }
                      }
                  })
                : []
        } else if (selectedDataType === 23) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      return {
                          key: key,
                          value: [''],
                      }
                  })
                : []
        } else if (
            selectedDataType === 15 ||
            selectedDataType === 18 ||
            selectedDataType === 19 ||
            selectedDataType === 16 ||
            selectedDataType === 17 ||
            selectedDataType === 29
        ) {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      if (key == 'ext') {
                          return {
                              key: key,
                              value: [],
                          }
                      }
                  })
                : []
        } else {
            proccessedAttribUnits = attributeUnit
                ? attributeUnit.map(({ id, key }) => {
                      return {
                          key: key,
                          value: [''],
                      }
                  })
                : []
        }
        return proccessedAttribUnits
    }

    // Took a deep copy of the prodMenu prop usin JSON.Parse and JSON.string method,
    // Because shallow copy of the prodMenu Prop using spread operator, was mutating the original prodMenu Prop instead of taking copy...
    let copyOfMenu = deepCopy(prodMenu)

    //  Todo, commenting the code [105] related to arrtibute tag processor, will be used once the attribute tag api ready..
    // const defaultOption = isEditting ? attributeTagProcessor(copyOfMenu.filter((obj) => obj.id === editedOption)[0]) : {
    const defaultOption = isEditting
        ? copyOfMenu.filter((obj) => obj.id === editedOption)[0]
        : {
              // Generating Random Ids for Option Menus
              id: uuidv4(),
              optionType: selectedOption,
              option_Name: '',
              is_serverData: false,
              is_attribute_option_edited: false,
              is_AttributeFieldEmpty: false,
              attributes: {
                  name: '',
                  mandatory: false,
                  sensible: false,
                  revisable: false,
                  is_static: false,
                  unique: false,
                  hide_to_customer: false,
                  varient_specific: false,
                  is_variant_attribute: false,
                  order_specific: false,
                  help_text: '',
                  placeholder: '',
                  datatype: selectedDataType,
                  mapping_with: 'string',
                  mapping_id: 0,
                  mapping_editable: true,
                  multilingual: true,
                  attribute_tag: attributeTagData,
                  comparable: false,
                  attribute_unit_value: attributeUnitprocessor(),
                  attribute_type: 'Simple',
                  product_attribute_group:
                      productAttributeGroupData && productAttributeGroupData.length > 0
                          ? productAttributeGroupData[0].id
                          : '',
              },
              attribute_displayname: '',
              attributeValuePrefix: false,
              maxLengthForName: 100,
              attributeValueSuffix: false,
              isNonEditableValueEdited: false,
          }
    const [productOptions, setProductOptions] = useState(defaultOption)

    // This useEffect will be triggerd when ever the attribute response got updated..
    useEffect(() => {
        let copyOfProductAttributeGroupData = [...productAttributeGroupData]
        let copyOfProductOptions = { ...productOptions }
        if (responseFromAttributeGroupForm) {
            copyOfProductAttributeGroupData.unshift(responseFromAttributeGroupForm[0])
            copyOfProductOptions.attributes.product_attribute_group = responseFromAttributeGroupForm[0].id
            copyOfProductOptions.is_attribute_option_edited = true
            setProductOptions(copyOfProductOptions)
            setProductAttributeGroupData(copyOfProductAttributeGroupData)
            closeSegmentForm()
        } else if (responseFromAttributeGroupForm === null) {
            closeSegmentForm()
        }
    }, [responseFromAttributeGroupForm])

    const openSegmentForm = () => {
        setIsSegmentForm(true)
    }

    const closeSegmentForm = () => {
        setIsSegmentForm(false)
    }

    //  Below const are used for option Values logic
    const getDefaultOptionValues = () => {
        let defaultInputFields = []
        // special case for the fileImage Part
        if (
            selectedDataType === 18 &&
            selectedDataType === 19 &&
            selectedDataType === 16 &&
            selectedDataType === 15 &&
            selectedDataType === 17 &&
            selectedDataType === 29
        ) {
            if (imageData && imageData.length > 0) {
                // uploadData.forEach((data)=>{
                defaultInputFields.push({
                    id: uuidv4(),
                    attribute: productOptions.option_Name,
                    option_name: imageData,
                    isInValidField: false,
                    isAttributeOptionValueEdited: false,
                    isAttributeOptionValueFieldEmpty: false,
                    defaultValue: [''],
                    defaultValueType: getDefaultValueControlType(selectedDataType),
                    selectedNonEditableValue: false,
                    maxLengthForOption: 255,
                    option_displayname: '',
                    attributeValueSelected: false,
                })
                // })
            } else {
                defaultInputFields.push({
                    id: uuidv4(),
                    attribute: '',
                    option_name: '',
                    isInValidField: false,
                    isAttributeOptionValueEdited: false,
                    isAttributeOptionValueFieldEmpty: false,
                    defaultValue: [''],
                    defaultValueType: getDefaultValueControlType(selectedDataType),
                    selectedNonEditableValue: false,
                    maxLengthForOption: 255,
                    option_displayname: '',
                    attributeValueSelected: false,
                })
            }
        } else {
            // adding default input fields for all the datatype based on the minimum_number_values param
            for (let i = 0; i < minimumNoOfFields; i++) {
                defaultInputFields.push({
                    id: uuidv4(),
                    attribute: '',
                    option_name: '',
                    isInValidField: false,
                    isAttributeOptionValueEdited: false,
                    isAttributeOptionValueFieldEmpty: false,
                    defaultValue: [''],
                    defaultValueType: getDefaultValueControlType(selectedDataType),
                    selectedNonEditableValue: false,
                    maxLengthForOption: 255,
                    option_displayname: '',
                    attributeValueSelected: false,
                })
            }
        }
        return defaultInputFields
    }

    const defaultProductOptionValues = isEditting
        ? copyOfOptionValues.filter((obj) => obj.attributeName === editedOptionValueName)[0]
        : {
              attributeName: '',
              attributeID: productOptions.id,
              attribute_options: getDefaultOptionValues(),
              non_editableattribute_value: '',
              datatype: selectedDataType,
              dataGroup: dataGroup,
              isNonEditable: productOptions.attributes.is_static,
          }

    const [productOptionValues, setproductOptionValues] = useState(defaultProductOptionValues)

    const defaultTextRichEditorText =
        isEditting && selectedDataType === 10
            ? copyOfOptionValues.filter((obj) => obj.attributeName === editedOptionValueName)[0]
                ? copyOfOptionValues.filter((obj) => obj.attributeName === editedOptionValueName)[0]
                      .non_editableattribute_value
                : ''
            : ''

    const [textRichEditorData, setTextRichEditorData] = useState(defaultTextRichEditorText)

    const getDefaultValueofToggleForEdit = (selectedNonEditableValue, index) => {
        if (typeof selectedNonEditableValue == 'string' && selectedNonEditableValue == '' && index == 1) {
            return false
        } else if (typeof selectedNonEditableValue == 'string' && selectedNonEditableValue == '') {
            return true
        }
        return selectedNonEditableValue
    }

    // This function will create the JSON for the atttribute Values.....when ever it is called.
    // only when the attribute option are present.
    const getAttributeValues = (prodOptionValues) => {
        let defaultAttributeValues = []
        if (
            prodOptionValues &&
            prodOptionValues.attribute_options.length > 0 &&
            prodOptionValues.dataGroup === 'Optional'
        ) {
            if (isEditting) {
                prodOptionValues.attribute_options.map(
                    ({ id, option_name, selectedNonEditableValue, attributeValueSelected }, index) => {
                        return defaultAttributeValues.push({
                            attribute_id: productOptions.id,
                            attribute_value: option_name,
                            attributeValueSelected:
                                selectedDataType == 4
                                    ? getDefaultValueofToggleForEdit(selectedNonEditableValue, index)
                                    : selectedNonEditableValue || attributeValueSelected,
                            attribute_option: id,
                        })
                    }
                )
            } else {
                prodOptionValues.attribute_options.map(({ id, option_name, attributeValueSelected }, index) => {
                    return defaultAttributeValues.push({
                        attribute_id: productOptions.id,
                        attribute_value: option_name,
                        // special case for toggle, since one value should be selected by default
                        attributeValueSelected:
                            selectedDataType == 4 ? (index == 1 ? false : true) : attributeValueSelected,
                        attribute_option: id,
                    })
                })
            }
            return defaultAttributeValues
        } else if (
            prodOptionValues &&
            prodOptionValues.dataGroup !== 'Optional' &&
            prodOptionValues.dataGroup !== 'Upload' &&
            prodOptionValues.dataGroup !== 'Range'
        ) {
            defaultAttributeValues.push({
                attribute_id: productOptions.id,
                attribute_value:
                    prodOptionValues.non_editableattribute_value == undefined
                        ? ''
                        : prodOptionValues.non_editableattribute_value,
                attributeValueSelected: true,
                isNonEditableFieldEmpty: false,
                attribute_option: '',
                isAttributeValueFieldInvalid: false,
            })
        } else if (prodOptionValues && prodOptionValues.dataGroup === 'Range') {
            defaultAttributeValues.push({
                attribute_id: productOptions.id,
                attribute_value:
                    prodOptionValues.non_editableattribute_value == undefined
                        ? ''
                        : prodOptionValues.non_editableattribute_value,
                attributeValueSelected: true,
                isNonEditableFieldEmpty: false,
                attribute_option: '',
                isAttributeValueFieldInvalid: false,
            })
        } else if (prodOptionValues && prodOptionValues.dataGroup === 'Upload') {
            // productOptionValues.attribute_options.map(({id, option_name}, index)=>{
            defaultAttributeValues.push({
                attribute_id: productOptions.id,
                attribute_value:
                    prodOptionValues.non_editableattribute_value == undefined
                        ? ''
                        : prodOptionValues.non_editableattribute_value,
                attributeValueSelected: true,
                isNonEditableFieldEmpty: false,
                attribute_option: '',
                isAttributeValueFieldInvalid: false,
            })
            // })
        }
        return defaultAttributeValues
    }

    // This state is mainly used for the attribute values
    const defaultAttributeValues = getAttributeValues(productOptionValues)
    const [productAttributeValues, setProductAttributeValues] = useState(defaultAttributeValues)

    // This effect will be triggered, when ever we receive images url's
    useEffect(() => {
        if (imageData) {
            let optionsValuesCopy = { ...productOptionValues }
            let productAttribValuesCopy = [...productAttributeValues]
            let optionsCopy = { ...productOptions }
            if (
                isEditting &&
                productAttributeValues &&
                productAttributeValues.length > 0 &&
                (selectedDataType === 16 ||
                    selectedDataType === 18 ||
                    selectedDataType === 19 ||
                    selectedDataType === 15 ||
                    selectedDataType === 17 ||
                    selectedDataType === 29)
            ) {
                productAttribValuesCopy.forEach((obj) => {
                    obj.attribute_value = imageData.length <= 0 ? '' : imageData
                    if (imageData.length > 0) {
                        obj.isNonEditableFieldEmpty = false
                    } else {
                        obj.isNonEditableFieldEmpty = true
                    }
                })
                optionsCopy.isNonEditableValueEdited = true
                setProductOptions(optionsCopy)
                setProductAttributeValues(productAttribValuesCopy)
            } else if (
                selectedDataType === 16 ||
                selectedDataType === 18 ||
                selectedDataType === 19 ||
                selectedDataType === 15 ||
                selectedDataType === 17 ||
                selectedDataType === 29
            ) {
                optionsValuesCopy.attribute_options.forEach((obj) => {
                    obj.option_name = imageData.length <= 0 ? '' : imageData
                    obj.attribute = productOptions.option_Name
                    if (typeof imageData !== 'number') {
                        if (imageData.length > 0) {
                            obj.isAttributeOptionValueFieldEmpty = false
                        } else {
                            obj.isAttributeOptionValueFieldEmpty = true
                        }
                    } else {
                        obj.isAttributeOptionValueFieldEmpty = false
                    }
                })
                if (productOptionValues.dataGroup === 'Upload') {
                    optionsValuesCopy.non_editableattribute_value = imageData
                }
                setproductOptionValues(optionsValuesCopy)
            }
        }
    }, [imageData])

    useEffect(() => {
        const datatypes = [30, 10, 11, 12, 13, 20, 21, 22, 23, 24, 25, 26, 27]
        if (tabId != 2 && !datatypes.includes(selectedDataType)) {
            setDisableSensible(true)
        }
    }, [tabId])

    useEffect(() => {
        if (productOptions && Object.keys(productOptions).length > 0) {
            if (productOptions.attributes.is_static === true) {
                setDisableRevisable(true)
                setDisableMandatory(true)
            } else if (productOptions.attributes.revisable === true || productOptions.attributes.mandatory === true) {
                setDisableStatic(true)
            }
        }
    }, [productOptions])

    const usePrevious = (value) => {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        })
        return ref.current
    }

    //  This useEffect will be triggered, whenever we recieve data from textRichEditor Component
    let previous = usePrevious(textRichEditorData)
    useEffect(() => {
        let productAttribValuesCopy = [...productAttributeValues]
        let optionsCopy = { ...productOptions }
        if (isEditting && productAttributeValues && productAttributeValues.length > 0 && selectedDataType === 10) {
            productAttribValuesCopy.forEach((obj) => {
                obj.attribute_value = textRichEditorData
                obj.isNonEditableFieldEmpty = false
            })
            if (
                productAttribValuesCopy &&
                productAttribValuesCopy.filter(({ attribute_value }) => attribute_value != '').length > 0 &&
                productAttribValuesCopy.filter(({ attribute_value }) => attribute_value != undefined).length > 0 &&
                previous !== undefined
            ) {
                optionsCopy.isNonEditableValueEdited = true
            } else {
                optionsCopy.isNonEditableValueEdited = false
            }
            setProductOptions(optionsCopy)
        } else if (selectedDataType === 10) {
            let optionsValuesCopy = { ...productOptionValues }
            optionsValuesCopy.attribute_options.forEach((obj) => {
                obj.option_name = textRichEditorData
                obj.attribute = productOptions.option_Name
                if (typeof textRichEditorData !== 'number') {
                    if (textRichEditorData.length > 0) {
                        obj.isAttributeOptionValueFieldEmpty = false
                    } else {
                        obj.isAttributeOptionValueFieldEmpty = true
                    }
                } else {
                    obj.isAttributeOptionValueFieldEmpty = false
                }
            })
            if (productOptionValues.dataGroup !== 'Optional') {
                optionsValuesCopy.non_editableattribute_value = textRichEditorData
            }
            setproductOptionValues(optionsValuesCopy)
        }
    }, [textRichEditorData])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const setOptionName = (name) => {
        let optionsCopy = { ...productOptions }
        let optionValueCopy = { ...productOptionValues }
        optionsCopy.option_Name = name
        optionsCopy.attributes.name = name
        optionsCopy.is_attribute_option_edited = true
        if (name.length > 0) {
            optionsCopy.is_AttributeFieldEmpty = false
        } else {
            optionsCopy.is_AttributeFieldEmpty = true
        }
        if (productOptionValues) {
            optionValueCopy.attributeName = name
            optionValueCopy.attribute_options.forEach((obj) => {
                obj.attribute = name
            })
            setproductOptionValues(optionValueCopy)
        }
        setProductOptions(optionsCopy)
    }
    // funtion used to handle the perference check box
    const attributeSettingsHandler = (type, checked, mandatory) => {
        let optionsCopy = { ...productOptions }
        // For mandatory preferences, we are not allowing to check or uncheck the the settings
        if (!mandatory) {
            optionsCopy.attributes[type] = checked
            optionsCopy.is_attribute_option_edited = true
        }

        if ((type === 'revisable' || type === 'mandatory') && checked) {
            setDisableStatic(true)
        } else if (type === 'revisable' || type === 'mandatory') {
            setDisableStatic(false)
        } else if (type === 'is_static' && checked) {
            setDisableRevisable(true)
            setDisableMandatory(true)
        } else if (type === 'is_static') {
            setDisableRevisable(false)
            setDisableMandatory(false)
        }

        setProductOptions(optionsCopy)
    }

    const addInputFieldForNonEditable = (type, checked) => {
        if (
            isEditting &&
            type === 'is_static' &&
            productOptionValues &&
            (productOptionValues.dataGroup !== 'Optional' || productOptionValues.dataGroup === 'Upload')
        ) {
            if (checked) {
                setDeletAttributeValue(false)
                setProductAttributeValues(getAttributeValues(productOptionValues))
            } else {
                setDeletAttributeValue(true)
                setProductAttributeValues([])
            }
        } else {
            if (
                type === 'is_static' &&
                checked &&
                minimumNoOfFields <= 0 &&
                maximumNoOfFields <= 0 &&
                (dataGroup.toUpperCase() !== 'OPTIONAL' || dataGroup.toUpperCase() === 'UPLOAD')
            ) {
                let optionValues = { ...productOptionValues }
                if (productOptionValues != undefined) {
                    optionValues.attribute_options.push({
                        id: uuidv4(),
                        attribute: '',
                        option_name: '',
                        isInValidField: false,
                        isAttributeOptionValueEdited: false,
                        defaultValue: [''],
                        defaultValueType: getDefaultValueControlType(selectedDataType),
                        maxLengthForOption: 255,
                    })
                } else {
                    if (Object.keys(optionValues).length === 0) {
                        Object.assign(optionValues, {
                            attributeName: '',
                            attribute_options: [
                                {
                                    id: uuidv4(),
                                    attribute: '',
                                    option_name: '',
                                    isInValidField: false,
                                    isAttributeOptionValueEdited: false,
                                    defaultValue: [''],
                                    defaultValueType: getDefaultValueControlType(selectedDataType),
                                    maxLengthForOption: 255,
                                },
                            ],
                            datatype: selectedDataType,
                        })
                    }
                }
                setproductOptionValues(optionValues)
            } else if (
                type === 'is_static' &&
                minimumNoOfFields <= 0 &&
                maximumNoOfFields <= 0 &&
                (dataGroup.toUpperCase() !== 'OPTIONAL' || dataGroup.toUpperCase() === 'UPLOAD')
            ) {
                setproductOptionValues({
                    attributeName: productOptions.option_Name,
                    attribute_options: getDefaultOptionValues(),
                    datatype: selectedDataType,
                    dataGroup: dataGroup,
                    non_editableattribute_value: '',
                    isNonEditable: productOptions.attributes.is_static,
                })
            } else if (
                type === 'is_static' &&
                checked &&
                minimumNoOfFields <= 0 &&
                (maximumNoOfFields > 0 || maximumNoOfFields == -1) &&
                dataGroup.toUpperCase() === 'OPTIONAL' &&
                productOptionValues.attribute_options.length <= 1 &&
                !isEditting
            ) {
                setproductOptionValues({
                    attributeName: '',
                    attribute_options: [
                        {
                            id: uuidv4(),
                            attribute: '',
                            option_name: '',
                            isInValidField: false,
                            isAttributeOptionValueEdited: false,
                            defaultValue: [''],
                            defaultValueType: getDefaultValueControlType(selectedDataType),
                            maxLengthForOption: 255,
                        },
                    ],
                    datatype: selectedDataType,
                })
            } else if (
                type === 'is_static' &&
                minimumNoOfFields <= 0 &&
                (maximumNoOfFields > 0 || maximumNoOfFields == -1) &&
                dataGroup.toUpperCase() === 'OPTIONAL' &&
                productOptionValues.attribute_options.length <= 1 &&
                !isEditting
            ) {
                setproductOptionValues({
                    attributeName: productOptions.option_Name,
                    attribute_options: getDefaultOptionValues(),
                    datatype: selectedDataType,
                    dataGroup: dataGroup,
                    non_editableattribute_value: '',
                    isNonEditable: productOptions.attributes.is_static,
                })
            }
        }
        if (isEditting && type === 'is_static' && productOptionValues && productOptionValues.dataGroup === 'Optional') {
            if (checked) {
                setDeletAttributeValue(false)
            } else {
                setDeletAttributeValue(true)
            }
        }
        // special case only for the range datagroup
        if (productOptionValues && productOptionValues.dataGroup === 'Range' && type === 'is_static') {
            let optionsCopy = { ...productOptions }
            if (checked) {
                setProductAttributeValues(getAttributeValues(productOptionValues))
            } else {
                setProductAttributeValues([])
            }
            optionsCopy.is_attribute_option_edited = true
            setProductOptions(optionsCopy)
        }
    }
    const attributeGroupHandler = (value) => {
        let optionsCopy = { ...productOptions }
        optionsCopy.attributes.product_attribute_group = value
        if (isEditting) {
            let oldAttributeGroupValue = copyOfMenu.filter((obj) => obj.id === editedOption)[0].attributes
                .product_attribute_group
            if (oldAttributeGroupValue == value) {
                optionsCopy.is_attribute_option_edited = false
            } else {
                optionsCopy.is_attribute_option_edited = true
            }
        }
        setProductOptions(optionsCopy)
    }

    // function used to handle the attribute type handler
    const attributeTypeHandler = (value) => {
        let optionsCopy = { ...productOptions }
        optionsCopy.attributes.attribute_type = value
        optionsCopy.is_attribute_option_edited = true
        setProductOptions(optionsCopy)
    }

    const deleteOptionValues = () => {
        closeOptionValueDeleteModal()
        setIsAttributeOptionDeleted(true)
        setOptionValueDeletionId([...optionValueDeletionId, { id: deleteOptionvalueId, value: deleteOptionvalue }])
        removeInput(deleteOptionvalueId)
    }

    const deleteNonEditableAttributeValue = () => {
        let attributeValueId = attributeNonEditableValues.filter(({ attribute }) => attribute == productOptions.id)[0]
            ?.id
        if (attributeValueId) {
            MarketplaceServices.remove(atttriuteNonEditableValuesAPI, {
                _id: attributeValueId,
            })
                .then((response) => {
                    // deleting the attribute non-editable value
                    let deletedValue = attributeNonEditableValues.filter(({ id }) => id !== attributeValueId)
                    let productOptionValue = copyOfOptionValues
                    productOptionValue.forEach((obj) => {
                        if (obj.attributeName === productOptions.option_Name) {
                            obj.attribute_options.forEach((option) => {
                                option.selectedNonEditableValue = false
                            })
                            obj.non_editableattribute_value = ''
                        }
                    })
                    setProductAttributeValues([])
                    setProductAttribOptionValues(productOptionValue)
                    setAttributeNonEditableValues(deletedValue)
                    closeAddOptionValuesForm('edit', 'success')
                })
                .catch((error) => {
                    console.log(
                        'Error Server response from deleteNonEditableAttributeValue is',
                        atttriuteNonEditableValuesAPI,
                        error
                    )
                    MarketplaceToaster.showToast(error.response)
                })
        }
    }

    const deleteAllOptionValues = () => {
        axios
            .all(
                [...new Set(optionValueDeletionId)].map(({ id }) =>
                    MarketplaceServices.remove(
                        attributeOptionAPI.replace('product-attribute/option', 'product-attributeoption-id'),
                        {
                            _id: id,
                        }
                    )
                )
            )
            .then((response) => {
                closeDeleteOptionValuesWarningModal()
                const newlyAddedAttribOptionValues = productOptionValues.attribute_options.filter(
                    ({ id }) => typeof id == 'string'
                )
                if (!newlyAddedAttribOptionValues.length > 0) {
                    closeAddOptionValuesForm('edit', 'success')
                }
                setProductMenus(
                    isOptionValueFieldEditted,
                    productOptions,
                    productOptions.is_serverData,
                    productOptionValues,
                    productOptions.attributes.is_static && productOptionValues.dataGroup === 'Optional'
                        ? productAttributeValues && productOptionValues.dataType == 4
                            ? productAttributeValues.filter(
                                  ({ attributeValueSelected }) => attributeValueSelected === false
                              )
                            : productAttributeValues.filter(({ attributeValueSelected }) => attributeValueSelected)
                        : productAttributeValues,
                    productOptions.attributes.is_static,
                    productAttributeGroupData,
                    productOptions.isNonEditableValueEdited,
                    'delete'
                )
                dispatch(fnAttributeDetailsUpdated(true))
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log('Error Server response from deleteAllOptionValues is', attributeOptionAPI, error)
            })
    }

    // Below function are added for the optionValues logic;
    // function will disbale and enable delete options for the input fields of the controls like Toggle, Switcher, Slider, Date
    const enableAndDisableDeleteButton = (productOptionValueLength, optionValueId, optionValue) => {
        if (productOptionValueLength <= minimumNoOfFields) {
            return ''
        } else if (
            productOptionValueLength > 1 ||
            (minimumNoOfFields === 0 &&
                productOptionValues.dataGroup.toUpperCase() === 'OPTIONAL' &&
                !productOptions.attributes.is_static)
        ) {
            return isEditting && isCore ? (
                ''
            ) : (
                <Button
                    onClick={() => {
                        if (isSeverData && typeof optionValueId === 'number') {
                            setIsOptionValueDeletionModalOpen(true)
                            setDeleteOptionvalueId(optionValueId)
                            setDeleteOptionvalue(optionValue)

                            // deleteOptionValues(optionValueId)
                        } else {
                            removeInput(optionValueId)
                        }
                    }}
                    className={`app-btn-icon app-delete-icon mx-2`}>
                    <div className='flex justify-center'>
                        {trashCanSVG}
                        {/* <img src={TrashCan} alt='trash' /> */}
                    </div>
                </Button>
            )
        } else {
            return ''
        }
    }

    // function used to add empty input fields to option values
    const addInput = () => {
        let optionValues = { ...productOptionValues }
        optionValues.attribute_options.push({
            id: uuidv4(),
            attribute: '',
            option_name: '',
            isInValidField: false,
            isAttributeOptionValueEdited: false,
            defaultValue: [''],
            defaultValueType: getDefaultValueControlType(selectedDataType),
            maxLengthForOption: 255,
            option_displayname: '',
        })
        setproductOptionValues(optionValues)
    }

    // function used to delete empty input fields from option values
    const removeInput = (id) => {
        let optionsValuesCopy = { ...productOptionValues }
        let productAttribValuesCopy = [...productAttributeValues]
        // will delet the attribute option
        let removedOptionValues = optionsValuesCopy.attribute_options.filter((obj) => obj.id !== id)
        // will delete the attribute values.
        let removedAttributeValues = productAttribValuesCopy.filter(({ attribute_option }) => attribute_option !== id)
        optionsValuesCopy.attribute_options = removedOptionValues
        setProductAttributeValues(removedAttributeValues)
        setproductOptionValues(optionsValuesCopy)
    }

    // function used to fill empty input fields
    const setOptionValue = (value, aid) => {
        let optionsValuesCopy = { ...productOptionValues }
        optionsValuesCopy.attribute_options.forEach((obj) => {
            if (obj.id === aid) {
                obj.option_name = value
                obj.attribute = productOptions.option_Name
                obj.isAttributeOptionValueEdited = true
                if (typeof value !== 'number') {
                    if (value.length > 0) {
                        obj.isAttributeOptionValueFieldEmpty = false
                    } else {
                        obj.isAttributeOptionValueFieldEmpty = true
                    }
                } else {
                    obj.isAttributeOptionValueFieldEmpty = false
                }
            }
        })

        if (isEditting && value && typeof aid == 'string') {
            setIsOptionValueFieldEditted(true)
        }

        if (productOptionValues.dataGroup !== 'Optional') {
            optionsValuesCopy.non_editableattribute_value = value
        } else {
            // This setter method will set the attribute values..
            setProductAttributeValues(getAttributeValues(productOptionValues))
        }
        // will be called only, when the default type is single
        if (getDefaultValueControlType(selectedDataType) === 'single') {
            // Here it will update the default value for the attribute unit...
            if (
                optionsValuesCopy.attribute_options.filter(({ defaultValue }) => defaultValue[0] === aid)[0]
                    ?.option_name
            ) {
                attributeUnitHandler(
                    optionsValuesCopy.attribute_options.filter(({ defaultValue }) => defaultValue[0] === aid)[0]
                        ?.option_name,
                    'default_selection'
                )
            } else {
                attributeUnitHandler('', 'default_selection')
            }
        }

        // will be called only, when the default type is multiple
        if (getDefaultValueControlType(selectedDataType) === 'multiple' && optionsValuesCopy.dataGroup === 'Optional') {
            setProductOptions(getDefaultValuePayloadForCheckBox(optionsValuesCopy))
        }
        setproductOptionValues(optionsValuesCopy)
    }

    // function used to fill the empty fields of prefix and suffix
    const attributeUnitHandler = (attributeUnitValue, unitName, checkBoxValue) => {
        let optionsCopy = { ...productOptions }
        let optionsValuesCopy = { ...productOptionValues }
        let productAttribValuesCopy = [...productAttributeValues]
        optionsCopy.is_attribute_option_edited = true
        optionsCopy.attributes.attribute_unit_value.forEach((obj) => {
            if (obj.key === unitName && obj.key !== 'ext') {
                if (obj.key == 'format' && (selectedDataType == 5 || selectedDataType == 14 || selectedDataType == 9)) {
                    if (productOptionValues.dataGroup !== 'Optional' && !isEditting) {
                        // Here we need to change the value line no: 1625
                        let copyOfnoneditableValue = optionsValuesCopy.non_editableattribute_value
                        optionsValuesCopy.non_editableattribute_value =
                            optionsValuesCopy.non_editableattribute_value != ''
                                ? selectedDataType == 5 || selectedDataType == 14
                                    ? getDateFromCurrentFormatToNewFormat(
                                          optionsValuesCopy.non_editableattribute_value,
                                          getAttributeUnitValue('format')[0].value[0],
                                          attributeUnitValue
                                      )
                                    : dayjs(
                                          optionsValuesCopy.non_editableattribute_value,
                                          getAttributeUnitValue('format')[0].value[0]
                                      ).format(attributeUnitValue)
                                : ''
                        if (selectedDataType == 9 && optionsValuesCopy.attribute_options.length > 0) {
                            optionsValuesCopy.attribute_options[0].option_name =
                                optionsValuesCopy.attribute_options[0].option_name != ''
                                    ? dayjs(copyOfnoneditableValue, getAttributeUnitValue('format')[0].value[0]).format(
                                          attributeUnitValue
                                      )
                                    : ''
                        }
                        if (
                            productOptions.attributes.is_static &&
                            optionsValuesCopy.non_editableattribute_value != ''
                        ) {
                            optionsCopy.isNonEditableValueEdited = true
                        }
                        obj.value[0] = attributeUnitValue
                        setproductOptionValues(optionsValuesCopy)
                    } else if (productOptionValues.dataGroup !== 'Optional' && isEditting) {
                        productAttribValuesCopy.forEach((obj) => {
                            // Here we need to change the value line no: 1634
                            obj.attribute_value =
                                obj.attribute_value != ''
                                    ? selectedDataType == 5 || selectedDataType == 14
                                        ? getDateFromCurrentFormatToNewFormat(
                                              obj.attribute_value,
                                              getAttributeUnitValue('format')[0].value[0],
                                              attributeUnitValue
                                          )
                                        : dayjs(
                                              obj.attribute_value,
                                              getAttributeUnitValue('format')[0].value[0]
                                          ).format(attributeUnitValue)
                                    : ''
                            obj.isNonEditableFieldEmpty = false
                            if (productOptions.attributes.is_static && obj.attribute_value != '') {
                                optionsCopy.isNonEditableValueEdited = true
                            }
                        })
                        obj.value[0] = attributeUnitValue
                    }
                    setProductOptions(optionsCopy)
                    setProductAttributeValues(productAttribValuesCopy)
                } else {
                    obj.value[0] = attributeUnitValue
                }
            } else if (obj.key === 'ext') {
                if (checkBoxValue) {
                    obj.value.push(attributeUnitValue)
                    setIsExtensionRequired(false)
                } else {
                    let removedExt = obj.value.filter((value) => value !== attributeUnitValue)
                    if (removedExt.length <= 0) {
                        setIsExtensionRequired(true)
                    }
                    obj.value = removedExt
                }
            }
        })
        // Inserting suffix attribute unit manually for the first time, only when suffix is checked and key "suffix_displayname" is not available from the server..
        if (
            productOptions.attributeValueSuffix &&
            optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'suffix_displayname').length == 0
        ) {
            optionsCopy.attributes.attribute_unit_value.push({
                key: 'suffix_displayname',
                value: [attributeUnitValue],
            })
        }
        // Inserting prefix attribute unit manually for the first time, only when prefix is checked and key "prefix_displayname" is not available from the server..
        if (
            productOptions.attributeValuePrefix &&
            optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'prefix_displayname').length == 0
        ) {
            optionsCopy.attributes.attribute_unit_value.push({
                key: 'prefix_displayname',
                value: [attributeUnitValue],
            })
        }
        // Inserting default attribute unit manually for the first time, adding key "default_selection" if not available from the server..
        if (
            optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'default_selection').length == 0 &&
            (selectedDataType === 1 ||
                selectedDataType === 2 ||
                selectedDataType === 3 ||
                selectedDataType === 7 ||
                selectedDataType === 8)
        ) {
            optionsCopy.attributes.attribute_unit_value.push({
                key: 'default_selection',
                value: [attributeUnitValue],
            })
        }

        if (
            unitName === 'prefix_displayname' &&
            optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'prefix_displayname')[0]
                ?.value[0] !== ''
        ) {
            setIsPrefixFieldEmpty(false)
        }

        if (
            unitName === 'suffix_displayname' &&
            optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'suffix_displayname')[0]
                ?.value[0] !== ''
        ) {
            setIsSufixFieldEmpty(false)
        }

        setProductOptions(optionsCopy)
    }

    const getDefaultValuePayloadForCheckBox = (optionsValuesCopy) => {
        // intialy default section
        let defaultObject = {
            key: 'default_selection',
            value: [],
        }
        // filtering only the checked default fields
        // and pushing the values in the above object,only when their is a option_name value.
        let checkedDefaultFields = optionsValuesCopy.attribute_options.filter(
            ({ defaultValue }) => defaultValue[0] != ''
        )
        if (checkedDefaultFields.length > 0) {
            checkedDefaultFields.forEach(({ option_name }) => {
                if (option_name != '') {
                    defaultObject.value.push(option_name)
                }
            })
        }

        let optionsCopy = { ...productOptions }
        optionsCopy.is_attribute_option_edited = true
        //  if there is a value, will add that attribute option for making post call (or) will make values as empty payload
        if (defaultObject.value.length > 0) {
            optionsCopy.attributes.attribute_unit_value.forEach((obj) => {
                if (obj.key === 'default_selection') {
                    obj.value = defaultObject.value
                }
            })
            // Inserting default attribute unit manually for the first time, adding key "default_selection" if not available from the server..
            if (
                optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'default_selection').length == 0
            ) {
                optionsCopy.attributes.attribute_unit_value.push(defaultObject)
            }
        } else {
            // Inserting default attribute unit manually for the first time, adding key "default_selection" if not available from the server..
            if (
                optionsCopy.attributes.attribute_unit_value.filter(({ key }) => key === 'default_selection').length == 0
            ) {
                optionsCopy.attributes.attribute_unit_value.push(defaultObject)
            }
        }
        return optionsCopy
    }

    // function used to handle the suffix check box
    const attributePrefixCheckBoxHandler = (prefixCheck) => {
        let optionsCopy = { ...productOptions }
        if (prefixCheck) {
            optionsCopy.attributeValuePrefix = prefixCheck
        } else {
            optionsCopy.attributeValuePrefix = prefixCheck
            optionsCopy.is_attribute_option_edited = true
            optionsCopy.attributes.attribute_unit_value.forEach((obj) => {
                if (obj.key === 'prefix_displayname') {
                    obj.value[0] = ''
                }
            })
        }
        setProductOptions(optionsCopy)
    }

    // function used to handle the prefix check box
    const attributeSuffixCheckBoxHandler = (suffixCheck) => {
        let optionsCopy = { ...productOptions }
        if (suffixCheck) {
            optionsCopy.attributeValueSuffix = suffixCheck
        } else {
            optionsCopy.attributeValueSuffix = suffixCheck
            optionsCopy.is_attribute_option_edited = true
            optionsCopy.attributes.attribute_unit_value.forEach((obj) => {
                if (obj.key === 'suffix_displayname') {
                    obj.value[0] = ''
                }
            })
        }
        setProductOptions(optionsCopy)
    }

    // function used to handle the attribute values for all the datatype, which are optional
    const attributeValeuHandler = (id, value, attributeValue, dataType) => {
        let productAttribValuesCopy = [...productAttributeValues]
        let optionValuesCopy = { ...productOptionValues }
        let optionsCopy = { ...productOptions }
        if (productOptionValues && productOptionValues.dataGroup !== 'Optional' && dataType && isEditting) {
            switch (dataType) {
                //  Text-Url
                //  Integer Limit
                // "Text-Email"
                // "Text-Rich"
                // "Text-Phonenumber"
                // Float-positive
                // Number-percentage
                // Number-positive
                // Integer Limit
                // "float-limit"
                // "Text-Name"
                //  Text
                // Time
                // date
                // dataTime
                // Range
                case 13:
                case 23:
                case 11:
                case 10:
                case 12:
                case 20:
                // float-roundoff
                case 25:
                // float-roundofflimit
                case 26:
                case 21:
                case 22:
                case 24:
                case 27:
                case 30:
                case 9:
                case 5:
                case 14:
                case 6:
                    productAttribValuesCopy.forEach((obj) => {
                        obj.attribute_value = value
                        obj.isNonEditableFieldEmpty = false
                    })
                    break
                default:
            }
            optionsCopy.isNonEditableValueEdited = true
            setProductOptions(optionsCopy)
            setProductAttributeValues(productAttribValuesCopy)
        } else {
            switch (dataType) {
                // CheckBoxAttributeValueHandler
                // RadioAttributeHandler
                // choice Chip or Tag
                case 1:
                case 3:
                    productAttribValuesCopy.forEach((obj) => {
                        if (obj.attribute_option === id) {
                            obj.attributeValueSelected = value
                        }
                    })
                    optionValuesCopy.attribute_options.forEach((obj) => {
                        if (obj.id === id) {
                            obj.attributeValueSelected = value
                        }
                    })
                    break
                case 2:
                    productAttribValuesCopy.forEach((obj) => {
                        if (obj.attribute_option === id) {
                            obj.attributeValueSelected = value
                        } else {
                            obj.attributeValueSelected = false
                        }
                    })
                    optionValuesCopy.attribute_options.forEach((obj) => {
                        if (obj.id === id) {
                            obj.attributeValueSelected = value
                        } else {
                            obj.attributeValueSelected = false
                            if (obj.selectedNonEditableValue) obj.selectedNonEditableValue = false
                        }
                    })
                    break
                // Toggle
                case 4:
                    if (attributeValue) {
                        productAttribValuesCopy[0].attributeValueSelected = true
                        productAttribValuesCopy[1].attributeValueSelected = false
                    } else {
                        productAttribValuesCopy[0].attributeValueSelected = false
                        productAttribValuesCopy[1].attributeValueSelected = true
                    }
                    break
                // Drop down and Switcher
                case 7:
                case 8:
                    productAttribValuesCopy.forEach((obj) => {
                        if (obj.attribute_value === attributeValue) {
                            obj.attributeValueSelected = true
                        } else {
                            obj.attributeValueSelected = false
                        }
                    })
                    optionValuesCopy.attribute_options.forEach((obj) => {
                        if (obj.option_name === attributeValue) {
                            obj.attributeValueSelected = true
                        } else {
                            obj.attributeValueSelected = false
                            if (obj.selectedNonEditableValue) obj.selectedNonEditableValue = false
                        }
                    })
                    break
                default:
            }
            setIsAttributeValueSelected(true)
            optionsCopy.isNonEditableValueEdited = true
            setProductOptions(optionsCopy)
            setproductOptionValues(optionValuesCopy)
            setProductAttributeValues(productAttribValuesCopy)
        }
    }

    const convertUrlToObjectForFileUpload = (fileUrls) => {
        let convertedObject = []
        let obj = {}
        if (attributeFileType == 'image') {
            obj['image_full_path'] = fileUrls
        } else {
            obj['document_full_path'] = fileUrls
        }
        convertedObject.push(obj)
        return convertedObject
    }

    // This function will filter the prefix and suffix values and will return them
    const getAttributeUnitValue = (unitName) => {
        let filteredUnitValue = productOptions.attributes.attribute_unit_value.filter(({ key }) => key === unitName)
        return filteredUnitValue
    }

    // function will return input fields for the dynamic form
    const getOptionValuesForm = () => {
        return (
            <Row className='mb-[1.5rem] mt-[0.3rem] p-3'>
                <Col span={13}>
                    <Typography.Title level={5} className='mb-3'>
                        {t('attributes:attribute_details_label')}
                    </Typography.Title>
                    <div>
                        {/* attribute group */}
                        {isEditting && isCore ? (
                            ''
                        ) : (
                            <>
                                {' '}
                                <Typography className='input-label-color mb-2'>
                                    {t('attributes:attribute_group')}
                                    <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                                </Typography>
                                <Row className='flex justify-between  pb-3'>
                                    <Select
                                        placeholder={t('attributes:select_attribute_group')}
                                        className='w-[70%]'
                                        value={
                                            productOptions.attributes?.product_attribute_group === ''
                                                ? t('attributes:no_attribute_group')
                                                : productOptions.attributes?.product_attribute_group
                                        }
                                        status={isAttributeGroupEmpty ? 'error' : ''}
                                        onChange={(value) => {
                                            attributeGroupHandler(value)
                                            setIsAttributeGroupEmpty(false)
                                        }}>
                                        {productAttributeGroupData &&
                                            productAttributeGroupData.length > 0 &&
                                            productAttributeGroupData.map((obj) => (
                                                <Select.Option
                                                    value={obj.id}
                                                    key={`${obj.id}_${
                                                        productAttributeGroupData.filter(({ id }) => id == obj.id)[0]
                                                            .display_name
                                                    }`}>
                                                    {
                                                        productAttributeGroupData.filter(({ id }) => id == obj.id)[0]
                                                            .display_name
                                                    }
                                                </Select.Option>
                                            ))}
                                    </Select>

                                    <Button
                                        onClick={() => {
                                            openSegmentForm()
                                        }}
                                        className='ml-1  custom-ant-button-hover'>
                                        <div className='flex justify-center app-btn-secondary items-center gap-1'>
                                            <span>
                                                <PlusOutlined />
                                            </span>
                                            <Text
                                                ellipsis={{ tooltip: t('attributes:add_new_group') }}
                                                className='w-[100px] app-btn-secondary'>
                                                {t('attributes:add_new_group')}
                                            </Text>
                                        </div>
                                    </Button>
                                </Row>
                            </>
                        )}
                    </div>
                    <div className='flex pb-3'>
                        {/* This below check fields will be displayed only, when we have attrib units.. */}
                        {productOptions.attributes.attribute_unit_value &&
                            productOptions.attributes.attribute_unit_value.filter(
                                ({ key }) => key === 'prefix_displayname'
                            ).length > 0 &&
                            (selectedDataType == 1 ||
                                selectedDataType == 2 ||
                                selectedDataType == 3 ||
                                selectedDataType == 7 ||
                                selectedDataType == 8) && (
                                <div className='my-2'>
                                    <Checkbox
                                        onChange={(e) => attributePrefixCheckBoxHandler(e.target.checked)}
                                        checked={productOptions.attributeValuePrefix}
                                        disabled={isEditting && isCore ? true : false}
                                        className='input-label-color'>
                                        {t('attributes:enable_prefix')}
                                    </Checkbox>
                                </div>
                            )}
                        {productOptions.attributes.attribute_unit_value &&
                            productOptions.attributes.attribute_unit_value.filter(
                                ({ key }) => key === 'suffix_displayname'
                            ).length > 0 &&
                            (selectedDataType == 1 ||
                                selectedDataType == 2 ||
                                selectedDataType == 3 ||
                                selectedDataType == 7 ||
                                selectedDataType == 8) && (
                                <div className='my-2'>
                                    <Checkbox
                                        className='input-label-color'
                                        onChange={(e) => attributeSuffixCheckBoxHandler(e.target.checked)}
                                        checked={productOptions.attributeValueSuffix}
                                        disabled={isEditting && isCore ? true : false}>
                                        {t('attributes:enable_suffix')}
                                    </Checkbox>
                                </div>
                            )}
                    </div>
                    {/* Attribute Name */}
                    <Typography className='input-label-color'>
                        {t('attributes:attribute_name')}
                        <span className={`mandatory-symbol-color text-sm mx-1`}>*</span>
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '20px',
                            paddingTop: '8px',
                        }}>
                        {productOptions.attributeValuePrefix ? (
                            <Input
                                className='w-[15%] mr-2'
                                placeholder={t('attributes:prefix_placeholdername')}
                                minLength={prefixMinLength}
                                maxLength={prefixMaxLength}
                                value={
                                    getAttributeUnitValue('prefix_displayname')[0]?.value
                                        ? getAttributeUnitValue('prefix_displayname')[0].value
                                        : ''
                                }
                                onBlur={(e) =>
                                    attributeUnitHandler(
                                        e.target.value.trim().replace(/\s+/g, ' '),
                                        'prefix_displayname'
                                    )
                                }
                                onChange={(e) => attributeUnitHandler(e.target.value, 'prefix_displayname')}
                                status={isPrefixFieldEmpty ? t('attributes:error') : ''}></Input>
                        ) : (
                            ''
                        )}
                        <Input
                            placeholder={t('attributes:enter_attribute_name')}
                            className={` ${
                                productOptions.attributeValueSuffix && productOptions.attributeValuePrefix
                                    ? 'w-[70%]'
                                    : productOptions.attributeValueSuffix || productOptions.attributeValuePrefix
                                      ? 'w-[85%]'
                                      : 'w-full'
                            }`}
                            maxLength={titleMaxLength}
                            value={isEditting ? productOptions.attribute_displayname : productOptions.option_Name}
                            status={productOptions.is_AttributeFieldEmpty || attributeNameAlreadyExist ? 'error' : ''}
                            onBlur={(e) => {
                                if (e.target.value) {
                                    setOptionName(e.target.value.trim().replace(/\s+/g, ' '))
                                }
                            }}
                            onChange={(e) => {
                                if (
                                    e.target.value.trim() !== '' &&
                                    validator.isLength(e.target.value.trim(), {
                                        min: 0,
                                        max: titleMaxLength,
                                    })
                                ) {
                                    setOptionName(e.target.value)
                                } else if (e.target.value.trim() === '') {
                                    setOptionName(e.target.value)
                                }
                            }}
                            disabled={isEditting && (isCore || isEditting) ? true : false}
                            addonAfter={
                                storeLanguageFromReduxState && storeLanguageFromReduxState.length > 0 && isEditting ? (
                                    <TranslationOutlined
                                        onClick={() => {
                                            handleAttributeNameTranslaterModal()
                                            setOnChangeDisableFields(true)
                                        }}
                                        className='iconsHoverClass'></TranslationOutlined>
                                ) : (
                                    ''
                                )
                            }
                        />
                        {/* Suffix Field */}
                        {productOptions.attributeValueSuffix ? (
                            <Input
                                className='w-[15%] ml-2'
                                placeholder={t('attributes:suffix_placeholdername')}
                                maxLength={suffixMaxLength}
                                minLength={suffixMinLength}
                                value={
                                    getAttributeUnitValue('suffix_displayname')[0]?.value
                                        ? getAttributeUnitValue('suffix_displayname')[0].value
                                        : ''
                                }
                                onBlur={(e) =>
                                    attributeUnitHandler(
                                        e.target.value.trim().replace(/\s+/g, ' '),
                                        'suffix_displayname'
                                    )
                                }
                                onChange={(e) => attributeUnitHandler(e.target.value, 'suffix_displayname')}
                                status={isSufixFieldEmpty ? t('attributes:error') : ''}></Input>
                        ) : (
                            ''
                        )}
                    </div>
                    {/* If datagroup is single and non editable field is not checked, where are not allowing to attribute to add attribute values... */}
                    {productOptionValues != undefined ? (
                        productOptionValues.dataGroup !== 'Optional' &&
                        minimumNoOfFields <= 0 &&
                        productOptionValues?.attribute_options.length <= 0 &&
                        maximumNoOfFields == 0 ? (
                            <>
                                {productAttributeValues &&
                                productAttributeValues.length >= 0 &&
                                (productAttributeValues.filter(({ attribute_value }) => attribute_value != '').length >
                                    0 ||
                                    productOptions.attributes.is_static) &&
                                productOptions.attributes.is_static &&
                                productAttributeValues.filter(({ attribute_value }) => attribute_value != undefined)
                                    .length > 0 &&
                                isEditting ? (
                                    ''
                                ) : (!isCore || !isEditting) && !productOptions.attributes.is_static ? (
                                    // <p>
                                    //     <Trans
                                    //         i18nKey={t('attributes:static_message')}
                                    //         components={{ span: <span style={{ color: 'red' }} /> }}
                                    //         shouldUnescape={true}
                                    //         values={{
                                    //             static: t('attributes:static_name'),
                                    //             option:
                                    //                 selectedOption &&
                                    //                 selectedOption.props &&
                                    //                 t(selectedOption.props.translate),
                                    //         }}
                                    //     />
                                    // </p>
                                    <div className='flex items-center'>
                                        <RiInformationLine className='text-xl mr-2 input-label-color' />
                                        <p className='input-label-color'>{t('attributes:static_message')}</p>
                                    </div>
                                ) : (
                                    ''
                                )}{' '}
                            </>
                        ) : (
                            <>
                                {/* Option Values  */}
                                {minimumNoOfFields <= 0 &&
                                productOptionValues?.attribute_options.length <= 0 &&
                                maximumNoOfFields == 0 ? (
                                    (!isCore || !isEditting) && !productOptions.attributes.is_static ? (
                                        // <p>
                                        //     <Trans
                                        //         i18nKey={t('attributes:static_message')}
                                        //         components={{ span: <span style={{ color: 'red' }} /> }}
                                        //         shouldUnescape={true}
                                        //         values={{
                                        //             static: t('attributes:static_name'),
                                        //             option:
                                        //                 selectedOption &&
                                        //                 selectedOption.props &&
                                        //                 t(selectedOption.props.translate),
                                        //         }}
                                        //     />
                                        // </p>
                                        <div className='flex items-center'>
                                            <RiInformationLine className='text-xl mr-2 input-label-color' />
                                            <p className='input-label-color'>{t('attributes:static_message')}</p>
                                        </div>
                                    ) : (
                                        ''
                                    )
                                ) : (
                                    <Typography className='input-label-color' style={{ paddingBottom: '8px' }}>
                                        {selectedOption === 'File-image'
                                            ? t('attributes:attribute_image')
                                            : minimumNoOfFields <= 0 &&
                                                maximumNoOfFields <= 0 &&
                                                productOptionValues.dataGroup.toUpperCase() !== 'OPTIONAL'
                                              ? t('attributes:attribute_values')
                                              : t('attributes:attribute_options')}
                                        <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                                    </Typography>
                                )}
                                <Content className={selectedDataType == 10 ? '' : 'max-h-[220px] overflow-y-auto'}>
                                    {
                                        <AttributeOptionControls
                                            selectedDataType={selectedDataType}
                                            productOptionValues={productOptionValues}
                                            getAttributeUnitValue={getAttributeUnitValue}
                                            setOptionValue={setOptionValue}
                                            enableAndDisableDeleteButton={enableAndDisableDeleteButton}
                                            validatePositiveNumber={validatePositiveNumber}
                                            validatePositiveFloatNumber={validatePositiveFloatNumber}
                                            invalidAttributeValue={invalidAttributeValue}
                                            setOpenImageModel={setOpenImageModel}
                                            isExtensionRequired={isExtensionRequired}
                                            isEditting={isEditting}
                                            isCore={isCore}
                                            setImageData={setImageData}
                                            convertUrlToObjectForFileUpload={convertUrlToObjectForFileUpload}
                                            attributeFileType={attributeFileType}
                                            textRichEditorData={textRichEditorData}
                                            setTextRichEditorData={setTextRichEditorData}
                                            phoneCode={phoneCode}
                                            setIsPhoneCodeNotValid={setIsPhoneCodeNotValid}
                                            setPhoneCode={setPhoneCode}
                                            isPhoneCodeNotValid={isPhoneCodeNotValid}
                                            langDirection={langDirection}
                                            handleOptionTranslaterModal={handleOptionTranslaterModal}
                                            setOnChangeDisableFields={setOnChangeDisableFields}
                                            productOptions={productOptions}
                                        />
                                    }
                                </Content>
                                {minimumNoOfFields !== 0 &&
                                maximumNoOfFields !== 0 &&
                                (productOptionValues?.attribute_options.length < maximumNoOfFields ||
                                    maximumNoOfFields === -1) ? (
                                    <Button
                                        style={{
                                            width: '100%',
                                            marginBottom: '18px',
                                            marginTop: '20px',
                                            fontWeight: 500,
                                        }}
                                        className={`custom-ant-button-hover ${langDirection == 'rtl' ? 'mr-2' : ''}`}
                                        block
                                        onClick={() => addInput()}
                                        disabled={isEditting && isCore ? true : false}>
                                        <div className='flex justify-center items-center gap-1'>
                                            <span>
                                                <PlusOutlined />
                                            </span>
                                            <>{t('attributes:add_attribute_option')}</>
                                        </div>
                                    </Button>
                                ) : productOptions.attributes.is_static ? (
                                    minimumNoOfFields !== productOptionValues?.attribute_options.length &&
                                    (productOptionValues?.attribute_options.length < maximumNoOfFields ||
                                        maximumNoOfFields === -1) &&
                                    productOptions.attributes.is_static ? (
                                        <Button
                                            style={{
                                                width: '100%',
                                                marginBottom: '18px',
                                                marginTop: '20px',
                                                fontWeight: 500,
                                            }}
                                            className={`custom-ant-button-hover ${langDirection == 'rtl' ? 'mr-2' : ''}`}
                                            block
                                            onClick={() => addInput()}
                                            disabled={isEditting && isCore ? true : false}>
                                            <div className='flex justify-center items-center gap-1'>
                                                <span>
                                                    <PlusOutlined />
                                                </span>
                                                <>{t('attributes:add_attribute_option')}</>
                                            </div>
                                        </Button>
                                    ) : (
                                        ''
                                    )
                                ) : minimumNoOfFields == 0 &&
                                  maximumNoOfFields !== 0 &&
                                  (productOptionValues?.attribute_options.length < maximumNoOfFields ||
                                      maximumNoOfFields === -1) ? (
                                    <Button
                                        style={{
                                            width: '100%',
                                            marginBottom: '18px',
                                            marginTop: '20px',
                                            fontWeight: 500,
                                        }}
                                        className={`custom-ant-button-hover ${langDirection == 'rtl' ? 'mr-2' : ''}`}
                                        block
                                        onClick={() => addInput()}
                                        disabled={isEditting && isCore ? true : false}>
                                        <div className='flex justify-center items-center gap-1'>
                                            <span>
                                                <PlusOutlined />
                                            </span>
                                            <>{t('attributes:add_attribute_option')}</>
                                        </div>
                                    </Button>
                                ) : (
                                    ''
                                )}
                            </>
                        )
                    ) : (
                        ''
                    )}
                    {/* Attribute Values will be displayed only when the is_static is checked and attribute should have atleast one value or it will not displayed */}
                    {productAttributeValues &&
                    productAttributeValues.filter(({ attribute_value }) => attribute_value != '').length > 0 &&
                    productOptions.attributes.is_static ? (
                        <>
                            {productOptionValues && productOptionValues.dataGroup !== 'Upload' ? (
                                <Typography className='input-label-color mb-2'>
                                    {t('attributes:attribute_values')}
                                    <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                                </Typography>
                            ) : (
                                ''
                            )}
                            <StaticAttributeControls
                                productOptionValues={productOptionValues}
                                isEditting={isEditting}
                                datatype={selectedDataType}
                                productAttributeValues={productAttributeValues}
                                attributeValeuHandler={attributeValeuHandler}
                                isCore={isCore}
                                textRichEditorData={textRichEditorData}
                                setTextRichEditorData={setTextRichEditorData}
                                setOpenImageModel={setOpenImageModel}
                                getAttributeUnitValue={getAttributeUnitValue}
                                generateObjectFromUrlWithFullImagePath={generateObjectFromUrlWithFullImagePath}
                                setImageData={setImageData}
                                generateObjectFromUrl={generateObjectFromUrl}
                                attributeFileType={attributeFileType}
                                productOptions={productOptions}
                                setProductOptions={setProductOptions}
                                setIsPhoneCodeNotValid={setIsPhoneCodeNotValid}
                                setPhoneCode={setPhoneCode}
                                isPhoneCodeNotValid={isPhoneCodeNotValid}
                                phoneCode={phoneCode}
                                validatePositiveFloatNumber={validatePositiveFloatNumber}
                                validatePositiveNumber={validatePositiveNumber}
                                invalidAttributeValue={invalidAttributeValue}
                                langDirection={langDirection}
                                isAttributeValueSelected={isAttributeValueSelected}
                                getImageUrl={getImageUrl}
                            />
                        </>
                    ) : productOptions.attributes.is_static &&
                      productAttributeValues.length &&
                      isEditting &&
                      productOptionValues &&
                      (productOptionValues.dataGroup !== 'Optional' || productOptionValues.dataGroup === 'Upload') ? (
                        <>
                            <Typography className='mb-2 input-label-color'>
                                {t('attributes:attribute_values')}
                                <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                            </Typography>
                            <StaticAttributeControls
                                productOptionValues={productOptionValues}
                                isEditting={isEditting}
                                datatype={selectedDataType}
                                productAttributeValues={productAttributeValues}
                                attributeValeuHandler={attributeValeuHandler}
                                isCore={isCore}
                                textRichEditorData={textRichEditorData}
                                setTextRichEditorData={setTextRichEditorData}
                                setOpenImageModel={setOpenImageModel}
                                getAttributeUnitValue={getAttributeUnitValue}
                                generateObjectFromUrlWithFullImagePath={generateObjectFromUrlWithFullImagePath}
                                setImageData={setImageData}
                                generateObjectFromUrl={generateObjectFromUrl}
                                attributeFileType={attributeFileType}
                                productOptions={productOptions}
                                setProductOptions={setProductOptions}
                                setIsPhoneCodeNotValid={setIsPhoneCodeNotValid}
                                setPhoneCode={setPhoneCode}
                                isPhoneCodeNotValid={isPhoneCodeNotValid}
                                phoneCode={phoneCode}
                                validatePositiveFloatNumber={validatePositiveFloatNumber}
                                validatePositiveNumber={validatePositiveNumber}
                                invalidAttributeValue={invalidAttributeValue}
                                langDirection={langDirection}
                                isAttributeValueSelected={isAttributeValueSelected}
                                getImageUrl={getImageUrl}
                            />
                        </>
                    ) : (
                        ''
                    )}
                    {/* Advanced settings for the attribute unit */}
                    <Content className='w-full mt-3'>
                        {productOptions.attributes.attribute_unit_value.length > 0 &&
                        selectedDataType !== 6 &&
                        selectedDataType !== 1 &&
                        selectedDataType !== 2 &&
                        selectedDataType !== 3 &&
                        selectedDataType !== 7 &&
                        selectedDataType !== 8 &&
                        selectedDataType !== 11 ? (
                            <AttributeUnitControl
                                defaultMin={
                                    selectedDataType === 24 || selectedDataType === 26 || selectedDataType === 23
                                        ? getAttributeUnitValue('min')[0].value[0] != ''
                                            ? parseInt(getAttributeUnitValue('min')[0].value[0])
                                            : getAttributeUnitValue('min')[0].value[0]
                                        : ''
                                }
                                defaultMax={
                                    selectedDataType === 24 || selectedDataType === 26 || selectedDataType === 23
                                        ? getAttributeUnitValue('max')[0].value[0] != ''
                                            ? parseInt(getAttributeUnitValue('max')[0].value[0])
                                            : getAttributeUnitValue('max')[0].value[0]
                                        : ''
                                }
                                attributeUnit={productOptions.attributes.attribute_unit_value}
                                attributeUnitHandler={attributeUnitHandler}
                                productOptions={productOptions}
                                dataType={selectedDataType}
                                storeLanuage={storeLanguageFromReduxState}
                                attributeExtension={attributeExtension}
                                setMaxAndminFailed={setMaxAndminFailed}
                                extensionRequired={isExtensionRequired}
                                saveButtonClicked={saveButtonClicked}
                                langDirection={langDirection}></AttributeUnitControl>
                        ) : null}
                    </Content>
                </Col>
                <Col offset={1} span={10} className={`${isEditting ? 'mt-[1.8rem]' : 'mb-4'}`}>
                    {/* Settings col of the product attribute  */}
                    {
                        <Row className='bg-gray-100 p-2'>
                            <Col span={18} offset={1}>
                                <Typography.Title level={5}>{t('attributes:preference')}</Typography.Title>
                            </Col>
                            {attibutePrefernce
                                .filter(({ isEnable }) => isEnable)
                                .map(({ type, settingsName, isMandtory, description }) => {
                                    return (selectedDataType === 2 ||
                                        selectedDataType === 3 ||
                                        selectedDataType === 4 ||
                                        selectedDataType === 7 ||
                                        selectedDataType === 8) &&
                                        type === 'is_variant_attribute' ? (
                                        <Col
                                            span={11}
                                            className='px-3 py-1 flex items-center'
                                            key={`${type}_${productOptions.attributes[type]}_${settingsName}`}>
                                            <div className='whitespace-nowrap gap-2 flex items-center'>
                                                <Checkbox
                                                    checked={productOptions.attributes[type] || isMandtory}
                                                    disabled={
                                                        type &&
                                                        (type === 'attribute_type' ||
                                                            (t(settingsName.props.translate) === 'Revisable'
                                                                ? disableRevisable ||
                                                                  attributeTypeValue === 'Journey' ||
                                                                  (isEditting && selectedAttributeType === 'Journey')
                                                                : false) ||
                                                            (t(settingsName.props.translate) === 'Static'
                                                                ? choices == 'Variant'
                                                                    ? true
                                                                    : disableStatic ||
                                                                      attributeTypeValue === 'Journey' ||
                                                                      (isEditting &&
                                                                          selectedAttributeType === 'Journey')
                                                                : false) ||
                                                            (t(settingsName.props.translate) === 'Sensitive'
                                                                ? disableSensible
                                                                : false) ||
                                                            (t(settingsName.props.translate) === 'Mandatory'
                                                                ? disableMandatory
                                                                : false) ||
                                                            (type === 'varient_specific' && choices == 'Variant'))
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        attributeSettingsHandler(type, e.target.checked, isMandtory)
                                                        addInputFieldForNonEditable(type, e.target.checked)
                                                        if (t(settingsName.props.translate) === 'Static') {
                                                            let copyOfProductOptionValues = { ...productOptionValues }
                                                            copyOfProductOptionValues?.attribute_options.forEach(
                                                                (obj) => {
                                                                    obj.attributeValueSelected = false
                                                                }
                                                            )
                                                            setProductAttributeValues(
                                                                getAttributeValues(copyOfProductOptionValues)
                                                            )
                                                        }
                                                    }}
                                                />
                                                {isMandtory ? (
                                                    <div className='flex'>
                                                        {/* <p className='input-label-color'>{settingsName}</p> */}
                                                        <div className=' max-w-[100px]'>
                                                            <Text
                                                                ellipsis={{
                                                                    tooltip: {
                                                                        title: settingsName,
                                                                        mouseLeaveDelay: 0,
                                                                        mouseEnterDelay: 0.5,
                                                                    },
                                                                }}
                                                                className='input-label-color'>
                                                                {settingsName}
                                                            </Text>
                                                        </div>
                                                        <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                                                    </div>
                                                ) : (
                                                    // <p className='input-label-color'>{settingsName}</p>
                                                    <div className=' max-w-[100px]'>
                                                        <Text
                                                            ellipsis={{
                                                                tooltip: {
                                                                    title: settingsName,
                                                                    mouseLeaveDelay: 0,
                                                                    mouseEnterDelay: 0.5,
                                                                },
                                                            }}
                                                            className='input-label-color'>
                                                            {settingsName}
                                                        </Text>
                                                    </div>
                                                )}
                                                <Tooltip title={description} placement='right'>
                                                    <InfoCircleTwoTone twoToneColor={'#7d3192'} className='text-xs' />
                                                </Tooltip>
                                            </div>
                                        </Col>
                                    ) : (
                                        <>
                                            {type !== 'is_variant_attribute' ? (
                                                <Col
                                                    span={11}
                                                    className='px-3 py-1 flex items-center'
                                                    key={`${type}_${productOptions.attributes[type]}_${settingsName}`}>
                                                    <div className='whitespace-nowrap gap-2 flex items-center'>
                                                        <Checkbox
                                                            checked={productOptions.attributes[type] || isMandtory}
                                                            disabled={
                                                                type &&
                                                                (type === 'attribute_type' ||
                                                                    (t(settingsName.props.translate) === 'Revisable'
                                                                        ? disableRevisable ||
                                                                          attributeTypeValue === 'Journey' ||
                                                                          (isEditting &&
                                                                              selectedAttributeType === 'Journey')
                                                                        : false) ||
                                                                    (t(settingsName.props.translate) === 'Static'
                                                                        ? choices == 'Variant'
                                                                            ? true
                                                                            : disableStatic ||
                                                                              attributeTypeValue === 'Journey' ||
                                                                              (isEditting &&
                                                                                  selectedAttributeType === 'Journey')
                                                                        : false) ||
                                                                    (t(settingsName.props.translate) === 'Sensitive'
                                                                        ? disableSensible
                                                                        : false) ||
                                                                    (t(settingsName.props.translate) === 'Mandatory'
                                                                        ? disableMandatory
                                                                        : false) ||
                                                                    (type === 'varient_specific' &&
                                                                        choices == 'Variant'))
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={(e) => {
                                                                attributeSettingsHandler(
                                                                    type,
                                                                    e.target.checked,
                                                                    isMandtory
                                                                )
                                                                addInputFieldForNonEditable(type, e.target.checked)
                                                                if (t(settingsName.props.translate) === 'Static') {
                                                                    let copyOfProductOptionValues = {
                                                                        ...productOptionValues,
                                                                    }
                                                                    copyOfProductOptionValues?.attribute_options.forEach(
                                                                        (obj) => {
                                                                            obj.attributeValueSelected = false
                                                                        }
                                                                    )
                                                                    setProductAttributeValues(
                                                                        getAttributeValues(copyOfProductOptionValues)
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                        {isMandtory ? (
                                                            <div className='flex'>
                                                                {/* <p className='input-label-color'>{settingsName}</p> */}
                                                                <div className=' max-w-[100px]'>
                                                                    <Text
                                                                        ellipsis={{
                                                                            tooltip: {
                                                                                title: settingsName,
                                                                                mouseLeaveDelay: 0,
                                                                                mouseEnterDelay: 0.5,
                                                                            },
                                                                        }}
                                                                        className='input-label-color attributePreference'>
                                                                        {settingsName}
                                                                    </Text>
                                                                </div>
                                                                <span className='mandatory-symbol-color text-sm mx-1'>
                                                                    *
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            // <p className='input-label-color'>{settingsName}</p>
                                                            <div className=' max-w-[113px]'>
                                                                <Text
                                                                    ellipsis={{
                                                                        tooltip: {
                                                                            title: settingsName,
                                                                            mouseLeaveDelay: 0,
                                                                            mouseEnterDelay: 0.5,
                                                                        },
                                                                    }}
                                                                    className='input-label-color attributePreference'>
                                                                    {settingsName}
                                                                </Text>
                                                            </div>
                                                        )}

                                                        <Tooltip title={description} placement='right'>
                                                            <RiInformationLine className='text-xl mr-2 input-label-color' />
                                                        </Tooltip>
                                                    </div>
                                                </Col>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )
                                })}
                            {attributeType?.length > 1 ? (
                                <div>
                                    {/* Attribute type drop down */}
                                    {
                                        <div className='pl-4 flex-col mt-3'>
                                            <Typography.Title level={5}>
                                                {t('attributes:attribute_type')}
                                            </Typography.Title>
                                            {productOptions.attributes.attribute_type ||
                                            (attibutePrefernce.filter(({ type }) => type === 'attribute_type')[0]
                                                .isMandtory &&
                                                attibutePrefernce.filter(({ type }) => type === 'attribute_type')[0]
                                                    .isEnable) ? (
                                                <div>
                                                    <Radio.Group
                                                        buttonStyle='outline'
                                                        placeholder={t('attributes:select_attribute_type')}
                                                        className='custom-radio-button custom-radio-button-hover custom-radio-button-disabled custom-radio-button-disabled-hover'
                                                        onChange={(e) => {
                                                            console.log(
                                                                'value of type productAddOptionValues.js',
                                                                e.target.value
                                                            )
                                                            setSelectedAttributeType(e.target.value)
                                                            setAttributeTypeValue(e.target.value)
                                                            attributeTypeHandler(e.target.value)
                                                            setAttributeTypeFieldEmpty(false)
                                                        }}
                                                        defaultValue={
                                                            productOptions.attributes.attribute_type === ''
                                                                ? t('attributes:simple_input_field')
                                                                : productOptions.attributes.attribute_type
                                                        }
                                                        disabled={isEditting || attributeType.length == 1}>
                                                        {attributeType &&
                                                            attributeType.length > 0 &&
                                                            attributeType.map((value) => (
                                                                <Radio.Button
                                                                    value={value}
                                                                    key={`${value}_${
                                                                        attibuteTypes.filter(
                                                                            ({ type }) => type == value
                                                                        )[0].settingsName
                                                                    }`}>
                                                                    {
                                                                        attibuteTypes.filter(
                                                                            ({ type }) => type == value
                                                                        )[0].settingsName
                                                                    }
                                                                </Radio.Button>
                                                            ))}
                                                    </Radio.Group>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    }
                                    {/* Attribute type description */}
                                    <Row className='mt-2 pl-4'>
                                        <p>
                                            {
                                                attibuteTypes.filter(
                                                    ({ type }) => type == productOptions.attributes.attribute_type
                                                )[0].description
                                            }
                                        </p>
                                    </Row>{' '}
                                </div>
                            ) : null}
                        </Row>
                    }
                </Col>
            </Row>
        )
    }
    // This function will remove tags in the string
    function removeTags(txt) {
        var rex = /<\s*[^>]*>/gi
        var stripped = txt.replace(rex, '')
        return stripped
    }

    // This function will validate the attribute option values and wil update "isInValidField" boolen either true or false based on the regex pattern and will return true, if all the attribute values are matching it
    // if there is no regex pattern , it will not check the condition, will not update and will return false.
    const validateAttributeOptionValues = () => {
        if (
            selectedRegEx !== '' &&
            productOptions.attributes.is_static &&
            productOptionValues &&
            productOptionValues.attribute_options.length > 0
        ) {
            const copyOfProductOptionValues = { ...productOptionValues }
            copyOfProductOptionValues.attribute_options.forEach((obj) => {
                // if (!new RegExp(selectedRegEx).test(obj.option_name)) {
                if (
                    !testValueByRegexPattern(
                        selectedRegEx,
                        selectedOption === 'Time'
                            ? obj.option_name.split(' ')[0]
                            : selectedDataType == 10
                              ? removeTags(obj.option_name)
                              : obj.option_name
                    )
                ) {
                    obj.isInValidField = true
                } else {
                    obj.isInValidField = false
                }
            })
            if (copyOfProductOptionValues.attribute_options.filter((obj) => obj.isInValidField).length > 0) {
                setproductOptionValues(copyOfProductOptionValues)
                return true
            } else {
                setproductOptionValues(copyOfProductOptionValues)
                return false
            }
        } else {
            if (isEditting && productAttributeValues && productAttributeValues.length > 0 && selectedRegEx !== '') {
                const copyOfProductAttributeValues = [...productAttributeValues]
                copyOfProductAttributeValues.forEach((obj) => {
                    if (
                        obj.attribute_value !== '' &&
                        obj.attribute_value !== undefined &&
                        typeof obj.attribute_value === 'string' &&
                        !testValueByRegexPattern(
                            selectedRegEx,
                            selectedOption === 'Time'
                                ? obj.attribute_value.split(' ')[0]
                                : selectedDataType == 10
                                  ? removeTags(obj.attribute_value)
                                  : obj.attribute_value
                        )
                    ) {
                        obj.isAttributeValueFieldInvalid = true
                    } else {
                        obj.isAttributeValueFieldInvalid = false
                    }
                })
                if (copyOfProductAttributeValues.filter((obj) => obj.isAttributeValueFieldInvalid).length > 0) {
                    setProductAttributeValues(copyOfProductAttributeValues)
                    return true
                } else {
                    setProductAttributeValues(copyOfProductAttributeValues)
                    return false
                }
            } else {
                return false
            }
        }
    }

    const checkForDuplicateOptionValues = () => {
        const copyOfProductOptionValues = { ...productOptionValues }
        const copyOfProductOptionValuestwo = deepCopy(productOptionValues)
        if (
            copyOfProductOptionValues &&
            copyOfProductOptionValues.attribute_options &&
            copyOfProductOptionValues.attribute_options.length >= 2
        ) {
            copyOfProductOptionValues.attribute_options.forEach(({ option_name }, pi) => {
                copyOfProductOptionValuestwo.attribute_options.forEach((obj, ci) => {
                    if (obj.option_name === option_name && pi !== ci) {
                        obj.isAttributeOptionValueFieldEmpty = true
                    }
                })
            })
        } else {
            return false
        }
        setproductOptionValues(copyOfProductOptionValuestwo)
        if (
            copyOfProductOptionValuestwo.attribute_options &&
            copyOfProductOptionValuestwo.attribute_options.filter(
                ({ isAttributeOptionValueFieldEmpty }) => isAttributeOptionValueFieldEmpty
            ).length >= 2
        ) {
            return true
        } else {
            return false
        }
    }

    const checkForEmptyAttributeOption = () => {
        let optionsCopy = { ...productOptions }
        if (optionsCopy.option_Name.trim() === '') {
            optionsCopy.is_AttributeFieldEmpty = true
            setProductOptions(optionsCopy)
            return true
        } else {
            optionsCopy.is_AttributeFieldEmpty = false
            setProductOptions(optionsCopy)
            return false
        }
    }
    const validateFloatValue = () => {
        const copyOfProductOptionValues = { ...productOptionValues }
        let min = typeof (
            getAttributeUnitValue('min')[0].value[0] == 'string' && getAttributeUnitValue('min')[0].value[0] != ''
        )
            ? parseInt(getAttributeUnitValue('min')[0].value[0])
            : getAttributeUnitValue('min')[0].value[0]
        let max = typeof (
            getAttributeUnitValue('max')[0].value[0] == 'string' && getAttributeUnitValue('max')[0].value[0] != ''
        )
            ? parseInt(getAttributeUnitValue('max')[0].value[0])
            : getAttributeUnitValue('max')[0].value[0]
        let optionValue =
            copyOfProductOptionValues.attribute_options.length > 0 &&
            parseInt(copyOfProductOptionValues.attribute_options[0].option_name)
        if (
            min &&
            min != '' &&
            max &&
            max != '' &&
            optionValue &&
            (selectedDataType == 23 || selectedDataType == 24 || selectedDataType == 26)
        ) {
            if (optionValue >= min && optionValue <= max) {
                setInvalidAttributeValue(false)
                return false
            } else {
                setInvalidAttributeValue(true)
                return true
            }
        } else {
            setInvalidAttributeValue(false)
            return false
        }
    }

    const validateFloatValueEdit = () => {
        let productAttribValuesCopy = [...productAttributeValues]
        let min = typeof (
            getAttributeUnitValue('min')[0].value[0] == 'string' && getAttributeUnitValue('min')[0].value[0] != ''
        )
            ? parseInt(getAttributeUnitValue('min')[0].value[0])
            : getAttributeUnitValue('min')[0].value[0]
        let max = typeof (
            getAttributeUnitValue('max')[0].value[0] == 'string' && getAttributeUnitValue('max')[0].value[0] != ''
        )
            ? parseInt(getAttributeUnitValue('max')[0].value[0])
            : getAttributeUnitValue('max')[0].value[0]
        let optionValue = parseInt(productAttribValuesCopy[0].attribute_value)
        console.log('Handle...', min, max, optionValue)
        if (
            min &&
            min != '' &&
            max &&
            max != '' &&
            optionValue &&
            (selectedDataType == 23 || selectedDataType == 24 || selectedDataType == 26)
        ) {
            if (optionValue >= min && optionValue <= max) {
                setInvalidAttributeValue(false)
                return false
            } else {
                setInvalidAttributeValue(true)
                return true
            }
        } else {
            setInvalidAttributeValue(false)
            return false
        }
    }

    const validateExtensionChangeAdd = () => {
        let optionValueCopy = { ...productOptionValues }
        let copyofProductOptions = { ...productOptions }
        if (
            optionValueCopy &&
            optionValueCopy.attribute_options.length > 0 &&
            optionValueCopy.attribute_options[0].option_name != ''
        ) {
            let ext =
                (selectedDataType == 16 || selectedDataType == 18 ? '.' : '') +
                optionValueCopy.attribute_options[0].option_name[0].extension.toLowerCase()
            console.log('product option values...', ext, copyofProductOptions.attributes.attribute_unit_value[0].value)
            if (copyofProductOptions.attributes.attribute_unit_value[0].value.includes(ext)) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const validateExtensionChangeEdit = () => {
        if (
            productAttributeValues &&
            productAttributeValues.length > 0 &&
            productAttributeValues[0].attribute_value != ''
        ) {
            let ext
            if (typeof productAttributeValues[0].attribute_value === 'string') {
                ext =
                    (selectedDataType == 16 || selectedDataType == 18 ? '.' : '') +
                    productAttributeValues[0].attribute_value.split('.')[1].toLowerCase()
            } else {
                ext =
                    (selectedDataType == 16 || selectedDataType == 18 ? '.' : '') +
                    productAttributeValues[0].attribute_value[0].extension.toLowerCase()
            }
            // console.log("product option values...", productOptions.attributes.attribute_unit_value[0].value, productAttributeValues[0].attribute_value)
            if (productOptions.attributes.attribute_unit_value[0].value.includes(ext)) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    }

    const checkForEmptyAttributeOptionValues = () => {
        const copyOfProductOptionValues = { ...productOptionValues }
        copyOfProductOptionValues.attribute_options.forEach((obj) => {
            if (
                (obj.option_name === null || obj.option_name === undefined
                    ? ''
                    : typeof obj.option_name === 'string'
                      ? obj.option_name.trim()
                      : obj.option_name) === ''
            ) {
                obj.isAttributeOptionValueFieldEmpty = true
            } else {
                obj.isAttributeOptionValueFieldEmpty = false
            }
        })
        if (
            copyOfProductOptionValues.attribute_options.filter((obj) => obj.isAttributeOptionValueFieldEmpty).length > 0
        ) {
            setproductOptionValues(copyOfProductOptionValues)
            return true
        } else {
            setproductOptionValues(copyOfProductOptionValues)
            return false
        }
    }
    // The function will be called only when the is_static setting is enabled
    // And will return true, if the value is selected or will return false
    const checkforAttributeValue = () => {
        let productAttribValuesCopy = [...productAttributeValues]
        if (productAttribValuesCopy.filter(({ attributeValueSelected }) => attributeValueSelected).length > 0) {
            return true
        } else {
            return false
        }
    }

    //  The function will check wheather non editable field value is empty or not
    //  and will return true, if it is empty or will return false
    //  this function will be called only for the datagroup, oher than option...
    const checkforEmptyAttributeValue = () => {
        let productAttribValuesCopy = [...productAttributeValues]
        if (
            (productAttribValuesCopy[0].attribute_value === null ||
            productAttribValuesCopy[0].attribute_value === undefined
                ? ''
                : typeof productAttribValuesCopy[0].attribute_value === 'string'
                  ? productAttribValuesCopy[0].attribute_value.trim()
                  : productAttribValuesCopy[0].attribute_value) === ''
        ) {
            productAttribValuesCopy[0].isNonEditableFieldEmpty = true
            setProductAttributeValues(productAttribValuesCopy)
            return true
        } else {
            productAttribValuesCopy[0].isNonEditableFieldEmpty = false
            setProductAttributeValues(productAttribValuesCopy)
            return false
        }
    }

    const checkForPrefixFieldEmty = () => {
        if (
            productOptions.attributeValuePrefix &&
            getAttributeUnitValue('prefix_displayname')[0]?.value[0].trim() === ''
        ) {
            setIsPrefixFieldEmpty(true)
            return true
        } else {
            return false
        }
    }

    const checkForSuffixFieldEmty = () => {
        if (
            productOptions.attributeValueSuffix &&
            getAttributeUnitValue('suffix_displayname')[0]?.value[0].trim() === ''
        ) {
            setIsSufixFieldEmpty(true)
            return true
        } else {
            return false
        }
    }

    const checkForAttributeGroup = () => {
        let optionsCopy = { ...productOptions }
        if (optionsCopy.attributes.product_attribute_group === '') {
            setIsAttributeGroupEmpty(true)
            return true
        } else {
            setIsAttributeGroupEmpty(false)
            return false
        }
    }

    const getFileUploadActionType = () => {
        switch (type) {
            case 'Product_Revision':
                return 'product'
            case 'Category_Revision':
                return 'category'
            default:
                return 'product'
        }
    }

    const checkForMandatoryExtension = () => {
        let copyofProductOptions = { ...productOptions }
        let extensionValue = copyofProductOptions.attributes.attribute_unit_value[0].value
        setSaveButtonClicked(true)
        if (extensionValue.length > 0) {
            setIsExtensionRequired(false)
            return false
        } else {
            setIsExtensionRequired(true)
            return true
        }
    }

    const formatExtension = (extensions) => {
        let formatedExtension = ''
        extensions.forEach((ext, index) => {
            let comma = index == extensions.length - 1 ? '' : ','
            if (ext.slice(0, 1).indexOf('.', 0) === -1) {
                formatedExtension = formatedExtension + '.' + ext + comma
            } else {
                formatedExtension = formatedExtension + ext + comma
            }
        })
        return formatedExtension
    }

    const handleOptionTranslaterModal = (optionId) => {
        if (productOptionValues) {
            let jsonDataForAttributeOptionNameDetails = []
            let temp =
                productOptionValues &&
                productOptionValues.attribute_options &&
                productOptionValues.attribute_options.length > 0 &&
                productOptionValues.attribute_options.map((ele) => {
                    if (parseInt(optionId) === parseInt(ele.id)) {
                        jsonDataForAttributeOptionNameDetails.push({
                            attributeOptionName: ele.option_name,
                            attributeOptionId: ele.id,
                            attibuteId: productOptionValues && productOptionValues.attributeID,
                        })
                    }
                })
            setAttributeOptionNameDetailsForTranslater(jsonDataForAttributeOptionNameDetails)
            setAttributeNameDetailsForTranslater([])
            setTranslateModalVisible(true)
        }
    }

    const handleAttributeNameTranslaterModal = () => {
        if (productOptions) {
            let jsonDataForAttributeNameDetails = [
                {
                    name: productOptions && productOptions.attributes.name,
                    attributeId: productOptions && productOptions.id,
                    helperText: productOptions && productOptions.attributes.help_text,
                    placeholder: productOptions && productOptions.attributes.placeholder,
                    attributeUnitValue:
                        productOptions && productOptions.attributes && productOptions.attributes.attribute_unit_value,
                    belongsTo: type,
                    belongingId: revisionId,
                    dataType:
                        productOptions &&
                        productOptions.attributes &&
                        productOptions.attributes.datatype &&
                        productOptions.attributes.datatype.id,
                },
            ]
            setAttributeNameDetailsForTranslater(jsonDataForAttributeNameDetails)
            setAttributeOptionNameDetailsForTranslater([])
            setTranslateModalVisible(true)
        }
    }

    const translateModalCloseHandler = () => {
        setTranslateModalVisible(false)
    }
    const validatePhoneCode = () => {
        let phoneNumber = phoneCode.charAt(1)
        if (phoneNumber !== '') {
            if (phoneNumber > 0) {
                setIsPhoneCodeNotValid(false)
                return false
            } else {
                setIsPhoneCodeNotValid(true)
                return true
            }
        } else {
            setIsPhoneCodeNotValid(true)
            return true
        }
    }

    return (
        <div>
            {/* Header */}
            <div className='px-3 pt-3'>
                <div className='input-label-color text-[16px] font-medium mb-1'>
                    {isEditting ? t('attributes:edit_attribute') : t('attributes:add_attribute')}
                </div>
                <div className={`text-[18px] font-bold mb-1`}>{selectedOption}</div>
                {/* Swap componnet */}
                <div className='flex mb-2'>
                    {!isEditting && (
                        <Button
                            onClick={() => openChooseOptionWindow()}
                            type='link'
                            style={{ fontWeight: 500 }}
                            icon={langDirection === 'rtl' ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
                            className='flex items-center px-0
                            text-brandPrimaryColor hover:!text-brandPrimaryColor
                            '>
                            {t('attributes:choose_different_component')}
                        </Button>
                    )}
                </div>
            </div>
            <Divider className='!m-0'></Divider>
            {/* Image pop up */}
            {openImageModel && (
                <ListImageAndAddImage
                    openModal={openImageModel}
                    setOpenModal={setOpenImageModel}
                    actionType={[getFileUploadActionType()]}
                    selectedFileData={imageData}
                    setSelectedFileData={setImageData}
                    selectionOption={'single'}
                    supportedExtensions={
                        selectedDataType === 16 || selectedDataType === 18 || selectedDataType === 19
                            ? formatExtension(productOptions.attributes.attribute_unit_value[0].value)
                            : attributeExtension
                    }
                    fileType={attributeFileType}></ListImageAndAddImage>
            )}
            <StoreModal
                title={t('attributes:delete_attribute_value')}
                isVisible={isOptionValueDeletionModalOpen}
                cancelButtonText={t('common:cancel')}
                okButtonText={t('common:ok')}
                okCallback={() => deleteOptionValues()}
                cancelCallback={() => closeOptionValueDeleteModal()}
                isSpin={false}
                hideCloseButton={false}>
                <div>
                    <Trans
                        i18nKey={'attributes:opt_val_del_warning_mess'}
                        components={{
                            p: <p style={{ paddingTop: '8px', paddingBottom: '8px' }} />,
                            span1: <span style={{ fontWeight: 600 }} />,
                            span2: <span style={{ color: 'red' }} />,
                            p2: (
                                <p
                                    style={{
                                        fontSize: '12px',
                                        lineHeight: '16px',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                    }}
                                />
                            ),
                        }}
                        shouldUnescape={true}
                    />
                </div>
            </StoreModal>
            <StoreModal
                title={t('common:translate')}
                isVisible={translateModalVisible}
                okButtonText={null}
                cancelButtonText={null}
                hideCloseButton={false}
                okCallback={null}
                cancelCallback={() => translateModalCloseHandler()}
                isSpin={false}
                width={1000}>
                {/* testt */}
                <Content>
                    <AttributeTranslatorModal
                        dataJsonForAttributeName={[
                            {
                                order: 1,
                                label: `${t('attributes:attribute_name')}`,
                                type: 'textbox',
                                value: attributeNameDetailsForTranslater,
                            },
                        ]}
                        dataJsonForAttributeOption={[
                            {
                                order: 1,
                                label: `${t('attributes:attribute_options')}`,
                                type: 'textbox',
                                value: attributeOptionNameDetailsForTranslater,
                            },
                        ]}
                        setTranslateModalVisible={setTranslateModalVisible}
                        onChangeDisableFields={onChangeDisableFields}
                        setOnChangeDisableFields={setOnChangeDisableFields}
                        setIsMultilingualCallSuccess={setIsMultilingualCallSuccess}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                title={t('common:information')}
                isVisible={isDeleteOptionValuesWarningModal}
                okButtonText={t('attributes:confirm')}
                cancelButtonText={t('common:reject')}
                okCallback={() => deleteAllOptionValues()}
                cancelCallback={() => closeDeleteOptionValuesWarningModal()}
                hideCloseButton={false}
                isSpin={false}>
                <div>
                    <p className='py-2'>
                        {t('attributes:del_following_attr_val')}
                        {/* You are about to delete the following attribute values: */}
                    </p>
                    <ul className='list-disc ml-5 pb-1'>
                        {optionValueDeletionId.map(({ value }) => {
                            return <li className='text-red-600'>{value}</li>
                        })}
                    </ul>
                    {productOptionValues &&
                    productOptionValues.attribute_options.filter(({ id }) => typeof id === 'string').length > 0 ? (
                        <>
                            {t('attributes:will_be_adding_follow_new_val')}
                            {/* And will be adding the following new values: */}
                            <ul className='list-disc ml-5 pb-1'>
                                {productOptionValues.attribute_options
                                    .filter(({ id }) => typeof id === 'string')
                                    .map(({ option_name }) => {
                                        return <li className='text-green-600'>{option_name}</li>
                                    })}
                            </ul>
                        </>
                    ) : (
                        ''
                    )}
                    <p className='py-2'>
                        <Trans
                            i18nKey={t('attributes:del_attr_val_confirm_mess')}
                            components={{ span: <span style={{ color: 'red' }} /> }}
                            shouldUnescape={true}
                        />
                    </p>
                </div>
            </StoreModal>
            <StoreModal
                title={t('attributes:add_attribute_group')}
                isVisible={isSegmentForm}
                cancelCallback={() => closeSegmentForm()}
                isSpin={false}
                okButtonText={null}
                cancelButtonText={null}
                hideCloseButton={false}>
                <SegmentForm
                    setAttributeFormResponse={setResponseFromAttributeGroupForm}
                    lngCode={defaultLngsCode}
                    closeSegmentForm={() => closeSegmentForm()}></SegmentForm>
            </StoreModal>
            {getOptionValuesForm()}

            {/* will show some information before the delete */}
            <div>
                {deleteOptionValueInfo && (
                    <Alert
                        style={{
                            marginLeft: '25px',
                            marginTop: '10px',
                            marginBottom: '20px',
                        }}
                        message={t('common:information')}
                        description={t('attributes:del_opt_val_info_desc_text')}
                        type='info'
                    />
                )}
            </div>

            <Divider className='!m-0'></Divider>
            {/* Footer */}
            <div className='flex justify-end p-3'>
                {
                    <Button
                        style={{ marginLeft: '15px', fontWeight: 500 }}
                        className={'app-btn-secondary'}
                        onClick={() => {
                            closeAddOptionValuesForm(
                                'edit',
                                'discard',
                                productOptions.isNonEditableValueEdited ||
                                    productOptions.is_attribute_option_edited ||
                                    isAttributeOptionDeleted
                                    ? false
                                    : true
                            )
                            if (attributeDetailsTranslated) {
                                dispatch(fnAttributeDetailsUpdated(true))
                            } else {
                                dispatch(fnAttributeDetailsUpdated('discard'))
                            }
                            setIsEditting(false)
                        }}>
                        {t('common:cancel')}
                    </Button>
                }
                {
                    <Button
                        style={{
                            marginLeft: '15px',
                            fontWeight: 500,
                        }}
                        className={
                            isEditting
                                ? productOptions.isNonEditableValueEdited ||
                                  productOptions.is_attribute_option_edited ||
                                  isAttributeOptionDeleted
                                    ? 'app-btn-primary'
                                    : ''
                                : 'app-btn-primary'
                        }
                        onClick={() => {
                            // check for emty fields before adding the products
                            // Todo, Now we are allowing post call, only when attribute type is checked as it is mandatory from backend
                            //  below code will be uncommented once the discussion is finalized
                            let isAttribueOptionEmpty = checkForEmptyAttributeOption()
                            let isAttribueOptionValuesEmpty =
                                productOptionValues && productOptionValues.attribute_options.length > 0
                                    ? checkForEmptyAttributeOptionValues()
                                    : false
                            let isAttributeValueChecked =
                                productOptions.attributes.is_static && productOptionValues.dataGroup === 'Optional'
                                    ? checkforAttributeValue()
                                    : true
                            let isAttribueValueFieldEmpty =
                                productOptionValues &&
                                productOptionValues.dataGroup !== 'Optional' &&
                                isEditting &&
                                productAttributeValues.length > 0 &&
                                productOptions.attributes.is_static
                                    ? checkforEmptyAttributeValue()
                                    : false
                            let prefixFieldEmpty = checkForPrefixFieldEmty()
                            let sufixFieldEmpty = checkForSuffixFieldEmty()
                            let attributeGroupEmpty = checkForAttributeGroup()
                            let duplicateOptionValues = checkForDuplicateOptionValues()
                            let isUploadContent =
                                selectedDataType == 16 || selectedDataType == 18 || selectedDataType == 19
                                    ? checkForMandatoryExtension()
                                    : false
                            let isPhoneCode = selectedDataType == 12 ? validatePhoneCode() : false
                            let isInvalidLimitValue = isAttribueOptionValuesEmpty
                                ? false
                                : selectedDataType == 23 || selectedDataType == 24 || selectedDataType == 26
                                  ? validateFloatValue()
                                  : false
                            let isInValidLimitValueEdit = isAttribueValueFieldEmpty
                                ? false
                                : (selectedDataType == 23 || selectedDataType == 24 || selectedDataType == 26) &&
                                    isEditting &&
                                    productOptions.attributes.is_static &&
                                    productAttributeValues.length > 0
                                  ? validateFloatValueEdit()
                                  : false
                            let extensionChangeAdd =
                                (selectedDataType == 16 || selectedDataType == 18) &&
                                productOptions.attributes.is_static &&
                                !isAttribueOptionValuesEmpty
                                    ? validateExtensionChangeAdd()
                                    : false
                            let extensionChangeEdit =
                                (selectedDataType == 16 || selectedDataType == 18) &&
                                productOptions.attributes.is_static &&
                                !isAttribueValueFieldEmpty &&
                                isEditting
                                    ? validateExtensionChangeEdit()
                                    : false
                            let filetype = selectedDataType == 16 ? t('attributes:image') : t('attributes:files')
                            if (
                                !isPhoneCode &&
                                !isUploadContent &&
                                !isAttribueOptionEmpty &&
                                !attributeGroupEmpty &&
                                !isAttribueOptionValuesEmpty &&
                                !sufixFieldEmpty &&
                                !prefixFieldEmpty &&
                                isAttributeValueChecked &&
                                (productOptions.attributes.attribute_type || isEditting) &&
                                !isAttribueValueFieldEmpty
                            ) {
                                if (validateAttributeOptionValues()) {
                                    MarketplaceToaster.showToast(util.getToastObject(regExExceptionMessage, 'error'))
                                } else {
                                    // Check for duplicate product option before adding
                                    let duplicateOption = prodAttrib.filter(
                                        (obj) => obj.option_Name === productOptions.option_Name.trim()
                                    )
                                    if (false) {
                                        MarketplaceToaster.showToast(
                                            util.getToastObject(`${t('attributes:attribute_already_exists')}`, 'error')
                                        )
                                    } else {
                                        if (
                                            productOptions.attributes.is_static &&
                                            productAttributeValues.length > 0 &&
                                            productAttributeValues.filter(
                                                ({ attributeValueSelected }) => attributeValueSelected
                                            ).length >= 0
                                        ) {
                                            setIsAttributeValueSelected(true)
                                        }
                                        //  Below call will delete the non editable attribute value
                                        if (deleteAttributeValue && productOptionValues && isEditting) {
                                            deleteNonEditableAttributeValue()
                                        }

                                        // check to identify the attribute values are deleted or not.
                                        // if they are deleted. Making an api call to delete all the values...
                                        if ([...new Set(optionValueDeletionId)].length >= 1) {
                                            setIsDeleteOptionValuesWarningModal(true)
                                        } else {
                                            if (duplicateOptionValues) {
                                                MarketplaceToaster.showToast(
                                                    util.getToastObject(`${t('attributes:duplicate_values')}`, 'error')
                                                )
                                            } else if (isInvalidLimitValue || isInValidLimitValueEdit) {
                                                MarketplaceToaster.showToast(
                                                    util.getToastObject(`${t('attributes:valid_float_value')}`, 'error')
                                                )
                                            } else if (extensionChangeAdd || extensionChangeEdit) {
                                                MarketplaceToaster.showToast(
                                                    util.getToastObject(
                                                        `${t('attributes:error_message_extension_changed', {
                                                            filetype,
                                                        })}`,
                                                        'error'
                                                    )
                                                )
                                            } else if (!maxAndminFailed) {
                                                setProductMenus(
                                                    isOptionValueFieldEditted,
                                                    productOptions,
                                                    productOptions.is_serverData,
                                                    productOptionValues,
                                                    productOptions.attributes.is_static &&
                                                        productOptionValues.dataGroup === 'Optional'
                                                        ? productAttributeValues &&
                                                              productAttributeValues.filter(
                                                                  ({ attributeValueSelected }) => attributeValueSelected
                                                              )
                                                        : productAttributeValues,
                                                    productOptions.attributes.is_static,
                                                    productAttributeGroupData,
                                                    productOptions.isNonEditableValueEdited,
                                                    'edit'
                                                )
                                            }
                                        }
                                    }
                                }
                            } else {
                                // This setter method will handled for the attribute value is they are not selecte
                                if (
                                    productOptions.attributes.is_static &&
                                    productOptionValues.dataGroup === 'Optional' &&
                                    productAttributeValues &&
                                    productAttributeValues.filter(
                                        ({ attributeValueSelected }) => attributeValueSelected
                                    ).length <= 0
                                ) {
                                    setIsAttributeValueSelected(false)
                                }
                                // making the state true, only when the attribute is not selected
                                if (!productOptions.attributes.attribute_type) {
                                    setAttributeTypeFieldEmpty(true)
                                }
                                MarketplaceToaster.showToast(
                                    util.getToastObject(`${t('attributes:fill_attribute_fields')}`, 'error')
                                )
                            }
                            // Reseting the delete info shown to the option value
                            if (deleteOptionValueInfo) {
                                setDeleteOptionValueInfo(false)
                            }
                        }}
                        disabled={
                            isEditting
                                ? productOptions.isNonEditableValueEdited ||
                                  productOptions.is_attribute_option_edited ||
                                  isAttributeOptionDeleted
                                    ? false
                                    : true
                                : false
                        }>
                        {isEditting ? t('common:update') : t('common:save')}
                    </Button>
                }
            </div>
        </div>
    )
}

export default ProductAddOptionValues
