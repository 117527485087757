import { Layout, Spin, Tabs, Tooltip, Typography, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, useLocation } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnVendorSettlementInfo } from '../../services/redux/actions/ActionsVendorSettlement'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import SettlementHeader from './SettlementHeader'

//! ant design initialization
const { Title } = Typography
const { Content } = Layout

const vendorSettlementDetailsAPI = process.env.REACT_APP_VENDOR_DETAILS_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const pagelimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)

const Settlement = () => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal') + ' - ' + String(`${t('transactions:settlements')}`))
    const search = useLocation().search
    const tabId = new URLSearchParams(search).get('tabId')
    const vendorId = new URLSearchParams(search).get('vid')

    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [filteredSettlementData, setFilteredSettlementData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [vendorCount, setVendorCount] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageLimit, setPageLimit] = useState(pagelimit)
    const [spinLoading, setSpinLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(true)
    const [vendorDisplayName, setVendorDisplayName] = useState()
    let vendorSettlementInfoRedux = useSelector((state) => state.reducerVendorSettlementInfo.vendorSettlementInfo)

    //!get call for settlement details
    const findByPageSettlementDetailsList = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(vendorSettlementDetailsAPI, null, pageNumber, pageLimit, false)
            .then(function (response) {
                setIsLoading(false)
                setIsNetworkError(false)
                setShowLoading(false)
                console.log(
                    'Server response from findByPageSettlementDetailsList is',
                    vendorSettlementDetailsAPI,
                    response.data.response_body
                )
                setSpinLoading(false)
                setVendorCount(response.data.response_body.total_vendor_count)
                dispatch(fnVendorSettlementInfo(response.data.response_body))

                settlementDetailsDataProcessor(response.data.response_body.vendors_list)
            })
            .catch(function (error) {
                setShowLoading(false)
                setIsLoading(false)
                setSpinLoading(false)
                setIsNetworkError(true)
                dispatch(fnVendorSettlementInfo({}))
                console.log(
                    'Server Error response from findByPageSettlementDetailsList is',
                    vendorSettlementDetailsAPI,
                    error.response
                )
            })
    }

    //! DataProcessor for settlement details data
    const settlementDetailsDataProcessor = (settlementDetailsData) => {
        let localSettlementDetailsData = []
        if (settlementDetailsData && settlementDetailsData.length > 0) {
            for (var i = 0; i < settlementDetailsData.length; i++) {
                const temp = {}
                temp['key'] = settlementDetailsData[i].vendor_id
                temp['label'] = settlementDetailsData[i].vendor_display_name
                temp['value'] = settlementDetailsData[i].vendor_id
                localSettlementDetailsData.push(temp)
            }
            setFilteredSettlementData(localSettlementDetailsData)
        } else {
            setFilteredSettlementData(localSettlementDetailsData)
        }
    }

    const handlePaymentTabChange = (vendorId) => {
        let filterParticularVendor =
            vendorSettlementInfoRedux &&
            vendorSettlementInfoRedux.vendors_list.filter((ele) => ele.vendor_id === parseInt(vendorId))
        if (filterParticularVendor && filterParticularVendor.length > 0) {
            setVendorDisplayName(filterParticularVendor[0].vendor_display_name)
            setSearchParams({
                vid: vendorId,
                vname: filterParticularVendor[0].vendor__name,
                tabId: 0,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        }
    }

    const handlePageNumberChange = (page, pageSize) => {
        setSpinLoading(true)
        setPageNumber(page)
        setPageLimit(pageSize)
    }

    useEffect(() => {
        if (
            vendorSettlementInfoRedux &&
            vendorSettlementInfoRedux.vendors_list &&
            vendorSettlementInfoRedux.vendors_list.length > 0
        ) {
            if (tabId == null) {
                setVendorDisplayName(vendorSettlementInfoRedux.vendors_list[0].vendor_display_name)
                setSearchParams({
                    vid: vendorSettlementInfoRedux.vendors_list[0].vendor_id,
                    vname: vendorSettlementInfoRedux.vendors_list[0].vendor__name,
                    tabId: 0,
                    store: MarketplaceAppConfig.getStore('onlyClient'),
                })
            }
        }
    }, [vendorSettlementInfoRedux, tabId])

    useEffect(() => {
        window.scrollTo(0, 0)
        findByPageSettlementDetailsList(pageNumber, pageLimit)
    }, [pageNumber, pageLimit])

    useEffect(() => {
        if (vendorSettlementInfoRedux !== undefined) {
            let filterParticularVendor =
                vendorSettlementInfoRedux &&
                vendorSettlementInfoRedux.vendors_list?.filter((ele) => ele.vendor_id === parseInt(vendorId))
            if (filterParticularVendor && filterParticularVendor.length > 0) {
                setVendorDisplayName(filterParticularVendor[0].vendor_display_name)
            }
        }
    }, [vendorDisplayName, vendorSettlementInfoRedux])

    return (
        <Content className='w-full'>
            <HeaderForTitle
                title={
                    <Title level={3} className='!font-medium'>
                        {t('transactions:settlements')}
                    </Title>
                }
            />
            <Content className='!p-3 !pt-28 max-w-full'>
                {isLoading ? (
                    <Content className=' bg-white text-center !p-2'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                        <p>{`${t('common:network_error')}`}</p>
                    </Content>
                ) : (
                    <Content className='flex pt-3 pb-3 !min-h-screen !bg-white rounded-lg shadow-brandShadow overflow-x-auto'>
                        {filteredSettlementData?.length > 0 ? (
                            <>
                                <div className='flex max-w-full flex-grow-0 !pr-3'>
                                    <Content className=''>
                                        {/* <Content className='p-3'>
                                            <Title level={4}> {t('transactions:all_vendors')}</Title>
                                        </Content> */}
                                        <Content>
                                            <Spin spinning={spinLoading}>
                                                <Tabs
                                                    activeKey={vendorId}
                                                    // defaultActiveKey={'0'}
                                                    onTabClick={handlePaymentTabChange}
                                                    tabPosition={'left'}
                                                    className='!mb-0'>
                                                    {filteredSettlementData.map((tab) => (
                                                        <Tabs.TabPane
                                                            key={tab.key}
                                                            tab={
                                                                <Tooltip
                                                                    title={
                                                                        tab.label && tab.label.length > 10
                                                                            ? tab.label
                                                                            : null
                                                                    }>
                                                                    <span
                                                                        style={{
                                                                            maxWidth: '100px',
                                                                            display: 'inline-block',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                        }}>
                                                                        {tab.label}
                                                                    </span>
                                                                </Tooltip>
                                                            }></Tabs.TabPane>
                                                    ))}
                                                </Tabs>

                                                {vendorCount > pageLimit ? (
                                                    <Content className='z-10  w-full'>
                                                        <DmPagination
                                                            currentPage={pageNumber}
                                                            totalItemsCount={vendorCount}
                                                            defaultPageSize={pageLimit}
                                                            pageSize={pageLimit}
                                                            handlePageNumberChange={handlePageNumberChange}
                                                            showSizeChanger={false}
                                                            showTotal={false}
                                                            paginationSize={'small'}
                                                            showQuickJumper={true}

                                                        />
                                                    </Content>
                                                ) : null}
                                            </Spin>
                                        </Content>
                                    </Content>
                                    <Content className='border rounded-lg p-3 w-5/6'>
                                        <SettlementHeader vendorDisplayName={vendorDisplayName} />
                                    </Content>
                                </div>
                            </>
                        ) : (
                            <>
                                {showLoading ? (
                                    <Content className=' bg-white text-center !p-2'>
                                        <SkeletonComponent />
                                    </Content>
                                ) : (
                                    <Content className='mt-16'>
                                        {' '}
                                        <Empty description={t('dashboard:no_vendor_available')} />
                                    </Content>
                                )}
                            </>
                        )}
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default Settlement
