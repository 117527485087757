// Note: As per discussion with sushil and logo, we are maintaing this static response for attribute datatype.
// Because response from the server is still not constitent. yet to be discused.
// Once after the final decision is taken, we will comment or will remove this file from repository

import TranslateAttributeStrings from '../pages/product_new_options/TranslateAttributeStrings'
const attribDataTypeResponse = [
    {
        id: 1,
        name: <TranslateAttributeStrings translate={'attributes:check_box'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 1,
        maximum_number_values: -1,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/checkbox.png',
        display_name: <TranslateAttributeStrings translate={'attributes:check_box'} />,
        description: <TranslateAttributeStrings translate={'attributes:mul_select_opt_from_list_of_choices'} />,
        attribute_unit: [
            {
                id: 1,
                key: 'prefix_displayname',
            },
            {
                id: 2,
                key: 'suffix_displayname',
            },
            {
                id: 3,
                key: 'option_regex',
            },
            {
                id: 4,
                key: 'default_selection',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 2,
        name: <TranslateAttributeStrings translate={'attributes:radio_single_select'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 2,
        maximum_number_values: -1,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/RadioSingleselect.png',
        display_name: <TranslateAttributeStrings translate={'attributes:radio_single_select'} />,
        description: <TranslateAttributeStrings translate={'attributes:sing_sel_opt_from_list_of_choices'} />,
        attribute_unit: [
            {
                id: 1,
                key: 'prefix_displayname',
            },
            {
                id: 2,
                key: 'suffix_displayname',
            },
            {
                id: 3,
                key: 'option_regex',
            },
            {
                id: 4,
                key: 'default_selection',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 3,
        name: <TranslateAttributeStrings translate={'attributes:choice_chips'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 2,
        maximum_number_values: -1,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/ChoiceChips.png',
        display_name: <TranslateAttributeStrings translate={'attributes:choice_chips'} />,
        description: <TranslateAttributeStrings translate={'attributes:mul_select_opt_from_list_of_choices'} />,
        attribute_unit: [
            {
                id: 1,
                key: 'prefix_displayname',
            },
            {
                id: 2,
                key: 'suffix_displayname',
            },
            {
                id: 3,
                key: 'option_regex',
            },
            {
                id: 4,
                key: 'default_selection',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 4,
        name: <TranslateAttributeStrings translate={'attributes:toggle'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 2,
        maximum_number_values: 2,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Toggle.png',
        display_name: <TranslateAttributeStrings translate={'attributes:toggle'} />,
        description: <TranslateAttributeStrings translate={'attributes:datatype_for_toggle_0_or_1'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // ToDO:
    {
        id: 5,
        name: <TranslateAttributeStrings translate={'attributes:date'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'Date should be in YYYY-mm-dd format',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Date.png',
        display_name: <TranslateAttributeStrings translate={'attributes:date'} />,
        description: <TranslateAttributeStrings translate={'attributes:date_accepts_date_of_yyyy_mm_dd'} />,
        attribute_unit: [
            {
                id: 16,
                key: 'format',
            },
        ],
        extension: null,
        file_type: null,
    },
    // Need clarification
    // ToDO:
    // {
    //   id: 6,
    //   name: <TranslateAttributeStrings translate={"attributes:slider"} />,
    //   regex: "[\\s\\S]*$",
    //   regex_exception_message: "This regex accepts everything",
    //   predefined: true,
    //   data_type_group: "Range",
    //   regex_validation: false,
    //   minimum_number_values: 0,
    //   maximum_number_values: 0,
    //   attribute_type: ["Simple", "Journey"],
    //   image: "/image/Slider.png",
    //   display_name: <TranslateAttributeStrings translate={"attributes:slider"} />,
    //   description: <TranslateAttributeStrings translate={"attributes:choose_from_min_to_max_range"} />,
    //   attribute_unit: [
    //     {
    //       id: 18,
    //       key: "left",
    //     },
    //     {
    //       id: 22,
    //       key: "limiter",
    //     },
    //     {
    //       id: 20,
    //       key: "min_value",
    //     },
    //     {
    //       id: 21,
    //       key: "max_value",
    //     },
    //     {
    //       id: 19,
    //       key: "right",
    //     },
    //   ],
    //   extension: null,
    //   file_type: null,
    // },
    {
        id: 7,
        name: <TranslateAttributeStrings translate={'attributes:dropdown_single_select'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 1,
        maximum_number_values: -1,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Dropdown-Singleselect.png',
        display_name: <TranslateAttributeStrings translate={'attributes:dropdown_single_select'} />,
        description: <TranslateAttributeStrings translate={'attributes:dropdown_control_description'} />,
        attribute_unit: [
            {
                id: 13,
                key: 'prefix_displayname',
            },
            {
                id: 14,
                key: 'suffix_displayname',
            },
            {
                id: 15,
                key: 'option_regex',
            },
            {
                id: 16,
                key: 'default_selection',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 8,
        name: <TranslateAttributeStrings translate={'attributes:switcher'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Optional',
        regex_validation: false,
        minimum_number_values: 3,
        maximum_number_values: -1,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Switcher.png',
        display_name: <TranslateAttributeStrings translate={'attributes:switcher'} />,
        description: <TranslateAttributeStrings translate={'attributes:sing_sel_opt_from_list_of_choices'} />,
        attribute_unit: [
            {
                id: 13,
                key: 'prefix_displayname',
            },
            {
                id: 14,
                key: 'suffix_displayname',
            },
            {
                id: 15,
                key: 'option_regex',
            },
            {
                id: 16,
                key: 'default_selection',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 9,
        name: <TranslateAttributeStrings translate={'attributes:time'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'Time should be of format hh:mm:ss',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Time.png',
        display_name: <TranslateAttributeStrings translate={'attributes:time'} />,
        description: <TranslateAttributeStrings translate={'attributes:time_accept_time_in_hh_mm_ss_format'} />,
        attribute_unit: [
            {
                id: 31,
                key: 'format',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 10,
        name: <TranslateAttributeStrings translate={'attributes:rich_text'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'This regex accepts everything',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: false,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Text-Rich.png',
        display_name: <TranslateAttributeStrings translate={'attributes:rich_text'} />,
        description: <TranslateAttributeStrings translate={'attributes:allows_bold_italic_listing_numbering'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    {
        id: 11,
        name: <TranslateAttributeStrings translate={'attributes:email'} />,
        regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/,
        regex_exception_message: 'Please enter your email in format: yourname@example.com',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Text-Email.png',
        display_name: <TranslateAttributeStrings translate={'attributes:email'} />,
        description: <TranslateAttributeStrings translate={'attributes:email_control_description'} />,
        attribute_unit: [
            {
                id: 32,
                key: 'value_regex',
            },
            {
                id: 33,
                key: 'value_regex_error_message',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 12,
        name: <TranslateAttributeStrings translate={'attributes:phone_number'} />,
        regex: '^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
        regex_exception_message: 'Phone numbers must have 10 digits',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Text-Phonenumber.png',
        display_name: <TranslateAttributeStrings translate={'attributes:phone_number'} />,
        description: <TranslateAttributeStrings translate={'attributes:phone_number_control_description'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // Need clarification
    {
        id: 13,
        name: <TranslateAttributeStrings translate={'attributes:text_url'} />,
        regex: '^\\b(?:https?|ftp):\\/\\/[^\\s\\/$.?#].[^\\s]*$',
        regex_exception_message: <TranslateAttributeStrings translate={'attributes:url_format_message'} />,
        predefined: true,
        data_type_group: 'Url',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Url',
        display_name: <TranslateAttributeStrings translate={'attributes:text_url'} />,
        description: <TranslateAttributeStrings translate={'attributes:url_control_description'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    {
        id: 14,
        name: <TranslateAttributeStrings translate={'attributes:date_time'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'DateTime should be of format yyyy-mm-dd hh:mm:ss',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/DateTime.png',
        display_name: <TranslateAttributeStrings translate={'attributes:date_time'} />,
        description: <TranslateAttributeStrings translate={'attributes:date_time_control_description'} />,
        attribute_unit: [
            {
                id: 34,
                key: 'format',
            },
        ],
        extension: null,
        file_type: null,
    },
    // need a clasrification
    // ToDO:
    // {
    //   id: 15,
    //   name: <TranslateAttributeStrings translate={"attributes:file"} />,
    //   regex: "[\\s\\S]*$",
    //   regex_exception_message: "File accepts the all extension",
    //   predefined: true,
    //   data_type_group: "Upload",
    //   regex_validation: true,
    //   minimum_number_values: 0,
    //   maximum_number_values: 0,
    //   attribute_type: ["Simple", "Journey"],
    //   image: "/image/file.png",
    //   display_name: <TranslateAttributeStrings translate={"attributes:file"} />,
    //   description: (
    //     <TranslateAttributeStrings
    //       translate={"attributes:file_control_description"}
    //     />
    //   ),
    //   attribute_unit: [
    //     {
    //       id: 34,
    //       key: "ext",
    //     },
    //   ],
    //   extension: null,
    //   file_type: "document",
    // },
    // need a clasrification
    {
        id: 16,
        name: <TranslateAttributeStrings translate={'attributes:file_image'} />,
        regex: '',
        regex_exception_message: 'File-image accepts image of format jpg,png,jpeg',
        predefined: true,
        data_type_group: 'Upload',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/File-image.png',
        display_name: <TranslateAttributeStrings translate={'attributes:file_image'} />,
        description: <TranslateAttributeStrings translate={'attributes:file_image_control_description'} />,
        attribute_unit: [
            {
                id: 24,
                key: 'ext',
            },
        ],
        extension: ['.png', '.jpg', '.jpeg'],
        file_type: 'image',
    },
    // need a clasrification
    // ToDO:
    // {
    //   id: 17,
    //   name: <TranslateAttributeStrings translate={"attributes:file_video"} />,
    //   regex: "^([^\\s]+(\\.?(PNG|png|JPG))$)",
    //   regex_exception_message: "File video accets video of format PNG,png,JPG",
    //   predefined: true,
    //   data_type_group: "Upload",
    //   regex_validation: true,
    //   minimum_number_values: 0,
    //   maximum_number_values: 0,
    //   attribute_type: ["Simple", "Journey"],
    //   image: "/image/File-video.png",
    //   display_name: <TranslateAttributeStrings translate={"attributes:file_video"} />,
    //   description: <TranslateAttributeStrings translate={"attributes:file_video_control_description"} />,
    //   attribute_unit: [
    //     {
    //       id: 14,
    //       key: "ext",
    //     }
    //   ],
    //   extension: null,
    //   file_type: "video",
    // },
    // need a clasrification
    {
        id: 18,
        name: <TranslateAttributeStrings translate={'attributes:file_docs'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'File-docs accepts file of PNG,png,JPG formats',
        predefined: true,
        data_type_group: 'Upload',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/File-docs.png',
        display_name: <TranslateAttributeStrings translate={'attributes:file_docs'} />,
        description: <TranslateAttributeStrings translate={'attributes:file_doc_control_description'} />,
        attribute_unit: [
            {
                id: 34,
                key: 'ext',
            },
        ],
        extension: ['.doc', '.docx', '.json', '.yaml', '.yml'],
        file_type: 'document',
    },
    // need a clasrification
    {
        id: 19,
        name: <TranslateAttributeStrings translate={'attributes:file_pdf'} />,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'File-pdf accepts file of PNG,png,JPG formats',
        predefined: true,
        data_type_group: 'Upload',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/File-pdf.png',
        display_name: <TranslateAttributeStrings translate={'attributes:file_pdf'} />,
        description: <TranslateAttributeStrings translate={'attributes:file_pdf_control_description'} />,
        attribute_unit: [
            {
                id: 34,
                key: 'ext',
            },
        ],
        extension: ['.pdf'],
        file_type: 'document',
    },
    // Overlapping with other tabs, need a discussion
    {
        id: 20,
        name: <TranslateAttributeStrings translate={'attributes:float_positive'} />,
        regex: '^[+-]?([0-9]*[.])?[0-9]+$',
        regex_exception_message: 'Float positive regex accepts only float numbers with atmost 2 digits after decimal',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Float-positive.png',
        display_name: <TranslateAttributeStrings translate={'attributes:float_positive'} />,
        description: <TranslateAttributeStrings translate={'attributes:float_positive_control_desc'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // Overlapping with other tabs, need a discussion
    {
        id: 21,
        name: <TranslateAttributeStrings translate={'attributes:number_percentage'} />,
        regex: '^((100)|(\\d{1,2}(\\.\\d*)?))$',
        regex_exception_message: 'Number regex accepts number with percentage upto 100',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Number-percentage.png',
        display_name: <TranslateAttributeStrings translate={'attributes:number_percentage'} />,
        description: <TranslateAttributeStrings translate={'attributes:num_percent_control_desc'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // Overlapping with other tabs, need a discussion
    {
        id: 22,
        name: <TranslateAttributeStrings translate={'attributes:number_positive'} />,
        regex: '^([+]?[0-9]+[0-9]*|[0-9])$',
        regex_exception_message: 'Number regex accepts only positive numbers',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Number-positive.png',
        display_name: <TranslateAttributeStrings translate={'attributes:number_positive'} />,
        description: <TranslateAttributeStrings translate={'attributes:num_positive_control_desc'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // need a clasrification
    // Overlapping with other tabs, need a discussion
    {
        id: 23,
        name: <TranslateAttributeStrings translate={'attributes:integer_limit'} />,
        regex: '^([0-9]+)$',
        regex_exception_message: 'Number regex accepts number between start and end range',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Number-limit.png',
        display_name: <TranslateAttributeStrings translate={'attributes:integer_limit'} />,
        description: <TranslateAttributeStrings translate={'attributes:int_limit_control_desc'} />,
        attribute_unit: [
            {
                id: 1,
                key: 'min',
            },
            {
                id: 2,
                key: 'max',
            },
        ],
        extension: null,
        file_type: null,
    },
    // need a clasrification
    // Overlapping with other tabs, need a discussion
    {
        id: 24,
        name: <TranslateAttributeStrings translate={'attributes:float_limit'} />,
        regex: '^[-+]?[0-9]*\\.?[0-9]*$',
        regex_exception_message: 'Float regex accepts float values between start and end range',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/float-limit.png',
        display_name: <TranslateAttributeStrings translate={'attributes:float_limit'} />,
        description: <TranslateAttributeStrings translate={'attributes:float_limit_control_description'} />,
        attribute_unit: [
            {
                id: 1,
                key: 'min',
            },
            {
                id: 2,
                key: 'max',
            },
        ],
        extension: null,
        file_type: null,
    },
    // Overlapping with other tabs, need a discussion
    {
        id: 25,
        name: <TranslateAttributeStrings translate={'attributes:float_round_off'} />,
        regex: '^(\\d+(\\.\\d{0,2})?|\\.?\\d{1,2})$',
        regex_exception_message: 'Float regex accepts float values in the range of 2 digits after decimal',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/float-roundoff.png',
        display_name: <TranslateAttributeStrings translate={'attributes:float_round_off'} />,
        description: <TranslateAttributeStrings translate={'attributes:float_round_off_cont_desc'} />,
        attribute_unit: [
            {
                id: 43,
                key: 'decimal',
            },
        ],
        extension: null,
        file_type: null,
    },
    // Overlapping with other tabs, need a discussion
    {
        id: 26,
        name: <TranslateAttributeStrings translate={'attributes:float_round_off_limit'} />,
        regex: '^(\\d+(\\.\\d{0,3})?|\\.?\\d{1,3})$',
        regex_exception_message: 'Float regex accepts float values with the limit 3 digits after decimal',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/float-roundoff-limit.png',
        display_name: <TranslateAttributeStrings translate={'attributes:float_round_off_limit'} />,
        description: <TranslateAttributeStrings translate={'attributes:float_round_off_limit_cont_desc'} />,
        attribute_unit: [
            {
                id: 48,
                key: 'min',
            },
            {
                id: 47,
                key: 'max',
            },
            {
                id: 45,
                key: 'decimal',
            },
        ],
        extension: null,
        file_type: null,
    },
    {
        id: 27,
        name: <TranslateAttributeStrings translate={'attributes:text_name'} />,
        regex: '^([aA-zZ\\s]{1,250})$',
        regex_exception_message: 'Text regex accepts only text fields upto 250 charachters',
        predefined: true,
        data_type_group: 'Single',
        regex_validation: true,
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Text-Name.png',
        display_name: <TranslateAttributeStrings translate={'attributes:text_name'} />,
        description: <TranslateAttributeStrings translate={'attributes:text_name_control_description'} />,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
    // need a clasrification
    // ToDO:
    // {
    //   id: 28,
    //   name: <TranslateAttributeStrings translate={"attributes:list_product_images"} />,
    //   regex: "[\\s\\S]*$",
    //   regex_exception_message: "This regex accepts everything",
    //   predefined: true,
    //   data_type_group: "Optional",
    //   regex_validation: false,
    //   minimum_number_values: 0,
    //   maximum_number_values: 0,
    //   attribute_type: ["Simple", "Journey"],
    //   image: "/image/List-ProductImages.png",
    //   display_name: <TranslateAttributeStrings translate={"attributes:list_product_images"} />,
    //   description: <TranslateAttributeStrings translate={"attributes:list_product_image_cont_desc"} />,
    //   attribute_unit: [],
    //   extension: null,
    //   file_type: null,
    // },
    // {
    //   id: 29,
    //   name: <TranslateAttributeStrings translate={"attributes:file_audio"} />,
    //   predefined: true,
    //   regex: "^([^s]+(.(mp3))$)",
    //   regex_exception_message: "This regex accepts  File-audio of format mp3",
    //   data_type_group: "Upload",
    //   minimum_number_values: 0,
    //   maximum_number_values: 0,
    //   attribute_type: ["Simple", "Journey"],
    //   display_name: <TranslateAttributeStrings translate={"attributes:file_audio"} />,
    //   image: "/image/File-audio.png",
    //   description: <TranslateAttributeStrings translate={"attributes:sing_sel_opt_from_list_of_choices"} />,
    //   regex_validation: true,
    //   attribute_unit: [{
    //     id: 34,
    //     key: "ext",
    //   }],
    //   extension: null,
    //   file_type: "audio",
    // },
    // ToDO:
    {
        id: 30,
        name: <TranslateAttributeStrings translate={'attributes:text'} />,
        predefined: true,
        regex: '[\\s\\S]*$',
        regex_exception_message: 'Text regex accepts only text fields upto 250 charachters',
        data_type_group: 'Single',
        minimum_number_values: 0,
        maximum_number_values: 0,
        attribute_type: ['Simple', 'Journey'],
        image: '/image/Text.png',
        display_name: <TranslateAttributeStrings translate={'attributes:text'} />,
        description: <TranslateAttributeStrings translate={'attributes:datatype_to_input_text_field'} />,
        regex_validation: true,
        attribute_unit: [],
        extension: null,
        file_type: null,
    },
]

export default attribDataTypeResponse
