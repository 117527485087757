import { SettingOutlined } from '@ant-design/icons'
import { Button, Collapse, Layout, Switch, Typography, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'
import AttributeGroups from '../AttributeGroups/AttributeGroups'
import GetAttributeGroup from '../AttributeGroups/GetAttributeGroup'
const { Content } = Layout
const { Title, Text } = Typography
const { Panel } = Collapse

const productTemplateMappingAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_MAPPING_API
const productHierarchyAPI = process.env.REACT_APP_PRODUCT_HIERARCHY_API

function ProductTemplateRelatedAttribute() {
    const { t } = useTranslation()
    const productTemplateSelectedInfo = useSelector((state) => state.reducerProducts.productTemplateSelectedInfo)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [seletedTemplateData, setSelectedTemplateData] = useState()
    const [responseFromGroupAttribute, setResponseFromGroupAttribute] = useState('')
    const [ModalTitle, setModalTitle] = useState()
    const [deactivateProductTemplateShowModal, setDeactivateProductTemplateShowModal] = useState(false)
    const [productHierarchy, setProductHierarchy] = useState([])
    const [serverData, setServerData] = useState([])
    const [isLoadingProductHierarchy, setIsLoadingProductHierarchy] = useState(true)
    const [productTemplateName, setProductTemplateName] = useState('')
    const [productTemplateMappingData, setProductTemplateMappingData] = useState([])
    const [isMappingDataBoolean, setIsMappingDataBoolean] = useState(false)
    const [selectedParentTemplateId, setSelectedParentTemplateId] = useState()
    let parentTemplateMappingData = []

    //! get the count of procureTemplate revision in this get call
    const findAllProductTemplateMapping = (revisionId) => {
        let temp = {
            'product-template-revision-id': parseInt(revisionId),
        }
        MarketplaceServices.findAllWithoutPage(productTemplateMappingAPI, temp, false)
            .then(function (response) {
                console.log(
                    'server response from findAllProductTemplateMapping API is',
                    productTemplateMappingAPI,
                    response.data.product_mapping_data
                )
                if (response.data.response_body.product_mapping_data.length > 0) {
                    setProductTemplateMappingData(response.data.response_body.product_mapping_data[0].revision_id)
                }
                setIsMappingDataBoolean(true)
            })
            .catch(function (error) {
                console.log(
                    'Error server response from findAllProductTemplateMapping API is',
                    productTemplateMappingAPI,
                    error
                )
                setProductTemplateMappingData([])
                setIsMappingDataBoolean(true)
            })
    }

    const dataProcessorProductHierarchy = (data) => {
        let localData = []
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                const temp = {}
                temp['product_template_id'] = data[i].product_template_id
                temp['latest_revision_id'] = data[i].latest_revision_id
                temp['name'] = data[i].display_name
                temp['description'] = data[i].display_description
                temp['image_path'] = data[i].display_image
                temp['revision_data'] = data[i].revision_data
                temp['toggle_status'] = false
                temp['isParent'] = false
                localData.push(temp)
            }
            return localData
        } else {
            return localData
        }
    }

    const findAllWithoutPageProductHierachy = (productTemplateRevisionId) => {
        let temp = {
            'product-template-revision-id': parseInt(productTemplateRevisionId),
        }
        MarketplaceServices.findAllWithoutPage(productHierarchyAPI, temp, false)
            .then(function (response) {
                console.log(
                    'Server Response from findAllWithoutPageProductHierachy is---->',
                    productHierarchyAPI,
                    response.data
                )
                productTemplateherirachy(response.data.response_body.parent_product_template)
                let temp = dataProcessorProductHierarchy(response.data.response_body.procure_product_template_data)

                let data = temp.concat(parentTemplateMappingData)

                const uniqueData = Array.from(new Map(data?.map((item) => [item.product_template_id, item])).values())

                setServerData(uniqueData)

                setIsLoadingProductHierarchy(false)
            })
            .catch(function (error) {
                console.log(
                    'Error Server response from findAllWithoutPageProductHierachy is',
                    productHierarchyAPI,
                    error
                )
                setIsLoadingProductHierarchy(false)
            })
    }

    //! delete of productTemplateMapping
    const removeMapping = (templateId) => {
        let temp = {
            template_mapping_id: parseInt(templateId),
        }
        MarketplaceServices.remove(productTemplateMappingAPI, temp, false)
            .then(function (response) {
                console.log('Server response from removeMapping is', response.data)
                let localData = deepCopy(productHierarchy)
                let index =
                    localData &&
                    localData.length > 0 &&
                    localData.findIndex((ele) => ele.product_template_id === parseInt(selectedParentTemplateId))
                if (index !== false) {
                    localData[index]['toggle_status'] = false
                    setProductHierarchy(localData)
                }
                setDeactivateProductTemplateShowModal(!deactivateProductTemplateShowModal)
                MarketplaceToaster.showToast(response)
            })
            .catch(function (error) {
                console.log('Error Server response from removeMapping is', error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //! handling switch
    const attributeSwitchChange = (e, latestRevisionId, productTemplateId, name, revisionData, parentTemplateId) => {
        const checkingWhichModalToOpenBasedOnStatus =
            productHierarchy &&
            productHierarchy.filter((element) => element.product_template_id === parseInt(parentTemplateId))
        if (checkingWhichModalToOpenBasedOnStatus) {
            if (checkingWhichModalToOpenBasedOnStatus[0].toggle_status === true) {
                setDeactivateProductTemplateShowModal(!deactivateProductTemplateShowModal)
            } else {
                setIsModalOpen(true)
            }
        }
        setSelectedParentTemplateId(parentTemplateId)
        setModalTitle(name)
        setSelectedTemplateData({
            revisionId: latestRevisionId,
            productTemplateId: productTemplateId,
            productTemplateName: name + ' Attributes',
            revisionData: revisionData,
            selectedRevision: revisionData[0].revision_id,
            action: 'Add',
            type: 'ProductRevision',
        })
    }
    //!closing the modal of deactivate
    const closeDeactivateProductTemplateModal = () => {
        setDeactivateProductTemplateShowModal(!deactivateProductTemplateShowModal)
    }
    //! closing the modal of deactivate and update the procure template
    const updateDeactivateProductTemplateModal = () => {
        let TemplateId =
            productTemplateMappingData &&
            productTemplateMappingData.length > 0 &&
            productTemplateMappingData.filter(
                (ele) => ele.parent_product_template === parseInt(selectedParentTemplateId)
            )
        if (TemplateId && TemplateId.length > 0) {
            removeMapping(TemplateId[0].template_mapping_id)
        }
        setDeactivateProductTemplateShowModal(!deactivateProductTemplateShowModal)
    }

    const recursiveFunctionForProductHeirarchy = (childrenData) => {
        if (childrenData && childrenData.length > 0) {
            for (var i = 0; i < childrenData.length; i++) {
                let temp = {}
                temp['product_template_id'] = childrenData[i].product_template_id
                temp['latest_revision_id'] = childrenData[i].latest_revision_id
                temp['name'] = childrenData[i].display_name
                temp['description'] = childrenData[i].display_description
                temp['image_path'] = childrenData[i].display_image
                temp['revision_data'] = childrenData[i].revision_data
                temp['toggle_status'] = childrenData[i].false
                parentTemplateMappingData.push(temp)
                recursiveFunctionForProductHeirarchy(childrenData[i].parent_product_template)
            }
        }
    }

    const productTemplateherirachy = (data) => {
        for (var i = 0; i < data.length; i++) {
            let temp = {}
            temp['product_template_id'] = data[i].product_template_id
            temp['latest_revision_id'] = data[i].latest_revision_id
            temp['name'] = data[i].display_name
            temp['description'] = data[i].display_description
            temp['image_path'] = data[i].display_image
            temp['revision_data'] = data[i].revision_data
            temp['toggle_status'] = data[i].false
            temp['isParent'] = true
            parentTemplateMappingData.push(temp)
            recursiveFunctionForProductHeirarchy(data[i].parent_product_template)
        }
    }

    useEffect(() => {
        if (
            responseFromGroupAttribute !== null &&
            responseFromGroupAttribute.length > 0 &&
            responseFromGroupAttribute !== 'error'
        ) {
            let localData = deepCopy(productHierarchy)
            let index =
                localData &&
                localData.length > 0 &&
                localData.findIndex((ele) => ele.product_template_id === selectedParentTemplateId)
            if (index !== false) {
                localData[index]['toggle_status'] = true
                setProductHierarchy(localData)
            }
            let localProductTemplateMappingData = deepCopy(productTemplateMappingData)
            let indexOfProductTemplateMappingData = localProductTemplateMappingData.findIndex(
                (ele) => ele.parent_product_template === responseFromGroupAttribute[0].parent_product_template
            )
            if (indexOfProductTemplateMappingData !== -1) {
                localProductTemplateMappingData[indexOfProductTemplateMappingData] = responseFromGroupAttribute[0]
                setProductTemplateMappingData(localProductTemplateMappingData)
            } else {
                localProductTemplateMappingData.push(responseFromGroupAttribute[0])
                setProductTemplateMappingData(localProductTemplateMappingData)
            }
            setIsModalOpen(false)
            setResponseFromGroupAttribute(null)
        } else if (responseFromGroupAttribute === '') {
            setIsModalOpen(false)
        } else if (responseFromGroupAttribute === null && responseFromGroupAttribute !== '') {
            setIsModalOpen(false)
            setResponseFromGroupAttribute('')
        }
    }, [responseFromGroupAttribute])

    useEffect(() => {
        if (productTemplateSelectedInfo && productTemplateSelectedInfo.length > 0) {
            findAllWithoutPageProductHierachy(
                productTemplateSelectedInfo &&
                    productTemplateSelectedInfo.length > 0 &&
                    productTemplateSelectedInfo[0].id
            )
            findAllProductTemplateMapping(
                productTemplateSelectedInfo &&
                    productTemplateSelectedInfo.length > 0 &&
                    productTemplateSelectedInfo[0].id
            )
            setProductTemplateName(
                productTemplateSelectedInfo &&
                    productTemplateSelectedInfo.length > 0 &&
                    productTemplateSelectedInfo[1].name
            )
        }
    }, [productTemplateSelectedInfo])

    useEffect(() => {
        if (serverData && serverData.length > 0) {
            console.log('serverData------>', serverData)
            let localData = deepCopy(serverData)

            for (var i = 0; i < serverData.length; i++) {
                let productId = serverData[i].product_template_id
                let filterMappingData =
                    productTemplateMappingData &&
                    productTemplateMappingData.length > 0 &&
                    productTemplateMappingData.filter((ele) => ele.parent_product_template === productId)

                if (filterMappingData && filterMappingData.length > 0) {
                    let index =
                        localData &&
                        localData.length > 0 &&
                        localData.findIndex((ele) => ele.product_template_id === productId)
                    localData[index]['toggle_status'] = true
                } else {
                    let index =
                        localData &&
                        localData.length > 0 &&
                        localData.findIndex((ele) => ele.product_template_id === productId)
                    localData[index]['toggle_status'] = false
                }
            }
            setProductHierarchy(localData)
        } else {
            setProductHierarchy([])
        }
    }, [serverData, isMappingDataBoolean])

    console.log('serverData----------->', serverData)

    const getParticularRevisionMappingRevisionId = (productId) => {
        if (productTemplateMappingData.length > 0) {
            const particularRevision =
                productTemplateMappingData &&
                productTemplateMappingData.filter((ele) => ele.parent_product_template === productId)
            if (particularRevision && particularRevision.length > 0) {
                return particularRevision[0].mapping_revision_id
            } else {
                return ''
            }
        }
    }

    const onClickSettingsIcon = (latestRevisionId, productTemplateId, name, revisionData, parentTemplateId) => {
        if (productTemplateMappingData.length > 0) {
            const mappingData = productTemplateMappingData.filter(
                (ele) => ele.parent_product_template === parentTemplateId
            )
            if (mappingData.length > 0) {
                setSelectedTemplateData({
                    revisionId: latestRevisionId,
                    productTemplateId: productTemplateId,
                    productTemplateName: name + ' Attributes',
                    revisionData: revisionData,
                    selectedRevision: mappingData[0].mapping_revision_id,
                    mappingId: mappingData[0].template_mapping_id,
                    action: 'Edit',
                    type: 'ProductRevision',
                })
                setIsModalOpen(true)
            }
        }
        setSelectedParentTemplateId(parentTemplateId)
        setModalTitle(name)
    }

    const genExtra = (toggleStatus, latestRevisionId, producTemplateId, name, revisionData) => {
        return (
            <Content className='flex gap-2'>
                <div className='flex gap-2'>
                    <p className='text-[#8899A8] !text-base !font-medium'>Status</p>
                    <p className='text-[#8899A8]'>:</p>
                </div>

                <Switch
                    size='small'
                    className={` !mt-[0.3rem] ${toggleStatus ? '!bg-brandPrimaryColor hover:!bg-brandPrimaryColor' : 'bg-[#bfbfbf]'}`}
                    checked={toggleStatus ? true : false}
                    onChange={(e) =>
                        attributeSwitchChange(
                            e,
                            parseInt(latestRevisionId),
                            producTemplateId,
                            name,
                            revisionData,
                            producTemplateId
                        )
                    }
                />
                <p className='pl-2 !pt-1 !font-normal text-[#637381] !text-xs'>
                    {toggleStatus ? `${t('dashboard:active')}` : `${t('dashboard:inactive')}`}
                </p>
            </Content>
        )
    }
    const title = ModalTitle + ' Attributes'
    const showTooltip = title.length > 40

    return isLoadingProductHierarchy ? (
        <Content className='bg-white my-3 p-3'>
            <SkeletonComponent />
        </Content>
    ) : (
        <Content className='my-3'>
            {productHierarchy &&
                productHierarchy.length > 0 &&
                productHierarchy.map(
                    ({ name, toggle_status, latest_revision_id, product_template_id, revision_data, isParent }) => {
                        return (
                            <Collapse accordion bordered={true} className='!bg-white !mt-6  !pt-4'>
                                <Panel
                                    header={
                                        <div className='flex justify-between !pb-3'>
                                            <div className='flex gap-2 !w-[80%] pr-2'>
                                                <Tooltip title={name?.length > 40 ? name : undefined}>
                                                    <p className='text-regal-blue !text-lg !font-semibold truncate max-w-[350px]'>
                                                        {name}
                                                    </p>
                                                </Tooltip>

                                                {toggle_status == true ? (
                                                    <p
                                                        className='text-brandPrimaryColor text-sm font-medium pt-1 px-2 cursor-pointer'
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            onClickSettingsIcon(
                                                                parseInt(latest_revision_id),
                                                                product_template_id,
                                                                name,
                                                                revision_data,
                                                                product_template_id
                                                            )
                                                        }}>
                                                        V
                                                        {productTemplateMappingData &&
                                                            productTemplateMappingData.length > 0 &&
                                                            productTemplateMappingData
                                                                .filter(
                                                                    (ele) =>
                                                                        ele.parent_product_template ===
                                                                        product_template_id
                                                                )[0]
                                                                .mapping_revision_id__revision_number?.toFixed(1)}
                                                    </p>
                                                ) : null}
                                                <p
                                                    className={`text-white text-xs font-normal !mt-1 pt-[2px] h-[20px] px-2 rounded-3xl ${isParent ? 'bg-[#13C2C2]' : 'bg-[#EB2F96]'}`}>
                                                    {isParent
                                                        ? t('common:parent_template')
                                                        : t('common:copied_template')}
                                                </p>
                                            </div>
                                            <div className='!w-[20%] !pl-6'>
                                                {genExtra(
                                                    toggle_status,
                                                    latest_revision_id,
                                                    product_template_id,
                                                    name,
                                                    revision_data
                                                )}
                                            </div>
                                        </div>
                                    }
                                    key={product_template_id}>
                                    <Content className='px-3'>
                                        {toggle_status === true ? (
                                            <Content className='!m-0'>
                                                <GetAttributeGroup
                                                    publishingProductsPropsData={{
                                                        revisionId: parseInt(
                                                            getParticularRevisionMappingRevisionId(product_template_id)
                                                        ),
                                                        revisionType: 'ProductRevision',
                                                        isParentHierarchy: false,
                                                        isCoreAttribute: false,
                                                        isAttributeGroupNameVisible: true,
                                                        isAttributeData: null,
                                                        isStaticData: null,
                                                        isDispatch: false,
                                                        isAttributeDisabled: true,
                                                        isEditIconVisible: false,
                                                    }}
                                                    viewType={'page'}
                                                />
                                            </Content>
                                        ) : (
                                            <p className='break-words whitespace-normal'>
                                                {t('attributes:none_of_the_attr_mapped', {
                                                    name,
                                                    productTemplateName,
                                                })}
                                            </p>
                                        )}
                                    </Content>
                                </Panel>
                            </Collapse>
                        )
                    }
                )}

            <StoreModal
                isVisible={isModalOpen}
                okButtonText={null}
                title={
                    showTooltip ? (
                        <Tooltip title={title}>
                            <span className='truncate max-w-[80%] inline-block'>{title}</span>
                        </Tooltip>
                    ) : (
                        <span className='truncate max-w-[80%] inline-block'>{title}</span>
                    )
                }
                cancelButtonText={null}
                width={600}
                isSpin={false}
                cancelCallback={() => setIsModalOpen(!isModalOpen)}>
                <Content>
                    <AttributeGroups
                        seletedTemplateData={seletedTemplateData}
                        setResponseFromGroupAttribute={setResponseFromGroupAttribute}
                        type={'ProductRevision'}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={deactivateProductTemplateShowModal}
                okButtonText={t('common:save')}
                title={t('common:warning')}
                cancelButtonText={t('common:discard')}
                width={600}
                isSpin={false}
                hideCloseButton
                okCallback={() => updateDeactivateProductTemplateModal()}
                cancelCallback={() => closeDeactivateProductTemplateModal()}>
                <Content>
                    {`${t('attributes:want_to_remove')} ${ModalTitle} ${t(
                        'attributes:attribute_from'
                    )} ${productTemplateName}?`}
                </Content>
            </StoreModal>
        </Content>
    )
}

export default ProductTemplateRelatedAttribute
