const ProductsTabData = [
    {
        tabId: 3,
        tabTitle: 'Published',
        color: 'green',
        status: true,
    },
    {
        tabId: 2,
        tabTitle: 'Awaiting Approval',
        color: 'yellow',
        status: true,
    },
    {
        tabId: 1,
        tabTitle: 'Draft',
        color: 'blue',
        status: true,
    },
    {
        tabId: 4,
        tabTitle: 'Rejected',
        color: 'red',
        status: true,
    },
    // {
    //   tabId: 5,
    //   tabTitle: "Deprecated",
    //   status: true
    // },
    {
        tabId: 5,
        tabTitle: 'Unpublished',
        color: 'orange',
        status: true,
    },
    {
        tabId: 6,
        tabTitle: 'Retired',
        color: 'grey',
        status: true,
    },
]
export default ProductsTabData.filter((element) => element.status)
