import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Image, Layout, Modal, Tooltip, Typography, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TiDelete } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnAbsoluteStoreImageInfo } from '../../services/redux/actions/ActionStoreImages'
import MarketplaceToaster from '../../util/marketplaceToaster'
import './StoreImages.css'
const { Title } = Typography
const { Content } = Layout

const storeDeleteImagesAPI = process.env.REACT_APP_STORE_DELETE_IMAGES_API
const baseURL = process.env.REACT_APP_BASE_URL
const BannerImagesUploadLength = process.env.REACT_APP_BANNER_IMAGES_MAX_LENGTH
const supportedFileExtensions = process.env.REACT_APP_IMAGES_EXTENSIONS
const maxFileSizeDefined = process.env.REACT_APP_MAX_FILE_SIZE_UPLOAD
const minFileSizeDefined = process.env.REACT_APP_MIN_FILE_SIZE_UPLOAD

const StoreImages = ({
    title,
    type,
    isSingleUpload,
    imagesUpload,
    setImagesUpload,
    getImageData,
    validStoreLogo,
    InfoCircleText,
    bannerAbsoluteImage,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const absoluteStoreImageInfo = useSelector((state) => state.reducerAbsoluteStoreImageInfo.absoluteStoreImageInfo)

    const [fileList, setFileList] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [imagePathShow, setImagePathShow] = useState()
    const [copyImagePath, setCopyImagePath] = useState()
    const [allImageUrl, setAllImageUrl] = useState([])
    const [reset, setReset] = useState(false)
    const [imageIndex, setImageIndex] = useState()
    const [imageElement, setImageElement] = useState()
    const [isImageDeleting, setIsImageDeleting] = useState(false)
    const [isDeleteImageModalOpen, setIsDeleteImageModalOpen] = useState(false)
    const [bannerImagesLength, setBannerImagesLength] = useState(0)

    var selectedImageArrayOfObject = []
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type='button'>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}>
                {t('store_settings:upload')}
            </div>
        </button>
    )

    const handleChange = (e) => {
        setFileList(e.fileList)
        if (type === 'store_logo') {
            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'store_logo')
                setImagesUpload(temp)
            } else {
                let copyImageData = [...imagesUpload]
                copyImageData.push({ type: 'store_logo', imageValue: e.file })
                setImagesUpload(copyImageData)
            }
        }
        if (type === 'banner_images') {
            setBannerImagesLength(
                parseInt(allImageUrl && allImageUrl.length) + parseInt(e && e.fileList && e.fileList.length)
            )
            selectedImageArrayOfObject.push(e.file)
            var sampleBannerImagesLength =
                parseInt(allImageUrl && allImageUrl.length) + parseInt(e && e.fileList && e.fileList.length)

            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'banner_images')
                setImagesUpload(temp)
            } else {
                let totalSelectLength = e.fileList.length
                if (sampleBannerImagesLength > BannerImagesUploadLength) {
                    let imagesUploadLength = sampleBannerImagesLength - BannerImagesUploadLength
                    let imagesSelect = sampleBannerImagesLength - imagesUploadLength
                    totalSelectLength = imagesSelect - allImageUrl.length
                    e.fileList.splice(totalSelectLength) // Limit the fileList to eight files
                }
                let copyImageData = [...imagesUpload]
                selectedImageArrayOfObject.splice(totalSelectLength)
                let index = copyImageData.findIndex((item) => item.type === 'banner_images')
                console.log('index', index)
                if (index === -1) {
                    if (e.fileList.length === 0) {
                        copyImageData.push({
                            type: 'banner_images',
                            imageValue: [e.file],
                        })
                    } else {
                        copyImageData.push({
                            type: 'banner_images',
                            imageValue: selectedImageArrayOfObject,
                        })
                    }
                } else {
                    let bannerImagesData = copyImageData[index]
                    let duplicateValues = [...bannerImagesData.imageValue]
                    if (e.file.status === 'removed') {
                        let filteredDuplicateValues = duplicateValues.filter((ele) => ele.uid !== e.file.uid)
                        bannerImagesData['imageValue'] = filteredDuplicateValues
                    } else {
                        duplicateValues.push(e.file)
                        bannerImagesData['imageValue'] = duplicateValues
                    }
                    copyImageData[index] = bannerImagesData
                    console.log('bannerImagesData', bannerImagesData)
                }
                console.log('copyImageData', copyImageData)
                setImagesUpload(copyImageData)
            }
        }
        if (type === 'search_logo') {
            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'search_logo')
                setImagesUpload(temp)
            } else {
                let copyImageData = [...imagesUpload]
                copyImageData.push({ type: 'search_logo', imageValue: e.file })
                setImagesUpload(copyImageData)
            }
        }
        if (type === 'customer_logo') {
            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'customer_logo')
                setImagesUpload(temp)
            } else {
                let copyImageData = [...imagesUpload]
                copyImageData.push({ type: 'customer_logo', imageValue: e.file })
                setImagesUpload(copyImageData)
            }
        }
        if (type === 'cart_logo') {
            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'cart_logo')
                setImagesUpload(temp)
            } else {
                let copyImageData = [...imagesUpload]
                copyImageData.push({ type: 'cart_logo', imageValue: e.file })
                setImagesUpload(copyImageData)
            }
        }
        if (type === 'wishlist_logo') {
            if (e.fileList.length === 0) {
                let temp = imagesUpload.filter((e) => e.type !== 'wishlist_logo')
                setImagesUpload(temp)
            } else {
                let copyImageData = [...imagesUpload]
                copyImageData.push({ type: 'wishlist_logo', imageValue: e.file })
                setImagesUpload(copyImageData)
            }
        }
    }

    useEffect(() => {
        if (getImageData && getImageData !== undefined) {
            if (type === 'store_logo') {
                let temp = getImageData && getImageData.store_logo_path
                if (temp !== null && temp !== undefined) {
                    findAllWithoutPageStoreAbsoluteImagesApi(temp)
                } else {
                    setImagePathShow()
                }
            }
            if (type === 'customer_logo') {
                let temp = getImageData && getImageData.customer_logo_path
                if (temp !== null) {
                    findAllWithoutPageStoreAbsoluteImagesApi(temp)
                } else {
                    setImagePathShow()
                }
            }
            if (type === 'cart_logo') {
                let temp = getImageData && getImageData.cart_logo_path
                if (temp !== null) {
                    findAllWithoutPageStoreAbsoluteImagesApi(temp)
                } else {
                    setImagePathShow()
                }
            }
            if (type === 'search_logo') {
                let temp = getImageData && getImageData.search_logo_path
                if (temp !== null) {
                    findAllWithoutPageStoreAbsoluteImagesApi(temp)
                } else {
                    setImagePathShow()
                }
            }
            if (type === 'wishlist_logo') {
                let temp = getImageData && getImageData.wishlist_logo_path
                if (temp !== null) {
                    findAllWithoutPageStoreAbsoluteImagesApi(temp)
                } else {
                    setImagePathShow()
                }
            }
        }
        selectedImageArrayOfObject = []
    }, [getImageData])

    // useEffect(() => {
    //     setImagePathShow()
    // }, [])

    useEffect(() => {
        if (bannerAbsoluteImage && bannerAbsoluteImage.length > 0) {
            let temp = []
            for (var i = 0; i < bannerAbsoluteImage.length; i++) {
                if (type === 'banner_images') {
                    temp.push(baseURL + bannerAbsoluteImage[i].image_fullpath)
                }
            }
            setAllImageUrl(temp)
            setImagePathShow(temp)
        }
    }, [bannerAbsoluteImage])

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handleCancel = () => setPreviewOpen(false)

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    }

    const findAllWithoutPageStoreAbsoluteImagesApi = (imagePath) => {
        let url = baseURL + imagePath
        let temp = []
        temp.push(url)
        if (absoluteStoreImageInfo && absoluteStoreImageInfo.length > 0) {
            let imageData = [...absoluteStoreImageInfo]
            let imageType = { type: type, value: url }
            imageData.push(imageType)
            dispatch(fnAbsoluteStoreImageInfo(ImageData))
        } else {
            let imageType = { type: type, value: url }
            dispatch(fnAbsoluteStoreImageInfo(imageType))
        }
        setAllImageUrl(temp)
        setImagePathShow(url)
        setCopyImagePath(url)
    }

    // closing the delete popup model
    const closeDeleteModal = () => {
        setIsDeleteImageModalOpen(false)
    }
    //!delete function of language
    const removeMedia = (index) => {
        // debugger;
        setIsImageDeleting(true)
        console.log('index', index)
        let dataObject = {}
        dataObject['image-type'] = type
        if (type === 'banner_images') {
            let temp = bannerAbsoluteImage[imageIndex]
            dataObject['image-path'] = temp.path
        } else {
            dataObject['image-path'] = getImageData[type]
        }
        MarketplaceServices.remove(storeDeleteImagesAPI, dataObject)
            .then((response) => {
                console.log('response from delete===>', response)
                MarketplaceToaster.showToast(response)
                if (type === 'banner_images') {
                    //remove from setBannerAbsoluteImage
                    bannerAbsoluteImage.splice(imageIndex, 1)

                    setBannerImagesLength(bannerImagesLength - 1)

                    let temp = allImageUrl.filter((item) => item !== imageElement)
                    if (temp && temp.length > 0) {
                        setAllImageUrl(temp)
                    } else {
                        setAllImageUrl([])
                        setImagePathShow()
                    }
                } else {
                    setImagePathShow()
                    setReset(true)
                }
                setIsDeleteImageModalOpen(false)
                // disabling spinner
                setIsImageDeleting(false)
            })
            .catch((error) => {
                // disabling spinner
                setIsImageDeleting(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    useEffect(() => {
        if (imagesUpload && imagesUpload.length === 0) {
            setFileList([])
        }
    }, [imagesUpload])

    useEffect(() => {
        setBannerImagesLength(bannerAbsoluteImage && bannerAbsoluteImage.length)
    }, [bannerAbsoluteImage])

    return (
        <Content className=' mb-2'>
            <Content className='flex !mb-3 gap-1'>
                <Title level={5} className='!text-[#637381]'>
                    {title}
                </Title>
                <Content className=' items-end  '>
                    <Tooltip
                        title={InfoCircleText}
                        overlayStyle={{ zIndex: 1, position: 'fixed' }}
                        placement='topLeft'></Tooltip>
                </Content>
            </Content>
            {imagePathShow === undefined ? (
                <Content>
                    {isSingleUpload && isSingleUpload === true ? (
                        <Content>
                            <Upload
                                className={`hover:border-[var(--mp-primary-border-color)] hover:text-[var(--mp-brand-color-h)]`}
                                listType='picture-card'
                                fileList={fileList}
                                name='file'
                                onPreview={handlePreview}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                beforeUpload={() => {
                                    return false
                                }}
                                afterUpload={() => {
                                    return false
                                }}
                                accept={supportedFileExtensions}>
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Alert
                                className='mt-4'
                                message={<h5 className='font-semibold'>{t('store_settings:image_requirements')}</h5>}
                                description={
                                    <ul className='list-disc ml-4'>
                                        <li className='!mb-0 '>{t('store_settings:store_logo_info')}</li>
                                        <li>{t('store_settings:store_logo_resolution')}</li>
                                        <li className='!mb-0 '>
                                            {t('store_settings:upload_image_content', {
                                                minFileSizeDefined,
                                                maxFileSizeDefined,
                                            })}
                                        </li>
                                    </ul>
                                }
                                type='info'
                                showIcon
                            />
                        </Content>
                    ) : (
                        <>
                            <Upload
                                maxCount={BannerImagesUploadLength}
                                className='hover:border-[var(--mp-primary-border-color)] hover:text-[var(--mp-brand-color-h)]'
                                listType='picture-card'
                                multiple={true}
                                beforeUpload={() => {
                                    return false
                                }}
                                afterUpload={() => {
                                    return false
                                }}
                                fileList={fileList}
                                onPreview={handlePreview}
                                accept={supportedFileExtensions}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                openFileDialogOnClick={bannerImagesLength < BannerImagesUploadLength ? true : false}>
                                {bannerImagesLength < BannerImagesUploadLength ? uploadButton : null}
                            </Upload>
                            <div className='mt-4 text-[#a8a8a8] '>
                                <Alert
                                    message={
                                        <h5 className='font-semibold'>{t('store_settings:image_requirements')}</h5>
                                    }
                                    description={
                                        <ul className='list-disc ml-6'>
                                            <li className='mb-0'>{t('store_settings:banner_logo_info')}</li>
                                            <li className='mb-0'>{t('store_settings:banner_logo_resolution')}</li>
                                            <li className='!mb-0 '>
                                                {t('store_settings:upload_image_content', {
                                                    minFileSizeDefined,
                                                    maxFileSizeDefined,
                                                })}
                                            </li>
                                            <li className='!mb-2'>
                                                {t('store_settings:please_ensure_eight_images', {
                                                    BannerImagesUploadLength,
                                                })}
                                            </li>
                                        </ul>
                                    }
                                    type='info'
                                    showIcon
                                />
                            </div>
                        </>
                    )}
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img
                            alt='previewImage'
                            style={{
                                width: '100%',
                            }}
                            src={previewImage}
                        />
                    </Modal>
                </Content>
            ) : (
                <>
                    <Content className='flex !space-x-2'>
                        {allImageUrl &&
                            allImageUrl.length > 0 &&
                            allImageUrl.map((ele, index) => {
                                return (
                                    <div className='!relative'>
                                        <Image src={ele} className='!w-[102px] !h-[102px] ' />
                                        <TiDelete
                                            className='!absolute !cursor-pointer !right-[-5px] !z-5  !top-[-10px] !text-2xl !text-red-600 !shadow-lg  hover:translate-'
                                            onClick={() => {
                                                setIsDeleteImageModalOpen(true)
                                                setImageIndex(index)
                                                setImageElement(ele)
                                            }}
                                        />
                                    </div>
                                )
                            })}
                        {type === 'banner_images' && (
                            <Upload
                                disabled={bannerImagesLength < BannerImagesUploadLength ? false : true}
                                maxCount={BannerImagesUploadLength}
                                multiple={true}
                                className='hover:border-[var(--mp-primary-border-color)] hover:text-[var(--mp-brand-color-h)] w-auto !-ml-10'
                                listType='picture-card'
                                onPreview={handlePreview}
                                beforeUpload={() => {
                                    return false
                                }}
                                afterUpload={() => {
                                    return false
                                }}
                                fileList={fileList}
                                accept={supportedFileExtensions}
                                onChange={(e) => {
                                    handleChange(e)
                                }}
                                openFileDialogOnClick={bannerImagesLength < BannerImagesUploadLength ? true : false}>
                                {bannerImagesLength < BannerImagesUploadLength ? uploadButton : null}
                            </Upload>
                        )}
                    </Content>
                    <Content className='!mt-4'>
                        {type === 'banner_images' ? (
                            <>
                                <div className='mt-2'>
                                    <Alert
                                        message={
                                            <h5 className='font-semibold'>{t('store_settings:image_requirements')}</h5>
                                        }
                                        description={
                                            <ul className='list-disc ml-4'>
                                                <li className='mb-0'>{t('store_settings:banner_logo_info')}</li>
                                                <li className='mb-0'>{t('store_settings:banner_logo_resolution')}</li>
                                                <li className='!mb-0 '>
                                                    {t('store_settings:upload_image_content', {
                                                        minFileSizeDefined,
                                                        maxFileSizeDefined,
                                                    })}
                                                </li>
                                                <li className='!mb-2'>
                                                    {t('store_settings:please_ensure_eight_images', {
                                                        BannerImagesUploadLength,
                                                    })}
                                                </li>
                                            </ul>
                                        }
                                        type='info'
                                        showIcon
                                    />
                                </div>
                                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                    <img
                                        alt='previewImage'
                                        style={{
                                            width: '100%',
                                        }}
                                        src={previewImage}
                                    />
                                </Modal>
                            </>
                        ) : null}
                    </Content>
                </>
            )}
            <StoreModal
                isVisible={isDeleteImageModalOpen}
                okButtonText={t('common:yes')}
                cancelButtonText={t('common:cancel')}
                title={t('common:warning')}
                okCallback={() => removeMedia()}
                cancelCallback={() => closeDeleteModal()}
                isSpin={isImageDeleting}
                hideCloseButton={false}>
                {
                    <div className='text-brandGray1'>
                        <p>{t('store_settings:confirm_image_deletion')}</p>
                        <p>{t('store_settings:delete_confirmation_message')}</p>
                    </div>
                }
            </StoreModal>
        </Content>
    )
}

export default StoreImages
