import { ActionTypes } from '../constants/ActionTypes'

const { RDX_STORE_PRODUCT_TYPES, RDX_PRODUCT_TYPE_DETAILS } = ActionTypes

export const fnStoreProductTypes = (data) => {
    return {
        type: RDX_STORE_PRODUCT_TYPES,
        payload: data,
    }
}

export const fnProductTypeDetails = (data) => {
    return {
        type: RDX_PRODUCT_TYPE_DETAILS,
        payload: data,
    }
}
