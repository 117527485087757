import React from 'react'

const BoxIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_3010_47999)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M16.5 6.40625V9.65625C18.25 10.4062 19.4375 12.1562 19.4375 14.1562C19.4375 16.875 17.2188 19.0937 14.5 19.0937C13.0625 19.0937 11.75 18.4687 10.8438 17.4687H2.6875C1.53125 17.4687 0.5625 16.5312 0.5625 15.3437V6.375C0.5625 5.875 0.75 5.34375 1.09375 4.96875L4.09375 1.59375C4.5 1.125 5.09375 0.875 5.6875 0.875H11.3438C11.9688 0.875 12.5313 1.15625 12.9375 1.59375L15.9688 5C16.3125 5.375 16.5 5.875 16.5 6.40625ZM14.2188 5.15625L11.875 2.53125C11.75 2.375 11.5625 2.28125 11.3438 2.28125H9.21875V5.15625H14.2188ZM5.6875 2.28125C5.5 2.28125 5.28125 2.375 5.15625 2.53125L2.8125 5.15625H7.8125V2.28125H5.6875ZM1.96875 15.375C1.96875 15.7812 2.28125 16.0937 2.6875 16.0937H9.96875C9.71875 15.5 9.59375 14.8437 9.59375 14.1562C9.59375 11.4375 11.8125 9.21875 14.5313 9.21875C14.7188 9.21875 14.9375 9.21875 15.125 9.25V6.5625H1.96875V15.375ZM10.9688 14.1875C10.9688 16.125 12.5313 17.7187 14.5 17.7187C16.4688 17.7187 18.0313 16.0937 18.0313 14.1562C18.0313 12.2187 16.4688 10.625 14.5 10.625C12.5313 10.625 10.9688 12.25 10.9688 14.1875ZM14.0625 14.1875L15.3438 12.8438C15.5938 12.5625 16.0625 12.5625 16.3125 12.7813C16.5938 13.0625 16.625 13.5 16.3438 13.7812L14.5313 15.6875C14.4063 15.8125 14.2188 15.9062 14.0313 15.9062C13.8438 15.9062 13.6562 15.8438 13.5313 15.6875L12.6563 14.75C12.375 14.4688 12.4063 14 12.6875 13.75C12.9688 13.4688 13.4375 13.5 13.6875 13.7812L14.0625 14.1875Z'
                    fill='#8899A8'
                />
            </g>
            <defs>
                <clipPath id='clip0_3010_47999'>
                    <rect width='20' height='20' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default BoxIcon
