import { DownOutlined, ReloadOutlined } from '@ant-design/icons'
import { Column } from '@ant-design/plots'
import { useQuery } from '@tanstack/react-query'
import { Badge, Button, Dropdown, Empty, Skeleton, Space, Tooltip, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import util from '../../util/common'
import './dashboard.css'

const { Text, Title } = Typography

const TopProductGraph = (props) => {
    const { t } = useTranslation()
    const auth = useAuth()
    const instance = axios.create()
    const [timeInterval, setTimeInterval] = useState(
        sessionStorage.getItem('timeInt') ? sessionStorage.getItem('timeInt') : 'Daily'
    )
    const [topProductWidgetID, setTopProductWidgetID] = useState(null)
    const [refetcher, setRefetcher] = useState(false)
    const [loader, setLoader] = useState(false)

    const [langDirection, setLangDirection] = useState('ltr')
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1366)
    const [updatedTimes, setUpdatedTimes] = useState({
        Daily: sessionStorage.getItem('updated_time_last24h'),
        Weekly: sessionStorage.getItem('updated_time_last7d'),
        Monthly: sessionStorage.getItem('updated_time_last4w'),
        Yearly: sessionStorage.getItem('updated_time_last12m'),
    })

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const dm4sightBaseURL = process.env.REACT_APP_4SIGHT_BASE_URL
    const dm4sightGetWidgetIdAPI = process.env.REACT_APP_4SIGHT_GETWIDGETID_API
    const dm4sightGetGraphDataAPI = process.env.REACT_APP_4SIGHT_GETGRAPHDATA_API
    const dm4sightGetDetailsByCustomQueryAPI = process.env.REACT_APP_4SIGHT_GETDETAILSBYCUSTOMQUERY_API
    const dm4sightClientID = process.env.REACT_APP_4SIGHT_CLIENT_ID
    const dm4sightEnabled = process.env.REACT_APP_4SIGHT_DATA_ENABLED

    let storeID = contentSettingFromRedux ? contentSettingFromRedux[0].store_id : props.storeID
    const realmName = util.getClient()
    let token = auth?.user?.access_token
    let reloadPerformance = props.reloadPerformance

    const dm4sightHeaders = {
        token: token,
        realmname: realmName,
        dmClientId: dm4sightClientID,
        client: 'store',
    }

    const items = [
        {
            key: '1',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e, t) => {
                        handleButtonClick(12, 'Daily')
                        // setTimeInterval('daily')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 12 {t('dashboard:hours')}
                </a>
            ),
            value: 'Daily',
        },
        {
            key: '2',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={() => {
                        handleButtonClick(7, 'Weekly')
                        // setTimeInterval('weekly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 7 {t('dashboard:days')}
                </a>
            ),
            value: 'Weekly',
        },
        {
            key: '3',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e) => {
                        handleButtonClick(30, 'Monthly')
                        // setTimeInterval('monthly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 4 {t('dashboard:weeks')}
                </a>
            ),
            value: 'Monthly',
        },
        {
            key: '4',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e) => {
                        handleButtonClick(120, 'Yearly')
                        // setTimeInterval('yearly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 12 {t('dashboard:months')}
                </a>
            ),
            value: 'Yearly',
        },
    ]

    const handleButtonClick = (value, interval) => {
        setTimeInterval(interval)
        sessionStorage.setItem('timeInt', interval)
    }

    const fetchWidgetId = async () => {
        try {
            const response = await instance({
                url: dm4sightBaseURL + dm4sightGetWidgetIdAPI,
                method: 'post',
                headers: dm4sightHeaders,
                data: {
                    names: ['top_products_smp'],
                },
            })

            const widgetId = response.data.widget_details.data[0].id
            return widgetId
        } catch (error) {
            throw error
        }
    }

    const {
        data: cachedWidgetId,
        isLoading: widgetIdLoading,
        refetch: refetchWidgetId,
    } = useQuery({
        queryKey: ['widgetId'],
        queryFn: fetchWidgetId,
        enabled: topProductWidgetID === null && dm4sightEnabled === 'true', // fetch only if topProductWidgetID is null
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24, //24hrs in milliseconds
    })

    // Update topProductWidgetID when the cachedWidgetId is available
    useEffect(() => {
        if (cachedWidgetId) {
            setTopProductWidgetID(cachedWidgetId)
        }
    }, [cachedWidgetId])

    useEffect(() => {
        if (topProductWidgetID) {
            getTopProductData()
        }
    }, [reloadPerformance])

    const getTopProductData = async () => {
        setLoader(true)
        try {
            const graphdataUrl =
                dm4sightBaseURL +
                dm4sightGetGraphDataAPI +
                `?widgetId=${topProductWidgetID}&filter_col=store_id&filter_val=${storeID}`
            const getDetailsByCustomQuery =
                dm4sightBaseURL + dm4sightGetDetailsByCustomQueryAPI + `?id=${topProductWidgetID}`

            const resGraph = await instance({
                url: graphdataUrl,
                method: 'post',
                headers: dm4sightHeaders,
            })

            const resGraphData = resGraph?.data?.data?.data[0]
            const columnKeys = [...new Set(resGraphData?.map((obj) => Object.keys(obj)).flat())]
            const topProductsFormatted = `(${resGraphData
                ?.map((obj) => `'${obj.name.replaceAll("'", "''")}'`)
                .join(',')})`

            const resCustomQuery = await instance({
                url: getDetailsByCustomQuery,
                method: 'post',
                headers: dm4sightHeaders,
                data: {
                    language_code: util.getUserSelectedLngCode(),
                    column_name: columnKeys,
                    column_data: topProductsFormatted,
                    time_interval: timeInterval.toLowerCase(),
                    filters: "store_id='" + storeID + "'",
                },
            })

            const customOutput = resCustomQuery ? resCustomQuery?.data?.data?.[0] : []

            let displayData = resCustomQuery?.data?.display

            let nonMatchingValues = displayData?.filter((ele) => ele.display_value !== ele.name)
            console.log('nonMatchingValues', nonMatchingValues)

            let queryUpdatedData = customOutput
                ? customOutput?.map((item, index) => {
                      return {
                          xValue:
                              timeInterval === 'Yearly'
                                  ? item.updated_time
                                  : timeInterval === 'Monthly'
                                    ? item.updated_time
                                    : timeInterval === 'Weekly'
                                      ? item.updated_time.split(' ')[0]
                                      : timeInterval === 'Daily' && item.updated_time,
                          product_name: item.name,
                          value: item.quantity,
                      }
                  })
                : []

            let result = []

            let topProds = resGraphData.map((ele) => ele.name)

            queryUpdatedData = queryUpdatedData.filter((item) => topProds.includes(item.product_name))

            console.log('queryUpdatedData common', queryUpdatedData)

            if (timeInterval === 'Weekly') {
                if (queryUpdatedData?.length > 0) {
                    result = [...queryUpdatedData]

                    result.forEach((item) => {
                        let formattedDate = new Date(item.xValue)
                            .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })
                            .replace(/,/g, '')
                            .split(' ')

                        item.xValue = formattedDate[1] + ' ' + formattedDate[0] + ' ' + formattedDate[2]
                    })

                    // Sort the array based on the converted dates
                    result.sort((a, b) => {
                        return new Date(a.xValue) - new Date(b.xValue)
                    })
                } else {
                    result = []
                }
                console.log('res', result)
            } else if (timeInterval === 'Monthly') {
                if (queryUpdatedData?.length > 0) {
                    result = [...queryUpdatedData]

                    result.forEach((item) => {
                        const dates = item.xValue.split('&')

                        const startDate = new Date(dates[0])
                            .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })
                            .replace(/,/g, '')
                            .split(' ')

                        let startDateFormatted = startDate[1] + ' ' + startDate[0] + ' ' + startDate[2]

                        const endDate = new Date(dates[1])
                            .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })
                            .replace(/,/g, '')
                            .split(' ')

                        let endDateFormatted = endDate[1] + ' ' + endDate[0] + ' ' + endDate[2]

                        item.xValue = `${startDateFormatted} - ${endDateFormatted}`
                    })

                    result.sort((a, b) => new Date(a.xValue.split(' - ')[0]) - new Date(b.xValue.split(' - ')[0]))
                } else {
                    result = []
                }

                console.log('res', result)
            } else if (timeInterval === 'Yearly') {
                if (queryUpdatedData?.length > 0) {
                    result = [...queryUpdatedData]
                    result.sort((a, b) => {
                        const dateA = new Date(a.xValue)
                        const dateB = new Date(b.xValue)

                        return dateA - dateB
                    })
                } else {
                    result = []
                }
            } else if (timeInterval === 'Daily') {
                if (queryUpdatedData?.length > 0) {
                    result = [...queryUpdatedData]

                    // compare dates and sort
                    result.sort((a, b) => {
                        return new Date(a.xValue.split(' ')[0]) - new Date(b.xValue.split(' ')[0])
                    })

                    const d = new Date()
                    let currentHour = d.getHours()

                    result = result.map((item) => ({
                        xValue: item.xValue.split(' ')[1],
                        product_name: item.product_name,
                        value: item.value,
                    }))
                    let less_ress = []
                    let high_ress = []

                    if (currentHour <= 10 && currentHour >= 0) {
                        result.forEach((ele) => {
                            console.log(ele.xValue, 'day')
                            if (ele.xValue >= 0 && ele.xValue <= 10) {
                                less_ress.push(ele)
                            } else {
                                high_ress.push(ele)
                            }
                            //console.log(ele.xValue, "hii")
                        })

                        less_ress.sort((a, b) => {
                            return parseInt(a.xValue) - parseInt(b.xValue)
                        })
                        high_ress.sort((a, b) => {
                            return parseInt(a.xValue) - parseInt(b.xValue)
                        })
                        console.log(less_ress, 'less')
                        console.log(high_ress, 'high')
                        result = []
                        high_ress.forEach((ele) => {
                            if (ele.xValue === '23') {
                                ele.xValue = ele.xValue + '-00'
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }

                            result.push(ele)
                        })
                        less_ress.forEach((ele) => {
                            if (parseInt(ele.xValue) < 9) {
                                ele.xValue = ele.xValue + '-0' + (parseInt(ele.xValue) + 1).toString()
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }

                            result.push(ele)
                        })

                        console.log(result, 'finally')
                    } else {
                        result.sort((a, b) => {
                            return a.xValue - b.xValue
                        })
                        result.forEach((ele) => {
                            if (parseInt(ele.xValue) < 9) {
                                ele.xValue = ele.xValue + '-0' + (parseInt(ele.xValue) + 1).toString()
                            } else if (ele.xValue === '23') {
                                ele.xValue = ele.xValue + '-00'
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }
                        })
                    }
                }
            } else {
                result = []
            }

            if (nonMatchingValues?.length > 0) {
                // Function to find the corresponding display_value for a given product_name
                const findDisplayValue = (productName) => {
                    const mapping = nonMatchingValues.find((item) => item.name === productName)
                    return mapping ? mapping.display_value : productName
                }

                // Modify product_name in the dataArray
                const modifiedArray = result.map((item) => ({
                    ...item,
                    product_name: findDisplayValue(item.product_name),
                }))

                // result = modifiedArray;

                //add the values when product name and xField value(monthname/hourname/weekname/dayname) are the same
                const newConsolidatedData = []
                modifiedArray.forEach((item) => {
                    const existingItem = newConsolidatedData.find(
                        (i) => i.xValue === item.xValue && i.product_name === item.product_name
                    )
                    if (existingItem) {
                        existingItem.value += item.value
                    } else {
                        newConsolidatedData.push(item)
                    }
                })

                result = newConsolidatedData
            }

            // console.log("res2", result);

            //add the values when product name and xField value(monthname/hourname/weekname/dayname) are the same
            let addedData = []
            result.forEach((item) => {
                const existingItem = addedData.find(
                    (i) => i.xValue === item.xValue && i.product_name === item.product_name
                )
                if (existingItem) {
                    existingItem.value += item.value
                } else {
                    addedData.push(item)
                }
                console.log('addedData', addedData)
            })

            const currentDate = new Date()
            const currentTime = currentDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
            if (timeInterval === 'Yearly') {
                setUpdatedTimes({
                    ...updatedTimes,
                    Yearly: currentTime,
                })
                sessionStorage.setItem('updated_time_last12m', currentTime)
            } else if (timeInterval === 'Monthly') {
                setUpdatedTimes({
                    ...updatedTimes,
                    Monthly: currentTime,
                })
                sessionStorage.setItem('updated_time_last4w', currentTime)
            } else if (timeInterval === 'Weekly') {
                setUpdatedTimes({
                    ...updatedTimes,
                    Weekly: currentTime,
                })
                sessionStorage.setItem('updated_time_last7d', currentTime)
            } else if (timeInterval === 'Daily') {
                setUpdatedTimes({
                    ...updatedTimes,
                    Daily: currentTime,
                })
                sessionStorage.setItem('updated_time_last24h', currentTime)
            }
            setRefetcher(false)

            return result.sort((a, b) => (a.product_name > b.product_name ? 1 : -1)).reverse()
        } catch (error) {
            throw error
        } finally {
            setLoader(false)
        }
    }

    const {
        data: topProductData,
        isLoading: isLoadingProducts,
        isFetching,
        isRefetching,
        isFetched,
        refetch: refetchProducts,
    } = useQuery({
        queryKey: ['topProductData', timeInterval, topProductWidgetID],
        queryFn: getTopProductData,
        enabled: !!timeInterval && topProductWidgetID !== null && dm4sightEnabled === 'true',
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 60 * 24, //24hrs in milliseconds
    })
    // Trim product_name to 50 characters and add "..." if needed
    const trimmedData = topProductData?.map((item) => ({
        ...item,
        product_name: item.product_name?.length > 50 ? `${item.product_name.slice(0, 50)}...` : item.product_name,
    }))

    const config = {
        data: trimmedData,
        legend: false,
        xField: 'xValue',
        yField: 'value',
        yAxis: {
            label: {
                formatter: (val) => (Number.isInteger(parseFloat(val)) ? val : ''), // Hide decimal values
            },
            title: {
                text: t('dashboard:number_of_orders'),
            },
            grid: {
                line: {
                    style: {
                        lineDash: [7, 7],
                    },
                },
            },
        },
        xAxis: {
            title: {
                text:
                    timeInterval === 'Yearly'
                        ? t('dashboard:months_caps')
                        : timeInterval === 'Monthly'
                          ? t('dashboard:weeks_caps')
                          : timeInterval === 'Weekly'
                            ? t('dashboard:days_caps')
                            : t('dashboard:hours_caps'),
            },
        },
        seriesField: 'product_name',
        isGroup: true,
        columnStyle: {
            radius: [20, 20, 20, 20],
            style: {
                fill: 'red',
                fillOpacity: 0.1,
                stroke: 'black',
                lineWidth: 1,
                lineDash: [1, 1],
                strokeOpacity: 0.1,
                shadowColor: 'black',
                shadowBlur: 10,
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                cursor: 'pointer',
            },
        },
        marginRatio: 5,
        columnWidthRatio: 0.1,
        color: ['#001529', '#FCC32A', '#1890FF'],
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1366)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div>
            <Content className=''>
                <Title level={4} className='!m-0 !pt-0 !mt-0 !text-[#8899A8] flex gap-2 justify-between '>
                    {t('dashboard:top_products')}

                    <Title level={5}>
                        <Dropdown
                            menu={{
                                items,
                            }}>
                            <a style={{ color: '#FB8500' }} onClick={(e) => e.preventDefault()}>
                                <Space>
                                    {items?.filter((ele) => ele.value === timeInterval)?.[0]?.label}
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </Title>
                </Title>
                <div
                    className={
                        isSmallScreen
                            ? 'flex flex-col items-baseline relative mt-1 mb-5'
                            : 'flex flex-wrap gap-1 w-[30em] mb-5'
                    }>
                    {/* <div
                        className={` ${
                            langDirection === 'rtl' ? 'absolute top-2 !left-4' : 'absolute top-2 !right-4'
                        }`}>
                        <div className='flex flex-wrap gap-1 w-[30em] mt-2 justify-end'>
                            {timeIntervals.map((item) => (
                                <Button
                                    className={timeInterval === item.interval ? 'app-btn-primary' : 'app-btn-text'}
                                    key={item.value}
                                    type={active === item.value ? '' : 'text'}
                                    onClick={() => handleButtonClick(item.value, item.interval)}
                                    ghost>
                                    {item.label}
                                </Button>
                            ))}
                        </div>
                    </div> */}
                </div>
                {(topProductData && isLoadingProducts && !isFetched) ||
                (topProductData && isRefetching && isFetching && !isFetched) ||
                (topProductData && refetcher) ||
                isLoadingProducts ||
                loader ? (
                    <Skeleton paragraph={{ rows: 6 }} />
                ) : !topProductData || topProductData?.length === 0 ? (
                    <Content className='mt-5'>
                        <Empty description={t('dashboard:no_products_available')} />
                    </Content>
                ) : (
                    <Column {...config} />
                )}
                <div className='relative mt-3'>
                    <div className='flex  justify-left flex-col'>
                        {[...new Set(topProductData?.map((item) => item?.product_name))]
                            .sort()
                            .reverse()
                            ?.map((ele, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: '0px 10px',
                                        width: 'max-content',
                                        gap: 4,
                                    }}>
                                    <Badge color={config.color[index]} />
                                    <Tooltip title={ele}>
                                        <Text className='text-[#637381]' strong>
                                            {ele.length <= 50 ? ele : ele.slice(0, 50) + '...'}
                                        </Text>
                                    </Tooltip>
                                </div>
                            ))}
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default TopProductGraph
