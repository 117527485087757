import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_NEWLY_CREATED_ATTRIBUTE_INFO,
    RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO,
    RDX_ALREADY_CREATED_ATTRIBUTE_INFO,
    RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO,
    RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO,
    RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO,
} = ActionTypes

export const ReducerCalculativeAttributeInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO:
            return { ...state, calculativeAttributeInfo: payload }
        default:
            return state
    }
}

export const ReducerCalculativeOperatorInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO:
            return { ...state, calculativeOperatorInfo: payload }
        default:
            return state
    }
}

export const ReducerNewlyCreatedAttributeInfo = (state = {}, { type, payload }) => {
    switch (type) {
        case RDX_NEWLY_CREATED_ATTRIBUTE_INFO:
            return { ...state, newlyCreatedAttributeInfo: payload }
        default:
            return state
    }
}

export const ReducerNewlyCreatedStaticAttributeInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO:
            return { ...state, newlyCreatedStaticAttributeInfo: payload }
        default:
            return state
    }
}
export const ReducerAlreadyCreatedAttributesInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_ALREADY_CREATED_ATTRIBUTE_INFO:
            return { ...state, alreadyCreatedAttributeInfo: payload }
        case RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO:
            return { ...state, alreadyCreatedStaticAttributeInfo: payload }
        default:
            return state
    }
}
