import { HolderOutlined, InboxOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Skeleton, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useGetStaticAttributeValues from '../../../hooks/useGetStaticAttributeValues'
import { fnAttributeDetailsUpdated } from '../../../services/redux/actions/ActionsAPICall'
import { GetAttributesControls } from '../../../util/AttributeControls'
import util from '../../../util/common'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useGetVariantAttributes from '../hooks/useGetVariantAttributes'
import useSaveVariantAttributes from '../hooks/useSaveVariantAttributes'
const { Text } = Typography
const { Content } = Layout
function ListVariantAttributes({
    productVariantAttributesData,
    setShowModal,
    variantCount,
    setVariantCount,
    currentProductVariantAttributeArray,
    setCurrentProductVariantAttributeArray,
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const search = useLocation().search
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    const { data: variantAttributesAPIData, status: variantAttributesStatus } = useGetVariantAttributes({
        productTemplateRevisionId: productTemplateRevisionId,
    })
    const { data: nonEditableValues } = useGetStaticAttributeValues({
        productTemplateId: productTemplateId,
        productTemplateRevisionId: productTemplateRevisionId,
    })
    const [productVariantAttributeArray, setProductVariantAttributeArray] = useState([])
    const [langDirection, setLangDirection] = useState('ltr')
    const { mutate: saveVariantAttributes } = useSaveVariantAttributes()
    /**
     * The function is to handle the adding the variant attributes to the server.
     * Below we are using a custom hook, which will handle the save call by taking the post body as params.
     */
    const handlePostVariantAttributes = () => {
        saveVariantAttributes(
            {
                postBody: {
                    product_template_revision: parseInt(productTemplateRevisionId),
                    attribute: currentProductVariantAttributeArray,
                },
            },
            {
                onSuccess: (response) => {
                    console.log(response)
                    MarketplaceToaster.showToast(response)
                    let tempProductVariantArray = [
                        ...productVariantAttributeArray,
                        ...currentProductVariantAttributeArray,
                    ]
                    setProductVariantAttributeArray(tempProductVariantArray)
                    setCurrentProductVariantAttributeArray([])
                    dispatch(fnAttributeDetailsUpdated(true))
                    closeModal()
                },
                onError: (err) => {
                    MarketplaceToaster.showToast(err.response)
                },
            }
        )
    }
    /**
     * The below function will close the add variant attributes modal and it make the current local productVariantAttributeArray empty.
     * we have to make the productVariantAttributeArray because to revert to the server state, instead of local state what user did.
     */
    const closeModal = () => {
        setShowModal(false)
        setCurrentProductVariantAttributeArray([])
    }
    /**
     * The function handles the non-editable(static) preference related value fetching.
     * @param {*} attributeId It is the attribute ID.
     * @returns the static attribute value to populate when attribute is static.
     */
    const getNonEditableOptionsForAttribute = (attributeId) => {
        let attributeOptions = []
        if (nonEditableValues && nonEditableValues.length > 0) {
            const attributeValueData = nonEditableValues.filter((item) => item.attribute === attributeId)
            if (attributeValueData && attributeValueData.length > 0) {
                attributeOptions = attributeValueData[0].attribute_option
            }
        }
        return attributeOptions
    }
    /**
     * The function is to handle the variant attribute addition with the existing attributes.
     * @param {*} attributeId It is the attribute ID.
     * It will take the attributes created in the product template screen and maps the id and add it as a variant attribute.
     */
    const handleAddFromExistingAttributesButton = (attributeId) => {
        var currentVariantAttributeArray = [...currentProductVariantAttributeArray]
        currentVariantAttributeArray.push(attributeId)
        setVariantCount(variantCount + 1)
        setCurrentProductVariantAttributeArray(currentVariantAttributeArray)
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    useEffect(() => {
        let tempArray = []
        productVariantAttributesData &&
            productVariantAttributesData?.length > 0 &&
            productVariantAttributesData?.map(
                (item) =>
                    item.attribute &&
                    item.attribute.length > 0 &&
                    item.attribute.map((item1) => tempArray.push(item1.attribute_id))
            )
        setProductVariantAttributeArray(tempArray)
        setCurrentProductVariantAttributeArray([])
    }, [productVariantAttributesData])
    console.log('product_attribute_group_data', productVariantAttributeArray)

    return (
        <div>
            {variantAttributesStatus === 'pending' ? (
                <Content className='bg-white p-3'>
                    <Skeleton paragraph={{ rows: 3 }} />
                </Content>
            ) : variantAttributesStatus === 'error' ? (
                <Content className='text-center bg-white p-3'>
                    <h5 className='!flex !justify-center'>{t('common:network_error')}</h5>
                </Content>
            ) : (
                <>
                    {variantAttributesAPIData.product_attribute_group_data &&
                    variantAttributesAPIData.product_attribute_group_data.length > 0 &&
                    variantAttributesAPIData.product_attribute_group_data?.[0] !==
                        'No data Found for Product_revision_id' ? (
                        <>
                            {variantAttributesAPIData.product_attribute_group_data &&
                                variantAttributesAPIData.product_attribute_group_data.length > 0 &&
                                variantAttributesAPIData.product_attribute_group_data.map((element) => (
                                    <Content className='bg-white '>
                                        {element.attribute &&
                                            element.attribute.length > 0 &&
                                            element.attribute.map((element1) => (
                                                <Content className='!flex my-2'>
                                                    <Col span={12}>
                                                        <Content className='!flex !flex-row '>
                                                            <span className=''>
                                                                <HolderOutlined />
                                                            </span>
                                                            <Content className='!flex !flex-col'>
                                                                <Tooltip
                                                                    title={element1 && element1.display_name}
                                                                    overlayStyle={{
                                                                        zIndex: 5000,
                                                                    }}
                                                                    placement={
                                                                        langDirection === 'rtl' ? 'left' : 'right'
                                                                    }>
                                                                    <Text ellipsis={true} className='!max-w-[100px]'>
                                                                        {element1 && element1.display_name}
                                                                    </Text>
                                                                </Tooltip>
                                                                <span className='mx-2 my-2'>
                                                                    <GetAttributesControls
                                                                        optionType={element1.datatype_id}
                                                                        optionValues={element1.attribute_option}
                                                                        isEditable={true}
                                                                        nonEditableValue={getNonEditableOptionsForAttribute(
                                                                            element1.attribute_id
                                                                        )}
                                                                        placeholder={element1.placeholder}
                                                                        attribute_unit_values={
                                                                            element1.attribute_unit_values
                                                                        }
                                                                        viewTypeForDropDown={'modal'}
                                                                    />
                                                                </span>
                                                            </Content>
                                                        </Content>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Content className='!flex mt-2'>
                                                            <span className=''></span>
                                                            {productVariantAttributeArray &&
                                                            productVariantAttributeArray.length > 0 &&
                                                            productVariantAttributeArray.includes(
                                                                element1.attribute_id
                                                            ) ? (
                                                                <Content className='!flex !justify-end !items-center'>
                                                                    <Tooltip
                                                                        title={t(
                                                                            'product_template:variant_attribute_tooltip_message'
                                                                        )}>
                                                                        <Button
                                                                            className='app-btn-secondary'
                                                                            disabled={true}>
                                                                            <Content className='!flex gap-2 justify-center items-center'>
                                                                                <span className=''>
                                                                                    <PlusOutlined />
                                                                                </span>
                                                                                <span className=''>
                                                                                    {t(
                                                                                        'product_template:add_variant_attributes'
                                                                                    )}
                                                                                </span>
                                                                            </Content>
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Content>
                                                            ) : (
                                                                <Content className='!flex !justify-end !items-center'>
                                                                    <Button
                                                                        className='app-btn-secondary'
                                                                        onClick={() =>
                                                                            handleAddFromExistingAttributesButton(
                                                                                element1.attribute_id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            currentProductVariantAttributeArray &&
                                                                            currentProductVariantAttributeArray.length >
                                                                                0 &&
                                                                            currentProductVariantAttributeArray.includes(
                                                                                element1.attribute_id
                                                                            )
                                                                                ? true
                                                                                : variantCount < 6
                                                                                  ? false
                                                                                  : true
                                                                        }>
                                                                        <Content className='!flex gap-2 justify-center items-center '>
                                                                            <span className=''>
                                                                                <PlusOutlined />
                                                                            </span>
                                                                            <span className='pl-1'>
                                                                                {t(
                                                                                    'product_template:add_variant_attributes'
                                                                                )}
                                                                            </span>
                                                                        </Content>
                                                                    </Button>
                                                                </Content>
                                                            )}
                                                        </Content>
                                                    </Col>
                                                </Content>
                                            ))}
                                    </Content>
                                ))}
                            <Content className='!flex !justify-end !items-center'>
                                <Button className='mr-2 app-btn-secondary' onClick={closeModal}>
                                    {' '}
                                    {t('common:discard')}
                                </Button>
                                <Button
                                    className={`app-btn-primary ${
                                        currentProductVariantAttributeArray &&
                                        currentProductVariantAttributeArray.length === 0
                                            ? 'opacity-50'
                                            : ''
                                    }`}
                                    key='submit'
                                    disabled={
                                        currentProductVariantAttributeArray &&
                                        currentProductVariantAttributeArray.length === 0
                                            ? true
                                            : false
                                    }
                                    onClick={() => handlePostVariantAttributes()}>
                                    {t('common:save')}
                                </Button>
                            </Content>
                        </>
                    ) : (
                        <Content className='!flex !flex-col !justify-center !items-center !bg-white'>
                            <InboxOutlined className='mt-3' />
                            <p className='text-[16px] my-2'>{t('product_template:no_attributes_added')}</p>
                        </Content>
                    )}
                </>
            )}
        </div>
    )
}

export default ListVariantAttributes
