import Cookies from 'js-cookie'

let storeName = window.sessionStorage.getItem('client')

const isDev = () => {
    return process.env.NODE_ENV === 'development' ? true : false
}

/**
 * Getter Functions
 */

const getAuthToken = () => {
    return Cookies.get(storeName + '_' + 'smp_access_token')
}

const getRefreshToken = () => {
    return Cookies.get(storeName + '_' + 'smp_refresh_token')
}

const getClient = (Case) => {
    if (Case === 'lower') return window.sessionStorage.getItem('client').toLowerCase()
    else if (Case === 'upper') return window.sessionStorage.getItem('client').toUpperCase()
    else return window.sessionStorage.getItem('client')
}

const getClientID = (client) => {
    return client + '-client'
}

const getReduxPersistRoot = () => {
    let reduxPersistRoot = window.localStorage.getItem('persist:root')
    if (reduxPersistRoot) return JSON.parse(reduxPersistRoot)
    else return null
}

const getStoreDefaultLngCode = () => {
    try {
        const allStoreLngs = getReduxPersistRoot()
        let defaultStoreLng = allStoreLngs && allStoreLngs.reducerDefaultLanguage
        defaultStoreLng = defaultStoreLng && JSON.parse(defaultStoreLng).defaultLanguage

        if (defaultStoreLng) {
            if (defaultStoreLng.language_code) {
                return String(defaultStoreLng.language_code).toLowerCase()
            } else {
                return 'en'
            }
        } else return 'en'
    } catch (error) {
        return 'en'
    }
}

const getUserSelectedLngCode = () => {
    try {
        const cookieLngCode = Cookies.get('dmsmplng')
        // console.log("cookieLngCodeinI18", cookieLngCode);
        const localStorageLngCode = window.localStorage.getItem('dmsmplng')
        // console.log("localStorageLngCodeinI18", localStorageLngCode);
        if (cookieLngCode) return cookieLngCode
        else if (localStorageLngCode) return localStorageLngCode
        else {
            const allStoreLngs = getReduxPersistRoot()
            let defaultStoreLng = allStoreLngs && allStoreLngs.reducerDefaultLanguage
            defaultStoreLng = defaultStoreLng && JSON.parse(defaultStoreLng).defaultLanguage

            let userSelectedLng = allStoreLngs && allStoreLngs.reducerSelectedLanguage
            userSelectedLng = userSelectedLng && JSON.parse(userSelectedLng).selectedLanguage
            if (userSelectedLng) {
                if (userSelectedLng.language_code) {
                    return String(userSelectedLng.language_code).toLowerCase()
                } else {
                    return 'en'
                }
            } else if (defaultStoreLng) {
                if (defaultStoreLng.language_code) {
                    return String(defaultStoreLng.language_code).toLowerCase()
                } else {
                    return 'en'
                }
            } else {
                return 'en'
            }
        }
    } catch (error) {
        console.log('insideCatch')
        // setUserSelectedLngCode("en");
        return 'en'
    }
}

const getStoreSupportedLngs = () => {
    let storeSupportedLngs = ['en']
    try {
        const reduxPersistRoot = getReduxPersistRoot()
        let storeLngs = reduxPersistRoot && reduxPersistRoot.reducerStoreLanguage
        storeLngs = storeLngs && JSON.parse(storeLngs).storeLanguage
        if (storeLngs && storeLngs.length > 0) {
            storeSupportedLngs = []
            storeLngs.forEach((element) => {
                if (element.language_code) {
                    storeSupportedLngs.push(element.language_code.toLowerCase())
                }
            })
        }

        return storeSupportedLngs
    } catch (error) {
        return storeSupportedLngs
    }
}

const getSelectedLanguageDirection = () => {
    try {
        const allStoreLngs = getReduxPersistRoot()
        let defaultStoreLng = allStoreLngs && allStoreLngs.reducerDefaultLanguage
        defaultStoreLng = defaultStoreLng && JSON.parse(defaultStoreLng).defaultLanguage
        let userSelectedLng = allStoreLngs && allStoreLngs.reducerSelectedLanguage
        userSelectedLng = userSelectedLng && JSON.parse(userSelectedLng).selectedLanguage
        if (userSelectedLng) {
            return userSelectedLng.writing_script_direction
        } else if (defaultStoreLng) {
            return defaultStoreLng.writing_script_direction
        } else {
            return 'LTR'
        }
    } catch (error) {
        return 'LTR'
    }
}

const getStoreName = (appConfig, searchParams) => {
    try {
        const storeConfig = getAppConfig(appConfig, searchParams)
        let storeName = storeConfig?.store.toLowerCase()
        storeName = storeName.substring(storeName.lastIndexOf('-') + 1, storeName.length)
        return storeName
    } catch (error) {
        return ''
    }
}
const getFinalAppConfig = (appConfig) => {
    setClient(appConfig.store)
    appConfig.onSigninCallback = (_user) => {
        window.history.replaceState({}, document.title, window.location.pathname + '?store=' + appConfig.store)
    }
    return appConfig
}

const getToastObject = (message, type) => {
    const toastObject = {}
    const dataObject = {}
    dataObject['response_message'] = message
    dataObject['error_type'] = type

    toastObject['data'] = dataObject

    return toastObject
}

const getPermissionData = () => {
    return window.sessionStorage.getItem('permissions_data')
}

const setStoreNameWhenWeChangeINUrl = () => {
    const clientKeyName = 'store'
    const clientValueName = sessionStorage.getItem('client')
    const urlParams = new URLSearchParams(window.location.search)

    if (clientValueName !== null) {
        if (urlParams.has(clientKeyName)) {
            urlParams.set(clientKeyName, clientValueName)
            const newUrl = `${window.location.pathname}?${urlParams.toString()}`
            window.history.pushState({}, null, newUrl)
        }
    }
}

const getAppConfig = (appConfig, searchParams) => {
    if (appConfig && appConfig.config_details.length > 0) {
        console.log('----------Check url if there is any path param available or not ', searchParams.get('store'))
        const defaultClient = appConfig.config_details[0]
        // Check url if there is any path param available or not
        if (searchParams.size === 0) {
            console.log('----------User may or may not be logged in ', searchParams.size)
            //User may or may not be logged in
            if (getClient()) {
                console.log('----------User is logged in', getClient())
                // User is logged in
                const activeClient = appConfig.config_details.filter(
                    (element) => String(element.store).toLowerCase() === String(getClient()).toLowerCase()
                )
                return getFinalAppConfig(activeClient[0])
            } else {
                // User is not logged in
                console.log('----------User is not logged in ')
                return getFinalAppConfig(defaultClient)
            }
        } else if (searchParams.has('store')) {
            console.log('----------If user has provided vendor info in the url ')
            // If user has provided vendor info in the url
            const activeClient = appConfig.config_details.filter(
                (element) => String(element.store).toLowerCase() === String(searchParams.get('store')).toLowerCase()
            )
            if (activeClient && activeClient.length > 0) {
                if (searchParams.size > 0) {
                    // When user provides valid vendor info in the url
                    console.log('----------When user provides valid vendor info in the url ')
                    return getFinalAppConfig(activeClient[0])
                } else {
                    // Set default when user provides invalid vendor info in the url
                    console.log('----------Set default when user provides invalid vendor info in the url ')
                    return getFinalAppConfig(defaultClient)
                }
            } else {
                setStoreNameWhenWeChangeINUrl()
            }
        } else if (searchParams.has('state')) {
            // User is logged in, state will be available post login when key cloak redirects back to MP
            const activeClient = appConfig.config_details.filter(
                (element) => String(element.store).toLowerCase() === String(getClient()).toLowerCase()
            )
            if (activeClient.length > 0) {
                // User logged in and client is available in the session storage
                return getFinalAppConfig(activeClient[0])
            } else {
                // User logged in but client is not available in the session storage
                return getFinalAppConfig(defaultClient)
            }
        } else {
            // When user provides invalid path params in the url
            return getFinalAppConfig(defaultClient)
        }
    } else {
        //Redirect to Error Page
        console.log('APPLICATION CONFIGURATION NOT FOUND1')
    }
}

const getIsAuthorized = () => {
    return Cookies.get(storeName + '_' + 'smp_is_authorized')
}

const getCouplerMultilingualKey = (value) => {
    let coupleMultilingualKey = value.toLowerCase()
    coupleMultilingualKey = coupleMultilingualKey.replaceAll(/ /g, '_').replaceAll(/-/g, '_')
    return coupleMultilingualKey
}

/**
 * Setter Functions
 */

const setAuthToken = (authToken) => {
    Cookies.set(storeName + '_' + 'smp_access_token', authToken)
}

const setRefreshToken = (refreshToken) => {
    Cookies.set(storeName + '_' + 'smp_refresh_token', refreshToken)
}

const setIsLoggedIn = (value) => {
    // window.sessionStorage.setItem("smp_is_logged_in", value);
    Cookies.set('smp_is_logged_in', value)
}

const setClient = (value) => {
    window.sessionStorage.setItem('client', value)
}

const setPermissionData = (value) => {
    window.sessionStorage.setItem('permissions_data', value)
}
const setUserSelectedLngCode = (value) => {
    window.localStorage.setItem('dmsmplng', value)
    Cookies.set('dmsmplng', value)
}

const setIsAuthorized = (value) => {
    Cookies.set(storeName + '_' + 'smp_is_authorized', value)
}

/**
 * Remove Functions
 */

const removeIsAuthorized = () => {
    Cookies.remove(storeName + '_' + 'smp_is_authorized')
}

const removeAuthToken = () => {
    Cookies.remove(storeName + '_' + 'smp_access_token')
}

const setSelectedLngCode = (langCode) => {
    window.localStorage.setItem('selected_dmsmplng', langCode)
    Cookies.set('selected_dmsmplng', langCode)
}

const removeSelectedLngCode = () => {
    window.localStorage.removeItem('selected_dmsmplng')
    Cookies.remove('selected_dmsmplng')
}

const util = {
    isDev,

    getAuthToken,
    getRefreshToken,
    getUserSelectedLngCode,
    getStoreDefaultLngCode,
    getStoreSupportedLngs,
    getClient,
    getClientID,
    getAppConfig,
    getIsAuthorized,
    getPermissionData,
    getToastObject,
    getCouplerMultilingualKey,
    getSelectedLanguageDirection,
    getStoreName,

    setAuthToken,
    setRefreshToken,
    setIsLoggedIn,
    setClient,
    setIsAuthorized,
    setPermissionData,
    setUserSelectedLngCode,
    setStoreNameWhenWeChangeINUrl,

    removeAuthToken,
    removeIsAuthorized,

    setSelectedLngCode,
    removeSelectedLngCode,
}

export default util
