const timeZoneMap = {
    '-12:00': 'IDLW',
    '-11:00': 'NT',
    '-10:00': 'HST',
    '-09:30': 'MIT',
    '-09:00': 'AKST',
    '-08:00': 'PST',
    '-07:00': 'MST',
    '-06:00': 'CST',
    '-05:00': 'EST',
    '-04:00': 'AST',
    '-03:30': 'NST',
    '-03:00': 'ART',
    '-02:00': 'BRST',
    '-01:00': 'AZOT',
    '00:00': 'GMT',
    '+01:00': 'CET',
    '+02:00': 'EET',
    '+03:00': 'EAT',
    '+03:30': 'IRST',
    '+04:00': 'GET',
    '+04:30': 'AFT',
    '+05:00': 'PKT',
    '+05:30': 'IST',
    '+05:45': 'NPT',
    '+06:00': 'BST',
    '+06:30': 'CCT',
    '+07:00': 'WIB',
    '+08:00': 'CST',
    '+09:00': 'JST',
    '+09:30': 'ACST',
    '+10:00': 'AEST',
    '+11:00': 'SBT',
    '+12:00': 'NZST',
    '+13:00': 'PHOT',
    '+14:00': 'LINT',
}

export const getTimeZoneAbbreviation = () => {
    const offset = new Date().getTimezoneOffset()
    const hours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0')
    const minutes = String(Math.abs(offset) % 60).padStart(2, '0')
    const sign = offset > 0 ? '-' : '+'
    const tzKey = `${sign}${hours}:${minutes}`
    return timeZoneMap[tzKey] || 'Unknown Time Zone'
}