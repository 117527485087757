import React, { useState, useEffect } from 'react' //npm install react-quill --save and ,npm i quill-image-resize-module ,npm i quill-image-resize-module-react
import ReactQuill from 'react-quill'
import EditorToolbar, { modules, formats } from './EditorToolbar'
import 'react-quill/dist/quill.snow.css'
import util from '../../util/common'

/**
 * ! TextRichEditor is a custom component designed to get text data with some stylings.
 * ! The main purpose of this custom component is to change the normal text to some text styles.
 * ? Use of this custom component
 * ? We can import this custom component in any of the react component and can call this inside component.
 * ! How to use this  custom component.
 * ? import TextRichEditor from "../../components/TextRichEditor/TextRichEditor";
 * * from path my vary based on the component & this custom component directory.
 * ?  <TextRichEditor data={abc} setData={setValue},lngCode={kn}/>
 * */

const TextRichEditor = ({ data, setData, isTextFieldEmpty, placeholder, editable, minHeight }) => {
    const [propsData, setPropsData] = useState(data)
    const [langDirection, setLangDirection] = useState('ltr')

    //Onchange function
    const handleChange = (val) => {
        setPropsData(val)
        if (val === '' || val === '<p><br></p>') {
            setData('')
        } else {
            setData(val)
        }
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    console.log('editable------------>', editable)

    return (
        <div
            className={`${minHeight} ${
                langDirection === 'rtl' ? 'reactquillrtlplaceholder' : 'reactquillltrplaceholder'
            } !max-h-[350px] !overflow-y-auto overflow-x-hidden
    ${editable === true ? 'reactquilltoolbardisabled' : ''}
      ${isTextFieldEmpty ? 'border-[1px] border-[#ff4d4f] rounded-md' : 'border-[1px] border-[#ccc] rounded-md'}`}>
            {/* <EditorToolbar /> */}
            <ReactQuill
                theme='snow'
                value={data}
                onChange={(val) => {
                    //console.log("text-richcontent...,", val);
                    handleChange(val)
                }}
                readOnly={editable}
                placeholder={
                    placeholder !== false &&
                    placeholder !== '' &&
                    placeholder !== null &&
                    placeholder !== undefined &&
                    placeholder !== 'False'
                        ? placeholder
                        : 'Enter Rich Text Data'
                }
                // modules={modules}
                formats={formats}
            />
        </div>
    )
}

export default TextRichEditor
