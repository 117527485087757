import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_MAKE_API_CALL,
    RDX_API_SUCCESS_RESPONSE,
    RDX_MAKE_API_CALL2,
    RDX_CALL_PUBLISHING_API,
    RDX_SERVICE_PRODUCT_TIME_VALIDATION,
    RDX_ATTRIBUTE_DETAILS_UPDATED,
    RDX_ATTRIBUTE_DETAILS_TRANSLATED,
} = ActionTypes

export const ReducerAPICalls = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_MAKE_API_CALL:
            return { ...state, makeAPICall: payload }
        case RDX_API_SUCCESS_RESPONSE:
            return { ...state, apiSuccessResponse: payload }
        case RDX_MAKE_API_CALL2:
            return { ...state, makeAPICall2: payload }
        case RDX_CALL_PUBLISHING_API:
            return { ...state, makePublishingCall: payload }
        case RDX_SERVICE_PRODUCT_TIME_VALIDATION:
            return { ...state, makeServiceProductValidation: payload }
        case RDX_ATTRIBUTE_DETAILS_UPDATED:
            return { ...state, isAttributeDetailsUpdated: payload }
        case RDX_ATTRIBUTE_DETAILS_TRANSLATED:
            return { ...state, isAttributeDetailsTranslated: payload }
        default:
            return state
    }
}
