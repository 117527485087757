import { Button, Layout, Skeleton, Switch, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import MarketplaceToaster from '../../util/marketplaceToaster'
import CreateGroup from './CreateGroup'
import util from '../../util/common'

const { Content } = Layout
const { Text } = Typography

const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const groupsAPI = process.env.REACT_APP_GROUPS_API
const usersAllAPI = process.env.REACT_APP_USERS_ALL_API
const userAPI = process.env.REACT_APP_USERS_API
const updateUserStatusAPI = process.env.REACT_APP_USER_STATUS_API
const currentUserDetailsAPI = process.env.REACT_APP_USER_PROFILE_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const UserAccessControl = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [serverDataCount, setServerDataCount] = useState()
    const [usersServerData, setUsersServerData] = useState([])
    const [groupServerData, setGroupServerData] = useState([])
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [groupId, setShowGroupId] = useState()
    const [groupName, setGroupName] = useState('')
    const [deleteModalLoading, setDeleteModalLoading] = useState(false)
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
    const [userName, setUserName] = useState()
    const [showUserEnableDisableModal, setShowUserEnableDisableModal] = useState(false)
    const [selectedUserData, setSelectedUserData] = useState({})
    const [currentUserDetailsAPIData, setCurrentUserDetailsAPIData] = useState()
    const [currentAPILoading, setCurrentAPILoading] = useState(false)
    usePageTitle(t('common:store_management_portal') + ' - ' + String(`${t('common:user_access_control')}`))
    //!json data displaying for tabs
    const mainTabData = [
        {
            key: 0,
            label: <p className=' !mb-0'>{t('users_roles:users')}</p>,
            value: 0,
        },
        {
            key: 1,
            label: (
                <p className={`!mb-0 ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : ''}`}>
                    {t('users_roles:roles')}
                </p>
            ),
            value: 1,
        },
    ]

    //! changing the tab
    const handleMainTabChange = (tabId) => {
        setIsLoading(true)
        setSearchParams({
            tab: tabId,
            page: 1,
            limit: itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }
    const getCurrentUserDetails = () => {
        setCurrentAPILoading(true)
        MarketplaceServices.findAll(currentUserDetailsAPI, null, false)
            .then((res) => {
                setCurrentAPILoading(false)
                console.log('get access token res', res)
                setCurrentUserDetailsAPIData(res.data.response_body)
            })
            .catch((err) => {
                setCurrentAPILoading(false)
                console.log('get access token err', err)
            })
    }

    useEffect(() => {
        window.scroll(0, 0)
        getCurrentUserDetails()
        setSearchParams({
            tab: searchParams.get('tab') ? searchParams.get('tab') : 0,
            page: searchParams.get('page') ? searchParams.get('page') : 1,
            limit: searchParams.get('limit') ? searchParams.get('limit') : itemsPerPageFromEnv,
            store: searchParams.get('store') ? searchParams.get('store') : MarketplaceAppConfig.getStore('onlyClient'),
        })
    }, [])

    //!user table columns
    const usersColumns = [
        {
            title: <Content className='text-regal-blue'>{t('users_roles:user_name')}</Content>,
            dataIndex: 'userName',
            key: 'userName',
            ellipsis: true,
            width: '17%',

            render: (text, record) => {
                return <Content className='text-brandTableText'>{record.username}</Content>
            },
        },

        {
            title: <Content className='text-regal-blue'>{t('users_roles:email')}</Content>,
            dataIndex: 'emailId',
            key: 'emailId',
            width: '25%',

            render: (text, record) => {
                return <Content className='text-brandTableText'>{record.email}</Content>
            },
        },
        {
            title: <Content className='text-regal-blue'>{t('users_roles:status')}</Content>,
            dataIndex: 'status',
            key: 'status',
            width: '10%',

            render: (text, record) => {
                return (
                    <Content>
                        <Switch
                            disabled={
                                currentUserDetailsAPIData?.preferred_username === record?.username ||
                                currentUserDetailsAPIData?.email === record?.email ||
                                record?.attributes?.is_default_owner[0] === 'True'
                                    ? true
                                    : false
                            }
                            className={record.enabled === true ? '!bg-orange-500' : '!bg-gray-400'}
                            checked={record.enabled}
                            onChange={() => openUserEnableDisableModal(record.enabled, record.username)}
                        />
                    </Content>
                )
            },
        },
        {
            title: <Content className='text-regal-blue'>{t('users_roles:role')}</Content>,
            dataIndex: 'role',
            key: 'role',
            ellipsis: true,
            width: '25%',

            render: (text, record) => {
                return (
                    <Content className='flex gap-2'>
                        <div className='text-brandTableText'>
                            {record.groups[0]?.name
                                ? String(record.groups[0]?.name).replaceAll('-', ' ')
                                : t('common:not_available')}
                        </div>
                        <div className=''>
                            {record.attributes?.is_default_owner[0] === 'True' ? (
                                <div className='bg-black text-white border px-1'>
                                    {t('users_roles:primary_account')}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </Content>
                )
            },
        },
        {
            title: <Content className='text-regal-blue'>{t('common:action')}</Content>,
            dataIndex: '',
            key: '',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className='flex items-center gap-2'>
                        {currentUserDetailsAPIData?.preferred_username === record?.username ||
                        currentAPILoading === true ||
                        record.attributes?.is_default_owner[0] === 'True' ||
                        currentUserDetailsAPIData?.email === record?.email ? (
                            <div className='text-[#cbd5e1] whitespace-nowrap  cursor-not-allowed'>
                                {t('common:delete')}
                            </div>
                        ) : (
                            <div className='text-[15px] font-medium text-brandPrimaryColor cursor-pointer'>
                                <Tooltip
                                    placement='bottom'
                                    title={String(`${t('common:delete')}`) + ' ' + String(`${record.username}`)}
                                    overlayStyle={{ zIndex: 1 }}
                                    onClick={() => openUserDeleteModal(record.username)}>
                                    {t('common:delete')}
                                </Tooltip>
                            </div>
                        )}
                        <Tooltip title={t('common:edit')} className='ml-2' placement='bottom'>
                            <div
                                className='text-[15px] font-medium text-brandPrimaryColor cursor-pointer'
                                onClick={() => {
                                    navigate(
                                        `/dashboard/user-access-control/edit-user?id=${record.id}&uname=${currentUserDetailsAPIData?.preferred_username}&default=${record.attributes?.is_default_owner[0]}&role=${record.groups[0]?.name}&${MarketplaceAppConfig.getStore(
                                            ''
                                        )}`
                                    )
                                }}>
                                {t('common:edit')}
                            </div>
                        </Tooltip>
                    </Content>
                )
            },
        },
    ]

    //! pagination handle change
    const handlePageNumberChange = (page, pageSize) => {
        setIsLoading(true)
        setSearchParams({
            tab: searchParams.get('tab'),
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }

    //! group table column
    const groupColumns = [
        {
            title: <Content className='text-regal-blue'>{t('users_roles:role_name')}</Content>,
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,

            render: (text, record) => {
                return <Content className='text-brandTableText'>{String(record.name).replaceAll('-', ' ')}</Content>
            },
        },
    ]

    //User Delete modal open function
    const openUserDeleteModal = (userName) => {
        setShowDeleteUserModal(true)
        setUserName(userName)
    }

    //User enable modal open function
    const openUserEnableDisableModal = (userStatus, userName) => {
        let dataObject = {
            status: userStatus,
            username: userName,
        }
        setSelectedUserData(dataObject)
        setShowUserEnableDisableModal(true)
    }

    //Delete call of group from server
    const removeGroup = () => {
        setDeleteModalLoading(true)
        MarketplaceServices.remove(groupsAPI, {
            group_name: groupId,
        })
            .then(function (response) {
                console.log('delete response of group', response)
                setDeleteModalLoading(false)
                MarketplaceToaster.showToast(response)
                findAllGroupLists()
                setShowDeleteModal(false)
            })
            .catch(function (error) {
                console.log('delete error response of group', error)
                MarketplaceToaster.showToast(error.response)
                setDeleteModalLoading(false)
            })
    }

    //Delete call of user frm server
    const removeUser = () => {
        setDeleteModalLoading(true)
        MarketplaceServices.remove(userAPI, {
            'user-name': userName,
        })
            .then(function (response) {
                console.log('delete response of user', response)
                setDeleteModalLoading(false)
                MarketplaceToaster.showToast(response)
                findAllUsersLists()
                setShowDeleteUserModal(false)
            })
            .catch(function (error) {
                console.log('delete error response of user', error)
                MarketplaceToaster.showToast(error.response)
                setDeleteModalLoading(false)
            })
    }

    //Enable ad disable user from server
    const enableDisableUserFromServer = () => {
        setDeleteModalLoading(true)
        MarketplaceServices.update(
            updateUserStatusAPI,
            {
                status: selectedUserData.status === false ? true : false,
            },
            { user_name: selectedUserData.username }
        )
            .then(function (response) {
                console.log('update server response of user enable', response)
                MarketplaceToaster.showToast(response)
                setDeleteModalLoading(false)
                setShowUserEnableDisableModal(false)
                var presentTab = searchParams.get('tab')
                var pageNumber = searchParams.get('page') ? searchParams.get('page') : 1
                var pageLimit = searchParams.get('limit') ? searchParams.get('limit') : itemsPerPageFromEnv

                setIsLoading(true)
                if (presentTab === '1') {
                    findAllGroupLists(parseInt(pageNumber), parseInt(pageLimit))
                } else {
                    findAllUsersLists(parseInt(pageNumber), parseInt(pageLimit))
                }
            })
            .catch((error) => {
                console.log('update server response of user enable')
                MarketplaceToaster.showToast(error.response)
                setDeleteModalLoading(false)
            })
    }

    //Get call of groups
    const findAllGroupLists = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(groupsAPI, null, pageNumber, pageLimit, true)

            .then(function (response) {
                console.log('groupList get call response-->', response.data.response_body)
                setServerDataCount(response.data.response_body && response.data.response_body.length)
                setGroupServerData(response.data.response_body)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('grouplist get error call response-->', error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    //Get call of users
    const findAllUsersLists = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(usersAllAPI, null, pageNumber, pageLimit, true)
            .then(function (response) {
                console.log('usersList get call response-->', response.data.response_body.users)
                setServerDataCount(response.data.response_body && response.data.response_body.count)

                setUsersServerData(response.data.response_body.users)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('usersList get error call response-->', error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    //useEffect to call get calls
    useEffect(() => {
        var presentTab = searchParams.get('tab')
        var pageNumber = searchParams.get('page') ? searchParams.get('page') : 1
        var pageLimit = searchParams.get('limit') ? searchParams.get('limit') : itemsPerPageFromEnv

        // setIsLoading(true)
        if (presentTab === '1') {
            findAllGroupLists(parseInt(pageNumber), parseInt(pageLimit))
        } else {
            findAllUsersLists(parseInt(pageNumber), parseInt(pageLimit))
        }
    }, [searchParams])

    return (
        <Content>
            <HeaderForTitle
                title={
                    // <Title level={3} className='!font-normal'>
                    //     {t('common:user_access_control')}
                    // </Title>
                    <Content>
                        <div className='flex flex-row justify-between items-center h-[40px]'>
                            <Text className='!font-semibold text-regal-blue text-2xl'>
                                {t('common:user_access_control')}
                            </Text>
                            {searchParams.get('tab') === '0' ? (
                                <Button
                                    className='app-btn-primary !h-8 hover:!h-8'
                                    onClick={() =>
                                        navigate(
                                            '/dashboard/user-access-control/add-user?' +
                                                MarketplaceAppConfig.getStore('')
                                        )
                                    }>
                                    {t('users_roles:add_user')}
                                </Button>
                            ) : null}
                        </div>
                        <p className='!font-normal !text-[#637381] mt-2'>{t('users_roles:note')}</p>
                    </Content>
                }
                headerContent={
                    <Content>
                        {/* <Paragraph className='!font-semibold !text-slate-400 !m-0'>{t('users_roles:note')}</Paragraph> */}

                        <Content className='!h-10 !mt-[120px] flex -mb-2'>
                            <Content className='!w-[80%] mt-1'>
                                <DmTabAntDesign
                                    tabData={mainTabData}
                                    tabBarPosition={'top'}
                                    defaultSelectedTabKey={'0'}
                                    activeKey={
                                        searchParams.get('tab') === '0'
                                            ? 0
                                            : searchParams.get('tab') === '1'
                                              ? 1
                                              : searchParams.get('tab') === '2'
                                                ? 2
                                                : 0
                                    }
                                    handleTabChangeFunction={handleMainTabChange}
                                    tabType='line'
                                />
                            </Content>

                            {/* <>
                                {searchParams.get('tab') === '0' ? (
                                    <Button
                                        className='app-btn-primary !h-8 hover:!h-8'
                                        onClick={() =>
                                            navigate(
                                                '/dashboard/user-access-control/add-user?' +
                                                    MarketplaceAppConfig.getStore('')
                                            )
                                        }>
                                        {t('users_roles:add_user')}
                                    </Button>
                                ) : null}
                            </> */}
                        </Content>
                    </Content>
                }
            />
            <Content className='p-3 '>
                {isLoading ? (
                    <Content className='bg-white rounded-lg'>
                        <Skeleton
                            active
                            paragraph={{
                                rows: 6,
                            }}
                            className='p-3'></Skeleton>
                    </Content>
                ) : isNetworkError ? (
                    <Content className='bg-white m-4 rounded-lg'>
                        <p className='p-4 text-center'>{t('common:network_error')}</p>
                    </Content>
                ) : (
                    <Content className='bg-white rounded-md shadow-brandShadow p-3 '>
                        <div className='mx-1 mb-3 text-base font-semibold text-regal-blue'>
                            {parseInt(searchParams.get('tab')) === 0
                                ? `${t('users_roles:users')}`
                                : `${t('users_roles:roles')}`}
                        </div>
                        {searchParams.get('tab') === '1' ? (
                            <Table dataSource={groupServerData} columns={groupColumns} pagination={false} />
                        ) : searchParams.get('tab') === '2' ? null : (
                            <Table dataSource={usersServerData} columns={usersColumns} pagination={false} />
                        )}

                        {serverDataCount > itemsPerPageFromEnv ? (
                            <Content className='!grid !justify-items-end'>
                                <DmPagination
                                    currentPage={
                                        parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                    }
                                    totalItemsCount={serverDataCount}
                                    defaultPageSize={itemsPerPageFromEnv}
                                    pageSize={
                                        parseInt(searchParams.get('limit'))
                                            ? parseInt(searchParams.get('limit'))
                                            : itemsPerPageFromEnv
                                    }
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showTotal={true}
                                    showQuickJumper={true}
                                />
                            </Content>
                        ) : null}
                    </Content>
                )}
            </Content>
            <StoreModal
                isVisible={showGroupModal}
                okButtonText={null}
                title={''}
                cancelButtonText={null}
                cancelCallback={() => setShowGroupModal(false)}
                width={700}
                isSpin={false}
                hideCloseButton={false}>
                <CreateGroup groupNameProps={groupName} setShowGroupModal={setShowGroupModal} />
            </StoreModal>
            <StoreModal
                isVisible={showDeleteModal}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={''}
                okCallback={() => removeGroup()}
                cancelCallback={() => setShowDeleteModal(false)}
                isSpin={deleteModalLoading}>
                {<div> {t('users_roles:are_you_sure_you_want_delete_the_group')}</div>}
            </StoreModal>
            <StoreModal
                isVisible={showDeleteUserModal}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={''}
                okCallback={() => removeUser()}
                cancelCallback={() => setShowDeleteUserModal(false)}
                isSpin={deleteModalLoading}>
                {<div> {t('users_roles:are_you_sure_you_want_delete_the_user')}</div>}
            </StoreModal>
            <StoreModal
                isVisible={showUserEnableDisableModal}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={''}
                okCallback={() => enableDisableUserFromServer()}
                cancelCallback={() => setShowUserEnableDisableModal(false)}
                isSpin={deleteModalLoading}>
                {
                    <div>
                        {selectedUserData.status === true ? (
                            <p>{t('users_roles:are_you_sure_you_want_disable_status')}</p>
                        ) : (
                            <p>{t('users_roles:are_you_sure_you_want_enable_status')}</p>
                        )}
                    </div>
                }
            </StoreModal>
        </Content>
    )
}

export default UserAccessControl
