//! Import libraries
import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import {
    Button,
    Col,
    Divider,
    Drawer,
    Input,
    Layout,
    Radio,
    Row,
    Select,
    Skeleton,
    Tag,
    Tooltip,
    Typography,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { TranslationOutlined } from '@ant-design/icons'

//! Import CSS libraries
import './productversionstatus.css'

//! Import user defined services
import { fnRevisionInfo, fnRevisionSelectedInfo } from './../../services/redux/actions/ActionsRevisionInfo'
//! Import user defined components
import StoreModal from '../../components/storeModal/StoreModal'
import RevisionStatus from '../../constants/RevisionStatus'
import CategoryProductRevisiontitle from './../../constants/CategoryProductRevisionStatus'
import TranslatorModal from '../product-category/TranslatorModal.js'

//! Import user defined functions
import { MdEdit } from 'react-icons/md'
import MarketplaceServices from '../../services/axios/MarketplaceServices.js'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getNextRevisionNumber, sortObjectArrayByKey } from '../../util/util'

//! Import user defined CSS

//! Get all required details from .env file
const productTemplateRevisionAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_API
const productTemplateRevisionStatusUpdateAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_STATUS_API
const approvalRequestAPI = process.env.REACT_APP_APPROVAL_REQUEST_API
const productTemplateRevisionPostAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_POST_API
const productTemplateCopyRevisionAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_COPY_REVISION_API
const productTemplateRevisionDisplayNameAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_DISPLAY_NAME_API
const productTypeRevisionStatusAPI = process.env.REACT_APP_PRODUCT_TYPE_REVISION_STATUS_API
const productTypeRevisionCreationAPI = process.env.REACT_APP_PRODUCT_TYPE_REVISION_CREATION_API
const productTypeCopyRevisionAPI = process.env.REACT_APP_PRODUCT_TYPE_COPY_REVISION_API
const productTypeRevisionDisplayNameAPI = process.env.REACT_APP_PRODUCT_TYPE_REVISION_DISPLAY_NAME_API
const productTypeUpdateRevisionAPI = process.env.REACT_APP_PRODUCT_TYPE_UPDATE_REVISION_API
const productTemplateRevisionUpdateAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_UPDATE_API
const productPublishStatusUpdateAPI = process.env.REACT_APP_PRODUCT_PUBLISHING_STATUS_UPDATE_API
const descriptionMaxLength = parseInt(process.env.REACT_APP_DESCRIPTION_MAX_LENGTH)

//! Destructure the components
const { Content } = Layout
const { Text, Title, Paragraph } = Typography
const { TextArea } = Input

const ProductVersionStatus = ({
    productTemplateRevisionSlotId,
    isProductTemplateServiceProduct,
    updatedTheProductTypeRevision,
    setUpdatedTheProductTypeRevision,
    type,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    let productTemplateId = searchParams.get('ptsid')
    let productTemplateRevisionId = searchParams.get('ptsrid')
    let product_id = searchParams.get('vrid')
    let version_status = searchParams.get('vrsts')
    let version_number = searchParams.get('vrno')
    let mode = searchParams.get('m')
    let product_type_revision_id = searchParams.get('ptrid')
    let approvalId = searchParams.get('apprid')
    const byDefaultLanguageCode = util.getUserSelectedLngCode()
    const pageCompAction = useSelector((state) => state.reducerPageComp.pageCompAction)

    const revisionInfo = useSelector((state) => state.reducerRevisionInfo.revisionInfo)

    const revisionSelectedInfo = useSelector((state) => state.reducerRevisionInfo.revisionSelectedInfo)
    const productTemplateSelectedInfo = useSelector((state) => state.reducerProducts.productTemplateSelectedInfo)
    const [rejectionReason, setRejectionReason] = useState()
    const [approvalReason, setApprovalReason] = useState('')
    const [isModalApproveVisible, setIsModalApproveVisible] = useState(false)
    const [isRejectUploading, setIsRejectUploading] = useState(false)
    const [isApprovalUploading, setIsApprovalUploading] = useState(false)
    const [isModalRejectVisible, setIsModalRejectVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isNetWorkErrorProductRevision, setIsNetWorkErrorProductRevision] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isRevisionNumberModalOpen, setIsRevisionNumberModalOpen] = useState(false)
    const [isModalUpdateRevision, setIsModalUpdateRevision] = useState(false)
    const [isOkModalOpen, setIsOkModalOpen] = useState(false)
    const [selectedRevisionId, setSelectedRevisionId] = useState()
    const [revisionStatusId, setRevisionStatusId] = useState()
    const [id, setId] = useState()
    const [submissionReason, setSubmissionReason] = useState()
    const [isModalApprovalRequestVisible, setIsModalApprovalRequestVisible] = useState(false)
    const [revisionId, setRevisionId] = useState()
    const [isUploading, setIsUploading] = useState(false)
    const [revisionTypeToggle, setRevisionTypeToggle] = useState('new_revision')
    const [revisionDescription, setRevisionDescription] = useState('')
    const [inValidRevisionDescription, setInValidRevisionDescription] = useState(false)
    const [selectedDropDownValue, setSelectedDropDownValue] = useState(
        `${t('product_types:select_existing_version_placeholder')}`
    )
    const [inValidSelectedDropDownValue, setInValidSelectedDropDownValue] = useState(false)
    const [inValidRevisionNumber, setInValidRevisionNumber] = useState(false)
    const [copyRevisionNumber, setCopyRevisionNumber] = useState('')
    const [copyDescription, setCopyDescription] = useState('')
    const [inValidCopyDescription, setInvalidCopyDescription] = useState(false)
    const [open, setOpen] = useState(false)
    const [tempRevisionNumber, setTempRevisionNumber] = useState()
    const [tempDescription, setTempDescription] = useState()
    const [tempStatus, setTempStatus] = useState()
    const [filterStatus, setFilterStatus] = useState([])
    const [nextStatusForProductPublishing, setNextStatusForProductPublishing] = useState()
    const [currentStatusDataForProductPublishing, setCurrentStatusDataForProductPublishing] = useState()
    const [integerCount, setIntegerCount] = useState(0)
    const [floatCount, setFloatCount] = useState(0)
    const [nextRevisionNumber, setNextRevisionNumber] = useState()
    const [updateRevisionDescription, setUpdateRevisionDescription] = useState('')
    const [updateRevisionNumber, setUpdateRevisionNumber] = useState()
    const [enableUpdateButton, setEnableUpdateButton] = useState(true)
    const [nextStatusName, setNextStatusName] = useState('')
    const [langDirection, setLangDirection] = useState('ltr')
    const [loadingSkelton, setLoadingSkelton] = useState(false)
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)
    const [versionDescriptionData, setVersionDescriptionData] = useState([])
    const [isMultilingualCallSuccess, setIsMultilingualCallSuccess] = useState(false)
    // const [searchParams, setSearchParams] = useSearchParams();
    // get permissions from storage
    const permissionValue = util.getPermissionData() || []
    //disable buttons based on permissions
    const [permissionDisabled, setpermissionDisabled] = useState(
        (permissionValue && permissionValue.includes('UI-create-edit-product-template')) ||
            (permissionValue && permissionValue.includes('UI-create-edit-producttype'))
            ? false
            : true
    )
    const [modalTitle, setModalTitle] = useState('')
    const toggleModal = () => {
        setIsModalOpen(true)
        getTitle('create')
    }
    const closeApproveModal = () => {
        setIsModalApproveVisible(false)
        setApprovalReason('')
        setSubmissionReason('')
    }
    const closeRejectModal = () => {
        setIsModalRejectVisible(false)
        setApprovalReason('')
        setRejectionReason('')
    }

    //! GET call for Product revision
    const getProductRevision = () => {
        MarketplaceServices.findAll(
            productTemplateRevisionAPI,
            {
                'product-template': parseInt(productTemplateId),
            },
            true
        )
            .then(function (response) {
                console.log(
                    'Server response from getProductRevision is',
                    productTemplateRevisionAPI,
                    response.data.response_body
                )
                let serverResponse = response.data.response_body.data
                serverResponse = sortObjectArrayByKey('id', 'number', serverResponse, 'desc')
                dispatch(fnRevisionInfo(serverResponse))
                dispatch(fnRevisionSelectedInfo(serverResponse[0]))
                setVersionDescriptionData(serverResponse[0].display_description)
                setIsLoading(false)
                setIsNetWorkErrorProductRevision(false)
            })
            .catch(function (error) {
                console.log('error Server response from getProductRevision is', productTemplateRevisionAPI, error)
                setIsNetWorkErrorProductRevision(true)
                setIsLoading(false)
            })
    }

    const validationEditProductType = () => {
        let count = 2
        if (updateRevisionNumber === '' && updateRevisionDescription === '') {
            count--
            setInValidRevisionNumber(true)
            setInValidRevisionDescription(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
            )
        } else if (updateRevisionNumber === '') {
            count--
            setInValidRevisionNumber(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
            )
        } else if (updateRevisionDescription === '') {
            setInValidRevisionDescription(true)

            count--
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
            )
        }
        if (count === 2) {
            productTypeRevisionUpdate()
        }
    }
    //! API PUT call for updating revision status
    const updateRevisionStatus = () => {
        setIsUploading(true)
        let updateRevisionStatusAPI = ''
        let updateRevisionStatusBody = ''
        let putSuccessMsg = ''
        let putErrorMsg = ''
        if (parseInt(mode) === 1) {
            updateRevisionStatusAPI = productPublishStatusUpdateAPI.replace('{product_id}', String(product_id))
            updateRevisionStatusBody = {
                product_status: parseInt(revisionStatusId),
            }
            // console.log("body", updateRevisionStatusBody);
            putSuccessMsg = `${t('product_types:status_updated_to_unpublished')}`
            putErrorMsg = `${t('product_types:unable_to_update_product_publish_revision')}`
        } else if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            updateRevisionStatusAPI = productTypeRevisionStatusAPI.replace(
                '{revision_id}',
                String(revisionSelectedInfo.id)
            )
            updateRevisionStatusBody = {
                status: String(revisionStatusId),
            }
            putSuccessMsg = `${t('product_types:version_status_updated_successfully')}`
            putErrorMsg = `${t('product_types:unable_to_update_product_type_version')}`
        } else {
            updateRevisionStatusAPI = productTemplateRevisionStatusUpdateAPI.replace(
                '{revision_id}',
                String(selectedRevisionId)
            )
            updateRevisionStatusBody = {
                product_revision_status: revisionStatusId,
            }
        }

        MarketplaceServices.update(updateRevisionStatusAPI, updateRevisionStatusBody, null)
            .then(function (response) {
                console.log('Server response from updateRevisionStatus is', updateRevisionStatusAPI, response.data)

                if (parseInt(mode) === 1) {
                    setCurrentStatusDataForProductPublishing([
                        {
                            id: 5,
                            title: 'Unpublished',
                            status: true,
                        },
                    ])
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: 5,
                        vrid: product_id,
                        vrno: version_number,
                        m: 1,
                        a: 0,
                        ptrid: product_type_revision_id,
                    })
                } else if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                    let newRevisionInfo = revisionInfo.findIndex((obj) => obj.id === revisionSelectedInfo.id)
                    revisionInfo[newRevisionInfo].status = revisionStatusId
                    dispatch(fnRevisionInfo(revisionInfo))
                    const newObject = {
                        ...revisionSelectedInfo,
                        status: revisionStatusId,
                    }
                    dispatch(fnRevisionSelectedInfo(newObject))
                    setTempStatus(filterStatus[0])
                } else {
                    let newRevisionInfo = revisionInfo.findIndex((obj) => obj.id === revisionSelectedInfo.id)
                    revisionInfo[newRevisionInfo].product_revision_status = revisionStatusId
                    dispatch(fnRevisionInfo(revisionInfo))
                    const newObject = {
                        ...revisionSelectedInfo,
                        product_revision_status: revisionStatusId,
                    }
                    dispatch(fnRevisionSelectedInfo(newObject))
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: `${newObject.product_template}`,
                        ptsrid: `${newObject.id}`,
                        ptss: `${newObject.product_revision_status}`,
                    })
                    setFilterStatus(getStatus(revisionStatusId))
                    let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === revisionStatusId)
                    setTempStatus(filterStatus[0])
                    getProductRevision()
                }
                if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                    setUpdatedTheProductTypeRevision(true)
                }

                MarketplaceToaster.showToast(response)
                setIsUploading(false)
                setIsOkModalOpen(false)
            })
            .catch((error) => {
                console.log(
                    'Error Server response from updateRevisionStatus is',
                    updateRevisionStatusAPI,
                    error.response
                )
                MarketplaceToaster.showToast(error.response)
                setIsUploading(false)
            })
    }

    //! API Post call for ApprovalRequest
    const postApprovalRequest = () => {
        setIsUploading(true)
        const postBodyObject = {
            type_choice:
                pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                    ? 'storeproduct_type_revision'
                    : 'product_revision',
            revision_id: [revisionId],
            revision_status: selectedRevisionId,
            submission_reason: submissionReason,
        }
        MarketplaceServices.save(approvalRequestAPI, postBodyObject)
            .then(function (response) {
                console.log('Server response from postApprovalRequest is', response.data.revision_status)
                setFilterStatus(getStatus(response.data.response_body.revision_status))
                let filterStatus = CategoryProductRevisiontitle.filter(
                    (e) => e.id === response.data.response_body.revision_status
                )
                setTempStatus(filterStatus[0])
                if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                    let revisionInfoData = revisionInfo.filter(
                        (element) => element.id !== response.data.response_body.revision_id
                    )

                    let revisionObject = {
                        id: response.data.response_body.id,
                        status: response.data.response_body.revision_status,
                        revision_number: response.data.response_body.revision_number,
                        display_description: response.data.response_body.revision_desc,
                        description: response.data.response_body.revision_desc,
                    }
                    revisionInfoData = revisionInfoData.concat(revisionObject)
                    const revisionData = sortObjectArrayByKey('id', 'number', revisionInfoData, 'desc')
                    dispatch(fnRevisionSelectedInfo(revisionObject))
                    dispatch(fnRevisionInfo(revisionData))
                } else {
                    let revisionInfoData = revisionInfo.filter(
                        (element) => element.id !== response.data.response_body.revision_id
                    )

                    let revisionObject = {
                        id: response.data.response_body.id,
                        product_revision_status: response.data.response_body.revision_status,
                        revision_number: response.data.response_body.revision_number,
                        description: response.data.response_body.revision_desc,
                    }
                    revisionInfoData = revisionInfoData.concat(revisionObject)
                    const revisionData = sortObjectArrayByKey('id', 'number', revisionInfoData, 'desc')
                    dispatch(fnRevisionSelectedInfo(revisionObject))
                    dispatch(fnRevisionInfo(revisionData))
                }
                MarketplaceToaster.showToast(response)
                setIsUploading(false)
                setIsModalApprovalRequestVisible(false)
                setSubmissionReason('')
            })
            .catch(function (error) {
                console.log('Server error response from postApprovalRequest is', error.response)
                MarketplaceToaster.showToast(error.response)
                setIsUploading(false)
                setIsModalApprovalRequestVisible(false)
                setSubmissionReason('')
            })
    }
    const updateStatusOfProductsForAwaitingApprovals = (status, reason) => {
        setIsApprovalUploading(true)
        setIsRejectUploading(true)
        let dataObject = {
            revision_id: parseInt(revisionSelectedInfo && revisionSelectedInfo.id),
            revision_status: status,
            type_choice: 'product_revision',
            approval_reason: reason,
        }
        MarketplaceServices.update(approvalRequestAPI, dataObject, {
            _id: parseInt(productTemplateSelectedInfo?.length > 0 && productTemplateSelectedInfo[0].approvalId),
        })
            .then(function (response) {
                console.log(
                    'Server response from updateStatusOfProductsForAwaitingApprovals is',
                    approvalRequestAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)
                setIsOkModalOpen(false)
                setIsUploading(false)
                getProductRevision()
                closeApproveModal()
                closeRejectModal()
                setRejectionReason()
                setApprovalReason()
                setIsApprovalUploading(false)
                setIsRejectUploading(false)
            })
            .catch((error) => {
                console.log('error Server Response from updateStatusOfProductsForAwaitingApprovals', error.response)
                setIsApprovalUploading(false)
                setIsOkModalOpen(false)
                setIsUploading(false)
                MarketplaceToaster.showToast(error.response)
                closeApproveModal()
                closeRejectModal()
                setRejectionReason()
                setApprovalReason()
                setIsApprovalUploading(false)
                setIsRejectUploading(false)
            })
    }

    //!API POST call for creating revision
    const creatingNewRevisionPostCall = () => {
        setIsUploading(true)
        let createdRevisionAPI = ''
        let createdRevisionStatusBody = ''
        let postSuccessMsg = ''
        let postErrorMsg = ''

        if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            createdRevisionAPI = productTypeRevisionCreationAPI.replace(
                '{store_product_type}',
                String(revisionSelectedInfo.store_product_type_id)
            )
            createdRevisionStatusBody = {
                revision_number: parseFloat(nextRevisionNumber),
                description: revisionDescription,
                store_product_type: revisionSelectedInfo.store_product_type_id,
            }
            postSuccessMsg = `${t('product_types:product_type_version_created_successfully')}`
            postErrorMsg = `${t('product_types:unable_to_create_product_type_version')}`
        } else {
            createdRevisionAPI = productTemplateRevisionPostAPI.replace('{product_template}', String(productTemplateId))
            let productTemplatesStoreProductTypes =
                productTemplateSelectedInfo &&
                productTemplateSelectedInfo.length > 0 &&
                productTemplateSelectedInfo[0].storeProductType

            let temp = []
            if (productTemplatesStoreProductTypes && productTemplatesStoreProductTypes.length > 0) {
                for (var i = 0; i < productTemplatesStoreProductTypes.length; i++) {
                    let sample = {}
                    sample['store_product_type'] = productTemplatesStoreProductTypes[i].store_product_type_id
                    sample['store_product_type_revision'] =
                        productTemplatesStoreProductTypes[i].store_product_type_revision_id
                    temp.push(sample)
                }
            }
            createdRevisionStatusBody = {
                revision_number: parseFloat(nextRevisionNumber),
                description: revisionDescription,
                product_template: parseInt(productTemplateId),
                store_product_type: temp,
            }
            console.log('createdRevisionStatusBody', createdRevisionStatusBody)
        }
        MarketplaceServices.save(createdRevisionAPI, createdRevisionStatusBody)
            .then(function (response) {
                console.log(
                    'Server response for creatingNewRevisionPostCall',
                    createdRevisionAPI,
                    response.data.response_body
                )
                createMultilingualDetails(response.data.response_body)
                if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                    setTempRevisionNumber(response.data.response_body[0].revision_number)
                    setTempDescription(response.data.response_body[0].description)
                    setUpdatedTheProductTypeRevision(true)

                    let filterStatus = CategoryProductRevisiontitle.filter(
                        (e) => e.id === parseInt(response.data.response_body[0].status)
                    )
                    setTempStatus(filterStatus[0])
                    setFilterStatus(getStatus(response.data.response_body[0].status))
                    setRevisionId(response.data.response_body[0].id)
                    setSelectedRevisionId(response.data.response_body[0].id)
                    let oldData = [...revisionInfo]
                    oldData = oldData.concat(response.data.response_body)
                    const revisionData = sortObjectArrayByKey('id', 'number', oldData, 'desc')
                    dispatch(fnRevisionInfo(revisionData))
                    dispatch(fnRevisionSelectedInfo(revisionData[0]))
                } else {
                    setTempRevisionNumber(response.data.response_body[0].revision_number)
                    setTempDescription(response.data.response_body[0].description)

                    let filterStatus = CategoryProductRevisiontitle.filter(
                        (e) => e.id === response.data.response_body[0].product_revision_status
                    )
                    setTempStatus(filterStatus[0])
                    setFilterStatus(getStatus(response.data.response_body[0].product_revision_status))
                    setRevisionId(response.data.response_body[0].id)
                    setSelectedRevisionId(response.data.response_body[0].id)
                    let oldData = [...revisionInfo]
                    oldData = oldData.concat(response.data.response_body)
                    const revisionData = sortObjectArrayByKey('id', 'number', oldData, 'desc')
                    dispatch(fnRevisionInfo(revisionData))
                    dispatch(fnRevisionSelectedInfo(revisionData[0]))
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: `${response.data.response_body[0].product_template}`,
                        ptsrid: `${response.data.response_body[0].id}`,
                        ptss: `${response.data.response_body[0].product_revision_status}`,
                    })
                    getProductRevision()
                }
                MarketplaceToaster.showToast(response)
                setIsUploading(false)
                clearAllField()
                setIsUploading(false)
                setIsModalOpen(false)
            })
            .catch((error) => {
                console.log('Error catch block from create Revision POST call', error)
                console.log(
                    'error Server response from  creatingNewRevisionPostCall is',
                    createdRevisionAPI,
                    error.response
                )
                MarketplaceToaster.showToast(error.response)
                setIsUploading(false)
            })
    }

    //! API POST call for creating copy revision
    const createCopyRevisionPostCall = () => {
        setIsUploading(true)
        let createdCopyRevisionAPI = ''
        let createdCopyRevisionStatusBody = ''
        let copySuccessMsg = ''
        let copyErrorMsg = ''

        if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            createdCopyRevisionAPI = productTypeCopyRevisionAPI.replace(
                '{revision_id}',
                String(revisionSelectedInfo.id)
            )
            if (nextRevisionNumber === null) {
                createdCopyRevisionStatusBody = {
                    description: copyDescription,
                    revision_id: revisionSelectedInfo.id,
                }
            } else {
                createdCopyRevisionStatusBody = {
                    revision_number: parseFloat(nextRevisionNumber),
                    description: copyDescription,
                    revision_id: revisionSelectedInfo.id,
                }
            }

            copySuccessMsg = `${t('product_types:product_type_version_created_successfully')}`
            copyErrorMsg = `${t('product_types:unable_to_create_product_type_version')}`
        } else {
            createdCopyRevisionAPI = productTemplateCopyRevisionAPI.replace(
                '{revision_id}',
                String(revisionSelectedInfo && revisionSelectedInfo.id)
            )
            createdCopyRevisionStatusBody = {
                revision_number: parseFloat(nextRevisionNumber),
                description: copyDescription,
            }
            console.log('createdCopyRevisionStatusBody', createdCopyRevisionStatusBody)
        }
        MarketplaceServices.save(createdCopyRevisionAPI, createdCopyRevisionStatusBody)
            .then(function (response) {
                console.log(
                    'Server response from createCopyRevisionPostCall is',
                    createdCopyRevisionAPI,
                    response.data.response_body
                )
                if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                    setTempRevisionNumber(response.data.response_body[0].revision_number)
                    setTempDescription(response.data.response_body[0].description)
                    setUpdatedTheProductTypeRevision(true)
                    let filterStatus = CategoryProductRevisiontitle.filter(
                        (e) => e.id === parseInt(response.data.response_body[0].status)
                    )
                    setTempStatus(filterStatus[0])
                    setFilterStatus(getStatus(response.data.response_body[0].status))
                    setRevisionId(response.data.response_body[0].id)
                    setSelectedRevisionId(response.data.response_body[0].id)
                    let oldData = [...revisionInfo]
                    oldData = oldData.concat(response.data.response_body)
                    const revisionData = sortObjectArrayByKey('id', 'number', oldData, 'desc')
                    dispatch(fnRevisionInfo(revisionData))
                    dispatch(fnRevisionSelectedInfo(revisionData[0]))
                } else {
                    setTempRevisionNumber(response.data.response_body[0].revision_number)
                    setTempDescription(response.data.response_body[0].description)

                    let filterStatus = CategoryProductRevisiontitle.filter(
                        (e) => e.id === response.data.response_body[0].product_revision_status
                    )
                    setTempStatus(filterStatus[0])
                    setFilterStatus(getStatus(response.data.response_body[0].product_revision_status))
                    setRevisionId(response.data.response_body[0].id)
                    setSelectedRevisionId(response.data.response_body[0].id)
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: `${response.data.response_body[0].product_template}`,
                        ptsrid: `${response.data.response_body[0].id}`,
                        ptss: `${response.data.response_body[0].product_revision_status}`,
                    })
                }

                MarketplaceToaster.showToast(response)
                let oldData = [...revisionInfo]
                oldData = oldData.concat(response.data.response_body)
                const revisionData = sortObjectArrayByKey('id', 'number', oldData, 'desc')
                dispatch(fnRevisionInfo(revisionData))
                dispatch(fnRevisionSelectedInfo(revisionData[0]))
                clearAllField()
                setIsModalOpen(false)
                setIsUploading(false)
                getProductRevision()
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log(
                    'error response from createCopyRevisionPostCall API is',
                    createdCopyRevisionAPI,
                    error.response
                )
                setIsUploading(false)
            })
    }

    const createMultilingualDetails = (serverResponse) => {
        let postBody = ''
        let apiEndpoint = ''
        if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            apiEndpoint = productTypeRevisionDisplayNameAPI
            postBody = {
                store_producttype_revision_displayname: [
                    {
                        store_product_type_revision: revisionSelectedInfo.id,
                        language_code: String(byDefaultLanguageCode),
                        description: revisionDescription,
                    },
                ],
            }
            postMultilingualDetails(postBody, apiEndpoint)
        } else {
            apiEndpoint = productTemplateRevisionDisplayNameAPI
        }
    }

    const postMultilingualDetails = (postBody, apiEndpoint) => {
        console.log('post body for ---', apiEndpoint, ' is:', postBody)
        MarketplaceServices.save(apiEndpoint, postBody)
            .then(function (response) {
                console.log('Server Response from postMultilingualDetails is', apiEndpoint, response.data)
            })
            .catch(function (error) {
                console.log(' error Server Response from postMultilingualDetails is', apiEndpoint, error.response)
                MarketplaceServices.showToast(error.response)
            })
    }
    //! PUT call for Update ProductType & product template description,revision num
    const productTypeRevisionUpdate = () => {
        setIsUploading(true)
        let updateRevisionStatusAPI = ''
        let updateRevisionStatusBody = ''
        let putSuccessMsg = ''
        let putErrorMsg = ''
        if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            updateRevisionStatusAPI = productTypeUpdateRevisionAPI.replace(
                '{revision_id}',
                String(revisionSelectedInfo.id)
            )
            updateRevisionStatusBody = {
                status: String(revisionSelectedInfo.status),
                revision_number: parseFloat(updateRevisionNumber),
                description: updateRevisionDescription,
                store_product_type: revisionSelectedInfo.store_product_type_id,
            }
            console.log('updateRevisionStatusBody', updateRevisionStatusBody)
            putSuccessMsg = `${t('product_types:version_status_updated_successfully')}`
            putErrorMsg = `${t('product_types:unable_to_update_product_type_version')}`
        } else {
            updateRevisionStatusAPI = productTemplateRevisionUpdateAPI.replace(
                '{product_template_revision_id}',
                String(revisionSelectedInfo && revisionSelectedInfo.id)
            )

            updateRevisionStatusBody = {
                description: updateRevisionDescription,
            }
        }
        MarketplaceServices.update(updateRevisionStatusAPI, updateRevisionStatusBody)
            .then(function (response) {
                console.log(
                    'Server response from productTypeRevisionUpdate',
                    updateRevisionStatusAPI,
                    response.data.response_body
                )
                if (response.status === 200) {
                    if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                        let newData = response.data.response_body
                        newData['store_product_type_id'] = revisionSelectedInfo.store_product_type_id
                        let oldData = [...revisionInfo]
                        let temp = oldData.filter((ele) => ele.id !== revisionSelectedInfo.id)

                        temp = temp.concat(newData)
                        const revisionData = sortObjectArrayByKey('id', 'number', temp, 'desc')
                        dispatch(fnRevisionInfo(revisionData))
                        dispatch(fnRevisionSelectedInfo(newData))
                        setUpdatedTheProductTypeRevision(true)

                        MarketplaceToaster.showToast(response)
                    } else {
                        console.log('updateRevisionNumber', updateRevisionNumber)
                        let newData = response.data.response_body
                        newData['store_product_type'] = revisionSelectedInfo.store_product_type
                        newData['revision_number'] = updateRevisionNumber
                        newData['product_revision_status'] = 1
                        newData['description'] = updateRevisionDescription
                        let oldData = [...revisionInfo]
                        let temp = oldData.filter((ele) => ele.id !== revisionSelectedInfo.id)

                        temp = temp.concat(newData)
                        const revisionData = sortObjectArrayByKey('id', 'number', temp, 'desc')
                        dispatch(fnRevisionInfo(revisionData))
                        dispatch(fnRevisionSelectedInfo(newData))
                    }

                    MarketplaceToaster.showToast(response)
                    setIsUploading(false)
                }
                setEnableUpdateButton(true)
                setIsModalUpdateRevision(false)
            })
            .catch((error) => {
                console.log(
                    'Error Server response from productTypeRevisionUpdate',
                    updateRevisionStatusAPI,
                    error.response
                )
                MarketplaceToaster.showToast(error.response)
                setIsUploading(false)
            })
    }
    // ! this useEffect for calling product template GET call
    useEffect(() => {
        if (pageCompAction && pageCompAction.type.toUpperCase() !== 'PRODUCT_TYPE') {
            getProductRevision()
        }
    }, [pageCompAction?.type])

    // useEffect(()=>{
    //     const fullURL = window.location.href;
    //     if (fullURL.includes("edit-product-template")) {
    //         getProductRevision()

    //     }
    // })

    //! this useEffect for revision info
    useEffect(() => {
        setRevisionTypeToggle('new_revision')
        if (revisionInfo !== undefined) {
            if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                setTempRevisionNumber(revisionInfo[0].revision_number)
                setTempDescription(revisionInfo[0].description)

                let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === parseInt(revisionInfo[0].status))
                setTempStatus(filterStatus[0])
                setFilterStatus(getStatus(revisionInfo[0].status))
                setRevisionId(revisionInfo[0].id)
                setSelectedRevisionId(revisionInfo[0].id)
                setIsLoading(false)
            } else {
                setTempRevisionNumber(revisionInfo[0].revision_number)
                setTempDescription(revisionInfo[0].description)

                let filterStatus = CategoryProductRevisiontitle.filter(
                    (e) => e.id === revisionInfo[0].product_revision_status
                )
                setTempStatus(filterStatus[0])
                setFilterStatus(getStatus(revisionInfo[0].product_revision_status))
                setRevisionId(revisionInfo[0].id)
                setSelectedRevisionId(revisionInfo[0].id)
            }

            let currentRevisionNumber = revisionInfo[0].revision_number
            setNextRevisionNumber(getNextRevisionNumber(currentRevisionNumber))
            setUpdateRevisionNumber(getNextRevisionNumber(currentRevisionNumber))
        }
    }, [revisionInfo])

    //! this useEffect for revision selected info
    useEffect(() => {
        if (revisionSelectedInfo !== undefined) {
            if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
                setTempRevisionNumber(revisionSelectedInfo.revision_number)
                setTempDescription(revisionSelectedInfo.description)
                let filterStatus = CategoryProductRevisiontitle.filter(
                    (e) => e.id === parseInt(revisionSelectedInfo.status)
                )
                setTempStatus(filterStatus[0])
                setFilterStatus(getStatus(revisionSelectedInfo.status))
                setRevisionId(revisionSelectedInfo.id)
                setSelectedRevisionId(revisionSelectedInfo.id)
                setIsLoading(false)
            } else {
                setTempRevisionNumber(revisionSelectedInfo.revision_number)
                setTempDescription(revisionSelectedInfo.description)
                let filterStatus = CategoryProductRevisiontitle.filter(
                    (e) => e.id === revisionSelectedInfo.product_revision_status
                )
                setTempStatus(filterStatus[0])
                setFilterStatus(getStatus(revisionSelectedInfo.product_revision_status))
                setRevisionId(revisionSelectedInfo.id)
                setSelectedRevisionId(revisionSelectedInfo.id)
            }
        }
    }, [revisionSelectedInfo])

    useEffect(() => {
        if (version_status && version_status === 5) {
            setNextStatusForProductPublishing(getStatus(version_status))
            setCurrentStatusDataForProductPublishing([
                {
                    id: 5,
                    title: 'Unpublished',
                    status: true,
                },
            ])
        } else {
            var filterProductPublishingStatus = CategoryProductRevisiontitle.filter(
                (e) => e.id === parseInt(version_status)
            )
            if (version_status && version_status === 3) {
                setCurrentStatusDataForProductPublishing([
                    {
                        id: 3,
                        title: 'Published',
                        status: true,
                    },
                ])
            } else {
                setCurrentStatusDataForProductPublishing(filterProductPublishingStatus)
            }
            setNextStatusForProductPublishing(getStatus(version_status))
        }
    }, [version_status])
    //! This useEffect for clearing all field
    useEffect(() => {
        clearAllField()
    }, [isModalOpen, revisionTypeToggle, isModalUpdateRevision])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    // The useffect will be triggered when the multilingual post call is success
    useEffect(() => {
        if (isMultilingualCallSuccess) {
            if (pageCompAction && pageCompAction.type.toUpperCase() !== 'PRODUCT_TYPE') {
                getProductRevision()
            }
        }
    }, [isMultilingualCallSuccess])

    const handleVersionChange = (revisionId) => {
        setOpen(false)
        const selectedRevisionDetails = revisionInfo.find((element) => element.id === revisionId)
        if (pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE') {
            setTempRevisionNumber(selectedRevisionDetails.revision_number)
            setTempDescription(selectedRevisionDetails.description)

            let filterStatus = CategoryProductRevisiontitle.filter(
                (e) => e.id === parseInt(selectedRevisionDetails.status)
            )
            setTempStatus(filterStatus[0])
        } else {
            setTempRevisionNumber(selectedRevisionDetails.revision_number)
            setTempDescription(selectedRevisionDetails.description)
            let filterStatus = CategoryProductRevisiontitle.filter(
                (e) => e.id === parseInt(selectedRevisionDetails.product_revision_status)
            )
            setTempStatus(filterStatus[0])
        }

        dispatch(fnRevisionSelectedInfo(selectedRevisionDetails))
    }

    const getNextAllowedTypeStatus = (currentTypeStatus) => {
        let allowedStatus = []

        if (parseInt(currentTypeStatus) === 1) {
            allowedStatus = allowedStatus.concat(RevisionStatus[7])
        } else if (parseInt(currentTypeStatus) === 2) {
            allowedStatus = allowedStatus.concat(RevisionStatus[2])
        } else if (parseInt(currentTypeStatus) === 3) {
            allowedStatus = allowedStatus.concat(RevisionStatus[3])
        } else if (parseInt(currentTypeStatus) === 4) {
            allowedStatus = allowedStatus.concat(RevisionStatus[4])
        } else if (parseInt(currentTypeStatus) === 5) {
            allowedStatus = allowedStatus.concat(RevisionStatus[5])
        } else if (parseInt(currentTypeStatus) === 6) {
            allowedStatus = allowedStatus.concat(RevisionStatus[6])
        }
        return allowedStatus
    }

    const showModal = (revisionStatusIdParams, Id, buttonName) => {
        setIsOkModalOpen(true)
        setRevisionStatusId(revisionStatusIdParams)

        if (parseInt(revisionStatusIdParams) === 1) {
            setNextStatusName(t('common:draft'))
        } else if (parseInt(revisionStatusIdParams) === 3) {
            setNextStatusName(t('common:approve'))
        } else if (parseInt(revisionStatusIdParams) === 4) {
            setNextStatusName(t('common:reject'))
        } else if (parseInt(revisionStatusIdParams) === 5) {
            setNextStatusName(t('common:deprecate'))
        } else if (parseInt(revisionStatusIdParams) === 6) {
            setNextStatusName(t('common:retire'))
        }
    }

    const modalOk = () => {
        if (approvalId && parseInt(revisionStatusId) === 1) {
            setIsUploading(true)
            updateStatusOfProductsForAwaitingApprovals(1, '')
        } else {
            updateRevisionStatus()
        }
    }

    const closeRequestApprovalModal = () => {
        setIsModalApprovalRequestVisible(false)
        setIsOkModalOpen(false)
        setSubmissionReason('')
    }

    //! here using onClick opening modals
    const handleVersionStatusAction = (revisionStatus, id, buttonName) => {
        switch (revisionStatus) {
            case 1:
                showModal(revisionStatus, id, buttonName)
                break
            case 2:
                setIsModalApprovalRequestVisible(true)
                setSelectedRevisionId(revisionStatus)
                break
            case 3:
                showModal(revisionStatus, id, buttonName)
                break
            case 4:
                showModal(revisionStatus, id, buttonName)
                break
            case 5:
                showModal(revisionStatus, id, buttonName)
                break
            case 6:
                showModal(revisionStatus, id, buttonName)
                break
            default:
                break
        }
    }
    //! title button for modals
    const optionsForRevisionType = [
        {
            label: `${t('product_types:create_version')}`,
            value: 'new_revision',
        },
        {
            label: `${t('product_types:copy_from_existing_version')}`,
            value: 'copy_revision',
        },
    ]

    const onChangeRevisionType = ({ target: { value } }) => {
        setRevisionTypeToggle(value)
    }

    const handleRevisionNumber = (e) => {
        setInValidRevisionNumber(false)
        setUpdateRevisionNumber(e.target.value)
    }

    const handleDescriptionChange = (e) => {
        setInValidRevisionDescription(false)
        setTempDescription(e.target.value)
        setRevisionDescription(e.target.value)
        setUpdateRevisionDescription(e.target.value)
        setEnableUpdateButton(false)
    }
    const handleDropdownSelectedValue = (value) => {
        setSelectedDropDownValue(value)
        setInValidSelectedDropDownValue(false)
    }

    const handleCopyRevisionNumber = (e) => {
        setCopyRevisionNumber(e.target.value)
    }

    const handleCopyDescriptionChange = (e) => {
        setCopyDescription(e.target.value)
        setInvalidCopyDescription(false)
    }

    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const clearAllField = () => {
        if (revisionTypeToggle === 'new_revision') {
            setRevisionDescription('')
            setInValidRevisionDescription(false)
            setInValidRevisionNumber(false)
        } else if (revisionTypeToggle === 'copy_revision') {
            setCopyDescription('')
            setCopyRevisionNumber('')
            setInvalidCopyDescription(false)
            setInValidSelectedDropDownValue(false)
            setSelectedDropDownValue(`${t('product_types:select_existing_version_placeholder')}`)
        }
    }
    const validateFieldForRevisions = () => {
        if (revisionTypeToggle === 'new_revision') {
            let validValues = 2

            if (nextRevisionNumber === '' && revisionDescription === '') {
                setInValidRevisionNumber(true)
                setInValidRevisionDescription(true)
                validValues -= 1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }
            if (revisionDescription !== '' && nextRevisionNumber === '' && nextRevisionNumber < 1) {
                setInValidRevisionNumber(true)
                validValues -= 1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }
            if (revisionDescription === '' && nextRevisionNumber !== '') {
                setInValidRevisionDescription(true)
                validValues -= 1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }
            if (nextRevisionNumber < 1 && revisionDescription !== '' && nextRevisionNumber !== '') {
                setInValidRevisionNumber(true)

                validValues -= 1

                MarketplaceToaster.showToast(util.getToastObject(`${t('product_types:Revision-Number')}`, 'error'))
            }

            if (validValues === 2) {
                creatingNewRevisionPostCall()
            }
        } else {
            let validColumn = 2

            if (
                copyDescription === '' &&
                selectedDropDownValue === `${t('product_types:select_existing_version_placeholder')}`
            ) {
                setInvalidCopyDescription(true)
                setInValidSelectedDropDownValue(true)
                validColumn = -1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }
            if (
                selectedDropDownValue === `${t('product_types:select_existing_version_placeholder')}` &&
                copyDescription !== ''
            ) {
                setInValidSelectedDropDownValue(true)
                validColumn -= 1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }
            if (
                selectedDropDownValue !== `${t('product_types:select_existing_version_placeholder')}` &&
                copyDescription === ''
            ) {
                setInvalidCopyDescription(true)
                validColumn -= 1
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:please_enter_the_mandatory_fields')}`, 'error')
                )
            }

            if (validColumn === 2) {
                createCopyRevisionPostCall()
            }
        }
    }

    //! filtering status
    const getStatus = (currentStatus) => {
        let status = []
        if (
            parseInt(currentStatus) === 1 &&
            permissionValue &&
            permissionValue.length > 0 &&
            permissionValue.includes('UI-list-approvals')
        ) {
            let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === 3)
            status = status.concat(filterStatus)
        } else if (parseInt(currentStatus) === 2) {
            let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === 3)
            status = status.concat(filterStatus)
        } else if (parseInt(currentStatus) === 3) {
            if (parseInt(mode) === 1) {
                status = status.concat({
                    id: 5,
                    title: 'Unpublished',
                    status: true,
                })
            } else {
                let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === 5)
                status = status.concat(filterStatus)
            }
        } else if (parseInt(currentStatus) === 5) {
            if (parseInt(mode) === 1) {
                status = status.concat({
                    id: 6,
                    title: 'Unpublished',
                    status: true,
                })
            } else {
                let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === 6)
                status = status.concat(filterStatus)
            }
        } else if (parseInt(currentStatus) === 4) {
            let filterStatus = CategoryProductRevisiontitle.filter((e) => e.id === 1)
            status = status.concat(filterStatus)
        } else if (parseInt(currentStatus) === 6) {
            status = status.concat(0)
        }
        return status
    }
    const text = (
        <Content className=''>
            {filterStatus &&
                filterStatus.length > 0 &&
                filterStatus.map((ele) => {
                    return (
                        <Row className='p-2' key={ele.id}>
                            <Col className='flex items-center !gap-[6px]'>
                                <Text className='!text-white'>
                                    {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                        ? revisionSelectedInfo && revisionSelectedInfo?.status_display_name
                                            ? revisionSelectedInfo?.status_display_name
                                            : t('common:not_available')
                                        : revisionSelectedInfo &&
                                            revisionSelectedInfo?.product_revision_status_display_name
                                          ? revisionSelectedInfo?.product_revision_status_display_name
                                          : t('common:not_available')}
                                </Text>
                                {langDirection === 'rtl' ? (
                                    <ArrowLeftOutlined className='!text-white' />
                                ) : (
                                    <ArrowRightOutlined className='!text-white' />
                                )}
                                {parseInt(tempStatus && tempStatus.id) === 6 ? (
                                    <span className='ml-2 text-red-500'>
                                        {t('product_types:this_version_has_been_retired')}
                                    </span>
                                ) : parseInt(tempStatus && tempStatus.id) === 2 ? (
                                    <span>
                                        {approvalId ? (
                                            <span className='flex gap-2'>
                                                <Button
                                                    onClick={() => {
                                                        setIsModalApproveVisible(true)
                                                        setApprovalReason()
                                                    }}
                                                    // size='small'
                                                    className={`app-btn-secondary-small ${
                                                        langDirection === 'rtl' ? 'pl-2' : 'mr-2'
                                                    }`}>
                                                    {t('common:approve')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setIsModalRejectVisible(true)
                                                        setRejectionReason()
                                                    }}
                                                    // size='small'
                                                    className='app-btn-secondary-small'>
                                                    {t('common:reject')}
                                                </Button>
                                            </span>
                                        ) : (
                                            <span className='ml-2 text-red-500'>
                                                {t('approvals:please_review_approval_section')}
                                            </span>
                                        )}
                                    </span>
                                ) : (
                                    <Button
                                        disabled={permissionDisabled}
                                        onClick={() => handleVersionStatusAction(ele.id, ele.status, ele.title)}
                                        className='app-btn-secondary-small'>
                                        {ele.title}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    )
                })}
        </Content>
    )

    const text1 = (
        <div className='p-2'>
            <span>{t('product_types:already_one_draft_state_version')}</span>
        </div>
    )

    const productPublishingText = (
        <Content className=''>
            {nextStatusForProductPublishing &&
                nextStatusForProductPublishing.length > 0 &&
                nextStatusForProductPublishing.map((ele) => {
                    return (
                        <Row className='p-2' key={ele.id}>
                            <Col className='flex items-center gap-1'>
                                <Text className='!text-white'>
                                    {currentStatusDataForProductPublishing &&
                                        currentStatusDataForProductPublishing.length > 0 &&
                                        currentStatusDataForProductPublishing[0].title}{' '}
                                </Text>
                                {langDirection === 'rtl' ? (
                                    <ArrowLeftOutlined className='!text-white' />
                                ) : (
                                    <ArrowRightOutlined className='!text-white' />
                                )}
                                {currentStatusDataForProductPublishing &&
                                currentStatusDataForProductPublishing.length > 0 &&
                                currentStatusDataForProductPublishing[0].title === 'Submit For Approval' ? (
                                    <span className=' ml-2 text-red-500'>
                                        {t('product_types:version_submitted_for_approval')}
                                    </span>
                                ) : currentStatusDataForProductPublishing &&
                                  currentStatusDataForProductPublishing.length > 0 &&
                                  currentStatusDataForProductPublishing[0].title === 'Retired' ? (
                                    <span className='ml-2 text-red-500'>
                                        {t('product_types:this_version_has_been_retired')}
                                    </span>
                                ) : currentStatusDataForProductPublishing &&
                                  currentStatusDataForProductPublishing.length > 0 &&
                                  currentStatusDataForProductPublishing[0].title === 'Rejected' ? (
                                    <span className='ml-2 text-red-500'>
                                        {t('product_types:this_version_has_been_retired')}
                                    </span>
                                ) : currentStatusDataForProductPublishing &&
                                  currentStatusDataForProductPublishing.length > 0 &&
                                  currentStatusDataForProductPublishing[0].title === 'Unpublished' ? (
                                    <span className='ml-2 text-red-500'>{t('product_types:version_unpublished')}</span>
                                ) : (
                                    <Button
                                        disabled={permissionDisabled}
                                        onClick={() => handleVersionStatusAction(ele.id, ele.status, ele.title)}
                                        size='small'
                                        className=' bg-white text-black rounded-xl ml-1.5'>
                                        {ele.title}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    )
                })}
        </Content>
    )

    const changeRevisionButton = () => {
        if (integerCount === '' || floatCount === '') {
            MarketplaceToaster.showToast(util.getToastObject(`${t('product_types:fill_version_number')}`, 'error'))
            return
        }

        const currentRevisionNum = revisionInfo[0].revision_number
        const updatedRevisionNum = parseFloat(integerCount + '.' + floatCount)
        if (updatedRevisionNum <= currentRevisionNum) {
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('product_types:invalid_revision_num_message')} ${currentRevisionNum}`, 'error')
            )
            return
        }

        setNextRevisionNumber(integerCount + '.' + floatCount)
        setIsRevisionNumberModalOpen(false)
        setUpdateRevisionNumber(integerCount + '.' + floatCount)
    }

    const editNextRevisionNumber = () => {
        var decPart = (nextRevisionNumber + '').split('.')
        setIntegerCount(parseInt(decPart[0]))
        setFloatCount(parseInt(decPart[1]))
        setIsRevisionNumberModalOpen(true)
    }

    const changeRightValue = (action) => {
        /**
         * ! Change after decimal value increase or decrease
         * ? leftValue is before decimal value of next revision number
         * ? rightValue is after decimal value of next revision number
         * ? integerCount is state variable for for before decimal text box
         * ? floatCount is state variable for for before decimal text box
         */
        const nextRevisionNum = getNextRevisionNumber(revisionInfo && revisionInfo[0].revision_number)
        // console.log("setNextRevisionNumber", nextRevisionNum);
        const leftValue = parseInt(String(updateRevisionNumber).split('.')[0])
        const rightValue = parseInt(String(updateRevisionNumber).split('.')[1])
        const leftNextValue = parseInt(String(nextRevisionNum).split('.')[0])
        const rightNextValue = parseInt(String(nextRevisionNum).split('.')[1])
        if (action === 'increase') {
            if (floatCount === '') {
                setFloatCount(rightValue)
            } else if (parseInt(floatCount) < 9) {
                if (parseInt(integerCount) < leftValue && parseInt(floatCount) < rightValue) {
                    setIntegerCount(leftValue)
                    setFloatCount(rightValue)
                } else setFloatCount(parseInt(floatCount) + 1)
            } else {
                if (parseInt(integerCount) < 999) {
                    setFloatCount(0)
                    setIntegerCount(parseInt(integerCount) + 1)
                } else {
                    setFloatCount(9)

                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('product_types:revision_max_limit')}`, 'error')
                    )
                }
            }
        } else {
            if (parseInt(integerCount) > parseInt(leftNextValue)) {
                if (parseInt(floatCount) > 0) {
                    setFloatCount(parseInt(floatCount) - 1)
                } else {
                    setFloatCount(9)
                    setIntegerCount(parseInt(integerCount) - 1)
                }
            } else if (parseInt(integerCount) === parseInt(leftNextValue)) {
                setIntegerCount(leftNextValue)
                if (parseInt(floatCount) > parseInt(rightNextValue)) {
                    setFloatCount(parseInt(floatCount) - 1)
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('product_types:revision_min_limit')} ${nextRevisionNum}`, 'error')
                    )
                }
            } else {
                if (parseInt(integerCount) < leftNextValue) {
                    setIntegerCount(leftNextValue)
                    setFloatCount(rightNextValue)
                } else setFloatCount(rightNextValue)

                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:revision_min_limit')} ${nextRevisionNum}`, 'error')
                )
            }
        }
    }

    const changeLeftValue = (action) => {
        /**
         * ! Change after decimal value increase or decrease
         * ? leftValue is before decimal value of next revision number
         * ? rightValue is after decimal value of next revision number
         * ? integerCount is state variable for for before decimal text box
         * ? floatCount is state variable for for before decimal text box
         */
        const nextRevisionNum = getNextRevisionNumber(revisionInfo && revisionInfo[0].revision_number)
        // console.log("setNextRevisionNumber", nextRevisionNum);
        const leftValue = parseInt(String(updateRevisionNumber).split('.')[0])
        const rightValue = parseInt(String(updateRevisionNumber).split('.')[1])
        const leftNextValue = parseInt(String(nextRevisionNum).split('.')[0])
        if (action === 'increase') {
            if (integerCount === '') {
                setIntegerCount(leftValue)
            } else if (parseInt(integerCount) < 999) {
                if (parseInt(integerCount) < leftValue && parseInt(integerCount) === leftNextValue) {
                    setIntegerCount(parseInt(integerCount) + 1)
                    if (parseInt(floatCount) < rightValue) {
                        setFloatCount(rightValue)
                    }
                } else {
                    if (parseInt(integerCount) < leftNextValue) {
                        setIntegerCount(leftNextValue)
                    } else setIntegerCount(parseInt(integerCount) + 1)
                }
            } else {
                MarketplaceToaster.showToast(util.getToastObject(`${t('product_types:revision_max_limit')}`, 'error'))
            }
        } else {
            if (floatCount < rightValue) {
                setFloatCount(rightValue)
            }
            if (parseInt(integerCount) > leftNextValue) {
                setIntegerCount(parseInt(integerCount) - 1)
            } else {
                setIntegerCount(leftNextValue)
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('product_types:revision_min_limit')} ${nextRevisionNum}`, 'error')
                )
            }
        }
    }
    const handleRevisionTextChanged = (e, type) => {
        let value = e.target.value
        const validNumberRegex = /^[0-9]*$/
        if (validNumberRegex.test(value)) {
            if (type === 'integerCount') setIntegerCount(value)
            else setFloatCount(value)
        }
    }

    const editUpdateRevision = () => {
        setUpdateRevisionDescription(revisionSelectedInfo?.description?.trim().replace(/\s+/g, ' '))
        setUpdateRevisionNumber(revisionSelectedInfo.revision_number)
        setTempDescription(revisionSelectedInfo.description)
        // setVersionDescriptionData(revisionSelectedInfo.display_description)
        setIsModalUpdateRevision(true)
        getTitle('edit')
    }

    const handleMainPopUPClose = () => {
        setIsModalOpen(false)
        const nextRevisionNum = getNextRevisionNumber(revisionInfo && revisionInfo[0].revision_number)
        setNextRevisionNumber(nextRevisionNum)
        setUpdateRevisionNumber(nextRevisionNum)
    }
    const translateModalCloseHandler = () => {
        setTranslateModalVisible(false)
        setLoadingSkelton(false)
    }
    const getTitle = (action) => {
        if (action === 'edit') {
            if (type === 'productType') {
                setModalTitle(t('product_types:edit_product_version'))
            } else if (type === 'productTemplate') {
                setModalTitle(t('product_types:edit_product_template_version'))
            }
        } else if (action === 'create') {
            if (type === 'productType') {
                setModalTitle(t('product_types:create_product_version'))
            } else if (type === 'productTemplate') {
                setModalTitle(t('product_types:create_product_template_version'))
            }
        }
    }

    return isLoading ? (
        <Content className='bg-white p-3'>
            <Skeleton
                active
                paragraph={{
                    rows: 2,
                }}
            />
        </Content>
    ) : isNetWorkErrorProductRevision ? (
        <Content className='p-3 text-center bg-white '>
            <Text>{t('common:network_error')}</Text>
        </Content>
    ) : (
        <Content className=''>
            <Row gutter={[8, 0]}>
                <Col className=' flex flex-row font-normal text-[18px] !leading-[26px] !text-regal-blue '>
                    {version_number ? (
                        <>{'V' + Number(version_number).toFixed(1)} </>
                    ) : (
                        <>{'V' + Number(tempRevisionNumber).toFixed(1)}</>
                    )}
                    {(revisionSelectedInfo && revisionSelectedInfo.status === '1') ||
                    (revisionSelectedInfo &&
                        revisionSelectedInfo.product_revision_status === 1 &&
                        !permissionDisabled) ? (
                        <Button type='text' className='app-btn-icon app-edit-icon ml-1' onClick={editUpdateRevision}>
                            <MdEdit />
                        </Button>
                    ) : null}
                </Col>
                <Col className='!mt-2 !cursor-pointer'>
                    {parseInt(mode) === 1 && product_id && version_status && version_number ? (
                        <Tooltip placement='bottomRight' title={productPublishingText} color='black' zIndex={1}>
                            <Text className='text-brandPrimaryColor mr-2 cursor-pointer font-medium text-[14px] leading-[22px]'>
                                {currentStatusDataForProductPublishing &&
                                    currentStatusDataForProductPublishing.length > 0 &&
                                    currentStatusDataForProductPublishing[0].title}{' '}
                            </Text>

                            <DownOutlined className='!text-brandPrimaryColor -translate-y-[2px]' />
                        </Tooltip>
                    ) : (
                        <Tooltip
                            placement='bottomRight'
                            title={
                                permissionValue &&
                                permissionValue.length > 0 &&
                                permissionValue.includes('UI-list-approvals') ? (
                                    isProductTemplateServiceProduct === true &&
                                    productTemplateRevisionSlotId === undefined ? (
                                        <div className='max-w-[240px]'>{t('products:config_slot')}</div>
                                    ) : (
                                        text
                                    )
                                ) : undefined
                            }
                            color='black'
                            overlayStyle={{ maxWidth: '2000px' }}
                            // overlayInnerStyle={{ mixWidth: '300px' }}
                            zIndex={1}>
                            <Content className='flex gap-1 -translate-y-1'>
                                <Text
                                    className={`text-brandPrimaryColor font-medium text-[14px] leading-[22px]
                          ${
                              permissionValue &&
                              permissionValue.length > 0 &&
                              permissionValue.includes('UI-list-approvals')
                                  ? '!cursor-pointer'
                                  : '!cursor-not-allowed'
                          }
                          `}>
                                    {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                        ? revisionSelectedInfo && revisionSelectedInfo?.status_display_name
                                            ? revisionSelectedInfo?.status_display_name
                                            : t('common:not_available')
                                        : revisionSelectedInfo &&
                                            revisionSelectedInfo?.product_revision_status_display_name
                                          ? revisionSelectedInfo?.product_revision_status_display_name
                                          : t('common:not_available')}
                                </Text>

                                <DownOutlined className='!text-brandPrimaryColor mt-1 text-xs' />
                            </Content>
                        </Tooltip>
                    )}
                </Col>
            </Row>
            {parseInt(mode) === 1 && product_id && version_status && version_number ? null : (
                <Content className=' mt-2'>
                    {/* <div className='flex items-center justify-between'>
                        <Tooltip
                            placement='bottom'
                            title={
                                pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                    ? tempDescription
                                    : versionDescriptionData
                            }
                            overlayStyle={{
                                zIndex: 1,
                            }}>
                            <Text ellipsis={true} style={{ maxWidth: 300 }}>
                                {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                    ? tempDescription
                                    : versionDescriptionData}
                            </Text>
                        </Tooltip>
                    </div> */}
                    {revisionSelectedInfo && parseInt(revisionSelectedInfo.product_revision_status) >= 2 ? (
                        <Row className='!pt-2 mb-2 flex gap-1'>
                            <p className='!text-[14px] !text-regal-blue font-medium'>
                                {parseInt(revisionSelectedInfo.product_revision_status) === 4 ? (
                                    <>{t('common:rejection_note')} : </>
                                ) : (
                                    <>
                                        {t('common:approval_note')} :{''}
                                    </>
                                )}
                            </p>
                            <p className='mb-0'>
                                {revisionSelectedInfo.approval_reason &&
                                revisionSelectedInfo.approval_reason !== null &&
                                revisionSelectedInfo.approval_reason !== undefined &&
                                revisionSelectedInfo.approval_reason !== '' ? (
                                    <Tooltip
                                        title={revisionSelectedInfo && revisionSelectedInfo.approval_reason}
                                        overlayStyle={{ position: 'relative', zIndex: 1 }}
                                        placement='bottom'
                                        mouseLeaveDelay={0}>
                                        <Text
                                            ellipsis={true}
                                            style={{ maxWidth: 250 }}
                                            className='!text-[14px] !text-[#637381] font-normal'>
                                            {revisionSelectedInfo && revisionSelectedInfo.approval_reason
                                                ? revisionSelectedInfo.approval_reason
                                                : t('product_types:not_applicable')}
                                        </Text>
                                    </Tooltip>
                                ) : (
                                    <p className={'mb-0 !text-[14px] !text-[#637381] !font-normal'}>
                                        {t('product_types:not_applicable')}
                                    </p>
                                )}
                            </p>
                        </Row>
                    ) : null}
                    {revisionSelectedInfo && parseInt(revisionSelectedInfo.product_revision_status) >= 2 ? (
                        <Row className='mb-2 flex gap-1'>
                            <p className='!text-[14px] !text-regal-blue font-medium'>
                                {t('common:submission_note')} :{' '}
                            </p>
                            <p className='mb-0'>
                                {revisionSelectedInfo.submission_reason &&
                                revisionSelectedInfo.submission_reason !== null &&
                                revisionSelectedInfo.submission_reason !== undefined &&
                                revisionSelectedInfo.submission_reason !== '' ? (
                                    <Tooltip
                                        title={revisionSelectedInfo && revisionSelectedInfo.submission_reason}
                                        overlayStyle={{ position: 'relative', zIndex: 1 }}
                                        placement='bottom'
                                        mouseLeaveDelay={0}>
                                        <Text
                                            ellipsis={true}
                                            style={{ maxWidth: 250 }}
                                            className='!text-[14px] !text-[#637381] !font-normal'>
                                            {revisionSelectedInfo && revisionSelectedInfo.submission_reason
                                                ? revisionSelectedInfo.submission_reason
                                                : t('product_types:not_applicable')}
                                        </Text>
                                    </Tooltip>
                                ) : (
                                    <p className={'mb-0!text-[14px] !text-[#637381] !font-normal'}>
                                        {t('product_types:not_applicable')}
                                    </p>
                                )}
                            </p>
                        </Row>
                    ) : null}
                    <Content className='flex mt-4'>
                        <Content>
                            {(revisionSelectedInfo &&
                                revisionSelectedInfo.length > 0 &&
                                revisionSelectedInfo &&
                                revisionSelectedInfo.product_revision_status &&
                                revisionSelectedInfo.product_revision_status === 1) ||
                            (revisionSelectedInfo && revisionSelectedInfo.status === '1') ||
                            (revisionInfo &&
                                revisionInfo.length > 0 &&
                                revisionInfo.filter(
                                    (element) => element.status === '1' || element.product_revision_status === 1
                                ).length > 0) ? (
                                <Tooltip
                                    placement='bottom'
                                    title={text1}
                                    color='black'
                                    overlayStyle={{ zIndex: 1 }}
                                    mouseLeaveDelay={0}>
                                    <Content className='flex'>
                                        <Button
                                            disabled
                                            className='app-btn-secondary text-[14px] text-black flex items-center !pointer-events-none !cursor-none !hover:pointer-events-none !hover:cursor-none mx-2'>
                                            <PlusOutlined className='' />
                                            {t('product_types:create_new_version')}
                                        </Button>
                                    </Content>
                                </Tooltip>
                            ) : (
                                <Content className='flex'>
                                    <Tooltip
                                        placement='bottom'
                                        title={
                                            revisionInfo &&
                                            revisionInfo.length > 0 &&
                                            revisionInfo.filter((element) => element.revision_number == 999.9).length >
                                                0
                                                ? t('product_template:version_limit_reached_msg')
                                                : null
                                        }
                                        color='black'
                                        overlayStyle={{ zIndex: 1 }}>
                                        <Button
                                            disabled={
                                                revisionInfo &&
                                                revisionInfo.length > 0 &&
                                                revisionInfo.filter(
                                                    (element) => parseFloat(element.revision_number) === 999.9
                                                ).length > 0
                                                    ? true
                                                    : false
                                            }
                                            className='app-btn-secondary flex justify-center items-center mx-2'
                                            onClick={toggleModal}>
                                            <PlusOutlined className='' />
                                            {t('product_types:create_new_version')}
                                        </Button>
                                    </Tooltip>
                                </Content>
                            )}
                        </Content>
                        <Content className='!items-center'>
                            <Text
                                className='app-btn-link whitespace-nowrap !font-medium !max-w-[140px]'
                                ellipsis={{ tooltip: t('product_types:show_version_history') }}>
                                <span className='flex !text-brandPrimaryColor cursor-pointer mt-1' onClick={showDrawer}>
                                    {t('product_types:show_version_history')}
                                </span>
                            </Text>
                        </Content>
                    </Content>

                    <Drawer
                        title={
                            <p className='text-base font-medium text-regal-blue'>
                                {t('product_types:version_history')}
                            </p>
                        }
                        placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                        onClose={onClose}
                        open={open}
                        width='490px'>
                        <Content className=''>
                            {revisionInfo &&
                                revisionInfo.length > 0 &&
                                revisionInfo.map((item, index) => {
                                    return (
                                        <Content className='!p-0' key={item.id}>
                                            {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE' ? (
                                                <div
                                                    onClick={() => {
                                                        handleVersionChange(item.id)
                                                    }}
                                                    className={`${
                                                        revisionSelectedInfo && revisionSelectedInfo.id === item.id
                                                            ? '  p-3 !bg-[#FFFFFF]  border-black cursor-pointer !hover:bg-[#FFFFFF]'
                                                            : ' p-3 bg-[#F0F0F0]  border-black cursor-pointer !hover:bg-[#FFFFFF]'
                                                    }`}>
                                                    <div className=' flex'>
                                                        <div className=' font-semibold !text-regal-blue'>
                                                            {'V' + Number(item.revision_number)?.toFixed(1)}
                                                        </div>
                                                        <div className=''>
                                                            {getNextAllowedTypeStatus(
                                                                item.product_revision_status || parseInt(item.status)
                                                            )
                                                                .filter((ele) => ele.position.toUpperCase() === 'LEFT')
                                                                .map((ele) => (
                                                                    //                             <Button
                                                                    //                                 key={index.id}
                                                                    //                                 onClick={() => {
                                                                    //                                     handleVersionChange(item.id)
                                                                    //                                 }}
                                                                    //                                 size='small'
                                                                    //                                 className='app-btn-primary
                                                                    // text-[10px] !ml-1'>
                                                                    //                                 {item.status_display_name}
                                                                    //                             </Button>
                                                                    <Tag
                                                                        key={index.id}
                                                                        onClick={() => {
                                                                            handleVersionChange(item.id)
                                                                        }}
                                                                        color='orange'
                                                                        className='h-[31px] flex items-center tagText'>
                                                                        {item.status_display_name}
                                                                    </Tag>
                                                                ))}
                                                        </div>
                                                    </div>

                                                    <Paragraph className=' my-2.5 !text-brandGray2'>
                                                        {item.description}
                                                    </Paragraph>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() => {
                                                        setSearchParams({
                                                            store: `${MarketplaceAppConfig.getStore('onlyClient')}`,
                                                            ptsid: `${
                                                                revisionSelectedInfo &&
                                                                revisionSelectedInfo.product_template
                                                            }`,
                                                            ptsrid: `${item.id}`,
                                                            ptss: `${item.product_revision_status}`,
                                                        })
                                                        handleVersionChange(item.id)
                                                    }}
                                                    className={`${
                                                        revisionSelectedInfo && revisionSelectedInfo.id === item.id
                                                            ? '  p-3 !bg-[#FFFFFF]  border-black cursor-pointer !hover:bg-[#FFFFFF]'
                                                            : ' p-3 bg-[#F0F0F0]  border-black cursor-pointer !hover:bg-[#FFFFFF]'
                                                    }`}>
                                                    <div className=' items-center flex gap-1'>
                                                        <div className=' font-semibold !text-regal-blue'>
                                                            {'V' + Number(item.revision_number)?.toFixed(1)}
                                                        </div>
                                                        <div className=''>
                                                            {getNextAllowedTypeStatus(
                                                                item.product_revision_status || parseInt(item.status)
                                                            )
                                                                .filter((ele) => ele.position.toUpperCase() === 'LEFT')
                                                                .map((ele) => (
                                                                    //                                 <Button
                                                                    //                                     key={index.id}
                                                                    //                                     onClick={() => {
                                                                    //                                         handleVersionChange(item.id)
                                                                    //                                     }}
                                                                    //                                     size='small'
                                                                    //                                     className='app-btn-primary
                                                                    // text-[10px]'>
                                                                    //                                     {item.product_revision_status_display_name}{' '}
                                                                    //                                 </Button>

                                                                    <Tag
                                                                        key={index.id}
                                                                        onClick={() => {
                                                                            handleVersionChange(item.id)
                                                                        }}
                                                                        color='orange'
                                                                        className='h-[31px] flex items-center tagText'>
                                                                        {item.product_revision_status_display_name}{' '}
                                                                    </Tag>
                                                                ))}
                                                        </div>
                                                    </div>

                                                    <Paragraph className=' my-2.5 !text-brandGray2'>
                                                        {item.display_description}
                                                    </Paragraph>
                                                    {item && parseInt(item.product_revision_status) >= 2 ? (
                                                        <Row className='mb-2 flex gap-1'>
                                                            <p className='!mb-0 text-regal-blue font-medium text-sm'>
                                                                {parseInt(item.product_revision_status) === 4 ? (
                                                                    <>{t('common:rejection_note')} : </>
                                                                ) : (
                                                                    <>
                                                                        {t('common:approval_note')} :{''}
                                                                    </>
                                                                )}
                                                            </p>
                                                            <p className='!mb-0'>
                                                                {item &&
                                                                item.approval_reason &&
                                                                item.approval_reason !== null &&
                                                                item.approval_reason !== undefined &&
                                                                item.approval_reason !== '' ? (
                                                                    <Tooltip
                                                                        title={item && item.approval_reason}
                                                                        placement='bottom'
                                                                        overlayStyle={{
                                                                            position: 'relative',
                                                                            zIndex: 1001,
                                                                        }}
                                                                        mouseLeaveDelay={0}>
                                                                        <Text
                                                                            ellipsis={true}
                                                                            style={{ maxWidth: 300 }}
                                                                            className='!text-[14px] !text-[#637381] !font-normal'>
                                                                            {item && item.approval_reason
                                                                                ? item.approval_reason
                                                                                : t('product_types:not_applicable')}
                                                                        </Text>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Content
                                                                        className={`!text-[14px] !text-[#637381] !font-normal${
                                                                            langDirection === 'rtl' ? '!mr-1.5' : ''
                                                                        }`}>
                                                                        {t('product_types:not_applicable')}
                                                                    </Content>
                                                                )}
                                                            </p>
                                                        </Row>
                                                    ) : null}
                                                    {item && parseInt(item.product_revision_status) >= 2 ? (
                                                        <Row className='!mt-0 mb-2 flex gap-1'>
                                                            <p className='!text-[14px] !text-regal-blue font-medium'>
                                                                {t('common:submission_note')} :{' '}
                                                            </p>
                                                            <p className='!mb-0'>
                                                                {item &&
                                                                item.submission_reason &&
                                                                item.submission_reason !== null &&
                                                                item.submission_reason !== undefined &&
                                                                item.submission_reason !== '' ? (
                                                                    <Tooltip
                                                                        title={item && item.submission_reason}
                                                                        overlayStyle={{
                                                                            position: 'relative',
                                                                            zIndex: 1001,
                                                                        }}
                                                                        placement='bottom'
                                                                        mouseLeaveDelay={0}>
                                                                        <Text
                                                                            ellipsis={true}
                                                                            style={{ maxWidth: 300 }}
                                                                            className='!text-[14px] !text-[#637381] !font-normal'>
                                                                            {item && item.submission_reason
                                                                                ? item.submission_reason
                                                                                : t('product_types:not_applicable')}
                                                                        </Text>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Content className='!text-[14px] !text-[#637381] !font-normal'>
                                                                        {t('product_types:not_applicable')}
                                                                    </Content>
                                                                )}
                                                            </p>
                                                        </Row>
                                                    ) : null}
                                                </div>
                                            )}
                                            <Divider className='!m-0 !bg-[#C7C7C7]' />
                                        </Content>
                                    )
                                })}
                        </Content>
                    </Drawer>

                    <Content className='text-sm -mt-2 py-2 '>
                        <StoreModal
                            isVisible={isRevisionNumberModalOpen}
                            title={t('product_types:change_version_number')}
                            okCallback={() => changeRevisionButton()}
                            cancelCallback={setIsRevisionNumberModalOpen}
                            okButtonText={t('common:change')}
                            cancelButtonText={t('common:discard')}
                            isSpin={isUploading}
                            width={400}>
                            <Content>
                                <Row>
                                    <Col span={6} className='!mt-14'>
                                        <Typography className='input-label-color'>
                                            {t('product_types:version')}
                                        </Typography>
                                    </Col>
                                    <Col span={5}>
                                        <div
                                            className={`border-1 border-[#393939] rounded-sm cursor-pointer ${
                                                integerCount === '' ? 'border-red-500' : ''
                                            }`}
                                            onClick={() => changeLeftValue('increase')}>
                                            <CaretUpOutlined
                                                className={`mb-2 ml-7 ${langDirection === 'rtl' ? '!mr-7' : ''}`}
                                            />
                                        </div>

                                        <Input
                                            className={`my-3 ${integerCount === '' ? 'border-red-500' : ''}`}
                                            maxLength={3}
                                            value={integerCount}
                                            onChange={(e) => handleRevisionTextChanged(e, 'integerCount')}
                                        />
                                        <div
                                            className={`border-1 border-[#393939] rounded-sm cursor-pointer ${
                                                integerCount === '' ? 'border-red-500' : ''
                                            }`}
                                            onClick={() => changeLeftValue('decrease')}>
                                            <CaretDownOutlined
                                                className={`mb-2 ml-7 ${langDirection === 'rtl' ? '!mr-7' : ''}`}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={2} className='!mt-14 ml-3.5'>
                                        <span>.</span>
                                    </Col>
                                    <Col span={5}>
                                        <div
                                            className={`border-1 border-[#393939] rounded-sm cursor-pointer ${
                                                floatCount === '' ? 'border-red-500' : ''
                                            }`}
                                            onClick={() => changeRightValue('increase')}>
                                            <CaretUpOutlined
                                                className={`mb-2 ml-7 ${langDirection === 'rtl' ? '!mr-7' : ''}`}
                                            />
                                        </div>

                                        <Input
                                            className={`my-3 ${floatCount === '' ? 'border-red-500' : ''}`}
                                            maxLength={1}
                                            value={floatCount}
                                            onChange={(e) => handleRevisionTextChanged(e, 'floatCount')}
                                        />
                                        <div
                                            className={`border-1 border-[#393939] rounded-sm cursor-pointer ${
                                                floatCount === '' ? 'border-red-500' : ''
                                            }`}
                                            onClick={() => changeRightValue('decrease')}>
                                            <CaretDownOutlined
                                                className={`mb-2 ml-7 ${langDirection === 'rtl' ? '!mr-7' : ''}`}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Content>
                        </StoreModal>

                        <StoreModal
                            isVisible={isModalOpen}
                            // title={t('product_types:create_product_version')}
                            title={modalTitle}
                            okCallback={() => validateFieldForRevisions()}
                            cancelCallback={() => handleMainPopUPClose()}
                            okButtonText={t('product_types:create')}
                            cancelButtonText={t('common:cancel')}
                            isSpin={isUploading}>
                            <Content className=''>
                                <Typography className='mb-2 input-label-color'>
                                    {t('product_types:version_type')}
                                </Typography>
                                <Row className='mt-[3px]'>
                                    <Radio.Group
                                        options={optionsForRevisionType}
                                        onChange={onChangeRevisionType}
                                        value={revisionTypeToggle}
                                        optionType='button'
                                        buttonStyle='solid'
                                    />
                                </Row>
                            </Content>
                            {revisionTypeToggle === 'new_revision' && (
                                <Content>
                                    <Content className='my-2'>
                                        <Typography className='input-label-color mb-2'>
                                            {t('product_types:version_number')}
                                            <span
                                                className={`mandatory-symbol-color text-sm ml-1 ${
                                                    langDirection === 'rtl' ? '!mr-1' : ''
                                                }`}>
                                                *
                                            </span>
                                        </Typography>
                                        <Input
                                            disabled
                                            addonAfter={<EditOutlined role='button' onClick={editNextRevisionNumber} />}
                                            onChange={handleRevisionNumber}
                                            value={nextRevisionNumber}
                                            className={`${
                                                inValidRevisionNumber
                                                    ? 'border-red-400 h-10 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                                    : ' '
                                            }`}
                                        />
                                        <span className=' text-red-600'>
                                            {t('product_types:current_latest_version_number_is')}{' '}
                                            <span className=' font-bold'>
                                                {' '}
                                                {revisionInfo && Number(revisionInfo[0].revision_number)?.toFixed(1)}
                                            </span>{' '}
                                            , {t('product_types:next_version_number_will_be')}{' '}
                                            <span className=' font-bold'>{updateRevisionNumber}</span>
                                            {'. '}
                                            {t('product_types:you_can_change_version_number')}
                                        </span>
                                    </Content>

                                    <Content className='mb-4'>
                                        <Typography className='input-label-color mb-2'>
                                            {t('product_types:version_description')}{' '}
                                            <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                        </Typography>
                                        <TextArea
                                            showCount
                                            maxLength={descriptionMaxLength}
                                            autoSize={true}
                                            placeholder={t('product_types:enter_the_description')}
                                            value={revisionDescription}
                                            className={`!pr-[3px] ${
                                                inValidRevisionDescription
                                                    ? 'border-red-400 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                                    : ''
                                            }`}
                                            onChange={(e) => {
                                                handleDescriptionChange(e)
                                            }}
                                            onBlur={(e) => {
                                                setRevisionDescription(e.target.value.trim().replace(/\s+/g, ' '))
                                                setUpdateRevisionDescription(e.target.value.trim().replace(/\s+/g, ' '))
                                            }}
                                        />
                                    </Content>
                                </Content>
                            )}
                            {revisionTypeToggle === 'copy_revision' && (
                                <Content className=' my-2'>
                                    <Content className=''>
                                        <Typography className='input-label-color mb-2'>
                                            {t('product_types:select_existing_version')}{' '}
                                            <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                        </Typography>
                                        <Select
                                            placeholder={t('product_types:select_existing_version_placeholder')}
                                            value={selectedDropDownValue}
                                            className={`${
                                                inValidSelectedDropDownValue
                                                    ? 'border-red-400 w-[230px] !rounded-md flex h-8.5 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                                    : ' w-[230px] h-9 flex'
                                            }`}
                                            onChange={handleDropdownSelectedValue}>
                                            {revisionInfo &&
                                                revisionInfo.length > 0 &&
                                                revisionInfo.map((item) => {
                                                    return (
                                                        <Select.Option value={item.id} key={item.id}>
                                                            {'V' + Number(item.revision_number)?.toFixed(1)}
                                                        </Select.Option>
                                                    )
                                                })}
                                        </Select>
                                    </Content>
                                    <Content className='my-2'>
                                        <Typography className='input-label-color mb-2'>
                                            {' '}
                                            {t('product_types:version_number')}
                                        </Typography>
                                        <Input
                                            disabled
                                            addonAfter={<EditOutlined role='button' onClick={editNextRevisionNumber} />}
                                            onChange={handleCopyRevisionNumber}
                                            value={nextRevisionNumber}
                                        />
                                        <span className=' text-red-600'>
                                            {t('product_types:current_latest_version_number_is')}{' '}
                                            <span className=' font-bold'>
                                                {' '}
                                                {revisionInfo && Number(revisionInfo?.[0]?.revision_number)?.toFixed(1)}
                                            </span>{' '}
                                            , {t('product_types:next_version_number_will_be')}{' '}
                                            <label className=' font-bold'>{updateRevisionNumber}</label>
                                            {'. '}
                                            {t('product_types:you_can_change_version_number')}
                                        </span>
                                    </Content>
                                    <Content className='mb-4'>
                                        <Typography className='input-label-color mb-2'>
                                            {t('product_types:version_description')}{' '}
                                            <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                        </Typography>
                                        <TextArea
                                            showCount
                                            maxLength={descriptionMaxLength}
                                            placeholder={t('product_types:please_enter_version_description')}
                                            autoSize={true}
                                            value={copyDescription}
                                            className={`!pr-[3px] ${
                                                inValidCopyDescription
                                                    ? 'border-red-400 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                                    : ''
                                            }`}
                                            onChange={(e) => {
                                                handleCopyDescriptionChange(e)
                                            }}
                                            onBlur={(e) => {
                                                setCopyDescription(e.target.value.trim().replace(/\s+/g, ' '))
                                            }}
                                        />
                                    </Content>
                                </Content>
                            )}
                        </StoreModal>
                    </Content>
                </Content>
            )}

            {/* store modal for updating revision */}

            <StoreModal
                isVisible={isModalUpdateRevision}
                // title={t('product_types:edit_product_version')}
                title={modalTitle}
                okCallback={() => validationEditProductType()}
                cancelCallback={setIsModalUpdateRevision}
                okButtonText={null}
                cancelButtonText={null}
                isSpin={isUploading}>
                <Content className='z-50'>
                    <Content className='my-3'>
                        <Typography className='input-label-color mb-2'>
                            {t('product_types:version_number')}
                            <span
                                className={`mandatory-symbol-color text-sm ml-1 ${
                                    langDirection === 'rtl' ? '!mr-1' : ''
                                }`}>
                                *
                            </span>
                        </Typography>
                        {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE' ? (
                            <Input
                                disabled
                                addonAfter={
                                    <EditOutlined
                                        className='app-btn-link'
                                        role='button'
                                        onClick={editNextRevisionNumber}
                                    />
                                }
                                onChange={handleRevisionNumber}
                                value={updateRevisionNumber}
                                className={`${
                                    inValidRevisionNumber
                                        ? 'border-red-400 mt-2 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                        : 'mt-2'
                                }`}
                            />
                        ) : (
                            <Input
                                disabled
                                onChange={handleRevisionNumber}
                                value={updateRevisionNumber}
                                className={`${
                                    inValidRevisionNumber
                                        ? 'border-red-400 h-10 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                        : ' '
                                }`}
                            />
                        )}
                    </Content>
                    <Content className='mt-3 mb-4'>
                        <Typography className='input-label-color mb-2'>
                            {t('product_types:version_description')}{' '}
                            <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                        </Typography>
                        <div className='relative flex'>
                            <TextArea
                                // showCount
                                autoSize={true}
                                disabled={
                                    pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                        ? false
                                        : true
                                }
                                maxLength={descriptionMaxLength}
                                placeholder={t('product_types:please_enter_version_description')}
                                value={
                                    pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                                        ? tempDescription
                                        : versionDescriptionData
                                }
                                className={`!pr-[3px] relative ${
                                    inValidRevisionDescription
                                        ? 'border-red-400 border-[1px] border-solid focus:border-red-400 hover:border-red-400'
                                        : ' '
                                }`}
                                onChange={(e) => {
                                    handleDescriptionChange(e)
                                }}
                                onBlur={(e) => {
                                    setUpdateRevisionDescription(e.target.value.trim().replace(/\s+/g, ' '))
                                    setRevisionDescription(e.target.value.trim().replace(/\s+/g, ' '))
                                }}
                            />
                            {pageCompAction && pageCompAction.type.toUpperCase() != 'PRODUCT_TYPE' && (
                                <Content
                                    className={`!mt-1 ${
                                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                            ? 'absolute !left-[12px]'
                                            : 'absolute !right-[10px]'
                                    }`}>
                                    <TranslationOutlined
                                        onClick={() => {
                                            setLoadingSkelton(true)
                                            setTranslateModalVisible(true)
                                            setOnChangeDisableFields(true)
                                            setIsMultilingualCallSuccess(false)
                                        }}
                                        className='iconsHoverClass'
                                    />
                                </Content>
                            )}
                        </div>
                    </Content>
                </Content>
                <Content className='flex !justify-end space-x-2'>
                    <Button
                        className={`app-btn-secondary ${langDirection === 'rtl' ? '!ml-2' : ''}`}
                        key='back'
                        onClick={() => setIsModalUpdateRevision(false)}>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        className='app-btn-primary'
                        key='submit'
                        onMouseDown={() => validationEditProductType()}
                        disabled={enableUpdateButton}>
                        {t('common:update')}
                    </Button>
                </Content>
            </StoreModal>
            <StoreModal
                title={`${t('common:translate')}`}
                isVisible={translateModalVisible}
                okButtonText={null}
                cancelButtonText={null}
                okCallback={null}
                hideCloseButton={false}
                cancelCallback={() => translateModalCloseHandler()}
                isSpin={false}
                width={1000}>
                <Content>
                    <TranslatorModal
                        dataJson={[
                            {
                                order: 1,
                                label: t('vendors:enter_vendor_name'),
                                type: 'textarea',
                                value: updateRevisionDescription,
                            },
                        ]}
                        componentType='template_version_description'
                        respectiveId={parseInt(productTemplateRevisionId)}
                        setTranslateModalVisible={setTranslateModalVisible}
                        versionDescriptionData={versionDescriptionData}
                        setVersionDescriptionData={setVersionDescriptionData}
                        onChangeDisableFields={onChangeDisableFields}
                        setOnChangeDisableFields={setOnChangeDisableFields}
                        loadingSkelton={loadingSkelton}
                        setLoadingSkelton={setLoadingSkelton}
                        setIsMultilingualCallSuccess={setIsMultilingualCallSuccess}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={isOkModalOpen}
                title={t('common:confirmation')}
                okCallback={() => modalOk()}
                cancelCallback={() => setIsOkModalOpen(false)}
                okButtonText={t('common:yes')}
                width={'370px'}
                isSpin={isUploading}>
                <Content className=''>
                    <Text>
                        {t('product_types:are_you_sure_you_want_to')} {nextStatusName?.toLocaleLowerCase()}{' '}
                        {t('product_types:this')}{' '}
                        {pageCompAction && pageCompAction.type.toUpperCase() === 'PRODUCT_TYPE'
                            ? `${t('product_types:product_type_in_small_case')}`
                            : `${t('product_template:product_template_small_case')}`}
                        ?
                    </Text>
                </Content>
            </StoreModal>

            <StoreModal
                isVisible={isModalApprovalRequestVisible}
                title={t('approvals:approval_request_submission')}
                okCallback={() => postApprovalRequest()}
                cancelCallback={() => closeRequestApprovalModal()}
                okButtonText={t('common:ok')}
                cancelButtonText={t('common:cancel')}
                isSpin={isUploading}>
                <Content className=''>
                    <Title level={5} className=' mb-2'>
                        {t('approvals:submission_reason')}
                    </Title>
                    <TextArea
                        value={submissionReason}
                        showCount
                        autoSize={true}
                        maxLength={225}
                        placeholder={t('product_types:please_enter_submission_request_message')}
                        onChange={(e) => setSubmissionReason(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                const trimmed = submissionReason.trim()
                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                setSubmissionReason(trimmedUpdate)
                            }, 300)
                        }}
                        className='!pr-[3px]'
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={isModalApproveVisible}
                title={`${t('approvals:approval_request_submission')}`}
                okCallback={() => updateStatusOfProductsForAwaitingApprovals(3, approvalReason)}
                cancelCallback={() => closeApproveModal()}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                isSpin={isApprovalUploading}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('product_template:approve_message')}
                    </Title>
                    <TextArea
                        maxLength={225}
                        autoSize={true}
                        value={approvalReason}
                        placeholder={t('approvals:enter_approval_req_message')}
                        onChange={(e) => setApprovalReason(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                const trimmed = approvalReason.trim()
                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                setApprovalReason(trimmedUpdate)
                            }, 300)
                        }}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={isModalRejectVisible}
                title={`${t('approvals:rejection_request_submission')}`}
                okCallback={() => updateStatusOfProductsForAwaitingApprovals(4, rejectionReason)}
                cancelCallback={() => closeRejectModal()}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                isSpin={isRejectUploading}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('product_template:reject_message')}
                    </Title>
                    <TextArea
                        showCount
                        maxLength={225}
                        autoSize={true}
                        value={rejectionReason}
                        placeholder={t('approvals:please_enter_rejection_submission_request_message')}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                const trimmed = rejectionReason.trim()
                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                setRejectionReason(trimmedUpdate)
                            }, 300)
                        }}
                        className='!pr-[3px]'
                    />
                </Content>
            </StoreModal>
        </Content>
    )
}

export default ProductVersionStatus
