import React from 'react'
import { Skeleton } from 'antd'

const PDPDetails = () => {
    return (
        <div>
            <Skeleton />
        </div>
    )
}

export default PDPDetails
