import { InboxOutlined } from '@ant-design/icons'
import { Button, Layout, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

//! Import CSS libraries
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

//! Global Variables

const { Content } = Layout
const { Option } = Select
const { Title, Text } = Typography

const listJourneysAPI = process.env.REACT_APP_LIST_SETTLEMENT_JOURNEYS_API
const canvasIframeURL = process.env.REACT_APP_CANVAS_IFRAME_URL
const canvasCreateSettlementJourneyUrl = process.env.REACT_APP_CANVAS_CREATE_SETTLEMENT_JOURNEY_URL
const settlementJourneyAPI = process.env.REACT_APP_SETTLEMENT_JOURNEY_API

const Settings = () => {
    const { t } = useTranslation()
    let { id } = useParams()
    const [orderItemJourneys, setOrderItemJourneys] = useState([])
    const [iframeScr, setIframeSrc] = useState()
    const [selectedJourneyId, setSelectedJourneyId] = useState(t('common:select_journey'))
    const [validJourneySelect, setValidJourneySelect] = useState(false)
    const [mappedJourneys, setMappedJourneys] = useState([])
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [alreadyMappedId, setAlreadyMappedId] = useState()

    //! Get list of all journeys available to bind in dropdown
    const findAllWithoutPageListJourneys = () => {
        MarketplaceServices.findAllWithoutPage(listJourneysAPI, null, false)
            .then((response) => {
                console.log('Server Response from findAllWithoutPageListJourneys is', listJourneysAPI, response.data)
                setOrderItemJourneys(response.data.response_body.Response)
            })
            .catch((error) => {
                console.log('Error Server response from findAllWithoutPageListJourneys ', listJourneysAPI, error)
                setOrderItemJourneys([])
            })
    }
    //! Build coupler journey flow canvas URL to bind in iframe
    const setCanvasIframeURL = (journeyId, journeyFlowName, journeyPackageName) => {
        let canvasURL = canvasIframeURL
        canvasURL = canvasURL.replace('{realmName}', util.getClient('lower'))
        canvasURL = canvasURL.replace('{journeyID}', journeyId)
        canvasURL = canvasURL.replace('{journeyFlowName}', journeyFlowName)
        canvasURL = canvasURL.replace('{journeyPackageName}', journeyPackageName)
        setIframeSrc(canvasURL)
    }

    //! When journey is changed from the dropdown
    const orderJourneyChange = (journeyId, journeyIdDetails) => {
        setValidJourneySelect(false)
        setButtonDisabled(false)
        const journeyPackageName = journeyIdDetails.journeyPackageName
        const journeyFlowName = journeyIdDetails.journeyFlowName
        setSelectedJourneyId(journeyId)
        setCanvasIframeURL(journeyId, journeyFlowName, journeyPackageName)
    }

    useEffect(() => {
        findAllWithoutPageListJourneys()
        findAllMappedJourney()
    }, [])

    const validationSettlementJourney = () => {
        let count = 1
        if (selectedJourneyId === t('common:select_journey')) {
            count--

            MarketplaceToaster.showToast(
                util.getToastObject(`${t('transactions:please_select_the_mandatory_field')}`, 'error')
            )
            setValidJourneySelect(true)
        }
        if (count === 1) {
            if (alreadyMappedId !== undefined) {
                updateSettlementJourney()
            } else {
                saveSettlement()
            }
        }
    }

    const updateSettlementJourney = () => {
        let dataObject = {
            journey_id: parseInt(selectedJourneyId),
            vendor_id: parseInt(id),
            journey_url:
                orderItemJourneys &&
                orderItemJourneys.length > 0 &&
                orderItemJourneys.filter((ele) => parseInt(ele.id) === parseInt(selectedJourneyId))[0].url,
        }
        MarketplaceServices.update(settlementJourneyAPI, dataObject, {
            _id: alreadyMappedId,
        })
            .then(function (response) {
                console.log('Server response from updateSettlementJourney is', settlementJourneyAPI, response)
                MarketplaceToaster.showToast(response)
                // toast(
                //   `${t(
                //     "transactions:settlement_journey_update_mapping_is_successfully"
                //   )}`,
                //   {
                //     position: toast.POSITION.TOP_RIGHT,
                //     type: "success",
                //     autoClose: 10000,
                //   }
                // );
                setButtonDisabled(true)
            })
            .catch(function (error) {
                console.log(
                    'errorServer response from updateSettlementJourney is',
                    settlementJourneyAPI,
                    error.response
                )
                MarketplaceToaster.showToast(error.response)
                // toast(error.response.data.message, {
                //   position: toast.POSITION.TOP_RIGHT,
                //   type: "error",
                //   autoClose: 10000,
                // });
                setButtonDisabled(true)
            })
    }

    const saveSettlement = () => {
        let dataObject = {
            journey_id: parseInt(selectedJourneyId),
            journey_url:
                orderItemJourneys &&
                orderItemJourneys.length > 0 &&
                orderItemJourneys.filter((ele) => parseInt(ele.id) === parseInt(selectedJourneyId))[0].url,
            vendor_id: parseInt(id),
        }
        // axios
        //   .post(settlementJourneyAPI, dataObject, authorizationHeader)
        MarketplaceServices.save(settlementJourneyAPI, dataObject, null)
            .then(function (response) {
                console.log('Server response from v API is', settlementJourneyAPI, response.data.response_body)
                setAlreadyMappedId(response.data.response_body.id)

                MarketplaceToaster.showToast(response)
                // toast(
                //   `${t(
                //     "transactions:settlement_journey_update_mapping_is_successfully"
                //   )}`,
                //   {
                //     position: toast.POSITION.TOP_RIGHT,
                //     type: "success",
                //     autoClose: 10000,
                //   }
                // );
                setButtonDisabled(true)
            })
            .catch(function (error) {
                MarketplaceToaster.showToast(error.response)
                console.log('error Server response from v API is', settlementJourneyAPI, error)
                setButtonDisabled(true)
                // toast(
                //   `${t("transactions:settlement_journey_mapping_is_unSuccessfully")}`,
                //   {
                //     position: toast.POSITION.TOP_RIGHT,
                //     type: "error",
                //     autoClose: 10000,
                //   }
                // );
            })
    }

    const findAllMappedJourney = () => {
        // axios
        //   .get(
        //     settlementJourneyAPI,
        //     {
        //       params: {
        //         "page-number": -1,
        //       },
        //     },
        //     authorizationHeader
        //   )
        MarketplaceServices.findAll(settlementJourneyAPI, null, false)
            .then((response) => {
                console.log(
                    'Server response from findAllMappedJourney API is',
                    settlementJourneyAPI,
                    response.data.response_body.data
                )
                setMappedJourneys(response.data.response_body.data.settlement_mapping_data)
            })
            .catch((error) => {
                console.log('ERROR Server response from findAllMappedJourney API is', settlementJourneyAPI, error)
                setMappedJourneys([])
            })
    }

    useEffect(() => {
        if (mappedJourneys && mappedJourneys.length > 0 && orderItemJourneys && orderItemJourneys.length > 0) {
            let mappedVendorJourney =
                mappedJourneys &&
                mappedJourneys.length > 0 &&
                mappedJourneys.filter((ele) => ele.vendor_id === parseInt(id))

            if (mappedVendorJourney && mappedVendorJourney.length > 0) {
                setAlreadyMappedId(mappedVendorJourney[0].id)

                let filterOrderItemJourneys = orderItemJourneys.filter(
                    (ele) => ele.url === mappedVendorJourney[0].journey_url
                )

                if (filterOrderItemJourneys && filterOrderItemJourneys.length > 0) {
                    setSelectedJourneyId(filterOrderItemJourneys[0].id)
                    setCanvasIframeURL(
                        filterOrderItemJourneys[0].id,
                        filterOrderItemJourneys[0].journey_flow_name,
                        filterOrderItemJourneys[0].journey_package_name
                    )
                }
            }
        }
    }, [mappedJourneys, orderItemJourneys, id])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Layout className='!bg-transparent'>
            <Title level={4}> {t('transactions:configure_settlement_journey')}</Title>
            <Text> {t('transactions:settlement_journey')}</Text>

            <Content className='text-left'>
                <Content>
                    <Select
                        value={selectedJourneyId}
                        onChange={orderJourneyChange}
                        className='w-[50%] my-2'
                        status={validJourneySelect === true ? 'error' : ''}>
                        {orderItemJourneys &&
                            orderItemJourneys.length > 0 &&
                            orderItemJourneys.map((item) => (
                                <Option
                                    key={item.id}
                                    value={item.id}
                                    journeyId={item.id}
                                    journeyPackageName={item.journey_package_name}
                                    journeyFlowName={item.journey_flow_name}
                                    flowInfo={item.flow_info}>
                                    {t(`coupler:${util.getCouplerMultilingualKey(item.journey_flow_name)}`)}
                                </Option>
                            ))}
                    </Select>
                    {/* <Button
            type="text"
            className="float-right app-btn-secondary"
            onClick={() =>
              window.open(canvasCreateSettlementJourneyUrl, "_blank")
            }
          >
            Customize settlement Journey
          </Button> */}
                    <Button
                        type='text'
                        className='float-right app-btn-secondary my-2'
                        disabled={buttonDisabled}
                        onClick={() => validationSettlementJourney()}>
                        {alreadyMappedId === undefined ? (
                            <>{t('transactions:save_journey_mapping')}</>
                        ) : (
                            <>{t('transactions:update_journey_mapping')}</>
                        )}
                    </Button>
                </Content>

                {iframeScr ? (
                    <Content className='h-[300px] overflow-auto'>
                        <iframe
                            id='canvasIframe'
                            src={iframeScr}
                            loading='eager'
                            title='journey canvas'
                            width='100%'
                            height='627'
                        />
                    </Content>
                ) : (
                    <Content className='bg-slate-100 p-10 text-center my-2'>
                        <Content>
                            <InboxOutlined className='my-2 text-2xl' />
                        </Content>
                        <h1>{t('transactions:journey_not_mapped')}</h1>
                    </Content>
                )}
            </Content>
        </Layout>
    )
}

export default Settings
