import React from 'react'

const EyeIcon = () => {
    return (
        <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M10.9984 15.5266C6.66719 15.5266 4.52344 11.3922 4.43594 11.2172C4.37031 11.0859 4.37031 10.9109 4.43594 10.7797C4.52344 10.6047 6.66719 6.49219 10.9984 6.49219C15.3297 6.49219 17.4734 10.6047 17.5609 10.7797C17.6266 10.9109 17.6266 11.0859 17.5609 11.2172C17.4734 11.3922 15.3297 15.5266 10.9984 15.5266ZM5.44219 10.9984C5.92344 11.8078 7.80469 14.5422 10.9984 14.5422C14.1922 14.5422 16.0734 11.8078 16.5547 10.9984C16.0734 10.1891 14.1922 7.45469 10.9984 7.45469C7.80469 7.45469 5.92344 10.1891 5.44219 10.9984Z'
                fill='#637381'
            />
            <path
                d='M11 12.8594C9.97188 12.8594 9.14062 12.0281 9.14062 11C9.14062 9.97188 9.97188 9.14062 11 9.14062C12.0281 9.14062 12.8594 9.97188 12.8594 11C12.8594 12.0281 12.0281 12.8594 11 12.8594ZM11 10.125C10.5187 10.125 10.125 10.5187 10.125 11C10.125 11.4813 10.5187 11.875 11 11.875C11.4813 11.875 11.875 11.4813 11.875 11C11.875 10.5187 11.4813 10.125 11 10.125Z'
                fill='#637381'
            />
        </svg>
    )
}

export default EyeIcon
