import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_PRODUCT_VARIANTS_ATTRIBUTES_API

/**
 * !This function is a DELETE Method to remove the attribute as a variant attribute for a particular product template revision.
 * @param {*} attributeId -> the attribute id which we are passing to add that attribute as a variant attribute.
 * @param {*} productTemplateRevisionId -> this is the product template revision id we are passing to link the attribute as a variant attribute to the passed product template revisionId.\
 * * @returns it returns the API response from the async method.
 */
const useSaveVariantAttributes = () => {
    const saveVariantAttributes = async ({ postBody }) => {
        const res = await MarketplaceServices.save(BASE_URL, postBody)
        console.log('useSaveVariantAttributes', res)
        return res
    }
    return useMutation({ mutationFn: saveVariantAttributes })
}
export default useSaveVariantAttributes
