import React from 'react'

const ShoppingBagIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M9.46894 5.15625C10.4689 5.15625 11.2814 5.90625 11.4064 6.875L12.3127 15.125C12.3752 15.6563 12.2189 16.2188 11.8439 16.625C11.5002 17.0312 10.9689 17.2812 10.4064 17.2812H2.46894C1.93769 17.2812 1.40644 17.0312 1.03144 16.625C0.68769 16.2188 0.50019 15.6875 0.56269 15.125L1.50019 6.875C1.62519 5.90625 2.43769 5.15625 3.43769 5.15625H3.75019C3.90644 3.8125 5.03144 2.75 6.43769 2.75C7.84394 2.75 8.96894 3.8125 9.12519 5.15625H9.46894ZM7.71894 5.15625C7.59394 4.59375 7.09394 4.15625 6.46894 4.15625C5.84394 4.15625 5.34394 4.5625 5.21894 5.15625H7.71894ZM10.4064 15.875C10.6252 15.875 10.7502 15.75 10.8127 15.6875C10.8752 15.625 10.9689 15.4688 10.9064 15.2813L9.96894 7.03125C9.93769 6.75 9.71894 6.5625 9.43769 6.5625H8.37519H4.50019H3.43769C3.15644 6.5625 2.93769 6.75 2.90644 7.03125L1.96894 15.2813C1.93769 15.4688 2.03144 15.625 2.09394 15.6875C2.15644 15.75 2.28144 15.875 2.50019 15.875H10.4064ZM14.9689 6.75C15.3439 6.75 15.6564 7.0625 15.6564 7.4375C15.6564 7.8125 15.3127 8.125 14.9689 8.125H13.0627C12.6877 8.125 12.3752 7.8125 12.3752 7.4375C12.3752 7.0625 12.6877 6.75 13.0627 6.75H14.9689ZM17.3439 10.8438C17.3439 10.4688 17.0314 10.1562 16.6564 10.1562H13.9064C13.5314 10.1562 13.2189 10.4688 13.2189 10.8438C13.2189 11.2188 13.5314 11.5313 13.9064 11.5313H16.6564C17.0314 11.5625 17.3439 11.25 17.3439 10.8438ZM14.7189 13.5625H18.7502C19.1252 13.5625 19.4377 13.875 19.4377 14.25C19.4377 14.625 19.1252 14.9375 18.7502 14.9375H14.7189C14.3439 14.9375 14.0314 14.625 14.0314 14.25C14.0314 13.875 14.3439 13.5625 14.7189 13.5625Z'
                fill='#8899A8'
            />
        </svg>
    )
}

export default ShoppingBagIcon
