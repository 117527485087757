import { Button, Layout, Divider } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StoreLogo } from '../../constants/media'
import util from '../../util/common'
const { Header, Content } = Layout
const Preview = ({
    headerBackgroundColor,
    headerForegroundColor,
    footerBackgroundColor,
    footerForegroundColor,
    pageBackgroundColor,
    foreGroundColor,
    buttonPrimaryBackgroundColor,
    buttonSecondaryBackgroundColor,
    // buttonTeritaryBackgroundColor,
    buttonPrimaryForegroundColor,
    buttonSecondaryForegroundColor,
    buttonTeritaryForegroundColor,
    getImageData,
}) => {
    const { t } = useTranslation()
    const absoluteStoreImageInfo = useSelector((state) => state.reducerAbsoluteStoreImageInfo.absoluteStoreImageInfo)
    return (
        <Content>
            <Divider style={{ margin: '8px 0' }} />
            <Content className='flex !mb-2 bg-[var(--mp-theme-preview-header-background-color)]'>
                <div className='flex justify-start !w-[20%] p-2 '>
                    {getImageData && getImageData.length > 0 ? (
                        absoluteStoreImageInfo && absoluteStoreImageInfo.type === 'store_logo' ? (
                            <img
                                src={absoluteStoreImageInfo && absoluteStoreImageInfo.value}
                                alt='storeImageIcon'
                                height={60}
                                width={100}
                            />
                        ) : (
                            <img src={StoreLogo} alt='StoreLogo' height={60} width={100} />
                        )
                    ) : (
                        <img src={StoreLogo} alt='StoreLogo' height={60} width={100} />
                    )}
                </div>
                <div className='!flex !w-[58%]  !justify-center !text-[#6d7b88] !mt-4'>
                    {t('store_settings:header_content_of_the_page')}
                </div>
            </Content>
            <Divider style={{ margin: '8px 0' }} />
            <Content className={`min-h-[300px] text-center bg-[var(--mp-theme-preview-page-content-background-color)]`}>
                <p className={`text-center !text-[#6d7b88] !mt-8 !mr-6`}>
                    {t('store_settings:main_content_of_the_page')}
                </p>
                <Content className='text-center p-24 '>
                    <Button
                        style={{
                            backgroundColor: buttonPrimaryBackgroundColor,
                            color: buttonPrimaryForegroundColor,
                            border: buttonPrimaryBackgroundColor,
                        }}>
                        {t('store_settings:primary_button')}
                    </Button>
                    <Button
                        className={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : 'ml-4'}
                        style={{
                            backgroundColor: buttonSecondaryBackgroundColor,
                            color: buttonSecondaryForegroundColor,
                            border: buttonSecondaryBackgroundColor,
                        }}>
                        {t('store_settings:secondary_button')}
                    </Button>
                    <Button
                        type='link'
                        className={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-8' : 'ml-8'}
                        style={{
                            // backgroundColor: buttonTeritaryBackgroundColor,
                            color: buttonTeritaryForegroundColor,
                            // border: buttonTeritaryBackgroundColor,
                        }}>
                        {t('store_settings:teritiary_button')}
                    </Button>
                </Content>
            </Content>
            <Content
                className={`!h-24 flex items-center justify-center bg-[var(--mp-theme-preview-footer-background-color)]`}>
                <p className=''>{t('store_settings:footer_content_of_the_page')}</p>
            </Content>
        </Content>
    )
}

export default Preview
