import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdInfo } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Toggle from 'react-toggle'
import {
    ApplicationProdTypeLogo,
    BookableProdTypeLogo,
    BuyableProdTypeLogo,
    DigitalProdTypeLogo,
    ServiceProdTypeLogo,
} from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import { Button, Typography, Tooltip } from 'antd'

const productTypeAPI = process.env.REACT_APP_PRODUCT_TYPE_API
const productTypeLayoutAPI = process.env.REACT_APP_PRODUCT_TYPE_LAYOUT_API

//! Destructure the components
const { Title } = Typography

function ProductTypeOptions2({
    typeId,
    storeProductTypeId,
    title,
    changeStatus,
    status,
    totalTypesEnabled,
    description,
}) {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [statusChanged, setStatusChanged] = useState(false)
    // get permissions from storage
    const permissionValue = util.getPermissionData() || []
    const [langDirection, setLangDirection] = useState('ltr')
    const closeModel = () => {
        setIsModalOpen(false)
    }

    const openModel = (e) => {
        setStatusChanged(e.target.checked)
        setIsModalOpen(true)
    }

    const requestServer = async () => {
        const updatedStatus = statusChanged ? 'supported' : 'unsupported'
        const requestBody = {
            product_types: [
                {
                    product_type_id: typeId,
                    status: updatedStatus,
                },
            ],
        }

        const successMsg =
            `${t('product_types:the')} ` +
            title +
            '' +
            ' ' +
            `${t('product_types:type_successfully')}` +
            ' ' +
            (statusChanged ? `${t('product_types:activated')}` : `${t('product_types:deactivated')}`)
        await MarketplaceServices.update(productTypeAPI, requestBody, null)
            .then((response) => {
                console.log('response', response)
                changeStatus(statusChanged, typeId)
                toast(successMsg, {
                    type: 'success',
                    autoClose: 10000,
                })
            })
            .catch((error) => {
                toast(`${t('product_types:deactivation_failed')}`, {
                    type: 'error',
                    autoClose: 10000,
                })
                console.log('error', error)
            })
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    const ModelPopUp = ({ title, handler }) => {
        return (
            <div
                className={`${
                    isModalOpen ? 'flex' : 'hidden'
                } justify-center items-center bg-opacity-50 bg-black fixed top-0 right-0 left-0 z-50 w-full md:inset-0 md:h-full`}>
                <div className='relative max-w-[672px] h-screen md:h-auto'>
                    {/* Modal content --> */}
                    <div className='relative bg-white p-3 shadow dark:bg-gray-700'>
                        {/* <!-- Modal header --> */}
                        <div className='flex justify-between items-center'>
                            <div className='text-[23px]  text-gray-900'>
                                {statusChanged
                                    ? `${t('product_types:product_activation')}`
                                    : `${t('product_types:product_deactivation')}`}
                            </div>
                        </div>
                        {/* <!-- Modal body --> */}
                        {statusChanged ? (
                            <div className='mt-4'>
                                <p className='!font-bold'>{`${t('product_types:awesome')}`}</p>
                                <p className='!mt-2'>
                                    {`${t('product_types:about_to_activate')}` +
                                        ` "${title}" ` +
                                        `${t('product_types:category')} ` +
                                        `${t('product_types:would_you_like_to_proceed')}`}
                                </p>
                            </div>
                        ) : (
                            <div className='mt-4'>
                                <p className='!font-semibold'>
                                    {t('product_types:about_to_deactivate')} {'"'}
                                    {title}
                                    {'"'} {t('product_types:from_your_store')}
                                    {/* {`${t("product_types:proceed_deactivating")}`} */}
                                </p>
                                <p className='mt-2'>
                                    {t('product_types:are_you_sure_you_want_to_proceed_with_deactivating_the')}
                                    {'"'}
                                    {title}
                                    {'"'} {t('product_types:category')}?
                                </p>
                            </div>
                        )}

                        {/* <!-- Modal footer --> */}
                        <div className='flex items-center justify-end mt-[2.5rem]'>
                            <Button
                                type='button'
                                onClick={() => closeModel()}
                                className={`app-btn-secondary  ${langDirection === 'rtl' ? '!ml-4' : '!mr-4'}`}>
                                {t('common:discard')}
                            </Button>
                            <Button
                                type='button'
                                className={` ${statusChanged ? 'app-btn-primary' : 'app-btn-danger'} `}
                                onClick={() => {
                                    closeModel()
                                    requestServer()
                                }}>
                                {t('common:yes')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getProdTypeLogo = (productypeId) => {
        switch (productypeId) {
            // 5 "Subscription Product"
            case 5:
                return <img src={BookableProdTypeLogo} alt='icon' className='w-6' />
            // 1 "Physical Product"
            case 1:
                return <img src={BuyableProdTypeLogo} alt='icon' className='w-6' />
            // 2 "Bundled Product"
            case 2:
                return <img src={ApplicationProdTypeLogo} alt='icon' className='w-6' />
            // 3 "Service Product"
            case 3:
                return <img src={ServiceProdTypeLogo} alt='icon' className='w-6' />
            // 4 "Digital Product"
            case 4:
                return <img src={DigitalProdTypeLogo} alt='icon' className='w-6' />
            default:
                return <img src='' alt='icon' />
        }
    }

    return (
        <div className={`flex  border-b-[1px] border-[#C6C6C6]  py-3 ${langDirection === 'rtl' ? '!ml-3' : '!mr-3'}`}>
            <ModelPopUp title={title}></ModelPopUp>
            {/* title and its description */}
            <div className='w-[800px]'>
                <div className='flex items-center'>
                    <div>{getProdTypeLogo(typeId)}</div>
                    <Link
                        to={{
                            pathname: `/dashboard/settings/store-product-types`,
                            search: `?${MarketplaceAppConfig.getStore('')}&product_type_id=${typeId}&product_type_name=${title}&store_product_type_id=${storeProductTypeId} `,
                        }}>
                        <div className={`pl-2 ${langDirection === 'rtl' ? '!pr-2' : ''}`}>
                            {/* {title} */}
                            <Title
                                level={4}
                                className='!font-medium !text-[15px] app-btn-link max-w-[500px]'
                                ellipsis={{ tooltip: title }}>
                                {title}
                            </Title>
                        </div>
                    </Link>
                </div>
                <div className={`text-[15px] pl-8 ${langDirection === 'rtl' ? '!pr-8' : ''}`}>{description}</div>
            </div>

            {/* switch icon  */}
            <div className='px-4 pt-1 w-[150px] text-[#393939]'>
                {t('product_types:status')}
                <div className='flex pt-1 items-center'>
                    {totalTypesEnabled && totalTypesEnabled.length === 0 ? (
                        <Toggle
                            checked={status}
                            icons={false}
                            disabled={false}
                            className='dm-toggle'
                            onChange={(e) => {
                                openModel(e)
                            }}
                        />
                    ) : (
                        <Toggle
                            checked={status}
                            icons={false}
                            disabled={
                                totalTypesEnabled &&
                                typeof totalTypesEnabled !== 'object' &&
                                totalTypesEnabled.length > 0 &&
                                totalTypesEnabled[0].name === title &&
                                totalTypesEnabled.length === 1 &&
                                permissionValue &&
                                permissionValue.length > 0 &&
                                !permissionValue.includes('UI-update-producttype-status')
                                    ? true
                                    : false
                            }
                            className='dm-toggle'
                            onChange={(e) => {
                                openModel(e)
                            }}
                        />
                    )}
                    {totalTypesEnabled && totalTypesEnabled.length === 0 ? (
                        <div className={`pl-1 text-[#393939]`}>{t('dashboard:inactive')}</div>
                    ) : (
                        <div
                            className={`pl-1 ${langDirection === 'rtl' ? '!pr-1' : ''} ${
                                totalTypesEnabled &&
                                typeof totalTypesEnabled !== 'object' &&
                                totalTypesEnabled.length > 0 &&
                                totalTypesEnabled[0].name === title &&
                                totalTypesEnabled.length === 1
                                    ? 'text-gray-400'
                                    : 'text-[#393939]'
                            }`}>
                            {status ? `${t('dashboard:active')}` : `${t('dashboard:inactive')}`}
                        </div>
                    )}
                </div>
            </div>
            {totalTypesEnabled &&
            totalTypesEnabled.length > 0 &&
            totalTypesEnabled[0].name === title &&
            totalTypesEnabled.length === 1 ? (
                <div className='flex items-start w-[400px]'>
                    <MdInfo className='text-black text-[43px]' />
                    <div className='pt-2 px-1 text-[13px]'>
                        {t('product_types:at_least_one_activated_product_type')}
                    </div>
                </div>
            ) : (
                <div className='w-[400px]'></div>
            )}
        </div>
    )
}

export default ProductTypeOptions2
