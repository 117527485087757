import { PlusOutlined } from '@ant-design/icons'
import { Button, Layout, Skeleton, Typography, Collapse } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import MarketplaceAppConfig from '../../../util/MarketplaceMutlitenancy'
import useGetProductVariants from '../hooks/useGetVariants'
import ListProductVariantAttributes from './ListProductVariantAttributes'
import DynamicTable from '../../tablelayoutcomponent/DynamicTable'
import { createJourneyIcon } from '../../../constants/media'
import util from '../../../util/common'

const { Content } = Layout
const { Title } = Typography
const { Panel } = Collapse

function ListVariants() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const search = useLocation().search
    /**
     * !Defined variables to read the URL search params.
     */
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    let productTemplateStatus = new URLSearchParams(search).get('ptss')
    const revisionSelectedInfo = useSelector((state) => state.reducerRevisionInfo.revisionSelectedInfo)
    const { data: productVariantsAPIData, status: variantsStatus } = useGetProductVariants({
        productTemplateRevisionId: productTemplateRevisionId,
    })
    const [variantDataForTable, setVariantDataForTable] = useState([])
    const [productVariantAttributesData, setProductVariantAttributesData] = useState([])
    /**
     * !In the below useEffect constructing the required data for the generated variants related table.
     */
    useEffect(() => {
        let tempArray = []
        let individualObject = {}
        productVariantsAPIData &&
            productVariantsAPIData.length > 0 &&
            productVariantsAPIData.forEach((ele, iteratingId) => {
                individualObject = {}
                individualObject.name = ele.variant_name
                individualObject.id = ele.id
                individualObject.key = iteratingId
                tempArray.push(individualObject)
            })
        setVariantDataForTable(tempArray)
    }, [productVariantsAPIData])
    /**
     * !The below are the table columns for the variant related table.
     */
    const tableHeader = [
        {
            title: `${t('common:variant_name')}`,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <div className={'text-[#DC3E3E] text-lg font-semibold'}>{record.name}</div>
            },
        },
    ]
    /**
     * !TablePropsData to render the table columns,data,pagination
     */
    const tablePropsData = {
        table_header: tableHeader,
        table_content: variantDataForTable,

        search_settings: {
            is_enabled: false,
            search_title: 'Search by name',
            search_data: ['name'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: "Filter's",
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }
    return (
        <Collapse accordion bordered={true} className=' !mb-6 bg-white !pt-4'>
            <Panel
                header={
                    <div className='flex justify-between !pb-3'>
                        <p className='font-semibold text-lg text-regal-blue'>{t('layouts:variant_details')}</p>
                        {revisionSelectedInfo &&
                        parseInt(revisionSelectedInfo.product_revision_status) === parseInt(1) ? (
                            <div
                                className={`flex flex-row w-max border-[1px] border-[#D9D9D9]  rounded-md py-2 cursor-pointer hover:border-[#fb8500] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pr-5' : '!pl-5'}`}
                                onClick={() =>
                                    navigate(
                                        `/dashboard/product-template/add-variant-attribute?ptsid=${
                                            revisionSelectedInfo && revisionSelectedInfo.product_template
                                        }&ptsrid=${revisionSelectedInfo && revisionSelectedInfo.id}&ptrss=${
                                            revisionSelectedInfo && revisionSelectedInfo.product_revision_status
                                        }&ptss=${productTemplateStatus}&${MarketplaceAppConfig.getStore('')}`
                                    )
                                }>
                                <img src={createJourneyIcon} width={14} height={14} />
                                <p
                                    className={`font-medium text-sm text-regal-blue  mx-2`}>
                                    {t('product_template:add_variant_attributes')}
                                </p>
                            </div>
                        ) : null}
                    </div>
                }>
                <Content>
                    <p className='mt-3 pl-2 !font-normal text-sm text-[#8899A8]'>
                        {t('product_template:variant_attributes_info')}
                    </p>

                    <Content>
                        <ListProductVariantAttributes
                            productVariantAttributesData={productVariantAttributesData}
                            setProductVariantAttributesData={setProductVariantAttributesData}
                        />
                    </Content>
                    {productVariantAttributesData &&
                    productVariantAttributesData.length === 0 &&
                    revisionSelectedInfo &&
                    parseInt(revisionSelectedInfo.product_revision_status) === parseInt(1) ? (
                        <Content className='!flex !justify-center !items-center '>
                            <div
                                className={`flex flex-row w-max border-[1px] border-[#D9D9D9]  rounded-md py-2 cursor-pointer hover:border-[#fb8500] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pr-5' : '!pl-5'}`}
                                onClick={() =>
                                    navigate(
                                        `/dashboard/product-template/add-variant-attribute?ptsid=${
                                            revisionSelectedInfo && revisionSelectedInfo.product_template
                                        }&ptsrid=${revisionSelectedInfo && revisionSelectedInfo.id}&ptrss=${
                                            revisionSelectedInfo && revisionSelectedInfo.product_revision_status
                                        }&ptss=${productTemplateStatus}&${MarketplaceAppConfig.getStore('')}`
                                    )
                                }>
                                <img src={createJourneyIcon} width={14} height={14} />
                                <p
                                    className={`font-medium text-sm text-regal-blue  mx-2`}>
                                    {t('product_template:add_variant_attributes')}
                                </p>
                            </div>
                        </Content>
                    ) : null}
                    {variantDataForTable && variantDataForTable.length > 0 ? (
                        <>
                            <h5 level={5} className='mb-3 !font-semibold'>
                                {t('common:variants')}
                            </h5>
                            <Content className='!flex !flex-col'>
                                {variantsStatus === 'pending' ? (
                                    <Skeleton paragraph={{ rows: 3 }} className='p-3' />
                                ) : variantsStatus === 'error' ? (
                                    <h5>{t('messages:access_validation')}</h5>
                                ) : (
                                    <>
                                        <DynamicTable tableComponentData={tablePropsData} />
                                    </>
                                )}
                            </Content>
                        </>
                    ) : null}
                </Content>
            </Panel>
        </Collapse>
    )
}

export default ListVariants
