import { Layout, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import '../../core-ui/footer.css'
import useGetStoreAdminConsent from '../../hooks/useGetStoreAdminConsent'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'

const { Content } = Layout
const { Text, Paragraph, Link: AntLink } = Typography

const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const NewFooter = () => {
    const { data: userConsentData } = useGetStoreAdminConsent()
    const { t } = useTranslation()

    return (
        <div className=' bg-white py-6  max-h-[96px] text-center p-[2px] flex flex-col gap-2 items-center justify-center'>
            <>
                {userConsentData && userConsentData.length > 0 ? (
                    <div className=' text-xs  gap-x-3  flex max-h-[45px] overflow-y-auto items-center flex-wrap justify-center'>
                        {userConsentData?.map((data, index) => {
                            return (
                                <>
                                    {index <= 3 ? (
                                        <>
                                            <Link
                                                to={`/dashboard/userprofile?tab=policies&subtab=${
                                                    data?.id
                                                }&store=${MarketplaceAppConfig.getStore('onlyClient')}`}>
                                                <div className=' max-w-[280px]'>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: {
                                                                title: data?.version_details?.consent_display_name,
                                                                mouseLeaveDelay: 0,
                                                                mouseEnterDelay: 0.5,
                                                            },
                                                        }}
                                                        className='!text-xs text-brandGray1'>
                                                        {data?.version_details?.consent_display_name}
                                                    </Text>
                                                </div>
                                            </Link>
                                            {index !== userConsentData?.length - 1 ? (
                                                <Text className='text-brandGray'>|</Text>
                                            ) : null}
                                        </>
                                    ) : null}
                                </>
                            )
                        })}
                        {userConsentData?.length > 4 ? (
                            <Link
                                className='  !text-brandGray1 no-underline'
                                to={`/dashboard/userprofile?tab=policies&subtab=${userConsentData && userConsentData[0].id}&store=${MarketplaceAppConfig.getStore('onlyClient')}`}>
                                {t('policies:show_more')}
                            </Link>
                        ) : null}
                    </div>
                ) : null}
            </>

            {t('common:footer_content') !== 'Not Applicable' && (
                <Paragraph className='text-brandGray2 text-xs !mb-0'>{t('common:footer_content')}</Paragraph>
            )}
        </div>
    )
}

export default NewFooter
