export const validatingModule = [
    {
        id: 1,
        labelName: 'Order',
        value: 'Order',
    },
    {
        id: 2,
        labelName: 'OrderLine Item',
        value: 'OrderLineItem',
    },
]

export const validatingFields = [
    {
        Order: [
            {
                id: 3,
                labelName: 'Amount',
                value: 'Amount',
            },
        ],
    },
    {
        OrderLineItem: [
            {
                id: 4,
                labelName: 'Product',
                value: 'Product',
            },
            {
                id: 5,
                labelName: 'Store Product Type',
                value: 'Store Product Type',
            },
            {
                id: 7,
                labelName: 'Quantity',
                value: 'Quantity',
            },
        ],
    },
]

export const validatiors = [
    {
        id: 8,
        labelName: 'Equal to ( == )',
        value: '==',
    },
    {
        id: 8,
        labelName: 'Greater ( > )',
        value: '>',
    },
    {
        id: 8,
        labelName: 'Lesser ( < )',
        value: '<',
    },
    {
        id: 8,
        labelName: 'Greater then equal to ( >= )',
        value: '>=',
    },
    {
        id: 8,
        labelName: 'Lesser then equal to ( <= )',
        value: '<=',
    },
]

export const validatingTargets = [
    {
        id: 1,
        labelName: 'Order',
        value: 'Order',
    },
    {
        id: 1,
        labelName: 'Placed',
        value: 'Placed',
    },
    {
        id: 1,
        labelName: 'Attempted',
        value: 'attempted',
    },
    {
        id: 1,
        labelName: 'Created',
        value: 'Created',
    },
    {
        id: 1,
        labelName: 'Paid',
        value: 'paid',
    },
    {
        id: 1,
        labelName: 'Pending',
        value: 'pending',
    },
    {
        id: 1,
        labelName: 'Processed',
        value: 'processed',
    },
    {
        id: 1,
        labelName: 'Cancelled',
        value: 'cancelled',
    },
    {
        id: 1,
        labelName: 'Partially cancelled',
        value: 'partially cancelled',
    },
    {
        id: 1,
        labelName: 'Completed',
        value: 'Completed',
    },
]
