//! data processor for productTemplateLists
const productTemplateListsDataProcessor = (productTemplateLists) => {
    let localData = []
    let temp = {}
    if (productTemplateLists && productTemplateLists.length > 0) {
        for (var i = 0; i < productTemplateLists.length; i++) {
            temp = {
                key: productTemplateLists[i].product_template.id,
                ...productTemplateLists[i],
            }
            localData.push(temp)
        }
        return localData
    } else {
        return localData
    }
}
export default productTemplateListsDataProcessor
