import { Card, Col, Row } from 'antd'
import React, { useState } from 'react'
import attibuteDataTypeSettings from '../../constants/AttributeDataTypeSettings'
import attibuteDataTypeSettingsForVariant from '../../constants/AttributeDataTypeSettingsForVariants'
import './productoption.css'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import attribDataTypeResponse from '../../constants/AttributeDataTypeStaticResponse'
const { Meta } = Card
function ProductChooseOptions({
    closeChooseOptionWindow,
    openAddOptionValuesForm,
    attributeDataType,
    closeAttributeCreationModal,
    type,
    Choices,
}) {
    const { t } = useTranslation()
    const [selectedtabId, setSelectedTabId] = useState(1)
    const [selectedTabDataType, setSelectedTabDataType] = useState(
        Choices === 'Variant' ? attibuteDataTypeSettingsForVariant[0].dataTypes : attibuteDataTypeSettings[0].dataTypes
    )

    const attributeDataTypeProcessor = (dataTypes) => {
        let groupedTabdataTypes = []
        dataTypes.forEach(({ dataTypeId }) => {
            attribDataTypeResponse.forEach((obj) => {
                if (dataTypeId == obj.id) {
                    groupedTabdataTypes.push(obj)
                }
            })
        })
        return groupedTabdataTypes
    }

    const handleChildTab = (tab) => {
        if (Choices === 'Variant') {
        } else {
            setSelectedTabId(tab)
            setSelectedTabDataType(
                attibuteDataTypeSettings.filter(({ key }) => parseInt(key) === parseInt(tab))[0].dataTypes
            )
        }
    }

    return (
        <div className='p-3'>
            <div className='chooseoption_button'>
                <div className='!font-bold mb-3'>{t('attributes:choose_component')}</div>
                <CloseOutlined role={'button'} onClick={() => closeAttributeCreationModal()}></CloseOutlined>
            </div>
            <DmTabAntDesign
                tabData={
                    type === 'StoreProducttype_Revision'
                        ? attibuteDataTypeSettings.filter(({ key }) => key !== 3)
                        : Choices === 'Variant'
                          ? attibuteDataTypeSettingsForVariant
                          : attibuteDataTypeSettings
                }
                defaultSelectedTabKey={1}
                handleTabChangeFunction={handleChildTab}
            />
            {attribDataTypeResponse && attribDataTypeResponse.length > 0 ? (
                <Row gutter={[32, { xs: 8, sm: 16, md: 24, lg: 32 }]} align='middle' className='!mt-4'>
                    {attributeDataTypeProcessor(selectedTabDataType).length > 0 ? (
                        attributeDataTypeProcessor(selectedTabDataType).map((option) => {
                            return (
                                <Col key={`${option.name}_${option.id}`}>
                                    <Card
                                        hoverable
                                        style={{
                                            width: 220,
                                            minHeight: 270,
                                        }}
                                        cover={
                                            selectedTabDataType.filter((obj) => obj.dataTypeId === option.id)[0] ? (
                                                <img
                                                    alt={option.name}
                                                    src={
                                                        selectedTabDataType.filter(
                                                            (obj) => obj.dataTypeId === option.id
                                                        )[0] &&
                                                        selectedTabDataType.filter(
                                                            (obj) => obj.dataTypeId === option.id
                                                        )[0].image
                                                    }
                                                />
                                            ) : (
                                                ''
                                            )
                                        }
                                        onClick={() => {
                                            if (
                                                (option.id == 17 || option.id == 28 || option.id == 29) &&
                                                selectedtabId != 4
                                            ) {
                                                // console.log("No handlerss.. for", option.name, option.id);
                                            } else if (
                                                option.id != 20 &&
                                                option.id != 22 &&
                                                option.id !== 21 &&
                                                option.id !== 23 &&
                                                option.id !== 24 &&
                                                option.id !== 25 &&
                                                option.id !== 26 &&
                                                selectedtabId == 4
                                            ) {
                                                // console.log("No handlerss.. for", option.name, option.id);
                                            } else {
                                                openAddOptionValuesForm(
                                                    option.name,
                                                    option.id,
                                                    option.regex,
                                                    option.regex_exception_message,
                                                    option.data_type_group,
                                                    option.attribute_unit,
                                                    option.minimum_number_values,
                                                    option.maximum_number_values,
                                                    option.attribute_type,
                                                    option.extension,
                                                    option.file_type,
                                                    selectedtabId
                                                )
                                            }
                                        }}
                                        className={
                                            option.id == 17 || option.id == 28 || option.id == 29
                                                ? 'opacity-50 cursor-not-allowed'
                                                : (selectedtabId == 4 && option.id == 20) ||
                                                    option.id == 22 ||
                                                    option.id == 21 ||
                                                    option.id == 23 ||
                                                    option.id == 24 ||
                                                    option.id == 25 ||
                                                    option.id == 26
                                                  ? ''
                                                  : selectedtabId != 4
                                                    ? ''
                                                    : 'opacity-50 cursor-not-allowed'
                                        }>
                                        <Meta
                                            className='text-xs'
                                            title={option.display_name}
                                            description={option.description}
                                        />
                                    </Card>
                                </Col>
                            )
                        })
                    ) : (
                        <div>{t('attributes:attributes_not_available')}</div>
                    )}
                </Row>
            ) : (
                <Row className='flex justify-start items-start mt-8'>
                    <div className='text-xl text-red-600'>{t('attributes:attribute_datatype_not_available')}</div>
                </Row>
            )}
        </div>
    )
}

export default ProductChooseOptions
