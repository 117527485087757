import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_NEWLY_CREATED_ATTRIBUTE_INFO,
    RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO,
    RDX_ALREADY_CREATED_ATTRIBUTE_INFO,
    RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO,
    RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO,
    RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO,
} = ActionTypes

export const fnNewlyCreatedAttributeData = (data) => {
    return {
        type: RDX_NEWLY_CREATED_ATTRIBUTE_INFO,
        payload: data,
    }
}

export const fnNewlyCreatedStaticAttributeData = (data) => {
    return {
        type: RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO,
        payload: data,
    }
}
export const fnAlreadyCreatedAttributeInfo = (data) => {
    return {
        type: RDX_ALREADY_CREATED_ATTRIBUTE_INFO,
        payload: data,
    }
}

export const fnAlreadyCreatedStaticAttributeInfo = (data) => {
    return {
        type: RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO,
        payload: data,
    }
}

export const fnCalculativeAttributeInfo = (data) => {
    return {
        type: RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO,
        payload: data,
    }
}

export const fnCalculativeOpertaInfo = (data) => {
    return {
        type: RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO,
        payload: data,
    }
}
