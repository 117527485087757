import { sortObjectArrayByKey } from '../../../util/util'

const PLPLayouts = [
    {
        componentName: 'PLPLayout1',
        componentTitle: 'List Page Layout-1',
        isEnabled: true,
    },
    {
        componentName: 'PLPLayout2',
        componentTitle: 'List Page Layout-2',
        isEnabled: true,
    },
]

export default sortObjectArrayByKey(
    'componentTitle',
    'string',
    PLPLayouts.filter((element) => element.isEnabled),
    'asc'
)
