import { Badge, Button, Empty, Layout, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
//! Import CSS libraries
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'

const { Content } = Layout
const { Title, Paragraph, Text } = Typography

const productTypeLayoutAPI = process.env.REACT_APP_PRODUCT_TYPE_LAYOUT_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)

const ListLayout = () => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal')+ ' - ' + String(` ${t('common:product_layout')}`))
    const [searchParams, setSearchParams] = useSearchParams()
    const [sendDataLoading, setSendDataLoading] = useState(false)
    const [layoutId, setLayoutId] = useState()
    const [listLayout, setListLayout] = useState([])
    const [listLayoutLoading, setListLayoutLoading] = useState(true)
    const [listLayoutNetworkError, setListLayoutNetworkError] = useState(false)
    const [listCount, setListCount] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const findByPageListLayouts = (pageNumber, pageLimit) => {
        MarketplaceServices.findByPage(productTypeLayoutAPI, null, pageNumber, pageLimit, true)
            .then(function (response) {
                console.log(
                    'Server response from findByPageListLayouts is ',
                    productTypeLayoutAPI,
                    response.data.response_body.data
                )
                setListLayout(response.data.response_body.data.layout_data)
                setListCount(response.data.response_body.count)
                setListLayoutLoading(false)
            })
            .catch(function (error) {
                console.log(
                    'Server Error response from findByPageListLayouts is ',
                    productTypeLayoutAPI,
                    error.response
                )
                setListLayoutLoading(false)
                setListLayoutNetworkError(true)
            })
    }

    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            page: page,
            limit: pageSize,
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        let pageNumber = parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
        let pageSize = parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv
        setListLayoutLoading(true)
        findByPageListLayouts(pageNumber, pageSize)
    }, [searchParams])

    const tableHeader = [
        {
            title: `${t('common:title')} `,
            dataIndex: 'display_name',
            key: 'display_name',
            width: '35%',

            render: (text, record) => {
                console.log('record', record)
                return (
                    <Content>
                        <Text
                            className=' font-normal text-[#637381] !max-w-[300px]'
                            ellipsis={{ tooltip: record?.layout_display_name }}>
                            {record?.layout_display_name}
                        </Text>
                    </Content>
                )
            },
        },
        {
            title: `${t('layouts:status')}`,
            dataIndex: 'status',
            key: 'status',
            width: '15%',

            render: (text, record) => {
                return (
                    <div>
                        {parseInt(record.status) === 1 ? (
                            <Badge
                                color='green'
                                className=' flex items-center justify-center'
                                text={<span className='!text-brandGray1'>{t('layouts:active')}</span>}
                            />
                        ) : (
                            <Badge
                                color='gray'
                                className=' flex items-center justify-center'
                                text={<span className='!text-brandGray1'>{t('layouts:inactive')}</span>}
                            />
                        )}
                        {/* <p className=' text-[#706f6f]'>
                            {record.status === 1 ? `${t('layouts:active')}` : `${t('layouts:inactive')}`}
                        </p>  */}
                    </div>
                )
            },
        },
        {
            title: `${t('common:description')} `,
            dataIndex: 'type',
            key: 'type',
            width: '30%',

            render: (text, record) => {
                return (
                    <Text
                        className=' font-normal text-[#637381] !max-w-[280px]'
                        ellipsis={{ tooltip: record?.layout_display_description }}>
                        {record?.layout_display_description}
                    </Text>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: '',
            key: '',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className='flex'>
                        <Link
                            to={{
                                pathname: '/dashboard/edit-layout',
                                search: `?lid=${record.id}&${MarketplaceAppConfig.getStore(
                                    ''
                                )}`,
                            }}
                            className='font-semibold'>
                            <Button className='app-btn-icon app-edit-icon' type='text'>
                                <Tooltip
                                    placement='bottom'
                                    className='text-sm'
                                    title={`${t('common:edit')} ${record.name}`}>
                                    {t('common:edit')}
                                </Tooltip>
                            </Button>
                        </Link>
                    </Content>
                )
            },
        },
    ]

    //! delete the Layout
    const removeLayout = () => {
        setSendDataLoading(true)
        MarketplaceServices.remove(productTypeLayoutAPI, {
            layout_id: layoutId,
        })
            .then(function (response) {
                console.log('Server response of delete layout--->', productTypeLayoutAPI, response.data.response_body)
                setIsModalVisible(false)
                MarketplaceToaster.showToast(response)
                setSendDataLoading(false)
                let filterListLayout =
                    listLayout && listLayout.length > 0 && listLayout.filter((ele) => ele.id !== layoutId)
                if (filterListLayout.length > 0) {
                    setListLayout(filterListLayout)
                }
            })
            .catch(function (error) {
                setIsModalVisible(false)
                setSendDataLoading(false)
                console.log('Error Server response of delete layout--->', productTypeLayoutAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const closeModal = () => {
        setIsModalVisible(false)
    }

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])
    return (
        <Content className='pt-1 h-full'>
            <Content>
                <HeaderForTitle
                    title={
                        <Content className='flex'>
                            <Content className='!w-[80%]'>
                                <Title level={3} className='!font-semibold'>
                                    {t('common:product_layout')}
                                </Title>
                            </Content>
                        </Content>
                    }
                    headerContent={
                        <Paragraph className='!font-normal !text-[#637381] !m-0'>
                            {t('common:prod_layout_short_desc')}
                        </Paragraph>
                    }
                />
            </Content>
            <Content className='p-3'>
                {listLayoutLoading ? (
                    <Content className=' bg-white p-3 shadow-brandShadow rounded-lg'>
                        <SkeletonComponent />
                    </Content>
                ) : listLayoutNetworkError ? (
                    <Content className='text-center !p-0  !mb-2 bg-white rounded-lg shadow-brandShadow'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : listLayout && listLayout.length > 0 ? (
                    <Content className='bg-white p-3 rounded-lg shadow-brandShadow'>
                        <Table dataSource={listLayout} columns={tableHeader} pagination={false} />
                        {listCount > itemsPerPageFromEnv ? (
                            <Content className=' grid justify-items-end'>
                                <DmPagination
                                    currentPage={
                                        parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                    }
                                    totalItemsCount={listCount}
                                    defaultPageSize={itemsPerPageFromEnv}
                                    pageSize={
                                        parseInt(searchParams.get('limit'))
                                            ? parseInt(searchParams.get('limit'))
                                            : itemsPerPageFromEnv
                                    }
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showQuickJumper={true}
                                />
                            </Content>
                        ) : null}
                        <Content>
                            <StoreModal
                                isVisible={isModalVisible}
                                okButtonText={`${t('common:ok')}`}
                                cancelButtonText={`${t('common:cancel')}`}
                                title={''}
                                okCallback={() => removeLayout()}
                                cancelCallback={() => closeModal()}
                                isSpin={sendDataLoading}>
                                {<div> {t('layouts:want_to_delete_the_layout')}</div>}
                            </StoreModal>
                        </Content>
                    </Content>
                ) : listLayout && listLayout.length === 0 ? (
                    <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                        <Empty description={t('common:no_data_available_to_display')} />
                    </div>
                ) : null}
            </Content>
        </Content>
    )
}

export default ListLayout
