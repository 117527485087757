import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_PAGE_COMP_ACTION,
    RDX_PAGE_COMP_EXECUTION_ORDER,
    RDX_PAGE_CHILD_COMP_STATUS,
    RDX_PAGE_COMP_UPDATE_ACTION,
    RDX_PAGE_COMP_REMOVE_UPDATE_ACTION,
} = ActionTypes

export const ReducerPageComp = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_PAGE_COMP_ACTION:
            return { ...state, pageCompAction: payload }
        case RDX_PAGE_COMP_EXECUTION_ORDER:
            return { ...state, pageCompExecutionOrder: payload }
        case RDX_PAGE_CHILD_COMP_STATUS:
            return { ...state, pageChildCompStatus: payload }
        case RDX_PAGE_COMP_UPDATE_ACTION:
            return { ...state, pageCompUpdateAction: payload }
        case RDX_PAGE_COMP_REMOVE_UPDATE_ACTION:
            delete state.pageCompUpdateAction
            return { ...state }
        default:
            return state
    }
}
