import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import { useLocation } from 'react-router-dom'
const variantAttributesAPI = process.env.REACT_APP_VARIANT_ATTRIBUTES_API
/**
 * !This function is a GET Method to get the list of variant attributes for a particular product template revision.
 * @param {*} productTemplateRevisionId -> To get the variant attributes based on the product_template_revision_id.
 */
//!REST --> GET Method.
const useGetVariantAttributes = ({ productTemplateRevisionId }) => {
    const search = useLocation().search
    let productTemplateRevisionIdFromURL = new URLSearchParams(search).get('ptsrid')
    let templateRevisionId = productTemplateRevisionId ? productTemplateRevisionId : productTemplateRevisionIdFromURL
    const getVariantAttributes = async () => {
        const params = {
            'product-revision-id': parseInt(templateRevisionId),
            'is-static': false,
        }
        const response = await MarketplaceServices.findAllWithoutPage(variantAttributesAPI, params)
        console.log('response===>', response)
        return response?.data?.response_body
    }
    return useQuery({
        queryKey: ['variantAttributes', templateRevisionId],
        queryFn: getVariantAttributes,
        refetchOnWindowFocus: false,
        enabled: !!templateRevisionId,
        retry: false,
    })
}
export default useGetVariantAttributes
