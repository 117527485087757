import { Button, Modal, Spin } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
const PolicyModal = ({
    okCallback,
    cancelCallback,
    isVisible,
    children,
    title,
    cancelButtonText,
    okButtonText,
    width,
    hideCloseButton,
    isSpin,
    isCancelButtonDisabled,
    isOkButtonDisabled,
    isScroll,
    destroyOnClose,
    noCloseIcon,
}) => {
    const { t } = useTranslation()
    const handleOk = () => {
        okCallback()
    }

    const handleCancel = () => {
        cancelCallback()
    }

    return (
        <Modal
            title={title}
            open={isVisible}
            onOk={handleOk}
            closable={hideCloseButton}
            centered={true}
            maskClosable={hideCloseButton}
            onCancel={handleCancel}
            width={width}
            closeIcon={!noCloseIcon}
            className='custom-modal'
            bodyStyle={isScroll ? { overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' } : null}
            footer={[
                cancelButtonText == null ? null : (
                    <Button
                        className={` app-btn-secondary ${
                            isCancelButtonDisabled ? ' !opacity-50 !cursor-not-allowed' : ' '
                        }`}
                        key='back'
                        onClick={handleCancel}
                        disabled={isCancelButtonDisabled ? isCancelButtonDisabled : false}>
                        {cancelButtonText}
                    </Button>
                ),
                okButtonText == null ? null : (
                    <Button
                        className={` app-btn-primary ${isOkButtonDisabled ? ' !opacity-50 !cursor-not-allowed' : ' '}`}
                        disabled={isOkButtonDisabled ? isOkButtonDisabled : false}
                        key='submit'
                        onClick={handleOk}>
                        {okButtonText}
                    </Button>
                ),
            ]}
            destroyOnClose={destroyOnClose}>
            <Spin tip={t('common:please_wait')} spinning={isSpin}>
                {children}
            </Spin>
        </Modal>
    )
}

export default PolicyModal
