import { Empty, Layout, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

/**
 *! Components are imported Here.
 */

import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import { getGenerateDateAndTime } from '../../util/util'

//! ant design initialization
const { Content } = Layout

//! Global Variables
const refundSettlementDataAPI = process.env.REACT_APP_REFUND_SETTLEMENT_DATA_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE

const Returns = () => {
    const { t } = useTranslation()
    const [settlementRefund, setSettlementRefund] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [count, setCount] = useState()
    const [pageNumber, setPageNumber] = useState()
    const [pageLimit, setPageLimit] = useState()
    let { id } = useParams()

    //!Table column for the sales order(Sales Details)
    const tableColumnForHistory = [
        {
            title: `${t('transactions:order_id')}`,
            dataIndex: 'order_id',
            key: 'order_id',
            ellipsis: true,
            // width: "5%",
            render: (text, record) => {
                return <Content className=''>{record.orderId}</Content>
            },
        },
        {
            title: `${t('transactions:order_item_id')}`,
            dataIndex: 'order_item_id',
            key: 'order_item_id',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return <Content className=''>{record.orderItemId}</Content>
            },
        },
        {
            title: `${t('transactions:date')}`,
            dataIndex: 'order_date',
            key: 'order_date',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return <Content className=''>{record.orderDate}</Content>
            },
        },
        {
            title: `${t('transactions:unit_price')}`,
            dataIndex: 'unit_price',
            key: 'unit_price',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return <Content className=''>{record.unitPrice}</Content>
            },
        },
        {
            title: `${t('transactions:amount_refunded')}`,
            dataIndex: 'amount_refunded',
            key: 'amount_refunded',
            ellipsis: true,
            // width: "15%",
            render: (text, record) => {
                return <Content className=''>{record.amountRefunded}</Content>
            },
        },
        {
            title: `${t('transactions:store_commission_refunded')}`,
            dataIndex: 'store_commission_refunded',
            key: 'store_commission_refunded',
            ellipsis: true,
            // width: "10%",
            render: (text, record) => {
                return <Content className=''>{record.storeCommissionRefunded}</Content>
            },
        },
        {
            title: `${t('transactions:order_refund_status')}`,
            dataIndex: 'order_refund_status',
            key: 'order_refund_status',
            ellipsis: true,
            // width: "5%",
            render: (text, record) => {
                return <Content className=''>{record.orderRefundStatus}</Content>
            },
        },
        {
            title: `${t('transactions:settlement_date')}`,
            dataIndex: 'settlement_date',
            key: 'settlement_date',
            ellipsis: true,
            // width: "15%",
            render: (text, record) => {
                return <Content className=''>{record.settlementDate}</Content>
            },
        },
        {
            title: `${t('transactions:settlement_status')}`,
            dataIndex: 'settlement_status',
            key: 'settlement_status',
            ellipsis: true,
            // width: "12%",
            render: (text, record) => {
                return <Content className=''>{record.settlementStatus}</Content>
            },
        },
    ]

    const getRefundProcessedData = (settlementRefundData) => {
        let refundData = []
        if (settlementRefundData && settlementRefundData.length > 0) {
            settlementRefundData.forEach((element) => {
                let refundObj = {}
                refundObj['key'] = element.id
                refundObj['orderId'] = element.order_id
                refundObj['orderItemId'] = element.order_item_id
                refundObj['orderDate'] = getGenerateDateAndTime(element.created_at, 'D MMMM YYYY')
                refundObj['unitPrice'] = element.selling_price
                refundObj['amountRefunded'] = element.projected_settlement_amount
                refundObj['storeCommissionRefunded'] = element.store_commission_amount
                refundObj['orderRefundStatus'] = element.is_settled ? 'Completed' : 'Pending'
                refundObj['settlementDate'] = getGenerateDateAndTime(element.settlement_date, 'D MMMM YYYY')
                refundObj['settlementStatus'] = element.settlement_status
                    ? element.settlement_status
                    : t('common:not_available')

                refundObj['actualSettlementAmount'] = element.actual_settlement_amount
                refundData.push(refundObj)
            })
        }
        console.log('refundData', refundData)
        return refundData
    }

    const getRefundsByPage = (pageNum, pageLimit) => {
        MarketplaceServices.findByPage(refundSettlementDataAPI, { vendor_id: parseInt(id) }, pageNum, pageLimit, true)
            .then(function (response) {
                console.log(
                    'Server response from getRefundsByPage is',
                    refundSettlementDataAPI,
                    response.data.response_body.data
                )
                setCount(response.data.response_body.count)
                setSettlementRefund(getRefundProcessedData(response.data.response_body.data))
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Error Server response from getRefundsByPage is', refundSettlementDataAPI, error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    const handlePageNumberChange = (page, pageSize) => {
        window.scrollTo(0, 240)
        setIsLoading(true)
        setPageNumber(page)
        setPageLimit(pageSize)
    }

    useEffect(() => {
        let pageNum = pageNumber === undefined ? 1 : pageNumber
        let pagelimit = pageLimit === undefined ? itemsPerPageFromEnv : pageLimit
        getRefundsByPage(pageNum, pagelimit)
    }, [pageNumber, pageLimit, id])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
    }, [])
    return (
        <Content className='!pt-1'>
            {isLoading ? (
                <Content className=' bg-white p-3'>
                    <SkeletonComponent />
                </Content>
            ) : isNetworkError ? (
                <Content className=' bg-white p-3'>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`${t('common:network_error')}`} />{' '}
                </Content>
            ) : (
                <Content className='mb-3'>
                    <Table pagination={false} columns={tableColumnForHistory} dataSource={settlementRefund} />
                    {count > itemsPerPageFromEnv ? (
                        <Content className=' grid justify-items-end'>
                            <DmPagination
                                currentPage={pageNumber === undefined ? 1 : pageNumber}
                                totalItemsCount={count}
                                defaultPageSize={itemsPerPageFromEnv}
                                pageSize={pageLimit === undefined ? itemsPerPageFromEnv : pageLimit}
                                handlePageNumberChange={handlePageNumberChange}
                                showSizeChanger={true}
                                showTotal={true}
                            />
                        </Content>
                    ) : null}
                </Content>
            )}
        </Content>
    )
}

export default Returns
