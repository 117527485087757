import { useEffect } from 'react'
import MarketplaceServices from '../services/axios/MarketplaceServices'
import { useDispatch, useSelector } from 'react-redux'
import { fnDefaultLanguage, fnSelectedLanguage, fnStoreLanguage } from '../services/redux/actions/ActionStoreLanguage'
import util from '../util/common'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from 'react-oidc-context'
const storeLanguageAPI = process.env.REACT_APP_STORE_LANGUAGE_API

const useUserLanguage = () => {
    const dispatch = useDispatch()
    const { i18n } = useTranslation()
    const auth = useAuth()

    const selectedLanguageRedux = useSelector((state) => state.reducerSelectedLanguage)?.selectedLanguage?.language_code

    const findAllStoreLanguages = async () => {
        const response = await MarketplaceServices.findAll(storeLanguageAPI, { 'language-status': 1 }, false)
        return response
    }

    const {
        data: userLanguage,
        status: userLanguageStatus,
        isLoading: isUserLanguageLoading,
    } = useQuery({
        queryKey: ['userLanguage'],
        queryFn: findAllStoreLanguages,
        refetchOnWindowFocus: false,
        enabled: auth.isAuthenticated,
        retry: false,
    })

    useEffect(() => {
        if (userLanguageStatus === 'success') {
            const storeLanguages = userLanguage?.data?.response_body?.data
            const defaultLanguage = storeLanguages.find((item) => item.is_default)

            // Get default selected language from Redux , cookie and localStorage
            const langFromCookie = Cookies.get('dmsmplng')
            const langFromLocalStorage = window.localStorage.getItem('dmsmplng')
            const defaultSelectedLanguage = selectedLanguageRedux || langFromCookie || langFromLocalStorage
            // Validate the default selected language code with store languages
            const validDefaultSelectedLang = storeLanguages?.filter(
                (language) => language.language_code === defaultSelectedLanguage
            )

            // Get user selected language from cookie and localStorage
            const userSelectedLanguageFromCookies = Cookies.get('selected_dmsmplng')
            const userSelectedLanguageFromLocalStorage = window.localStorage.getItem('selected_dmsmplng')
            const userSelectedLanguage = userSelectedLanguageFromCookies || userSelectedLanguageFromLocalStorage
            // Validate the default selected language code with store languages
            const validUserSelectedSelectedLang = storeLanguages?.filter(
                (language) => language.language_code === userSelectedLanguage
            )

            // Set selected language if no default selected language found OR selected language is invalid OR no user selected language
            if (
                !defaultSelectedLanguage ||
                (defaultSelectedLanguage && validDefaultSelectedLang?.length === 0) ||
                validUserSelectedSelectedLang?.length <= 0
            ) {
                dispatch(fnSelectedLanguage(defaultLanguage))
                util.setUserSelectedLngCode(defaultLanguage?.language_code)
                document.body.style.direction = defaultLanguage?.writing_script_direction?.toLowerCase()
                util.removeSelectedLngCode()
            }

            // update redux state of store languages and default language
            dispatch(fnStoreLanguage(storeLanguages))
            dispatch(fnDefaultLanguage(defaultLanguage))

            // Updating i18n instance with supportedLanguagesCodes and fallbackLng with  user languages from api
            const supportedLanguagesCodes = []
            storeLanguages?.forEach(
                (lang) => lang?.language_code && supportedLanguagesCodes.push(lang?.language_code?.toLowerCase())
            )
            if (
                supportedLanguagesCodes?.length > 0 &&
                defaultLanguage?.language_code &&
                i18n?.language !== validUserSelectedSelectedLang?.[0]?.language_code &&
                i18n?.language !== defaultLanguage?.language_code
            ) {
                i18n.init({
                    supportedLngs: supportedLanguagesCodes,
                    fallbackLng: defaultLanguage?.language_code || 'en',
                    whitelist: supportedLanguagesCodes,
                    preload: [
                        defaultLanguage?.language_code,
                        validDefaultSelectedLang?.[0]?.language_code,
                        validUserSelectedSelectedLang?.[0]?.language_code,
                    ],
                })
                i18n.changeLanguage(validUserSelectedSelectedLang?.[0]?.language_code || defaultLanguage?.language_code)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userLanguage, userLanguageStatus])

    return { userLanguage, userLanguageStatus, isUserLanguageLoading }
}
export default useUserLanguage
