// here we are creating the action names
export const ActionTypes = {
    //! Component Testing Action
    RDX_TEST_REDUX_IMPLEMENTATION: 'RDX_TEST_REDUX_IMPLEMENTATION',
    RDX_TEST_REMOVE_REDUX_IMPLEMENTATION: 'RDX_TEST_REMOVE_REDUX_IMPLEMENTATION',

    //! Vendor access info related action types
    RDX_VENDOR_ACCESS_INFO: 'RDX_VENDOR_ACCESS_INFO',

    //! Component Page Action for the portal
    RDX_PAGE_COMP_ACTION: 'RDX_PAGE_COMP_ACTION',
    RDX_PAGE_COMP_EXECUTION_ORDER: 'RDX_PAGE_COMP_EXECUTION_ORDER',
    RDX_PAGE_CHILD_COMP_STATUS: 'RDX_PAGE_CHILD_COMP_STATUS',
    RDX_PAGE_COMP_UPDATE_ACTION: 'RDX_PAGE_COMP_UPDATE_ACTION',
    RDX_PAGE_COMP_REMOVE_UPDATE_ACTION: 'RDX_PAGE_COMP_REMOVE_UPDATE_ACTION',

    //! Product types related action types
    RDX_STORE_PRODUCT_TYPES: 'RDX_STORE_PRODUCT_TYPES',
    RDX_PRODUCT_TYPE_DETAILS: 'RDX_PRODUCT_TYPE_DETAILS',

    //! Attribute Group related action types
    RDX_ATTRIBUTE_GROUP_DETAILS: 'RDX_ATTRIBUTE_GROUP_DETAILS',

    //! Category related action types
    RDX_CATEGORY_BY_STATUS: 'RDX_CATEGORY_BY_STATUS',
    RDX_CATEGORY_BY_STATUS_RAW_DATA: 'RDX_CATEGORY_BY_STATUS_RAW_DATA',
    RDX_CATEGORY_SELECTED_INFO: 'RDX_CATEGORY_SELECTED_INFO',
    RDX_PARENT_CATEGORY_SELECTED_INFO: 'RDX_PARENT_CATEGORY_SELECTED_INFO',
    RDX_REMOVE_CATEGORY_SELECTED_INFO: 'RDX_REMOVE_CATEGORY_SELECTED_INFO',
    RDX_CATEGORY_SELECTED_SEGMENT_INFO: 'RDX_CATEGORY_SELECTED_SEGMENT_INFO',

    //! Product related action types
    RDX_PRODUCT_SELECTED_INFO: 'RDX_PRODUCT_SELECTED_INFO',
    RDX_REMOVE_PRODUCT_SELECTED_INFO: 'RDX_REMOVE_PRODUCT_SELECTED_INFO',
    RDX_PRODUCT_TEMPLATE_SELECTED_INFO: 'RDX_PRODUCT_TEMPLATE_SELECTED_INFO',
    RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO: 'RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO',
    RDX_ACTIVE_PRODUCT_TEMPLATES_INFO: 'RDX_ACTIVE_PRODUCT_TEMPLATES_INFO',

    //! Revision related action types
    RDX_REVISION_INFO: 'RDX_REVISION_INFO',
    RDX_REVISION_SELECTED_INFO: 'RDX_REVISION_SELECTED_INFO',

    //! VendorType related action types
    RDX_VENDORTYPE_INFO: 'RDX_VENDORTYPE_INFO',
    RDX_VENDORTYPE_SELECTED_INFO: 'RDX_VENDORTYPE_SELECTED_INFO',

    //! Vendor related action types
    RDX_VENDOR_INFO: 'RDX_VENDOR_INFO',
    RDX_VENDOR_SELECTED_INFO: 'RDX_VENDOR_SELECTED_INFO',

    //! Store Language related action types
    RDX_STORE_LANGUAGE_INFO: 'RDX_STORE_LANGUAGE_INFO',
    RDX_SELECTED_LANGUAGE_INFO: 'RDX_SELECTED_LANGUAGE_INFO',
    RDX_DEFAULT_LANGUAGE_INFO: 'RDX_DEFAULT_LANGUAGE_INFO',

    //! API Calls related action types
    RDX_MAKE_API_CALL: 'RDX_MAKE_API_CALL',
    RDX_API_SUCCESS_RESPONSE: 'RDX_API_SUCCESS_RESPONSE',
    RDX_MAKE_API_CALL2: 'RDX_MAKE_API_CALL2',
    RDX_ATTRIBUTE_DETAILS_UPDATED: 'RDX_ATTRIBUTE_DETAILS_UPDATED',
    RDX_ATTRIBUTE_DETAILS_TRANSLATED: 'RDX_ATTRIBUTE_DETAILS_TRANSLATED',

    //! Edit Segment related action types
    RDX_EDIT_SEGMENT_INFO: 'RDX_EDIT_SEGMENT_INFO',

    //! Store related action types
    RDX_STORE_AVAILABLE_INFO: 'RDX_STORE_AVAILABLE_INFO',
    RDX_STORE_SELECTED_INFO: 'RDX_STORE_SELECTED_INFO',

    //! Role related action types
    RDX_ROLE_AVAILABLE_INFO: 'RDX_ROLE_AVAILABLE_INFO',
    RDX_ROLE_SELECTED_INFO: 'RDX_ROLE_SELECTED_INFO',

    //! User logged related action types
    RDX_USER_LOGGED_INFO: 'RDX_USER_LOGGED_INFO',

    //! Newly created attribute info related action types
    RDX_NEWLY_CREATED_ATTRIBUTE_INFO: 'RDX_NEWLY_CREATED_ATTRIBUTE_INFO',

    //! Newly created static attribute info related action types
    RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO: 'RDX_NEWLY_CREATED_STATIC_ATTRIBUTE__INFO',

    //!  already created attribute info related action types
    RDX_ALREADY_CREATED_ATTRIBUTE_INFO: 'RDX_ALREADY_CREATED_ATTRIBUTE_INFO',

    //!  already created static attribute info related action types
    RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO: 'RDX_ALREADY_CREATED_STATIC_ATTRIBUTE_INFO',

    //! Newly created calculative attribute info related action types
    RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO: 'RDX_LIST_OF_CALCULATIVE_ATTRIBUTE__INFO',

    //! Newly created calculative attribute operator info related action types
    RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO: 'RDX_LIST_OF_CALCULATIVE_OPERATOR__INFO',
    //! Payment cycle related action types
    RDX_PAYMENT_CYCLE_INFO: 'RDX_PAYMENT_CYCLE_INFO',

    //! VENDOR Settlement related action types
    RDX_VENDOR_SETTLEMENT_INFO: 'RDX_VENDOR_SETTLEMENT_INFO',
    //! Selected Vendor details related action types
    RDX_SELECTED_VENDOR_SETTLEMENT_INFO: 'RDX_SELECTED_VENDOR_SETTLEMENT_INFO',

    //! Content setting informations
    RDX_CONTENT_SETTING_INFO: 'RDX_CONTENT_SETTING_INFO',

    //! Store logo information
    RDX_STORE_LOGO_INFO: 'RDX_STORE_LOGO_INFO',

    // !store logo loading
    RDX_STORE_LOGO_LOADING: 'RDX_STORE_LOGO_LOADING',

    //!API call for the product publishing
    RDX_CALL_PUBLISHING_API: 'RDX_CALL_PUBLISHING_API',

    //!UserDetails Redux
    RDX_USER_PROFILE_INFO: 'RDX_USER_PROFILE_INFO',

    //!StoreImage
    RDX_ABSOLUTE_STORE_IMAGE_INFO: 'RDX_ABSOLUTE_STORE_IMAGE_INFO',
    RDX_SERVICE_PRODUCT_TIME_VALIDATION: 'RDX_SERVICE_PRODUCT_TIME_VALIDATION',

    //! StoreSettings
    RDX_STORE_SETTINGS_INFO: 'RDX_STORE_SETTINGS_INFO',

    //!ProductLayout
    RDX_LAYOUT_INFO: 'RDX_LAYOUT_INFO',
}
