export const trashCanSVG = (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M12.2248 2.2H10.2998V1.775C10.2998 1.025 9.6998 0.425003 8.9498 0.425003H7.02481C6.27481 0.425003 5.6748 1.025 5.6748 1.775V2.2H3.7498C3.0248 2.2 2.4248 2.8 2.4248 3.525V4.275C2.4248 4.825 2.7498 5.275 3.2248 5.475L3.6248 13.75C3.6748 14.775 4.52481 15.575 5.5498 15.575H10.3998C11.4248 15.575 12.2748 14.775 12.3248 13.75L12.7498 5.45C13.2248 5.25 13.5498 4.775 13.5498 4.25V3.5C13.5498 2.8 12.9498 2.2 12.2248 2.2ZM6.8248 1.775C6.8248 1.65 6.9248 1.55 7.0498 1.55H8.9748C9.0998 1.55 9.1998 1.65 9.1998 1.775V2.2H6.8498V1.775H6.8248ZM3.5748 3.525C3.5748 3.425 3.6498 3.325 3.7748 3.325H12.2248C12.3248 3.325 12.4248 3.4 12.4248 3.525V4.275C12.4248 4.375 12.3498 4.475 12.2248 4.475H3.7748C3.6748 4.475 3.5748 4.4 3.5748 4.275V3.525ZM10.4248 14.45H5.5748C5.1498 14.45 4.7998 14.125 4.7748 13.675L4.3998 5.575H11.6248L11.2498 13.675C11.1998 14.1 10.8498 14.45 10.4248 14.45Z'
            fill='#F5222D'
        />
        <path
            d='M7.9998 8.10001C7.6998 8.10001 7.4248 8.35001 7.4248 8.67501V11.85C7.4248 12.15 7.6748 12.425 7.9998 12.425C8.2998 12.425 8.5748 12.175 8.5748 11.85V8.67501C8.5748 8.35001 8.2998 8.10001 7.9998 8.10001Z'
            fill='#F5222D'
        />
        <path
            d='M9.99994 8.6C9.67494 8.575 9.42494 8.8 9.39994 9.125L9.24994 11.325C9.22494 11.625 9.44994 11.9 9.77494 11.925C9.79994 11.925 9.79994 11.925 9.82494 11.925C10.1249 11.925 10.3749 11.7 10.3749 11.4L10.5249 9.2C10.5249 8.875 10.2999 8.625 9.99994 8.6Z'
            fill='#F5222D'
        />
        <path
            d='M5.97497 8.6C5.67497 8.625 5.42497 8.9 5.44997 9.2L5.62497 11.4C5.64997 11.7 5.89997 11.925 6.17497 11.925C6.19997 11.925 6.19997 11.925 6.22497 11.925C6.52497 11.9 6.77497 11.625 6.74997 11.325L6.57497 9.125C6.57497 8.8 6.29997 8.575 5.97497 8.6Z'
            fill='#F5222D'
        />
    </svg>
)
