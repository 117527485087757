import { useEffect, useState } from 'react'
import MarketplaceServices from '../services/axios/MarketplaceServices'
import productTemplateListsDataProcessor from '../constants/dataProcessor/productTemplateListDataProcessor'

const productTemplateListAPI = process.env.REACT_APP_PRODUCT_API
const useGetProductTemplateList = (searchKey, additionalParams) => {
    const [loading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [apiData, setAPIData] = useState([])
    const [apiProcessedData, setAPIProcessedData] = useState([])
    const [searchKeyTriggered, setSearchKeyTriggered] = useState(false)

    useEffect(() => {
        let params = {}
        params = { ...additionalParams }
        params['product-revision-status'] = 3
        console.log('==> params', params)
        if (searchKey) {
            params['search'] = String(searchKey)
            setSearchKeyTriggered(true)
        }
        setIsLoading(true)
        MarketplaceServices.findAll(productTemplateListAPI, params, true)
            .then(function (response) {
                console.log(
                    'Server response from findAllProductTemplateLists Hook is',
                    productTemplateListAPI,
                    response.data.response_body
                )
                setAPIData(response.data.response_body)
                setAPIProcessedData(productTemplateListsDataProcessor(response.data.response_body.StoreProductRevision))
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log(
                    'Server Error response from findAllProductTemplateLists hook ',
                    productTemplateListAPI,
                    error.response
                )
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }, [searchKey, additionalParams])

    return {
        loading,
        isNetworkError,
        apiData,
        apiProcessedData,
        searchKeyTriggered,
        setSearchKeyTriggered,
    }
}
export default useGetProductTemplateList
