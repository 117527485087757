import { TranslationOutlined } from '@ant-design/icons'
import { Button, Col, Input, Layout, Row, Select, Spin, TreeSelect, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import TranslatorModal from '../product-category/TranslatorModal'
import './AddProductTemplate.css'

import {
    fnActiveProductTemplatesInfo,
    fnProductTemplateSelectedInfo,
} from '../../services/redux/actions/ActionsProducts'

import { fnMakeAPICall, fnMakeAPICall2 } from '../../services/redux/actions/ActionsAPICall'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getParentChildrenDataForProductParentTemplate } from '../../util/util'

//!destructerising libraries
const { Content } = Layout
const { TextArea } = Input
const { SHOW_PARENT } = TreeSelect

//!Declaring env variables
const storeProductTypeAPI = process.env.REACT_APP_PRODUCT_TYPE_API
const productTemplateAPI = process.env.REACT_APP_PRODUCT_API
const productTemplateActiveAPI = process.env.REACT_APP_ACTIVE_PRODUCT_TEMPLATE_API
const updateProductTypeProcureTemplateAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_UPDATE_API
const productHierarchyAPI = process.env.REACT_APP_PRODUCT_HIERARCHY_API

// ! input fields properties
const titleMaxLength = process.env.REACT_APP_TITLE_MAX_LENGTH
const descriptionMaxLength = process.env.REACT_APP_DESCRIPTION_MAX_LENGTH

const AddProductTemplate = ({ action, setIsModalOpen }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const search = useLocation().search
    const [onChangeValues, setOnChangeValues] = useState(false)
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')

    const byDefaultLanguageCode = useSelector(
        (state) =>
            state.reducerDefaultLanguage &&
            state.reducerDefaultLanguage.defaultLanguage &&
            state.reducerDefaultLanguage.defaultLanguage.language_code
    )

    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)

    const revisionSelectedInfo = useSelector((state) => state.reducerRevisionInfo.revisionSelectedInfo)
    const productTemplateSelectedInfo = useSelector((state) => state.reducerProducts.productTemplateSelectedInfo)

    const activeProductTemplatesInfo = useSelector((state) => state.reducerProducts.activeProductTemplatesInfo)

    const [storeProductTypeAPIData, setStoreProductTypeAPIData] = useState([])
    const [productTypeValuesSelectedFromDropdown, setProductTypeValuesSelectedFromDropdown] = useState([])

    const [procureTemplateAPIData, setProcureTemplateAPIData] = useState([])
    const [procureTemplateDropdownData, setProcureTemplateDropdownData] = useState([])

    const [getStoreProductTypeFilteredData, setGetStoreProductTypeFilteredData] = useState([])

    const [selectedStoreProductTypes, setSelectedStoreProductTypes] = useState('Choose Product Type')
    const [productTemplateName, setProductTemplateName] = useState('')

    const [invalidProductTemplateName, setInvalidProductTemplateName] = useState(false)
    const [productTemplateDescription, setProductTemplateDescription] = useState('')

    const [invalidProductTemplateDescription, setInvalidProductTemplateDescription] = useState(false)
    const [inValidSelectedStoreProductTypes, setInValidSelectedStoreProductTypes] = useState(false)
    const [parentTemplateData, setParentTemplateData] = useState('Choose a Parent Template')
    const [activeProductTemplateFilteredData, setActiveProductTemplateFilteredData] = useState([])
    const [procureTemplateData, setProcureTemplateData] = useState([])
    const [productTemplatePostData, setProductTemplatePostData] = useState(null)

    let [apiCountForDisplayName, setApiCountForDisplayName] = useState(2)

    //! Related to edit Product Templates Page

    const [isUpLoading, setIsUpLoading] = useState(false)
    const [alreadySelectedProductTypes, setAlreadySelectedProductTypes] = useState([])

    const [alreadySelectedParentData, setAlreadySelectedParentData] = useState()
    const [activeProductTemplateFlatData, setActiveProductTemplateFlatData] = useState([])
    const [productHeirarchyData, setProductHeirarchyData] = useState([])
    const [procureTemplateServerData, setProcureTemplateServerData] = useState([])
    const [editProcureTemplate, setEditProcureTemplate] = useState([])

    const permissionValue = util.getPermissionData() || []
    const [disableFeilds, setDisableFeilds] = useState(
        permissionValue && permissionValue.includes('UI-create-edit-product-template') === false ? true : false
    )
    const [langDirection, setLangDirection] = useState('ltr')
    const [parentTemplateRevisionId, setParentTemplateRevisionId] = useState()
    const [storeProductTypesLoading, setStoreProductTypesLoading] = useState(true)
    const [parentTemplatesLoading, setParentTemplatesLoading] = useState(true)

    //! onchange parent template
    const parentTemplateChange = (parentValue) => {
        if (onChangeValues === false) {
            setOnChangeValues(true)
        }
        if (parentValue !== undefined) {
            setParentTemplateData(parentValue)
            const data =
                activeProductTemplateFlatData &&
                activeProductTemplateFlatData.length > 0 &&
                activeProductTemplateFlatData.filter((ele) => ele.value === parentValue)
            if (data && data.length > 0) {
                console.log('data---->', data[0].value)
                findAllWithoutPageProductHierachy(data[0].revisionId)
            }

            //setProcureTemplateData(parentValue);
            setAlreadySelectedParentData(parentValue)
        } else {
            //setAlreadySelectedParentData(null);
            setAlreadySelectedParentData()
            let data = getParentChildrenDataForProductParentTemplate(activeProductTemplatesInfo)

            setActiveProductTemplateFilteredData(data[0].localProductTemplateData)
            if (procureTemplateServerData && procureTemplateServerData.length > 0) {
                setProcureTemplateData(procureTemplateServerData)
            } else {
                setProcureTemplateData([])
                setProcureTemplateServerData([])
            }

            setParentTemplateData('Choose a Parent Template')
        }
    }

    //! onchange procure template
    const handleProcureSelectChange = (procureValue) => {
        const procureTemplateOne = procureValue
        const procureTemplateDropdownselect = procureTemplateOne.map((item) => item.value)
        setProcureTemplateDropdownData(procureTemplateDropdownselect)

        if (onChangeValues === false) {
            setOnChangeValues(true)
        }
        if (procureValue.length > 0) {
            let temp = []
            let serverData = []
            for (var i = 0; i < procureValue.length; i++) {
                temp.push(procureValue[i].value)
                if (procureValue[i].disabled !== true) {
                    serverData.push(procureValue[i].value)
                }
            }
            setProcureTemplateData(temp)

            setProcureTemplateServerData(serverData)
        } else {
            if (
                parentTemplateData !== undefined &&
                parentTemplateData !== null &&
                parentTemplateData !== 'Choose a Parent Template'
            ) {
                setProcureTemplateData(productHeirarchyData.concat(parentTemplateData))
                setProcureTemplateServerData([])
            } else {
                setProcureTemplateData([])

                setProcureTemplateServerData([])
            }
        }
    }

    //! onchange producttypes change
    const handleStoreProductTypeChange = (storeProductTypeId, obj) => {
        if (onChangeValues === false) {
            setOnChangeValues(true)
        }

        setProductTypeValuesSelectedFromDropdown(storeProductTypeId)
        setSelectedStoreProductTypes(obj)
        setAlreadySelectedProductTypes(obj)
        setInValidSelectedStoreProductTypes(false)
        if (obj && obj.length === 0) {
            setSelectedStoreProductTypes('Choose Product Type')
        }
    }

    const storeProductTypeDataProcessor = (storeProductTypeData) => {
        let localStoreProductTypeData = []
        if (storeProductTypeData && storeProductTypeData.length > 0) {
            for (var i = 0; i < storeProductTypeData.length; i++) {
                const temp = {}
                temp['label'] = storeProductTypeData[i].store_product_type.display_name
                temp['value'] = storeProductTypeData[i].store_product_type.id
                temp['store_product_type_revision_id'] = storeProductTypeData[i].id
                temp['store_product_type_id'] = storeProductTypeData[i].store_product_type.id
                localStoreProductTypeData.push(temp)
                setGetStoreProductTypeFilteredData(localStoreProductTypeData)
            }
            return localStoreProductTypeData
        } else {
            return localStoreProductTypeData
        }
    }

    //!get call for store product types
    const findAllWithoutPageStoreProductTypes = (id) => {
        let params = {}
        if (id) {
        } else {
            params['status'] = 'supported'
        }
        MarketplaceServices.findAllWithoutPage(storeProductTypeAPI, params, true)
            .then(function (response) {
                console.log(
                    'Server Response from findAllWithoutPageStoreProductTypes findAllWithoutPage',
                    storeProductTypeAPI,
                    response.data
                )
                if (response.data.response_body.StoreProductTypeRevision.length > 0) {
                    let productTypesData = response.data.response_body.StoreProductTypeRevision

                    let temp = []
                    if (productTypesData && productTypesData.length > 0) {
                        let filteredTypes = productTypesData.filter((ele) => ele.store_product_type.product_type != 6)
                        if (filteredTypes && filteredTypes.length > 0) {
                            storeProductTypeDataProcessor(filteredTypes)
                        } else {
                            storeProductTypeDataProcessor([])
                        }
                    } else {
                        storeProductTypeDataProcessor([])
                    }
                }
                setStoreProductTypesLoading(false)
            })
            .catch(function (error) {
                console.log('Server Error response from store product types', storeProductTypeAPI, error)
                setStoreProductTypesLoading(false)
            })
    }

    //! get call for active product template
    const findAllWithoutPageActivePoductTemplate = () => {
        let temp = {
            'product-template-status': 'active',
            'exclude-product-template-id': productTemplateId,
        }
        MarketplaceServices.findAllWithoutPage(productTemplateActiveAPI, temp, false)
            .then(function (response) {
                console.log(
                    'server response from findAllWithoutPageActivePoductTemplate API is :',
                    productTemplateActiveAPI,
                    response.data.response_body
                )
                if (response.data.response_body.product_template_data.length > 0) {
                    let data = getParentChildrenDataForProductParentTemplate(
                        response.data.response_body.product_template_data
                    )
                    setActiveProductTemplateFilteredData(data[0].localProductTemplateData)
                    setActiveProductTemplateFlatData(data[0].flatproductTemplateData)
                    dispatch(fnActiveProductTemplatesInfo(response.data.response_body.product_template_data))
                }
                setParentTemplatesLoading(false)
            })
            .catch(function (error) {
                setParentTemplatesLoading(false)
                console.log(
                    ' Error Server response from findAllWithoutPageActivePoductTemplate is',
                    productTemplateActiveAPI,
                    error.response
                )
            })
    }

    //!update Product Type Procure Template
    const updateProductTypeProcureTemplate = () => {
        setIsUpLoading(true)
        const productType = JSON.stringify(alreadySelectedProductTypes)
        const productTypeResponse = JSON.stringify(
            productTemplateSelectedInfo && productTemplateSelectedInfo[0].storeProductType
        )

        const procureTemplate = JSON.stringify(procureTemplateData)
        const procureTemplateResponse = JSON.stringify(
            productTemplateSelectedInfo && productTemplateSelectedInfo[0].procureTemplate
        )

        if (
            alreadySelectedProductTypes &&
            alreadySelectedProductTypes.length === 0 &&
            alreadySelectedProductTypes.length < 1
        ) {
            setInValidSelectedStoreProductTypes(true)

            MarketplaceToaster.showToast(
                util.getToastObject(`${t('product_template:select_the_product_types')}`, 'error')
            )
        } else if (productType !== productTypeResponse || procureTemplate !== procureTemplateResponse) {
            let localProductTypeData = []

            for (var i = 0; i < (alreadySelectedProductTypes && alreadySelectedProductTypes.length); i++) {
                let productTypeBody = {}
                // const revisionId = storeProductTypeRevisionId.find(
                //   (ele) => ele.store_product_type.id === alreadySelectedProductTypes.value[i]
                // );
                productTypeBody['store_product_type'] = alreadySelectedProductTypes[i].store_product_type_id
                productTypeBody['store_product_type_revision'] =
                    alreadySelectedProductTypes[i].store_product_type_revision_id
                localProductTypeData.push(productTypeBody)
            }

            const putBody = {
                procure_template: procureTemplateServerData,
                store_product_type: localProductTypeData,
            }

            setIsUpLoading(true)

            let temp = {
                id: revisionSelectedInfo && revisionSelectedInfo.id,
            }
            MarketplaceServices.update(
                updateProductTypeProcureTemplateAPI.replace(
                    '{product_template_revision_id}',
                    revisionSelectedInfo && revisionSelectedInfo.id
                ),
                putBody,
                temp
            )
                .then(function (response) {
                    console.log(
                        'Server response from updateProductTypeProcureTemplate is',
                        updateProductTypeProcureTemplateAPI,
                        response.data.response_body
                    )
                    setIsUpLoading(false)

                    MarketplaceToaster.showToast(response)
                    findAllWithoutPageProductTemplatesByIdAndRevisionId(productTemplateId, productTemplateRevisionId)
                    setIsModalOpen(false)
                })
                .catch(function (error) {
                    console.log(
                        'Error Server response from updateProductTypeProcureTemplate response error',
                        updateProductTypeProcureTemplateAPI,
                        error.response
                    )
                    MarketplaceToaster.showToast(error.response)
                    setIsUpLoading(false)
                })
        }
    }

    //! post call for add product template
    const saveProductTemplateData = () => {
        let postBody = {}
        postBody['name'] = productTemplateName.trim()
        postBody['description'] = productTemplateDescription.trim()

        let localProductTypeData = []

        for (var i = 0; i < (selectedStoreProductTypes && selectedStoreProductTypes.length); i++) {
            let productTypeBody = {}

            productTypeBody['store_product_type'] = selectedStoreProductTypes[i].store_product_type_id
            productTypeBody['store_product_type_revision'] = selectedStoreProductTypes[i].store_product_type_revision_id
            localProductTypeData.push(productTypeBody)
        }
        postBody['store_product_type'] = localProductTypeData

        if (parentTemplateData !== 'Choose a Parent Template') postBody['parent_product_template'] = parentTemplateData

        postBody['procure_template'] = procureTemplateServerData

        setIsUpLoading(true)

        MarketplaceServices.save(productTemplateAPI, postBody)
            .then((response) => {
                console.log(
                    'Server Success Response From Add Product data',
                    productTemplateAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                dispatch(fnMakeAPICall({ makeAPICall: false }))

                setProductTemplatePostData(response.data.response_body)

                const productTemplateDisplayNameBody = {
                    product_displaynames: [
                        {
                            product: response.data.response_body[0].product_template.id,
                            language_code: String(byDefaultLanguageCode),
                            display_name: response.data.response_body[0].product_template.name,
                            description: response.data.response_body[0].product_template.description,
                        },
                    ],
                }
                setApiCountForDisplayName(0)
                const productRevisionDisplayNameBody = {
                    product_revision_displaynames: [
                        {
                            product_revision: response.data.response_body[0].id,
                            language_code: String(byDefaultLanguageCode),
                            description: response.data.response_body[0].description,
                        },
                    ],
                }
                setIsModalOpen(false)
                setProductTemplateName('')
                setProductTemplateDescription('')
                setAlreadySelectedProductTypes([])
                setAlreadySelectedParentData()
                setProcureTemplateData([])
                setIsUpLoading(false)
            })
            .catch((error) => {
                console.log('Error Server response from saveProductTemplateData', productTemplateAPI, error.response)
                MarketplaceToaster.showToast(error.response)
                dispatch(fnMakeAPICall({ makeAPICall: false }))
                setIsUpLoading(false)
            })
    }

    //! validation of post call of product template
    const validatePostCall = () => {
        const patternName = /^[a-zA-Z0-9_ ]*$/
        let count = 3
        if (
            productTemplateName.trim() === '' &&
            productTemplateDescription.trim() === '' &&
            selectedStoreProductTypes === 'Choose Product Type'
        ) {
            count--
            setInvalidProductTemplateName(true)
            setInvalidProductTemplateDescription(true)
            setInValidSelectedStoreProductTypes(true)
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() !== '' &&
            productTemplateDescription.trim() === '' &&
            selectedStoreProductTypes === 'Choose Product Type'
        ) {
            count--
            setInvalidProductTemplateDescription(true)
            setInValidSelectedStoreProductTypes(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() !== '' &&
            productTemplateDescription.trim() !== '' &&
            selectedStoreProductTypes === 'Choose Product Type'
        ) {
            count--

            setInValidSelectedStoreProductTypes(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() === '' &&
            productTemplateDescription.trim() !== '' &&
            selectedStoreProductTypes !== 'Choose Product Type'
        ) {
            count--
            setInvalidProductTemplateName(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() !== '' &&
            productTemplateDescription.trim() === '' &&
            selectedStoreProductTypes !== 'Choose Product Type'
        ) {
            count--

            setInvalidProductTemplateDescription(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() === '' &&
            productTemplateDescription.trim() !== '' &&
            selectedStoreProductTypes === 'Choose Product Type'
        ) {
            count--
            setInvalidProductTemplateName(true)
            setInValidSelectedStoreProductTypes(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (
            productTemplateName.trim() === '' &&
            productTemplateDescription.trim() === '' &&
            selectedStoreProductTypes !== 'Choose Product Type'
        ) {
            count--
            setInvalidProductTemplateName(true)
            setInvalidProductTemplateDescription(true)
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        }

        if (count === 3) {
            saveProductTemplateData()
        }
    }

    const validatePutCall = () => {
        let count = 2
        let n = ''

        let storeProductTypeData =
            productTemplateSelectedInfo &&
            productTemplateSelectedInfo.length > 0 &&
            productTemplateSelectedInfo[0].storeProductType
        if (alreadySelectedProductTypes && alreadySelectedProductTypes.length > 0) {
            for (var i = 0; i < alreadySelectedProductTypes.length; i++) {
                let indexChecking =
                    storeProductTypeData &&
                    storeProductTypeData.length > 0 &&
                    storeProductTypeData.findIndex(
                        (ele) => ele.store_product_type_id === alreadySelectedProductTypes[i].value
                    )

                if (indexChecking !== -1) {
                    n = true
                } else {
                    n = false
                    break
                }
            }
        }

        if (
            storeProductTypeAPIData === productTypeValuesSelectedFromDropdown &&
            procureTemplateAPIData === procureTemplateDropdownData
        ) {
            count--

            MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'warning'))
        }

        if (count === 2) {
            updateProductTypeProcureTemplate()
        }
    }

    //! In this we are getting Store product types selected while creating the Product template
    //!get specific  product Template based on Product Template Id and   Product Template Revision Id
    const findAllWithoutPageProductTemplatesByIdAndRevisionId = (productTemplateId, productTemplateRevisionId) => {
        // setIsUpLoading(true);

        let temp = {
            'product-template-id': productTemplateId,
            'product-template-revision-id': productTemplateRevisionId,
        }
        MarketplaceServices.findAllWithoutPage(productTemplateAPI, temp, true)
            .then(function (response) {
                console.log(
                    ' Server Response findAllWithoutPageProductTemplatesByIdAndRevisionId API is  ',
                    productTemplateAPI,
                    response.data.response_body
                )
                const storeProductTemplateData = response.data.response_body.StoreProductRevision[0]

                let finalArray = []
                let firstObject = {}
                firstObject['id'] = storeProductTemplateData.id
                firstObject['vendorId'] = storeProductTemplateData.vendor_id
                firstObject['productRevisionStatus'] = storeProductTemplateData.product_revision_status
                firstObject['revisionNumber'] = storeProductTemplateData.revision_number
                firstObject['parentProductTemplate'] = storeProductTemplateData.parent_product_template
                firstObject['storeProductTypeName'] = storeProductTemplateData.store_product_type_name
                firstObject['isVendorRestricted'] = storeProductTemplateData.is_vendor_restricted
                firstObject['restrictedVendors'] = storeProductTemplateData.restricted_vendors
                firstObject['restrictedVendorTypes'] = storeProductTemplateData.restricted_vendor_types
                firstObject['procureTemplate'] = storeProductTemplateData.procure_template
                firstObject['revisionDisplayDescription'] = storeProductTemplateData.revisiondisplay_description
                firstObject['productTemplateId'] = storeProductTemplateData.product_template.id
                firstObject['name'] = storeProductTemplateData.product_template.name
                firstObject['description'] = storeProductTemplateData.product_template.description
                firstObject['storeId'] = storeProductTemplateData.product_template.store_id
                firstObject['lastPublishedRevision'] = storeProductTemplateData.product_template.last_published_revision
                firstObject['productStatus'] = storeProductTemplateData.product_template.product_status
                firstObject['storeProductType'] = storeProductTemplateData.store_product_type
                firstObject['procureTemplateDisplayNames'] = storeProductTemplateData.procure_template_displaynames
                firstObject['parentTemplateDisplayName'] = storeProductTemplateData.parent_template_display_name
                finalArray.push(firstObject)
                let secondObject = {}
                secondObject['name'] = storeProductTemplateData.product_template.display_name
                secondObject['description'] = storeProductTemplateData.product_template.display_description
                finalArray.push(secondObject)

                dispatch(fnProductTemplateSelectedInfo(finalArray))
            })
            .catch(function (error) {
                console.log(
                    'Server Error response from product templates by id and Revision id',
                    productTemplateAPI,
                    error
                )
            })
    }

    let parentTemplateMappingData = []
    //! recursive function which helps to get product heirachy with parent and children relation
    const recursiveFunctionForProductHeirarchy = (childrenData) => {
        if (childrenData && childrenData.length > 0) {
            for (var i = 0; i < childrenData.length; i++) {
                parentTemplateMappingData.push(childrenData[i].product_template_id)
                // setServerData(serverData.push(temp));
                recursiveFunctionForProductHeirarchy(childrenData[i].parent_product_template)
            }
        }
    }

    //! product template herirachy function inside that we are calling above recurive function
    const productTemplateherirachy = (data) => {
        for (var i = 0; i < data.length; i++) {
            parentTemplateMappingData.push(data[i].product_template_id)
            //setServerData(serverData.push(temp));
            recursiveFunctionForProductHeirarchy(data[i].parent_product_template)
        }
    }

    //! get call of pageProductHierarchy
    const findAllWithoutPageProductHierachy = (productTemplateRevisionId) => {
        let temp = {
            'product-template-revision-id': parseInt(productTemplateRevisionId),
        }
        MarketplaceServices.findAllWithoutPage(productHierarchyAPI, temp, false)
            .then(function (response) {
                console.log(
                    'Server response from findAllWithoutPageProductHierachy findAllWithoutPage---->',
                    productHierarchyAPI,
                    response.data
                )
                productTemplateherirachy(response.data.response_body.parent_product_template)

                setProductHeirarchyData(parentTemplateMappingData)
            })
            .catch(function (error) {
                console.log(
                    'error Server response from findAllWithoutPageProductHierachy is->',
                    productHierarchyAPI,
                    error
                )
            })
    }

    //! this useffect is to fill the dropdown values inside for procure template

    const updateTreeSelectData = () => {
        const add = true
        let data = getParentChildrenDataForProductParentTemplate(
            activeProductTemplatesInfo,
            0,
            productHeirarchyData.concat(parentTemplateData),
            add
        )

        setActiveProductTemplateFilteredData(data[0].localProductTemplateData)
    }

    //! here we are calling activeproduct template get call storeproduct types get call
    useEffect(() => {
        findAllWithoutPageStoreProductTypes(productTemplateId)
    }, [productTemplateId])

    //! once after display name post call is successfull we are navigating to edit section
    useEffect(() => {
        if (apiCountForDisplayName === 0) {
            if (productTemplatePostData && productTemplatePostData.length > 0) {
                const productTemplateId = productTemplatePostData[0].product_template.id
                const productRevisionId = productTemplatePostData[0].id
                const productStatus = productTemplatePostData[0].product_template.product_status
                navigate(
                    `/dashboard/product-template/edit-product-template?ptsid=${productTemplateId}&ptsrid=${productRevisionId}&ptss=${productStatus}&${MarketplaceAppConfig.getStore(
                        ''
                    )}`
                )
                // navigate(0);
            }
        }
    }, [apiCountForDisplayName])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        findAllWithoutPageActivePoductTemplate()
    }, [])

    useEffect(() => {
        if (productTemplateSelectedInfo?.length > 0) {
            setProductTemplateName(productTemplateSelectedInfo[1].name)
            setProductTemplateDescription(productTemplateSelectedInfo[1].description)
            setEditProcureTemplate(productTemplateSelectedInfo[0].procureTemplate)
            setProcureTemplateServerData(productTemplateSelectedInfo[0].procureTemplate)
            const productTypeFromAPI = productTemplateSelectedInfo[0].storeProductType
            const productTypeNewArray = [...new Set(productTypeFromAPI.map((item) => item.store_product_type_id))]

            setStoreProductTypeAPIData(productTypeNewArray)
            setProductTypeValuesSelectedFromDropdown(productTypeNewArray)

            //! here we are setting procure template data from API
            const procureAPIDataOne = productTemplateSelectedInfo[0].procureTemplate
            setProcureTemplateAPIData(procureAPIDataOne)
            setProcureTemplateDropdownData(procureAPIDataOne)
            let temp = []
            let temp1 = []
            for (var i = 0; i < productTemplateSelectedInfo[0].storeProductType.length; i++) {
                temp1.push(productTemplateSelectedInfo[0].storeProductType[i].store_product_type_id)
                let localData = {}
                localData['value'] = productTemplateSelectedInfo[0].storeProductType[i].store_product_type_id
                localData['store_product_type'] =
                    productTemplateSelectedInfo[0].storeProductType[i].store_product_type_id
                localData['store_product_type_revision'] =
                    productTemplateSelectedInfo[0].storeProductType[i].store_product_type_revision_id
                temp.push(localData)
            }
            setAlreadySelectedProductTypes(temp)

            setAlreadySelectedParentData(
                productTemplateSelectedInfo[0]?.parentProductTemplate
                    ? productTemplateSelectedInfo[0]?.parentProductTemplate
                    : null
            )
            setParentTemplateData(
                productTemplateSelectedInfo[0]?.parentProductTemplate
                    ? productTemplateSelectedInfo[0]?.parentProductTemplate
                    : null
            )
            const data =
                activeProductTemplateFlatData &&
                activeProductTemplateFlatData.length > 0 &&
                activeProductTemplateFlatData.filter(
                    (ele) => ele.value === productTemplateSelectedInfo[0]?.parentProductTemplate
                )

            if (data && data.length > 0) {
                setParentTemplateRevisionId(data[0].revisionId)
            }
        }
    }, [productTemplateSelectedInfo, activeProductTemplateFilteredData])

    useEffect(() => {
        if (parentTemplateRevisionId) {
            findAllWithoutPageProductHierachy(parentTemplateRevisionId)
        }
    }, [parentTemplateRevisionId])

    useEffect(() => {
        if (
            parentTemplateData !== undefined &&
            parentTemplateData !== 'Choose a Parent Template' &&
            activeProductTemplatesInfo !== undefined
        ) {
            let data = getParentChildrenDataForProductParentTemplate(
                activeProductTemplatesInfo,
                productHeirarchyData.concat(parentTemplateData)
            )

            setActiveProductTemplateFilteredData([...data[0]?.localProductTemplateData])
            console.log('data[0]?.localProductTemplateData--------->', data[0]?.localProductTemplateData)

            if (action !== 'edit') {
                if (procureTemplateServerData && procureTemplateServerData.length > 0) {
                    let tempData = productHeirarchyData.concat(parentTemplateData)

                    setProcureTemplateData(tempData.concat(procureTemplateServerData))
                } else {
                    setProcureTemplateData(productHeirarchyData.concat(parentTemplateData))
                }
            } else {
                if (parentTemplateData !== null) {
                    let sampleData = productHeirarchyData.concat(parentTemplateData)

                    setProcureTemplateData(sampleData.concat(editProcureTemplate))
                } else {
                    setProcureTemplateData(editProcureTemplate)
                }
            }
        }
    }, [parentTemplateData, productHeirarchyData, editProcureTemplate, activeProductTemplatesInfo])

    console.log('activeProductTemplateFilteredData123--------->', activeProductTemplateFilteredData)

    return (
        <Content>
            <Spin
                tip='Please wait!'
                size='large'
                // spinning={action === "edit" && isLoadingProductTemplatesById}
                spinning={isUpLoading}>
                <Content>
                    <Typography disabled={disableFeilds} className='input-label-color mb-2'>
                        {t('common:product_template')}
                        <span
                            className={`mandatory-symbol-color text-sm ml-1 ${langDirection == 'rtl' ? '!mr-1' : ''}`}>
                            *
                        </span>
                    </Typography>
                    <Input
                        disabled={action === 'edit' ? true : false}
                        placeholder={t('product_template:enter_product_template')}
                        maxLength={parseInt(titleMaxLength)}
                        value={productTemplateName}
                        onChange={(e) => {
                            if (onChangeValues === false) {
                                setOnChangeValues(true)
                            }

                            setProductTemplateName(e.target.value)

                            setInvalidProductTemplateName(false)
                        }}
                        onBlur={() => {
                            const trimmed = productTemplateName.trim()
                            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                            setProductTemplateName(trimmedUpdate)
                        }}
                        className={`${
                            invalidProductTemplateName
                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                : ' border-[#C6C6C6]'
                        }`}
                    />
                    <Typography disabled={disableFeilds} className='mt-3 mb-2 input-label-color'>
                        {t('common:description')}
                        <span
                            className={`mandatory-symbol-color text-sm ml-1 ${langDirection == 'rtl' ? '!mr-1' : ''}`}>
                            *
                        </span>
                    </Typography>
                    <Content className={`flex ${action === 'edit' ? ' relative ' : '!w-[100%]  border-[#C6C6C6]'}`}>
                        <TextArea
                            disabled={action === 'edit' ? true : false}
                            rows={1}
                            showCount
                            maxLength={parseInt(descriptionMaxLength)}
                            autoSize={true}
                            placeholder={t('product_template:enter_description')}
                            value={productTemplateDescription}
                            onChange={(e) => {
                                if (onChangeValues === false) {
                                    setOnChangeValues(true)
                                }
                                setProductTemplateDescription(e.target.value)
                                setInvalidProductTemplateDescription(false)
                            }}
                            onBlur={() => {
                                const trimmed = productTemplateDescription.trim()
                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')

                                setProductTemplateDescription(trimmedUpdate)
                            }}
                            className={`!pr-[3px] ${
                                invalidProductTemplateDescription
                                    ? 'border-red-400  border-[1px] border-solid !w-[100%]  focus:border-red-400 hover:border-red-400'
                                    : 'border-[#C6C6C6] !w-[100%]'
                            }`}
                        />
                    </Content>

                    <Row>
                        <Col span={12} className='pr-1'>
                            <Typography className='mt-3 mb-2 input-label-color' disabled={disableFeilds}>
                                {t('product_template:parent_template')}
                            </Typography>
                            <TreeSelect
                                className='w-100 !truncate'
                                listHeight={130}
                                loading={parentTemplatesLoading}
                                // dropdownStyle={{ zIndex: action === 'edit' ? 1 : '' }}

                                value={parentTemplatesLoading == false ? alreadySelectedParentData : undefined}
                                allowClear
                                disabled={action === 'edit' ? true : false}
                                treeData={activeProductTemplateFilteredData}
                                placeholder={t('product_template:choose_a_parent')}
                                onChange={parentTemplateChange}
                            />
                        </Col>
                        <Col span={12} className={`pl-1 ${langDirection == 'rtl' ? '!pr-3' : ''}`}>
                            <Typography level={5} className='mt-3 mb-2 input-label-color' disabled={disableFeilds}>
                                {t('product_template:product_types')}
                                <span
                                    className={`mandatory-symbol-color text-sm ml-1 ${
                                        langDirection == 'rtl' ? '!mr-1' : ''
                                    }`}>
                                    *
                                </span>
                            </Typography>
                            <Select
                                className='w-100'
                                status={inValidSelectedStoreProductTypes ? 'error' : ''}
                                listHeight={130}
                                loading={storeProductTypesLoading}
                                mode='multiple'
                                allowClear
                                value={storeProductTypesLoading == false ? alreadySelectedProductTypes : undefined}
                                showArrow
                                showSearch={false}
                                disabled={
                                    action === 'edit'
                                        ? revisionSelectedInfo &&
                                          revisionSelectedInfo.product_revision_status < 2 &&
                                          !disableFeilds
                                            ? false
                                            : true
                                        : false
                                }
                                placeholder={t('product_template:choose_product_type')}
                                onChange={handleStoreProductTypeChange}
                                options={getStoreProductTypeFilteredData}
                                maxTagCount={1}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Typography level={5} className='mb-2 input-label-color'>
                            {t('product_template:copy_attributes_from')}
                        </Typography>
                        <TreeSelect
                            className='w-100'
                            placeholder={t('product_template:choose_your_desired_template')}
                            loading={parentTemplatesLoading}
                            value={
                                !parentTemplatesLoading
                                    ? procureTemplateData.filter((item) => item !== alreadySelectedParentData)
                                    : []
                            }
                            showArrow
                            disabled={
                                action === 'edit'
                                    ? revisionSelectedInfo &&
                                      revisionSelectedInfo.product_revision_status < 3 &&
                                      !disableFeilds
                                        ? false
                                        : true
                                    : false
                            }
                            treeData={activeProductTemplateFilteredData}
                            treeCheckable={true}
                            onChange={handleProcureSelectChange}
                            showCheckedStrategy={SHOW_PARENT}
                            treeCheckStrictly={true}
                            maxTagCount={1}
                            allowClear={true}
                            filterTreeNode={(search, item) => {
                                return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                            }}
                        />
                    </Row>
                </Content>

                <Content className={`text-right ${langDirection == 'rtl' ? '!text-left' : ''}`}>
                    <Button
                        onClick={() => {
                            setIsModalOpen(false)
                            setInvalidProductTemplateName(false)
                            setInvalidProductTemplateDescription(false)
                            setInValidSelectedStoreProductTypes(false)
                            setProductTemplateName('')
                            setProductTemplateDescription('')
                            setAlreadySelectedProductTypes([])
                            setAlreadySelectedParentData()
                            setProcureTemplateData([])
                            setSelectedStoreProductTypes('Choose Product Type')
                            updateTreeSelectData()
                            setParentTemplateData('Choose a Parent Template')
                            setProcureTemplateServerData([])
                        }}
                        className='app-btn-secondary mr-2'>
                        {t('common:cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (action == 'edit') {
                                validatePutCall()
                            } else {
                                validatePostCall()
                            }
                        }}
                        disabled={
                            productTemplateName.trim() != '' ||
                            productTemplateDescription.trim() != '' ||
                            selectedStoreProductTypes != 'Choose Product Type' ||
                            parentTemplateData != 'Choose a Parent Template' ||
                            procureTemplateServerData.length !== 0
                                ? false
                                : true
                        }
                        className={`app-btn-primary ${langDirection == 'rtl' ? '!mr-2' : ''}`}>
                        {action == 'edit' ? t('common:update') : t('common:proceed')}
                    </Button>
                </Content>
            </Spin>
        </Content>
    )
}

export default AddProductTemplate
