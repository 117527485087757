import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Layout, Select, Tooltip, Typography, Collapse, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

const { Content } = Layout
const { Title } = Typography
const { Panel } = Collapse
const productTypeLayoutMapping = process.env.REACT_APP_PRODUCT_TYPE_LAYOUT_MAPPING
const productTypeLayout = process.env.REACT_APP_PRODUCT_TYPE_LAYOUT_API
const categoryLayoutMapping = process.env.REACT_APP_CATEGORY_LAYOUT_MAPPING
const productLayoutMappingAPI = process.env.REACT_APP_PRODUCT_LAYOUT_MAPPING_API
const ChooseLayout = ({
    type,
    id,
    filterType,
    setEnableDisableSaveBtn,
    isLayoutMappingRequired,
    setIsLayoutMappingRequired,
    productTypeId,
}) => {
    const { t } = useTranslation()
    const [selectedLayoutName, setSelectedLayoutName] = useState('')
    const [selectedLayoutNameFromServer, setSelectedLayoutNameFromServer] = useState('')
    const [validationOfLayout, setValidationOfLayout] = useState(false)
    const [listOfLayouts, setListOfLayouts] = useState([])
    const [mappingId, setMappingId] = useState()
    const [layoutId, setLayoutId] = useState()
    const [isLayoutMappingChanged, setIsLayoutMappingChanged] = useState(false)
    const [langDirection, setLangDirection] = useState('ltr')
    const search = useLocation().search
    let version_status = new URLSearchParams(search).get('vrsts')
    //!GET call to get all layouts which are created.
    const handleGetLayouts = () => {
        MarketplaceServices.findAll(productTypeLayout, { product_type: parseInt(productTypeId) }, false)
            .then(function (response) {
                console.log(
                    'Server response from handleGetLayouts API is: ',
                    productTypeLayout,
                    response.data.response_body.data
                )
                if (response.data.response_body.data.layout_data.length > 0) {
                    let filterData = response.data.response_body.data.layout_data.filter(
                        (ele) => ele.type === filterType
                    )
                    if (filterData && filterData.length > 0) {
                        setListOfLayouts(filterData)
                    } else {
                        setListOfLayouts([])
                    }
                }
            })
            .catch(function (error) {
                console.log('Server Error response from handleGetLayouts is', productTypeLayout, error.response)
                setListOfLayouts([])
            })
    }

    //!onChangeHandler of layout dropdown
    /**
     * This is function which is responsible for the layout dropdown change.
     * @param {value} value
     * @param {*} obj
     */
    const handleChangeLayouts = (value, obj) => {
        setValidationOfLayout(false)
        setIsLayoutMappingChanged(true)
        setLayoutId(value)
        setSelectedLayoutName(obj.title)
    }

    //! Api's call related to product type layout mapping
    //!POST Call for the product type mapping layout.
    const productTypeMappingPostCall = () => {
        let temp = {
            product_type_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.save(productTypeLayoutMapping, temp, null)
            .then(function (response) {
                console.log(
                    'Server response from productTypeMappingPostCall is',
                    productTypeLayoutMapping,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                productTypeMappingGetCall()
                setIsLayoutMappingChanged(false)
            })
            .catch((error) => {
                console.log(
                    'Error server response from productTypeMappingPostCall is',
                    productTypeLayoutMapping,
                    error.response
                )
                MarketplaceToaster.showToast(error.response)
                setIsLayoutMappingChanged(false)
            })
    }

    //!PUT Call for the product type mapping layout.
    const productTypeMappingPutCall = () => {
        let temp = {
            product_type_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.update(productTypeLayoutMapping, temp, {
            mapping_id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Response of Product Type Mapping Layout PUT call --->',
                    productTypeLayoutMapping,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                productTypeMappingGetCall()
                setIsLayoutMappingChanged(false)
            })
            .catch((error) => {
                console.log(
                    'error Server response from productTypeMappingPutCall is >',
                    productTypeLayoutMapping,
                    error
                )
                setIsLayoutMappingChanged(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //!PUT Call for the product type mapping layout.
    const productTypeMappingGetCall = () => {
        MarketplaceServices.findAll(
            productTypeLayoutMapping,
            {
                product_type_id: id,
            },
            true
        )
            .then(function (response) {
                console.log(
                    'Server Response of productTypeMappingGetCall Layout GET call --->',
                    productTypeLayoutMapping,
                    response.data.response_body.product_type_layout_data
                )

                if (
                    response &&
                    response.data &&
                    response.data.response_body &&
                    response.data.response_body.product_type_layout_data &&
                    response.data.response_body.product_type_layout_data.length > 0
                ) {
                    setMappingId(response.data.response_body.product_type_layout_data[0].id)
                    setLayoutId(response.data.response_body.product_type_layout_data[0].layout_id)
                    let layoutIdMapped = response.data.response_body.product_type_layout_data[0].layout_id
                    if (listOfLayouts && listOfLayouts.length > 0 && layoutIdMapped) {
                        let temp = listOfLayouts.filter((ele) => parseInt(ele.id) === parseInt(layoutIdMapped))
                        console.log(
                            'temporary array from the product type layout mapping GET call for the disabled feature--->',
                            temp
                        )

                        if (temp && temp.length > 0) {
                            setSelectedLayoutNameFromServer(temp[0].display_name)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log('error Server response from productTypeLayoutMapping is', productTypeLayoutMapping, error)
                setIsLayoutMappingChanged(false)
            })
    }

    //! Api's call related to category layout mapping
    //!POST Call for the category mapping layout.
    const categoryMappingPostCall = () => {
        let temp = {
            category_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.save(categoryLayoutMapping, temp, null)
            .then(function (response) {
                console.log(
                    'Server Response of categoryMappingPostCall',
                    categoryLayoutMapping,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                categoryMappingGetCall()
                setEnableDisableSaveBtn(true)
                setIsLayoutMappingChanged(false)
            })
            .catch((error) => {
                console.log('error Server Response of categoryMappingPostCall', categoryLayoutMapping, error)
                MarketplaceToaster.showToast(error.response)
                setIsLayoutMappingChanged(false)
                setEnableDisableSaveBtn(true)
            })
    }

    //!PUT Call for the category mapping layout.
    const categoryMappingPutCall = () => {
        let temp = {
            category_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.update(categoryLayoutMapping, temp, {
            mapping_id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Server Response of categoryMappingPutCall',
                    categoryLayoutMapping,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                categoryMappingGetCall()
                setIsLayoutMappingChanged(false)
            })
            .catch((error) => {
                console.log('error Server Response of categoryMappingPutCall', categoryLayoutMapping, error)
                setIsLayoutMappingChanged(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //!GET Call for the category mapping layout.
    const categoryMappingGetCall = () => {
        MarketplaceServices.findAll(categoryLayoutMapping, { category_id: id }, true)
            .then(function (response) {
                console.log(
                    'server Response from categoryMappingGetCall API is',
                    categoryLayoutMapping,
                    response.data.response_body.category_layout_data
                )
                if (
                    response &&
                    response.data &&
                    response.data.response_body.category_layout_data &&
                    response.data.response_body.category_layout_data.length > 0
                ) {
                    setMappingId(response.data.response_body.category_layout_data[0].id)
                    setLayoutId(response.data.response_body.category_layout_data[0].layout_id)
                    let layoutIdMapped = response.data.response_body.product_layout_data[0].layout_id
                    if (listOfLayouts && listOfLayouts.length > 0 && layoutIdMapped) {
                        let temp = listOfLayouts.filter((ele) => parseInt(ele.id) === parseInt(layoutIdMapped))
                        if (temp && temp.length > 0) {
                            setSelectedLayoutNameFromServer(temp[0].display_name)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log('server Response from categoryMappingGetCall API is', categoryLayoutMapping, error)
            })
    }

    //! Api's call related to product layout mapping
    //!POST Call for the product mapping layout.
    const productMappingPostCall = () => {
        let temp = {
            product_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.save(productLayoutMappingAPI, temp, null)
            .then(function (response) {
                console.log(
                    'Server Response of productMappingPostCall API is',
                    productLayoutMappingAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                productMappingGetCall()
                setIsLayoutMappingChanged(false)
            })
            .catch((error) => {
                console.log('error Server Response of productMappingPostCall API is', productLayoutMappingAPI, error)
                MarketplaceToaster.showToast(error.response)
                setIsLayoutMappingChanged(false)
            })
    }

    //!PUT Call for the product mapping layout.
    const productMappingPutCall = () => {
        let temp = {
            product_id: id,
            layout_id: layoutId,
        }
        MarketplaceServices.update(productLayoutMappingAPI, temp, {
            mapping_id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Server Response of productMappingPutCall API is',
                    productLayoutMappingAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)
                setIsLayoutMappingChanged(false)
                productMappingGetCall()
            })
            .catch((error) => {
                console.log('error Server Response of productMappingPutCall API is', productLayoutMappingAPI, error)
                setIsLayoutMappingChanged(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //!GET Call for the product mapping layout.
    const productMappingGetCall = () => {
        MarketplaceServices.findAll(
            productLayoutMappingAPI,
            {
                product_id: id,
            },
            true
        )
            .then(function (response) {
                console.log(
                    'Server Response from  productMappingGetCall API call is',
                    productLayoutMappingAPI,
                    response.data.response_body.product_layout_data
                )
                if (
                    response &&
                    response.data &&
                    response.data.response_body &&
                    response.data.response_body.product_layout_data &&
                    response.data.response_body.product_layout_data &&
                    response.data.response_body.product_layout_data.length > 0
                ) {
                    setMappingId(response.data.response_body.product_layout_data[0].id)
                    setLayoutId(response.data.response_body.product_layout_data[0].layout_id)
                    let layoutIdMapped = response.data.response_body.product_layout_data[0].layout_id
                    if (listOfLayouts && listOfLayouts.length > 0 && layoutIdMapped) {
                        let temp = listOfLayouts.filter((ele) => parseInt(ele.id) === parseInt(layoutIdMapped))
                        if (temp && temp.length > 0) {
                            setSelectedLayoutNameFromServer(temp[0].display_name)
                        }
                    }
                }
            })
            .catch((error) => {
                console.log('Server Response from  productMappingGetCall API call is', productLayoutMappingAPI, error)
            })
    }

    //! useEffect to call the mapped layout for category, product and product type
    useEffect(() => {
        if (productTypeId !== undefined) {
            handleGetLayouts()
            if (type === 'product-type') {
                productTypeMappingGetCall()
            } else if (type === 'category') {
                categoryMappingGetCall()
            } else if (type === 'product') {
                productMappingGetCall()
            }
        }
    }, [productTypeId])

    //!Validation function for the selected layout is empty of onSelect layout dropdown
    const handleValidationLayouts = () => {
        let count = 1
        if (selectedLayoutName === '') {
            count--
            setValidationOfLayout(true)

            MarketplaceToaster.showToast(util.getToastObject(`${t('products:please_select_layout')}`, 'error'))
        }
        if (type === 'product-type') {
            if (count === 1) {
                if (mappingId !== undefined) {
                    productTypeMappingPutCall()
                } else {
                    productTypeMappingPostCall()
                }
            }
        } else if (type === 'category') {
            if (count === 1) {
                if (mappingId !== undefined) {
                    categoryMappingPutCall()
                } else {
                    categoryMappingPostCall()
                }
            }
        } else if (type === 'product') {
            if (count === 1) {
                if (mappingId !== undefined) {
                    productMappingPutCall()
                } else {
                    productMappingPostCall()
                }
            }
        }
    }

    //! when ever we get listOfAllLayouts and particular layout id mapped to product type or category that time to show the selected layout in UI
    useEffect(() => {
        if (listOfLayouts && listOfLayouts.length > 0 && layoutId) {
            let temp = listOfLayouts.filter((ele) => ele.id === layoutId)
            if (temp && temp.length > 0) {
                setSelectedLayoutName(temp[0].name)
            }
        }
    }, [listOfLayouts, layoutId])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    return (
        <Content>
            <Collapse accordion bordered={false} className='!bg-white panel-alignment'>
                <Panel
                    header={
                        // <label className='text-regal-blue font-semibold !leading-[26px] !text-[18px]'>
                        //     {t('products:product_layout')}
                        // </label>
                        <div className='flex flex-row justify-between pb-2'>
                            <p className='font-semibold text-lg'>{t('products:product_layout')}</p>
                        </div>
                    }>
                    <div className='px-3'>
                        <Typography className='!text-sm !m-0'>
                            <Content className='flex items-baseline'>
                                <Typography className='text-brandGray2'>
                                    {type === 'product-type' ? (
                                        <>{t('product_types:default_physical_product_description_layout')}</>
                                    ) : (
                                        <>{t('products:product_description_layout')}</>
                                    )}
                                </Typography>
                                <Tooltip
                                    placement='right'
                                    overlayStyle={{ zIndex: 1 }}
                                    title={
                                        <>
                                            {t('products:personalize_your_store')}{' '}
                                            {type === 'product-type'
                                                ? `${t('products:product_details')}`
                                                : `${t('products:product_listing')}`}{' '}
                                            {t('products:select_layout_from')}
                                        </>
                                    }>
                                    <InfoCircleOutlined
                                        className={`${langDirection === 'rtl' ? '!mr-2' : '!ml-2'} !text-brandGray2 translate-y-[1px]`}
                                    />
                                </Tooltip>
                            </Content>
                        </Typography>
                        <Content className='my-3'>
                            <Select
                                disabled={
                                    type === 'product' ||
                                    parseInt(version_status) === 5 ||
                                    parseInt(version_status) === 4 ||
                                    parseInt(version_status) === 3
                                        ? true
                                        : false
                                }
                                dropdownStyle={{ zIndex: 1 }}
                                value={layoutId}
                                placeholder={t('products:please_select_layout')}
                                onChange={handleChangeLayouts}
                                className={`${
                                    validationOfLayout
                                        ? 'border-red-400  border-[1px] rounded-sm border-solid focus:border-red-400 hover:border-red-400 w-[80%]'
                                        : 'w-[50%]'
                                }`}>
                                {listOfLayouts &&
                                    listOfLayouts.length > 0 &&
                                    listOfLayouts.map((e) => (
                                        <Select.Option
                                            value={e.id}
                                            title={e.display_name}
                                            disabled={selectedLayoutNameFromServer === e.display_name ? true : false}
                                            componentName={e.display_name}>
                                            {e.layout_display_name}
                                        </Select.Option>
                                    ))}
                            </Select>
                            {parseInt(version_status) === 1 || parseInt(version_status) === 2 ? (
                                <Content className='!mt-5'>
                                    <Button
                                        onClick={handleValidationLayouts}
                                        disabled={!isLayoutMappingChanged}
                                        className={`app-btn-primary ${!isLayoutMappingChanged ? 'opacity-50' : ''} mr-2`}>
                                        {t('common:update')}
                                    </Button>
                                </Content>
                            ) : null}
                            {type === 'product-type' ? (
                                <Content className='!mt-5'>
                                    <Button
                                        onClick={handleValidationLayouts}
                                        disabled={!isLayoutMappingChanged}
                                        className={`app-btn-primary ${!isLayoutMappingChanged ? 'opacity-50' : ''} mr-2`}>
                                        {t('common:update')}
                                    </Button>
                                </Content>
                            ) : null}
                        </Content>
                    </div>
                </Panel>
            </Collapse>
        </Content>
    )
}

export default ChooseLayout
