import { Layout, Tooltip, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import { EditIcon } from '../../constants/media'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import ListCurrentSettlement from './ListCurrentSettlement'
import ListPastSettlement from './ListPastSettlement'
import PencilIcon from './Icons/PencilIcon'
//! ant design initialization
const { Content } = Layout
const { Title, Text } = Typography

const SettlementHeader = ({ vendorDisplayName }) => {
    const { t } = useTranslation()
    const search = useLocation().search
    const navigate = useNavigate()
    const vid = new URLSearchParams(search).get('vid')
    const vname = new URLSearchParams(search).get('vname')
    const [searchParams, setSearchParams] = useSearchParams()

    const tabData = [
        {
            key: 0,
            label: <span> {t('transactions:transactions')} </span>,
        },
        {
            key: 1,
            label: (
                <span className={`${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mx-2' : ''}`}>
                    {' '}
                    {t('transactions:settlements')}{' '}
                </span>
            ),
        },
    ]

    const handleTabChange = (status) => {
        setSearchParams({
            vid: vid,
            vname: vname,
            tabId: status,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }

    console.log('vname----->', vendorDisplayName, vid)
    return (
        <Content className='!min-w-full'>
            {vendorDisplayName === undefined || vendorDisplayName === null ? null : (
                <div>
                    <Content className='flex justify-between items-center'>
                        <Text
                            ellipsis={{
                                tooltip: { title: vendorDisplayName, mouseLeaveDelay: 0, mouseEnterDelay: 0.1 },
                            }}
                            className='w-[550px] text-lg font-semibold'>
                            {vendorDisplayName}
                        </Text>
                        <div
                            className='cursor-pointer flex justify-center items-center gap-2 svgIcon'
                            onClick={() =>
                                navigate(
                                    `/dashboard/vendors/vendors-details?v_id=${vid}&v_name=${vname}&${MarketplaceAppConfig.getStore('')}`
                                )
                            }>
                            <PencilIcon />
                            <Text className='text-regal-blue whitespace-nowrap font-medium app-btn-secondary'>
                                {t('transactions:edit_settlement_cycle_dates_and_journey')}
                            </Text>
                        </div>
                    </Content>
                    <Content className='mt-2'>
                        <DmTabAntDesign
                            tabType={'line'}
                            tabBarPosition={'top'}
                            tabData={tabData}
                            defaultSelectedTabKey={'0'}
                            activeKey={parseInt(searchParams.get('tabId')) === 1 ? 1 : 0}
                            handleTabChangeFunction={handleTabChange}
                        />
                    </Content>
                    <Content className=''>
                        {parseInt(searchParams.get('tabId')) === 1 ? <ListPastSettlement /> : <ListCurrentSettlement />}
                    </Content>
                </div>
            )}
        </Content>
    )
}

export default SettlementHeader
