import util from './common'
import localConfigData from '../constants/LocalConfig'

const configS3URL = process.env.REACT_APP_CONFIG_S3_URL

const getStore = (type) => {
    try {
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.has('store'))
            return type === 'onlyClient' ? searchParams.get('store') : 'store=' + searchParams.get('store')
        else {
            const client = util.getClient()
            if (client) return type === 'onlyClient' ? client : 'store=' + client
            else {
                const appConfig = getApplicationConfig()
                if (appConfig.config_details.length > 0) {
                    const activeStore = appConfig.config_details.filter(
                        (element) =>
                            element.store === searchParams.get('store') &&
                            element.client_id === util.getClientID(searchParams.get('store'))
                    )
                    if (activeStore.length > 0) {
                        util.setClient(activeStore[0].store)
                        return type === 'onlyClient' ? activeStore[0].store : 'store=' + activeStore[0].store
                    } else {
                        util.setClient(appConfig.config_details[0].store)
                        return type === 'onlyClient'
                            ? appConfig.config_details[0].store
                            : 'store=' + appConfig.config_details[0].store
                    }
                } else {
                    return ''
                }
            }
        }
    } catch (error) {
        return ''
    }
}

const getApplicationConfig = async () => {
    const response = await fetch(configS3URL)
    const appConfig = response.json()

    //if we want to switch to local config uncomment below line and comment above code
    // const appConfig=localConfigData;
    return appConfig
}

const MarketplaceAppConfig = {
    getStore,
}

export default MarketplaceAppConfig
