import { InfoCircleTwoTone } from '@ant-design/icons'
import { Button, Checkbox, Col, Row, Spin, Tooltip } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import attibutePrefernce from '../../constants/AttributePreference'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'
const coreAttributeAPI = process.env.REACT_APP_CORE_ATTRIBUTE_API

function PreferenceComponent({ preferenceObj }) {
    console.log('Props received from the preference', preferenceObj)
    const defaultPerence = {
        mandatory: preferenceObj.mandatory,
        sensible: preferenceObj.sensible,
        revisable: preferenceObj.revisable,
        is_static: preferenceObj.is_static,
        unique: preferenceObj.unique,
        hide_to_customer: preferenceObj.hide_to_customer,
        varient_specific: preferenceObj.varient_specific,
        order_specific: preferenceObj.order_specific,
        comparable: true,
        is_variant_attribute: false,
    }
    const [preferenceValues, setPreferenceValues] = useState(defaultPerence)
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
        if (preferenceObj) {
            let copyofpreferenceValues = { ...preferenceValues }
            copyofpreferenceValues.mandatory = preferenceObj.mandatory
            copyofpreferenceValues.sensible = preferenceObj.sensible
            copyofpreferenceValues.revisable = preferenceObj.revisable
            copyofpreferenceValues.is_static = preferenceObj.is_static
            copyofpreferenceValues.unique = preferenceObj.unique
            copyofpreferenceValues.hide_to_customer = preferenceObj.hide_to_customer
            copyofpreferenceValues.varient_specific = preferenceObj.varient_specific
            copyofpreferenceValues.order_specific = preferenceObj.order_specific
            copyofpreferenceValues.comparable = true
            setPreferenceValues(copyofpreferenceValues)
        }
    }, [preferenceObj])

    const preferenceHandler = (type, checked) => {
        let copyOfPreferenceValue = { ...preferenceValues }
        copyOfPreferenceValue[type] = checked
        setPreferenceValues(copyOfPreferenceValue)
    }

    const savePreference = () => {
        const payload = {
            sensible: preferenceValues.sensible,
            revisable: preferenceValues.revisable,
            unique: preferenceValues.unique,
            hide_to_customer: preferenceValues.hide_to_customer,
            varient_specific: preferenceValues.varient_specific,
        }
        setIsLoading(true)
        MarketplaceServices.update(coreAttributeAPI, payload, {
            mapping_id: preferenceObj.attribute_id,
        })
            .then(function (response) {
                setIsLoading(false)
                MarketplaceToaster.showToast(response)
                window.location.reload()
            })
            .catch(function (error) {
                setIsLoading(false)
                console.log('Error response from preference update')
                MarketplaceToaster.showToast(error.response)
            })
    }

    return (
        <Content>
            <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}>
                <Row className=' p-2 w-[24rem]'>
                    {attibutePrefernce
                        .filter(({ isEnable }) => isEnable)
                        .filter((item) => {
                            return item.type !== 'mandatory' && item.type !== 'order_specific'
                        })
                        .map(({ type, settingsName, isMandtory, description }) => {
                            return type == 'attribute_type' || type == 'is_static' ? (
                                ''
                            ) : (
                                <Col
                                    span={12}
                                    className='px-3 py-[0.28rem] flex items-center'
                                    key={`${type}_${preferenceValues[type]}_${settingsName}`}>
                                    <div className='whitespace-nowrap gap-1 flex items-center '>
                                        <Checkbox
                                            checked={preferenceValues[type] || isMandtory}
                                            disabled={
                                                t(settingsName.props.translate) === 'Static' ||
                                                t(settingsName.props.translate) === 'Comparable'
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => {
                                                preferenceHandler(type, e.target.checked)
                                            }}
                                        />
                                        {isMandtory ? (
                                            <div className='flex'>
                                                {settingsName}
                                                <span className='mandatory-symbol-color text-sm mx-1'>*</span>
                                            </div>
                                        ) : (
                                            settingsName
                                        )}
                                        <Tooltip title={description} placement='right'>
                                            <InfoCircleTwoTone className='text-xs' />
                                        </Tooltip>
                                    </div>
                                </Col>
                            )
                        })}
                </Row>
                <Row justify={'end'}>
                    <Button
                        style={{ fontWeight: 500 }}
                        className={'app-btn-primary'}
                        onClick={() => {
                            savePreference()
                        }}>
                        {t('common:save')}
                    </Button>
                </Row>
            </Spin>
        </Content>
    )
}

export default PreferenceComponent
