import { ActionTypes } from '../constants/ActionTypes'

const { RDX_VENDOR_INFO, RDX_VENDOR_SELECTED_INFO } = ActionTypes

export const fnVendorSelectedInfo = (data) => {
    return {
        type: RDX_VENDOR_SELECTED_INFO,
        payload: data,
    }
}
export const fnVendorInfo = (data) => {
    return {
        type: RDX_VENDOR_INFO,
        payload: data,
    }
}
