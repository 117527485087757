import { Input, Layout, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StoreModal from '../../../components/storeModal/StoreModal'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useUpdateApprovalStatus from '../hooks/useUpdateApprovalStatus'

const { Content } = Layout
const { TextArea } = Input
function ApproveModal({ showApproveModal, setShowApproveModal, typeChoice, revisionId, putApprovalId,refetchApprovalData }) {
    const { t } = useTranslation()
    const [approvalReason, setApprovalReason] = useState(null)
    const { mutate: updateApprovalStatus, status: updatedApprovalStatus } = useUpdateApprovalStatus()

    /**
     * ?To Construct  the object for the approval put object for Approval Put Call.
     */
    const constructApprovalPutObject = () => {
        let putObject = {}
        putObject.type_choice = typeChoice
        putObject.revision_id = parseInt(revisionId)
        putObject.revision_status = 3
        if (approvalReason) {
            putObject.approval_reason = approvalReason
        }
        updateApprovalStatus(
            { putApprovalId: putApprovalId, putBody: putObject },
            {
                onSuccess: (response) => {
                    refetchApprovalData()
                    console.log(response)
                    MarketplaceToaster.showToast(response)
                    setShowApproveModal(false)
                },
                onError: (err) => {
                    MarketplaceToaster.showToast(err.response)
                },
            }
        )
    }
    return (
        <div>
            {showApproveModal && (
                <StoreModal
                    isVisible={showApproveModal}
                    title={t('approvals:approval_request_submission')}
                    okCallback={() => constructApprovalPutObject()}
                    okButtonText={t('common:submit')}
                    cancelButtonText={t('common:cancel')}
                    cancelCallback={() => setShowApproveModal(false)}
                    isSpin={updatedApprovalStatus === 'pending' ? true : false}>
                    <Content className='mb-4'>
                        <Typography className='mb-2 input-label-color'>{t('approvals:approval_reason')}</Typography>
                        <TextArea
                            showCount
                            maxLength={225}
                            autoSize={true}
                            value={approvalReason}
                            placeholder={t('approvals:enter_approval_req_message')}
                            onChange={(e) => {
                                setApprovalReason(e.target.value)
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    const trimmed = approvalReason.trim()
                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                    setApprovalReason(trimmedUpdate)
                                }, 300)
                            }}
                            className='!pr-[3px]'
                        />
                    </Content>
                </StoreModal>
            )}
        </div>
    )
}

export default ApproveModal
