import { Button, Input, Layout, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'

import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'

import { getGenerateDateAndTime } from '../../util/util'

import { NoDataImage } from '../../constants/media'

//! ant design initialization
const { Content } = Layout
const { Text } = Typography
const { Search } = Input

//! Global Variables
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const orderListAPI = process.env.REACT_APP_ORDERS_LIST_API

const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

function ListSalesReturns() {
    const { t } = useTranslation()
    usePageTitle(`${t('common:store_management_portal')} - ${t("common:orders")}`)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)

    const [currentCount, setCurrentCount] = useState()
    const [listOrdersAPIData, setListOrdersAPIData] = useState([])

    const [searchTableValue, setSearchTableValue] = useState('')
    const [doSearchAPICall, setDoSearchAPICall] = useState(false)

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    //! In Progress Tab Data
    const tableColumnForSalesOrders = [
        {
            title: `${t('sales_orders:date')}`,
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            render: (text, record) => {
                return (
                    <p className='text-brandTableText !font-normal !text-sm'>
                        {getGenerateDateAndTime(record.date_of_order, 'ddd MMM DD YYYY')}
                    </p>
                )
            },
        },
        {
            title: `${t('sales_orders:order_id')}`,
            dataIndex: 'id',
            key: 'id',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <Button
                            type='text'
                            className='app-btn-text !font-normal !text-sm'
                            role={'button'}
                            onClick={() =>
                                navigate(
                                    `/dashboard/orders/order-details?oid=${record.id}&${MarketplaceAppConfig.getStore(
                                        ''
                                    )}`
                                )
                            }>
                            {record.id}{' '}
                        </Button>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:customer_name')}`,
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: '23%',
            render: (text, record) => {
                return (
                    <Content>
                        {' '}
                        <Text
                            ellipsis={{ tooltip: record.customer_name }}
                            className='text-brandTableText !font-normal !text-sm'>
                            {record.customer_name}
                        </Text>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='text-brandTableText !font-normal !text-sm'>
                            {currencySymbolFromRedux + ' ' + record.total_amount}
                        </p>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:payment_method')}`,
            dataIndex: 'payment_type_name',
            key: 'payment_type_name',
            ellipsis: true,
            width: '27%',
            render: (text, record) => {
                return (
                    <Content>
                        {' '}
                        <Tooltip overlayStyle={{ zIndex: 1 }} title={record.payment_type_name}>
                            <Text
                                ellipsis={{ tooltip: record.payment_type_name }}
                                className='text-brandTableText !font-normal !text-sm'>
                                {record.payment_type_name === '' ? t('common:not_available') : record.payment_type_name}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },
    ]

    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
        // setCurrentCount(pageSize)
    }
    //!Get Call for List Orders
    const findByPageListOrders = (page, pageLimit, searchKey) => {
        let params = {}
        if (searchKey !== '') {
            console.log('searchKey123---->', searchKey)
            params['search'] = searchKey
        } else {
            params = null
        }

        MarketplaceServices.findByPage(orderListAPI, params, page, pageLimit, true)
            .then(function (response) {
                console.log(
                    'Server Response from get call of listOrdersGetCall1',
                    orderListAPI,
                    response.data.response_body
                )
                setCurrentCount(response.data.response_body.count)
                setListOrdersAPIData(response.data.response_body.Orders)

                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Error server Response from findByPageListOrders', orderListAPI, error.response)
                setIsLoading(false)
                setIsNetworkError(true)
                setListOrdersAPIData([])
            })
    }

    //!  Get Calls are used in this UseEffect
    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        let pageNumber = parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
        let pageSize = parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv
        findByPageListOrders(pageNumber, pageSize, searchTableValue)
    }, [searchParams, doSearchAPICall])

    useEffect(() => {
        window.scrollTo(0, 0)
        setSearchParams({
            page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
            limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    const handleSearchChange = (searchValue) => {
        if (searchValue?.trim()) {
            setSearchTableValue(searchValue)
            setDoSearchAPICall(!doSearchAPICall)
        }
    }

    const handleInputChange = (event) => {
        let searchText = event.target.value
        if (searchText === '' && searchTableValue !== ' ' && searchTableValue?.trim()) {
            setDoSearchAPICall(!doSearchAPICall)
        }
        let trimmedSearchText = searchText.replace(/\s+/g, ' ')
        setSearchTableValue(trimmedSearchText)
    }

    const customButton = <Button type='primary' disabled={!searchTableValue.trim()} icon={<SearchOutlined />} />

    return (
        <Content>
            <Content className='h-[135px] bg-white fixed !z-10  !px-6 !pb-6 !pt-10 headerWidth'>
                <p className='!font-semibold !text-2xl !leading-7 !text-regal-blue'> {t('common:orders')}</p>
                <p className='text-[#637381] !text-sm !font-normal !mt-2'> {t('common:sales_order_short_desc')}</p>
            </Content>
            <Content className=' !pt-[160px] !px-6 !pb-6'>
                <div className=' w-full  shadow-brandShadow rounded-lg bg-white p-6'>
                    {searchTableValue !== '' || listOrdersAPIData?.length > 0 ? (
                        <Search
                            placeholder={t('common:please_enter_search_text_here')}
                            onSearch={handleSearchChange}
                            onChange={handleInputChange}
                            value={searchTableValue}
                            suffix={null}
                            maxLength={searchMaxLength}
                            enterButton={customButton}
                            allowClear
                            className='!w-[30%] !float-right !mb-6'
                        />
                    ) : null}

                    <div>
                        {isLoading ? (
                            <Content className='text-center bg-white p-3'>
                                <SkeletonComponent />
                            </Content>
                        ) : isNetworkError ? (
                            <Content className='text-center bg-white p-3'>
                                <h5>{t('common:network_error')}</h5>
                            </Content>
                        ) : searchTableValue !== '' && listOrdersAPIData.length === 0 ? (
                            <Content className='text-center font-semibold ml-2 mt-2 bg-white px-3 !pt-[5rem] !pb-3'>
                                <Text>{t('common:not_able_to_find_searched_details')}</Text>
                            </Content>
                        ) : (
                            <Content>
                                {listOrdersAPIData?.length > 0 ? (
                                    <>
                                        <Table
                                            pagination={false}
                                            columns={tableColumnForSalesOrders}
                                            dataSource={listOrdersAPIData}
                                        />
                                        <Content className='text-right'>
                                            {currentCount > itemsPerPageFromEnv ? (
                                                <Content className='!grid !justify-items-end'>
                                                    <DmPagination
                                                        currentPage={
                                                            parseInt(searchParams.get('page'))
                                                                ? parseInt(searchParams.get('page'))
                                                                : 1
                                                        }
                                                        totalItemsCount={currentCount}
                                                        defaultPageSize={itemsPerPageFromEnv}
                                                        pageSize={
                                                            parseInt(searchParams.get('limit'))
                                                                ? parseInt(searchParams.get('limit'))
                                                                : itemsPerPageFromEnv
                                                        }
                                                        handlePageNumberChange={handlePageNumberChange}
                                                        showSizeChanger={true}
                                                        showTotal={true}
                                                        showQuickJumper={true}
                                                    />
                                                </Content>
                                            ) : null}
                                        </Content>
                                    </>
                                ) : (
                                    <Content className='text-center p-6'>
                                        <img src={NoDataImage} />
                                        <p className='pt-2 text-[#00000040] !font-bold !text-base'>
                                            {t('common:looks_like_there_nothing_here_yet')}
                                        </p>
                                    </Content>
                                )}
                            </Content>
                        )}
                    </div>
                </div>
            </Content>
        </Content>
    )
}

export default ListSalesReturns
