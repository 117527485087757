import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from 'react-oidc-context'

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'flag-icon-css/css/flag-icons.min.css'
import 'react-toggle/style.css'
import './index.css'
import './core-ui/index.css'
import { Provider } from 'react-redux'

//! Import user defined services
import Store, { Persistor } from './services/redux/store'
import { PersistGate } from 'redux-persist/integration/react'

import './services/i18next/1i18n'
import LoadingMarkup from './components/loader/LoadingMarkup'
import util from './util/common'
import localConfigData from './constants/LocalConfig'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
const queryClient = new QueryClient()

//! Get all required details from .env file
const configS3URL = process.env.REACT_APP_CONFIG_S3_URL
let clientId = process.env.REACT_APP_CLIENT_ID
let realm = process.env.REACT_APP_REALM

const keycloakData = {
    realmName: realm,
    clientId: clientId,
}
sessionStorage.setItem('keycloakData', JSON.stringify(keycloakData))

let appConfig = {}
try {
    const searchParams = new URLSearchParams(window.location.search)
    const response = await fetch(configS3URL)

    appConfig = await response.json()
    appConfig = util.getAppConfig(appConfig, searchParams)

    //if we want to switch to local config uncomment below line and comment above code

    // appConfig = util.getAppConfig(localConfigData, searchParams)

} catch (error) {
    //Redirect to Error Page
    console.log('APPLICATION CONFIGURATION NOT FOUND2')
}

console.log('----------appConfig---final', appConfig)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <Suspense fallback={<LoadingMarkup />}>
        <AuthProvider {...appConfig}>
            <Provider store={Store}>
                <PersistGate loading={null} persistor={Persistor}>
                    <StrictMode>
                        <QueryClientProvider client={queryClient}>
                            <App />
                        </QueryClientProvider>
                    </StrictMode>
                </PersistGate>
            </Provider>
        </AuthProvider>
    </Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
