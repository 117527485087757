import React from 'react'

const CheckBoxIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M19.25 2.75C18.9688 2.46875 18.5313 2.46875 18.25 2.75L17.125 3.875V3.65625C17.125 2.59375 16.25 1.6875 15.1563 1.6875H2.5C1.4375 1.6875 0.53125 2.5625 0.53125 3.65625V16.3438C0.53125 17.4063 1.40625 18.3125 2.5 18.3125H15.1875C16.25 18.3125 17.1563 17.4375 17.1563 16.3438V5.875L19.2813 3.75C19.5313 3.46875 19.5313 3.03125 19.25 2.75ZM15.7188 16.3438C15.7188 16.6563 15.4688 16.9063 15.1563 16.9063H2.5C2.1875 16.9063 1.9375 16.6563 1.9375 16.3438V3.65625C1.96875 3.375 2.1875 3.125 2.5 3.125H15.1875C15.5 3.125 15.75 3.375 15.75 3.6875V5.3125L12.5312 8.5L10.5938 6.5625C10.3125 6.28125 9.875 6.28125 9.59375 6.5625C9.3125 6.84375 9.3125 7.28125 9.59375 7.5625L12.0313 10C12.0313 10 12.0625 10.0312 12.0938 10.0312C12.125 10.0625 12.125 10.0625 12.1563 10.0938C12.1875 10.0938 12.1875 10.125 12.2188 10.125C12.25 10.125 12.25 10.1563 12.2813 10.1563C12.3125 10.1563 12.3438 10.1875 12.375 10.1875C12.4062 10.1875 12.4063 10.1875 12.4375 10.1875C12.4688 10.1875 12.5313 10.1875 12.5625 10.1875C12.5938 10.1875 12.6562 10.1875 12.6875 10.1875C12.7188 10.1875 12.75 10.1563 12.8125 10.1563C12.8125 10.1563 12.8125 10.1563 12.8438 10.1563C12.875 10.1563 12.9063 10.125 12.9375 10.0938C12.9375 10.0938 12.9688 10.0938 12.9688 10.0625C13 10.0313 13.0313 10.0313 13.0625 10L15.75 7.3125V16.3438H15.7188Z'
                fill='#8899A8'
            />
        </svg>
    )
}

export default CheckBoxIcon
