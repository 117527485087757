import React from 'react'

const PencilIcon = () => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_3204_5406)'>
                <path
                    d='M13.3882 2.84453C12.6882 2.10078 11.9445 1.35703 11.2007 0.635156C11.0476 0.482031 10.8726 0.394531 10.6757 0.394531C10.4789 0.394531 10.282 0.460156 10.1507 0.613281L1.90386 8.79453C1.77261 8.92578 1.68511 9.07891 1.61949 9.23203L0.416363 12.907C0.350738 13.082 0.394488 13.257 0.481988 13.3883C0.591363 13.5195 0.744488 13.607 0.941363 13.607H1.02886L4.76949 12.3602C4.94449 12.2945 5.09761 12.207 5.20699 12.0758L13.4101 3.89453C13.5414 3.76328 13.6289 3.56641 13.6289 3.36953C13.6289 3.17266 13.5414 2.99766 13.3882 2.84453ZM4.50699 11.3977C4.48511 11.4195 4.46324 11.4195 4.44136 11.4414L1.61949 12.382L2.56011 9.56016C2.56011 9.53828 2.58199 9.51641 2.60386 9.49453L8.61949 3.50078L10.5226 5.40391L4.50699 11.3977ZM11.2007 4.70391L9.29761 2.80078L10.632 1.46641C11.2664 2.07891 11.9007 2.73516 12.5132 3.36953L11.2007 4.70391Z'
                    fill='#023047'
                />
            </g>
            <defs>
                <clipPath id='clip0_3204_5406'>
                    <rect width='14' height='14' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default PencilIcon
