import { Alert, Layout, Switch, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdInfo } from 'react-icons/md'
import { useAuth } from 'react-oidc-context'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CardText, Container } from 'reactstrap'
import { toast } from 'react-toastify'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import ProductTypeColumn2 from './ProductTypeColumn2'
import StoreModal from '../../components/storeModal/StoreModal'

import HeaderForTitle from '../../components/header/HeaderForTitle'
import { fnRevisionInfo, fnRevisionSelectedInfo } from '../../services/redux/actions/ActionsRevisionInfo'
import util from '../../util/common'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import './ProdType.css'

const { Paragraph, Title, Text } = Typography
const productTypeAPI = process.env.REACT_APP_PRODUCT_TYPE_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const { Content } = Layout
const ProductTypes = () => {
    const { t } = useTranslation()
    const auth = useAuth()
    usePageTitle(`${t('common:store_management_portal')} - ${t('common:product_settings')}`)
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [storeProductTypes, setStoreProductTypes] = useState([])
    const [permissions, setPermissions] = useState(false)
    const [langDirection, setLangDirection] = useState('ltr')
    const [productStatusModalOpen, setProductStatusModalOpen] = useState(false)
    const [productSelected, setProductSelected] = useState('')
    const [selectedProductStatus, setSelectedProductStatus] = useState('')
    const [selectedProductName, setSelectedProductName] = useState('')
    const dispatch = useDispatch()

    //to get permissions from sessionStorage
    const permissionValue = util.getPermissionData()

    useEffect(() => {
        window.scrollTo(0, 0)
        util.setStoreNameWhenWeChangeINUrl()
        if (
            !auth.isAuthenticated ||
            (auth.isAuthenticated &&
                permissionValue &&
                permissionValue.length > 0 &&
                permissionValue.includes('UI-list-producttype'))
        ) {
            setPermissions(true)
            MarketplaceServices.findAllWithoutPage(productTypeAPI, null, true)
                .then(function (response) {
                    //TODO: Remove below code once we get status as boolean
                    console.log(
                        'Server response from  MarketplaceServices.findAllWithoutPage(productTypeAPI, null, true)',
                        productTypeAPI,
                        response.data.response_body.StoreProductTypeRevision
                    )
                    let serverDataByDestructingStoreProductType =
                        response.data.response_body.StoreProductTypeRevision.map(
                            ({ store_product_type }) => store_product_type
                        )
                    let productTypesData = serverDataByDestructingStoreProductType

                    if (productTypesData && productTypesData.length > 0) {
                        let filteredTypes = productTypesData.filter(
                            (ele) =>
                                ele.product_type === 1 ||
                                ele.product_type === 5 ||
                                ele.product_type === 2 ||
                                ele.product_type === 3 ||
                                ele.product_type === 4 ||
                                ele.product_type === 7
                        )
                        filteredTypes.forEach((type) => {
                            if (type.status === 'supported') type.status = true
                            else type.status = false
                        })
                        setStoreProductTypes(filteredTypes)
                    } else {
                        setStoreProductTypes([])
                    }
                    setIsLoading(false)
                    setIsNetworkError(false)
                })
                .catch(function (error) {
                    console.log(
                        'Error response from  MarketplaceServices.findAllWithoutPage(productTypeAPI, null, true)',
                        productTypeAPI,
                        error
                    )
                    setIsNetworkError(true)
                    setIsLoading(false)
                })
        } else {
            setIsLoading(false)
            setPermissions(false)
        }
        dispatch(fnRevisionInfo())
        dispatch(fnRevisionSelectedInfo())
    }, [])

    const changeStatus = (status, typeId) => {
        let prodTypes = [...storeProductTypes]
        prodTypes.forEach((type) => {
            if (type.product_type === typeId) {
                type.status = status
            }
        })
        setStoreProductTypes(prodTypes)
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const tableData = [
        {
            title: `${t('product_types:product_type_heading')}`,
            dataIndex: 'product_type',
            key: 'product_type',
            width: '25%',
            render: (text, record) => {
                return (
                    <Content>
                        {' '}
                        <Text className='!text-[#637381] !m-0 !text-[14px] !leading-[22px] font-normal'>
                            {record.display_name}
                        </Text>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:description')}`,
            dataIndex: 'description',
            key: 'description',
            width: '55%',
            render: (text, record) => {
                return (
                    <Content>
                        <Paragraph className='!text-[#637381] !m-0 !text-[14px] !leading-[22px] font-normal'>
                            {record.display_description}
                        </Paragraph>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:status')}`,
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (text, record) => {
                return (
                    <div>
                        <Switch
                            onChange={() => {
                                if (productStatusModalOpen === false) {
                                    setProductStatusModalOpen(true)
                                }
                                setProductSelected(record.product_type)
                                setSelectedProductStatus(record.status)
                                setSelectedProductName(record.display_name)
                            }}
                            className={record.status ? '!bg-orange-500' : '!bg-gray-400'}
                            checked={record.status}
                        />
                    </div>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content>
                        <Link
                            to={{
                                pathname: `/dashboard/settings/store-product-types`,
                                search: `?${MarketplaceAppConfig.getStore('')}&product_type_id=${record.product_type}&product_type_name=${record.display_name}&store_product_type_id=${record.id} `,
                            }}>
                            <Text className='app-btn-link whitespace-nowrap font-semibold'>
                                {t('common:view_details')}
                            </Text>
                        </Link>
                    </Content>
                )
            },
        },
    ]

    const statusChangePutCall = () => {
        const updatedStatus = selectedProductStatus ? 'unsupported' : 'supported'
        let putBody = {
            product_types: [
                {
                    product_type_id: productSelected,
                    status: updatedStatus,
                },
            ],
        }

        const successMsg =
            `${t('product_types:the')} ` +
            selectedProductName +
            '' +
            ' ' +
            `${t('product_types:type_successfully')}` +
            ' ' +
            (selectedProductStatus ? `${t('product_types:deactivated')}` : `${t('product_types:activated')}`)

        MarketplaceServices.update(productTypeAPI, putBody)
            .then(function (response) {
                setProductStatusModalOpen(false)
                changeStatus(!selectedProductStatus, productSelected)
                toast(successMsg, {
                    type: 'success',
                    autoClose: 10000,
                })
            })
            .catch((error) => {
                toast(`${t('product_types:deactivation_failed')}`, {
                    type: 'error',
                    autoClose: 10000,
                })
                setProductStatusModalOpen(false)
            })
    }

    return (
        <Content className='!pt-1'>
            <>
                <StoreModal
                    title={`${
                        selectedProductStatus
                            ? t('product_types:product_deactivation')
                            : t('product_types:product_activation')
                    }`}
                    isSpin={false}
                    okCallback={() => {
                        statusChangePutCall()
                    }}
                    isVisible={productStatusModalOpen}
                    cancelButtonText={t('common:cancel')}
                    okButtonText={t('common:ok')}
                    noCloseIcon={true}
                    width={'500px'}
                    cancelCallback={() => {
                        setProductStatusModalOpen(false)
                        setProductSelected('')
                    }}>
                    <Content>
                        <div>
                            <p>
                                {t('product_types:you_are_about_to') +
                                    ' ' +
                                    (selectedProductStatus
                                        ? t('common:deactivate').toLowerCase()
                                        : t('common:activate').toLowerCase()) +
                                    ' "' +
                                    selectedProductName +
                                    '" ' +
                                    t('product_types:type_from_your_store')}
                            </p>
                            <p>
                                {t('product_types:proceed_text') +
                                    ' ' +
                                    (selectedProductStatus
                                        ? t('product_types:deactivating')
                                        : t('product_types:activating')) +
                                    ' ' +
                                    t('product_types:the').toLowerCase() +
                                    ' "' +
                                    selectedProductName +
                                    '" ' +
                                    t('common:type').toLowerCase() +
                                    '?'}
                            </p>
                        </div>
                    </Content>
                </StoreModal>
            </>
            {permissions ? (
                <>
                    <Content>
                        <HeaderForTitle
                            title={
                                <Content className=''>
                                    <Title level={3} className='!font-medium'>
                                        {t('common:product_settings')}
                                    </Title>
                                    <Paragraph className='!font-normal !text-[#637381] !m-0 !py-2'>
                                        {t('product_types:product_types_description')}
                                    </Paragraph>
                                </Content>
                            }
                        />
                    </Content>
                    <Content className='p-3 bg-white m-3 rounded-lg !mt-[10.5rem] !min-h-screen'>
                        <Content className='py-3'>
                            <Alert
                                message={<h5 className='font-bold'>{t('product_types:note_heading')}</h5>}
                                description={
                                    <ul className={`list-disc  ${langDirection === 'rtl' ? '!pr-6' : '!pl-6'}`}>
                                        <li>{t('product_types:product_types_note1')}</li>
                                        <li>{t('product_types:product_types_note2')}</li>
                                    </ul>
                                }
                                type='info'
                                showIcon
                            />
                        </Content>
                        <CardText tag='h3' className=' d-flex m-0 align-items-center font-medium mt-2 text-[19px]'>
                            {t('product_types:list_of_product_types_supported')}
                        </CardText>
                        {isLoading ? (
                            <Container className='bg-white p-3 text-center '>
                                <SkeletonComponent />
                            </Container>
                        ) : isNetworkError ? (
                            <Content className='text-center bg-white p-3'>
                                <h5 className='!flex !justify-center'>{t('common:network_error')}</h5>
                            </Content>
                        ) : (
                            <Container className='p-0 !mt-5'>
                                <Content className='mb-3'>
                                    <Table pagination={false} columns={tableData} dataSource={storeProductTypes} />
                                </Content>
                                {/* <ProductTypeColumn2
                                    options={storeProductTypes}
                                    changeStatus={changeStatus}
                                    totalTypesEnabled={storeProductTypes.filter((type) => type.status)}
                                /> */}
                            </Container>
                        )}
                    </Content>
                </>
            ) : (
                <Content className='text-center mt-2 ml-2 bg-white p-3'>
                    <Text>{t('common:access_permission_required')}</Text>
                </Content>
            )}
        </Content>
    )
}

export default ProductTypes
