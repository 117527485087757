import { ActionTypes } from '../constants/ActionTypes'

const { RDX_REVISION_INFO, RDX_REVISION_SELECTED_INFO } = ActionTypes

// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods
export const ReducerRevisionInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_REVISION_INFO:
            return { ...state, revisionInfo: payload }
        case RDX_REVISION_SELECTED_INFO:
            return { ...state, revisionSelectedInfo: payload }

        default:
            return state
    }
}
