import { Layout, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import DynamicTable from '../../pages/tablelayoutcomponent/DynamicTable'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
const { Content } = Layout
const { Title } = Typography

const storeAllUsers = process.env.REACT_APP_USERS_ALL_API

const StoreUser = () => {
    const { t } = useTranslation()
    const [storeAllUsersData, setStoreAllUsersData] = useState([])
    const [tableContentData, setTableContentData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [errorMessage, setErrorMessage] = useState()

    //! table columns
    const StoreAllUserTable = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (text, record) => {
                return <>{record.username}</>
            },
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (text, record) => {
                return (
                    <>
                        {record.firstname === '' || record.firstname === undefined
                            ? t('common:not_available')
                            : record.firstname}
                    </>
                )
            },
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            key: 'lastname',
            render: (text, record) => {
                return (
                    <>
                        {record.lastname === '' || record.lastname === undefined
                            ? t('common:not_available')
                            : record.lastname}
                    </>
                )
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => {
                return <>{record.email}</>
            },
        },
        {
            title: 'Email Verified',
            dataIndex: 'emailverified',
            key: 'emailverified',
            render: (text, record) => {
                return <>{record.emailVerified === true ? 'Yes' : 'No'}</>
            },
        },
        {
            title: 'Enabled',
            dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record) => {
                return <>{record.enabled === true ? 'Yes' : 'No'}</>
            },
        },
    ]

    const storeAllData = (serverData) => {
        let tempArray = []
        serverData &&
            serverData.length > 0 &&
            serverData.forEach((element, index) => {
                var Id = element.id
                var Username = element.username
                var FirstName = element.firstName
                var LastName = element.lastName
                var Email = element.email
                var EmailVerified = element.emailVerified
                var Enabled = element.enabled
                tempArray.push({
                    id: Id,
                    key: index,
                    username: Username,
                    firstName: FirstName,
                    lastName: LastName,
                    email: Email,
                    emailVerified: EmailVerified,
                    enabled: Enabled,
                })
            })
        setTableContentData(tempArray)
    }

    const tablePropsData = {
        table_header: StoreAllUserTable,
        table_content: tableContentData,

        search_settings: {
            is_enabled: false,
            search_title: 'Search by username',
            search_data: ['username'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: '',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }

    const findAllWithoutPageAllStoreUsers = () => {
        MarketplaceServices.findAllWithoutPage(storeAllUsers, null, false)
            .then(function (response) {
                console.log('Server response from findAllWithoutPageAllStoreUsers is', storeAllUsers, response.data)
                setStoreAllUsersData(response.data)
                storeAllData(response.data)
                setIsNetworkError(false)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(
                    'errorServer response from findAllWithoutPageAllStoreUsers is',
                    storeAllUsers,
                    error.response
                )
                setIsNetworkError(true)
                setIsLoading(false)
                if (error.response) {
                    setErrorMessage(error.response.data.message)
                }
            })
    }

    useEffect(() => {
        findAllWithoutPageAllStoreUsers()
    }, [])

    return (
        <Content className='mt-3'>
            <HeaderForTitle
                title={
                    <Content className=''>
                        <Title level={3} className='!font-normal'>
                            {t('common:store_user')}
                        </Title>
                    </Content>
                }
            />
            <Content className='mt-[5rem] !min-h-screen !p-6'>
                {isLoading ? (
                    <Content className=' bg-white !mt-[1rem] !px-3 !pb-3 pt-1 mb-3 !mx-2'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Layout className='p-0 text-center mb-3 bg-[#F4F4F4]'>
                        <h5>{errorMessage ? errorMessage : t('common:network_error')}</h5>
                    </Layout>
                ) : (
                    <Content>
                        <DynamicTable tableComponentData={tablePropsData} />
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default StoreUser
