//!React Library Imports
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
//!Ant Design Library Imports
import { Input, Layout, Spin, Table, Tooltip, Typography, Image } from 'antd'
import { ProductDefaultImage } from '../../constants/media'
import { useSelector } from 'react-redux'

import util from '../../util/common'

//!User Defined Components.
import MarketplaceServices from '../../services/axios/MarketplaceServices'
const listProductsAPI = process.env.REACT_APP_LIST_PRODUCTS_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const baseURL = process.env.REACT_APP_BASE_URL
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOLl

//!Destructing of the Imports.
const { Title, Text, Paragraph } = Typography
const { Content } = Layout
const { Search } = Input

const AddProductsToDiscount = ({
    selectedProducts,
    setSelectedProducts,
    addProductModalVisible,
    setSaveButtonDisabledForModal,
    mainObjectDataForAllActions,
    triggerUseEffect,
}) => {
    const { t } = useTranslation()
    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [allPublishedProducts, setAllPublishedProducts] = useState([])
    const [tableData, setTableData] = useState([])
    const [langDirection, setLangDirection] = useState('ltr')
    const [doSearchAPICall, setDoSearchAPICall] = useState(false)

    //! currency symbol
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    const columns = [
        {
            title: `${t('common:product_name')}`,
            dataIndex: 'product_name',
            key: 'product_name',
            width: '33%',
            render: (text, record) => {
                return (
                    <div className='flex'>
                        <Image
                            src={record.product_image ? baseURL + record.product_image : ProductDefaultImage}
                            width={40}
                            height={40}></Image>
                        <div className='!w-[80%] ml-2 font-normal'>
                            <Tooltip
                                title={
                                    record.product_name && record.product_name.length > 15 ? record.product_name : null
                                }>
                                <span
                                    style={{
                                        maxWidth: '150px',
                                        display: 'inline-block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}>
                                    {record.product_name}
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                )
            },
        },
        {
            title: `${t('relative_products:stock_quantity')}`,
            dataIndex: 'quantity',
            key: 'quantity',
            width: '19%',
            render: (text, record) => {
                return (
                    <p className='font-normal !mb-0'>
                        {record.quantity
                            ? record.quantity >= 1
                                ? record.quantity
                                : 'Out of Stock'
                            : t('common:not_applicable')}
                    </p>
                )
            },
        },
        {
            title: `${t('relative_products:product_price')} (${currencySymbolFromRedux})`,
            dataIndex: 'product_price',
            key: 'product_price',
            width: '22%',

            render: (text, record) => {
                return <p className='font-normal !mb-0'> {record && record.product_price} </p>
            },
        },
        {
            title: `${t('products:selling_price')} (${currencySymbolFromRedux})`,
            dataIndex: 'selling_price',
            key: 'selling_price',
            width: '20%',

            render: (text, record) => {
                return <p className='font-normal !mb-0'> {record && record.selling_price} </p>
            },
        },
    ]

    const processAllPublishedProductsTable = (data) => {
        let localData = []
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                let temp = {}
                temp['key'] = data[i].product_id
                temp['product_id'] = data[i].product_id
                temp['product_name'] = data[i].product_name
                temp['product_price'] = data[i].product_price
                temp['quantity'] = data[i].quantity
                temp['selling_price'] = data[i].selling_price
                temp['discount_details'] = data[i].discount_details
                temp['product_image'] = data[i].product_image
                temp['rating'] = data[i].rating
                temp['additional_info'] = data[i].additional_info
                temp['category_id'] = data[i].category_id
                temp['product_image_path'] = data[i].product_image_path
                temp['product_layout_id'] = data[i].product_layout_id
                temp['product_layout_name'] = data[i].product_layout_name
                localData.push(temp)
            }
            return localData
        } else {
            return []
        }
    }

    const findAllPublishedProducts = () => {
        let params = {}
        params['is_bundled'] = false
        params['free-product'] = false
        if (searchValue) {
            params['search'] = searchValue.trim()
        }
        setIsLoadingProducts(true)

        MarketplaceServices.findAll(listProductsAPI, params, true)
            .then(function (response) {
                console.log(
                    'server response from findAllPublishedProducts API call is:',
                    listProductsAPI,
                    response.data
                )
                if (response?.data?.response_body?.product_data) {
                    setAllPublishedProducts(processAllPublishedProductsTable(response.data.response_body.product_data))
                    let removeAlreadyMappedProducts = processAllPublishedProductsTable(
                        response.data.response_body.product_data
                    ).filter((item) => !selectedProducts?.includes(item.key))
                    if (removeAlreadyMappedProducts) {
                        setTableData(removeAlreadyMappedProducts)
                    } else {
                        setTableData(processAllPublishedProductsTable(response.data.response_body.product_data))
                    }
                }
                setIsLoadingProducts(false)
            })
            .catch(function (error) {
                setIsLoadingProducts(false)
                console.log(
                    'Error server response from findAllPublishedProducts API call is: ',
                    listProductsAPI,
                    error.response
                )
            })
    }
    const onRowSelectChange = (newSelectedRowKeys) => {
        console.log('newSelectedRowKeystestt', newSelectedRowKeys)
        setSaveButtonDisabledForModal(false)
        setSelectedProducts(newSelectedRowKeys)

        // if (newSelectedRowKeys && newSelectedRowKeys.length === 0) {
        //   setIsButtonDisabled(true);
        // } else {
        //   setIsButtonDisabled(false);
        // }
    }
    const onSearch = (searchValue) => {
        setDoSearchAPICall(!doSearchAPICall)
    }
    const handleOnChangeSearch = (event) => {
        let searchText = event.target.value
        if (searchText == '') {
            setSearchValue('')
            setDoSearchAPICall(!doSearchAPICall)
        } else {
            let trimmedSearchText = searchText.replace(/\s+/g, ' ')
            setSearchValue(trimmedSearchText)
        }
    }

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        console.log('doSearchAPICall---->', doSearchAPICall)
        findAllPublishedProducts()
    }, [doSearchAPICall])

    return (
        <Content>
            {' '}
            <Content className='bg-white !p-3 !text-right'>
                <Search
                    placeholder={t('coupons:input_search_text')}
                    onSearch={(e) => onSearch(e)}
                    style={{
                        width: 232,
                    }}
                    allowClear
                    onChange={(e) => handleOnChangeSearch(e)}
                    value={searchValue}
                />
                <Content className='mt-4'>
                    <Spin spinning={isLoadingProducts} size='large'>
                        <Table
                            rowSelection={{
                                selectedRowKeys: selectedProducts,
                                preserveSelectedRowKeys: true,
                                onChange: onRowSelectChange,
                            }}
                            loading={isLoadingProducts}
                            dataSource={tableData}
                            columns={columns}
                            scroll={{}}
                            pagination={{
                                pageSize: itemsPerPageFromEnv,
                                showSizeChanger: false,
                                pageSizeOptions: false,
                                hideOnSinglePage: true,
                            }}
                        />
                    </Spin>
                </Content>
            </Content>
        </Content>
    )
}

export default AddProductsToDiscount
