import React, { useState, useEffect } from 'react'
import { Layout, Typography, Spin, Row, Col } from 'antd'
import PLPImages from './PLPImages'
import PLPOverView from './PLPOverView'
import PLPDetails from './PLPDetails'
import PLPTitleAndCategory from './PLPTitleAndCategory'
import PLPCards from './PLPCards'
import PLPFilter from './PLPFilter'
import { WorkInProgress, ThbsLogo, CategoriesImage, Samsung } from '../../../../constants/media'

const { Content } = Layout

const PLPLayout1 = ({ productId, backgroundColor }) => {
    console.log('productId--->', productId)
    let temp = [
        {
            id: 1,
            imagePath: WorkInProgress,
        },
        {
            id: 2,
            imagePath: ThbsLogo,
        },
        {
            id: 3,
            imagePath: Samsung,
        },
        {
            id: 4,
            imagePath: WorkInProgress,
        },
        {
            id: 5,
            imagePath: ThbsLogo,
        },
        {
            id: 6,
            imagePath: Samsung,
        },
    ]
    return (
        <Content className={`${'mt-3 p-3 w-[100%]  '} ${backgroundColor}`}>
            <Content className='flex justify-between'>
                <Content className=''>
                    <Content>
                        <PLPFilter />
                    </Content>
                </Content>
                <Content className='!ml-10'>
                    <Content>
                        <Content className='flex'>
                            <Content className='w-[100%] '>
                                <PLPTitleAndCategory />
                            </Content>
                            <Content className='w-[100%] p-3'>{/* <PLPOverView /> */}</Content>
                        </Content>
                        <Content className=''>
                            {/* <PLPImages imageData={[]} /> */}
                            <PLPCards />
                        </Content>
                        <Content className='w-[100%] p-3'>{/* <PLPDetails /> */}</Content>
                    </Content>
                </Content>
            </Content>
        </Content>
    )
}

export default PLPLayout1
