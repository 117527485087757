import { Button, Layout, Select, Typography } from 'antd'
import React, { useState } from 'react'
import PDPLayouts from '../../components/Layouts/Constants/PDPLayouts'
import DynamicComponent from '../../components/Layouts/DynamicComponent/DynamicComponent'

//! Import CSS libraries

const { Content } = Layout
const { Title } = Typography

const PDPLayoutParent = () => {
    const [selectedLayoutName, setSelectedLayoutName] = useState('Choose the layout')
    const [selectedLayoutTitle, setSelectedLayoutTitle] = useState()

    const handleChangePDPLayouts = (value, obj) => {
        setSelectedLayoutName(value)
        setSelectedLayoutTitle(obj.title)
    }

    return (
        <Content className='!mb-5 mt-2'>
            <Title level={5} className='!font-normal !m-0'>
                Personalize the store's product list page by selecting a layout from the drop-down menu
            </Title>
            <Title level={5} className='!font-normal !m-0'>
                The selected layout can be previewed before saving. Once saved, store's product list page will resemble
                the selected layout.
            </Title>
            <Content className='flex justify-between'>
                <Content className='mt-4'>
                    <Select value={selectedLayoutName} onChange={handleChangePDPLayouts} className='w-[40%]'>
                        {PDPLayouts &&
                            PDPLayouts.length > 0 &&
                            PDPLayouts.map((e) => (
                                <Select.Option value={e.componentName} title={e.componentTitle}>
                                    {e.componentTitle}
                                </Select.Option>
                            ))}
                    </Select>
                </Content>
                <Content className='mt-4 text-right'>
                    <Button className='app-btn-primary'>Publish</Button>
                </Content>
            </Content>

            <Content>
                {selectedLayoutName === 'Choose the layout' ? (
                    <Title level={5} className='!font-normal !m-2'>
                        Please select the layout.
                    </Title>
                ) : (
                    <> {DynamicComponent(selectedLayoutName, selectedLayoutTitle, 345, 'bg-slate-100')}</>
                )}
            </Content>
        </Content>
    )
}

export default PDPLayoutParent
