import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import { useLocation } from 'react-router-dom'
const productVariantAttributesAPI = process.env.REACT_APP_PRODUCT_VARIANTS_ATTRIBUTES_API
/**
 * !This function is a GET Method to get the list of variant attributes for a particular product template revision.
 * @param {*} productTemplateRevisionId -> To get the variant attributes based on the product_template_revision_id.
 */
//!REST --> GET Method.
const useGetProductVariantAttributes = ({ productTemplateRevisionId, attributeDetailsUpdated }) => {
    console.log('productTemplateRevisionId')
    const search = useLocation().search
    let productTemplateRevisionIdFromURL = new URLSearchParams(search).get('ptsrid')
    let templateRevisionId = productTemplateRevisionId ? productTemplateRevisionId : productTemplateRevisionIdFromURL
    const getProductVariantAttributes = async () => {
        const params = {
            'product-revision-id': parseInt(templateRevisionId),
        }
        const response = await MarketplaceServices.findAllWithoutPage(productVariantAttributesAPI, params)
        console.log('response===>', response)
        return response?.data?.response_body
    }
    return useQuery({
        queryKey: ['productVariantAttributes', templateRevisionId, attributeDetailsUpdated],
        queryFn: getProductVariantAttributes,
        refetchOnWindowFocus: false,
        enabled: !!templateRevisionId,
        retry: false,
    })
}
export default useGetProductVariantAttributes
