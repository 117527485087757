import { Input, Layout, Typography } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StoreModal from '../../../components/storeModal/StoreModal'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useUpdateApprovalStatus from '../hooks/useUpdateApprovalStatus'

const { Content } = Layout
const { Title } = Typography
const { TextArea } = Input
function MakeItDraftModal({
    showMakeItDraftModal,
    setShowMakeItDraftModal,
    typeChoice,
    revisionId,
    putApprovalId,
    refetchApprovalData,
}) {
    const { t } = useTranslation()
    const [makeItDraftReason, setMakeItDraftReason] = useState(null)
    const { mutate: updateApprovalStatus, status: updatedApprovalStatus } = useUpdateApprovalStatus()

    /**
     * ?To Construct  the object for the rejection put object for Reject Put Call.
     */
    const constructMakeItDraftPutObject = () => {
        // putRejectObject.store = parseInt(storeId);
        let putObject = {}
        putObject.type_choice = typeChoice
        putObject.revision_id = parseInt(revisionId)
        putObject.revision_status = 1
        if (makeItDraftReason) {
            putObject.approval_reason = makeItDraftReason
        }
        updateApprovalStatus(
            { putApprovalId: putApprovalId, putBody: putObject },
            {
                onSuccess: (response) => {
                    refetchApprovalData()
                    MarketplaceToaster.showToast(response)
                    setShowMakeItDraftModal(false)
                },
                onError: (err) => {
                    MarketplaceToaster.showToast(err.response)
                },
            }
        )
    }
    return (
        <div>
            <StoreModal
                isVisible={showMakeItDraftModal}
                title={t('approvals:draft_request_submission')}
                okCallback={() => constructMakeItDraftPutObject()}
                okButtonText={t('common:draft')}
                cancelButtonText={t('common:cancel')}
                cancelCallback={() => setShowMakeItDraftModal(false)}
                isSpin={updatedApprovalStatus === 'pending' ? true : false}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('approvals:make_it_draft_reason')}
                    </Title>
                    <TextArea
                        showCount
                        maxLength={225}
                        autoSize={true}
                        value={makeItDraftReason}
                        placeholder={t('approvals:enter_make_it_draft_req_message')}
                        onChange={(e) => setMakeItDraftReason(e.target.value)}
                        className='!pr-[3px]'
                    />
                </Content>
            </StoreModal>
        </div>
    )
}

export default MakeItDraftModal
