import { useQuery } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_APPROVAL_REQUEST_API
/**
 *
 * @param {*} typeChoice -> To get the approval with API with the typeChoice(the main tab)
 * @param {*} approvalStatus -> To get the approval API with the status(the second tab, approval radio group).
 * @param {*} page -> the page number user selected.
 * @param {*} limit -> the page limit user modified.
 * @param {*} searchValue  -> when the searchValue is present the API will be passed with a parameter search.
 * @param {*} setIsSearchTriggered -> to update that API is triggered with search.
 * @returns -> it returns the API response from the async method.
 */
const useGetApprovals = ({ typeChoice, approvalStatus, page, limit, searchValue }) => {
    const getApprovals = async () => {
        const params = {}
        params['type-choice'] =
            typeChoice === 'product_template'
                ? 'product_revision'
                : typeChoice === 'product'
                  ? 'product_publishing'
                  : ''
        params['approval-status'] = approvalStatus
        if (searchValue.trim()) {
            params['search'] = searchValue.trim()
        }
        const res = await MarketplaceServices.findByPage(BASE_URL, params, page, limit, true)
        console.log(res, 'useGetApprovals')
        return res?.data?.response_body
    }
    const key = ['getApprovals', typeChoice, approvalStatus, page, limit]
    if (searchValue) key.push(searchValue)
    return useQuery({
        queryKey: key,
        queryFn: getApprovals,
        refetchOnWindowFocus: false,
        retry: false,
    })
}
export default useGetApprovals
