//! Import libraries
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Layout, Row, Skeleton, Spin, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//! Import user defined components
import StoreModal from '../../components/storeModal/StoreModal'

//! Import utility functions
import useGetActiveProductsList from '../../hooks/useGetActiveProductsList'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { Empty1SVG, EmptySVG } from '../../constants/media'

//! Get all required details from .env file
const categoryProductMapping = process.env.REACT_APP_CATEGORY_PRODUCT_MAPPING_API
const listProductsAPI = process.env.REACT_APP_LIST_PRODUCTS_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

//! Destructure the components
const { Content } = Layout
const { Text } = Typography
const { Search } = Input

const AddProducts = ({ getSelectedCategoryData }) => {
    const { t } = useTranslation()
    const [addProductTemplateModalVisible, setAddProductTemplateModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [removeProductId, setRemoveProductId] = useState()
    const [productsForPostCall, setProductsForPostCall] = useState([])
    const [alreadyMappedProducts, setAlreadyMappedProducts] = useState([])
    const permissionsData = util.getPermissionData() || []
    const [editPermissions, setEditPermissions] = useState(
        permissionsData && permissionsData.includes('UI-create-edit-category') ? false : true
    )
    const [langDirection, setLangDirection] = useState('ltr')
    const [searchKey, setSearchKey] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const { loading, isNetworkError, apiProcessedData, searchKeyTriggered } = useGetActiveProductsList(searchKey)

    const [modalTableData, setModalTableData] = useState([])
    const [productTableData, setProductsTableData] = useState([])
    const [isAllProductsDataLoading, setIsAllProductsDataLoading] = useState(true)
    const [isAllProductsDataNetworkError, setIsAllProductsDataNetworkError] = useState(false)
    const [allProductsData, setAllProductsData] = useState([])
    const [count, setCount] = useState(0)
    const [productDeleteId, setProductDeleteId] = useState()
    const allProductsTableColumns = [
        {
            title: <Content className=''>{t('common:product_name')}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '70%',
            render: (text, record) => {
                return (
                    <Text
                        className='text-brandGray1 !max-w-[400px]'
                        ellipsis={{ tooltip: { title: record.product_name || t('common:not_applicable') } }}>
                        {record?.product_name || t('common:not_applicable')}
                    </Text>
                    // <Content>
                    //     <Row className=''>
                    //         <Col className=''>
                    //             <Tooltip
                    //                 title={record.name}
                    //                 overlayStyle={{ zIndex: 2000 }}
                    //                 placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}>
                    //                 <p
                    //                     className='font-semibold app-table-data-title'
                    //                     style={{
                    //                         maxWidth: '270px',
                    //                         overflow: 'hidden',
                    //                         textOverflow: 'ellipsis',
                    //                         whiteSpace: 'nowrap',
                    //                     }}>
                    //                     {record && record.product_name ? record.product_name : t('common:not_available')}
                    //                 </p>
                    //             </Tooltip>
                    //         </Col>
                    //     </Row>
                    // </Content>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: '',
            key: '',
            render: (text, record) => {
                return (
                    <Content className=''>
                        {productsForPostCall.length > 0 &&
                        productsForPostCall.filter((item) => parseInt(item) === parseInt(record.product_id)).length >
                            0 ? (
                            <p
                                className='text-brandDanger font-medium cursor-pointer'
                                onClick={() => {
                                    setCount(count - 1)
                                    handleRemoveAddedProducts(record.product_id)
                                }}>
                                {t('common:remove')}
                            </p>
                        ) : (
                            <p
                                className='app-btn-link font-medium'
                                onClick={() => {
                                    setCount(count + 1)
                                    handleAddNewProducts(record.product_id)
                                }}>
                                {t('common:add')}
                            </p>
                        )}
                    </Content>
                )
            },
        },
    ]

    const mappedProductsTableColumns = [
        {
            title: <Content className=''>{t('common:product_name')}</Content>,
            dataIndex: 'name',
            key: 'name',
            width: '70%',
            render: (text, record) => {
                return (
                    <Text
                        className='text-brandGray1 w-[300px]'
                        ellipsis={{ tooltip: { title: record.product_name || t('common:not_available') } }}>
                        {record && record.product_name ? record.product_name : t('common:not_available')}
                    </Text>
                    // <Content>
                    //     <Row className=''>
                    //         <Col className=''>
                    //             <Tooltip
                    //                 title={record.product_name}
                    //                 overlayStyle={{ zIndex: 1 }}
                    //                 placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}>
                    //                 <p
                    //                     className='font-semibold app-table-data-title'
                    //                     style={{
                    //                         maxWidth: '270px',
                    //                         overflow: 'hidden',
                    //                         textOverflow: 'ellipsis',
                    //                         whiteSpace: 'nowrap',
                    //                     }}>
                    //                     {record && record.product_name ? record.product_name : t('common:not_available')}
                    //                 </p>
                    //             </Tooltip>
                    //         </Col>
                    //     </Row>
                    // </Content>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    record && (
                        <div
                            className='cursor-pointer !text-brandRed font-medium'
                            onClick={() => {
                                deleteModal(record && record.product_id)
                            }}>
                            {t('common:remove')}
                        </div>
                    )
                )
            },
        },
    ]

    //! onclick function for add to category in table
    const handleAddNewProducts = (productId) => {
        setProductsForPostCall([...productsForPostCall, productId])
    }
    const handleRemoveAddedProducts = (productId) => {
        setProductsForPostCall(productsForPostCall?.filter((element) => !(parseInt(element) === parseInt(productId))))
    }

    //! update function
    const updateProductMapping = () => {
        const newProductIds = productsForPostCall.filter((productId) => !alreadyMappedProducts.includes(productId))
        setIsLoading(true)
        let dataObject = {
            category: getSelectedCategoryData && getSelectedCategoryData.id,
            product: newProductIds,
        }

        MarketplaceServices.save(categoryProductMapping, dataObject, null)
            .then(function (response) {
                console.log('Server response from updateProductMapping is', categoryProductMapping, response.data)
                setAddProductTemplateModalVisible(false)
                setAlreadyMappedProducts(response.data.response_body)
                let serverData = response.data.response_body
                if (serverData && serverData.length > 0) {
                    const productIds = []
                    serverData.forEach((element) => {
                        productIds.push(element.product_id)
                    })
                    setProductsForPostCall(productIds)
                    setAlreadyMappedProducts(productIds)
                } else {
                    setProductsForPostCall([])
                    setAlreadyMappedProducts([])
                }
                MarketplaceToaster.showToast(response)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log('errorServer response from updateProductMapping is', categoryProductMapping, error.response)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const onSaveClick = () => {
        setCount(0)
        setSearchKey('')
        setSearchValue('')
        updateProductMapping()
    }

    //! put call for onload table data
    const removeProductsMapping = () => {
        setIsLoading(true)
        MarketplaceServices.remove(categoryProductMapping, { id: productDeleteId }, null)
            .then(function (response) {
                console.log('Server response from updateProductMapping is', categoryProductMapping, response.data)
                findAllProductMappedForCategory()
                setAddProductTemplateModalVisible(false)
                MarketplaceToaster.showToast(response)
                setDeleteModalVisible(!deleteModalVisible)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log('errorServer response from updateProductMapping is', categoryProductMapping, error.response)
                MarketplaceToaster.showToast(error.response)
                setDeleteModalVisible(!deleteModalVisible)
            })
    }

    //! show delete modal popup
    const deleteModal = (productId) => {
        setDeleteModalVisible(!deleteModalVisible)
        setRemoveProductId(productId)
        findAllProductMappedForCategory(productId)
    }

    // !discard in modal table
    const handleDiscard = () => {
        setCount(0)
        setAddProductTemplateModalVisible(false)
        setProductsForPostCall(alreadyMappedProducts)
        setSearchKey('')
        setSearchValue('')
    }

    const getAllProductsData = () => {
        let params = {}
        params['product-status'] = 3
        setIsAllProductsDataLoading(true)
        MarketplaceServices.findAll(listProductsAPI, params, true)
            .then(function (response) {
                setAllProductsData(response?.data?.response_body?.product_data)
                setIsAllProductsDataLoading(false)
                setIsAllProductsDataNetworkError(false)
            })
            .catch(function (error) {
                console.log('Server Error response from findAllProductLists hook ', listProductsAPI, error.response)
                setAllProductsData([])
                setIsAllProductsDataLoading(false)
                setIsAllProductsDataNetworkError(true)
            })
    }
    const findAllProductMappedForCategory = (productId) => {
        MarketplaceServices.findAll(
            categoryProductMapping,
            {
                'category-id': getSelectedCategoryData && getSelectedCategoryData.id,
            },
            true
        )
            .then(function (response) {
                console.log(
                    'Server response from findAllProductMappedForCategory is',
                    categoryProductMapping,
                    response.data.response_body
                )
                let serverData = response.data.response_body
                if (serverData && serverData.length > 0) {
                    const productIds = []
                    const deleteIds = []
                    serverData.forEach((element) => {
                        productIds.push(element.product_id)

                        const deleteId = element.product_id === productId ? element.id : null
                        if (deleteId) {
                            deleteIds.push(deleteId)
                        }
                    })
                    if (deleteIds?.length > 0) {
                        setProductDeleteId(deleteIds[0])
                    }

                    setProductsForPostCall(productIds)
                    setAlreadyMappedProducts(productIds)
                } else {
                    setProductsForPostCall([])
                    setAlreadyMappedProducts([])
                }
            })
            .catch(function (error) {
                console.log(
                    'Error Server response from findAllProductMappedForCategory is',
                    categoryProductMapping,
                    error
                )
                setAlreadyMappedProducts([])
            })
    }

    const handleSearchChange = (searchKey) => {
        if (searchKey?.trim()) {
            setSearchValue(searchKey)
            setSearchKey(searchKey)
        }
    }
    const handleInputChange = (event) => {
        const trimmed = String(event.target.value)
        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
        setSearchValue(trimmedUpdate)
        if (event.target.value === '') {
            setSearchKey('')
        }
    }
    const customButton = (
        <Button type='primary' disabled={searchValue?.trim() === '' ? true : false} icon={<SearchOutlined />} />
    )

    //! calling the getProduct lists
    useEffect(() => {
        findAllProductMappedForCategory()
        getAllProductsData()
    }, [])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    useEffect(() => {
        if (alreadyMappedProducts && apiProcessedData && allProductsData) {
            let removeTheProductsWhichAreAlreadyMapped = apiProcessedData.filter(
                (item) => !alreadyMappedProducts.includes(item.key)
            )
            setModalTableData(removeTheProductsWhichAreAlreadyMapped)
            let filteredData = allProductsData.filter((item) => alreadyMappedProducts.includes(item.product_id))
            if (filteredData && filteredData.length > 0) {
                setProductsTableData(filteredData)
            } else {
                setProductsTableData([])
            }
        }
    }, [alreadyMappedProducts, apiProcessedData, allProductsData])

    return (
        <Content className='p-3 bg-white '>
            <div className=' flex justify-between items-center mb-3'>
                <p className='font-semibold text-lg'>{t('common:products')}</p>
                <Button
                    disabled={editPermissions}
                    className='app-btn-secondary'
                    onClick={() => setAddProductTemplateModalVisible(true)}>
                    <PlusOutlined className='!align-[2px] !p-0 !m-0' />
                    {t('product_template:add_products')}
                </Button>
            </div>
            {isAllProductsDataLoading ? (
                <Content className=''>
                    <Skeleton
                        active
                        paragraph={{
                            rows: 4,
                        }}
                        className='p-3'
                    />
                </Content>
            ) : isAllProductsDataNetworkError ? (
                <Content className='text-center p-3 !bg-white'>
                    <h1 level={5}>{t('common:network_error')}</h1>
                </Content>
            ) : (
                <>
                    {alreadyMappedProducts && alreadyMappedProducts.length > 0 ? (
                        <Table
                            columns={mappedProductsTableColumns}
                            dataSource={productTableData}
                            pagination={{
                                pageSize: itemsPerPageFromEnv,
                                showSizeChanger: false,
                                pageSizeOptions: false,
                                hideOnSinglePage: true,
                            }}
                        />
                    ) : (
                        <div className=' mt-5 gap-4 flex flex-col items-center'>
                            <img src={Empty1SVG} alt='empty' />
                            <p className='font-semibold text-sm text-brandGray2'>
                                {t('product_template:products_message')}
                            </p>
                            <Button
                                disabled={editPermissions}
                                className=' app-btn-secondary'
                                onClick={() => setAddProductTemplateModalVisible(true)}>
                                <PlusOutlined className='!align-[2px] !p-0 !m-0' />
                                {t('product_template:add_products')}
                            </Button>
                        </div>
                    )}
                </>
            )}
            <Content>
                {addProductTemplateModalVisible && (
                    <StoreModal
                        title={t('product_template:add_products')}
                        hideCloseButton={true}
                        isVisible={addProductTemplateModalVisible}
                        width={750}
                        cancelButtonText={t('common:discard')}
                        okButtonText={t('common:save')}
                        cancelCallback={() => handleDiscard()}
                        okCallback={() => onSaveClick()}
                        isOkButtonDisabled={count === 0 ? true : false}
                        isSpin={isLoading}
                        isScroll={true}>
                        {/* <Spin tip={t('languages:please_wait')} size='large' spinning={isLoading}> */}
                        <Content className='!flex !justify-end mt-3 mr-3'>
                            <Search
                                placeholder={t('common:please_enter_search_text_here')}
                                onSearch={handleSearchChange}
                                onChange={handleInputChange}
                                value={searchValue}
                                suffix={null}
                                maxLength={searchMaxLength}
                                enterButton={customButton}
                                allowClear
                                className='!justify-end !w-[40%]'
                            />
                        </Content>
                        <Content className='mt-3 mr-3'>
                            {loading ? (
                                <Skeleton
                                    active
                                    paragraph={{
                                        rows: 4,
                                    }}
                                    className='p-3'
                                />
                            ) : isNetworkError ? (
                                <Content className='text-center !mb-2 pt-2 bg-white p-3'>
                                    <h1 level={5}>{t('common:network_error')}</h1>
                                </Content>
                            ) : (
                                <>
                                    {apiProcessedData && apiProcessedData.length > 0 ? (
                                        <Table
                                            className='!w-[750px]'
                                            columns={allProductsTableColumns}
                                            dataSource={modalTableData}
                                            pagination={{
                                                pageSize: itemsPerPageFromEnv,
                                                showSizeChanger: false,
                                                pageSizeOptions: false,
                                                hideOnSinglePage: true,
                                            }}
                                        />
                                    ) : searchKeyTriggered ? (
                                        <Content className='!text-center !mt-6 font-bold'>
                                            <Text>{t('common:not_able_to_find_searched_details')}</Text>
                                        </Content>
                                    ) : (
                                        <Content className='!text-center !mt-6 font-bold'>
                                            <Text>{t('categories:unable_to_find_a_match')}</Text>
                                        </Content>
                                    )}
                                </>
                            )}
                        </Content>
                        {/* </Spin> */}
                    </StoreModal>
                )}
                <StoreModal
                    title={t('common:warning')}
                    isVisible={deleteModalVisible}
                    okButtonText={t('common:yes')}
                    // cancelButtonText={t('common:cancel')}
                    cancelCallback={() => setDeleteModalVisible(!deleteModalVisible)}
                    okCallback={() => removeProductsMapping()}
                    isSpin={isLoading}>
                    <p>{t('categories:you_want_to_remove_prod')}</p>
                </StoreModal>
            </Content>
        </Content>
    )
}
export default AddProducts
