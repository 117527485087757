// Note: As per discussion with sushil and logo, we are maintaining this static structure
// For grouping the datatype under four categories, ie (Intractive Content, Text Based Content, Uploads Content, Calculative Content)
// Because backend is not supporting this grouping currently,
// Once grouping is supported, we will remove this file from repository.

import TranslateAttributeStrings from '../pages/product_new_options/TranslateAttributeStrings'
import {
    CheckBox,
    ChoiceChip,
    DatePicker,
    Dropdown,
    MultipleSwitch,
    RadioButton,
    SliderButton,
    ToggleButton,
    Time,
    FileDOCS,
    FileImage,
    FilePDF,
    FileVideo,
    File,
    FloatPositive,
    FloatLimit,
    FloatRoundoff,
    FloatRoundoffLimit,
    Text,
    TextEmail,
    TextPhoneNumber,
    TextName,
    TextRich,
    TextUrl,
    NumberLimit,
    NumberPercentage,
    NumberPositive,
    ListView,
    FileAudio,
    Date,
} from './media'

const attibuteDataTypeSettings = [
    {
        key: 1,
        label: <TranslateAttributeStrings translate={'attributes:interactive_content'} padding={false} />,
        selected: true,
        dataTypes: [
            {
                dataTypeId: 1,
                image: CheckBox,
            },
            {
                dataTypeId: 2,
                image: RadioButton,
            },
            {
                dataTypeId: 3,
                image: ChoiceChip,
            },
            {
                dataTypeId: 4,
                image: ToggleButton,
            },
            {
                dataTypeId: 5,
                image: Date,
            },
            {
                dataTypeId: 6,
                image: SliderButton,
            },
            {
                dataTypeId: 7,
                image: Dropdown,
            },
            {
                dataTypeId: 8,
                image: MultipleSwitch,
            },
            {
                dataTypeId: 9,
                image: Time,
            },
            {
                dataTypeId: 14,
                image: DatePicker,
            },
        ],
    },
    {
        key: 2,
        label: <TranslateAttributeStrings translate={'attributes:text_based_content'} padding={true} />,
        selected: false,
        dataTypes: [
            {
                dataTypeId: 30,
                image: Text,
            },
            {
                dataTypeId: 10,
                image: TextRich,
            },
            {
                dataTypeId: 11,
                image: TextEmail,
            },
            {
                dataTypeId: 12,
                image: TextPhoneNumber,
            },
            {
                dataTypeId: 13,
                image: TextUrl,
            },
            {
                dataTypeId: 20,
                image: FloatPositive,
            },
            {
                dataTypeId: 21,
                image: NumberPercentage,
            },
            {
                dataTypeId: 22,
                image: NumberPositive,
            },
            {
                dataTypeId: 23,
                image: NumberLimit,
            },
            {
                dataTypeId: 24,
                image: FloatLimit,
            },
            {
                dataTypeId: 25,
                image: FloatRoundoff,
            },
            {
                dataTypeId: 26,
                image: FloatRoundoffLimit,
            },
            {
                dataTypeId: 27,
                image: TextName,
            },
        ],
    },
    {
        key: 3,
        label: <TranslateAttributeStrings translate={'attributes:uploads_content'} padding={false} />,
        selected: false,
        dataTypes: [
            {
                dataTypeId: 15,
                image: File,
            },
            {
                dataTypeId: 16,
                image: FileImage,
            },
            {
                dataTypeId: 17,
                image: FileVideo,
            },
            {
                dataTypeId: 18,
                image: FileDOCS,
            },
            {
                dataTypeId: 19,
                image: FilePDF,
            },
            {
                dataTypeId: 28,
                image: ListView,
            },
            {
                dataTypeId: 29,
                image: FileAudio,
            },
        ],
    },
    // {
    //   key: 4,
    //   label: <TranslateAttributeStrings translate={"attributes:calculative_content"} />,
    //   selected: false,
    //   dataTypes: [
    //     {
    //       dataTypeId: 20,
    //       image: FloatPositive,
    //     },
    //     {
    //       dataTypeId: 21,
    //       image: NumberPercentage,
    //     },
    //     {
    //       dataTypeId: 22,
    //       image: NumberPositive,
    //     },
    //     {
    //       dataTypeId: 23,
    //       image: NumberLimit,
    //     },
    //     {
    //       dataTypeId: 24,
    //       image: FloatLimit,
    //     },
    //     {
    //       dataTypeId: 25,
    //       image: FloatRoundoff,
    //     },
    //     {
    //       dataTypeId: 26,
    //       image: FloatRoundoffLimit,
    //     },
    //   ],
    // },
]

export default attibuteDataTypeSettings
