import { Button, Layout, Typography } from 'antd'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import CreatePolicies from './components/CreatePolicies'
import PolicyModal from './components/PolicyModal'
import { USER_TYPE } from './constants'
import useGetStoreUserConsent from './hooks/useGetStoreUserConsent'

import PolicyHistory from './components/PolicyHistory'
import './policies.css'

const { Title, Text } = Typography
const { Content } = Layout

const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const Policies = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || USER_TYPE.CUSTOMER)
    const [previewAndCustomize, setPreviewAndCustomize] = useState(null)
    const [addNewPolicy, setAddNewPolicy] = useState(false)
    const [policyHistoryFlag, setPolicyHistoryFlag] = useState(false)
    const newPolicyRef = useRef(null)
    const {
        data: userConsents,
        status: userConsentStatus,
        refetch: refetchUserConsent,
    } = useGetStoreUserConsent({
        userType: selectedTab,
    })

    usePageTitle(`${t('common:store_management_portal')} - ${t('common:policies_settings')}`)

    const policiesTabData = [
        {
            key: USER_TYPE.CUSTOMER,
            label: `${t('common:customers')}`,
            value: 0,
        },
        {
            key: USER_TYPE.VENDOR,
            label: (
                <span className={`${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : ''}`}>
                    {t('common:vendors')}
                </span>
            ),
            value: 1,
        },
    ]

    const handelPoliciesTabChange = (tabKey) => {
        setSearchParams({
            tab: tabKey,
            page: 1,
            limit: parseInt(searchParams.get('limit')) || pageLimit,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
        setSelectedTab(tabKey)
    }

    const handelAddNewPolicy = () => {
        setAddNewPolicy(true)
        setTimeout(() => {
            newPolicyRef.current.scrollIntoView(false)
        }, [100])
    }

    const handlePreviewAndCustomize = () => {
        setPreviewAndCustomize(true)
    }

    return (
        <section>
            <HeaderForTitle
                title={
                    <Content className='flex'>
                        <Content className=''>
                            <Title level={3} className='!font-semibold'>
                                {t('policies:policies')}
                            </Title>
                        </Content>
                        <Content className=' text-right'>
                            <Content className='!flex gap-2 items-center !justify-end'>
                                <Button
                                    disabled={
                                        userConsentStatus !== 'success' ||
                                        userConsents?.store_userconsent_data?.length <= 0
                                    }
                                    className={`${
                                        userConsentStatus !== 'success' ||
                                        userConsents?.store_userconsent_data?.length <= 0
                                            ? 'hover:!text-[#000000] hover:!text-opacity-25 hover:!border-[#d9d9d9] '
                                            : ''
                                    }`}
                                    onClick={() => setPolicyHistoryFlag(true)}>
                                    {t('policies:policy_history')}
                                </Button>
                                <Button
                                    onClick={handlePreviewAndCustomize}
                                    disabled={
                                        userConsentStatus !== 'success' ||
                                        userConsents?.store_userconsent_data?.length <= 0
                                    }
                                    className={`${
                                        userConsentStatus !== 'success' ||
                                        userConsents?.store_userconsent_data?.length <= 0
                                            ? 'hover:!text-[#000000] hover:!text-opacity-25 hover:!border-[#d9d9d9] '
                                            : ''
                                    }`}>
                                    {t('policies:preview_and_customise')}
                                </Button>
                                <Button
                                    className='app-btn-primary '
                                    onClick={handelAddNewPolicy}
                                    disabled={userConsentStatus !== 'success'}>
                                    {t('policies:add_new_policy')}
                                </Button>
                            </Content>
                        </Content>
                    </Content>
                }
                headerContent={
                    <>
                        <div className='pt-1'>
                            <div>
                                <Text className='!text-[#637381]'>{t('policies:help_info_policies')}</Text>
                            </div>
                            <div className='mt-2'>
                                <Text className='!text-[#637381] font-semibold'>{t('policies:bonus')}</Text>
                                {': '}
                                <Text className='!text-[#637381]'>{t('policies:bonus_help_policy')}</Text>
                            </div>
                        </div>
                        <div className='!h-10 mt-2 -mb-2'>
                            <DmTabAntDesign
                                tabData={policiesTabData}
                                tabBarPosition={'top'}
                                defaultSelectedTabKey={USER_TYPE.CUSTOMER}
                                activeKey={searchParams.get('tab') || USER_TYPE.CUSTOMER}
                                handleTabChangeFunction={handelPoliciesTabChange}
                                tabType='line'
                            />
                        </div>
                    </>
                }
            />
            <Content className='p-3 !rounded-lg'>
                <CreatePolicies
                    selectedTab={selectedTab}
                    userConsents={userConsents}
                    userConsentStatus={userConsentStatus}
                    previewAndCustomize={previewAndCustomize}
                    addNewPolicy={addNewPolicy}
                    newPolicyRef={newPolicyRef}
                    refetchUserConsent={refetchUserConsent}
                    handelAddNewPolicy={handelAddNewPolicy}
                    setAddNewPolicy={setAddNewPolicy}
                    setPreviewAndCustomize={setPreviewAndCustomize}
                />
            </Content>

            {/* Policy history popup codes goes here */}
            <PolicyModal
                isVisible={policyHistoryFlag}
                cancelCallback={() => setPolicyHistoryFlag(false)}
                isSpin={false}
                width={1000}>
                <Content>
                    <PolicyHistory />
                </Content>
            </PolicyModal>
        </section>
    )
}
export default Policies
