//! Import libraries
import { Button, Empty, Layout, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

//! Import user defined components
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
//! Import user defined functions
import { usePageTitle } from '../../hooks/usePageTitle'

//! Import CSS libraries
import MarketplaceToaster from '../../util/marketplaceToaster'
import { plusIcon } from '../../constants/media'
import TranslatorModal from '../product-category/TranslatorModal'
import { useDispatch, useSelector } from 'react-redux'
import { fnAttributeGroupDetails } from '../../services/redux/actions/ActionAttributesGroup'
import useGetAttributegroups from './hooks/useGetAttributegroups'
import useDeleteAttributeGroup from './hooks/useDeleteAttributegroup'

const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE

//! Destructure the components
const { Content } = Layout
const { Text, Title } = Typography

const ListAttributeGroups = () => {
    const { t } = useTranslation()
    usePageTitle(`${t('common:store_management_portal')} - ${t('common:attribute_groups')}`)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const [attributeGroupId, setAttributeGroupId] = useState()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [loadingSkelton, setLoadingSkelton] = useState(false)
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [attribuetGroupDetails, setAttributeGroupDetails] = useState()
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)
    const [translateAttributeid, setTranslateAttributeId] = useState(null)

    let defaultObjectForAttributeGroup = useSelector(
        (state) =>
            state.reducerAttributeGroupDetails &&
            state.reducerAttributeGroupDetails.attribuetGroupDetails &&
            state.reducerAttributeGroupDetails.attribuetGroupDetails
    )

    const {
        data: listAttributeGroups,
        status: AttributesGroupDataStatus,
        refetch: refetchAttributeGroups,
    } = useGetAttributegroups()

    const { mutate: deleteAttributeGroup, status: deleteAttributeGroupStatus } = useDeleteAttributeGroup()

    const tableHeader = [
        {
            title: `${t('common:title')}`,
            dataIndex: 'name',
            key: 'name',

            render: (text, record) => {
                return (
                    <Content>
                        <Tooltip
                            title={record.display_name ? record.display_name : '-'}
                            mouseLeaveDelay={0}
                            placement={'bottom'}>
                            <Text ellipsis={true} style={{ maxWidth: 280 }} className='!text-[#637381] text-[14px]'>
                                {record.display_name ? record.display_name : '-'}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:description')}`,
            dataIndex: 'description',
            key: 'description',

            render: (text, record) => {
                return (
                    <Content>
                        <Tooltip
                            title={record.display_description ? record.display_description : '-'}
                            mouseLeaveDelay={0}
                            placement={'bottom'}
                            overlayStyle={{ maxWidth: '600px' }}>
                            <Text ellipsis={true} style={{ maxWidth: 280 }} className='!text-[#637381] text-[14px]'>
                                {record.display_description ? record.display_description : '-'}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },

        {
            title: `${t('common:actions')}`,
            dataIndex: '',
            key: '',
            render: (text, record) => {
                return (
                    <Content className='flex gap-2'>
                        <Button
                            className='app-btn-text'
                            type='text'
                            onClick={() => {
                                handleTranslateClick(record)
                            }}>
                            {/* <Tooltip
                                placement='bottom'
                                title={`${t('common:translate')} ${record.display_name ? record.display_name : '-'}`}
                                overlayStyle={{ zIndex: 1100 }}> */}
                            <Text className='text-brandPrimaryColor text-sm font-medium leading-[22px]'>
                                {t('common:translate')}
                            </Text>
                            {/* </Tooltip> */}
                        </Button>

                        <Button className='app-btn-text' type='text' onClick={() => onClickOpenDeleteModal(record.id)}>
                            {/* <Tooltip
                                placement='bottom'
                                title={`${t('common:delete')} ${record.display_name ? record.display_name : '-'}`}
                                overlayStyle={{ zIndex: 1100 }}
                                > */}
                            <Text className='text-dangerColor text-sm font-medium leading-[22px]'>
                                {t('common:delete')}
                            </Text>
                            {/* </Tooltip> */}
                        </Button>
                    </Content>
                )
            },
        },
    ]

    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
        })
    }
    const translateModalCloseHandler = () => {
        setTranslateAttributeId(null)
        setTranslateModalVisible(false)
        setLoadingSkelton(false)
    }

    const onClickOpenDeleteModal = (id) => {
        setAttributeGroupId(id)
        setDeleteModalOpen(true)
    }
    const handleDeleteAttributeGroup = () => {
        if (attributeGroupId) {
            deleteAttributeGroup(
                { attributeGroupId },
                {
                    onSuccess: (response) => {
                        setDeleteModalOpen(false)
                        refetchAttributeGroups()
                        MarketplaceToaster.showToast(response)
                    },
                    onError: (error) => {
                        MarketplaceToaster.showToast(error.response)
                        setDeleteModalOpen(false)
                    },
                }
            )
        }
    }

    const handleTranslateClick = (record) => {
        let groupObject = { groupName: record?.display_name, groupDescription: record?.display_description }
        setAttributeGroupDetails(groupObject)
        defaultObjectForAttributeGroup[0].groupName = record?.name
        defaultObjectForAttributeGroup[0].groupDescription = record?.description
        defaultObjectForAttributeGroup[1].groupName = record?.display_name
        defaultObjectForAttributeGroup[1].groupDescription = record?.display_description
        dispatch(fnAttributeGroupDetails(defaultObjectForAttributeGroup))
        setTranslateAttributeId(record?.id)
        setTranslateModalVisible(true)
        setLoadingSkelton(true)
        setOnChangeDisableFields(true)
    }
   

    return (
        <Content className='!pt-1'>
            <Content>
                <Content>
                    <HeaderForTitle
                        title={
                            <Content className='flex !h-[42px]'>
                                <Content>
                                    <Title
                                        level={3}
                                        className='!font-semibold !text-2xl !leading-[30px] !text-regal-blue'>
                                        {t('common:product_attribute_groups')}
                                    </Title>
                                </Content>
                                <Content>
                                    <Button
                                        className='app-btn-primary !mr-2 flex !items-center !float-right'
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/list-attributegroups/add-attributegroups?${MarketplaceAppConfig.getStore(
                                                    ''
                                                )}`,
                                                {
                                                    state: {
                                                        action: 'add',
                                                        type: 'attributegroups',
                                                        title: 'attributegroups',
                                                    },
                                                }
                                            )
                                        }>
                                        <img src={plusIcon} alt='plusIcon' className='!mr-2' />
                                        {t('attribute_groups:add_attribute_group')}
                                    </Button>
                                </Content>
                            </Content>
                        }
                        headerContent={
                            <Content className='!pb-3'>
                                <Text className='!font-normal !text-brandGray1 !text-[#637381] !leading-[22px]'>
                                    {t('common:attr_group_short_desc')}
                                </Text>
                            </Content>
                        }
                    />
                </Content>
            </Content>
            <Content className='!min-h-screen !m-6'>
                {AttributesGroupDataStatus === 'pending' ? (
                    <Content className=' bg-white p-3 shadow-brandShadow rounded-lg'>
                        <SkeletonComponent />
                    </Content>
                ) : AttributesGroupDataStatus === 'error' ? (
                    <Content className='text-center bg-white p-3 shadow-brandShadow rounded-lg'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : listAttributeGroups?.Groups && listAttributeGroups?.Groups?.length > 0 ? (
                    <Content className='!p-6 bg-white shadow-brandShadow rounded-md '>
                        <Content className='flex flex-col'>
                            <Table dataSource={listAttributeGroups?.Groups} columns={tableHeader} pagination={false} />
                            {listAttributeGroups?.TotalCount > itemsPerPageFromEnv ? (
                                <Content className='flex flex-row self-end z-10'>
                                    <DmPagination
                                        currentPage={
                                            parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                        }
                                        totalItemsCount={listAttributeGroups?.TotalCount}
                                        defaultPageSize={itemsPerPageFromEnv}
                                        pageSize={
                                            parseInt(searchParams.get('limit'))
                                                ? parseInt(searchParams.get('limit'))
                                                : itemsPerPageFromEnv
                                        }
                                        handlePageNumberChange={handlePageNumberChange}
                                        showSizeChanger={true}
                                        showTotal={true}
                                        showQuickJumper={true}
                                    />
                                </Content>
                            ) : null}
                        </Content>

                        <Content>
                            <StoreModal
                                isVisible={deleteModalOpen}
                                okButtonText={t('common:delete')}
                                cancelButtonText={null}
                                title={t('attribute_groups:delete_attribute_confirmation')}
                                okCallback={() => handleDeleteAttributeGroup()}
                                cancelCallback={() => setDeleteModalOpen(false)}
                                isSpin={false}>
                                {<div>{t('attribute_groups:sure_you_want_to_del_the_attr_group')}</div>}
                            </StoreModal>
                        </Content>
                    </Content>
                ) : listAttributeGroups && listAttributeGroups.length === 0 ? (
                    <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                        <Empty description={t('common:no_data_available_to_display')} />
                    </div>
                ) : null}
            </Content>
            {translateModalVisible && (
                <StoreModal
                    title={`${t('common:translate')}`}
                    isVisible={translateModalVisible}
                    okButtonText={null}
                    cancelButtonText={null}
                    okCallback={null}
                    hideCloseButton={false}
                    cancelCallback={() => translateModalCloseHandler()}
                    isSpin={false}
                    width={1000}>
                    <Content>
                        <TranslatorModal
                            dataJson={[
                                {
                                    order: 1,
                                    label: t('attribute_groups:enter_attribute_title'),
                                    type: 'textbox',
                                    value: attribuetGroupDetails?.groupName,
                                },
                                {
                                    order: 2,
                                    label: t('common:description'),
                                    type: 'textarea',
                                    value: attribuetGroupDetails?.groupDescription,
                                },
                            ]}
                            componentType='attributegroup'
                            respectiveId={parseInt(translateAttributeid)}
                            setTranslateModalVisible={(bool) => {
                                setTranslateModalVisible(bool)
                                refetchAttributeGroups()
                            }}
                            onChangeDisableFields={onChangeDisableFields}
                            setOnChangeDisableFields={setOnChangeDisableFields}
                            loadingSkelton={loadingSkelton}
                            setLoadingSkelton={setLoadingSkelton}
                        />
                    </Content>
                </StoreModal>
            )}
        </Content>
    )
}

export default ListAttributeGroups
