import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_USER_CONSENT_STATUS_UPDATE

const useUpdateStoreConsentStatus = () => {
    const updateStoreUserConsentUpdateStatus = async ({ consentVersionId, body }) => {
        const params = { 'store-userconsent-version-id': consentVersionId }
        const res = await MarketplaceServices.update(BASE_URL, body, params)
        console.log(res, 'useUpdateStoreConsentStatus')
        return res.data
    }
    return useMutation({ mutationFn: updateStoreUserConsentUpdateStatus })
}
export default useUpdateStoreConsentStatus
