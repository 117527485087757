import { Layout } from 'antd'
import React from 'react'
import PDPDetails from './PDPDetails'
import PDPImages from './PDPImages'
import PDPOverView from './PDPOverView'

const { Content } = Layout

const PDPLayout1 = ({ productId, backgroundColor }) => {
    return (
        <Content className={`${'mt-3 p-3  '} ${backgroundColor}`}>
            <Content className='flex'>
                <Content className='w-[100%] p-3'>
                    <PDPImages imageData={[]} />
                </Content>
                <Content className='w-[100%] p-3'>
                    <PDPOverView />
                </Content>
            </Content>
            <Content className='w-[100%] p-3'>
                <PDPDetails />
            </Content>
        </Content>
    )
}

export default PDPLayout1
