import { Button, Col, Drawer, Empty, Layout, Row, Select, Skeleton, Table, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import './ListOrders.css'

/**
 *! Components are imported Here.
 */
import { useTranslation } from 'react-i18next'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'
import OrderJourney from './OrderJourney'
//! ant design initialization
const { Content } = Layout
const { Title, Paragraph, Text } = Typography

//! Global Variables
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const orderListAPI = process.env.REACT_APP_ORDERS_LIST_API
const orderItemListAPI = process.env.REACT_APP_ORDER_ITEM_LIST_API
const retryOrderItemJourneyAPI = process.env.REACT_APP_RETRY_ORDER_ITEM_JOURNEY

function ListOrders({ showHeader, permission }) {
    const { t } = useTranslation()
    usePageTitle(`${t('common:store_management_portal')} - ${t("common:orders")}`)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [isOrderItemLoading, setIsOrderItemLoading] = useState(true)
    const [isOrderItemNetworkError, setIsOrderItemNetworkError] = useState(false)
    const [currentCount, setCurrentCount] = useState()
    const [listOrdersAPIData, setListOrdersAPIData] = useState([])
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [drawerRequiredData, setDrawerRequiredData] = useState()
    const [orderDataItemById, setOrderDataItemById] = useState([])

    const latestStatusOptions = [
        {
            key: 1,
            value: `${t('common:received')}`,
            label: `${t('common:received')}`,
        },
        {
            key: 2,
            value: `${t('common:in_progress')}`,
            label: `${t('common:in_progress')}`,
        },
        {
            key: 3,
            value: `${t('common:delivered')}`,
            label: `${t('common:delivered')}`,
        },
    ]
    const showDrawer = (recordDetail) => {
        // console.log("record details....", recordDetail);
        setDrawerRequiredData(recordDetail)
        getOrderItemByID(recordDetail.id)
        setDrawerOpen(true)
    }
    const onClose = () => {
        setDrawerRequiredData()
        setDrawerOpen(false)
        setOrderDataItemById([])
        setIsOrderItemLoading(true)
    }
    //! In Progress Tab Data
    const tableColumnForSalesOrders = [
        {
            title: `${t('sales_orders:date')}`,
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            render: (text, record) => {
                return <Content className=''>{getGenerateDateAndTime(record.date_of_order, 'D MMMM YYYY')}</Content>
            },
        },
        {
            title: `${t('sales_orders:order_id')}`,
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p
                            className='!text-[#1677ff] hover:text-[#4096ff] text-[14px] !mb-0'
                            role={'button'}
                            onClick={() => showDrawer(record)}>
                            {record.id}{' '}
                        </p>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:customer_name')}`,
            dataIndex: 'customer_name',
            key: 'customer_name',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content className='text-[14px]'>
                        <p className='!mb-0'>{record.customer_name}</p>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!mb-0'>{record.total_amount ? record.total_amount : '-'}</p>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:order_status')}`,
            dataIndex: 'order_status',
            key: 'order_status',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!mb-0'>{record.order_status ? record.order_status : '-'}</p>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_orders:payment_type')}`,
            dataIndex: 'payment_type',
            key: 'payment_type',
            width: '10%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <p className='!mb-0'>{record.payment_type_name ? record.payment_type_name : '-'}</p>
                    </Content>
                )
            },
        },
    ]

    const handlePageNumberChange = (page, pageSize) => {
        window.scrollTo(0, 0)
        if (searchParams.get('v_id') !== null && searchParams.get('v_id') !== undefined) {
            setSearchParams({
                v_id: searchParams.get('v_id'),
                page: parseInt(page) ? parseInt(page) : 1,
                limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        } else {
            setSearchParams({
                page: parseInt(page) ? parseInt(page) : 1,
                limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        }
        // setCurrentCount(pageSize)
        window.scrollTo(0, 0)
    }
    //!Get Call for List Orders
    const findByPageListOrders = (page, pageLimit) => {
        MarketplaceServices.findByPage(orderListAPI, null, page, pageLimit, true)
            .then(function (response) {
                console.log('Response from get call of listOrdersGetCall', response)
                setCurrentCount(response.data.response_body.count)
                console.log('currentCount', currentCount)
                setListOrdersAPIData(response.data.response_body.Orders)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch(function (error) {
                console.log('Response from catchBlock of listOrdersGetCall', error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }
    /**
     * API call to retry the order journey based on order_item_id.
     */
    const retryOrderItemJourney = (order_item_id) => {
        MarketplaceServices.save(
            retryOrderItemJourneyAPI,
            {},
            {
                order_item_id: order_item_id,
            }
        )
            .then(function (response) {
                console.log(
                    'Server Response from post call of retryOrderItemJourney',
                    retryOrderItemJourneyAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)
            })
            .catch(function (error) {
                MarketplaceToaster.showToast(error.response)
                console.log('Error Server Response from retryOrderItemJourney', retryOrderItemJourneyAPI, error)
            })
    }

    //! Get call to get the order items based on order_id
    const getOrderItemByID = (order_id) => {
        let orderIdArray = []
        orderIdArray.push(order_id)
        MarketplaceServices.findAllWithoutPage(
            orderItemListAPI,
            {
                order_id: order_id,
            },
            true
        )
            .then(function (response) {
                console.log('Response from get call of orderItemByID', response.data.response_body)
                setOrderDataItemById(destructureOrderItemByIdData(response.data.response_body))
                setIsOrderItemLoading(false)
                setIsOrderItemNetworkError(false)
            })
            .catch(function (error) {
                console.log('Response from catchBlock of orderItemByID', error)
                setIsOrderItemLoading(false)
                setIsOrderItemNetworkError(true)
            })
    }
    //!Destructing the order item by ID data for the table population
    const destructureOrderItemByIdData = (order_data) => {
        const tempArrayOne = []
        order_data &&
            order_data.length > 0 &&
            order_data.forEach((element, index) => {
                var tempAmount = element.amount
                var tempDate = element.expected_delivery
                var tempHandler = element.handled_by
                var tempId = element.id
                var tempImage = element.image
                var tempName = element.name
                var tempOrderId = element.order_id
                var tempQuantity = element.quantity
                var tempStatus = element.status
                var tempVendorName = element.vendor_name
                var tempJourneyId = element.journey_id
                var isJourneyStarted = element.is_journey_started

                tempArrayOne &&
                    tempArrayOne.push({
                        key: index,
                        amount: tempAmount,
                        order_date: tempDate,
                        handled_by: tempHandler,
                        id: tempId,
                        image: tempImage,
                        name: tempName,
                        order_id: tempOrderId,
                        quantity: tempQuantity,
                        status: tempStatus,
                        vendor_name: tempVendorName,
                        journey_id: tempJourneyId,
                        is_journey_started: isJourneyStarted,
                    })
            })
        return tempArrayOne
    }
    //!  Get Calls are used in this UseEffect
    useEffect(() => {
        window.scrollTo(0, 0)
        setIsLoading(true)
        let pageNumber = parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
        let pageSize = parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv
        findByPageListOrders(pageNumber, pageSize)
    }, [searchParams])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (searchParams.get('v_id') !== null && searchParams.get('v_id') !== undefined) {
            setSearchParams({
                v_id: searchParams.get('v_id'),
                page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        } else {
            setSearchParams({
                page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        }
    }, [])
    const tableColumnForOrderBasedItems = [
        {
            title: `${t('sales_returns:item_name_and_sku')}`,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='font-semibold'>
                        <Row className=''>
                            <Col>
                                {' '}
                                <Tooltip overlayStyle={{ zIndex: 1 }} title={record.name}>
                                    <Text ellipsis={true} style={{ width: 200 }} className='!text-[14px]'>
                                        {record.name}
                                    </Text>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_returns:quantity')}`,
            dataIndex: 'quantity',
            key: 'quantity',
            width: '20%',
            render: (text, record) => {
                return <Content className=''>{record.quantity}</Content>
            },
        },
        {
            title: `${t('sales_orders:amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            width: '20%',
            render: (text, record) => {
                return <Content className=''>{record.amount}</Content>
            },
        },
        {
            title: `${t('sales_returns:vendor_name')}`,
            dataIndex: 'vendor_name',
            key: 'amount',
            width: '20%',
            render: (text, record) => {
                return <Content className=''>{record.vendor_name}</Content>
            },
        },
        {
            title: `${t('sales_returns:status')}`,
            dataIndex: 'status',
            key: 'status',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className=''>
                        <Select
                            className='!w-[9.5rem]'
                            disabled={true}
                            placeholder='Order Status'
                            defaultValue={record.status}
                            options={latestStatusOptions}
                        />
                    </Content>
                )
            },
        },
    ]
    return (
        <Content className='!pt-1'>
            {showHeader === true ? (
                <Content>
                    <HeaderForTitle
                        title={
                            <Content className=''>
                                <Title level={3} className='!font-normal'>
                                    {t('common:sales_orders')}
                                </Title>
                            </Content>
                        }
                        headerContent={
                            <Paragraph className='!font-semibold !text-slate-400 !m-0'>
                                {t('common:sales_order_short_desc')}
                            </Paragraph>
                        }
                    />
                </Content>
            ) : null}
            <Content className={`!p-3 ${showHeader === true ? '!min-h-screen' : 'mt-3'}`}>
                {isLoading ? (
                    <Content className='text-center bg-white p-3'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Content className='text-center bg-white p-3'>
                        <h5>{t('common:network_error')}</h5>
                    </Content>
                ) : listOrdersAPIData && listOrdersAPIData.length > 0 ? (
                    <Content className='bg-white p-3'>
                        <Content className='mb-3'>
                            <Table
                                pagination={false}
                                columns={tableColumnForSalesOrders}
                                dataSource={listOrdersAPIData}
                            />
                        </Content>
                        <Content className='text-right'>
                            {currentCount > itemsPerPageFromEnv ? (
                                <Content className='!grid !justify-items-end'>
                                    <DmPagination
                                        currentPage={
                                            parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                        }
                                        totalItemsCount={currentCount}
                                        defaultPageSize={itemsPerPageFromEnv}
                                        pageSize={
                                            parseInt(searchParams.get('limit'))
                                                ? parseInt(searchParams.get('limit'))
                                                : itemsPerPageFromEnv
                                        }
                                        handlePageNumberChange={handlePageNumberChange}
                                        showSizeChanger={true}
                                        showTotal={true}
                                    />
                                </Content>
                            ) : null}
                        </Content>
                        <Content>
                            <Drawer
                                title={`${t('sales_orders:order_id')} : ${drawerRequiredData && drawerRequiredData.id}`}
                                className='!pl-0'
                                placement='right'
                                onClose={onClose}
                                open={isDrawerOpen}
                                width='60%'>
                                <Content className='min-h-[50px] max-h-[340px] !p-3'>
                                    <Content className='!flex'>
                                        {drawerRequiredData ? (
                                            <>
                                                <div className='w-[100%] !flex-col'>
                                                    <div className='w-[50%] !inline-block !text-right pr-2'>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:order_id')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:order_status')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:payment_type')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:reference_id')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:customer_name')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:order_date')} :
                                                        </p>
                                                        <p className='!text-gray-500 my-2'>
                                                            {t('sales_orders:amount')} :
                                                        </p>
                                                    </div>
                                                    <div className='w-[50%] !inline-block !text-left pl-2'>
                                                        <p className='!font-semibold my-2'>{drawerRequiredData.id}</p>
                                                        <p className='!font-semibold my-2'>
                                                            {drawerRequiredData.order_status}
                                                        </p>
                                                        <p className='!font-semibold my-2'>
                                                            {drawerRequiredData.payment_type_name === undefined
                                                                ? t('common:not_available')
                                                                : drawerRequiredData.payment_type_name}
                                                        </p>
                                                        <p className='!font-semibold my-2'>
                                                            {drawerRequiredData.order_referance_id === undefined ||
                                                            drawerRequiredData.order_referance_id === null
                                                           ? t('common:not_available')
                                                                : drawerRequiredData.order_referance_id}
                                                        </p>
                                                        <p className='!font-semibold my-2'>
                                                            {drawerRequiredData.customer_name}
                                                        </p>
                                                        <p className='!font-semibold my-2'>
                                                            {getGenerateDateAndTime(
                                                                drawerRequiredData.date_of_order,
                                                                'D MMMM YYYY'
                                                            )}
                                                        </p>
                                                        <p className='!font-semibold my-2'>
                                                            {drawerRequiredData.total_amount}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='w-[100%] !flex-col'>
                                                    <>
                                                        <div className='w-[50%] !inline-block !text-right pr-2'>
                                                            <p className='!text-gray-500 my-2'>
                                                                {t('sales_orders:customer_name')} :{' '}
                                                            </p>
                                                        </div>
                                                        <div className='w-[50%] !inline-block !text-left pl-2'>
                                                            <p className='!font-semibold my-2'>
                                                                {drawerRequiredData.customer_name}
                                                            </p>
                                                        </div>
                                                        <Content className='!flex !flex-row'>
                                                            <p className='!mb-0 !text-gray-500 !mx-2 my-1 w-[50%] text-right'>
                                                                {t('sales_returns:billing_address')} :{' '}
                                                            </p>
                                                            <Content className='!flex !flex-col !mx-2 my-1 w-[50%] text-left'>
                                                                {drawerRequiredData.billing_address ? (
                                                                    <>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.billing_address
                                                                                    .locality
                                                                            }
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.billing_address.city}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.billing_address.state}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.billing_address.zipcode}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.billing_address.country}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.billing_address
                                                                                    .phone_number
                                                                            }
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <p className='!font-semibold'>
                                                                        {t(
                                                                            'sales_returns:billing_address_not_available'
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </Content>
                                                        </Content>
                                                        <Content className='!flex !flex-row'>
                                                            <p className='!mb-0 !text-gray-500 !mx-2 my-1 w-[50%] text-right'>
                                                                {t('sales_returns:delivery_address')} :{' '}
                                                            </p>
                                                            <Content className='!flex !flex-col !mx-2 my-1 w-[50%] text-left'>
                                                                {drawerRequiredData.delivery_address ? (
                                                                    <>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.delivery_address
                                                                                    .locality
                                                                            }
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.delivery_address.city}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {drawerRequiredData.delivery_address.state}
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.delivery_address
                                                                                    .zipcode
                                                                            }
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.delivery_address
                                                                                    .country
                                                                            }
                                                                        </span>
                                                                        <span className='!font-semibold'>
                                                                            {
                                                                                drawerRequiredData.delivery_address
                                                                                    .phone_number
                                                                            }
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <p className='!font-semibold'>
                                                                        {t(
                                                                            'sales_returns:billing_address_not_available'
                                                                        )}
                                                                    </p>
                                                                )}
                                                            </Content>
                                                        </Content>
                                                    </>
                                                </div>
                                            </>
                                        ) : null}
                                    </Content>
                                    <Content className='mt-3'>
                                        {isOrderItemLoading ? (
                                            <Content className='bg-white p-3'>
                                                <Skeleton paragraph={{ rows: 3 }} />
                                            </Content>
                                        ) : isOrderItemNetworkError ? (
                                            <Content className='text-center bg-white p-3'>
                                                <h5 className='!flex !justify-center'>{t('common:network_error')}</h5>
                                            </Content>
                                        ) : (
                                            <Table
                                                expandable={{
                                                    expandedRowRender: (record) => (
                                                        <Content key={record.id}>
                                                            {record.is_journey_started === true ? (
                                                                <Content
                                                                    key={record.id}
                                                                    className='!min-w-[100%] !min-h-[150px] overflow-x-auto !relative p-3'>
                                                                    <Content className='!absolute !min-w-[100%] '>
                                                                        <OrderJourney
                                                                            order_item_id={record.id}
                                                                            order_id={record.order_id}
                                                                            journey_id={record.journey_id}
                                                                            is_journey_started={
                                                                                record.is_journey_started
                                                                            }
                                                                        />
                                                                    </Content>
                                                                </Content>
                                                            ) : (
                                                                <Content className='flex justify-between'>
                                                                    <Content className=''>
                                                                        <p className=''>
                                                                            {t('sales_orders:journey_not_started')}
                                                                        </p>
                                                                    </Content>
                                                                    <Content className='!inline-block !text-right'>
                                                                        <Button
                                                                            onClick={() =>
                                                                                retryOrderItemJourney(record.id)
                                                                            }
                                                                            className='app-btn-primary'>
                                                                            {t('sales_orders:start_journey')}
                                                                        </Button>
                                                                    </Content>
                                                                </Content>
                                                            )}
                                                        </Content>
                                                    ),

                                                    rowExpandable: (record) => record.name !== 'Not Expandable',
                                                }}
                                                columns={tableColumnForOrderBasedItems}
                                                dataSource={orderDataItemById}
                                            />
                                        )}
                                    </Content>
                                </Content>
                            </Drawer>
                        </Content>
                    </Content>
                ) : listOrdersAPIData && listOrdersAPIData.length === 0 ? (
                    <Empty></Empty>
                ) : null}
            </Content>
        </Content>
    )
}

export default ListOrders
