import { ActionTypes } from '../constants/ActionTypes'

const { RDX_VENDOR_ACCESS_INFO } = ActionTypes
// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods
export const ReducerVendorInfo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_VENDOR_ACCESS_INFO:
            return { ...state, vendorAccessInfo: payload }
        default:
            return state
    }
}
