import { useMutation } from '@tanstack/react-query'
import http from '../../../services/axios/http-common'
const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT_DISPLAYNAME

const useUpdateConsentTranslation = () => {
    const updateStoreUserConsent = async ({ body, consentVersionId }) => {
        const baseURL = `${BASE_URL}?version_id=${consentVersionId}`
        const res = http.put(baseURL, body)
        //console.log(res, 'useUpdateConsentTranslation')
        return res.data
    }
    return useMutation({ mutationFn: updateStoreUserConsent })
}
export default useUpdateConsentTranslation
