import { Checkbox, Col, Collapse, Image, Layout, Row, Spin } from 'antd'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'

import StoreModal from '../../components/storeModal/StoreModal'
import AddImage from './AddImage'

//! related to ListImage

import defaultImage from '../../assets/images/common/ImageLoad_Fail.png'
import './media.css'

const { Panel } = Collapse
const { Content } = Layout
const imagePathAPI = process.env.REACT_APP_IMAGES_API
const pageLimit = process.env.REACT_APP_ITEM_PER_PAGE
const absoluteImageAPI = process.env.REACT_APP_ABSOLUTE_IMAGE_API
const baseURL = process.env.REACT_APP_BASE_URL

const ListImageAndAddImage = ({
    actionType, //product , category, product_publishing
    openModal,
    setOpenModal,
    selectedFileData,
    setSelectedFileData,
    selectionOption,
    supportedExtensions,
    fileType, // new Props
}) => {
    const ChildRef = useRef()
    const { t } = useTranslation()
    const [showListImageAndAddImageModel, setShowListImageAndAddImageModel] = useState(openModal)
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [errorStatus, setErrorStatus] = useState()
    const [errorMessage, setErrorMessage] = useState()
    const [imagePath, setImagePath] = useState([])
    const [selectedAccordion, setSelectedAccordion] = useState()
    // List Image
    const [selectedImage, setSelectedImage] = useState([])

    //! for ListImage
    const [imagePathDataCopy, setImagePathDataCopy] = useState()
    const [page, setPage] = useState(1)
    const [loadMoreImage, setLoadMoreImage] = useState(false)
    const [reachedToLastPage, setReachedToLastPage] = useState(false)
    const [isLoadingListImageContent, setIsLoadingListImageContent] = useState(true)
    const [imageResponseFromApi, setImageResponseFromApi] = useState()
    const [allImagePathWithBlobUrl, setAllImagePathWithBlobUrl] = useState([])
    const [spinnerForAddImage, setSpinnerForAddImage] = useState(false)
    const [isFileSelected, setIsFileSelected] = useState(false)
    const getImagePath = () => {
        let supportedExtensionsForGetCall =
            supportedExtensions && supportedExtensions.length > 0 && supportedExtensions.split('.')

        if (supportedExtensionsForGetCall && supportedExtensionsForGetCall.length > 0) {
            supportedExtensionsForGetCall =
                supportedExtensionsForGetCall.shift() + supportedExtensionsForGetCall.join('')
        }
        MarketplaceServices.findByPage(
            imagePathAPI,
            {
                'image-type': actionType[0],
                ['extension']: supportedExtensionsForGetCall,
            },
            parseInt(page),
            parseInt(pageLimit),
            false
        )
            .then(function (res) {
                setImagePath([...imagePath, ...res.data.response_body.data])
                setImagePathDataCopy([...imagePath, ...res.data.response_body.data])
                setAllImagePathWithBlobUrl([...imagePath, ...res.data.response_body.data])
                setLoadMoreImage(false)
                if (res.data.response_body.count > 0) {
                    setSelectedAccordion('1')
                } else {
                    setSelectedAccordion('2')
                }
                setIsLoading(false)
                setIsLoadingListImageContent(false)
                setIsNetworkError(false)
                console.log('Response from ImageConfig ***', res.data)
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setReachedToLastPage(true)
                } else {
                    setIsNetworkError(true)
                    setErrorStatus(error.response.status)
                    setErrorMessage(error.message)
                }
                if (imagePath && !imagePath.length > 0) {
                    setSelectedAccordion('2')
                }

                setIsLoading(false)
                setIsLoadingListImageContent(false)
                setLoadMoreImage(false)

                console.log('error from imageList#---', error.response.status)
            })
    }

    const handleSaveImage = () => {
        if (selectedAccordion === '2') {
            setSpinnerForAddImage(true)
            ChildRef.current.getSave('save')
        } else {
            if (selectedImage.length > 0) {
                let finalData = []
                const tempArr =
                    imagePath &&
                    imagePath.length > 0 &&
                    imagePath.filter((element) => selectedImage.includes(element.id))
                tempArr &&
                    tempArr.length > 0 &&
                    tempArr.forEach((value) => {
                        let obj = {
                            default_ar: '',
                            default_size_name: value.default_size_name,
                            extension: value.extension,
                            id: value.id,
                            image_path: value.image_path,
                            image_full_path: value.image_full_path,
                            image_type: value.image_type,
                            specific_to: value.specific_to,
                            status: value.status,
                            store_id: value.store_id,
                            visibility: value.visibility,
                        }
                        finalData.push(obj)
                    })
                setSelectedFileData(finalData)
                setShowListImageAndAddImageModel(false)
                setSelectedImage([])
                setOpenModal(false)
            } else {
                if (imagePath.length > 0) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('categories:select_from_existing_files')}`, 'error')
                    )
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('categories:no_existing_files_available')}`, 'error')
                    )
                }
            }
        }
    }

    useEffect(() => {
        //! if fileType === "image"  then only calling getImagePath Api to get all the Paths from server
        if (fileType === 'image') {
            // getImagePath();
        } else {
            setSelectedAccordion('2')
        }
    }, [])

    const handleAccordionChange = (key) => {
        if (key !== undefined && Array.isArray(key) && key.length > 0) {
            setSelectedAccordion(key[0])
        } else {
            setSelectedAccordion('1')
        }
    }

    // for Button to handle Modal

    const showListImageAndAddImageModelHandler = () => {
        setShowListImageAndAddImageModel(!showListImageAndAddImageModel)
        // handleButton();
        setOpenModal(false)
    }

    const cancelListImageAndAddImageModelHandler = () => {
        setSelectedImage([])
        if (selectedAccordion === '2') {
            ChildRef.current.getSave('cancel')
        }
        setShowListImageAndAddImageModel(false)
        setOpenModal(false)
    }

    const checkboxHandler = (e) => {
        if (selectionOption === 'multiple') {
            if (e.target.checked) {
                setSelectedImage([...selectedImage, e.target.value])
                setIsFileSelected(true)
            } else {
                setSelectedImage(selectedImage.filter((ele) => ele !== e.target.value))
                setIsFileSelected(false)
            }
        } else {
            if (selectedImage.length < 1 && e.target.checked) {
                if (e.target.checked) {
                    setSelectedImage([...selectedImage, e.target.value])
                    setIsFileSelected(true)
                }
            } else if (e.target.checked === false) {
                setSelectedImage(selectedImage.filter((ele) => ele !== e.target.value))
                setIsFileSelected(false)
            } else if (selectedImage.length === 1 && e.target.checked) {
                setSelectedImage([e.target.value])
                setIsFileSelected(true)
            }
        }
    }
    const handleScroll = (e) => {
        const bottom = Math.abs(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop) < 1
        if (bottom) {
            if (!reachedToLastPage) {
                setIsLoadingListImageContent(true)
                setPage(page + 1)
                setLoadMoreImage(true)
            }
        }
    }
    useEffect(() => {
        if (fileType === 'image') {
            if (!reachedToLastPage) {
                getImagePath()
            }
        }
    }, [page])

    return (
        <Content className='!bg-white'>
            <StoreModal
                isVisible={showListImageAndAddImageModel}
                title={t('categories:upload_file')}
                okCallback={handleSaveImage}
                okButtonText={t('categories:save_file')}
                cancelButtonText={t('common:cancel')}
                cancelCallback={cancelListImageAndAddImageModelHandler}
                isSpin={false}
                width={800}
                isOkButtonDisabled={!isFileSelected}
                hideCloseButton={false}>
                <>
                    <Layout>
                        {/*we are checking selectedAccordion  value if it's undefined in during initial render then we are not
          showing collapse and if imagePathAPI having count > 0(means we have image on server) then only show first  Panel otherwise show second Panel to Upload  */}

                        {selectedAccordion !== undefined ? (
                            <Collapse
                                accordion
                                defaultActiveKey={[selectedAccordion]}
                                expandIconPosition={'end'}
                                onChange={handleAccordionChange}>
                                {/* if there fileType === "image"  then only we will show list image Panel (means first panel)
                otherwise we will show second Panel */}
                                {fileType === 'image' ? (
                                    <Panel header={t('categories:existing_files')} key='1'>
                                        <>
                                            {isNetworkError ? (
                                                errorStatus === 500 ? (
                                                    <Layout className='p-0 text-center mt-5 bg-white'>
                                                        <h5>{t('common:network_error')}</h5>
                                                    </Layout>
                                                ) : (
                                                    <Content className='text-center'>{errorMessage}</Content>
                                                )
                                            ) : isLoading ? (
                                                <Layout className='p-0 text-center !mt-5 bg-white'>
                                                    <Spin />
                                                </Layout>
                                            ) : imagePathDataCopy && imagePathDataCopy.length > 0 ? (
                                                <>
                                                    <Spin spinning={isLoadingListImageContent} tip='Please wait'>
                                                        <Layout className='bg-white '>
                                                            <Content
                                                                onScroll={handleScroll}
                                                                className=' mt-2   max-h-[400px] !overflow-y-auto overflow-x-hidden'>
                                                                <Row gutter={[6, 8]}>
                                                                    {allImagePathWithBlobUrl &&
                                                                        allImagePathWithBlobUrl.length > 0 &&
                                                                        allImagePathWithBlobUrl.map((element) => {
                                                                            return (
                                                                                <Fragment key={element.id}>
                                                                                    <div>
                                                                                        <Col>
                                                                                            <div className='!relative checkbox '>
                                                                                                <Checkbox
                                                                                                    value={element.id}
                                                                                                    checked={selectedImage.includes(
                                                                                                        element.id
                                                                                                    )}
                                                                                                    onChange={(e) =>
                                                                                                        checkboxHandler(
                                                                                                            e
                                                                                                        )
                                                                                                    }
                                                                                                    className='!absolute !right-1 !-top-0  !z-10 !text-black   '></Checkbox>
                                                                                                <div
                                                                                                    className={`!border-2 !border-transparent${
                                                                                                        selectedImage.includes(
                                                                                                            element.id
                                                                                                        )
                                                                                                            ? '!border-2 !border-indigo-600'
                                                                                                            : ''
                                                                                                    }`}>
                                                                                                    <Image
                                                                                                        width={125}
                                                                                                        src={
                                                                                                            baseURL +
                                                                                                            element.image_full_path
                                                                                                        }
                                                                                                        className=' !relative hover:shadow-lg '
                                                                                                        preview={true}
                                                                                                        fallback={
                                                                                                            defaultImage
                                                                                                        }></Image>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </div>
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                </Row>
                                                                {reachedToLastPage ? (
                                                                    <div className='text-center mt-2 font-medium'>
                                                                        <h1>
                                                                            {t('categories:no_more_image_available')}{' '}
                                                                        </h1>
                                                                    </div>
                                                                ) : (
                                                                    <div className='example !mt-3 text-center'>
                                                                        <Spin
                                                                            spinning={loadMoreImage}
                                                                            tip={t(
                                                                                'categories:loading_more_images'
                                                                            )}></Spin>
                                                                    </div>
                                                                )}
                                                            </Content>
                                                        </Layout>
                                                    </Spin>
                                                </>
                                            ) : (
                                                <div className='flex  items-center justify-center'>
                                                    <h4 className='font-semibold'>
                                                        {t('categories:no_existing_image_available')}
                                                    </h4>
                                                </div>
                                            )}
                                        </>
                                    </Panel>
                                ) : null}
                                <Panel header={t('categories:add_new_file')} key='2'>
                                    <Spin spinning={spinnerForAddImage} tip={t('languages:please_wait')}>
                                        <AddImage
                                            ref={ChildRef}
                                            actionType={actionType}
                                            handleSaveImgae={showListImageAndAddImageModelHandler}
                                            uploadedImageData={selectedFileData}
                                            setUploadedImageData={setSelectedFileData}
                                            selectionOption={selectionOption}
                                            fileType={fileType}
                                            supportedExtensions={supportedExtensions}
                                            spinnerForAddImage={spinnerForAddImage}
                                            setSpinnerForAddImage={setSpinnerForAddImage}
                                            setIsFileSelected={setIsFileSelected}
                                        />
                                    </Spin>
                                </Panel>
                            </Collapse>
                        ) : (
                            <div className='!bg-white flex items-center justify-center'>
                                <Spin />
                            </div>
                        )}
                    </Layout>
                </>
            </StoreModal>
        </Content>
    )
}

export default ListImageAndAddImage
