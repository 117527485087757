import { ActionTypes } from '../constants/ActionTypes'

const { RDX_CONTENT_SETTING_INFO, RDX_STORE_LOGO_INFO, RDX_STORE_LOGO_LOADING } = ActionTypes

export const ReducerContentSetting = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_CONTENT_SETTING_INFO:
            return { ...state, contentSettingInfo: payload }

        default:
            return state
    }
}

export const ReducerStoreLogo = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_STORE_LOGO_INFO:
            return { ...state, storeLogoInfo: payload }

        default:
            return state
    }
}

export const ReducerStoreLogoLoading = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_STORE_LOGO_LOADING:
            return { ...state, storeLogoLoading: payload }

        default:
            return state
    }
}
