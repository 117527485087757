import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_PRODUCT_SELECTED_INFO,
    RDX_PRODUCT_TEMPLATE_SELECTED_INFO,
    RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO,
    RDX_ACTIVE_PRODUCT_TEMPLATES_INFO,
} = ActionTypes

// selectedVendors is reducer here it will check the actiontype what we are getting
// if it matches it will return the payload to action methods
export const ReducerProducts = (state = [], { type, payload }) => {
    switch (type) {
        case RDX_PRODUCT_SELECTED_INFO:
            return { ...state, productSelectedInfo: payload }
        case RDX_PRODUCT_TEMPLATE_SELECTED_INFO:
            return { ...state, productTemplateSelectedInfo: payload }
        case RDX_PRODUCT_TEMPLATE_SELECTED_PRODUCT_TYPES_INFO:
            return { ...state, productTemplateSelectedProductTypesInfo: payload }
        case RDX_ACTIVE_PRODUCT_TEMPLATES_INFO:
            return { ...state, activeProductTemplatesInfo: payload }
        default:
            return state
    }
}
