import { Layout, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import PDPLayoutParent from './PDPLayoutParent'
import PLPLayoutParent from './PLPLayoutParent'

const { Content } = Layout
const { Title } = Typography
const SFSettings = () => {
    const tabData = [
        {
            key: 1,
            label: 'Theme',
        },
        {
            key: 2,
            label: 'Home',
        },
        {
            key: 3,
            label: 'Product Listing',
        },
        {
            key: 4,
            label: 'Product Details',
        },
    ]
    const [settingsTabData, setSettingsTabData] = useState(tabData[0])

    const handleChangeTab = (value) => {
        console.log('tabvalue-->', value)
        let localTabData = tabData.filter((ele) => ele.key === value)
        setSettingsTabData(localTabData[0])
    }

    const renderLayoutsBasedOnTabChange = (value) => {
        switch (value) {
            case 1:
                return <Content>Theme</Content>
            case 2:
                return <Content>Home</Content>
            case 3:
                return <PLPLayoutParent />
            case 4:
                return <PDPLayoutParent />
            default:
                return ''
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Content className='mt-3 p-3'>
            <Title level={3} className='!font-normal'>
                Store Front Settings
            </Title>
            <Content>
                <DmTabAntDesign
                    tabType={'line'}
                    tabBarPosition={'top'}
                    tabData={tabData}
                    handleTabChangeFunction={(value) => handleChangeTab(value)}
                />
            </Content>
            <Content className='bg-white min-h-[300px] p-3'>
                {renderLayoutsBasedOnTabChange(settingsTabData.key)}
            </Content>
        </Content>
    )
}

export default SFSettings
