import { combineReducers } from 'redux'
import { ReducerVendorInfo } from './VendorInfoReducer'
import { ReducerProducts } from './ReducerProducts'
import { ReducerCategories } from './ReducerCategories'
import { ReducerProductTypes } from './ReducerProductTypes'
import { ReducerRevisionInfo } from './ReducerRevisionInfo'
import { ReducerPageComp } from './ReducerPageComp'
import { ReducerVendorType } from './ReducerVendorType'
import { ReducerVendor } from './ReducerVendor'
import { ReducerTest } from './ReducerTest'
import { ReducerProductDetails } from './ReducerProductTypes'
import { ReducerAttributeGroupDetails } from './ReducerAttributeGroup'
import { ReducerAbsoluteStoreImageInfo } from './ReducerStoreImages'
import { ReducerStoreLanguage, ReducerSelectedLanguage, ReducerDefaultLanguage } from './ReducerStoreLanguage'
import { ReducerAPICalls } from './ReducerAPICalls'
import { ReducerSegment } from './ReducerSegment'
import { ReducerStoreAvailable, ReducerStoreSelected } from './ReducerStore'
import { ReducerRoleAvailable, ReducerRoleSelected } from './ReducerRole'
import { ReducerUserLoggedInfo } from './ReducerUser'
import {
    ReducerNewlyCreatedAttributeInfo,
    ReducerNewlyCreatedStaticAttributeInfo,
    ReducerAlreadyCreatedAttributesInfo,
    ReducerCalculativeOperatorInfo,
    ReducerCalculativeAttributeInfo,
} from './ReducerAttributes'
import { ReducerPaymentCycleInfo } from './ReducerPaymentCycle'
import { ReducerSelectedVendorSettlementInfo, ReducerVendorSettlementInfo } from './ReducerVendorSettlement'

import { ReducerContentSetting, ReducerStoreLogo, ReducerStoreLogoLoading } from './ReducerContentSetting'
import { ReducerUserProfileInfo } from './ReducerUserProfile'
import { ReducerStoreSettingsInfo } from './ReducerStoreSettings'
import { ReducerLayoutInfo } from './ReducerLayout'
// console.log("ReducerSelectedLanguage",ReducerSelectedLanguage)

// here we are combine the all reducer using combineReducers from redux
// the key name should be on user hand he can give which ever name he want
// but the value he is getting from actions there he written some methods

const reducers = combineReducers({
    reducerVendorInfo: ReducerVendorInfo,
    reducerProductTypes: ReducerProductTypes,
    reducerCategories: ReducerCategories,
    reducerProducts: ReducerProducts,
    reducerRevisionInfo: ReducerRevisionInfo,
    reducerPageComp: ReducerPageComp,
    reducerVendorType: ReducerVendorType,
    reducerVendor: ReducerVendor,
    reducerTest: ReducerTest,
    reducerStoreLanguage: ReducerStoreLanguage,
    reducerSelectedLanguage: ReducerSelectedLanguage,
    reducerDefaultLanguage: ReducerDefaultLanguage,
    reducerAPICalls: ReducerAPICalls,
    reducerSegment: ReducerSegment,
    reducerStoreAvailable: ReducerStoreAvailable,
    reducerStoreSelected: ReducerStoreSelected,
    reducerRoleAvailable: ReducerRoleAvailable,
    reducerRoleSelected: ReducerRoleSelected,
    reducerUserLoggedInfo: ReducerUserLoggedInfo,
    reducerNewlyCreatedAttributeInfo: ReducerNewlyCreatedAttributeInfo,
    reducerNewlyCreatedStaticAttributeInfo: ReducerNewlyCreatedStaticAttributeInfo,
    reducerAlreadyCreatedAttributesInfo: ReducerAlreadyCreatedAttributesInfo,
    reducerCalculativeOperatorInfo: ReducerCalculativeOperatorInfo,
    reducerCalculativeAttributeInfo: ReducerCalculativeAttributeInfo,
    reducerPaymentCycleInfo: ReducerPaymentCycleInfo,
    reducerVendorSettlementInfo: ReducerVendorSettlementInfo,
    reducerSelectedVendorSettlemetInfo: ReducerSelectedVendorSettlementInfo,
    reducerContentSetting: ReducerContentSetting,
    reducerProductTypeDetails: ReducerProductDetails,
    reducerUserProfileInfo: ReducerUserProfileInfo,
    reducerAttributeGroupDetails: ReducerAttributeGroupDetails,
    reducerStoreLogo: ReducerStoreLogo,
    reducerAbsoluteStoreImageInfo: ReducerAbsoluteStoreImageInfo,
    reducerStoreSettingsInfo: ReducerStoreSettingsInfo,
    reducerStoreLogoLoading: ReducerStoreLogoLoading,
    reducerLayoutInfo: ReducerLayoutInfo,
})

export default reducers
