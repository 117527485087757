import { useQuery } from '@tanstack/react-query'
// import MarketplaceServices from '../../../services/axios/MarketplaceServices'
import http from '../../../services/axios/http-common'
const BASE_URL = process.env.REACT_APP_STORE_USER_CONSENT_DISPLAYNAME

const useGetConsentTranslation = ({ versionId }) => {
    const getConsentTranslation = async () => {
        //const res = await MarketplaceServices.findAllWithoutPage(BASE_URL, params)
        const url = BASE_URL + '?version-id=' + versionId
        const res = await http.get(url)
        return res?.data?.response_body
    }
    return useQuery({
        queryKey: ['StoreUserConsentTranslation', versionId],
        queryFn: getConsentTranslation,
        refetchOnWindowFocus: false,
        retry: false,
    })
}
export default useGetConsentTranslation
