import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import util from '../../util/common'
import localConfigData from '../../constants/LocalConfig'

const localesURL = process.env.REACT_APP_LOCALES_URL
//! Get all required details from .env file
const configS3URL = process.env.REACT_APP_CONFIG_S3_URL

//! Disable all consoles when deployed in production
console.log('PROFILE:', process.env.NODE_ENV)
if (!util.isDev()) {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
    console.info = () => {}
    console.warn = () => {}
    console.trace = () => {}
}

document.body.style.direction = util.getSelectedLanguageDirection()?.toLowerCase()

let storeName
try {
    const searchParams = new URLSearchParams(window.location.search)
    const response = await fetch(configS3URL)
    const appConfig = await response.json()
    if (appConfig) {

       storeName = util.getStoreName(appConfig, searchParams)
        //if we want to switch to local config uncomment below line and comment above code
        // storeName = util.getStoreName(localConfigData, searchParams)
    }
} catch (error) {
    //Redirect to Error Page
    console.log('APPLICATION CONFIGURATION NOT FOUND2')
}

const getCurrentHost = util.isDev()
    ? '/assets/locales/{{lng}}/{{ns}}/translation.json'
    : localesURL.replace('{store-name}', String(storeName).toLowerCase())

if (storeName) {
    i18n.use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            supportedLngs: util.getStoreSupportedLngs(),
            fallbackLng: util.getUserSelectedLngCode().toLowerCase(),
            debug: true,
            returnEmptyString: false,

            whitelist: util.getStoreSupportedLngs(), // array with whitelisted languages

            nonExplicitWhitelist: false,
            load: 'currentOnly', // | currentOnly | languageOnly
            preload: [util.getUserSelectedLngCode().toLowerCase()], // array with preload languages

            // order and from where user language should be detected
            order: [
                'querystring',
                'cookie',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],

            // keys or params to lookup language from
            lookupQuerystring: 'dmsmplng',
            lookupCookie: 'dmsmplng',
            lookupLocalStorage: 'dmsmplng',
            lookupSessionStorage: 'dmsmplng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional set cookie options, reference:[MDN Set-Cookie docs]
            cookieOptions: { path: '/', sameSite: 'strict' },
            react: { useSuspense: true },
            backend: {
                loadPath: getCurrentHost,
            },
            ns: [
                'dashboard',
                'common',
                'product_template',
                'products',
                'categories',
                'approvals',
                'product_types',
                'languages',
                'layouts',
                'sales_orders',
                'sales_returns',
                'vendors',
                'customers',
                'attributes',
                'transactions',
                'attribute_groups',
                'relative_products',
                'messages',
                'coupler',
                'user_profile',
                'payment',
                'users_roles',
                'store_settings',
                'policies',
                'coupons'
            ],
            interpolation: {
                escapeValue: false,
            },
        })
}

export default i18n
