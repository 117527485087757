// Note: As per discussion with sushil, we are maintaining this static structure
//!In this we will be having the placeholder for all the datatypes.
const floatPositiveMaxValue = process.env.REACT_APP_FLOAT_POSITIVE_MAX_VALUE
const AttributeDataTypes = [
    {
        key: 5,
        dataTypeId: 5,
        placeholder: 'select_date',
    },
    {
        key: 6,
        dataTypeId: 6,
        min: 0,
        max: 999999999,
    },
    {
        key: 7,
        dataTypeId: 7,
        placeholder: 'select_data',
    },
    {
        key: 9,
        dataTypeId: 9,
        placeholder: 'select_time',
    },
    {
        key: 14,
        dataTypeId: 14,
        placeholder: 'select_date_time',
    },
    {
        key: 30,
        dataTypeId: 30,
        placeholder: 'enter_text',
        min: 0,
        max: 255,
    },
    {
        key: 10,
        dataTypeId: 10,
        placeholder: 'enter_text',
    },
    {
        key: 27,
        dataTypeId: 27,
        placeholder: 'enter_text',
        min: 0,
        max: 255,
    },
    {
        key: 11,
        dataTypeId: 11,
        placeholder: 'enter_email',
    },
    {
        key: 12,
        dataTypeId: 12,
        placeholder: 'enter_phone_number',
    },
    {
        key: 13,
        dataTypeId: 13,
        placeholder: 'enter_url',
    },
    {
        key: 20,
        dataTypeId: 20,
        placeholder: 'enter_positive_number',
        min: 0,
        max: floatPositiveMaxValue,
    },
    {
        key: 21,
        dataTypeId: 21,
        placeholder: 'enter_number',
        min: 0,
        max: 100,
    },
    {
        key: 22,
        dataTypeId: 22,
        placeholder: 'enter_positive_number',
        min: 0,
        max: 999999999,
    },
    {
        key: 23,
        dataTypeId: 23,
        placeholder: 'enter_number',
        min: 0,
        max: 999999999,
    },
    {
        key: 24,
        dataTypeId: 24,
        placeholder: 'enter_number',
        min: 0,
        max: 999999999,
    },
    {
        key: 25,
        dataTypeId: 25,
        placeholder: 'enter_number',
        min: 0,
        max: 999999999.99,
        decimal: 2,
    },
    {
        key: 26,
        dataTypeId: 26,
        placeholder: 'enter_number',
        min: 0,
        max: 999999999.99,
        decimal: 2,
    },
]

export default AttributeDataTypes
