import { useMutation } from '@tanstack/react-query'
import MarketplaceServices from '../../../services/axios/MarketplaceServices'
const BASE_URL = process.env.REACT_APP_APPROVAL_REQUEST_API
/**
 * The below function is to update the 
 * @returns it returns the API response from the async method.
 */
const useUpdateApprovalStatus = () => {
    const updateApprovalStatus = async ({ putApprovalId, putBody }) => {
        const params = { _id: parseInt(putApprovalId) }
        const res = await MarketplaceServices.update(BASE_URL, putBody, params)
        return res
    }
    return useMutation({ mutationFn: updateApprovalStatus })
}
export default useUpdateApprovalStatus
