import { PercentageOutlined } from '@ant-design/icons'
import {
    Affix,
    Alert,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Input,
    InputNumber,
    Layout,
    Radio,
    Row,
    Skeleton,
    Space,
    Spin,
    Table,
    TimePicker,
    Tooltip,
    Typography,
    Image,
    Badge,
    Tag,
} from 'antd'
import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { MdClear } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import { AvatarImg } from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnMakeAPICall } from '../../services/redux/actions/ActionsAPICall'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'
import { ProductDefaultImage } from '../../constants/media'

import AddCategoryToDiscount from './AddCategoryToDiscount'
import AddProductsToDiscount from './AddProductsToDiscount'
import Summary from './Summary'
import useCreateCoupons from './hooks/useCreateCoupons'
import useUpdateCoupons from './hooks/useUpdateCoupons'
import { getTimeZoneAbbreviation } from '../../util/GetTimeZone'

dayjs.extend(utc)
const { Content } = Layout
const { Title, Text } = Typography

const updateCouponStatusAPI = process.env.REACT_APP_UPDATE_COUPONS_STATUS_API
const categoryAPI = process.env.REACT_APP_CATEGORY_API
const generateCouponAPI = process.env.REACT_APP_GENERATE_COUPONS_API
const couponAPI = process.env.REACT_APP_COUPONS_API
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const listProductsAPI = process.env.REACT_APP_LIST_PRODUCTS_API
const baseURL = process.env.REACT_APP_BASE_URL
const discountCodeMinLength = process.env.REACT_APP_DISCOUNT_CODE_MIN_LENGTH
const discountCodeMaxLength = process.env.REACT_APP_DISCOUNT_CODE_MAX_LENGTH
const fixedAmtMinValue = process.env.REACT_APP_FIXED_AMT_MIN_VALUE
const fixedAmtMaxValue = process.env.REACT_APP_FIXED_AMT_MAX_VALUE
const percentageMinValue = process.env.REACT_APP_DISCOUNT_PERCENTAGE_MIN_VALUE
const percentageMaxValue = process.env.REACT_APP_DISCOUNT_PERCENTAGE_MAX_VALUE
const purchaseAmtMinValue = process.env.REACT_APP_PURCHASE_AMT_MIN_VALUE
const purchaseAmtMaxValue = process.env.REACT_APP_PURCHASE_AMT_MAX_VALUE
const minQtyItems = process.env.REACT_APP_MIN_QTY_ITEMS
const maxQtyItems = process.env.REACT_APP_MAX_QTY_ITEMS
const minNumberTimesDiscountUsed = process.env.REACT_APP_MIN_NUMBER_TIMES_DISCOUNT_USED
const maxNumberTimesDiscountUsed = process.env.REACT_APP_MAX_NUMBER_TIMES_DISCOUNT_USED
const maxStepValue = process.env.REACT_APP_MAX_VALUE_FOR_DISCOUNT_FIELD_STEP

function CreateDiscount() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const search = useLocation().search
    let id = new URLSearchParams(search).get('ref')
    let discountType = new URLSearchParams(search).get('dt')
    let action = new URLSearchParams(search).get('a')
    let couponId = new URLSearchParams(search).get('id')
    let couponCode = new URLSearchParams(search).get('code')
    // let couponStatus = new URLSearchParams(search).get('status')
    let searchParamsUrl = new URLSearchParams(search)
    let statusParam = searchParamsUrl.get('status')

    // all functionality of Active and Expired are same
    let couponStatus = (statusParam && statusParam === 'Active') || statusParam === 'Expired' ? 'Active' : 'InActive'
    let duplicateCouponFlag = new URLSearchParams(search).get('duplicate')

    const { mutate: createCoupons, status: createCouponsStatus } = useCreateCoupons()
    const { mutate: updateCoupons, status: updateCouponsStatus } = useUpdateCoupons()

    const [mainObjectDataForAllActions, setMainObjectDataForAllActions] = useState()
    const [copyMainObjectDataForAllActions, setCopyMainObjectDataForAllActions] = useState()

    const [vendorStatusModalOpen, setVendorStatusModalOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [couponStatusFlag, setCouponStatusFlag] = useState(couponStatus)
    const [statusChangeSpin, setStatusChangeSpin] = useState(false)
    const [vendorSuspensionModalOpen, setVendorSuspensionModalOpen] = useState(false)
    const [suspensionSpin, setSuspensionSpin] = useState(false)
    const [dataForHandleCancelAction, setDataForHandleCancelAction] = useState()
    const [headerCouponDisplay, setHeaderCouponDisplay] = useState()
    const [summaryData, setSummaryData] = useState()
    const [updateButton, setUpdateButton] = useState(true)
    const [disableActiveButton, setDisableActiveButton] = useState(false)
    const [editPageLoading, setEditPageLoading] = useState(false)
    const [editPageNetworkError, setEditPageNetworkError] = useState(false)
    const [langDirection, setLangDirection] = useState('ltr')
    const [enableEndDate, setEnableEndDate] = useState(false)
    const [minimumPurschaseRequirementDefaultValue, setMinimumPurschaseRequirementDefaultValue] =
        useState('NoRequirment')

    const [loadingCreateCoupon, setLoadingCreateCoupon] = useState(false)
    const [isUpLoading, setIsUpLoading] = useState(false)
    const [oncePerCustomer, setOncePerCustomer] = useState(false)
    const [numberOfUse, setNumberOfUse] = useState(false)

    const [inValidCouponCode, setInValidCouponCode] = useState(false)
    const [inValidFixedAmountValue, setInValidFixedAmountValue] = useState(false)
    const [inValidPercentageValue, setInValidPercentageValue] = useState(false)
    const [inValidStartDate, setInValidStartDate] = useState(false)
    const [inValidStartTime, setInValidStartTime] = useState(false)
    const [inValidEndDate, setInValidEndDate] = useState(false)
    const [inValidEndTime, setInValidEndTime] = useState(false)
    const [inValidAmount, setInValidAmount] = useState(false)
    const [inValidQuantity, setInValidQuantity] = useState(false)
    const [inValidLimitUse, setInValidLimitUse] = useState(false)
    const [startDate, setStartDate] = useState(null)

    const [addProductModalVisible, setAddProductModalVisible] = useState(false)

    const [selectedProducts, setSelectedProducts] = useState()
    const [allPublishedProducts, setAllPublishedProducts] = useState([])
    const [allPublishedProductsForList, setAllPublishedProductsForList] = useState([])
    const [inValidProductValue, setInValidProductValue] = useState(false)

    const [filteredDataBasedOnSelectedProduct, setFilteredDataBasedOnSelectedProduct] = useState([])
    const [addCategoryModalVisible, setAddCategoryModalVisible] = useState(false)

    const [selectedCategory, setSelectedCategory] = useState()
    const [allCategory, setAllCategory] = useState([])
    const [allCategoryForList, setAllCategoryForList] = useState([])
    const [inValidCategoryValue, setInValidCategoryValue] = useState(false)

    const [filteredDataBasedOnSelectedCategory, setFilteredDataBasedOnSelectedCategory] = useState([])
    const [selectedChoice, setSelectedChoice] = useState('product')
    const [saveButtonDisabledForModal, setSaveButtonDisabledForModal] = useState(true)
    const [triggerUseEffect, setTriggerUseEffect] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [dateValidationMsg, setDateValidationMsg] = useState(false)
    const [timeValidationMsg, setTimeValidationMsg] = useState(false)
    const [timeZone, setTimeZone] = useState(getTimeZoneAbbreviation())

    //! currency symbol
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    const disabledDate = (current) => {
        return current && current < moment().startOf('day')
    }
    const disabledEndDate = (current) => {
        if (startDate) {
            return startDate && current && current < moment(startDate).startOf('day')
        } else {
            return current && current < moment().startOf('day')
        }
    }

    const columnsForProduct = [
        {
            title: `${t('common:product_name')}`,
            dataIndex: 'product_name',
            key: 'product_name',
            width: '80%',
            render: (text, record) => {
                return (
                    <>
                        {' '}
                        <Content className='flex items-center'>
                            <Image
                                src={record.product_image ? baseURL + record.product_image : ProductDefaultImage}
                                width={40}
                                height={40}></Image>
                            <Content className={` ${langDirection === 'rtl' ? '!mr-3' : '!ml-3'} `}>
                                <Text
                                    ellipsis={{
                                        tooltip: {
                                            title: record && record.product_name,
                                            mouseLeaveDelay: 0,
                                        },
                                    }}
                                    style={{ maxWidth: 400 }}
                                    className='!text-[14px] text-brandGray1 font-normal'>
                                    {record && record.product_name}{' '}
                                </Text>
                            </Content>
                        </Content>
                    </>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'name',
            key: 'name',
            width: '17%',
            render: (text, record) => {
                return (
                    <>
                        <Button
                            className={`app-btn-text ${couponStatus === 'Active' ? '!cursor-not-allowed ' : '!cursor-pointer'}`}
                            type='text'>
                            <Text
                                onClick={() => handleSelectedProductChange(record.product_id)}
                                className={`
                 ${couponStatus === 'Active' ? 'cursor-not-allowed pointer-events-none app-btn-text font-medium' : 'app-btn-text font-medium'}
                `}>
                                {t('common:remove')}
                            </Text>
                        </Button>
                    </>
                )
            },
        },
    ]
    const columnsForCategory = [
        {
            title: `${t('common:category')}`,
            dataIndex: 'category_name',
            key: 'category_name',
            width: '80%',
            render: (text, record) => {
                return (
                    <>
                        <Content className='flex items-center'>
                            <Image
                                src={
                                    record && record?.category_media_full_path
                                        ? record.category_media_full_path
                                        : ProductDefaultImage
                                }
                                width={40}
                                height={40}></Image>
                            <div className={`!flex !flex-col ${langDirection === 'rtl' ? '!mr-3' : '!ml-3'} `}>
                                <Text
                                    ellipsis={{
                                        tooltip: {
                                            title: record && record.display_name,
                                            mouseLeaveDelay: 0,
                                        },
                                    }}
                                    style={{ maxWidth: 400 }}
                                    className='!text-[14px]'>
                                    {record && record.display_name}{' '}
                                </Text>
                                <Text className='!text-[14px]'>
                                    {record && record.total_products}
                                    <span> {t('common:products')}</span>
                                </Text>
                            </div>
                        </Content>
                    </>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'name',
            key: 'name',
            width: '10%',
            render: (text, record) => {
                return (
                    <>
                        <Button
                            className={`app-btn-text ${couponStatus === 'Active' ? '!cursor-not-allowed' : '!cursor-pointer'}`}
                            type='text'>
                            <Text
                                // size={20}
                                onClick={() => handleSelectedCategoryChange(record.id)}
                                className={`
                 ${couponStatus === 'Active' ? 'cursor-not-allowed pointer-events-none text-gray-400' : 'text-black'}
                `}
                            />
                        </Button>
                    </>
                )
            },
        },
    ]

    //!  create Coupons
    const createCoupon = () => {
        // let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions);
        let postObject = {}
        //! checking empty condition for non-mandatory fields
        postObject.coupon_type = mainObjectDataForAllActions && mainObjectDataForAllActions.coupon_type
        postObject.coupon_code = mainObjectDataForAllActions && mainObjectDataForAllActions.coupon_code.value
        // if(mainObjectDataForAllActions && mainObjectDataForAllActions.start_date)
        if (mainObjectDataForAllActions && mainObjectDataForAllActions.start_date !== '') {
            postObject.start_date = mainObjectDataForAllActions.start_date
        }
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.start_time !== '' &&
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.start_time !== null
        ) {
            postObject.start_time = dayjs.utc(mainObjectDataForAllActions.start_time, 'HH:mm:ss').format('HH:mm:ss')
            // !== ""
            //   ? mainObjectDataForAllActions.start_time
            //   : new Date().toLocaleTimeString("en-IN", {
            //       hour12: false,
            //       hour: "2-digit",
            //       minute: "2-digit",
            //       second: "2-digit",
            //       timeZone: "Asia/Kolkata",
            //     });
        } else {
            postObject.start_time = null
        }

        if (mainObjectDataForAllActions && mainObjectDataForAllActions.end_date !== '') {
            postObject.end_date = mainObjectDataForAllActions && mainObjectDataForAllActions.end_date
        }
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.end_time !== '' &&
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.end_time !== null
        ) {
            postObject.end_time = dayjs.utc(mainObjectDataForAllActions.end_time, 'HH:mm:ss').format('HH:mm:ss')
        } else {
            postObject.end_time = null
        }

        //! creating nested Object
        let product_discount_details = {}

        product_discount_details.coupon_discount_type =
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value === 1
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.fix_value !== '') {
                product_discount_details.fix_value = parseFloat(
                    mainObjectDataForAllActions.product_discount_details.fix_value
                )
            }
        } else if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value == 2
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.percentage !== '') {
                product_discount_details.percentage = parseFloat(
                    mainObjectDataForAllActions.product_discount_details.percentage
                )
            }
        }

        if (minimumPurschaseRequirementDefaultValue === 'NoRequirment') {
            product_discount_details.minimum_purchase_requirement = 1
        } else if (minimumPurschaseRequirementDefaultValue === 'PurchaseAmount') {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.min_amount !== '') {
                if (mainObjectDataForAllActions.product_discount_details.min_amount !== null) {
                    product_discount_details.min_amount =
                        mainObjectDataForAllActions.product_discount_details.min_amount

                    // product_discount_details.min_quantity = 0;
                    product_discount_details.minimum_purchase_requirement = 2
                }
            }
        } else if (minimumPurschaseRequirementDefaultValue === 'MinimumQuantityOfItem') {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details.min_quantity !== ''
            ) {
                if (mainObjectDataForAllActions.product_discount_details.min_quantity !== null) {
                    product_discount_details.min_quantity = parseInt(
                        mainObjectDataForAllActions.product_discount_details.min_quantity
                    )
                    // product_discount_details.min_amount = 0;
                    product_discount_details.minimum_purchase_requirement = 3
                }
            }
        }
        product_discount_details.once_per_customer = oncePerCustomer
        // numberOfUse
        if (numberOfUse) {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details.number_of_use !== ''
            ) {
                product_discount_details.number_of_use = parseInt(
                    mainObjectDataForAllActions.product_discount_details.number_of_use
                )
            }
        }

        //!? static value

        // product_discount_details.product_choice = selectedChoice;
        if (selectedChoice === 'product') {
            product_discount_details.product_choice = selectedChoice

            product_discount_details.product = selectedProducts
            // product_discount_details.category = [];
        }
        if (selectedChoice === 'category') {
            product_discount_details.category_choice = selectedChoice

            product_discount_details.category = selectedCategory
            // product_discount_details.product = [];
        }

        postObject.product_discount_details = product_discount_details

        console.log('postobjec', postObject)
        setIsUpLoading(true)
        createCoupons(
            { data: postObject },
            {
                onSuccess: (response) => {
                    window.scrollTo(0, 0)
                    dataProcessorForSettingDataToMainObjectBySuccessResponse(response?.data?.response_body, 'post')

                    MarketplaceToaster.showToast(response)
                    setTimeout(() => {
                        navigate(
                            `/dashboard/coupons/edit-discount?id=${response?.data?.response_body?.id}&a=${'edit'}&code=${
                                response?.data?.response_body?.coupon_code
                            }&status=${
                                response?.data?.response_body?.status === 0 ? 'InActive' : 'Active'
                            }&${MarketplaceAppConfig.getStore('')}`
                        )
                        setIsUpLoading(false)
                        setUpdateButton(true)
                        setDisableActiveButton(false)
                        dispatch(fnMakeAPICall({ makeAPICall: false }))
                    }, 500)
                },
                onError: (error) => {
                    MarketplaceToaster.showToast(error.response)

                    let alreadyCouponExistResponseCode = error?.response?.data?.response_code
                    if (alreadyCouponExistResponseCode == 'PMS-000509-20') {
                        setInValidCouponCode(true)
                    }
                    setIsUpLoading(false)
                    setDisableActiveButton(false)
                },
            }
        )
    }

    //! update Coupons
    // testt14
    const updateCouponDetails = () => {
        let putObject = {}
        //! checking empty condition for non-mandatory fields
        putObject.coupon_type = mainObjectDataForAllActions && mainObjectDataForAllActions.coupon_type
        putObject.coupon_code = mainObjectDataForAllActions && mainObjectDataForAllActions.coupon_code.value
        // if(mainObjectDataForAllActions && mainObjectDataForAllActions.start_date)
        if (mainObjectDataForAllActions && mainObjectDataForAllActions.start_date !== '') {
            putObject.start_date = mainObjectDataForAllActions && mainObjectDataForAllActions.start_date
        } else {
            putObject.start_date = null
        }
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.start_time !== '' &&
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.start_time !== null
        ) {
            putObject.start_time = dayjs.utc(mainObjectDataForAllActions.start_time, 'HH:mm:ss').format('HH:mm:ss')

            // !== ""
            //   ? mainObjectDataForAllActions.start_time
            //   : new Date().toLocaleTimeString("en-IN", {
            //       hour12: false,
            //       hour: "2-digit",
            //       minute: "2-digit",
            //       second: "2-digit",
            //       timeZone: "Asia/Kolkata",
            //     });
        } else {
            putObject.start_time = null
        }
        if (mainObjectDataForAllActions && mainObjectDataForAllActions.end_date !== '') {
            putObject.end_date = mainObjectDataForAllActions && mainObjectDataForAllActions.end_date
        }
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.end_time !== '' &&
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.end_time !== null
        ) {
            putObject.end_time = dayjs.utc(mainObjectDataForAllActions.end_time, 'HH:mm:ss').format('HH:mm:ss')
        } else {
            putObject.end_time = null
        }

        //! creating nested Object
        let product_discount_details = {}

        product_discount_details.coupon_discount_type =
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value === 1
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.fix_value !== '') {
                product_discount_details.fix_value = parseFloat(
                    mainObjectDataForAllActions.product_discount_details.fix_value
                )
            }
        } else if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value === 2
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.percentage !== '') {
                product_discount_details.percentage = parseFloat(
                    mainObjectDataForAllActions.product_discount_details.percentage
                )
            }
        }
        // PurchaseAmount
        // MinimumQuantityOfItem
        if (minimumPurschaseRequirementDefaultValue === 'NoRequirment') {
            product_discount_details.minimum_purchase_requirement = 1
        } else if (minimumPurschaseRequirementDefaultValue === 'PurchaseAmount') {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.min_amount !== '') {
                if (mainObjectDataForAllActions.product_discount_details.min_amount !== null) {
                    product_discount_details.min_amount =
                        mainObjectDataForAllActions.product_discount_details.min_amount

                    // product_discount_details.min_quantity = 0;
                    product_discount_details.minimum_purchase_requirement = 2
                }
            }
        } else if (minimumPurschaseRequirementDefaultValue === 'MinimumQuantityOfItem') {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details.min_quantity !== ''
            ) {
                if (mainObjectDataForAllActions.product_discount_details.min_quantity !== null) {
                    product_discount_details.min_quantity = parseInt(
                        mainObjectDataForAllActions.product_discount_details.min_quantity
                    )
                    // product_discount_details.min_amount = 0;
                    product_discount_details.minimum_purchase_requirement = 3
                }
            }
        }
        // if(product_discount_details.minimum_purchase_requirement )

        product_discount_details.once_per_customer = oncePerCustomer

        if (numberOfUse) {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details.number_of_use !== ''
            ) {
                product_discount_details.number_of_use = parseInt(
                    mainObjectDataForAllActions.product_discount_details.number_of_use
                )
            }
        } else {
            product_discount_details.number_of_use = null
        }

        //! static value

        if (selectedChoice === 'product') {
            product_discount_details.product_choice = selectedChoice
            product_discount_details.product = selectedProducts
            product_discount_details.category_choice = null
            product_discount_details.category = []
            // product_discount_details.category = [];
        }
        if (selectedChoice === 'category') {
            product_discount_details.category_choice = selectedChoice

            product_discount_details.category = selectedCategory
            product_discount_details.product_choice = null
            product_discount_details.product = []
            // product_discount_details.product = [];
        }
        putObject.product_discount_details = product_discount_details

        setIsUpLoading(true)
        updateCoupons(
            { couponId: couponId, data: putObject },
            {
                onSuccess: (response) => {
                    MarketplaceToaster.showToast(response)
                    window.scrollTo(0, 0)
                    setSearchParams({
                        a: action,
                        id: couponId,
                        code: response?.data?.response_body?.coupon_code,
                        status: couponStatus,
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                    })
                    dataProcessorForSettingDataToMainObjectBySuccessResponse(response?.data?.response_body, 'post')
                    setDataForHandleCancelAction(response?.data?.response_body)
                    setIsUpLoading(false)
                    setInValidCouponCode(false)
                    dispatch(fnMakeAPICall({ makeAPICall: false }))
                    setUpdateButton(true)
                    setDisableActiveButton(false)
                },
                onError: (error) => {
                    setIsUpLoading(false)
                    setDisableActiveButton(true)
                    MarketplaceToaster.showToast(error?.response)
                },
            }
        )
    }

    //! validation of input fields
    const couponDataValidationForPostAndPut = () => {
        let missingMandatoryFields = 0
        let invalidValues = 0
        let percentageChecking = 1
        let fixedAmountChecking = 1
        if (mainObjectDataForAllActions && mainObjectDataForAllActions.coupon_code.value.trim() === '') {
            missingMandatoryFields++
            setInValidCouponCode(true)
        } else if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.coupon_code.value.trim() !== '' &&
            mainObjectDataForAllActions.coupon_code.value.length < discountCodeMinLength
        ) {
            invalidValues++
            setInValidCouponCode(true)
        }
        if (
            mainObjectDataForAllActions &&
            mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value
        ) {
            if (mainObjectDataForAllActions.product_discount_details.coupon_discount_type.value === 1) {
                if (
                    mainObjectDataForAllActions.product_discount_details.fix_value === '' ||
                    mainObjectDataForAllActions.product_discount_details.fix_value === null
                ) {
                    setInValidFixedAmountValue(true)
                    missingMandatoryFields++
                }
                if (mainObjectDataForAllActions.product_discount_details.fix_value == 0) {
                    setInValidFixedAmountValue(true)
                    fixedAmountChecking--
                }
            } else {
                if (
                    mainObjectDataForAllActions.product_discount_details.percentage === '' ||
                    mainObjectDataForAllActions.product_discount_details.percentage === null
                ) {
                    setInValidPercentageValue(true)
                    missingMandatoryFields++
                } else if (mainObjectDataForAllActions.product_discount_details.percentage == 0) {
                    percentageChecking--
                    setInValidPercentageValue(true)
                }
            }
        }

        if (selectedChoice === 'category') {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details &&
                mainObjectDataForAllActions.product_discount_details?.category?.length <= 0
            ) {
                setInValidCategoryValue(true)
                setInValidProductValue(false)
                missingMandatoryFields++
            }
        }
        if (selectedChoice === 'product') {
            if (
                mainObjectDataForAllActions &&
                mainObjectDataForAllActions.product_discount_details &&
                mainObjectDataForAllActions.product_discount_details.product?.length <= 0
            ) {
                setInValidCategoryValue(false)
                setInValidProductValue(true)
                missingMandatoryFields++
            }
        }
        //! Start Date and Start time vice-versa validation
        if (
            (mainObjectDataForAllActions && mainObjectDataForAllActions.start_date?.length > 0) ||
            (mainObjectDataForAllActions && mainObjectDataForAllActions.start_time?.length > 0)
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.start_date?.length > 0) {
                if (
                    (mainObjectDataForAllActions && mainObjectDataForAllActions.start_time?.length <= 0) ||
                    !mainObjectDataForAllActions.start_time
                ) {
                    setInValidStartTime(true)
                    missingMandatoryFields++
                }
                setInValidStartDate(false)
            }
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.start_time?.length > 0) {
                if (
                    (mainObjectDataForAllActions && mainObjectDataForAllActions.start_date?.length <= 0) ||
                    !mainObjectDataForAllActions.start_date
                ) {
                    setInValidStartDate(true)
                    missingMandatoryFields++
                }
                setInValidStartTime(false)
            }
        }
        //! End Date and End Time vice-versa validation
        if (
            (mainObjectDataForAllActions && mainObjectDataForAllActions.end_date?.length > 0) ||
            (mainObjectDataForAllActions && mainObjectDataForAllActions.end_time?.length > 0)
        ) {
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.end_date?.length > 0) {
                if (
                    (mainObjectDataForAllActions && mainObjectDataForAllActions.end_time?.length <= 0) ||
                    !mainObjectDataForAllActions.end_time
                ) {
                    setInValidEndTime(true)
                    missingMandatoryFields++
                }
                setInValidEndDate(false)
            }
            if (mainObjectDataForAllActions && mainObjectDataForAllActions.end_time?.length > 0) {
                if (
                    (mainObjectDataForAllActions && mainObjectDataForAllActions.end_date?.length <= 0) ||
                    !mainObjectDataForAllActions.end_date
                ) {
                    setInValidEndDate(true)
                    missingMandatoryFields++
                }
                setInValidEndTime(false)
            }
        }
        if (minimumPurschaseRequirementDefaultValue === 'PurchaseAmount') {
            if (
                (mainObjectDataForAllActions &&
                    mainObjectDataForAllActions.product_discount_details.min_amount === '') ||
                mainObjectDataForAllActions.product_discount_details.min_amount === null
            ) {
                missingMandatoryFields++
                setInValidAmount(true)
            } else if (
                mainObjectDataForAllActions.product_discount_details.min_amount > purchaseAmtMaxValue ||
                mainObjectDataForAllActions.product_discount_details.min_amount < purchaseAmtMinValue
            ) {
                invalidValues++
                setInValidAmount(true)
            }
        }

        if (minimumPurschaseRequirementDefaultValue === 'MinimumQuantityOfItem') {
            if (
                (mainObjectDataForAllActions &&
                    mainObjectDataForAllActions.product_discount_details.min_quantity === '') ||
                mainObjectDataForAllActions.product_discount_details.min_quantity === null
            ) {
                missingMandatoryFields++
                setInValidQuantity(true)
            } else if (
                mainObjectDataForAllActions.product_discount_details.min_quantity > maxQtyItems ||
                mainObjectDataForAllActions.product_discount_details.min_quantity < minQtyItems
            ) {
                invalidValues++
                setInValidQuantity(true)
            }
        }
        // numberOfUse number_of_use
        if (numberOfUse) {
            if (
                (mainObjectDataForAllActions &&
                    mainObjectDataForAllActions.product_discount_details.number_of_use === '') ||
                mainObjectDataForAllActions.product_discount_details.number_of_use === null
            ) {
                missingMandatoryFields++
                setInValidLimitUse(true)
            } else if (
                mainObjectDataForAllActions.product_discount_details.number_of_use < minNumberTimesDiscountUsed ||
                mainObjectDataForAllActions.product_discount_details.number_of_use > maxNumberTimesDiscountUsed
            ) {
                invalidValues++
                setInValidLimitUse(true)
            }
        }
        if (enableEndDate) {
            console.log('mainObjectDataForAllActions------->', mainObjectDataForAllActions)
            let startDate = mainObjectDataForAllActions?.start_date
            let startTime = mainObjectDataForAllActions?.start_time
            let endDate = mainObjectDataForAllActions?.end_date
            let endTime = mainObjectDataForAllActions?.end_time
            if (startDate != '' && startDate != null && endDate != '' && endDate != null) {
                // missingMandatoryFields++
                if (new Date(endDate) < new Date(startDate)) {
                    setDateValidationMsg(true)
                } else {
                    setDateValidationMsg(false)
                }
            }
            if (startTime != '' && startTime != null && endTime != '' && endTime != null) {
                if (startDate === endDate) {
                    if (endTime <= startTime) {
                        // missingMandatoryFields++
                        setTimeValidationMsg(true)
                    }
                } else {
                    setTimeValidationMsg(false)
                }
            }
            if (startDate == '' || startDate == null || endDate == '' || endDate == null) {
                setInValidStartDate(true)
                setInValidEndDate(true)
                missingMandatoryFields++
            }
        }
        if (missingMandatoryFields !== 0) {
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:values_for_mandatory_fields')}`, 'error'))
        } else if (invalidValues !== 0) {
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:enter_valid_values')}`, 'error'))
        } else {
            if (duplicateCouponFlag === 'true') {
                // testt14
                if (percentageChecking != 1) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('coupons:percentage_should_not_be_zero')}`, 'error')
                    )
                } else if (fixedAmountChecking != 1) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('coupons:fixed_amount_should_not_be_zero')}`, 'error')
                    )
                } else {
                    createCoupon()
                }
            } else {
                if (action === 'add') {
                    if (percentageChecking != 1) {
                        MarketplaceToaster.showToast(
                            util.getToastObject(`${t('coupons:percentage_should_not_be_zero')}`, 'error')
                        )
                    } else if (fixedAmountChecking != 1) {
                        MarketplaceToaster.showToast(
                            util.getToastObject(`${t('coupons:fixed_amount_should_not_be_zero')}`, 'error')
                        )
                    } else {
                        createCoupon()
                    }
                } else if (action === 'edit') {
                    // updateCouponDetails();
                    console.log(
                        'checlValidationnn',
                        !_.isEqual(mainObjectDataForAllActions, copyMainObjectDataForAllActions)
                    )
                    if (_.isEqual(mainObjectDataForAllActions, copyMainObjectDataForAllActions)) {
                        MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                    } else {
                        if (percentageChecking != 1) {
                            MarketplaceToaster.showToast(
                                util.getToastObject(`${t('coupons:percentage_should_not_be_zero')}`, 'error')
                            )
                        } else if (fixedAmountChecking != 1) {
                            MarketplaceToaster.showToast(
                                util.getToastObject(`${t('coupons:fixed_amount_should_not_be_zero')}`, 'error')
                            )
                        } else {
                            updateCouponDetails()
                        }
                    }
                }
            }
        }
    }

    const createDiscountHandler = (value, action) => {
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidPercentageValue(false)
        setInValidFixedAmountValue(false)
        console.log('checkTheChangee', value, action)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        switch (action) {
            case 'coupon_code':
                // copyOfcreatDiscountInfoMandatory.coupon_code = value;
                break
            case 'coupon_discount_type':
                tempMainObjectDataForAllActions.product_discount_details.coupon_discount_type.value = value
                break
            case 'discount_percentage':
                tempMainObjectDataForAllActions.product_discount_details.percentage = value
                setInValidPercentageValue(false)
                break
            case 'fix_value':
                // if (value) {
                tempMainObjectDataForAllActions.product_discount_details.fix_value = value
                // }
                // else {
                //   tempMainObjectDataForAllActions.product_discount_details.fix_value =
                //     "";
                // }
                setInValidFixedAmountValue(false)

                break

            case 'category':
                // copyOfCreateDiscountInfo.category = value;
                break
            case 'product':
                // copyOfCreateDiscountInfo.product = value;
                break
            case 'start_date':
                // copyOfcreatDiscountInfoMandatory.start_date = value;
                break
            case 'end_date':
                // copyOfcreatDiscountInfoMandatory.end_date = value;
                break
            case 'customer_choice':
                // copyOfCreateDiscountInfo.customer_choice = value;
                break
            case 'customer':
                // copyOfCreateDiscountInfo.customer = value;
                break
            case 'category_choice':
                // copyOfCreateDiscountInfo.category_choice = value;

                break
            case 'product_choice':
                // copyOfCreateDiscountInfo.product_choice = value;

                break
            default:
                return
        }
        // setCreateDiscountInfo(copyOfCreateDiscountInfo);
        // setCreatDiscountInfoMandatory(copyOfcreatDiscountInfoMandatory);
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
    }

    const getOfferPercentageControl = (enablePercentage) => {
        console.log('checkValuessHere', enablePercentage)
        if (parseInt(enablePercentage) == 2) {
            return (
                <Input
                    className='w-[55%] my-2 border-solid border-[#C6C6C6]'
                    status={inValidPercentageValue ? 'error' : ''}
                    disabled={couponStatus === 'Active' ? true : false}
                    value={
                        mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.percentage
                    }
                    min={percentageMinValue}
                    max={percentageMaxValue}
                    onKeyPress={(e) => {
                        validatePositiveNumberForInputs(e, /[0-9]|\./)
                    }}
                    addonAfter={<PercentageOutlined />}
                    onChange={(e) => {
                        const { value: inputValue } = e.target
                        const regex = /(?:(?:100(?:\.0{1,2})?)|(?:\d{1,2}(?:\.\d{0,2})?))/
                        createDiscountHandler(inputValue.match(regex)?.[0] || null, 'discount_percentage')
                    }}
                    placeholder={t('coupons:enter_percentage')}
                />
            )
        } else {
            return (
                <InputNumber
                    className='w-[55%] my-2 border-solid border-[#C6C6C6'
                    status={inValidFixedAmountValue ? 'error' : ''}
                    disabled={couponStatus === 'Active' ? true : false}
                    addonBefore={currencySymbolFromRedux}
                    min={fixedAmtMinValue}
                    max={fixedAmtMaxValue}
                    value={
                        mainObjectDataForAllActions && mainObjectDataForAllActions.product_discount_details.fix_value
                    }
                    // parser={(value) => {
                    //   // Remove any non-digit characters, including percentage symbol
                    //   const parsedValue = value.replace(/[^\d]/g, "");
                    //   return parseInt(parsedValue, 10);
                    // }}
                    // testt15
                    onKeyPress={(e) => {
                        validatePositiveNumberForInputs(e, /[0-9]|\./)
                    }}
                    onChange={(value) => {
                        if (/[0-9]/.test(value) || !value) {
                            console.log('value', value)
                            createDiscountHandler(value, 'fix_value')
                        }
                    }}
                    placeholder={t('coupons:enter_amount')}
                />
            )
        }
    }
    const dataProcessorForSettingDataToMainObjectBySuccessResponse = (serverData, requestType) => {
        console.log('checkServerData', serverData)
        const createMainObjectFormate = {
            coupon_type: 1,
            coupon_name: serverData.coupon_name,
            coupon_code: {
                value: serverData.coupon_code,
                is_error: true,
                retrieve_from: requestType,
            },
            description: serverData.description,
            start_date: serverData.start_date,
            end_date: serverData.end_date,
            start_time: serverData.start_time,
            end_time: serverData.end_time,
            product_discount_details: {
                coupon_discount_type: {
                    value: serverData.product_discount_details.coupon_discount_type,
                    is_error: true,
                },
                fix_value: serverData.product_discount_details.fix_value,
                max_value: serverData.product_discount_details.max_value,
                percentage: serverData.product_discount_details.percentage,
                min_amount: serverData.product_discount_details.min_amount,
                category_choice: serverData.product_discount_details.category_choice,
                product_choice: serverData.product_discount_details.product_choice,
                minimum_purchase_requirement: serverData.product_discount_details.minimum_purchase_requirement,
                min_quantity: serverData.product_discount_details.min_quantity,
                once_per_customer: serverData.product_discount_details.once_per_customer,
                number_of_use: serverData.product_discount_details.number_of_use,
                category: serverData.product_discount_details.category,
                product: serverData.product_discount_details.product,
            },
        }
        setMainObjectDataForAllActions(createMainObjectFormate)
        setCopyMainObjectDataForAllActions(createMainObjectFormate)
        setHeaderCouponDisplay(serverData.coupon_code)
        //! handling child inputes
        if (serverData.product_discount_details && serverData.product_discount_details.min_amount) {
            setMinimumPurschaseRequirementDefaultValue('PurchaseAmount')
        } else if (serverData.product_discount_details && serverData.product_discount_details.min_quantity) {
            setMinimumPurschaseRequirementDefaultValue('MinimumQuantityOfItem')
        }
        if (parseInt(serverData.product_discount_details.minimum_purchase_requirement) === 1) {
            setMinimumPurschaseRequirementDefaultValue('NoRequirment')
        }

        setOncePerCustomer(serverData?.product_discount_details.once_per_customer)

        if (serverData.product_discount_details.number_of_use) {
            setNumberOfUse(true)
        }
        setMainObjectDataForAllActions(createMainObjectFormate)
        setCopyMainObjectDataForAllActions(createMainObjectFormate)
        if (serverData.product_discount_details.category_choice === 'category') {
            setSelectedChoice('category')
        }
        if (serverData.product_discount_details.product_choice === 'product') {
            setSelectedChoice('product')
        }
        // #########
        const summaryTempData = [
            {
                category: serverData?.product_discount_details && serverData?.product_discount_details?.category,
                product: serverData?.product_discount_details && serverData?.product_discount_details?.product,
                couponDiscountType:
                    serverData?.product_discount_details && serverData?.product_discount_details?.coupon_discount_type,
                fixValue: serverData?.product_discount_details && serverData?.product_discount_details?.fix_value,
                percentage: serverData?.product_discount_details && serverData?.product_discount_details?.percentage,
                minimumPurchaseReq:
                    serverData?.product_discount_details &&
                    serverData?.product_discount_details?.minimum_purchase_requirement,
                minimumAmount: serverData?.product_discount_details && serverData?.product_discount_details?.min_amount,
                minQuantity: serverData?.product_discount_details && serverData?.product_discount_details?.min_quantity,

                limitOfUsePerCustomer:
                    serverData?.product_discount_details && serverData?.product_discount_details?.number_of_use,
                oncePerCustomer:
                    serverData?.product_discount_details && serverData?.product_discount_details?.once_per_customer,
                startDate: serverData?.start_date,
                endDate: serverData?.end_date,
                startTime: serverData?.start_time,
                endTime: serverData?.end_time,
            },
        ]
        setSummaryData(summaryTempData)
        setSelectedProducts(serverData.product_discount_details.product)
        setSelectedCategory(serverData.product_discount_details.category)
        if (serverData.end_date && serverData.end_date.length > 0) {
            setEnableEndDate(true)
        }
    }

    //! GET call for  coupon
    const findByPageCouponsGetCall = (status) => {
        MarketplaceServices.findAllWithoutPage(couponAPI, { 'coupon-code': couponCode }, false)
            .then(function (res) {
                console.log('res coupons from get call...', res.data.response_body.data)

                if (res.data.response_body.data && res.data.response_body.data.length > 0) {
                    let serverData = res.data.response_body.data[0]
                    setDataForHandleCancelAction(serverData)
                    setHeaderCouponDisplay(serverData?.coupon_code)
                    setCouponStatusFlag(serverData?.status === 0 ? 'InActive' : 'Active')
                    const couponObjectDataFromServer = {
                        coupon_type: serverData.coupon_type,
                        coupon_name: '',
                        coupon_code: {
                            value: serverData.coupon_code,
                            is_error: true,
                            retrieve_from: 'getbycode',
                        },
                        description: '',
                        start_date: serverData.start_date,
                        end_date: serverData.end_date,
                        start_time: serverData.start_time,
                        end_time: serverData.end_time,
                        product_discount_details: {
                            coupon_discount_type: {
                                value:
                                    serverData?.product_discount_details &&
                                    serverData?.product_discount_details?.coupon_discount_type,
                                is_error: true,
                            },
                            fix_value:
                                serverData?.product_discount_details && serverData?.product_discount_details?.fix_value,
                            max_value:
                                serverData?.product_discount_details && serverData?.product_discount_details?.max_value,
                            percentage:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.percentage,
                            min_amount:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.min_amount,
                            category_choice:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.category_choice,
                            product_choice:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.product_choice,
                            minimum_purchase_requirement:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.minimum_purchase_requirement,
                            min_quantity:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.min_quantity,
                            once_per_customer:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.once_per_customer,
                            number_of_use:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.number_of_use,
                            category:
                                serverData?.product_discount_details && serverData?.product_discount_details?.category,
                            product:
                                serverData?.product_discount_details && serverData?.product_discount_details?.product,
                        },
                    }

                    //! handling child inputes
                    if (serverData.product_discount_details && serverData.product_discount_details.min_amount) {
                        setMinimumPurschaseRequirementDefaultValue('PurchaseAmount')
                    } else if (
                        serverData.product_discount_details &&
                        serverData.product_discount_details.min_quantity
                    ) {
                        setMinimumPurschaseRequirementDefaultValue('MinimumQuantityOfItem')
                    }
                    console.log('couponObjectDataFromServerDattaa', couponObjectDataFromServer)
                    if (parseInt(serverData.product_discount_details.minimum_purchase_requirement) === 1) {
                        setMinimumPurschaseRequirementDefaultValue('NoRequirment')
                    }

                    setOncePerCustomer(serverData?.product_discount_details.once_per_customer)

                    if (serverData.product_discount_details.number_of_use) {
                        setNumberOfUse(true)
                    }
                    setMainObjectDataForAllActions(couponObjectDataFromServer)
                    setCopyMainObjectDataForAllActions(couponObjectDataFromServer)
                    if (serverData.product_discount_details.category_choice === 'category') {
                        setSelectedChoice('category')
                    }
                    if (serverData.product_discount_details.product_choice === 'product') {
                        setSelectedChoice('product')
                    }

                    // setSummaryData

                    const summaryTempData = [
                        {
                            category:
                                serverData?.product_discount_details && serverData?.product_discount_details?.category,
                            product:
                                serverData?.product_discount_details && serverData?.product_discount_details?.product,
                            couponDiscountType:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.coupon_discount_type,
                            fixValue:
                                serverData?.product_discount_details && serverData?.product_discount_details?.fix_value,
                            percentage:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.percentage,
                            minimumPurchaseReq:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.minimum_purchase_requirement,
                            minimumAmount:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.min_amount,
                            minQuantity:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.min_quantity,
                            limitOfUsePerCustomer:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.number_of_use,
                            oncePerCustomer:
                                serverData?.product_discount_details &&
                                serverData?.product_discount_details?.once_per_customer,
                            startDate: serverData?.start_date,
                            endDate: serverData?.end_date,
                            startTime: serverData?.start_time,
                            endTime: serverData?.end_time,
                        },
                    ]

                    setSummaryData(summaryTempData)
                    setEditPageLoading(false)
                    setEditPageNetworkError(false)
                    if (serverData.end_date && serverData.end_date.length > 0) {
                        setEnableEndDate(true)
                    }
                    // let obj={}
                }
            })
            .catch((error) => {
                setEditPageLoading(false)
                setEditPageNetworkError(true)
                console.log('Server Error Response from Approvals GET API Call', error.response)
            })
    }

    const generateCoupon = () => {
        setUpdateButton(false)
        setDisableActiveButton(true)
        MarketplaceServices.findAllWithoutPage(generateCouponAPI, null, false)
            .then(function (res) {
                console.log('generate coupon response...', res.data.response_body?.coupon_name)

                let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
                tempMainObjectDataForAllActions.coupon_code.value = res.data.response_body?.coupon_name?.toUpperCase()
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
                setLoadingCreateCoupon(false)
                setInValidCouponCode(false)
            })
            .catch((error) => {
                console.log('Server Error Response from Approvals GET API Call', error.response)
                setLoadingCreateCoupon(false)
            })
    }
    //
    const validatePositiveNumberForInputs = (e, regex) => {
        const key = e.keyCode || e.which
        const keyChar = String.fromCharCode(key)
        // const regex = /[0-9]|\./;
        if (
            !regex.test(keyChar) ||
            parseInt(e.target.value) >= 999999999 ||
            (e.target.value?.toString()).length >= 9 ||
            (keyChar === '.' && e.target.value.includes('.'))
        ) {
            e.preventDefault()
        }
    }
    const validateQuantityForInputs = (e, regex) => {
        const key = e.keyCode || e.which
        const keyChar = String.fromCharCode(key)
        // const regex = /[0-9]|\./;
        if (
            !regex.test(keyChar) ||
            parseInt(e.target.value) >= 10000 ||
            (keyChar === '.' && e.target.value.includes('.'))
        ) {
            e.preventDefault()
        }
    }
    const handleCouponCodeChange = (e) => {
        const value = e
        const isValidExpression = /^[a-zA-Z0-9]+$/.test(value)
        //! Update state only if input is valid
        if (isValidExpression || value.length === 0) {
            if (value.length <= 20) {
                let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
                tempMainObjectDataForAllActions.coupon_code.value = e?.toUpperCase()
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
                setUpdateButton(false)
                setDisableActiveButton(true)
            }
        }
        setInValidCouponCode(false)
    }

    const minAmountChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.min_amount = e
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const minQuantityChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.min_quantity = e
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const limitNumberOfUseChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.number_of_use = e
        // tempMainObjectDataForAllActions.product_discount_details.once_per_customer = false;
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const oncePerCustomerChange = (e) => {
        console.log('checkCheckboxex', e)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.once_per_customer = e.target.checked
        setOncePerCustomer(e.target.checked)
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }
    const handleNumberOfUseChange = (e) => {
        setNumberOfUse(e.target.checked)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidLimitUse(false)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        if (action === 'add' || (action === 'edit' && numberOfUse)) {
            if (action === 'edit' && numberOfUse) {
                tempMainObjectDataForAllActions.product_discount_details.number_of_use = 0
            } else {
                tempMainObjectDataForAllActions.product_discount_details.number_of_use = null
            }
        } else {
            tempMainObjectDataForAllActions.product_discount_details.number_of_use =
                copyMainObjectDataForAllActions.product_discount_details.number_of_use
        }
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
    }

    const handleStartDateChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.start_date = e
        if (
            new Date(tempMainObjectDataForAllActions?.end_date) < new Date(tempMainObjectDataForAllActions.start_date)
        ) {
            tempMainObjectDataForAllActions.end_date = ''
        }
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setStartDate(e)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidStartDate(false)
        setDateValidationMsg(false)
    }
    const handleEndDateChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.end_date = e
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidEndDate(false)
        setDateValidationMsg(false)
    }

    const handleStartTimeChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.start_time = e
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidStartTime(false)
        setTimeValidationMsg(false)
    }

    const handleEndTimeChange = (e) => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.end_time = e
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidEndTime(false)
        setTimeValidationMsg(false)
    }

    const addProductModalCloseHandler = () => {
        setAddProductModalVisible(false)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setTriggerUseEffect(triggerUseEffect + 1)
        let temp = []
        for (var i = 0; i < filteredDataBasedOnSelectedProduct.length; i++) {
            temp.push(filteredDataBasedOnSelectedProduct[i].product_id)
        }
        setSelectedProducts(temp)
    }
    const addProductModalOkHandler = () => {
        setAddProductModalVisible(false)
        setInValidProductValue(false)
        const filteredObjects = allPublishedProductsForList.filter(
            (obj) => selectedProducts && selectedProducts.includes(obj.product_id)
        )
        setFilteredDataBasedOnSelectedProduct(filteredObjects)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.product = selectedProducts
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const addCategoryModalOkHandler = () => {
        setAddCategoryModalVisible(false)
        setInValidCategoryValue(false)
        const filteredObjects = allCategoryForList.filter(
            (obj) => selectedCategory && selectedCategory.includes(obj.id)
        )
        setFilteredDataBasedOnSelectedCategory(filteredObjects)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.category = selectedCategory
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const addCategoryModalCloseHandler = () => {
        setAddCategoryModalVisible(false)
        setUpdateButton(false)
        setDisableActiveButton(true)
        setTriggerUseEffect(triggerUseEffect + 1)

        let temp = []
        for (var i = 0; i < filteredDataBasedOnSelectedCategory.length; i++) {
            temp.push(filteredDataBasedOnSelectedCategory[i].id)
        }
        setSelectedCategory(temp)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.category = temp
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
    }
    const handlePurchaseRequirementsChange = (e) => {
        setUpdateButton(false)
        setDisableActiveButton(true)
        setInValidQuantity(false)
        setInValidAmount(false)
        setMinimumPurschaseRequirementDefaultValue(e.target.value)
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.minimum_purchase_requirement =
            e.target.value === 'NoRequirment'
                ? 1
                : e.target.value === 'PurchaseAmount'
                  ? 2
                  : e.target.value === 'MinimumQuantityOfItem'
                    ? 3
                    : 1
        if (action === 'add') {
            tempMainObjectDataForAllActions.product_discount_details.min_amount = null
            tempMainObjectDataForAllActions.product_discount_details.min_quantity = null
        } else {
            tempMainObjectDataForAllActions.product_discount_details.min_amount =
                copyMainObjectDataForAllActions.product_discount_details.min_amount
            tempMainObjectDataForAllActions.product_discount_details.min_quantity =
                copyMainObjectDataForAllActions.product_discount_details.min_quantity
        }
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
    }
    const handleSelectedProductChange = (productId) => {
        setFilteredDataBasedOnSelectedProduct(
            filteredDataBasedOnSelectedProduct &&
                filteredDataBasedOnSelectedProduct.length > 0 &&
                filteredDataBasedOnSelectedProduct.filter((ele) => parseInt(ele.product_id) !== parseInt(productId))
        )

        setSelectedProducts(
            selectedProducts &&
                selectedProducts.length > 0 &&
                selectedProducts.filter((val) => parseInt(val) !== parseInt(productId))
        )
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.product =
            selectedProducts &&
            selectedProducts.length > 0 &&
            selectedProducts.filter((val) => parseInt(val) !== parseInt(productId))
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)

        setUpdateButton(false)
        setDisableActiveButton(true)
    }

    const handleSelectedCategoryChange = (categoryId) => {
        setFilteredDataBasedOnSelectedCategory(
            filteredDataBasedOnSelectedCategory &&
                filteredDataBasedOnSelectedCategory.length > 0 &&
                filteredDataBasedOnSelectedCategory.filter((ele) => parseInt(ele.id) !== parseInt(categoryId))
        )

        setSelectedCategory(
            selectedCategory &&
                selectedCategory.length > 0 &&
                selectedCategory.filter((val) => parseInt(val) !== parseInt(categoryId))
        )
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        tempMainObjectDataForAllActions.product_discount_details.category =
            selectedCategory &&
            selectedCategory.length > 0 &&
            selectedCategory.filter((val) => parseInt(val) !== parseInt(categoryId))
        setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        setUpdateButton(false)
        setDisableActiveButton(true)
    }
    const findAllPublishedProducts = () => {
        var obj = {
            is_bundled: false,
            'page-number': -1,
            'free-product': false,
        }

        MarketplaceServices.findAll(listProductsAPI, obj, true)
            .then(function (response) {
                console.log(
                    'server response from findAllPublishedProducts API call is:',
                    listProductsAPI,
                    response.data
                )
                if (response.data.response_body.product_data) {
                    setAllPublishedProductsForList(response.data.response_body.product_data)

                    const filteredObjects = response.data.response_body.product_data.filter(
                        (obj) => selectedProducts && selectedProducts.includes(obj.product_id)
                    )

                    setFilteredDataBasedOnSelectedProduct(filteredObjects)

                    console.log('filteredObjectss', filteredObjects)
                }
            })
            .catch(function (error) {
                // setIsLoadingProducts(false);
                console.log(
                    'Error server response from findAllPublishedProducts API call is: ',
                    listProductsAPI,
                    error.response
                )
            })
    }

    const findAllCategory = () => {
        MarketplaceServices.findAll(categoryAPI, null, true)
            .then(function (response) {
                console.log(
                    'server response from findallCategory API call is:',
                    categoryAPI,
                    response.data.response_body
                )
                var tempData = []
                if (response.data.response_body.category_data) {
                    let serverData = response.data.response_body
                    for (let i = 0; i < serverData.category_data.length; i++) {
                        const category = serverData.category_data[i]

                        if (category.children_category && category.children_category.length > 0) {
                            for (let j = 0; j < category.children_category.length; j++) {
                                serverData.category_data.push(category.children_category[j])
                            }
                            delete category.children_category
                        }
                    }
                    if (serverData) {
                        setAllCategoryForList(serverData.category_data)
                        const filteredObjects =
                            serverData.category_data.length > 0 &&
                            serverData.category_data.filter(
                                (obj) => selectedCategory && selectedCategory.includes(obj.id)
                            )

                        setFilteredDataBasedOnSelectedCategory(filteredObjects)
                    }
                }
            })
            .catch(function (error) {
                console.log('Error server response from findallCategory API call is: ', categoryAPI, error.response)
            })
    }
    useEffect(() => {
        if (action === 'edit') {
            setEditPageLoading(true)
            findByPageCouponsGetCall()
        }
        findAllPublishedProducts()
        findAllCategory()
        window.scrollTo(0, 0)
    }, [couponCode])
    //! products

    useEffect(() => {
        if (action === 'edit') {
            if (allPublishedProductsForList && allPublishedProductsForList.length > 0 && mainObjectDataForAllActions) {
                const filteredObjects = allPublishedProductsForList.filter((obj) =>
                    mainObjectDataForAllActions?.product_discount_details?.product?.includes(obj.product_id)
                )
                setFilteredDataBasedOnSelectedProduct(filteredObjects)
                setSelectedProducts(mainObjectDataForAllActions?.product_discount_details?.product)
            }
        }
    }, [allPublishedProductsForList, mainObjectDataForAllActions])
    //! category
    useEffect(() => {
        if (action === 'edit') {
            if (allCategoryForList && allCategoryForList.length > 0 && mainObjectDataForAllActions) {
                const filteredObjects = allCategoryForList.filter((obj) =>
                    mainObjectDataForAllActions?.product_discount_details?.category?.includes(obj.id)
                )
                setFilteredDataBasedOnSelectedCategory(filteredObjects)
                setSelectedCategory(mainObjectDataForAllActions?.product_discount_details?.category)
            }
        }
    }, [allCategoryForList, mainObjectDataForAllActions])

    useEffect(() => {
        const emptyCouponObject = {
            coupon_type: 1,
            coupon_name: '',
            coupon_code: {
                value: '',
                is_error: true,
                retrieve_from: 'get',
            },
            description: '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            product_discount_details: {
                coupon_discount_type: {
                    value: 1,
                    is_error: true,
                },
                fix_value: '',
                max_value: '',
                percentage: '',
                min_amount: '',
                category_choice: '',
                product_choice: 'product',
                minimum_purchase_requirement: '',
                min_quantity: '',
                once_per_customer: false,
                number_of_use: '',
                category: [],
                product: [],
            },
        }
        setMainObjectDataForAllActions(emptyCouponObject)
    }, [])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    //! PUT call for update coupon the status
    const updateCouponStatus = () => {
        setStatusChangeSpin(true)

        MarketplaceServices.update(
            updateCouponStatusAPI,
            {
                status: couponStatusFlag === 'InActive' ? 1 : 0,
            },
            { coupon_id: couponId }
        )
            .then(function (response) {
                console.log('response from update status API###--->', response.data)

                setSearchParams({
                    a: action,
                    id: couponId,
                    code: couponCode,
                    status: couponStatusFlag === 'InActive' ? 'Active' : 'InActive',
                    store: MarketplaceAppConfig.getStore('onlyClient'),
                })
                setCouponStatusFlag(couponStatusFlag === 'InActive' ? 'Active' : 'InActive')
                MarketplaceToaster.showToast(response)
                setStatusChangeSpin(false)

                setVendorStatusModalOpen(false)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                setStatusChangeSpin(false)
                setVendorStatusModalOpen(false)
            })
    }

    const couponSuspension = () => {
        setSuspensionSpin(true)
        MarketplaceServices.remove(couponAPI, { 'coupon-id': parseInt(couponId) })
            .then(function (response) {
                console.log('response from update status API###--->', response.data)
                MarketplaceToaster.showToast(response)
                setSuspensionSpin(false)
                setVendorSuspensionModalOpen(false)

                navigate(`/dashboard/coupons/list-coupons?tab=3&page=1&limit=20${MarketplaceAppConfig.getStore('')}`)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                setSuspensionSpin(false)
                setVendorSuspensionModalOpen(false)
            })
    }

    const handleWarningModalSaveFn = () => {
        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        setInValidProductValue(false)
        setInValidCategoryValue(false)
        if (selectedChoice == 'category') {
            if (action == 'add') {
                setSelectedProducts([])
                setFilteredDataBasedOnSelectedProduct([])
                tempMainObjectDataForAllActions.product_discount_details.product_choice = ''
                tempMainObjectDataForAllActions.product_discount_details.product = []
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
            } else {
                tempMainObjectDataForAllActions.product_discount_details.product_choice =
                    copyMainObjectDataForAllActions.product_discount_details.product_choice
                tempMainObjectDataForAllActions.product_discount_details.product =
                    copyMainObjectDataForAllActions.product_discount_details.product
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
                if (
                    allPublishedProductsForList &&
                    allPublishedProductsForList.length > 0 &&
                    copyMainObjectDataForAllActions
                ) {
                    const filteredObjects = allPublishedProductsForList.filter((obj) =>
                        copyMainObjectDataForAllActions?.product_discount_details?.product?.includes(obj.product_id)
                    )
                    setFilteredDataBasedOnSelectedProduct(filteredObjects)
                    setSelectedProducts(mainObjectDataForAllActions?.product_discount_details?.product)
                }
            }
        } else {
            if (action == 'add') {
                setSelectedCategory([])
                setFilteredDataBasedOnSelectedCategory([])
                tempMainObjectDataForAllActions.product_discount_details.category_choice = ''
                tempMainObjectDataForAllActions.product_discount_details.category = []
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
            } else {
                tempMainObjectDataForAllActions.product_discount_details.category_choice =
                    copyMainObjectDataForAllActions.product_discount_details.category_choice
                tempMainObjectDataForAllActions.product_discount_details.category =
                    copyMainObjectDataForAllActions.product_discount_details.category
                setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
                if (allCategoryForList && allCategoryForList.length > 0 && copyMainObjectDataForAllActions) {
                    const filteredObjects = allCategoryForList.filter((obj) =>
                        copyMainObjectDataForAllActions?.product_discount_details?.category?.includes(obj.id)
                    )
                    setFilteredDataBasedOnSelectedCategory(filteredObjects)
                    setSelectedCategory(mainObjectDataForAllActions?.product_discount_details?.category)
                }
            }
        }
        if (selectedChoice === 'category') {
            setSelectedChoice('product')
        } else {
            setSelectedChoice('category')
        }
        setShowModal(false)
    }

    const handleWarningModalCancelFn = () => {
        setShowModal(false)
    }

    const handleEnableDate = (value) => {
        console.log('value--------->', value)
        setEnableEndDate(value)
        setDateValidationMsg(false)
        setTimeValidationMsg(false)
        setInValidEndTime(false)
        setInValidEndDate(false)
        setUpdateButton(false)
        setDisableActiveButton(true)

        let tempMainObjectDataForAllActions = deepCopy(mainObjectDataForAllActions)
        if (action == 'add') {
            tempMainObjectDataForAllActions.end_date = null
            tempMainObjectDataForAllActions.end_time = null
            setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        } else {
            if (value == false) {
                tempMainObjectDataForAllActions.end_date = null
                tempMainObjectDataForAllActions.end_time = null
            } else {
                tempMainObjectDataForAllActions.end_date = copyMainObjectDataForAllActions.end_date
                tempMainObjectDataForAllActions.end_time = copyMainObjectDataForAllActions.end_time
            }

            setMainObjectDataForAllActions(tempMainObjectDataForAllActions)
        }
    }
    console.log('disableActiveButton----->', disableActiveButton)

    return (
        <Content className=''>
            <StoreModal
                title={
                    couponStatusFlag === 'InActive'
                        ? t('coupons:discount_activation')
                        : t('coupons:discount_deactivation')
                }
                isSpin={statusChangeSpin}
                okCallback={() => {
                    updateCouponStatus()
                }}
                isVisible={vendorStatusModalOpen}
                okButtonText={t('common:yes')}
                cancelButtonText={t('common:cancel')}
                cancelCallback={() => {
                    setVendorStatusModalOpen(false)
                }}>
                <Content>
                    {couponStatusFlag === 'InActive' ? (
                        <div>
                            {/* <p>{t('coupons:coupon_activation_confirmation')}</p> */}
                            <p className='!text-brandGray1'>{t('coupons:coupon_activate_message')}</p>
                        </div>
                    ) : (
                        <div>
                            {/* <p>{t('coupons:coupon_deactivation_confirmation')}</p> */}
                            <p className='!text-brandGray1'>{t('coupons:coupon_deactivate_message')}</p>
                        </div>
                    )}
                </Content>
            </StoreModal>
            <StoreModal
                title={t('coupons:coupon_suspension')}
                isSpin={suspensionSpin}
                okCallback={() => {
                    couponSuspension()
                }}
                isVisible={vendorSuspensionModalOpen}
                okButtonText={t('common:yes')}
                cancelButtonText={t('common:cancel')}
                cancelCallback={() => {
                    setVendorSuspensionModalOpen(false)
                }}>
                <Content>
                    <div>
                        {/* <p>{t('coupons:coupon_suspension_confirmation')}</p> */}
                        <p className='!text-brandGray1'>{t('coupons:coupon_suspension_message')}</p>
                    </div>
                </Content>
            </StoreModal>
            <HeaderForTitle
                showArrowIcon={true}
                backNavigationPath={`/dashboard/coupons/list-coupons?tab=3&page=1&limit=20${MarketplaceAppConfig.getStore(
                    ''
                )}`}
                title={
                    <Content>
                        <Content className='flex !justify-around '>
                            <Content className='flex self-center space-x-2 !min-h-[40px]'>
                                {/* <img src={AvatarImg} alt='discountImg' width={35} height={35} className='!mb-2' /> */}
                                <Tooltip
                                    title={undefined}
                                    overlayStyle={{ zIndex: 11, position: 'fixed' }}
                                    placement='bottom'>
                                    <Title
                                        level={3}
                                        className={`!font-semibold !text-regal-blue !text-[24px] max-w-[800px] ${
                                            langDirection === 'rtl' ? 'mx-2' : null
                                        } `}
                                        ellipsis>
                                        {action === 'edit' ? headerCouponDisplay : t('coupons:create_coupon')}
                                    </Title>
                                </Tooltip>
                            </Content>
                            <Content className='flex  self-center  !justify-end '>
                                {action !== 'add' ? (
                                    <Row className='flex gap-2'>
                                        <Button
                                            disabled={disableActiveButton}
                                            className='app-btn-secondary'
                                            onClick={() => {
                                                if (vendorStatusModalOpen === false) {
                                                    setVendorStatusModalOpen(true)
                                                }
                                                // setVendorSelected(record.id);
                                                // setSelectedStatus(record.vendor_status);
                                            }}>
                                            {' '}
                                            {couponStatusFlag === 'Active'
                                                ? t('common:deactivate')
                                                : t('coupons:activate')}
                                        </Button>

                                        <Col>
                                            <Button
                                                type='primary'
                                                danger
                                                className=''
                                                onClick={() => setVendorSuspensionModalOpen(true)}>
                                                {t('common:delete')}
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null}
                                {console.log('couponStatusFlagcouponStatusFlag', couponStatusFlag)}
                                {(couponStatusFlag === 'InActive' || action === 'add') && (
                                    <div>
                                        <Button
                                            className='mx-2 app-btn-secondary'
                                            disabled={updateButton}
                                            onClick={() => {
                                                action === 'add'
                                                    ? navigate(
                                                          `/dashboard/coupons/list-coupons?tab=3&page=1&limit=20${MarketplaceAppConfig.getStore(
                                                              ''
                                                          )}`
                                                      )
                                                    : dataProcessorForSettingDataToMainObjectBySuccessResponse(
                                                          dataForHandleCancelAction
                                                      )
                                                setInValidAmount(false)
                                                setInValidLimitUse(false)

                                                setUpdateButton(true)
                                                setDisableActiveButton(false)
                                            }}>
                                            {t('common:cancel')}
                                        </Button>
                                        <Button
                                            onClick={() => couponDataValidationForPostAndPut()}
                                            //className="app-btn-primary opacity-50"
                                            className={'app-btn-primary'}
                                            disabled={updateButton}>
                                            {action === 'add' || duplicateCouponFlag === 'true'
                                                ? t('common:save')
                                                : t('common:update')}
                                        </Button>
                                    </div>
                                )}
                            </Content>
                        </Content>
                    </Content>
                }
                showButtons={false}
                // headerContent={<Content className="flex"></Content>}
                type={'productType'}
            />
            <Content className='!p-4 '>
                {editPageLoading ? (
                    <Content className=' bg-white p-3 !mt-[70px] mx-3'>
                        <Skeleton />
                    </Content>
                ) : editPageNetworkError ? (
                    <Content className='text-center  bg-white p-3 !mt-[70px] mx-3'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : (
                    <Content className='flex space-x-4 mt-4'>
                        <Content className='!w-[55%]'>
                            <Spin
                                tip='Please wait!'
                                size='large'
                                // spinning={action === "edit" && isLoadingProductTemplatesById}
                                spinning={isUpLoading}>
                                {mainObjectDataForAllActions && (
                                    <>
                                        <Content className='mx-2 !min-h-screen !mt-[4.3rem]'>
                                            <Content className='!space-y-5'>
                                                <Content className='bg-white p-3 rounded-lg shadow-md '>
                                                    <Content className='flex items-baseline space-x-1'>
                                                        <Text className='!text-regal-blue font-semibold text-[18px]'>
                                                            {t('coupons:amount_off_products')}
                                                        </Text>
                                                        {/* <Typography
                                                            className={`${
                                                                langDirection === 'rtl' ? 'mx-1' : null
                                                            } text-gray-400`}>
                                                            {t('coupons:product_discount')}
                                                        </Typography> */}
                                                    </Content>
                                                    <Divider className='mt-3' />
                                                    <Content>
                                                        <Title
                                                            level={5}
                                                            className='!text-regal-blue font-medium text-[16px]'>
                                                            {t('coupons:discount_code')}
                                                        </Title>
                                                        <Typography className='mt-1 font-normal text-[14px] !text-brandGray1'>
                                                            {t('coupons:customer_must_enter_this_code_at_checkout')}
                                                        </Typography>
                                                    </Content>
                                                    <Content className='mt-4 w-96'>
                                                        <Spin spinning={loadingCreateCoupon} className='text-center'>
                                                            <Content className='flex justify-between'>
                                                                <Typography className='mt-1 font-normal text-[14px] text-brandGray2'>
                                                                    {t('coupons:product_discount_code')}
                                                                    <span className='mandatory-symbol-color text-sm !mx-2'>
                                                                        *
                                                                    </span>
                                                                </Typography>

                                                                <Button
                                                                    type='link'
                                                                    className='app-btn-link text-[14px] font-normal'
                                                                    onClick={() => {
                                                                        generateCoupon()
                                                                        setLoadingCreateCoupon(true)
                                                                    }}
                                                                    disabled={couponStatus === 'Active' ? true : false}>
                                                                    {t('coupons:generate_random_code')}
                                                                </Button>
                                                            </Content>
                                                            <Content className='mt-2'>
                                                                <Input
                                                                    placeholder={t('coupons:discount_code_placeholder')}
                                                                    disabled={couponStatus === 'Active' ? true : false}
                                                                    className={`w-96 ${
                                                                        inValidCouponCode
                                                                            ? 'border-red-500 border-[1px]  border-solid focus:border-red-500 hover:border-red-500'
                                                                            : '  border-solid border-[#C6C6C6]'
                                                                    }`}
                                                                    value={
                                                                        mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions?.coupon_code?.value
                                                                    }
                                                                    // onChange={(e) =>
                                                                    //   createDiscountHandler(e.target.value, "coupon_code")
                                                                    // }
                                                                    min={discountCodeMinLength}
                                                                    max={discountCodeMaxLength}
                                                                    onChange={(e) =>
                                                                        handleCouponCodeChange(e.target.value)
                                                                    }
                                                                    onBlur={(e) => {
                                                                        let copyDetails = {
                                                                            ...mainObjectDataForAllActions,
                                                                        }
                                                                        copyDetails.coupon_code.value = e.target.value
                                                                            .trim()
                                                                            .replace(/\s+/g, '')
                                                                        setMainObjectDataForAllActions(copyDetails)
                                                                    }}></Input>
                                                                {mainObjectDataForAllActions &&
                                                                    mainObjectDataForAllActions?.coupon_code?.value &&
                                                                    mainObjectDataForAllActions?.coupon_code?.value
                                                                        .length < discountCodeMinLength && (
                                                                        <div style={{ color: 'red' }}>
                                                                            {t(
                                                                                'coupons:please_enter_code_with_minimum_length_of_characters'
                                                                            )}
                                                                        </div>
                                                                    )}
                                                            </Content>
                                                        </Spin>
                                                    </Content>
                                                </Content>
                                                <Content className='bg-white p-3 rounded-lg shadow-md '>
                                                    <Content className='flex '>
                                                        <Title
                                                            level={4}
                                                            className='!text-regal-blue font-semibold text-[18px]'>
                                                            {' '}
                                                            {t('coupons:value')}
                                                        </Title>
                                                    </Content>
                                                    <Divider className='mt-1' />
                                                    <Content className=' '>
                                                        {/* testt */}
                                                        <Content className='flex'>
                                                            <Content className=''>
                                                                <Typography className='mt-1 font-normal text-[14px] text-brandGray2'>
                                                                    {t('coupons:value_type')}
                                                                </Typography>
                                                                <Radio.Group
                                                                    className='my-2 w-[87%]'
                                                                    disabled={couponStatus === 'Active'}
                                                                    value={
                                                                        mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions
                                                                            .product_discount_details
                                                                            .coupon_discount_type.value
                                                                    }
                                                                    onChange={(e) => {
                                                                        const value = e.target.value
                                                                        setInValidPercentageValue(false)
                                                                        setInValidFixedAmountValue(false)
                                                                        let tempMainObjectDataForAllActions =
                                                                            deepCopy(mainObjectDataForAllActions)
                                                                        if (action === 'add') {
                                                                            tempMainObjectDataForAllActions.product_discount_details.coupon_discount_type.value =
                                                                                value
                                                                            tempMainObjectDataForAllActions.product_discount_details.percentage =
                                                                                null
                                                                            tempMainObjectDataForAllActions.product_discount_details.fix_value =
                                                                                null
                                                                            setMainObjectDataForAllActions(
                                                                                tempMainObjectDataForAllActions
                                                                            )
                                                                        } else {
                                                                            tempMainObjectDataForAllActions.product_discount_details.coupon_discount_type.value =
                                                                                value
                                                                            tempMainObjectDataForAllActions.product_discount_details.percentage =
                                                                                copyMainObjectDataForAllActions.product_discount_details.percentage
                                                                            tempMainObjectDataForAllActions.product_discount_details.fix_value =
                                                                                copyMainObjectDataForAllActions.product_discount_details.fix_value
                                                                            setMainObjectDataForAllActions(
                                                                                tempMainObjectDataForAllActions
                                                                            )
                                                                        }
                                                                    }}>
                                                                    <Radio.Button value={1}>
                                                                        {t('coupons:fixed_amount')}
                                                                    </Radio.Button>
                                                                    <Radio.Button value={2}>
                                                                        {t('coupons:percentage')}
                                                                    </Radio.Button>
                                                                </Radio.Group>
                                                            </Content>
                                                            <Content className=''>
                                                                <Typography className='mt-1 font-normal text-[14px] text-brandGray2'>
                                                                    {t('coupons:discount_value')}
                                                                    <span className='mandatory-symbol-color text-sm !mx-2'>
                                                                        *
                                                                    </span>
                                                                </Typography>
                                                                {getOfferPercentageControl(
                                                                    mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions
                                                                            .product_discount_details
                                                                            .coupon_discount_type.value
                                                                )}
                                                            </Content>
                                                        </Content>
                                                    </Content>
                                                    <Content className='!space-y-3'>
                                                        <Typography className='mt-1 font-normal text-[14px] text-brandGray2'>
                                                            {t('coupons:applies_to')}
                                                            <span className='mandatory-symbol-color text-sm !mx-2'>
                                                                *
                                                            </span>
                                                        </Typography>

                                                        <Radio.Group
                                                            value={selectedChoice}
                                                            onChange={(e) => {
                                                                // setSelectedChoice(e.target.value)
                                                                setShowModal(true)
                                                                // setInValidCategoryValue(false)
                                                                // setInValidProductValue(false)
                                                            }}
                                                            disabled={couponStatus === 'Active' ? true : false}>
                                                            <Space direction='vertical'>
                                                                <Radio
                                                                    value={'product'}
                                                                    className='border-solid font-normal text-[14px] text-brandGray1'>
                                                                    {t('coupons:specific_products')}{' '}
                                                                </Radio>
                                                                <Radio
                                                                    value={'category'}
                                                                    className='border-solid font-normal text-[14px] text-brandGray1'>
                                                                    {t('coupons:specific_categories')}
                                                                </Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                        <>
                                                            {inValidProductValue ? (
                                                                <Alert
                                                                    message={
                                                                        <h5 className='font-bold'>
                                                                            {t('coupons:missing_product_selection')}
                                                                        </h5>
                                                                    }
                                                                    description={
                                                                        <div className=''>
                                                                            <p>
                                                                                {t(
                                                                                    'coupons:at_least_one_product_need_to_select'
                                                                                )}
                                                                            </p>
                                                                            <p>
                                                                                {t(
                                                                                    'coupons:please_use_the_browse_products_button'
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    type='error'
                                                                    showIcon
                                                                />
                                                            ) : inValidCategoryValue ? (
                                                                <Alert
                                                                    message={
                                                                        <h5 className='font-bold'>
                                                                            {t('coupons:missing_category_selection')}
                                                                        </h5>
                                                                    }
                                                                    description={
                                                                        <div className=''>
                                                                            <p>
                                                                                {t(
                                                                                    'coupons:at_least_one_category_need_to_select'
                                                                                )}
                                                                            </p>
                                                                            <p>
                                                                                {t(
                                                                                    'coupons:please_use_the_browse_category_button'
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    type='error'
                                                                    showIcon
                                                                />
                                                            ) : null}
                                                        </>
                                                    </Content>
                                                    <Content className=' flex mt-4 justify-between '>
                                                        <Text className='!text-regal-blue font-semibold text-[18px]'>
                                                            {selectedChoice === 'category'
                                                                ? t('coupons:selected_categories')
                                                                : t('coupons:selected_products')}
                                                        </Text>

                                                        <Button
                                                            onClick={
                                                                selectedChoice === 'category'
                                                                    ? () => {
                                                                          setAddCategoryModalVisible(true)
                                                                          setSaveButtonDisabledForModal(true)
                                                                      }
                                                                    : () => {
                                                                          setAddProductModalVisible(true)
                                                                          setSaveButtonDisabledForModal(true)
                                                                      }
                                                            }
                                                            disabled={couponStatus === 'Active' ? true : false}
                                                            className={`${langDirection === 'rtl' ? '' : 'font-medium text-[14px] text-regal-blue'}`}>
                                                            {selectedChoice === 'category'
                                                                ? t('coupons:browse_categories')
                                                                : t('coupons:browse_products')}
                                                        </Button>
                                                    </Content>
                                                    <Content className='!my-6'>
                                                        {selectedChoice === 'category' ? (
                                                            <Table
                                                                // rowSelection={{
                                                                //   selectedRowKeys: selectedProducts,
                                                                //   preserveSelectedRowKeys: true,
                                                                //   onChange: onRowSelectChange,
                                                                // }}
                                                                // loading={isLoadingProducts}
                                                                dataSource={filteredDataBasedOnSelectedCategory}
                                                                columns={columnsForCategory}
                                                                scroll={{}}
                                                                pagination={false}
                                                                disabled={couponStatus === 'Active' ? true : false}
                                                                locale={{
                                                                    emptyText: `${t('coupons:no_category_has_been_linked')}`,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Table
                                                                // rowSelection={{
                                                                //   selectedRowKeys: selectedProducts,
                                                                //   preserveSelectedRowKeys: true,
                                                                //   onChange: onRowSelectChange,
                                                                // }}
                                                                // loading={isLoadingProducts}
                                                                dataSource={filteredDataBasedOnSelectedProduct}
                                                                columns={columnsForProduct}
                                                                scroll={{}}
                                                                pagination={false}
                                                                disabled={couponStatus === 'Active' ? true : false}
                                                                locale={{
                                                                    emptyText: `${t('coupons:no_product_has_been_linked')}`,
                                                                }}
                                                            />
                                                        )}
                                                    </Content>
                                                </Content>
                                                {/* Minimum purchase requirements */}
                                                <Content className='bg-white p-3 rounded-lg shadow-md '>
                                                    <Title
                                                        level={4}
                                                        className='!text-regal-blue font-semibold text-[18px]'>
                                                        {t('coupons:minimum_purchase_requirements')}
                                                    </Title>
                                                    <Divider className='mt-1' />
                                                    <Content>
                                                        {/* testt10 */}
                                                        <Radio.Group
                                                            value={minimumPurschaseRequirementDefaultValue}
                                                            // onChange={(e) =>
                                                            //   setMinimumPurschaseRequirementDefaultValue(
                                                            //     e.target.value
                                                            //   )
                                                            // }
                                                            onChange={(e) => handlePurchaseRequirementsChange(e)}
                                                            disabled={couponStatus === 'Active' ? true : false}>
                                                            <Space direction='vertical'>
                                                                <Radio
                                                                    value={'NoRequirment'}
                                                                    className='text-[14px] text-brandGray1 font-normal'>
                                                                    {t('coupons:no_minimum_requirements')}
                                                                </Radio>
                                                                <Radio
                                                                    value={'PurchaseAmount'}
                                                                    className='text-[14px] text-brandGray1 font-normal'>
                                                                    {t('coupons:minimum_purchase_amount')}{' '}
                                                                    {/* {currencySymbolFromRedux} */}
                                                                </Radio>
                                                                {minimumPurschaseRequirementDefaultValue ===
                                                                'PurchaseAmount' ? (
                                                                    <Content className='!px-6'>
                                                                        <Typography className='mb-2 text-brandGray2 text-[14px] font-normal '>
                                                                            {t('coupons:enter_minimum_amount')}
                                                                        </Typography>
                                                                        {/* onClick={() => {
                  generateCoupon();
                }} */}
                                                                        <InputNumber
                                                                            className={`!w-36`}
                                                                            addonBefore={currencySymbolFromRedux}
                                                                            value={
                                                                                mainObjectDataForAllActions &&
                                                                                mainObjectDataForAllActions
                                                                                    ?.product_discount_details
                                                                                    .min_amount
                                                                            }
                                                                            // onChange={minAmountChange}
                                                                            status={
                                                                                inValidAmount === true ? 'error' : ''
                                                                            }
                                                                            disabled={
                                                                                couponStatus === 'Active' ? true : false
                                                                            }
                                                                            // min={0}
                                                                            // max={2147483647}
                                                                            // onKeyPress={(e) => {
                                                                            //     validatePositiveNumberForInputs(
                                                                            //         e,
                                                                            //         /[0-9]/
                                                                            //     )
                                                                            // }}
                                                                            maxLength={purchaseAmtMaxValue?.length}
                                                                            min={purchaseAmtMinValue}
                                                                            max={maxStepValue}
                                                                            onKeyPress={(e) => {
                                                                                if (
                                                                                    !/[0-9\.]/.test(e.key) ||
                                                                                    (e.key === '.' &&
                                                                                        e.target.value.includes('.'))
                                                                                ) {
                                                                                    e.preventDefault()
                                                                                }
                                                                            }}
                                                                            onChange={(value) => {
                                                                                if (/[0-9]/.test(value) || !value) {
                                                                                    console.log('value------->', value)
                                                                                    if (
                                                                                        value > purchaseAmtMaxValue ||
                                                                                        value <= 0
                                                                                    ) {
                                                                                        setInValidAmount(true)
                                                                                    } else {
                                                                                        setInValidAmount(false)
                                                                                    }
                                                                                    minAmountChange(value)
                                                                                }
                                                                            }}
                                                                        />
                                                                        {inValidAmount == true ? (
                                                                            <div style={{ color: 'red' }}>
                                                                                {t(
                                                                                    'coupons:please_enter_purchase_amt_range_between',
                                                                                    {
                                                                                        purchaseAmtMinValue,
                                                                                        purchaseAmtMaxValue,
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        ) : null}
                                                                    </Content>
                                                                ) : null}

                                                                <Radio
                                                                    value={'MinimumQuantityOfItem'}
                                                                    className='text-[14px] text-brandGray1 font-normal'>
                                                                    {t('coupons:minimum_quantity_of_items')}
                                                                </Radio>
                                                                {minimumPurschaseRequirementDefaultValue ===
                                                                'MinimumQuantityOfItem' ? (
                                                                    <Content className='!px-6'>
                                                                        <Typography className='mb-2 text-brandGray2 text-[14px] font-normal '>
                                                                            {t('coupons:enter_minimum_quantity')}
                                                                        </Typography>
                                                                        <InputNumber
                                                                            className={`!w-36 `}
                                                                            value={
                                                                                mainObjectDataForAllActions &&
                                                                                mainObjectDataForAllActions
                                                                                    ?.product_discount_details
                                                                                    .min_quantity
                                                                            }
                                                                            // onChange={minQuantityChange}
                                                                            disabled={
                                                                                couponStatus === 'Active' ? true : false
                                                                            }
                                                                            status={
                                                                                inValidQuantity === true ? 'error' : ''
                                                                            }
                                                                            min={minQtyItems}
                                                                            max={maxStepValue}
                                                                            maxLength={maxQtyItems?.length}
                                                                            // min={1}
                                                                            // max={10000}
                                                                            // onKeyPress={(e) => {
                                                                            //     validateQuantityForInputs(e, /[0-9]/)
                                                                            // }}
                                                                            onKeyPress={(e) => {
                                                                                if (!/[0-9]/.test(e.key)) {
                                                                                    e.preventDefault()
                                                                                }
                                                                            }}
                                                                            onChange={(value) => {
                                                                                if (/[0-9]/.test(value) || !value) {
                                                                                    console.log('value------->', value)
                                                                                    if (
                                                                                        value > maxQtyItems ||
                                                                                        value <= 0
                                                                                    ) {
                                                                                        setInValidQuantity(true)
                                                                                    } else {
                                                                                        setInValidQuantity(false)
                                                                                    }
                                                                                    minQuantityChange(value)
                                                                                }
                                                                            }}
                                                                        />
                                                                        {inValidQuantity == true ? (
                                                                            <div style={{ color: 'red' }}>
                                                                                {t(
                                                                                    'coupons:please_enter_quantity_items_range_between',
                                                                                    { minQtyItems, maxQtyItems }
                                                                                )}
                                                                            </div>
                                                                        ) : null}
                                                                    </Content>
                                                                ) : null}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Content>
                                                </Content>

                                                {/* Maximum discount uses */}
                                                <Content className='bg-white p-3 rounded-lg shadow-md '>
                                                    <Title
                                                        level={4}
                                                        className='!text-regal-blue font-semibold text-[18px]'>
                                                        {t('coupons:maximum_discount_uses')}{' '}
                                                    </Title>
                                                    <Divider className='mt-1' />

                                                    <Content className='pb-3'>
                                                        <Checkbox
                                                            className='!text-brandGray1 font-normal text-[14px]'
                                                            checked={numberOfUse}
                                                            onChange={handleNumberOfUseChange}
                                                            disabled={couponStatus === 'Active' ? true : false}>
                                                            {t(
                                                                'coupons:limit_number_of_times_this_discount_can_be_used_in_total'
                                                            )}
                                                        </Checkbox>
                                                        {numberOfUse ? (
                                                            <Content className='!mx-6'>
                                                                <Typography className='mb-2 text-brandGray2 text-[14px] font-normal '>
                                                                    {t('coupons:enter_limit')}
                                                                </Typography>
                                                                <InputNumber
                                                                    className={`!w-36 `}
                                                                    status={inValidLimitUse === true ? 'error' : ''}
                                                                    value={
                                                                        mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions
                                                                            ?.product_discount_details.number_of_use
                                                                    }
                                                                    maxLength={maxNumberTimesDiscountUsed?.length}
                                                                    min={minNumberTimesDiscountUsed}
                                                                    max={maxStepValue}
                                                                    // min={0}
                                                                    // max={99999}
                                                                    // onChange={limitNumberOfUseChange}
                                                                    disabled={couponStatus === 'Active' ? true : false}
                                                                    // onKeyPress={(e) => {
                                                                    //   validatePositiveNumberForInputs(
                                                                    //     e,
                                                                    //     /[0-9]|/
                                                                    //   );
                                                                    // }}
                                                                    // onChange={(value) => {
                                                                    //   if (/[0-9]|/.test(value) || !value) {
                                                                    //     console.log("value", value);
                                                                    //     limitNumberOfUseChange(value);
                                                                    //   }
                                                                    // }}

                                                                    // onKeyPress={(e) => {
                                                                    //     validateQuantityForInputs(e, /[0-9]/)
                                                                    // }}
                                                                    onKeyPress={(e) => {
                                                                        if (!/[0-9]/.test(e.key)) {
                                                                            e.preventDefault()
                                                                        }
                                                                    }}
                                                                    onChange={(value) => {
                                                                        if (/[0-9]/.test(value) || !value) {
                                                                            console.log('value------->', value)
                                                                            if (
                                                                                value > maxNumberTimesDiscountUsed ||
                                                                                value < 0
                                                                            ) {
                                                                                setInValidLimitUse(true)
                                                                            } else {
                                                                                setInValidLimitUse(false)
                                                                            }
                                                                            limitNumberOfUseChange(value)
                                                                        }
                                                                    }}
                                                                />
                                                                {inValidLimitUse == true ? (
                                                                    <div style={{ color: 'red' }}>
                                                                        {t(
                                                                            'coupons:please_enter_total_number_of_times_discount_range',
                                                                            {
                                                                                minNumberTimesDiscountUsed,
                                                                                maxNumberTimesDiscountUsed,
                                                                            }
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                            </Content>
                                                        ) : null}
                                                    </Content>

                                                    <Content className='mt-1'>
                                                        <Checkbox
                                                            // value={
                                                            //   mainObjectDataForAllActions &&
                                                            //   mainObjectDataForAllActions?.product_discount_details
                                                            //     .once_per_customer
                                                            // }
                                                            className='!text-brandGray1 font-normal text-[14px]'
                                                            checked={oncePerCustomer}
                                                            onChange={oncePerCustomerChange}
                                                            disabled={couponStatus === 'Active' ? true : false}>
                                                            {t('coupons:limit_to_one_use_per_customer')}
                                                        </Checkbox>
                                                    </Content>
                                                    {/* </Space>
            </Checkbox.Group> */}
                                                </Content>
                                                {/* testt13 */}
                                                {/* Active dates */}
                                                <Content className='bg-white p-3 rounded-lg shadow-md'>
                                                    <Title
                                                        level={4}
                                                        className='!text-regal-blue flex items-end justify-start gap-2 font-semibold text-lg'>
                                                        {t('coupons:active_dates')}
                                                        <Tag color='orange'>{t('common:gmt')}</Tag>
                                                    </Title>
                                                    <p>{t('coupons:date_and_time_helper_text')}</p>
                                                    <p>{t('coupons:date_and_time_helper_text_example')}</p>
                                                    <Divider className='mt-1' />
                                                    <Content className='flex mb-2'>
                                                        <Content>
                                                            <Typography className='mb-2 text-brandGray2 text-[14px] font-normal'>
                                                                {t('coupons:start_date')}
                                                            </Typography>
                                                            <DatePicker
                                                                // value={
                                                                //   mainObjectDataForAllActions &&
                                                                //   mainObjectDataForAllActions.start_date !== ""
                                                                //     ? dayjs(
                                                                //         mainObjectDataForAllActions &&
                                                                //           mainObjectDataForAllActions?.start_date,
                                                                //         "YYYY-MM-DD"
                                                                //       )
                                                                //     : null
                                                                // }
                                                                value={
                                                                    (mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions?.start_date ===
                                                                            '') ||
                                                                    (mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions.start_date === null)
                                                                        ? null
                                                                        : dayjs(
                                                                              mainObjectDataForAllActions &&
                                                                                  mainObjectDataForAllActions?.start_date,
                                                                              'YYYY-MM-DD'
                                                                          )
                                                                }
                                                                disabled={couponStatus === 'Active' ? true : false}
                                                                disabledDate={disabledDate}
                                                                onChange={(value, timestring) => {
                                                                    handleStartDateChange(timestring)
                                                                }}
                                                                format={'YYYY-MM-DD'}
                                                                // className="w-[70%]"
                                                                className={`!w-[70%] ${
                                                                    inValidStartDate
                                                                        ? 'border-red-500 border-[1px] rounded-lg border-solid focus:border-red-500 hover:border-red-500'
                                                                        : ' border-solid border-[#C6C6C6]'
                                                                }`}
                                                                placeholder={t('common:select_date')}
                                                            />
                                                        </Content>
                                                        <Content>
                                                            <Typography className='mb-2 text-brandGray2 text-[14px] font-normal'>
                                                                {t('coupons:start_time')}
                                                                {/* {'('}
                                                                {timeZone}
                                                                {')'} */}
                                                            </Typography>
                                                            {console.log(
                                                                'starteDate, stardTime , endDate, EndTime',
                                                                inValidStartDate,
                                                                inValidStartTime,
                                                                inValidEndTime,
                                                                inValidEndTime
                                                            )}
                                                            <TimePicker
                                                                // use12Hours
                                                                // value={
                                                                //   mainObjectDataForAllActions &&
                                                                //   mainObjectDataForAllActions.start_time !== ""
                                                                //     ? dayjs(
                                                                //         mainObjectDataForAllActions &&
                                                                //           mainObjectDataForAllActions?.start_time,
                                                                //         "HH:mm:ss"
                                                                //       )
                                                                //     : null
                                                                // }
                                                                value={
                                                                    (mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions.start_time ===
                                                                            '') ||
                                                                    (mainObjectDataForAllActions &&
                                                                        mainObjectDataForAllActions.start_time === null)
                                                                        ? null
                                                                        : dayjs(
                                                                              mainObjectDataForAllActions &&
                                                                                  mainObjectDataForAllActions?.start_time,
                                                                              'HH:mm:ss'
                                                                          )
                                                                }
                                                                onChange={(value, timestring) => {
                                                                    handleStartTimeChange(timestring)
                                                                }}
                                                                disabled={couponStatus === 'Active' ? true : false}
                                                                // className="w-[70%] "

                                                                className={`!w-[70%] ${
                                                                    inValidStartTime
                                                                        ? 'border-red-500 border-[1px] rounded-lg border-solid focus:border-red-500 hover:border-red-500'
                                                                        : ' border-solid border-[#C6C6C6]'
                                                                }`}
                                                                showNow={true}
                                                                placeholder={t('common:select_time')}
                                                            />
                                                        </Content>
                                                    </Content>
                                                    {console.log('checkBoxesDtaInside', enableEndDate)}
                                                    <Checkbox
                                                        className={`${langDirection === 'rtl' ? 'ml-2' : 'mr-2 !mt-3'}`}
                                                        // value={enableEndDate}
                                                        checked={enableEndDate}
                                                        onChange={(e) => handleEnableDate(e.target.checked)}
                                                        disabled={couponStatus === 'Active' ? true : false}></Checkbox>
                                                    <span className='text-brandGray2 text-[14px] font-normal !mt-3'>
                                                        {t('coupons:set_end_date')}{' '}
                                                    </span>
                                                    {enableEndDate ? (
                                                        <Content className='flex my-4'>
                                                            <Content>
                                                                <Typography className='mb-2 text-brandGray2 text-[14px] font-normal'>
                                                                    {t('coupons:end_date')}
                                                                </Typography>
                                                                <DatePicker
                                                                    value={
                                                                        (mainObjectDataForAllActions &&
                                                                            mainObjectDataForAllActions?.end_date ===
                                                                                '') ||
                                                                        (mainObjectDataForAllActions &&
                                                                            mainObjectDataForAllActions.end_date ===
                                                                                null)
                                                                            ? null
                                                                            : dayjs(
                                                                                  mainObjectDataForAllActions &&
                                                                                      mainObjectDataForAllActions?.end_date,
                                                                                  'YYYY-MM-DD'
                                                                              )
                                                                    }
                                                                    onChange={(value, timestring) => {
                                                                        handleEndDateChange(timestring)
                                                                    }}
                                                                    disabled={couponStatus === 'Active' ? true : false}
                                                                    disabledDate={disabledEndDate}
                                                                    format={'YYYY-MM-DD'}
                                                                    // className="w-[70%]"
                                                                    className={`!w-[70%] ${
                                                                        inValidEndDate
                                                                            ? 'border-red-500 border-[1px] rounded-lg border-solid focus:border-red-500 hover:border-red-500'
                                                                            : ' border-solid border-[#C6C6C6]'
                                                                    }`}
                                                                    placeholder={t('common:select_date')}
                                                                />
                                                            </Content>
                                                            <Content>
                                                                <Typography className='mb-2 text-brandGray2 text-[14px] font-normal'>
                                                                    {t('coupons:end_time')}
                                                                    {/* {'('}
                                                                    {timeZone}
                                                                    {')'} */}
                                                                </Typography>
                                                                <TimePicker
                                                                    // use12Hours
                                                                    value={
                                                                        (mainObjectDataForAllActions &&
                                                                            mainObjectDataForAllActions.end_time ===
                                                                                '') ||
                                                                        (mainObjectDataForAllActions &&
                                                                            mainObjectDataForAllActions.end_time ===
                                                                                null)
                                                                            ? null
                                                                            : dayjs(
                                                                                  mainObjectDataForAllActions &&
                                                                                      mainObjectDataForAllActions?.end_time,
                                                                                  'HH:mm:ss'
                                                                              )
                                                                    }
                                                                    onChange={(value, timestring) => {
                                                                        handleEndTimeChange(timestring)
                                                                    }}
                                                                    disabled={couponStatus === 'Active' ? true : false}
                                                                    // className="w-[70%]"
                                                                    className={`!w-[70%] ${
                                                                        inValidEndTime
                                                                            ? 'border-red-500 border-[1px] rounded-lg border-solid focus:border-red-500 hover:border-red-500'
                                                                            : ' border-solid border-[#C6C6C6]'
                                                                    }`}
                                                                    showNow={true}
                                                                    placeholder={t('common:select_time')}
                                                                />
                                                            </Content>
                                                        </Content>
                                                    ) : null}
                                                    <Content>
                                                        <p className='text-red-500'>
                                                            {dateValidationMsg
                                                                ? t('coupons:end_date_and_start_date_validation_msg')
                                                                : null}
                                                        </p>
                                                        <p className='text-red-500'>
                                                            {timeValidationMsg
                                                                ? t('coupons:end_time_and_start_time_validation_msg')
                                                                : null}
                                                        </p>
                                                    </Content>
                                                </Content>
                                                {/* Action section.. */}

                                                {couponStatus === 'InActive' ||
                                                action === 'add' ||
                                                duplicateCouponFlag === 'true' ? (
                                                    <Content>
                                                        <Button
                                                            onClick={() => couponDataValidationForPostAndPut()}
                                                            //className="app-btn-primary opacity-50"
                                                            className={'app-btn-primary mx-2'}
                                                            disabled={updateButton}>
                                                            {/* // !This Button Renders on Store Product Type */}
                                                            {action === 'add' || duplicateCouponFlag === 'true'
                                                                ? t('common:save')
                                                                : t('common:update')}
                                                        </Button>

                                                        <Button
                                                            className='app-btn-secondary'
                                                            disabled={updateButton}
                                                            onClick={() => {
                                                                action === 'add'
                                                                    ? navigate(
                                                                          `/dashboard/coupons/list-coupons?tab=3&page=1&limit=20${MarketplaceAppConfig.getStore(
                                                                              ''
                                                                          )}`
                                                                      )
                                                                    : dataProcessorForSettingDataToMainObjectBySuccessResponse(
                                                                          dataForHandleCancelAction
                                                                      )
                                                                setUpdateButton(true)
                                                                setDisableActiveButton(false)
                                                            }}>
                                                            {t('common:cancel')}
                                                        </Button>
                                                    </Content>
                                                ) : null}
                                            </Content>
                                        </Content>
                                        <StoreModal
                                            title={
                                                <span className='ml-4 text-regal-blue text-[18px] font-bold'>
                                                    {t('product_template:add_products')}{' '}
                                                </span>
                                            }
                                            isVisible={addProductModalVisible}
                                            okButtonText={t('common:save')}
                                            cancelButtonText={t('common:cancel')}
                                            okCallback={() => addProductModalOkHandler()}
                                            hideCloseButton={false}
                                            cancelCallback={() => addProductModalCloseHandler()}
                                            isSpin={false}
                                            width={800}
                                            isOkButtonDisabled={saveButtonDisabledForModal}
                                            isScroll={true}>
                                            <Content>
                                                {/* selectedProducts,setSelectedProducts */}
                                                <AddProductsToDiscount
                                                    selectedProducts={selectedProducts}
                                                    setSelectedProducts={setSelectedProducts}
                                                    addProductModalVisible={addProductModalVisible}
                                                    setSaveButtonDisabledForModal={setSaveButtonDisabledForModal}
                                                    mainObjectDataForAllActions={mainObjectDataForAllActions}
                                                    triggerUseEffect={triggerUseEffect}
                                                />
                                            </Content>
                                        </StoreModal>
                                        {addCategoryModalVisible && (
                                            <StoreModal
                                                title={
                                                    <span className='ml-4 text-regal-blue text-[18px] font-bold'>
                                                        {t('coupons:add_categories')}
                                                    </span>
                                                }
                                                isVisible={addCategoryModalVisible}
                                                okButtonText={t('common:save')}
                                                cancelButtonText={t('common:cancel')}
                                                okCallback={() => addCategoryModalOkHandler()}
                                                hideCloseButton={false}
                                                cancelCallback={() => addCategoryModalCloseHandler()}
                                                isSpin={false}
                                                width={800}
                                                isScroll={true}
                                                isOkButtonDisabled={saveButtonDisabledForModal}>
                                                <Content>
                                                    <AddCategoryToDiscount
                                                        selectedCategory={selectedCategory}
                                                        setSelectedCategory={setSelectedCategory}
                                                        allCategory={allCategory}
                                                        setAllCategory={setAllCategory}
                                                        setSaveButtonDisabledForModal={setSaveButtonDisabledForModal}
                                                        mainObjectDataForAllActions={mainObjectDataForAllActions}
                                                    />
                                                </Content>
                                            </StoreModal>
                                        )}
                                        <StoreModal
                                            title={t('coupons:warning_changing_selection')}
                                            isVisible={showModal}
                                            okButtonText={t('common:yes')}
                                            cancelButtonText={t('common:no')}
                                            okCallback={() => handleWarningModalSaveFn()}
                                            hideCloseButton={false}
                                            cancelCallback={() => handleWarningModalCancelFn()}
                                            isSpin={false}
                                            width={750}
                                            isScroll={false}>
                                            <Content>
                                                {selectedChoice == 'category' ? (
                                                    <div>
                                                        <p>{t('coupons:choosing_specific_product_warning_msg')}</p>
                                                        <p>{t('coupons:are_you_sure_you_want_to_continue')}</p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <p>{t('coupons:choosing_specific_category_warning_msg')}</p>
                                                        <p>{t('coupons:are_you_sure_you_want_to_continue')}</p>
                                                    </div>
                                                )}
                                            </Content>
                                        </StoreModal>
                                    </>
                                )}
                            </Spin>
                        </Content>

                        <Affix offsetTop={144} className='!w-[36%]'>
                            <Content
                                className={`bg-white px-3 rounded-lg shadow-md ${action === 'add' ? '!h-64' : '!h-[370px]'}`}>
                                <Summary
                                    mainObjectDataForAllActions={mainObjectDataForAllActions}
                                    headerCouponDisplay={headerCouponDisplay}
                                    summaryData={summaryData}
                                />
                            </Content>
                        </Affix>
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default CreateDiscount
