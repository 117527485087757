import { ActionTypes } from '../constants/ActionTypes'

const {
    RDX_PAGE_COMP_ACTION,
    RDX_PAGE_COMP_EXECUTION_ORDER,
    RDX_PAGE_CHILD_COMP_STATUS,
    RDX_PAGE_COMP_UPDATE_ACTION,
    RDX_PAGE_COMP_REMOVE_UPDATE_ACTION,
} = ActionTypes

export const fnPageCompAction = (data) => {
    return {
        type: RDX_PAGE_COMP_ACTION,
        payload: data,
    }
}

export const fnPageCompExecutionOrder = (data) => {
    return {
        type: RDX_PAGE_COMP_EXECUTION_ORDER,
        payload: data,
    }
}

export const fnPageChildCompStatus = (data) => {
    return {
        type: RDX_PAGE_CHILD_COMP_STATUS,
        payload: data,
    }
}

export const fnPageCompUpdateAction = (data) => {
    return {
        type: RDX_PAGE_COMP_UPDATE_ACTION,
        payload: data,
    }
}

export const fnPageCompRemoveUpdateAction = () => {
    return {
        type: RDX_PAGE_COMP_REMOVE_UPDATE_ACTION,
    }
}
