import React, { useEffect, useState } from 'react'

import { GlobalOutlined, MailOutlined, TranslationOutlined, UserOutlined } from '@ant-design/icons'
// import { TroubleshootSharp } from '@mui/icons-material'
import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Layout,
    Radio,
    Row,
    Select,
    Slider,
    Switch,
    Tag,
    TimePicker,
    Tooltip,
    Typography,
} from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import validator from 'validator'
import StoreModal from '../components/storeModal/StoreModal'
import TextRichEditor from '../components/textRichEditor/TextRichEditor'
import AttributeDataTypes from '../constants/AttributesDataTypes'
import AttributeValuesTranslatorModal from '../pages-2.0/AttributeGroups/AttributeValuesTranslatorModal'
import DisplayImage from '../pages/media/DisplayImage'
import ListImageAndAddImage from '../pages/media/ListImageAndAddImage'
import { fnServiceProductTimeValidation } from '../services/redux/actions/ActionsAPICall'
import util from './common'
import MarketplaceToaster from './marketplaceToaster'
import {
    deepCopy,
    generateObjectFromUrl,
    generateObjectFromUrlWithFullDocumentPath,
    generateObjectFromUrlWithFullImagePath,
    getPrefixAndSuffix,
    sortObjectArrayByKey,
} from './util'
import { validatePositiveFloatNumber, validatePositiveNumber } from './validation'
import CustomPasswordInput from '../components/CustomPasswordInput'

const { Content } = Layout
const { Text } = Typography
const emailMaxLength = parseInt(process.env.REACT_APP_EMAIL_MAX_LENGTH)
let prefixSuffixMaxLength = process.env.REACT_APP_PREFIX_SUFFIX_MAX_SHOW_LENGTH
let choiceChipsPrefixSuffixMaxLength = process.env.REACT_APP_CHOICE_CHIPS_PREFIX_SUFFIX_MAX_SHOW_LENGTH

// This function will return the different controls along with their values populated for the product option values
const GetAttributesControlsNew = ({
    attributeId,
    attributeGroupId,
    attributeItem,
    optionType,
    attributeType,
    sensisble,
    revisable,
    action,
    mode,
    expression,
    optionValues,
    placeholder,
    attribute_unit_values,
    attributeFileType,
    attributeFileExtension,
    isEditable,
    isMandatory,
    isValidValue,
    setIsValidValue,
    isPhoneCodeNotValid,
    setIsPhoneCodeNotValid,
    isPhoneNumberNotValid,
    setIsPhoneNumberNotValid,
    nonEditableValue,
    valuesFromServer,
    valueFromServer,
    dataFromAttributeControls,
    attributeEditData,
    setDataFromAttributeControls,
    titleName,
    storeProductTypeId,
    slotNameProps,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    const search = useLocation().search
    const [inputData, setInputData] = useState(valueFromServer)
    const [openImageModel, setOpenImageModel] = useState(false)
    const [openDocumentModel, setOpenDocumentModel] = useState(false)
    const [openPdfModel, setOpenPdfModel] = useState(false)
    const [openFileModel, setOpenFileModel] = useState(false)
    const [imageData, setImageData] = useState('')
    const [imageDataToDisplayImage, setImageDataToDisplayImage] = useState()
    const [pdfData, setPdfData] = useState('')
    const [pdfDataToDisplayImage, setPdfDataToDisplayImage] = useState()
    const [documentData, setDocumentData] = useState('')
    const [documentDataToDisplayImage, setDocumentDataToDisplayImage] = useState()
    const [fileData, setFileData] = useState('')
    const [fileDataToDisplayImage, setFileDataToDisplayImage] = useState()
    const [phoneCode, setPhoneCode] = useState('+91')
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [dateValue, setDateValue] = useState('')
    const [textRichData, setTextRichData] = useState('')
    const [translateModalVisible, setTranslateModalVisible] = useState(false)
    const [dataForAttributeValueTranslater, setDataForAttributeValueTranslater] = useState()

    const [indeterminate, setIndeterminate] = useState(false)
    const [checkAll, setCheckAll] = useState(false)
    const [onChangeDisableFields, setOnChangeDisableFields] = useState(false)
    const [sensitiveVisibility, setSensitiveVisibility] = useState(false)
    const [phoneCodeVisibility, setPhoneCodeVisibility] = useState(false)

    let product_id = new URLSearchParams(search).get('vrid')
    let version_status = new URLSearchParams(search).get('vrsts')

    const [slotDays, setSlotDays] = useState([])
    const [triggerUseEffect, setTriggerUseEffect] = useState(0)

    const storeSettingsInfo = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    useEffect(() => {
        const attributePresentGroupData = deepCopy(attributeEditData)
        var attributePresentGroupDataFiltered =
            attributePresentGroupData &&
            attributePresentGroupData.length > 0 &&
            attributePresentGroupData.filter(
                (element) => parseInt(element.product_attribute_group_id) === parseInt(attributeGroupId)
            )
        var presentAttributeData =
            attributePresentGroupDataFiltered &&
            attributePresentGroupDataFiltered.length > 0 &&
            attributePresentGroupDataFiltered[0].product_attribute_group_data.find(
                (element) => parseInt(element.attribute_id) === parseInt(attributeId)
            )
        var presentAttributeObject = presentAttributeData
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}

        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        if (optionType === 1 || optionType === 3) {
            //MultiValue selection data type attributes
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].option &&
                    valuesFromServer[0][0].option.length > 0
                ) {
                    if (
                        presentEditedObject.is_edited_now === false &&
                        (presentEditedObject.data_type === 1 || presentEditedObject.data_type === 3)
                    ) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].option &&
                                valuesFromServer[0][0].option.length > 0 &&
                                valuesFromServer[0][0].option
                        )
                    }
                } else {
                    if (
                        presentEditedObject &&
                        presentEditedObject.is_edited_now === false &&
                        (presentEditedObject.data_type === 1 || presentEditedObject.data_type === 3)
                    ) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].option &&
                                valuesFromServer[0][0].option.length > 0 &&
                                valuesFromServer[0][0].option
                        )
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].attribute_option &&
                        valuesFromServer[0][0].attribute_option.length > 0 &&
                        valuesFromServer[0][0].attribute_option
                    ) {
                        if (
                            presentEditedObject.is_edited_now === false &&
                            (presentEditedObject.data_type === 1 || presentEditedObject.data_type === 3)
                        ) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].attribute_option &&
                                    valuesFromServer[0][0].attribute_option.length > 0 &&
                                    valuesFromServer[0][0].attribute_option
                            )
                        }
                    } else {
                        if (
                            presentEditedObject &&
                            presentEditedObject.is_edited_now === false &&
                            (presentEditedObject.data_type === 1 || presentEditedObject.data_type === 3)
                        ) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].attribute_option &&
                                    valuesFromServer[0][0].attribute_option.length > 0 &&
                                    valuesFromServer[0][0].attribute_option
                            )
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                }
            }
        } else if (optionType === 2 || optionType === 7 || optionType === 8) {
            //Single value selection data type attributes
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].option &&
                    valuesFromServer[0][0].option.length > 0 &&
                    valuesFromServer[0][0].option
                ) {
                    if (
                        presentEditedObject.is_edited_now === false &&
                        (presentEditedObject.data_type === 2 ||
                            presentEditedObject.data_type === 7 ||
                            presentEditedObject.data_type === 8)
                    ) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].option &&
                                valuesFromServer[0][0].option.length > 0 &&
                                valuesFromServer[0][0].option
                        )
                    }
                    // setInputData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].option &&
                    //     valuesFromServer[0][0].option.length > 0 &&
                    //     valuesFromServer[0][0].option
                    // );
                } else {
                    // setInputData(
                    //   presentAttributeObject &&
                    //     presentAttributeObject.option &&
                    //     presentAttributeObject.option.length > 0 &&
                    //     presentAttributeObject.option
                    // );
                    if (
                        presentEditedObject &&
                        presentEditedObject.is_edited_now === false &&
                        (presentEditedObject.data_type === 2 ||
                            presentEditedObject.data_type === 7 ||
                            presentEditedObject.data_type === 8)
                    ) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].option &&
                                valuesFromServer[0][0].option.length > 0 &&
                                valuesFromServer[0][0].option
                        )
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].attribute_option &&
                        valuesFromServer[0][0].attribute_option.length > 0 &&
                        valuesFromServer[0][0].attribute_option
                    ) {
                        if (
                            presentEditedObject.is_edited_now === false &&
                            (presentEditedObject.data_type === 2 ||
                                presentEditedObject.data_type === 7 ||
                                presentEditedObject.data_type === 8)
                        ) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].attribute_option &&
                                    valuesFromServer[0][0].attribute_option.length > 0 &&
                                    valuesFromServer[0][0].attribute_option
                            )
                        }
                        // setInputData(
                        //   valuesFromServer &&
                        //     valuesFromServer.length > 0 &&
                        //     valuesFromServer[0] &&
                        //     valuesFromServer[0].length > 0 &&
                        //     valuesFromServer[0][0].option &&
                        //     valuesFromServer[0][0].option.length > 0 &&
                        //     valuesFromServer[0][0].option
                        // );
                    } else {
                        // setInputData(
                        //   presentAttributeObject &&
                        //     presentAttributeObject.option &&
                        //     presentAttributeObject.option.length > 0 &&
                        //     presentAttributeObject.option
                        // );
                        if (
                            presentEditedObject &&
                            presentEditedObject.is_edited_now === false &&
                            (presentEditedObject.data_type === 2 ||
                                presentEditedObject.data_type === 7 ||
                                presentEditedObject.data_type === 8)
                        ) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].attribute_option &&
                                    valuesFromServer[0][0].attribute_option.length > 0 &&
                                    valuesFromServer[0][0].attribute_option
                            )
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                }
            }
        } else if (optionType === 12) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value !== null &&
                    valuesFromServer[0][0].display_value !== undefined &&
                    valuesFromServer[0][0].display_value !== ''
                ) {
                    if (presentEditedObject.is_edited_now === false) {
                        // setInputData(
                        //   valuesFromServer &&
                        //     valuesFromServer.length > 0 &&
                        //     valuesFromServer[0] &&
                        //     valuesFromServer[0].length > 0 &&
                        //     valuesFromServer[0][0].display_value
                        // );
                        setPhoneCode(
                            valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value.split('-')[0]
                        )
                        setPhoneNumber(
                            valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value.split('-')[1]
                        )
                    }
                    // setInputData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].display_value
                    // );
                } else {
                    if (
                        presentAttributeObject &&
                        presentAttributeObject.value !== '' &&
                        presentAttributeObject.value !== null &&
                        presentAttributeObject.value !== undefined
                    ) {
                        // setInputData(
                        //   presentAttributeObject && presentAttributeObject.value
                        // );
                        setPhoneCode(presentAttributeObject && presentAttributeObject.value.split('-')[0])
                        setPhoneNumber(presentAttributeObject && presentAttributeObject.value.split('-')[1])
                    } else {
                        setInputData('')
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value !== null &&
                        valuesFromServer[0][0].display_value !== undefined &&
                        valuesFromServer[0][0].display_value !== ''
                    ) {
                        if (presentEditedObject.is_edited_now === false) {
                            // setInputData(
                            //   valuesFromServer &&
                            //     valuesFromServer.length > 0 &&
                            //     valuesFromServer[0] &&
                            //     valuesFromServer[0].length > 0 &&
                            //     valuesFromServer[0][0].display_value
                            // );
                            setPhoneCode(
                                valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].display_value.split('-')[0]
                            )
                            setPhoneNumber(
                                valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].display_value.split('-')[1]
                            )
                        }
                        // setInputData(
                        //   valuesFromServer &&
                        //     valuesFromServer.length > 0 &&
                        //     valuesFromServer[0] &&
                        //     valuesFromServer[0].length > 0 &&
                        //     valuesFromServer[0][0].display_value
                        // );
                    } else {
                        if (
                            presentAttributeObject &&
                            presentAttributeObject.value !== '' &&
                            presentAttributeObject.value !== null &&
                            presentAttributeObject.value !== undefined
                        ) {
                            // setInputData(
                            //   presentAttributeObject && presentAttributeObject.value
                            // );
                            setPhoneCode(presentAttributeObject && presentAttributeObject.value.split('-')[0])
                            setPhoneNumber(presentAttributeObject && presentAttributeObject.value.split('-')[1])
                        } else {
                            setInputData('')
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                    setPhoneCode(nonEditableValue.split('-')[0])
                    setPhoneNumber(nonEditableValue.split('-')[1])
                }
            }
        } else if (
            optionType === 11 ||
            optionType === 13 ||
            optionType === 20 ||
            optionType === 21 ||
            optionType === 22 ||
            optionType === 23 ||
            optionType === 24 ||
            optionType === 25 ||
            optionType === 26 ||
            optionType === 6
        ) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].value !== null &&
                    valuesFromServer[0][0].value !== undefined &&
                    valuesFromServer[0][0].value !== ''
                ) {
                    if (presentEditedObject.is_edited_now === false) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].value
                        )
                    }
                } else {
                    if (
                        presentAttributeObject &&
                        presentAttributeObject.value !== '' &&
                        presentAttributeObject.value !== null &&
                        presentAttributeObject.value !== undefined
                    ) {
                        setInputData(String(presentAttributeObject && presentAttributeObject.value))
                    } else {
                        setInputData('')
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].value !== null &&
                        valuesFromServer[0][0].value !== undefined &&
                        valuesFromServer[0][0].value !== ''
                    ) {
                        if (presentEditedObject.is_edited_now === false) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].value
                            )
                        }
                    } else {
                        if (
                            presentAttributeObject &&
                            presentAttributeObject.value !== '' &&
                            presentAttributeObject.value !== null &&
                            presentAttributeObject.value !== undefined
                        ) {
                            setInputData(String(presentAttributeObject && presentAttributeObject.value))
                        } else {
                            setInputData('')
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                }
            }
        } else if (optionType === 27 || optionType === 30) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value !== null &&
                    valuesFromServer[0][0].display_value !== undefined &&
                    valuesFromServer[0][0].display_value !== ''
                ) {
                    if (presentEditedObject.is_edited_now === false) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )
                    }
                    // setInputData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].display_value
                    // );
                } else {
                    if (
                        presentAttributeObject &&
                        presentAttributeObject.value !== '' &&
                        presentAttributeObject.value !== null &&
                        presentAttributeObject.value !== undefined
                    ) {
                        setInputData(String(presentAttributeObject && presentAttributeObject.value))
                    } else {
                        setInputData('')
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value !== null &&
                        valuesFromServer[0][0].display_value !== undefined &&
                        valuesFromServer[0][0].display_value !== ''
                    ) {
                        if (presentEditedObject.is_edited_now === false) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].display_value
                            )
                        }
                        // setInputData(
                        //   valuesFromServer &&
                        //     valuesFromServer.length > 0 &&
                        //     valuesFromServer[0] &&
                        //     valuesFromServer[0].length > 0 &&
                        //     valuesFromServer[0][0].display_value
                        // );
                    } else {
                        if (
                            presentAttributeObject &&
                            presentAttributeObject.value !== '' &&
                            presentAttributeObject.value !== null &&
                            presentAttributeObject.value !== undefined
                        ) {
                            setInputData(String(presentAttributeObject && presentAttributeObject.value))
                        } else {
                            setInputData('')
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                }
            }
        } else if (optionType === 4) {
            let selectedValue
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].option &&
                    valuesFromServer[0][0].option.length > 0 &&
                    valuesFromServer[0][0].option[0]
                ) {
                    selectedValue =
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].option &&
                        valuesFromServer[0][0].option.length > 0 &&
                        valuesFromServer[0][0].option[0]
                } else {
                    // setInputData(
                    //   presentAttributeObject &&
                    //     presentAttributeObject.option &&
                    //     presentAttributeObject.option.length > 0 &&
                    //     presentAttributeObject.option[0]
                    // );
                    selectedValue =
                        presentAttributeObject &&
                        presentAttributeObject.option &&
                        presentAttributeObject.option.length > 0 &&
                        presentAttributeObject.option[0]
                }
                var selectedOptionObject =
                    optionValues &&
                    optionValues.length > 0 &&
                    optionValues.find((item) => parseInt(item.id) === parseInt(selectedValue))
                // if (
                //   presentEditedObject.is_edited_now === false &&
                //   presentEditedObject.data_type === 4
                // ) {
                setInputData(selectedOptionObject && selectedOptionObject.display_name)
                // }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].attribute_option &&
                        valuesFromServer[0][0].attribute_option.length > 0 &&
                        valuesFromServer[0][0].attribute_option[0]
                    ) {
                        selectedValue =
                            valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].attribute_option &&
                            valuesFromServer[0][0].attribute_option.length > 0 &&
                            valuesFromServer[0][0].attribute_option[0]
                    } else {
                        // setInputData(
                        //   presentAttributeObject &&
                        //     presentAttributeObject.option &&
                        //     presentAttributeObject.option.length > 0 &&
                        //     presentAttributeObject.option[0]
                        // );
                        selectedValue =
                            presentAttributeObject &&
                            presentAttributeObject.option &&
                            presentAttributeObject.option.length > 0 &&
                            presentAttributeObject.option[0]
                    }
                    var selectedOptionObject =
                        optionValues &&
                        optionValues.length > 0 &&
                        optionValues.find((item) => parseInt(item.id) === parseInt(selectedValue))
                    // if (
                    //   presentEditedObject.is_edited_now === false &&
                    //   presentEditedObject.data_type === 4
                    // ) {
                    setInputData(selectedOptionObject && selectedOptionObject.display_name)
                    // }
                } else {
                    selectedValue = nonEditableValue
                    var selectedOptionObject =
                        optionValues &&
                        optionValues.length > 0 &&
                        optionValues.find((item) => parseInt(item.id) === parseInt(selectedValue))
                    // if (
                    //   presentEditedObject.is_edited_now === false &&
                    //   presentEditedObject.data_type === 4
                    // ) {
                    setInputData(selectedOptionObject && selectedOptionObject.display_name)
                    // }
                }
            }
        } else if (optionType === 9 || optionType === 14 || optionType === 5) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    if (presentEditedObject.is_edited_now === false) {
                        setInputData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                                ? valuesFromServer &&
                                      valuesFromServer.length > 0 &&
                                      valuesFromServer[0] &&
                                      valuesFromServer[0].length > 0 &&
                                      valuesFromServer[0][0].display_value
                                : ''
                        )
                    }
                } else {
                    if (presentAttributeObject && presentAttributeObject.value !== null) {
                        setInputData(presentAttributeObject && presentAttributeObject.value)
                    } else {
                        setInputData('')
                    }
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value
                    ) {
                        if (presentEditedObject.is_edited_now === false) {
                            setInputData(
                                valuesFromServer &&
                                    valuesFromServer.length > 0 &&
                                    valuesFromServer[0] &&
                                    valuesFromServer[0].length > 0 &&
                                    valuesFromServer[0][0].display_value
                                    ? valuesFromServer &&
                                          valuesFromServer.length > 0 &&
                                          valuesFromServer[0] &&
                                          valuesFromServer[0].length > 0 &&
                                          valuesFromServer[0][0].display_value
                                    : ''
                            )
                        }
                    } else {
                        if (presentAttributeObject && presentAttributeObject.value !== null) {
                            setInputData(presentAttributeObject && presentAttributeObject.value)
                        } else {
                            setInputData('')
                        }
                    }
                } else {
                    setInputData(nonEditableValue)
                }
            }
        } else if (optionType === 16) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    var returnedArray = generateObjectFromUrlWithFullImagePath(
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )
                    // setImageDataToDisplayImage(imageDataArray);
                    setImageDataToDisplayImage(returnedArray)

                    // setImageData(imageDataArray);
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value
                    ) {
                        var returnedArray = generateObjectFromUrlWithFullImagePath(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )
                        // setImageDataToDisplayImage(imageDataArray);
                        setImageDataToDisplayImage(returnedArray)

                        // setImageData(imageDataArray);
                    }
                } else {
                    var returnedArray = generateObjectFromUrlWithFullImagePath(nonEditableValue)

                    setImageDataToDisplayImage(returnedArray)
                }
            }
        } else if (optionType === 18) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    // setDocumentData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].display_value
                    // );
                    var returnedArray = generateObjectFromUrlWithFullDocumentPath(
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )
                    // setImageDataToDisplayImage(imageDataArray);
                    setDocumentDataToDisplayImage(returnedArray)
                }
            }
        } else if (optionType === 19) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    // setPdfData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].display_value
                    // );
                    var returnedArray = generateObjectFromUrlWithFullDocumentPath(
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )
                    // setImageDataToDisplayImage(imageDataArray);
                    setPdfDataToDisplayImage(returnedArray)
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value
                    ) {
                        var returnedArray = generateObjectFromUrlWithFullDocumentPath(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )
                        // setDocumentDataToDisplayImage(imageDataArray);
                        setPdfDataToDisplayImage(returnedArray)

                        // setImageData(imageDataArray);
                    }
                } else {
                    var returnedArray = generateObjectFromUrlWithFullDocumentPath(nonEditableValue)

                    setPdfDataToDisplayImage(returnedArray)
                }
            }
        } else if (optionType === 15) {
            if (isEditable) {
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    // setFileData(
                    //   valuesFromServer &&
                    //     valuesFromServer.length > 0 &&
                    //     valuesFromServer[0] &&
                    //     valuesFromServer[0].length > 0 &&
                    //     valuesFromServer[0][0].display_value
                    // );
                    var returnedArray = generateObjectFromUrlWithFullDocumentPath(
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )
                    // setImageDataToDisplayImage(imageDataArray);
                    setFileDataToDisplayImage(returnedArray)
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value
                    ) {
                        var returnedArray = generateObjectFromUrlWithFullDocumentPath(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )
                        // setDocumentDataToDisplayImage(imageDataArray);
                        setFileDataToDisplayImage(returnedArray)

                        // setImageData(imageDataArray);
                    }
                } else {
                    var returnedArray = generateObjectFromUrlWithFullDocumentPath(nonEditableValue)

                    setFileDataToDisplayImage(returnedArray)
                }
            }
        } else if (optionType === 10) {
            if (isEditable) {
                console.log('textRichData, valuesFromServer', valuesFromServer)
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].display_value
                ) {
                    console.log(
                        'displayValue',
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )

                    setTextRichData(
                        valuesFromServer &&
                            valuesFromServer.length > 0 &&
                            valuesFromServer[0] &&
                            valuesFromServer[0].length > 0 &&
                            valuesFromServer[0][0].display_value
                    )
                } else {
                    setTextRichData(presentAttributeObject && presentAttributeObject.value)
                }
            } else {
                if (String(attributeType).toLowerCase() === 'journey') {
                    if (
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].display_value
                    ) {
                        console.log(
                            'displayValue',
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )

                        setTextRichData(
                            valuesFromServer &&
                                valuesFromServer.length > 0 &&
                                valuesFromServer[0] &&
                                valuesFromServer[0].length > 0 &&
                                valuesFromServer[0][0].display_value
                        )
                    } else {
                        setTextRichData(presentAttributeObject && presentAttributeObject.value)
                    }
                } else {
                    setTextRichData(nonEditableValue)
                }
            }
        }
    }, [valuesFromServer, nonEditableValue])

    useEffect(() => {
        if (triggerUseEffect === 0) {
            const attributePresentGroupData = deepCopy(attributeEditData)
            var attributePresentGroupDataFiltered =
                attributePresentGroupData &&
                attributePresentGroupData.length > 0 &&
                attributePresentGroupData.filter(
                    (element) => parseInt(element.product_attribute_group_id) === parseInt(attributeGroupId)
                )
            var presentAttributeData =
                attributePresentGroupDataFiltered &&
                attributePresentGroupDataFiltered.length > 0 &&
                attributePresentGroupDataFiltered[0].product_attribute_group_data.find(
                    (element) => parseInt(element.attribute_id) === parseInt(attributeId)
                )
            var presentAttributeObject = presentAttributeData
            let presentEditedData = deepCopy(attributeEditData)
            let presentEditedObject = {}

            presentEditedData &&
                presentEditedData.length > 0 &&
                presentEditedData.find(
                    (element) =>
                        (presentEditedObject =
                            element.product_attribute_group_data &&
                            element.product_attribute_group_data.length > 0 &&
                            element.product_attribute_group_data.find(
                                (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                            ))
                )
            if (optionType === 1) {
                setSlotDays(optionValues)
                if (
                    valuesFromServer &&
                    valuesFromServer.length > 0 &&
                    valuesFromServer[0] &&
                    valuesFromServer[0].length > 0 &&
                    valuesFromServer[0][0].option &&
                    valuesFromServer[0][0].option.length > 0
                ) {
                    let data =
                        valuesFromServer &&
                        valuesFromServer.length > 0 &&
                        valuesFromServer[0] &&
                        valuesFromServer[0].length > 0 &&
                        valuesFromServer[0][0].option &&
                        valuesFromServer[0][0].option.length > 0 &&
                        valuesFromServer[0][0].option

                    if (data.length === optionValues.length) {
                        setIndeterminate(false)
                        setCheckAll(true)
                        setTriggerUseEffect(1)
                    } else if (data.length > 0) {
                        setIndeterminate(true)
                        setTriggerUseEffect(1)
                    }
                }
            }
        }
    }, [optionType, optionValues, valuesFromServer, nonEditableValue, triggerUseEffect])
    const compareMinAndMaxForSensitiveFields = (presentValue, min, max, type) => {
        if (presentValue !== null || presentValue !== undefined || presentValue !== '' || presentValue) {
            if (parseFloat(presentValue) >= parseFloat(min) && parseFloat(presentValue) <= parseFloat(max)) {
                setInputData(presentValue)
            } else if (parseFloat(presentValue) > parseFloat(min) && parseFloat(presentValue) > parseFloat(max)) {
                MarketplaceToaster.showToast(
                    util.getToastObject(`Value should be in between, ${min} and ${max}`, 'error')
                )
                setInputData('')
            } else if (parseFloat(presentValue) < parseFloat(min)) {
                MarketplaceToaster.showToast(
                    util.getToastObject(`Value should be in between, ${min} and ${max}`, 'error')
                )
                setInputData('')
            }
        }
    }
    const onChangeData = (e, id, productAttributedGroupId, optionType, optionId, optionValues) => {
        const attributePresentGroupData = deepCopy(attributeEditData)
        var attributePresentGroupDataFiltered =
            attributePresentGroupData &&
            attributePresentGroupData.length > 0 &&
            attributePresentGroupData.filter(
                (element) => parseInt(element.product_attribute_group_id) === parseInt(attributeGroupId)
            )
        var presentAttributeData =
            attributePresentGroupDataFiltered &&
            attributePresentGroupDataFiltered.length > 0 &&
            attributePresentGroupDataFiltered[0].product_attribute_group_data.find(
                (element) => parseInt(element.attribute_id) === parseInt(attributeId)
            )
        var presentAttributeObject = presentAttributeData
        let presentEditedData = attributeEditData
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(id)
                        ))
            )
        presentAttributeObject.is_edited_now = true
        presentEditedObject.is_edited_now = true
        if (optionType === 11 || optionType === 13) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                const trimmed = e.target.value.trim()
                const trimmedUpdate = trimmed.replace(/\s+/g, '')
                presentEditedObject.value = trimmedUpdate
                presentAttributeObject.value = trimmedUpdate
                // presentEditedObject.isValid = true;
                // presentAttributeObject.is_edited_now=true;
                setInputData(trimmedUpdate)
                const index = isValidValue?.indexOf(id)
                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
            } else if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                const index = isValidValue?.indexOf(id)
                // presentAttributeObject.is_edited_now = true;
                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }

                presentEditedObject.option = e.target.value
                setInputData(e.target.value)
            }
        } else if (optionType === 27 || optionType === 30) {
            const trimmed = e.target.value.trim()
            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
            presentEditedObject.value = trimmedUpdate
            presentAttributeObject.value = trimmedUpdate
            // presentEditedObject.isValid = true;
            // presentAttributeObject.is_edited_now=true;
            setInputData(e.target.value)
            const index = isValidValue?.indexOf(id)
            if (index > -1) {
                // only splice isValidValue when item is found
                isValidValue.splice(index, 1) // 2nd parameter means remove one item only
            }
        } else if (
            optionType === 20 ||
            // optionType === 21 ||
            optionType === 22 ||
            optionType === 23 ||
            optionType === 24 ||
            optionType === 25 ||
            optionType === 26 ||
            optionType === 6
        ) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                if (sensisble === true) {
                    const trimmed = e.target.value.trim()
                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                    presentEditedObject.value = e.target.value
                    presentAttributeObject.value = e.target.value
                    // presentAttributeObject.is_edited_now = true;
                    setInputData(e.target.value)
                } else if (sensisble === false) {
                    presentEditedObject.value = String(e)
                    presentAttributeObject.value = String(e)
                    setInputData(e)
                }
                // presentEditedObject.value = e;
                // presentAttributeObject.value = e;

                const index = isValidValue?.indexOf(id)

                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
            }
        } else if (optionType === 1) {
            if (optionValues && optionValues.length > 0) {
                if (e.target.checked === true) {
                    let temp = []
                    setIndeterminate(false)
                    setCheckAll(true)
                    for (var i = 0; i < optionValues.length; i++) {
                        temp.push(optionValues[i].id)
                        presentEditedObject.option.push(optionValues[i].id)
                        presentAttributeObject.option.push(optionValues[i].id)
                    }
                    setInputData(temp)
                } else if (e.target.checked === false) {
                    setIndeterminate(false)
                    setCheckAll(false)
                    setInputData([])
                    presentEditedObject['option'] = []
                    presentAttributeObject['option'] = []
                }
            } else if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                if (e.target.checked === true) {
                    presentEditedObject.option.push(optionId)
                    presentAttributeObject.option.push(optionId)
                    let inputDataPrevious =
                        inputData && inputData.length > 0 && inputData.filter((item) => item !== optionId)
                    if (inputDataPrevious) {
                    } else {
                        inputDataPrevious = []
                    }
                    inputDataPrevious.push(optionId)
                    if (inputDataPrevious.length === slotDays.length) {
                        setIndeterminate(false)
                        setCheckAll(true)
                    } else {
                        setIndeterminate(true)
                    }

                    setInputData(inputDataPrevious)

                    const index = isValidValue?.indexOf(id)

                    if (index > -1) {
                        // only splice isValidValue when item is found
                        isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                    }
                } else if (e.target.checked === false) {
                    presentEditedObject.option = presentEditedObject.option.filter((item) => item !== optionId)
                    presentAttributeObject.option = presentAttributeObject.option.filter((item) => item != optionId)
                    let inputDataPrevious =
                        inputData && inputData.length > 0 && inputData.filter((item) => item !== optionId)
                    if (inputDataPrevious) {
                    } else {
                        inputDataPrevious = []
                    }
                    const index = isValidValue?.indexOf(id)

                    if (index > -1) {
                        // only splice isValidValue when item is found
                        isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                    }
                    if (inputDataPrevious.length > 0) {
                        setIndeterminate(true)
                        setCheckAll(false)
                    } else {
                        setIndeterminate(false)
                    }
                    setInputData(inputDataPrevious)
                }
            }
        } else if (optionType === 2 || optionType === 8) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                if (e.target.checked === true) {
                    if (presentEditedObject.option && presentEditedObject.option.length > 0) {
                        presentEditedObject.option.pop()
                        presentAttributeObject.option.pop()
                    }
                    presentEditedObject.option.push(e.target.value)
                    presentAttributeObject.option.push(e.target.value)
                    const index = isValidValue?.indexOf(id)

                    if (index > -1) {
                        // only splice isValidValue when item is found
                        isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                    }
                    setInputData([e.target.value])
                }
            }
        } else if (optionType === 9 || optionType === 14 || optionType === 5) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                presentEditedObject.value = e
                presentAttributeObject.value = e
                const index = isValidValue?.indexOf(id)

                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
                // setInputData(dayjs(e));
                // setDateValue(e);
                setInputData(e)
            }
        } else if (optionType === 21) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                // presentEditedObject.value = e;
                if (sensisble === true) {
                    presentEditedObject.value = e.target.value
                    presentAttributeObject.value = e.target.value
                    setInputData(e.target.value)
                } else if (sensisble === false) {
                    presentEditedObject.value = `${e}%`
                    presentAttributeObject.value = `${e}%`
                    setInputData(`${e}%`)
                }
                const index = isValidValue?.indexOf(id)

                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
            }
        }
        setDataFromAttributeControls(presentEditedData)
        if (slotNameProps === 'Slot Details') {
            var attributePresentGroupDataFilter =
                attributePresentGroupData &&
                attributePresentGroupData.length > 0 &&
                attributePresentGroupData.filter((element) => parseInt(element.product_attribute_group_id) === 9)
            if (attributePresentGroupDataFilter && attributePresentGroupDataFilter.length > 0) {
                let timeFilter =
                    attributePresentGroupDataFilter[0].product_attribute_group_data &&
                    attributePresentGroupDataFilter[0].product_attribute_group_data.length > 0 &&
                    attributePresentGroupDataFilter[0].product_attribute_group_data.filter(
                        (ele) => ele.attribute_name == 'Slot Start Time' || ele.attribute_name == 'Slot End Time'
                    )
                if (timeFilter && timeFilter.length > 0) {
                    if (timeFilter[0].value > timeFilter[1].value)
                        dispatch(
                            fnServiceProductTimeValidation({
                                makeServiceProductValidation: true,
                            })
                        )
                    else
                        dispatch(
                            fnServiceProductTimeValidation({
                                makeServiceProductValidation: false,
                            })
                        )
                }
            }
        }
    }
    const onChangePhoneNumber = (e, id, optionType, type) => {
        const attributePresentGroupData = deepCopy(attributeEditData)
        var attributePresentGroupDataFiltered =
            attributePresentGroupData &&
            attributePresentGroupData.length > 0 &&
            attributePresentGroupData.filter(
                (element) => parseInt(element.product_attribute_group_id) === parseInt(attributeGroupId)
            )
        var presentAttributeData =
            attributePresentGroupDataFiltered &&
            attributePresentGroupDataFiltered.length > 0 &&
            attributePresentGroupDataFiltered[0].product_attribute_group_data.find(
                (element) => parseInt(element.attribute_id) === parseInt(attributeId)
            )
        var presentAttributeObject = presentAttributeData
        let presentEditedData = attributeEditData
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(id)
                        ))
            )
        presentAttributeObject.is_edited_now = true
        presentEditedObject.is_edited_now = true
        if (optionType === 12) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                if (type === 'phoneCode') {
                    presentEditedObject.value = String(e.target.value) + '-' + String(phoneNumber)
                    presentAttributeObject.value = String(e.target.value) + '-' + String(phoneNumber)
                } else if (type === 'phoneNumber') {
                    presentEditedObject.value = String(phoneCode) + '-' + String(e.target.value)
                    presentAttributeObject.value = String(phoneCode) + '-' + String(e.target.value)
                }
                // presentEditedObject.isValid = true;
                // presentAttributeObject.is_edited_now=true;
                // setInputData(trimmedUpdate);
                const index = isValidValue?.indexOf(id)
                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
            }
        }
        setDataFromAttributeControls(presentEditedData)
    }
    const onChangeToggle = (e, id, productAttributedGroupId, optionType, checkedOptionValue, unCheckedOptionValue) => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}

        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(id)
                        ))
            )
        presentEditedObject.is_edited_now = true
        if (optionType === 4) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                const index = isValidValue?.indexOf(id)
                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
                if (e === true) {
                    if (presentEditedObject.option && presentEditedObject.option.length > 0) {
                        presentEditedObject.option.pop()
                    }
                    presentEditedObject.option.push(checkedOptionValue.id)
                    // presentEditedObject.option = checkedOptionValue.id;
                    // setInputData(checkedOptionValue.id);
                    var selectedCheckedOptionObject =
                        optionValues &&
                        optionValues.length > 0 &&
                        optionValues.find((item) => parseInt(item.id) === parseInt(checkedOptionValue.id))
                    setInputData(selectedCheckedOptionObject && selectedCheckedOptionObject.display_name)
                    // setInputData(checkedOptionValue.id);
                } else if (e === false) {
                    // presentEditedObject.option = [];
                    if (presentEditedObject.option && presentEditedObject.option.length > 0) {
                        presentEditedObject.option.pop()
                    }
                    presentEditedObject.option.push(unCheckedOptionValue.id)
                    // presentEditedObject.option = unCheckedOptionValue.id;

                    var selectedUnCheckedOptionObject =
                        optionValues &&
                        optionValues.length > 0 &&
                        optionValues.find((item) => parseInt(item.id) === parseInt(unCheckedOptionValue.id))
                    setInputData(selectedCheckedOptionObject && selectedCheckedOptionObject.display_name)
                    // setInputData(unCheckedOptionValue.id);
                }
            }
        }
        setDataFromAttributeControls(presentEditedData)
    }
    const handleTagChange = (tag, checked, optionType, id) => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(id)
                        ))
            )
        if (optionType === 3) {
            let valueArray
            if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                const index = isValidValue?.indexOf(id)
                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
                if (checked === true) {
                    presentEditedObject.option.push(tag)
                    presentEditedObject.is_edited_now = true
                    if (inputData && inputData.length > 0) {
                        valueArray = [...inputData]
                        valueArray.push(tag)
                    } else {
                        valueArray = []
                        valueArray.push(tag)
                    }
                    // valueArray = valueArray.filter((item) => item !== tag);
                    setInputData(valueArray)
                } else if (checked === false) {
                    presentEditedObject.option = presentEditedObject.option.filter((item) => item !== tag)
                    presentEditedObject.is_edited_now = true
                    if (inputData && inputData.length > 0) {
                        valueArray = [...inputData]
                        valueArray = valueArray.filter((item) => item !== tag)
                    } else {
                        valueArray = []
                        valueArray = valueArray.filter((item) => item !== tag)
                    }
                    setInputData(valueArray)
                }
            }
        }
        setDataFromAttributeControls(presentEditedData)
    }
    const onSingleSelectChange = (e, id, optionType, optionValues, prefix, suffix) => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        let combinedValue = ''

        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(id)
                        ))
            )
        if (optionType === 7) {
            let changedOptionObject =
                optionValues && optionValues.length > 0 && optionValues.find((item) => item.id === e)
            if (presentEditedObject && presentEditedObject.hasOwnProperty('option')) {
                presentEditedObject.option = [e]
                presentEditedObject.is_edited_now = true
                const index = isValidValue?.indexOf(id)

                if (index > -1) {
                    // only splice isValidValue when item is found
                    isValidValue.splice(index, 1) // 2nd parameter means remove one item only
                }
                if (changedOptionObject) {
                    if (
                        suffix !== '' &&
                        suffix !== undefined &&
                        suffix !== null &&
                        (prefix === '' || prefix === undefined || prefix === null)
                    ) {
                        combinedValue = changedOptionObject.display_name + '-' + suffix
                    } else if (
                        (suffix === '' || suffix === undefined || suffix === null) &&
                        prefix !== '' &&
                        prefix !== undefined &&
                        prefix !== null
                    ) {
                        combinedValue = prefix + '-' + changedOptionObject.display_name
                    } else if (
                        suffix !== '' &&
                        suffix !== undefined &&
                        suffix !== null &&
                        prefix !== '' &&
                        prefix !== undefined &&
                        prefix !== null
                    ) {
                        combinedValue = prefix + '-' + changedOptionObject.display_name + '-' + suffix
                    } else if (
                        (suffix === '' || suffix === undefined || suffix === null) &&
                        (prefix === '' || prefix === undefined || prefix === null)
                    ) {
                        combinedValue = changedOptionObject.display_name
                    }
                    setInputData(combinedValue)
                }
            }
        }
        setIsValidValue(isValidValue)
        setDataFromAttributeControls(presentEditedData)
    }
    //!The below function will convert the fileUrl to object which can be uploaded to server.
    const convertUrlToObjectForFileUpload = (fileUrls, type) => {
        let convertedObject = []
        let fromUrl = generateObjectFromUrl(fileUrls)
        let obj = {}
        if (fromUrl && Object.keys(fromUrl).length > 0) {
            if (type == 'image') {
                obj['image_path'] = fromUrl.image_path
                obj['image_type'] = fromUrl.image_type
                // obj["store-id"] = fromUrl.store_id;
            } else {
                obj['document_path'] = fromUrl.document_path
                obj['document_type'] = fromUrl.document_type
                // obj["store-id"] = fromUrl.store_id;
            }
        } else if (fromUrl && Object.keys(fromUrl).length === 0) {
            if (type == 'image') {
                obj['image_path'] = fileUrls
                obj['image_type'] = 'published_products'
                // obj["store-id"] = fromUrl.store_id;
            } else {
                obj['document_path'] = fileUrls
                obj['document_type'] = 'published_products'
                // obj["store-id"] = fromUrl.store_id;
            }
        }
        convertedObject.push(obj)
        return convertedObject
    }
    useEffect(() => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        const index = isValidValue?.indexOf(attributeId)

        if (index > -1) {
            // only splice isValidValue when item is found
            isValidValue.splice(index, 1)
        }
        setIsValidValue(isValidValue)
        if (imageData && imageData.length > 0 && parseInt(optionType) === 16) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                if (imageData[0].image_full_path !== undefined || imageData[0].image_full_path !== null) {
                    presentEditedObject.is_edited_now = true
                    presentEditedObject.value = imageData[0].image_full_path
                }
                // 2nd parameter means remove one item only
            }
            // setImageData(imageData)
            setImageDataToDisplayImage(imageData)
        }
        setDataFromAttributeControls(presentEditedData)
    }, [imageData])
    useEffect(() => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        // presentEditedObject.is_edited_now = true;
        const index = isValidValue?.indexOf(attributeId)

        if (index > -1) {
            // only splice isValidValue when item is found
            isValidValue.splice(index, 1) // 2nd parameter means remove one item only
        }
        if (documentData && documentData.length > 0 && parseInt(optionType) === 18) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                // if (
                //   presentEditedObject.value === null ||
                //   presentEditedObject.value === ""
                // ) {
                if (documentData[0].document_full_path !== undefined || documentData[0].document_full_path !== null) {
                    presentEditedObject.is_edited_now = true
                    presentEditedObject.value = documentData[0].document_full_path
                }

                // }
                setDocumentDataToDisplayImage(documentData)
                // setDocumentData(documentData)
                setIsValidValue(isValidValue)
            }
        } else if (parseInt(optionType) === 18 && documentData && documentData.length === 0) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                presentEditedObject.value = ''
                // setDocumentData(documentData);
            }
        }
        setDataFromAttributeControls(presentEditedData)
    }, [documentData])
    useEffect(() => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        // presentEditedObject.is_edited_now = true;
        const index = isValidValue?.indexOf(attributeId)

        if (index > -1) {
            // only splice isValidValue when item is found
            isValidValue.splice(index, 1) // 2nd parameter means remove one item only
        }
        if (pdfData && pdfData.length > 0 && parseInt(optionType) === 19) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                // if (
                //   presentEditedObject.value === null ||
                //   presentEditedObject.value === ""
                // ) {
                if (pdfData[0].document_full_path !== undefined || pdfData[0].document_full_path !== null) {
                    presentEditedObject.is_edited_now = true
                    presentEditedObject.value = pdfData[0].document_full_path
                }

                // }
                setPdfDataToDisplayImage(pdfData)
                // setPdfData(pdfData)
            }
        }
        setIsValidValue(isValidValue)
        setDataFromAttributeControls(presentEditedData)
    }, [pdfData])
    useEffect(() => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        // presentEditedObject.is_edited_now = true;
        const index = isValidValue?.indexOf(attributeId)

        if (index > -1) {
            // only splice isValidValue when item is found
            isValidValue.splice(index, 1) // 2nd parameter means remove one item only
        }
        if (fileData && fileData.length > 0 && parseInt(optionType) === 15) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                // if (
                //   presentEditedObject.value === null ||
                //   presentEditedObject.value === ""
                // ) {
                if (fileData[0].document_full_path !== undefined || fileData[0].document_full_path !== null) {
                    presentEditedObject.is_edited_now = true
                    presentEditedObject.value = fileData[0].document_full_path
                }

                // }
                // setFileData(fileData)
                setFileDataToDisplayImage(fileData)
            }
        }
        setIsValidValue(isValidValue)
        setDataFromAttributeControls(presentEditedData)
    }, [fileData])
    //  This useEffect will be triggered, whenever we receive data from textRichEditor Component
    useEffect(() => {
        let presentEditedData = deepCopy(attributeEditData)
        let presentEditedObject = {}
        presentEditedData &&
            presentEditedData.length > 0 &&
            presentEditedData.find(
                (element) =>
                    (presentEditedObject =
                        element.product_attribute_group_data &&
                        element.product_attribute_group_data.length > 0 &&
                        element.product_attribute_group_data.find(
                            (element1) => parseInt(element1.attribute_id) === parseInt(attributeId)
                        ))
            )
        // presentEditedObject.is_edited_now = true;
        const index = isValidValue?.indexOf(attributeId)

        if (index > -1) {
            // only splice isValidValue when item is found
            isValidValue.splice(index, 1) // 2nd parameter means remove one item only
        }
        if (parseInt(optionType) === 10) {
            if (presentEditedObject && presentEditedObject.hasOwnProperty('value')) {
                presentEditedObject.value = textRichData
                // if (
                //   textRichData !== "" &&
                //   presentEditedObject &&
                //   presentEditedObject.value !== "" &&
                //   presentEditedObject.value !== null
                // ) {
                if (
                    String(textRichData) !== String(valueFromServer) &&
                    textRichData !== undefined &&
                    textRichData !== ''
                ) {
                    presentEditedObject.is_edited_now = true
                }
                // }
                // if (textRichData && textRichData !== null) {
                //     setTextRichData(textRichData)
                // }
            }
        }
        setIsValidValue(isValidValue)
        setDataFromAttributeControls(presentEditedData)
    }, [textRichData])

    const handleKeyDown = (e) => {
        // Allow numeric characters, hyphen, and backspace
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-']

        // Check if the pressed key is not in the allowed keys or is not the backspace key
        if (!allowedKeys.includes(e.key) && e.key !== 'Backspace') {
            e.preventDefault()
        }
    }
    const handleTimeKeyDown = (e) => {
        // Allow numeric characters, hyphen, and backspace
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':']

        // Check if the pressed key is not in the allowed keys or is not the backspace key
        if (!allowedKeys.includes(e.key) && e.key !== 'Backspace') {
            e.preventDefault()
        }
    }
    const renderControls = () => {
        return (
            <div>
                {(() => {
                    switch (optionType) {
                        //Checkbox-Multiselect
                        case 1:
                            return (
                                <Content
                                    className={`${
                                        isValidValue &&
                                        isValidValue.length > 0 &&
                                        isValidValue &&
                                        isValidValue.includes(attributeId)
                                            ? 'border-[1px] rounded-md border-red-500'
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }`}>
                                    <Content>
                                        <Checkbox
                                            indeterminate={indeterminate}
                                            onChange={(e) =>
                                                onChangeData(
                                                    e,
                                                    attributeId,
                                                    attributeGroupId,
                                                    optionType,
                                                    '',
                                                    optionValues
                                                )
                                            }
                                            //className='min-w-[200px]'
                                            checked={checkAll}>
                                            <div className=''>
                                                <p className=''>{t('common:check_all')}</p>
                                            </div>
                                        </Checkbox>
                                    </Content>
                                    {optionValues &&
                                        optionValues.length > 0 &&
                                        sortObjectArrayByKey('id', 'number', optionValues, 'asc').map((item, index) => {
                                            return (
                                                <>
                                                    {getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name'
                                                    )?.length > prefixSuffixMaxLength ? (
                                                        <Tooltip
                                                            title={getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name'
                                                            )}
                                                            //className='!min-w-[200px]'
                                                            overlayStyle={{ zIndex: 1 }}
                                                            placement='right'
                                                            key={index}>
                                                            <Checkbox
                                                                key={index}
                                                                defaultChecked={
                                                                    inputData &&
                                                                    inputData.length > 0 &&
                                                                    inputData.includes(item.id)
                                                                        ? true
                                                                        : false
                                                                }
                                                                checked={
                                                                    inputData &&
                                                                    inputData.length > 0 &&
                                                                    inputData.includes(item.id)
                                                                        ? true
                                                                        : false
                                                                }
                                                                // value={inputData ? inputData : item.id}
                                                                value={item.id}
                                                                onChange={(e) =>
                                                                    onChangeData(
                                                                        e,
                                                                        attributeId,
                                                                        attributeGroupId,
                                                                        optionType,
                                                                        item.id
                                                                    )
                                                                }
                                                                className={`${
                                                                    isValidValue &&
                                                                    isValidValue.length > 0 &&
                                                                    isValidValue.includes(attributeId)
                                                                } ? "border-[1px] rounded-md border-[#ff4d4f]":""`}
                                                                disabled={
                                                                    true
                                                                    // parseInt(version_status) === 4 ||
                                                                    // parseInt(version_status) === 5 ||
                                                                    // parseInt(mode) === 0 ||
                                                                    // !isEditable
                                                                    //   ? true
                                                                    //   : (parseInt(version_status) === 3 &&
                                                                    //       revisable === true) ||
                                                                    //     (parseInt(version_status) === 2 &&
                                                                    //       revisable === true) ||
                                                                    //     parseInt(version_status) === 1
                                                                    //   ? false
                                                                    //   : true
                                                                }>
                                                                {getPrefixAndSuffix(
                                                                    attribute_unit_values,
                                                                    item.display_name,
                                                                    'name'
                                                                )}
                                                            </Checkbox>
                                                        </Tooltip>
                                                    ) : (
                                                        <Checkbox
                                                            key={index}
                                                            defaultChecked={
                                                                inputData &&
                                                                inputData.length > 0 &&
                                                                inputData.includes(item.id)
                                                                    ? true
                                                                    : false
                                                            }
                                                            checked={
                                                                inputData &&
                                                                inputData.length > 0 &&
                                                                inputData.includes(item.id)
                                                                    ? true
                                                                    : false
                                                            }
                                                            // value={inputData ? inputData : item.id}
                                                            value={item.id}
                                                            onChange={(e) =>
                                                                onChangeData(
                                                                    e,
                                                                    attributeId,
                                                                    attributeGroupId,
                                                                    optionType,
                                                                    item.id
                                                                )
                                                            }
                                                            className={`${
                                                                isValidValue &&
                                                                isValidValue.length > 0 &&
                                                                isValidValue.includes(attributeId)
                                                            } ? "border-[1px] rounded-md border-[#ff4d4f]  ":""`}
                                                            disabled={
                                                                true
                                                                // parseInt(version_status) === 4 ||
                                                                // parseInt(version_status) === 5 ||
                                                                // parseInt(mode) === 0 ||
                                                                // !isEditable
                                                                //   ? true
                                                                //   : (parseInt(version_status) === 3 &&
                                                                //       revisable === true) ||
                                                                //     (parseInt(version_status) === 2 &&
                                                                //       revisable === true) ||
                                                                //     parseInt(version_status) === 1
                                                                //   ? false
                                                                //   : true
                                                            }>
                                                            {getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name'
                                                            )}
                                                        </Checkbox>
                                                    )}
                                                </>
                                            )
                                        })}
                                </Content>
                            )

                        //Radio-SingleSelect
                        case 2:
                            return (
                                <Content
                                    className={`${
                                        isValidValue && isValidValue.length > 0 && isValidValue.includes(attributeId)
                                            ? 'p-2 border-[1px] rounded-md border-red-500'
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                        // `${
                                        //     parseInt(version_status) === 4 ||
                                        //     parseInt(version_status) === 5 ||
                                        //     parseInt(mode) === 0 ||
                                        //     !isEditable
                                        //       ? "opacity-60 !cursor-not-allowed"
                                        //       : (parseInt(version_status) === 3 &&
                                        //           revisable === true) ||
                                        //         (parseInt(version_status) === 2 &&
                                        //           revisable === true) ||
                                        //         parseInt(version_status) === 1
                                        //       ? ""
                                        //       : "opacity-60 !cursor-not-allowed"
                                        //   }`
                                    }`}>
                                    {optionValues.length > 0 &&
                                        (isEditable ? (
                                            <Content
                                                className={`${
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue &&
                                                    isValidValue.includes(attributeId)
                                                } ? "border-[1px] rounded-md border-[#ff4d4f] ":""`}>
                                                <Radio.Group
                                                    name='radiogroup'
                                                    className={`${
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue &&
                                                        isValidValue.includes(attributeId)
                                                    } ? "border-[1px] rounded-md border-[#ff4d4f] ":""`}
                                                    value={inputData && inputData.length > 0 && inputData[0]}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    onChange={(e) => {
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                        e.preventDefault()
                                                    }}>
                                                    {sortObjectArrayByKey('id', 'number', optionValues, 'asc').map(
                                                        (item, index) => {
                                                            return (
                                                                <>
                                                                    {getPrefixAndSuffix(
                                                                        attribute_unit_values,
                                                                        item.display_name,
                                                                        'full-name'
                                                                    )?.length > prefixSuffixMaxLength ? (
                                                                        <Tooltip
                                                                            title={getPrefixAndSuffix(
                                                                                attribute_unit_values,
                                                                                item.display_name,
                                                                                'full-name'
                                                                            )}
                                                                            overlayStyle={{ zIndex: 1 }}
                                                                            placement='right'
                                                                            key={item.id}
                                                                            className='min-w-[200px]'>
                                                                            <Radio
                                                                                value={item.id}
                                                                                checked={
                                                                                    inputData &&
                                                                                    inputData.length > 0 &&
                                                                                    inputData &&
                                                                                    inputData.includes(item.id)
                                                                                }
                                                                                disabled={
                                                                                    true
                                                                                    // parseInt(version_status) === 4 ||
                                                                                    // parseInt(version_status) === 5 ||
                                                                                    // parseInt(mode) === 0 ||
                                                                                    // !isEditable
                                                                                    //   ? true
                                                                                    //   : (parseInt(version_status) === 3 &&
                                                                                    //       revisable === true) ||
                                                                                    //     (parseInt(version_status) === 2 &&
                                                                                    //       revisable === true) ||
                                                                                    //     parseInt(version_status) === 1
                                                                                    //   ? false
                                                                                    //   : true
                                                                                }>
                                                                                {getPrefixAndSuffix(
                                                                                    attribute_unit_values,
                                                                                    item.display_name,
                                                                                    'name'
                                                                                )}
                                                                            </Radio>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Radio
                                                                            value={item.id}
                                                                            checked={
                                                                                inputData &&
                                                                                inputData.length > 0 &&
                                                                                inputData &&
                                                                                inputData.includes(item.id)
                                                                            }
                                                                            className='min-w-[200px]'
                                                                            disabled={
                                                                                true
                                                                                // parseInt(version_status) === 4 ||
                                                                                // parseInt(version_status) === 5 ||
                                                                                // parseInt(mode) === 0 ||
                                                                                // !isEditable
                                                                                //   ? true
                                                                                //   : (parseInt(version_status) === 3 &&
                                                                                //       revisable === true) ||
                                                                                //     (parseInt(version_status) === 2 &&
                                                                                //       revisable === true) ||
                                                                                //     parseInt(version_status) === 1
                                                                                //   ? false
                                                                                //   : true
                                                                            }>
                                                                            {getPrefixAndSuffix(
                                                                                attribute_unit_values,
                                                                                item.display_name,
                                                                                'full-name'
                                                                            )}
                                                                        </Radio>
                                                                    )}
                                                                </>
                                                            )
                                                        }
                                                    )}
                                                </Radio.Group>
                                            </Content>
                                        ) : (
                                            <Radio.Group
                                                name='radiogroup'
                                                value={nonEditableValue[0]}
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }>
                                                {sortObjectArrayByKey('id', 'number', optionValues, 'asc').map(
                                                    (item, index) => {
                                                        return (
                                                            <>
                                                                {getPrefixAndSuffix(
                                                                    attribute_unit_values,
                                                                    item.display_name,
                                                                    'full-name'
                                                                )?.length > prefixSuffixMaxLength ? (
                                                                    <Tooltip
                                                                        title={getPrefixAndSuffix(
                                                                            attribute_unit_values,
                                                                            item.display_name,
                                                                            'full-name'
                                                                        )}
                                                                        className='!min-w-[200px]'
                                                                        overlayStyle={{ zIndex: 1 }}
                                                                        placement='right'
                                                                        key={item.id}>
                                                                        <Radio
                                                                            value={item.id}
                                                                            disabled={
                                                                                true
                                                                                // parseInt(version_status) === 4 ||
                                                                                // parseInt(version_status) === 5 ||
                                                                                // parseInt(mode) === 0 ||
                                                                                // !isEditable
                                                                                //   ? true
                                                                                //   : (parseInt(version_status) === 3 &&
                                                                                //       revisable === true) ||
                                                                                //     (parseInt(version_status) === 2 &&
                                                                                //       revisable === true) ||
                                                                                //     parseInt(version_status) === 1
                                                                                //   ? false
                                                                                //   : true
                                                                            }>
                                                                            {getPrefixAndSuffix(
                                                                                attribute_unit_values,
                                                                                item.display_name,
                                                                                'name'
                                                                            )}
                                                                        </Radio>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <Radio
                                                                        value={item.id}
                                                                        disabled={
                                                                            true
                                                                            // parseInt(version_status) === 4 ||
                                                                            // parseInt(version_status) === 5 ||
                                                                            // parseInt(mode) === 0 ||
                                                                            // !isEditable
                                                                            //   ? true
                                                                            //   : (parseInt(version_status) === 3 &&
                                                                            //       revisable === true) ||
                                                                            //     (parseInt(version_status) === 2 &&
                                                                            //       revisable === true) ||
                                                                            //     parseInt(version_status) === 1
                                                                            //   ? false
                                                                            //   : true
                                                                        }
                                                                        className='!min-w-[200px]'>
                                                                        {getPrefixAndSuffix(
                                                                            attribute_unit_values,
                                                                            item.display_name,
                                                                            'full-name'
                                                                        )}
                                                                    </Radio>
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                )}
                                            </Radio.Group>
                                        ))}
                                </Content>
                            )

                        //ChoiceChips
                        case 3:
                            return (
                                <Content
                                    className={`${
                                        isValidValue && isValidValue.length > 0 && isValidValue.includes(attributeId)
                                            ? 'p-2 border-[1px] rounded-md border-red-500'
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                        // `${
                                        //     parseInt(version_status) === 4 ||
                                        //     parseInt(version_status) === 5 ||
                                        //     parseInt(mode) === 0 ||
                                        //     !isEditable
                                        //       ? "opacity-60 !cursor-not-allowed"
                                        //       : (parseInt(version_status) === 3 &&
                                        //           revisable === true) ||
                                        //         (parseInt(version_status) === 2 &&
                                        //           revisable === true) ||
                                        //         parseInt(version_status) === 1
                                        //       ? ""
                                        //       : "opacity-60 !cursor-not-allowed"
                                        //   }`
                                    }`}>
                                    {optionValues &&
                                        optionValues.length > 0 &&
                                        sortObjectArrayByKey('id', 'number', optionValues, 'asc').map((item, index) => {
                                            return (
                                                <>
                                                    {getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name',
                                                        choiceChipsPrefixSuffixMaxLength
                                                    )?.length > choiceChipsPrefixSuffixMaxLength ? (
                                                        <Tooltip
                                                            title={getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name',
                                                                choiceChipsPrefixSuffixMaxLength
                                                            )}
                                                            overlayStyle={{ zIndex: 1 }}
                                                            placement={
                                                                util.getSelectedLanguageDirection()?.toUpperCase() ===
                                                                'RTL'
                                                                    ? 'left'
                                                                    : 'right'
                                                            }
                                                            className='my-2 min-w-[100px]'
                                                            key={index}>
                                                            <Tag.CheckableTag
                                                                disabled={
                                                                    true
                                                                    // parseInt(version_status) === 4 ||
                                                                    // parseInt(version_status) === 5 ||
                                                                    // parseInt(mode) === 0 ||
                                                                    // !isEditable
                                                                    //   ? true
                                                                    //   : (parseInt(version_status) === 3 &&
                                                                    //       revisable === true) ||
                                                                    //     (parseInt(version_status) === 2 &&
                                                                    //       revisable === true) ||
                                                                    //     parseInt(version_status) === 1
                                                                    //   ? false
                                                                    //   : true
                                                                }
                                                                checked={
                                                                    inputData &&
                                                                    inputData.length > 0 &&
                                                                    inputData.includes(item.id)
                                                                }
                                                                className={`${
                                                                    isEditable
                                                                        ? 'border-2 border-gray-400 opacity-50 !cursor-not-allowed'
                                                                        : 'border-2 border-gray-400 opacity-50 hover:!text-black !cursor-not-allowed'
                                                                }`}
                                                                onChange={(checked) =>
                                                                    isEditable &&
                                                                    handleTagChange(
                                                                        item.id,
                                                                        checked,
                                                                        optionType,
                                                                        item.attribute_id
                                                                    )
                                                                }
                                                                key={index}>
                                                                {getPrefixAndSuffix(
                                                                    attribute_unit_values,
                                                                    item.display_name,
                                                                    'name',
                                                                    choiceChipsPrefixSuffixMaxLength
                                                                )}
                                                            </Tag.CheckableTag>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tag.CheckableTag
                                                            disabled={
                                                                true
                                                                // parseInt(version_status) === 4 ||
                                                                // parseInt(version_status) === 5 ||
                                                                // parseInt(mode) === 0 ||
                                                                // !isEditable
                                                                //   ? true
                                                                //   : (parseInt(version_status) === 3 &&
                                                                //       revisable === true) ||
                                                                //     (parseInt(version_status) === 2 &&
                                                                //       revisable === true) ||
                                                                //     parseInt(version_status) === 1
                                                                //   ? false
                                                                //   : true
                                                            }
                                                            checked={
                                                                inputData &&
                                                                inputData.length > 0 &&
                                                                inputData.includes(item.id)
                                                            }
                                                            className={`${
                                                                isEditable
                                                                    ? 'border-2 border-gray-400 opacity-50 !cursor-not-allowed my-2 min-w-[100px]'
                                                                    : 'border-2 border-gray-400 opacity-50 hover:!text-black !cursor-not-allowed my-2 min-w-[100px]'
                                                            }`}
                                                            onChange={(checked) =>
                                                                isEditable &&
                                                                handleTagChange(
                                                                    item.id,
                                                                    checked,
                                                                    optionType,
                                                                    item.attribute_id
                                                                )
                                                            }
                                                            key={index}>
                                                            {getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name',
                                                                choiceChipsPrefixSuffixMaxLength
                                                            )}
                                                        </Tag.CheckableTag>
                                                    )}
                                                </>
                                            )
                                        })}
                                </Content>
                            )

                        //Toggle
                        case 4:
                            return (
                                <Content
                                    className={`${
                                        isValidValue && isValidValue.length > 0 && isValidValue.includes(attributeId)
                                            ? 'p-2 border-[1px] rounded-md border-red-500'
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                        // `${
                                        //     parseInt(version_status) === 4 ||
                                        //     parseInt(version_status) === 5 ||
                                        //     parseInt(mode) === 0 ||
                                        //     !isEditable
                                        //       ? "opacity-60 !cursor-not-allowed"
                                        //       : (parseInt(version_status) === 3 &&
                                        //           revisable === true) ||
                                        //         (parseInt(version_status) === 2 &&
                                        //           revisable === true) ||
                                        //         parseInt(version_status) === 1
                                        //       ? ""
                                        //       : "opacity-60 !cursor-not-allowed"
                                        //   }`
                                    }`}>
                                    {optionValues && optionValues.length >= 2 ? (
                                        <Tooltip
                                            title={
                                                sortObjectArrayByKey('id', 'number', optionValues, 'asc')[0]
                                                    .display_name === inputData
                                                    ? sortObjectArrayByKey('id', 'number', optionValues, 'asc')[0]
                                                          .display_name
                                                    : sortObjectArrayByKey('id', 'number', optionValues, 'asc')[1]
                                                          .display_name
                                            }
                                            overlayStyle={{ zIndex: 1 }}
                                            placement='right'>
                                            <Switch
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                style={{ backgroundColor: 'gray' }}
                                                checked={
                                                    sortObjectArrayByKey('id', 'number', optionValues, 'asc')[0]
                                                        .display_name === inputData
                                                }
                                                checkedChildren={
                                                    optionValues[0].display_name &&
                                                    optionValues[0].display_name.length > 20
                                                        ? optionValues[0].display_name.slice(0, 20) + '...'
                                                        : optionValues[0].display_name
                                                }
                                                unCheckedChildren={
                                                    optionValues[1].display_name &&
                                                    optionValues[1].display_name.length > 10
                                                        ? optionValues[1].display_name.slice(0, 20) + '...'
                                                        : optionValues[1].display_name
                                                }
                                                onChange={(e) =>
                                                    onChangeToggle(
                                                        e,
                                                        attributeId,
                                                        attributeGroupId,
                                                        optionType,
                                                        sortObjectArrayByKey('id', 'number', optionValues, 'asc')[0],
                                                        sortObjectArrayByKey('id', 'number', optionValues, 'asc')[1]
                                                    )
                                                }
                                            />
                                        </Tooltip>
                                    ) : null}
                                </Content>
                            )

                        //Date
                        case 5:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {isEditable === true ? (
                                        inputData !== '' ? (
                                            <>
                                                <DatePicker
                                                    dropdownClassName='!z-0'
                                                    onKeyDown={handleKeyDown}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    // defaultValue={
                                                    //   display_name !== ""
                                                    //     ? [
                                                    //         dayjs(display_name[0], "YYYY/MM/DD"),
                                                    //         dayjs(display_name[1], "YYYY/MM/DD"),
                                                    //       ]
                                                    //     : null
                                                    // }
                                                    // format={"YYYY/MM/DD"}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    className='!w-[46%] !customPicker'
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 5
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    defaultValue={
                                                        inputData !== ''
                                                            ? dayjs(
                                                                  String(inputData),
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        )
                                                                          ? attribute_unit_values &&
                                                                            attribute_unit_values.length > 0 &&
                                                                            attribute_unit_values.find(
                                                                                (item) => String(item.key) === 'format'
                                                                            ).value
                                                                          : 'YYYY-MM-DD'
                                                                      : 'YYYY-MM-DD'
                                                              )
                                                            : null
                                                    }
                                                    onChange={(time, dateString) =>
                                                        onChangeData(
                                                            dateString,
                                                            attributeId,
                                                            attributeGroupId,
                                                            optionType
                                                        )
                                                    }
                                                    format={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'format'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'format'
                                                                  ).value
                                                                : 'YYYY-MM-DD'
                                                            : 'YYYY-MM-DD'
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <DatePicker
                                                dropdownClassName='!z-0'
                                                onKeyDown={handleKeyDown}
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                status={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? 'error'
                                                        : ''
                                                }
                                                // defaultValue={
                                                //   display_name !== ""
                                                //     ? [
                                                //         dayjs(display_name[0], "YYYY/MM/DD"),
                                                //         dayjs(display_name[1], "YYYY/MM/DD"),
                                                //       ]
                                                //     : null
                                                // }
                                                // format={"YYYY/MM/DD"}
                                                className='!w-[46%] !customPicker'
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 5)
                                                            .placeholder
                                                    }`
                                                )}
                                                onChange={(time, dateString) =>
                                                    onChangeData(dateString, attributeId, attributeGroupId, optionType)
                                                }
                                                format={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          )
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'format'
                                                              ).value
                                                            : 'YYYY-MM-DD'
                                                        : 'YYYY-MM-DD'
                                                }
                                            />
                                        )
                                    ) : (
                                        <DatePicker
                                            dropdownClassName='!z-0'
                                            onKeyDown={handleKeyDown}
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            // defaultValue={
                                            //   display_name !== ""
                                            //     ? [
                                            //         dayjs(display_name[0], "YYYY/MM/DD"),
                                            //         dayjs(display_name[1], "YYYY/MM/DD"),
                                            //       ]
                                            //     : null
                                            // }
                                            // format={"YYYY/MM/DD"}
                                            className='!w-[46%] !customPicker'
                                            placeholder={t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 5)
                                                        .placeholder
                                                }`
                                            )}
                                            value={dayjs(
                                                nonEditableValue,
                                                attribute_unit_values && attribute_unit_values.length > 0
                                                    ? attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'format'
                                                      )
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          ).value
                                                        : 'YYYY-MM-DD'
                                                    : 'YYYY-MM-DD'
                                            )}
                                            format={
                                                attribute_unit_values && attribute_unit_values.length > 0
                                                    ? attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'format'
                                                      )
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          ).value
                                                        : 'YYYY-MM-DD'
                                                    : 'YYYY-MM-DD'
                                            }
                                        />
                                    )}
                                </div>
                            )

                        //Slider
                        case 6:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {isEditable === true ? (
                                        <>
                                            <Slider
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                range={false}
                                                // style={{ width: "200px" }}
                                                // className="!w-[100%]"
                                                // defaultValue={[
                                                //   optionValues[0].display_name,
                                                //   optionValues[1].display_name,
                                                // ]}

                                                value={inputData ? inputData : 0}
                                                // onChange={onChangeData(value, attributeId)}
                                                onChange={(value) =>
                                                    onChangeData(value, attributeId, attributeGroupId, optionType)
                                                }
                                                step={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'limiter'
                                                          )
                                                            ? parseInt(
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0 &&
                                                                      attribute_unit_values.find(
                                                                          (item) => String(item.key) === 'limiter'
                                                                      ).value
                                                              )
                                                            : 1
                                                        : 1
                                                }
                                                min={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'min_value'
                                                          )
                                                            ? parseInt(
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0 &&
                                                                      attribute_unit_values.find(
                                                                          (item) => String(item.key) === 'min_value'
                                                                      ).value
                                                              )
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 6
                                                              ).min
                                                        : AttributeDataTypes.find(
                                                              (ele) => parseInt(ele.dataTypeId) === 6
                                                          ).min
                                                }
                                                max={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'max_value'
                                                          )
                                                            ? parseInt(
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0 &&
                                                                      attribute_unit_values.find(
                                                                          (item) => String(item.key) === 'max_value'
                                                                      ).value
                                                              )
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 6
                                                              ).max
                                                        : AttributeDataTypes.find(
                                                              (ele) => parseInt(ele.dataTypeId) === 6
                                                          ).max
                                                }
                                            />
                                            <Content className='flex justify-between'>
                                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                                    attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find(
                                                        (item) => String(item.key) === 'left'
                                                    ) ? (
                                                        <Text>
                                                            {attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'left'
                                                                ).value}
                                                        </Text>
                                                    ) : null
                                                ) : null}
                                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                                    attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find(
                                                        (item) => String(item.key) === 'right'
                                                    ) ? (
                                                        <Text>
                                                            {attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'right'
                                                                ).value}
                                                        </Text>
                                                    ) : null
                                                ) : null}
                                            </Content>
                                        </>
                                    ) : (
                                        <>
                                            <Slider
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                range={false}
                                                // style={{ width: "200px" }}
                                                // className="!w-[100%]"
                                                // defaultValue={[
                                                //   optionValues[0].display_name,
                                                //   optionValues[1].display_name,
                                                // ]}
                                                // defaultValue={parseInt(nonEditableValue)}
                                                value={parseInt(nonEditableValue)}
                                                min={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'min_value'
                                                          )
                                                            ? parseInt(
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0 &&
                                                                      attribute_unit_values.find(
                                                                          (item) => String(item.key) === 'min_value'
                                                                      ).value
                                                              )
                                                            : 0
                                                        : 0
                                                }
                                                max={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'max_value'
                                                          )
                                                            ? parseInt(
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0 &&
                                                                      attribute_unit_values.find(
                                                                          (item) => String(item.key) === 'max_value'
                                                                      ).value
                                                              )
                                                            : 10000
                                                        : 10000
                                                }
                                            />
                                            <Content className='flex justify-between'>
                                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                                    attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find(
                                                        (item) => String(item.key) === 'left'
                                                    ) ? (
                                                        <Text>
                                                            {attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'left'
                                                                ).value}
                                                        </Text>
                                                    ) : null
                                                ) : null}
                                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                                    attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find(
                                                        (item) => String(item.key) === 'right'
                                                    ) ? (
                                                        <Text>
                                                            {attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'right'
                                                                ).value}
                                                        </Text>
                                                    ) : null
                                                ) : null}
                                            </Content>
                                        </>
                                    )}
                                </div>
                            )

                        //Dropdown-SingleSelect
                        case 7:
                            return (
                                <Content
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    <Select
                                        placeholder={
                                            // placeholder !== false &&
                                            // placeholder !== "" &&
                                            // placeholder !== null &&
                                            // placeholder !== undefined &&
                                            // placeholder !== "False"
                                            //   ? placeholder
                                            //   : "Choose any value"
                                            t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 7)
                                                        .placeholder
                                                }`
                                            )
                                        }
                                        value={inputData ? inputData : null}
                                        style={{ width: 200 }}
                                        dropdownStyle={{ zIndex: 1 }}
                                        status={
                                            isValidValue &&
                                            isValidValue.length > 0 &&
                                            isValidValue &&
                                            isValidValue.includes(attributeId)
                                                ? 'error'
                                                : ''
                                        }
                                        disabled={
                                            true
                                            // parseInt(version_status) === 4 ||
                                            // parseInt(version_status) === 5 ||
                                            // parseInt(mode) === 0 ||
                                            // !isEditable
                                            //   ? true
                                            //   : (parseInt(version_status) === 3 &&
                                            //       revisable === true) ||
                                            //     (parseInt(version_status) === 2 &&
                                            //       revisable === true) ||
                                            //     parseInt(version_status) === 1
                                            //   ? false
                                            //   : true
                                        }
                                        onChange={(e) =>
                                            onSingleSelectChange(
                                                e,
                                                attributeId,
                                                optionType,
                                                optionValues,
                                                attribute_unit_values && attribute_unit_values.length > 0
                                                    ? attribute_unit_values.find(
                                                          (item) => String(item.key) === 'prefix_displayname'
                                                      )?.value || ''
                                                    : '',
                                                attribute_unit_values && attribute_unit_values.length > 0
                                                    ? (attribute_unit_values &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'suffix_displayname'
                                                          )?.value) ||
                                                          ''
                                                    : ''
                                            )
                                        }>
                                        {optionValues &&
                                            optionValues.length > 0 &&
                                            optionValues.map((item, index) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        dropdownStyle={{ zIndex: 1 }}
                                                        key={index}>
                                                        <Tooltip
                                                            title={getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name'
                                                            )}
                                                            overlayStyle={{ zIndex: 1 }}
                                                            placement='right'>
                                                            {getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'name'
                                                            )}
                                                        </Tooltip>
                                                    </Select.Option>
                                                )
                                            })}
                                    </Select>
                                </Content>
                            )

                        //Switcher
                        case 8:
                            return (
                                <Content
                                    className={`${
                                        isValidValue && isValidValue.length > 0 && isValidValue.includes(attributeId)
                                            ? 'p-2 border-[1px] rounded-md border-red-500'
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }`}>
                                    {optionValues && optionValues.length >= 2 ? (
                                        <Radio.Group
                                            defaultValue={inputData && inputData[0]}
                                            disabled={true}
                                            value={inputData && inputData[0]}
                                            onChange={(e) =>
                                                onChangeData(e, attributeId, attributeGroupId, optionType)
                                            }>
                                            {sortObjectArrayByKey('id', 'number', optionValues, 'asc').map(
                                                (item, index) => {
                                                    return (
                                                        <>
                                                            {getPrefixAndSuffix(
                                                                attribute_unit_values,
                                                                item.display_name,
                                                                'full-name'
                                                            )?.length > prefixSuffixMaxLength ? (
                                                                <Tooltip
                                                                    key={index}
                                                                    title={getPrefixAndSuffix(
                                                                        attribute_unit_values,
                                                                        item.display_name,
                                                                        'full-name'
                                                                    )}
                                                                    overlayStyle={{ zIndex: 1 }}
                                                                    placement='right'>
                                                                    <Radio.Button
                                                                        value={item.id}
                                                                        checked={
                                                                            inputData &&
                                                                            inputData.length > 0 &&
                                                                            inputData.includes(item.id)
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        disabled={
                                                                            true
                                                                            // parseInt(version_status) === 4 ||
                                                                            // parseInt(version_status) === 5 ||
                                                                            // parseInt(mode) === 0 ||
                                                                            // !isEditable
                                                                            //   ? true
                                                                            //   : (parseInt(version_status) === 3 &&
                                                                            //       revisable === true) ||
                                                                            //     (parseInt(version_status) === 2 &&
                                                                            //       revisable === true) ||
                                                                            //     parseInt(version_status) === 1
                                                                            //   ? false
                                                                            //   : true
                                                                        }>
                                                                        {getPrefixAndSuffix(
                                                                            attribute_unit_values,
                                                                            item.display_name,
                                                                            'name'
                                                                        )}
                                                                    </Radio.Button>
                                                                </Tooltip>
                                                            ) : (
                                                                <Radio.Button
                                                                    value={item.id}
                                                                    checked={
                                                                        inputData &&
                                                                        inputData.length > 0 &&
                                                                        inputData.includes(item.id)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    disabled={
                                                                        true
                                                                        // parseInt(version_status) === 4 ||
                                                                        // parseInt(version_status) === 5 ||
                                                                        // parseInt(mode) === 0 ||
                                                                        // !isEditable
                                                                        //   ? true
                                                                        //   : (parseInt(version_status) === 3 &&
                                                                        //       revisable === true) ||
                                                                        //     (parseInt(version_status) === 2 &&
                                                                        //       revisable === true) ||
                                                                        //     parseInt(version_status) === 1
                                                                        //   ? false
                                                                        //   : true
                                                                    }>
                                                                    {getPrefixAndSuffix(
                                                                        attribute_unit_values,
                                                                        item.display_name,
                                                                        'full-name'
                                                                    )}
                                                                </Radio.Button>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            )}
                                        </Radio.Group>
                                    ) : null}
                                </Content>
                            )

                        //Time
                        case 9:
                            return (
                                <Content
                                    className={`${
                                        isValidValue && isValidValue.length > 0 && isValidValue.includes(attributeId)
                                            ? ''
                                            : 'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                        // `${
                                        //     parseInt(version_status) === 4 ||
                                        //     parseInt(version_status) === 5 ||
                                        //     parseInt(mode) === 0 ||
                                        //     !isEditable
                                        //       ? "opacity-60 !cursor-not-allowed"
                                        //       : (parseInt(version_status) === 3 &&
                                        //           revisable === true) ||
                                        //         (parseInt(version_status) === 2 &&
                                        //           revisable === true) ||
                                        //         parseInt(version_status) === 1
                                        //       ? ""
                                        //       : "opacity-60 !cursor-not-allowed"
                                        //   }`
                                    }`}>
                                    {isEditable === true ? (
                                        inputData !== '' ? (
                                            <TimePicker
                                                dropdownClassName='!z-0'
                                                onKeyDown={handleTimeKeyDown}
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                use12Hours={slotNameProps === 'Slot Details' ? false : true}
                                                className='!w-[46%] !customPicker'
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 9)
                                                            .placeholder
                                                    }`
                                                )}
                                                status={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? 'error'
                                                        : ''
                                                }
                                                format={
                                                    slotNameProps === 'Slot Details'
                                                        ? 'HH:mm'
                                                        : attribute_unit_values && attribute_unit_values.length > 0
                                                          ? attribute_unit_values &&
                                                            attribute_unit_values.length > 0 &&
                                                            attribute_unit_values.find(
                                                                (item) => String(item.key) === 'format'
                                                            )
                                                              ? attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'format'
                                                                ).value
                                                              : 'HH:mm:ss A'
                                                          : 'HH:mm:ss A'
                                                }
                                                // defaultValue={
                                                //   dateValue !== "" ? dayjs(dateValue, "h:mm:ss A") : ""
                                                // }
                                                value={
                                                    inputData !== ''
                                                        ? slotNameProps === 'Slot Details'
                                                            ? dayjs(inputData, 'HH:mm')
                                                            : dayjs(
                                                                  inputData,
                                                                  attribute_unit_values &&
                                                                      attribute_unit_values.length > 0
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        )
                                                                          ? attribute_unit_values &&
                                                                            attribute_unit_values.length > 0 &&
                                                                            attribute_unit_values.find(
                                                                                (item) => String(item.key) === 'format'
                                                                            ).value
                                                                          : 'HH:mm:ss A'
                                                                      : 'HH:mm:ss A'
                                                              )
                                                        : ''
                                                }
                                                style={{
                                                    width: 140,
                                                    marginTop: '7px',
                                                }}
                                                onChange={(time, timeString) =>
                                                    onChangeData(timeString, attributeId, attributeGroupId, optionType)
                                                }
                                            />
                                        ) : (
                                            <TimePicker
                                                dropdownClassName='!z-0'
                                                onKeyDown={handleTimeKeyDown}
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                use12Hours={slotNameProps === 'Slot Details' ? false : true}
                                                className='!w-[46%] !customPicker'
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 9)
                                                            .placeholder
                                                    }`
                                                )}
                                                format={
                                                    slotNameProps === 'Slot Details'
                                                        ? 'HH:mm'
                                                        : attribute_unit_values && attribute_unit_values.length > 0
                                                          ? attribute_unit_values &&
                                                            attribute_unit_values.length > 0 &&
                                                            attribute_unit_values.find(
                                                                (item) => String(item.key) === 'format'
                                                            )
                                                              ? attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'format'
                                                                ).value
                                                              : 'HH:mm:ss A'
                                                          : 'HH:mm:ss A'
                                                }
                                                status={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? 'error'
                                                        : ''
                                                }
                                                // defaultValue={
                                                //   dateValue !== "" ? dayjs(dateValue, "h:mm:ss A") : ""
                                                // }
                                                // value={dateValue !== "" ? dayjs(dateValue, "h:mm:ss A") : ""}
                                                //defaultValue={dayjs(inputData, "HH:mm:ss A")}
                                                // style={{
                                                //   width: 140,
                                                //   marginTop: "7px",
                                                // }}
                                                onChange={(time, timeString) =>
                                                    onChangeData(timeString, attributeId, attributeGroupId, optionType)
                                                }
                                            />
                                        )
                                    ) : (
                                        <TimePicker
                                            dropdownClassName='!z-0'
                                            onKeyDown={handleTimeKeyDown}
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            use12Hours={slotNameProps === 'Slot Details' ? false : true}
                                            className='!w-[46%] !customPicker'
                                            placeholder={t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 9)
                                                        .placeholder
                                                }`
                                            )}
                                            format={
                                                slotNameProps === 'Slot Details'
                                                    ? 'HH:mm'
                                                    : attribute_unit_values && attribute_unit_values.length > 0
                                                      ? attribute_unit_values &&
                                                        attribute_unit_values.length > 0 &&
                                                        attribute_unit_values.find(
                                                            (item) => String(item.key) === 'format'
                                                        )
                                                          ? attribute_unit_values &&
                                                            attribute_unit_values.length > 0 &&
                                                            attribute_unit_values.find(
                                                                (item) => String(item.key) === 'format'
                                                            ).value
                                                          : 'HH:mm:ss A'
                                                      : 'HH:mm:ss A'
                                            }
                                            defaultValue={
                                                nonEditableValue !== ''
                                                    ? slotNameProps === 'Slot Details'
                                                        ? dayjs(nonEditableValue, 'HH:mm')
                                                        : dayjs(
                                                              nonEditableValue,
                                                              attribute_unit_values && attribute_unit_values.length > 0
                                                                  ? attribute_unit_values &&
                                                                    attribute_unit_values.length > 0 &&
                                                                    attribute_unit_values.find(
                                                                        (item) => String(item.key) === 'format'
                                                                    )
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        ).value
                                                                      : 'HH:mm:ss A'
                                                                  : 'HH:mm:ss A'
                                                          )
                                                    : null
                                            }
                                            value={
                                                nonEditableValue !== ''
                                                    ? slotNameProps === 'Slot Details'
                                                        ? dayjs(nonEditableValue, 'HH:mm')
                                                        : dayjs(
                                                              nonEditableValue,
                                                              attribute_unit_values && attribute_unit_values.length > 0
                                                                  ? attribute_unit_values &&
                                                                    attribute_unit_values.length > 0 &&
                                                                    attribute_unit_values.find(
                                                                        (item) => String(item.key) === 'format'
                                                                    )
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        ).value
                                                                      : 'HH:mm:ss A'
                                                                  : 'HH:mm:ss A'
                                                          )
                                                    : null
                                            }
                                            style={{
                                                width: 140,
                                                marginTop: '7px',
                                            }}
                                        />
                                    )}
                                </Content>
                            )

                        // TextRich
                        case 10:
                            return (
                                <Content
                                    className={
                                        'opacity-60 !cursor-not-allowed bg-[#F5F5F5] hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   !isEditable
                                    //     ? "bg-[#F5F5F5] !cursor-not-allowed"
                                    //     : parseInt(version_status) === 4 ||
                                    //       parseInt(version_status) === 5 ||
                                    //       parseInt(mode) === 0 ||
                                    //       !isEditable
                                    //     ? "bg-[#F5F5F5] !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "bg-[#F5F5F5]"
                                    // }
                                    // ${isEditable ? "" : "!cursor-not-allowed"}`}
                                >
                                    <Content
                                        // className={`${
                                        //   isValidValue &&
                                        //   isValidValue.length > 0 &&
                                        //   isValidValue.includes(attributeId)
                                        //     ? "p-2 border-2 border-red-500"
                                        //     : ""
                                        // }`}
                                        className={`${!isEditable ? '!w-[100%]' : '!w-[100%] '}`}>
                                        <Content className='!w-[100%] relative'>
                                            <TextRichEditor
                                                data={
                                                    textRichData ? textRichData : null
                                                    // : valuesFromServer &&
                                                    //   valuesFromServer.length > 0 &&
                                                    //   valuesFromServer[0] &&
                                                    //   valuesFromServer[0].length > 0 &&
                                                    //   valuesFromServer[0][0].display_value
                                                }
                                                className='w-[100%] pl-[6px]'
                                                setData={setTextRichData}
                                                editable={
                                                    true
                                                    // !isEditable
                                                    //   ? true
                                                    //   : parseInt(version_status) === 4 ||
                                                    //     parseInt(version_status) === 5 ||
                                                    //     parseInt(mode) === 0 ||
                                                    //     !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                // placeholder={placeholder}
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 10
                                                        ).placeholder
                                                    }`
                                                )}
                                                isTextFieldEmpty={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? true
                                                        : false
                                                }
                                            />

                                            <Content
                                                className={`!w-[5%] absolute top-0 ${
                                                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                        ? 'left-4'
                                                        : 'right-4'
                                                }`}>
                                                {storeLanguageFromReduxState &&
                                                storeLanguageFromReduxState.length > 1 &&
                                                attributeType !== 'Journey' &&
                                                parseInt(version_status) <= 3 ? (
                                                    <TranslationOutlined
                                                        size={20}
                                                        className='!p-[13px] iconsHoverClass !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                                        onClick={() =>
                                                            handleAttributesValuesTranslaterModal({
                                                                type: 'textrich',
                                                                attributeId: attributeId,
                                                                value: valuesFromServer[0][0].value,
                                                                // placeholder: placeholder,
                                                                placeholder: t(
                                                                    `common:${
                                                                        AttributeDataTypes.find(
                                                                            (ele) => parseInt(ele.dataTypeId) === 10
                                                                        ).placeholder
                                                                    }`
                                                                ),
                                                                attributeGroupId: attributeGroupId,
                                                                optionType: optionType,
                                                                titileName: titleName,
                                                            })
                                                        }
                                                    />
                                                ) : null}
                                            </Content>
                                        </Content>
                                    </Content>
                                </Content>
                            )

                        //Text Email
                        case 11:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {sensisble === false ? (
                                        <Input
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            maxLength={emailMaxLength}
                                            prefix={<MailOutlined />}
                                            className='!w-[100%]'
                                            placeholder={t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 11)
                                                        .placeholder
                                                }`
                                            )}
                                            // value={inputData}
                                            value={inputData}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, '')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <CustomPasswordInput
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            prefix={<MailOutlined />}
                                            // className="w-[70%] pl-[6px]"
                                            className='!w-[100%]'
                                            // placeholder="Enter Your Email Address"
                                            placeholder={
                                                // placeholder !== false &&
                                                // placeholder !== "" &&
                                                // placeholder !== null &&
                                                // placeholder !== undefined &&
                                                // placeholder !== "False"
                                                //   ? placeholder
                                                //   : "Enter e-mail"
                                                t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 11
                                                        ).placeholder
                                                    }`
                                                )
                                            }
                                            
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                            // value={inputData}
                                            value={inputData}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, '')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                        />
                                    )}
                                </div>
                            )

                        //text PhoneNumber
                        case 12:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(version_status) === 2 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {sensisble === false ? (
                                        <>
                                            <Input
                                                className='w-[15%] rounded-tr-none rounded-br-none'
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                value={phoneCode}
                                                onChange={(e) => {
                                                    setIsPhoneCodeNotValid(false)
                                                    // let number = /^[0-9]*$/.test(e.target.value);
                                                    // to allow only 10 digits
                                                    if (/^\+(\d{1}\-)?(\d{1,3})$/.test(e.target.value)) {
                                                        // let phoneNumber = /^(?:00|\\+)[0-9]*$/.test(e.target.value);
                                                        setPhoneCode(e.target.value)
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                    } else if (e.target.value === '+') {
                                                        setPhoneCode(e.target.value)
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                    }
                                                }}
                                                status={
                                                    isPhoneCodeNotValid
                                                        ? 'error'
                                                        : isValidValue &&
                                                            isValidValue.length > 0 &&
                                                            isValidValue.includes(attributeId)
                                                          ? 'error'
                                                          : ''
                                                }></Input>
                                            <Input
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                // addonBefore="+91"
                                                maxLength={10}
                                                placeholder={
                                                    // placeholder !== false &&
                                                    // placeholder !== "" &&
                                                    // placeholder !== null &&
                                                    // placeholder !== undefined &&
                                                    // placeholder !== "False"
                                                    //   ? placeholder
                                                    //   : "Enter phone number"
                                                    t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 12
                                                            ).placeholder
                                                        }`
                                                    )
                                                }
                                                onChange={(e) => {
                                                    // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                                    // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                                    let number = /^\d{1,10}$/.test(e.target.value)
                                                    // let number1 = /^[0-9]*$/.test(e.target.value);
                                                    // to allow only 10 digits
                                                    if (
                                                        number &&
                                                        parseInt(e.target.value) &&
                                                        String(e.target.value).length <= 10
                                                    ) {
                                                        // setOptionValue(e.target.value, obj.id);
                                                        setPhoneNumber(e.target.value)
                                                        setIsPhoneNumberNotValid(false)
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneNumber')
                                                    } else if (e.target.value === '') {
                                                        // setOptionValue(e.target.value, obj.id);
                                                        setPhoneNumber(e.target.value)
                                                        setIsPhoneNumberNotValid(false)
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneNumber')
                                                    }
                                                }}
                                                value={phoneNumber}
                                                // className="w-[70%]"
                                                className='!w-[85%] addonbefore-grayout rounded-tl-none rounded-bl-none'
                                                status={
                                                    isPhoneNumberNotValid
                                                        ? 'error'
                                                        : isValidValue &&
                                                            isValidValue.length > 0 &&
                                                            isValidValue.includes(attributeId)
                                                          ? 'error'
                                                          : ''
                                                }
                                                // onChange={(e) => {
                                                //   // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                                //   // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                                //   let number = /^[0-9]*$/.test(e.target.value);
                                                //   // to allow only 10 digits
                                                //   if (number && e.target.value.length <= 10) {
                                                //     onChangeData(
                                                //       e,
                                                //       attributeId,
                                                //       attributeGroupId,
                                                //       optionType
                                                //     );
                                                //   }
                                                // }}
                                                //value={display_name}
                                            />
                                        </>
                                    ) : (
                                        <div className='flex'>
                                            <div className='!w-[15%]'>
                                                <CustomPasswordInput
                                                    className=' rounded-tr-none rounded-br-none'
                                                    visibility={phoneCodeVisibility}
                                                    setVisibility={setPhoneCodeVisibility}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    value={phoneCode}
                                                    onChange={(e) => {
                                                        setIsPhoneCodeNotValid(false)
                                                        // let number = /^[0-9]*$/.test(e.target.value);
                                                        // to allow only 10 digits
                                                        if (/^\+(\d{1}\-)?(\d{1,3})$/.test(e.target.value)) {
                                                            // let phoneNumber = /^(?:00|\\+)[0-9]*$/.test(e.target.value);
                                                            setPhoneCode(e.target.value)
                                                            // onChangeData(
                                                            //   e,
                                                            //   attributeId,
                                                            //   attributeGroupId,
                                                            //   optionType
                                                            // );
                                                            onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                            // onChangeData(
                                                            //   e,
                                                            //   attributeId,
                                                            //   attributeGroupId,
                                                            //   optionType
                                                            // );
                                                        } else if (e.target.value === '+') {
                                                            setPhoneCode(e.target.value)
                                                            onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                            // onChangeData(
                                                            //   e,
                                                            //   attributeId,
                                                            //   attributeGroupId,
                                                            //   optionType
                                                            // );
                                                        }
                                                    }}
                                                    status={
                                                        isPhoneCodeNotValid
                                                            ? 'error'
                                                            : isValidValue &&
                                                                isValidValue.length > 0 &&
                                                                isValidValue.includes(attributeId)
                                                              ? 'error'
                                                              : ''
                                                    }
                                                    // width={'15%'}
                                                />
                                            </div>
                                            <div className='!w-[85%]'>
                                                <CustomPasswordInput
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    maxLength={10} 
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    placeholder={
                                                        // placeholder !== false &&
                                                        // placeholder !== "" &&
                                                        // placeholder !== null &&
                                                        // placeholder !== undefined &&
                                                        // placeholder !== "False"
                                                        //   ? placeholder
                                                        //   : "Enter phone number"
                                                        t(
                                                            `common:${
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 12
                                                                ).placeholder
                                                            }`
                                                        )
                                                    }
                                                    onChange={(e) => {
                                                        // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                                        // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                                        let number = /^[0-9]*$/.test(e.target.value)
                                                        // to allow only 10 digits

                                                        if (number && String(e.target.value).length <= 10) {
                                                            // setOptionValue(e.target.value, obj.id);
                                                            setPhoneNumber(e.target.value)
                                                            setIsPhoneNumberNotValid(false)
                                                            // onChangeData(
                                                            //   e,
                                                            //   attributeId,
                                                            //   attributeGroupId,
                                                            //   optionType
                                                            // );
                                                            onChangePhoneNumber(
                                                                e,
                                                                attributeId,
                                                                optionType,
                                                                'phoneNumber'
                                                            )
                                                        } else if (e.target.value === '') {
                                                            // setOptionValue(e.target.value, obj.id);
                                                            setPhoneNumber(e.target.value)
                                                            setIsPhoneNumberNotValid(false)
                                                            onChangePhoneNumber(
                                                                e,
                                                                attributeId,
                                                                optionType,
                                                                'phoneNumber'
                                                            )
                                                        }
                                                    }}
                                                    value={phoneNumber}
                                                    className=' addonbefore-grayout rounded-tl-none rounded-bl-none'
                                                    // width={'50%'}
                                                    status={
                                                        isPhoneNumberNotValid
                                                            ? 'error'
                                                            : isValidValue &&
                                                                isValidValue.length > 0 &&
                                                                isValidValue.includes(attributeId)
                                                              ? 'error'
                                                              : ''
                                                    }></CustomPasswordInput>
                                            </div>
                                            {/* <CustomPasswordInput
                                                className='w-[15%] rounded-tr-none rounded-br-none'
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                value={phoneCode}
                                                
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                onChange={(e) => {
                                                    setIsPhoneCodeNotValid(false)
                                                    // let number = /^[0-9]*$/.test(e.target.value);
                                                    // to allow only 10 digits
                                                    if (/^\+(\d{1}\-)?(\d{1,3})$/.test(e.target.value)) {
                                                        // let phoneNumber = /^(?:00|\\+)[0-9]*$/.test(e.target.value);
                                                        setPhoneCode(e.target.value)
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                    } else if (e.target.value === '+') {
                                                        setPhoneCode(e.target.value)
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneCode')
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                    }
                                                }}
                                                status={
                                                    isPhoneCodeNotValid
                                                        ? 'error'
                                                        : isValidValue &&
                                                            isValidValue.length > 0 &&
                                                            isValidValue.includes(attributeId)
                                                          ? 'error'
                                                          : ''
                                                }></Input.Password>
                                            <CustomPasswordInput
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                // addonBefore="+91"
                                                maxLength={10}
                                                placeholder={
                                                    // placeholder !== false &&
                                                    // placeholder !== "" &&
                                                    // placeholder !== null &&
                                                    // placeholder !== undefined &&
                                                    // placeholder !== "False"
                                                    //   ? placeholder
                                                    //   : "Enter phone number"
                                                    t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 12
                                                            ).placeholder
                                                        }`
                                                    )
                                                }
                                                
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                onChange={(e) => {
                                                    // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                                    // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                                    let number = /^[0-9]*$/.test(e.target.value)
                                                    // to allow only 10 digits

                                                    if (number && String(e.target.value).length <= 10) {
                                                        // setOptionValue(e.target.value, obj.id);
                                                        setPhoneNumber(e.target.value)
                                                        setIsPhoneNumberNotValid(false)
                                                        // onChangeData(
                                                        //   e,
                                                        //   attributeId,
                                                        //   attributeGroupId,
                                                        //   optionType
                                                        // );
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneNumber')
                                                    } else if (e.target.value === '') {
                                                        // setOptionValue(e.target.value, obj.id);
                                                        setPhoneNumber(e.target.value)
                                                        setIsPhoneNumberNotValid(false)
                                                        onChangePhoneNumber(e, attributeId, optionType, 'phoneNumber')
                                                    }
                                                }}
                                                value={phoneNumber}
                                                // className="w-[70%]"
                                                className='!w-[85%] addonbefore-grayout rounded-tl-none rounded-bl-none'
                                                status={
                                                    isPhoneNumberNotValid
                                                        ? 'error'
                                                        : isValidValue &&
                                                            isValidValue.length > 0 &&
                                                            isValidValue.includes(attributeId)
                                                          ? 'error'
                                                          : ''
                                                }
                                            /> */}
                                        </div>
                                    )}
                                </div>
                            )

                        //Text url
                        case 13:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {sensisble === false ? (
                                        <Input
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            prefix={<GlobalOutlined />}
                                            // className="w-[70%] pl-[7px]"
                                            className='!w-[100%]'
                                            // placeholder={
                                            //   // placeholder !== false &&
                                            //   // placeholder !== "" &&
                                            //   // placeholder !== null &&
                                            //   // placeholder !== undefined &&
                                            //   // placeholder !== "False"
                                            //   //   ? placeholder
                                            //   //   : "Enter url"
                                            //   t(
                                            //     `common:${
                                            //       AttributeDataTypes.find(
                                            //         (ele) => parseInt(ele.dataTypeId) === 13
                                            //       ).placeholder
                                            //     }`
                                            //   )
                                            // }
                                            placeholder={
                                                String(attributeItem.name).toUpperCase() === 'DIGITAL CONTENT URL' &&
                                                parseInt(attributeItem.product_type_id_id) === 4
                                                    ? t('products:this_is_digital_field_placeholder')
                                                    : t(
                                                          `common:${
                                                              AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 13
                                                              ).placeholder
                                                          }`
                                                      )
                                            }
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                            value={inputData}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, '')
                                                setInputData(trimmedUpdate)
                                            }}
                                            //value={display_name}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                        />
                                    ) : (
                                        <CustomPasswordInput
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            prefix={<GlobalOutlined />}
                                            // className="w-[70%] pl-[7px]"
                                            className='!w-[100%]'
                                            
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                            placeholder={
                                                // placeholder !== false &&
                                                // placeholder !== "" &&
                                                // placeholder !== null &&
                                                // placeholder !== undefined &&
                                                // placeholder !== "False"
                                                //   ? placeholder
                                                //   : "Enter url"
                                                t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 13
                                                        ).placeholder
                                                    }`
                                                )
                                            }
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                            value={inputData}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, '')
                                                setInputData(trimmedUpdate)
                                            }}
                                            //value={display_name}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                        />
                                    )}
                                </div>
                            )

                        //Date and Time
                        case 14:
                            return (
                                <Content
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {isEditable === true ? (
                                        inputData !== '' ? (
                                            <DatePicker
                                                showTime
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                // defaultValue={
                                                //   dateValue !== ""
                                                //     ? dayjs(dateValue, "YYYY-MM-DD HH:mm:ss")
                                                //     : null
                                                // }
                                                className='!w-[46%]'
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 14
                                                        ).placeholder
                                                    }`
                                                )}
                                                status={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? 'error'
                                                        : ''
                                                }
                                                defaultValue={
                                                    inputData !== ''
                                                        ? dayjs(
                                                              inputData,
                                                              attribute_unit_values && attribute_unit_values.length > 0
                                                                  ? attribute_unit_values &&
                                                                    attribute_unit_values.length > 0 &&
                                                                    attribute_unit_values.find(
                                                                        (item) => String(item.key) === 'format'
                                                                    )
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        ).value
                                                                      : 'YYYY-MM-DD HH:mm:ss'
                                                                  : 'YYYY-MM-DD HH:mm:ss'
                                                          )
                                                        : null
                                                }
                                                format={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          )
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'format'
                                                              ).value
                                                            : 'YYYY-MM-DD HH:mm:ss'
                                                        : 'YYYY-MM-DD HH:mm:ss'
                                                }
                                                onChange={(time, timeString) =>
                                                    onChangeData(timeString, attributeId, attributeGroupId, optionType)
                                                }
                                            />
                                        ) : (
                                            <DatePicker
                                                showTime
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 14
                                                        ).placeholder
                                                    }`
                                                )}
                                                className='w-[46%]'
                                                status={
                                                    isValidValue &&
                                                    isValidValue.length > 0 &&
                                                    isValidValue.includes(attributeId)
                                                        ? 'error'
                                                        : ''
                                                }
                                                format={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          )
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'format'
                                                              ).value
                                                            : 'YYYY-MM-DD HH:mm:ss'
                                                        : 'YYYY-MM-DD HH:mm:ss'
                                                }
                                                // defaultValue={
                                                //   dateValue !== ""
                                                //     ? dayjs(dateValue, "YYYY-MM-DD HH:mm:ss")
                                                //     : null
                                                // }
                                                // value={
                                                //   dateValue !== ""
                                                //     ? dayjs(dateValue, "YYYY-MM-DD HH:mm:ss")
                                                //     : null
                                                // }
                                                onChange={(time, timeString) =>
                                                    onChangeData(timeString, attributeId, attributeGroupId, optionType)
                                                }
                                            />
                                        )
                                    ) : (
                                        <div>
                                            <DatePicker
                                                showTime
                                                disabled={
                                                    true
                                                    // parseInt(version_status) === 4 ||
                                                    // parseInt(version_status) === 5 ||
                                                    // parseInt(mode) === 0 ||
                                                    // !isEditable
                                                    //   ? true
                                                    //   : (parseInt(version_status) === 3 &&
                                                    //       revisable === true) ||
                                                    //     (parseInt(version_status) === 2 &&
                                                    //       revisable === true) ||
                                                    //     parseInt(version_status) === 1
                                                    //   ? false
                                                    //   : true
                                                }
                                                format={
                                                    attribute_unit_values && attribute_unit_values.length > 0
                                                        ? attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'format'
                                                          )
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'format'
                                                              ).value
                                                            : 'YYYY-MM-DD HH:mm:ss'
                                                        : 'YYYY-MM-DD HH:mm:ss'
                                                }
                                                defaultValue={
                                                    nonEditableValue !== ''
                                                        ? dayjs(
                                                              nonEditableValue,
                                                              attribute_unit_values && attribute_unit_values.length > 0
                                                                  ? attribute_unit_values &&
                                                                    attribute_unit_values.length > 0 &&
                                                                    attribute_unit_values.find(
                                                                        (item) => String(item.key) === 'format'
                                                                    )
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        ).value
                                                                      : 'YYYY-MM-DD HH:mm:ss'
                                                                  : 'YYYY-MM-DD HH:mm:ss'
                                                          )
                                                        : null
                                                }
                                                className='w-[46%]'
                                                placeholder={t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 14
                                                        ).placeholder
                                                    }`
                                                )}
                                                value={
                                                    nonEditableValue !== ''
                                                        ? dayjs(
                                                              nonEditableValue,
                                                              attribute_unit_values && attribute_unit_values.length > 0
                                                                  ? attribute_unit_values &&
                                                                    attribute_unit_values.length > 0 &&
                                                                    attribute_unit_values.find(
                                                                        (item) => String(item.key) === 'format'
                                                                    )
                                                                      ? attribute_unit_values &&
                                                                        attribute_unit_values.length > 0 &&
                                                                        attribute_unit_values.find(
                                                                            (item) => String(item.key) === 'format'
                                                                        ).value
                                                                      : 'YYYY-MM-DD HH:mm:ss'
                                                                  : 'YYYY-MM-DD HH:mm:ss'
                                                          )
                                                        : null
                                                }
                                            />
                                        </div>
                                    )}
                                </Content>
                            )

                        // File
                        case 15:
                            return (
                                <Content>
                                    {isEditable ? (
                                        <>
                                            <Content>
                                                <Content
                                                    className={
                                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                                    }
                                                    //   `${
                                                    //   parseInt(version_status) === 4 ||
                                                    //   parseInt(version_status) === 5 ||
                                                    //   parseInt(mode) === 0 ||
                                                    //   !isEditable
                                                    //     ? "opacity-60 !cursor-not-allowed"
                                                    //     : (parseInt(version_status) === 3 &&
                                                    //         revisable === true) ||
                                                    //       (parseInt(version_status) === 2 &&
                                                    //         revisable === true) ||
                                                    //       parseInt(version_status) === 1
                                                    //     ? ""
                                                    //     : "opacity-60 !cursor-not-allowed"
                                                    // }`}
                                                >
                                                    {' '}
                                                    <Button
                                                        className={`${
                                                            isValidValue &&
                                                            isValidValue.length > 0 &&
                                                            isValidValue.includes(attributeId)
                                                                ? 'border-1 border-red-500'
                                                                : ''
                                                        }`}
                                                        disabled={
                                                            true
                                                            // parseInt(version_status) === 4 ||
                                                            // parseInt(version_status) === 5 ||
                                                            // parseInt(mode) === 0 ||
                                                            // !isEditable
                                                            //   ? true
                                                            //   : (parseInt(version_status) === 3 &&
                                                            //       revisable === true) ||
                                                            //     (parseInt(version_status) === 2 &&
                                                            //       revisable === true) ||
                                                            //     parseInt(version_status) === 1
                                                            //   ? false
                                                            //   : true
                                                        }
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: '18px',
                                                            fontWeight: 500,
                                                        }}
                                                        block
                                                        onClick={() => setOpenFileModel(true)}>
                                                        {fileDataToDisplayImage && fileDataToDisplayImage.length > 0
                                                            ? t('attributes:change_file')
                                                            : t('attributes:add_file')}
                                                    </Button>
                                                </Content>
                                            </Content>
                                            {fileDataToDisplayImage && fileDataToDisplayImage.length > 0 ? (
                                                <DisplayImage
                                                    ImageData={
                                                        typeof fileDataToDisplayImage === 'string'
                                                            ? convertUrlToObjectForFileUpload(
                                                                  fileDataToDisplayImage,
                                                                  'document'
                                                              )
                                                            : fileDataToDisplayImage
                                                    }
                                                    setAllImages={setFileData}
                                                    fileType={'document'}
                                                    deleteFromServer={false}
                                                    size={'large'}></DisplayImage>
                                            ) : null}
                                        </>
                                    ) : (
                                        <Content className='flex'>
                                            {nonEditableValue !== undefined &&
                                            nonEditableValue !== null &&
                                            nonEditableValue !== '' ? (
                                                <Row>
                                                    <DisplayImage
                                                        ImageData={
                                                            // typeof nonEditableValue === "string"
                                                            //   ? convertUrlToObjectForFileUpload(
                                                            //       nonEditableValue,
                                                            //       "document"
                                                            //     )
                                                            //   : nonEditableValue
                                                            nonEditableValue !== ''
                                                                ? generateObjectFromUrlWithFullDocumentPath(
                                                                      nonEditableValue
                                                                  )
                                                                : nonEditableValue
                                                        }
                                                        setAllImages={setFileData}
                                                        fileType={'document'}
                                                        deleteFromServer={false}
                                                        size={'large'}></DisplayImage>
                                                </Row>
                                            ) : (
                                                'No File was Selected'
                                            )}
                                        </Content>
                                    )}
                                </Content>
                            )

                        //File-Image
                        case 16:
                            return (
                                <Content>
                                    {isEditable ? (
                                        <Content>
                                            <Content
                                                className={
                                                    'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                                }
                                                //   `${
                                                //   parseInt(version_status) === 4 ||
                                                //   parseInt(version_status) === 5 ||
                                                //   parseInt(mode) === 0 ||
                                                //   !isEditable
                                                //     ? "opacity-60 !cursor-not-allowed"
                                                //     : (parseInt(version_status) === 3 &&
                                                //         revisable === true) ||
                                                //       (parseInt(version_status) === 2 &&
                                                //         revisable === true) ||
                                                //       parseInt(version_status) === 1
                                                //     ? ""
                                                //     : "opacity-60 !cursor-not-allowed"
                                                // }`}
                                            >
                                                <Button
                                                    // type="dashed"
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '18px',
                                                        fontWeight: 500,
                                                    }}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    className={`${
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'border-1 border-red-500'
                                                            : ''
                                                    }`}
                                                    block
                                                    onClick={() => setOpenImageModel(true)}>
                                                    {imageDataToDisplayImage && imageDataToDisplayImage.length > 0
                                                        ? t('attributes:change_image')
                                                        : t('attributes:add_image')}
                                                </Button>
                                            </Content>
                                            {imageDataToDisplayImage && imageDataToDisplayImage.length > 0 ? (
                                                <Row>
                                                    <DisplayImage
                                                        ImageData={
                                                            typeof imageDataToDisplayImage === 'string'
                                                                ? convertUrlToObjectForFileUpload(
                                                                      imageDataToDisplayImage,
                                                                      'image'
                                                                  )
                                                                : imageDataToDisplayImage
                                                        }
                                                        setAllImages={setImageData}
                                                        fileType={'image'}
                                                        deleteFromServer={false}
                                                        size={'large'}
                                                        closeButton={false}></DisplayImage>
                                                </Row>
                                            ) : null}
                                        </Content>
                                    ) : (
                                        <Content className='flex'>
                                            {nonEditableValue !== undefined &&
                                            nonEditableValue !== null &&
                                            nonEditableValue !== '' ? (
                                                <Row>
                                                    <DisplayImage
                                                        ImageData={
                                                            // typeof nonEditableValue === "string"
                                                            //   ? convertUrlToObjectForFileUpload(
                                                            //       nonEditableValue,
                                                            //       "image"
                                                            //     )
                                                            //   : nonEditableValue
                                                            nonEditableValue !== ''
                                                                ? generateObjectFromUrlWithFullImagePath(
                                                                      nonEditableValue
                                                                  )
                                                                : nonEditableValue
                                                        }
                                                        setAllImages={setImageData}
                                                        fileType={'image'}
                                                        deleteFromServer={false}
                                                        size={'large'}
                                                        // closeButton={}
                                                        closeButton={false}></DisplayImage>
                                                </Row>
                                            ) : (
                                                'No Image was Selected'
                                            )}
                                        </Content>
                                    )}
                                </Content>
                            )

                        //file -video
                        case 17:
                            return <p>File-video uploading not yet done</p>

                        //file-docs
                        case 18:
                            return (
                                <Content>
                                    {isEditable ? (
                                        <>
                                            <Content
                                                className={
                                                    'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                                }
                                                //   `${
                                                //   parseInt(version_status) === 4 ||
                                                //   parseInt(version_status) === 5 ||
                                                //   parseInt(mode) === 0 ||
                                                //   !isEditable
                                                //     ? "opacity-60 !cursor-not-allowed"
                                                //     : (parseInt(version_status) === 3 &&
                                                //         revisable === true) ||
                                                //       (parseInt(version_status) === 2 &&
                                                //         revisable === true) ||
                                                //       parseInt(version_status) === 1
                                                //     ? ""
                                                //     : "opacity-60 !cursor-not-allowed"
                                                // }`}
                                            >
                                                <Button
                                                    // type="dashed"
                                                    className={`${
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? ' border-1 border-red-500'
                                                            : ''
                                                    }`}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        // marginBottom: "18px",
                                                        fontWeight: 500,
                                                    }}
                                                    block
                                                    onClick={() => setOpenDocumentModel(true)}>
                                                    {documentDataToDisplayImage && documentDataToDisplayImage.length > 0
                                                        ? t('attributes:change_document')
                                                        : t('attributes:add_document')}
                                                </Button>
                                            </Content>
                                            {documentDataToDisplayImage && documentDataToDisplayImage.length > 0 ? (
                                                <DisplayImage
                                                    ImageData={
                                                        typeof documentDataToDisplayImage === 'string'
                                                            ? generateObjectFromUrlWithFullDocumentPath(
                                                                  documentDataToDisplayImage,
                                                                  'document'
                                                              )
                                                            : documentDataToDisplayImage
                                                    }
                                                    setAllImages={setDocumentData}
                                                    fileType={'document'}
                                                    deleteFromServer={false}
                                                    size={'large'}></DisplayImage>
                                            ) : null}
                                        </>
                                    ) : (
                                        <Content className='flex'>
                                            {nonEditableValue !== undefined &&
                                            nonEditableValue !== null &&
                                            nonEditableValue !== '' ? (
                                                <Row>
                                                    <DisplayImage
                                                        ImageData={
                                                            // typeof nonEditableValue === "string"
                                                            //   ? convertUrlToObjectForFileUpload(
                                                            //       nonEditableValue,
                                                            //       "document"
                                                            //     )
                                                            //   : nonEditableValue
                                                            nonEditableValue !== ''
                                                                ? generateObjectFromUrlWithFullDocumentPath(
                                                                      nonEditableValue
                                                                  )
                                                                : nonEditableValue
                                                        }
                                                        setAllImages={setImageData}
                                                        fileType={'document'}
                                                        deleteFromServer={false}
                                                        size={'large'}></DisplayImage>
                                                </Row>
                                            ) : (
                                                'No Document was Selected'
                                            )}
                                        </Content>
                                    )}
                                </Content>
                            )

                        //file-pdf
                        case 19:
                            return (
                                <Content>
                                    {isEditable ? (
                                        <>
                                            <Content
                                                className={
                                                    'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                                }
                                                //   `${
                                                //   parseInt(version_status) === 4 ||
                                                //   parseInt(version_status) === 5 ||
                                                //   parseInt(mode) === 0 ||
                                                //   !isEditable
                                                //     ? "opacity-60 !cursor-not-allowed"
                                                //     : (parseInt(version_status) === 3 &&
                                                //         revisable === true) ||
                                                //       (parseInt(version_status) === 2 &&
                                                //         revisable === true) ||
                                                //       parseInt(version_status) === 1
                                                //     ? ""
                                                //     : "opacity-60 !cursor-not-allowed"
                                                // }`}
                                            >
                                                <Button
                                                    // type="dashed"
                                                    className={`${
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'border-1 border-red-500'
                                                            : ''
                                                    }`}
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    style={{
                                                        width: '100%',
                                                        marginBottom: '18px',
                                                        fontWeight: 500,
                                                    }}
                                                    block
                                                    onClick={() => setOpenPdfModel(true)}>
                                                    {pdfDataToDisplayImage && pdfDataToDisplayImage.length > 0
                                                        ? t('attributes:Change-Pdf')
                                                        : t('attributes:Add-Pdf')}
                                                </Button>
                                            </Content>
                                            <Row>
                                                {pdfDataToDisplayImage && pdfDataToDisplayImage.length > 0 ? (
                                                    <DisplayImage
                                                        ImageData={
                                                            typeof pdfDataToDisplayImage === 'string'
                                                                ? convertUrlToObjectForFileUpload(
                                                                      pdfDataToDisplayImage,
                                                                      'document'
                                                                  )
                                                                : pdfDataToDisplayImage
                                                        }
                                                        setAllImages={setPdfData}
                                                        fileType={'document'}
                                                        deleteFromServer={false}
                                                        size={'large'}></DisplayImage>
                                                ) : null}
                                            </Row>
                                        </>
                                    ) : (
                                        <Content className='flex'>
                                            {nonEditableValue !== undefined &&
                                            nonEditableValue !== null &&
                                            nonEditableValue !== '' ? (
                                                <Row>
                                                    <DisplayImage
                                                        ImageData={
                                                            // typeof nonEditableValue === "string"
                                                            //   ? convertUrlToObjectForFileUpload(
                                                            //       nonEditableValue,
                                                            //       "document"
                                                            //     )
                                                            //   : nonEditableValue
                                                            nonEditableValue !== ''
                                                                ? generateObjectFromUrlWithFullDocumentPath(
                                                                      nonEditableValue
                                                                  )
                                                                : nonEditableValue
                                                        }
                                                        setAllImages={setImageData}
                                                        fileType={'pdf'}
                                                        deleteFromServer={false}
                                                        size={'large'}></DisplayImage>
                                                </Row>
                                            ) : (
                                                'No PDF was Selected'
                                            )}
                                        </Content>
                                    )}
                                </Content>
                            )
                        //Float
                        case 20:
                            return (
                                <div
                                    className={
                                        'opacity-60 !cursor-not-allowed hover:cursor-not-allowed hover:pointer-events-none'
                                    }
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {/* {expression
                        ? expression.replaceJSX(
                            "constant",
                            <InputNumber
                              // className="w-[100%]"
                              className="mx-2"
                              min="0.01"
                              placeholder={"Enter Constant here"}
                              disabled={isEditable}
                              // value={nonEditableValue}
                              // step="0.01"
                              stringMode
                            />
                          )
                        : "Expression is not available"} */}
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : String(attributeItem.name).toUpperCase() ===
                                                        //       "SELLING PRICE" &&
                                                        //     parseInt(attributeItem.product_type_id_id) === 2
                                                        //   ? true
                                                        //   : String(attributeItem.name).toUpperCase() ===
                                                        //       "PRODUCT PRICE" &&
                                                        //     parseInt(attributeItem.product_type_id_id) === 2
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    className='w-[100%]'
                                                    //defaultValue={display_name}
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 20
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 20
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 20
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 20
                                                              ).max
                                                    }
                                                    step='0.01'
                                                    placeholder={
                                                        (String(attributeItem.name).toUpperCase() === 'SELLING PRICE' &&
                                                            parseInt(attributeItem.product_type_id_id) === 2) ||
                                                        (String(attributeItem.name).toUpperCase() === 'PRODUCT PRICE' &&
                                                            parseInt(attributeItem.product_type_id_id) === 2)
                                                            ? t('products:this_is_auto_calculated_field')
                                                            : t(
                                                                  `common:${
                                                                      AttributeDataTypes.find(
                                                                          (ele) => parseInt(ele.dataTypeId) === 20
                                                                      ).placeholder
                                                                  }`
                                                              )
                                                    }
                                                    value={inputData && parseFloat(inputData)}
                                                    stringMode
                                                    addonBefore={
                                                        String(attributeItem.name).toUpperCase() === 'SELLING PRICE' ||
                                                        String(attributeItem.name).toUpperCase() === 'PRODUCT PRICE'
                                                            ? storeSettingsInfo[0]?.store_currency[0]?.symbol
                                                            : ''
                                                    }
                                                    // formatter={handleFormatter}
                                                    // parser={handleParser}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    // onKeyPress={(e) => {
                                                    //   validatePositiveNumber(e, /[0-9]|\./);
                                                    // }}
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(e, /^[0-9.]+$/)
                                                    }}
                                                    maxLength={12}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : String(attributeItem.name).toUpperCase() ===
                                                        //       "SELLING PRICE" &&
                                                        //     parseInt(attributeItem.product_type_id_id) === 2
                                                        //   ? true
                                                        //   : String(attributeItem.name).toUpperCase() ===
                                                        //       "PRODUCT PRICE" &&
                                                        //     parseInt(attributeItem.product_type_id_id) === 2
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    className='w-[100%]'
                                                    //defaultValue={display_name}
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 20
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 20
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 20
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 20
                                                              ).max
                                                    }
                                                    step='0.01'
                                                    addonBefore={
                                                        String(attributeItem.name).toUpperCase() === 'SELLING PRICE' ||
                                                        String(attributeItem.name).toUpperCase() === 'PRODUCT PRICE'
                                                            ? storeSettingsInfo[0]?.store_currency[0]?.symbol
                                                            : ''
                                                    }
                                                    // formatter={handleFormatter}
                                                    // parser={handleParser}
                                                    placeholder={
                                                        (String(attributeItem.name).toUpperCase() === 'SELLING PRICE' &&
                                                            parseInt(attributeItem.product_type_id_id) === 2) ||
                                                        (String(attributeItem.name).toUpperCase() === 'PRODUCT PRICE' &&
                                                            parseInt(attributeItem.product_type_id_id) === 2)
                                                            ? t('products:this_is_auto_calculated_field')
                                                            : t(
                                                                  `common:${
                                                                      AttributeDataTypes.find(
                                                                          (ele) => parseInt(ele.dataTypeId) === 20
                                                                      ).placeholder
                                                                  }`
                                                              )
                                                    }
                                                    value={inputData}
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    // stringMode
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    // onKeyPress={(e) => {
                                                    //   validatePositiveNumber(e, /[0-9]|\./);
                                                    // }}
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(e, /^[0-9.]+$/)
                                                    }}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //Percentage
                        case 21:
                            return (
                                <div className={'opacity-60 !cursor-not-allowed'}>
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {isEditable === true ? (
                                                <>
                                                    {sensisble === false ? (
                                                        <InputNumber
                                                            disabled={true}
                                                            min={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).min
                                                            }
                                                            max={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).max
                                                            }
                                                            className='!w-[100%]'
                                                            // step={1}
                                                            placeholder={t(
                                                                `common:${
                                                                    AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 21
                                                                    ).placeholder
                                                                }`
                                                            )}
                                                            suffix='%'
                                                            defaultValue={inputData}
                                                            formatter={(value) => (value !== '' ? `${value}` : '')}
                                                            // value={inputData}
                                                            onChange={(e) =>
                                                                onChangeData(
                                                                    e,
                                                                    attributeId,
                                                                    attributeGroupId,
                                                                    optionType
                                                                )
                                                            }
                                                            onKeyPress={(e) => {
                                                                validatePositiveNumber(e, /[0-9]/)
                                                            }}
                                                            status={
                                                                isValidValue &&
                                                                isValidValue.length > 0 &&
                                                                isValidValue.includes(attributeId)
                                                                    ? 'error'
                                                                    : ''
                                                            }
                                                        />
                                                    ) : (
                                                        <CustomPasswordInput
                                                            disabled={true}
                                                            min={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).min
                                                            }
                                                            max={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).max
                                                            }
                                                            visibilityToggle={{
                                                                visible: true,
                                                            }}
                                                            suffix='%'
                                                            className='!w-[100%]'
                                                            placeholder={t(
                                                                `common:${
                                                                    AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 21
                                                                    ).placeholder
                                                                }`
                                                            )}
                                                            value={
                                                                inputData !== '' || inputData !== null
                                                                    ? `${inputData}`
                                                                    : inputData
                                                            }
                                                            formatter={(value) => (value !== '' ? `${value}` : '')}
                                                            onChange={(e) =>
                                                                onChangeData(
                                                                    e,
                                                                    attributeId,
                                                                    attributeGroupId,
                                                                    optionType
                                                                )
                                                            }
                                                            onKeyPress={(e) => {
                                                                validatePositiveNumber(e, /[0-9]/)
                                                            }}
                                                            status={
                                                                isValidValue &&
                                                                isValidValue.length > 0 &&
                                                                isValidValue.includes(attributeId)
                                                                    ? 'error'
                                                                    : ''
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <div>
                                                    {sensisble === false ? (
                                                        <InputNumber
                                                            disabled={true}
                                                            className='!w-[100%]'
                                                            min={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).min
                                                            }
                                                            suffix='%'
                                                            max={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).max
                                                            }
                                                            formatter={(value) => (value !== '' ? `${value}` : '')}
                                                            placeholder={t(
                                                                `common:${
                                                                    AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 21
                                                                    ).placeholder
                                                                }`
                                                            )}
                                                            //defaultValue={display_name}
                                                            // formatter={(value) => `${value}%`}
                                                            value={nonEditableValue}
                                                            onChange={(e) =>
                                                                onChangeData(
                                                                    e,
                                                                    attributeId,
                                                                    attributeGroupId,
                                                                    optionType
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <CustomPasswordInput
                                                            disabled={true}
                                                            className='!w-[100%]'
                                                            min={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).min
                                                            }
                                                            max={
                                                                AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 21
                                                                ).max
                                                            }
                                                            visibilityToggle={{
                                                                visible: true,
                                                            }}
                                                            suffix='%'
                                                            placeholder={t(
                                                                `common:${
                                                                    AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 21
                                                                    ).placeholder
                                                                }`
                                                            )}
                                                            value={nonEditableValue}
                                                            formatter={(value) => (value !== '' ? `${value}` : '')}
                                                            onChange={(e) =>
                                                                onChangeData(
                                                                    e,
                                                                    attributeId,
                                                                    attributeGroupId,
                                                                    optionType
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //Integer
                        case 22:
                            return (
                                <div
                                    className={'opacity-60 !cursor-not-allowed'}
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {/* {expression
                        ? expression.replaceJSX(
                            "constant",
                            <InputNumber
                              // className="w-[100%]"
                              className="mx-2"
                              min="0.01"
                              placeholder={"Enter Constant here"}
                              disabled={isEditable}
                              // value={nonEditableValue}
                              // step="0.01"
                              stringMode
                            />
                          )
                        : "Expression not available"} */}
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    className='w-[100%]'
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 22
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 22
                                                              ).min
                                                    }
                                                    max={
                                                        slotNameProps === 'Slot Details'
                                                            ? 1440
                                                            : attribute_unit_values && attribute_unit_values.length > 0
                                                              ? attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'max'
                                                                )
                                                                  ? parseInt(
                                                                        attribute_unit_values &&
                                                                            attribute_unit_values.length > 0 &&
                                                                            attribute_unit_values.find(
                                                                                (item) => String(item.key) === 'max'
                                                                            ).value
                                                                    )
                                                                  : AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 22
                                                                    ).max
                                                              : AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 22
                                                                ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 22
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    value={
                                                        inputData !== null &&
                                                        inputData !== undefined &&
                                                        inputData !== false &&
                                                        inputData !== 'False'
                                                            ? inputData
                                                            : ''
                                                    }
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveNumber(e, /[0-9]/)
                                                    }}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    //defaultValue={display_name}
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    className='w-[100%]'
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 22
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 22
                                                              ).min
                                                    }
                                                    max={
                                                        slotNameProps === 'Slot Details'
                                                            ? 1440
                                                            : attribute_unit_values && attribute_unit_values.length > 0
                                                              ? attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'max'
                                                                )
                                                                  ? parseInt(
                                                                        attribute_unit_values &&
                                                                            attribute_unit_values.length > 0 &&
                                                                            attribute_unit_values.find(
                                                                                (item) => String(item.key) === 'max'
                                                                            ).value
                                                                    )
                                                                  : AttributeDataTypes.find(
                                                                        (ele) => parseInt(ele.dataTypeId) === 22
                                                                    ).max
                                                              : AttributeDataTypes.find(
                                                                    (ele) => parseInt(ele.dataTypeId) === 22
                                                                ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 22
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    value={
                                                        inputData !== null &&
                                                        inputData !== undefined &&
                                                        inputData !== false &&
                                                        inputData !== 'False'
                                                            ? inputData
                                                            : ''
                                                    }
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveNumber(e, /[0-9]/)
                                                    }}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    //defaultValue={display_name}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //Integer Limit
                        case 23:
                            return (
                                <div
                                    className={'opacity-60 !cursor-not-allowed'}
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {/* {expression
                        ? expression.replaceJSX(
                            "constant",
                            <InputNumber
                              // className="w-[100%]"
                              className="mx-2"
                              min="0.01"
                              placeholder={"Enter Constant here"}
                              disabled={isEditable}
                              // value={nonEditableValue}
                              // step="0.01"
                              stringMode
                            />
                          )
                        : "Expression not available"} */}
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    className='w-[100%]'
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    value={inputData}
                                                    onKeyPress={(e) => {
                                                        validatePositiveNumber(e, /[0-9]/)
                                                    }}
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 23
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 23
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 23
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 23
                                                              ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 23
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    //defaultValue={display_name}
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    className='w-[100%]'
                                                    disabled={
                                                        true
                                                        // parseInt(version_status) === 4 ||
                                                        // parseInt(version_status) === 5 ||
                                                        // parseInt(mode) === 0 ||
                                                        // !isEditable
                                                        //   ? true
                                                        //   : (parseInt(version_status) === 3 &&
                                                        //       revisable === true) ||
                                                        //     (parseInt(version_status) === 2 &&
                                                        //       revisable === true) ||
                                                        //     parseInt(version_status) === 1
                                                        //   ? false
                                                        //   : true
                                                    }
                                                    value={inputData}
                                                    onBlur={() =>
                                                        compareMinAndMaxForSensitiveFields(
                                                            inputData,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'min'
                                                                ).value,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'max'
                                                                ).value
                                                        )
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveNumber(e, /[0-9]/)
                                                    }}
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 23
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 23
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 23
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 23
                                                              ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 23
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                    //defaultValue={display_name}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //Float limit
                        case 24:
                            return (
                                <div className={'opacity-60 !cursor-not-allowed'}>
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    step='0.01'
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'min'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 24
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 24
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'max'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 24
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 24
                                                              ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 24
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    stringMode
                                                    value={inputData}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(e, /^[0-9.]+$/)
                                                    }}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 24
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    stringMode
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'min'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 24
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 24
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'max'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 24
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 24
                                                              ).max
                                                    }
                                                    value={inputData}
                                                    onBlur={() =>
                                                        compareMinAndMaxForSensitiveFields(
                                                            inputData,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'min'
                                                                ).value,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'max'
                                                                ).value
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType, null)
                                                    }
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(e, /^[0-9.]+$/)
                                                    }}
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //Float Round off
                        case 25:
                            return (
                                <div className={'opacity-60 !cursor-not-allowed'}>
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    //defaultValue={display_name}
                                                    step='0.01'
                                                    value={inputData}
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 25
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    stringMode
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(
                                                            e,
                                                            /^[0-9.]+$/,
                                                            (attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'decimal'
                                                                )?.value) ||
                                                                null
                                                        )
                                                    }}
                                                    precision={
                                                        attribute_unit_values &&
                                                        attribute_unit_values.length > 0 &&
                                                        attribute_unit_values.find(
                                                            (item) => String(item.key) === 'decimal'
                                                        )?.value
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'decimal'
                                                              )?.value
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).decimal
                                                    }
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 25
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 25
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 25
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 25
                                                              ).max
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    //defaultValue={display_name}
                                                    step='0.01'
                                                    value={inputData}
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 25
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    stringMode
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(
                                                            e,
                                                            /^[0-9.]+$/,
                                                            (attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'decimal'
                                                                )?.value) ||
                                                                null
                                                        )
                                                    }}
                                                    precision={
                                                        attribute_unit_values &&
                                                        attribute_unit_values.length > 0 &&
                                                        attribute_unit_values.find(
                                                            (item) => String(item.key) === 'decimal'
                                                        )?.value
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'decimal'
                                                              )?.value
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).decimal
                                                    }
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'min'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 25
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 25
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? parseInt(
                                                                      attribute_unit_values &&
                                                                          attribute_unit_values.length > 0 &&
                                                                          attribute_unit_values.find(
                                                                              (item) => String(item.key) === 'max'
                                                                          ).value
                                                                  )
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 25
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 25
                                                              ).max
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //FloatRoundOffLimit
                        case 26:
                            return (
                                <div className={'opacity-60 !cursor-not-allowed'}>
                                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                                        <>
                                            {expression !== 'null' && expression !== undefined && expression !== ''
                                                ? expression
                                                : 'Expression is not available'}
                                        </>
                                    ) : (
                                        <>
                                            {sensisble === false ? (
                                                <InputNumber
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    step='0.01'
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'min'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 26
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'max'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 26
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).max
                                                    }
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 26
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    stringMode
                                                    value={inputData}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(
                                                            e,
                                                            /^[0-9.]+$/,
                                                            (attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'decimal'
                                                                )?.value) ||
                                                                null
                                                        )
                                                    }}
                                                    precision={
                                                        attribute_unit_values &&
                                                        attribute_unit_values.length > 0 &&
                                                        attribute_unit_values.find(
                                                            (item) => String(item.key) === 'decimal'
                                                        )?.value
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'decimal'
                                                              )?.value
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).decimal
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <CustomPasswordInput
                                                    disabled={true}
                                                    className='w-[100%]'
                                                    onBlur={() =>
                                                        compareMinAndMaxForSensitiveFields(
                                                            inputData,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'min'
                                                                ).value,
                                                            attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'max'
                                                                ).value
                                                        )
                                                    }
                                                    min={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'min'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'min'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 26
                                                                  ).min
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).min
                                                    }
                                                    max={
                                                        attribute_unit_values && attribute_unit_values.length > 0
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'max'
                                                              )
                                                                ? attribute_unit_values &&
                                                                  attribute_unit_values.length > 0 &&
                                                                  attribute_unit_values.find(
                                                                      (item) => String(item.key) === 'max'
                                                                  ).value
                                                                : AttributeDataTypes.find(
                                                                      (ele) => parseInt(ele.dataTypeId) === 26
                                                                  ).max
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).max
                                                    }
                                                    
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                                    placeholder={t(
                                                        `common:${
                                                            AttributeDataTypes.find(
                                                                (ele) => parseInt(ele.dataTypeId) === 26
                                                            ).placeholder
                                                        }`
                                                    )}
                                                    precision={
                                                        attribute_unit_values &&
                                                        attribute_unit_values.length > 0 &&
                                                        attribute_unit_values.find(
                                                            (item) => String(item.key) === 'decimal'
                                                        )?.value
                                                            ? attribute_unit_values &&
                                                              attribute_unit_values.length > 0 &&
                                                              attribute_unit_values.find(
                                                                  (item) => String(item.key) === 'decimal'
                                                              )?.value
                                                            : AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 26
                                                              ).decimal
                                                    }
                                                    onKeyPress={(e) => {
                                                        validatePositiveFloatNumber(
                                                            e,
                                                            /^[0-9.]+$/,
                                                            (attribute_unit_values &&
                                                                attribute_unit_values.length > 0 &&
                                                                attribute_unit_values.find(
                                                                    (item) => String(item.key) === 'decimal'
                                                                )?.value) ||
                                                                null
                                                        )
                                                    }}
                                                    value={inputData}
                                                    onChange={(e) =>
                                                        onChangeData(e, attributeId, attributeGroupId, optionType)
                                                    }
                                                    status={
                                                        isValidValue &&
                                                        isValidValue.length > 0 &&
                                                        isValidValue.includes(attributeId)
                                                            ? 'error'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            )

                        //TextName
                        case 27:
                            return (
                                <div className={'opacity-60 !cursor-not-allowed'}>
                                    {sensisble === false ? (
                                        <Input
                                            disabled={true}
                                            className='w-[100%]'
                                            minLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).min
                                            }
                                            maxLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).max
                                            }
                                            prefix={<UserOutlined />}
                                            value={inputData}
                                            addonAfter={
                                                storeLanguageFromReduxState &&
                                                storeLanguageFromReduxState.length > 1 &&
                                                attributeType !== 'Journey' &&
                                                parseInt(version_status) <= 3 ? (
                                                    <TranslationOutlined
                                                        className='iconsHoverClass !cursor-not-allowed'
                                                        onClick={() =>
                                                            handleAttributesValuesTranslaterModal({
                                                                type: 'textname',
                                                                attributeId: attributeId,
                                                                value: valuesFromServer[0][0].value,
                                                                // placeholder: placeholder,
                                                                placeholder: t(
                                                                    `common:${
                                                                        AttributeDataTypes.find(
                                                                            (ele) => parseInt(ele.dataTypeId) === 27
                                                                        ).placeholder
                                                                    }`
                                                                ),
                                                                attributeGroupId: attributeGroupId,
                                                                optionType: optionType,
                                                                titileName: titleName,
                                                            })
                                                        }
                                                    />
                                                ) : null
                                            }
                                            placeholder={t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27)
                                                        .placeholder
                                                }`
                                            )}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => {
                                                const alphaWithoutSpaces = /^[a-zA-Z]+$/
                                                if (
                                                    e.target.value !== '' &&
                                                    validator.matches(e.target.value, alphaWithoutSpaces)
                                                ) {
                                                    onChangeData(e, attributeId, attributeGroupId, optionType)
                                                } else if (e.target.value === '') {
                                                    onChangeData(e, attributeId, attributeGroupId, optionType)
                                                }
                                            }}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <CustomPasswordInput
                                            disabled={true}
                                            className='w-[100%]'
                                            prefix={<UserOutlined />}
                                            value={inputData}
                                            minLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).min
                                            }
                                            maxLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).max
                                            }
                                            // max={
                                            //   AttributeDataTypes.find(
                                            //     (ele) => parseInt(ele.dataTypeId) === 27
                                            //   ).max
                                            // }
                                            
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                            addonAfter={
                                                storeLanguageFromReduxState &&
                                                storeLanguageFromReduxState.length > 1 &&
                                                attributeType !== 'Journey' &&
                                                parseInt(version_status) <= 3 ? (
                                                    <TranslationOutlined
                                                        className='iconsHoverClass !cursor-not-allowed'
                                                        onClick={() =>
                                                            handleAttributesValuesTranslaterModal({
                                                                type: 'textname',
                                                                attributeId: attributeId,
                                                                value: valuesFromServer[0][0].value,
                                                                // placeholder: placeholder,
                                                                placeholder: t(
                                                                    `common:${
                                                                        AttributeDataTypes.find(
                                                                            (ele) => parseInt(ele.dataTypeId) === 27
                                                                        ).placeholder
                                                                    }`
                                                                ),
                                                                attributeGroupId: attributeGroupId,
                                                                optionType: optionType,
                                                                titileName: titleName,
                                                            })
                                                        }
                                                    />
                                                ) : null
                                            }
                                            placeholder={
                                                // placeholder !== false &&
                                                // placeholder !== "" &&
                                                // placeholder !== null &&
                                                // placeholder !== undefined &&
                                                // placeholder !== "False"
                                                //   ? placeholder
                                                //   : "Enter name"
                                                t(
                                                    `common:${
                                                        AttributeDataTypes.find(
                                                            (ele) => parseInt(ele.dataTypeId) === 27
                                                        ).placeholder
                                                    }`
                                                )
                                            }
                                            // placeholder={"Enter name"}
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                            // onKeyPress={(e) => {
                                            //   let charCode = e.which || e.keyCode;
                                            //   if (!restrictNumbersAndSpecialCharacter(charCode)) {
                                            //     // will prevent the number and special character, not to be entered.
                                            //     e.preventDefault();
                                            //   }
                                            // }}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                        />
                                    )}
                                </div>
                            )

                        //ListProductImages
                        case 28:
                            return <p>List product images not yet done</p>

                        //File audio
                        case 29:
                            return <p>file audio not yet done</p>

                        //Text
                        case 30:
                            return (
                                <div
                                    className={'opacity-60 !cursor-not-allowed'}
                                    //   `${
                                    //   parseInt(version_status) === 4 ||
                                    //   parseInt(version_status) === 5 ||
                                    //   parseInt(mode) === 0 ||
                                    //   !isEditable
                                    //     ? "opacity-60 !cursor-not-allowed"
                                    //     : (parseInt(version_status) === 3 &&
                                    //         revisable === true) ||
                                    //       (parseInt(version_status) === 2 &&
                                    //         revisable === true) ||
                                    //       parseInt(version_status) === 1
                                    //     ? ""
                                    //     : "opacity-60 !cursor-not-allowed"
                                    // }`}
                                >
                                    {sensisble === false ? (
                                        <Input
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            className='w-[100%]'
                                            value={inputData}
                                            // placeholder={
                                            //   // placeholder !== false &&
                                            //   // placeholder !== "" &&
                                            //   // placeholder !== null &&
                                            //   // placeholder !== undefined &&
                                            //   // placeholder !== "False"
                                            //   //   ? placeholder
                                            //   //   : "Enter text"
                                            //   t(
                                            //     `common:${
                                            //       AttributeDataTypes.find(
                                            //         (ele) => parseInt(ele.dataTypeId) === 30
                                            //       ).placeholder
                                            //     }`
                                            //   )
                                            // }
                                            placeholder={
                                                String(attributeItem.name).toUpperCase() === 'DIGITAL FILE NAME' &&
                                                parseInt(attributeItem.product_type_id_id) === 4
                                                    ? t('products:this_is_digital_field_placeholder')
                                                    : t(
                                                          `common:${
                                                              AttributeDataTypes.find(
                                                                  (ele) => parseInt(ele.dataTypeId) === 30
                                                              ).placeholder
                                                          }`
                                                      )
                                            }
                                            // min={
                                            //   AttributeDataTypes.find(
                                            //     (ele) => parseInt(ele.dataTypeId) === 30
                                            //   ).min
                                            // }
                                            minLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).min
                                            }
                                            maxLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).max
                                            }
                                            // max={
                                            //   AttributeDataTypes.find(
                                            //     (ele) => parseInt(ele.dataTypeId) === 30
                                            //   ).max
                                            // }
                                            addonAfter={
                                                storeLanguageFromReduxState &&
                                                storeLanguageFromReduxState.length > 1 &&
                                                attributeType !== 'Journey' &&
                                                parseInt(version_status) <= 3 ? (
                                                    <TranslationOutlined
                                                        className='iconsHoverClass !cursor-not-allowed'
                                                        onClick={() =>
                                                            handleAttributesValuesTranslaterModal({
                                                                type: 'text',
                                                                attributeId: attributeId,
                                                                value: valuesFromServer[0][0].value,
                                                                // placeholder: placeholder,
                                                                placeholder: t(
                                                                    `common:${
                                                                        AttributeDataTypes.find(
                                                                            (ele) => parseInt(ele.dataTypeId) === 30
                                                                        ).placeholder
                                                                    }`
                                                                ),
                                                                attributeGroupId: attributeGroupId,
                                                                optionType: optionType,
                                                                titileName: titleName,
                                                            })
                                                        }
                                                    />
                                                ) : null
                                            }
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                            //value={display_name}
                                        />
                                    ) : (
                                        <CustomPasswordInput
                                            disabled={
                                                true
                                                // parseInt(version_status) === 4 ||
                                                // parseInt(version_status) === 5 ||
                                                // parseInt(mode) === 0 ||
                                                // !isEditable
                                                //   ? true
                                                //   : (parseInt(version_status) === 3 &&
                                                //       revisable === true) ||
                                                //     (parseInt(version_status) === 2 &&
                                                //       revisable === true) ||
                                                //     parseInt(version_status) === 1
                                                //   ? false
                                                //   : true
                                            }
                                            className='w-[100%]'
                                            value={inputData}
                                            // min={
                                            //   AttributeDataTypes.find(
                                            //     (ele) => parseInt(ele.dataTypeId) === 30
                                            //   ).min
                                            // }
                                            minLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).min
                                            }
                                            maxLength={
                                                AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).max
                                            }
                                            
                                                    visibility={sensitiveVisibility}
                                                    setVisibility={setSensitiveVisibility}
                                            // max={
                                            //   AttributeDataTypes.find(
                                            //     (ele) => parseInt(ele.dataTypeId) === 30
                                            //   ).max
                                            // }
                                            placeholder={t(
                                                `common:${
                                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30)
                                                        .placeholder
                                                }`
                                            )}
                                            addonAfter={
                                                storeLanguageFromReduxState &&
                                                storeLanguageFromReduxState.length > 1 &&
                                                attributeType !== 'Journey' &&
                                                parseInt(version_status) <= 3 ? (
                                                    <TranslationOutlined
                                                        className='iconsHoverClass !cursor-not-allowed'
                                                        onClick={() =>
                                                            handleAttributesValuesTranslaterModal({
                                                                type: 'text',
                                                                attributeId: attributeId,
                                                                value: valuesFromServer[0][0].value,
                                                                // placeholder: placeholder,
                                                                placeholder: t(
                                                                    `common:${
                                                                        AttributeDataTypes.find(
                                                                            (ele) => parseInt(ele.dataTypeId) === 30
                                                                        ).placeholder
                                                                    }`
                                                                ),
                                                                attributeGroupId: attributeGroupId,
                                                                optionType: optionType,
                                                                titileName: titleName,
                                                            })
                                                        }
                                                    />
                                                ) : null
                                            }
                                            onBlur={() => {
                                                const trimmed = inputData.trim()
                                                const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                setInputData(trimmedUpdate)
                                            }}
                                            onChange={(e) => onChangeData(e, attributeId, attributeGroupId, optionType)}
                                            status={
                                                isValidValue &&
                                                isValidValue.length > 0 &&
                                                isValidValue.includes(attributeId)
                                                    ? 'error'
                                                    : ''
                                            }
                                            //value={display_name}
                                        />
                                    )}
                                </div>
                            )
                        default:
                            return ''
                    }
                })()}
            </div>
        )
    }
    const translateModalCloseHandler = () => {
        setTranslateModalVisible(false)
        // dispatch(fnMakeAPICall2({ makeAPICall2: false }));
    }
    const handleAttributesValuesTranslaterModal = (val) => {
        setDataForAttributeValueTranslater(val)
        setTranslateModalVisible(true)
        setOnChangeDisableFields(true)
    }
    return (
        <Content>
            <Content>{renderControls()}</Content>
            <>
                {openImageModel && (
                    <ListImageAndAddImage
                        openModal={openImageModel}
                        setOpenModal={setOpenImageModel}
                        actionType={['published_products']}
                        selectedFileData={imageData}
                        setSelectedFileData={setImageData}
                        selectionOption={'single'}
                        supportedExtensions={'.png,.jpg,.jpeg'}
                        fileType={'image'}></ListImageAndAddImage>
                )}
            </>
            <>
                {openDocumentModel && (
                    <ListImageAndAddImage
                        openModal={openDocumentModel}
                        setOpenModal={setOpenDocumentModel}
                        actionType={['published_products']}
                        selectedFileData={documentData}
                        setSelectedFileData={setDocumentData}
                        selectionOption={'single'}
                        supportedExtensions={'.doc,.docx'}
                        fileType={'document'}></ListImageAndAddImage>
                )}
            </>
            <>
                {openPdfModel && (
                    <ListImageAndAddImage
                        openModal={openPdfModel}
                        setOpenModal={() => {
                            setOpenPdfModel()
                        }}
                        actionType={['published_products']}
                        selectedFileData={pdfData}
                        setSelectedFileData={setPdfData}
                        selectionOption={'single'}
                        supportedExtensions={'.pdf'}
                        fileType={'document'}></ListImageAndAddImage>
                )}
            </>
            <>
                {openFileModel && (
                    <ListImageAndAddImage
                        openModal={openFileModel}
                        setOpenModal={() => {
                            setOpenFileModel()
                        }}
                        actionType={['published_products']}
                        selectedFileData={fileData}
                        setSelectedFileData={setFileData}
                        selectionOption={'single'}
                        supportedExtensions={'.pdf,.doc,.docx'}
                        fileType={'document'}></ListImageAndAddImage>
                )}
            </>
            <>
                {translateModalVisible && (
                    <StoreModal
                        title={`${t('common:translate')}`}
                        isVisible={translateModalVisible}
                        okButtonText={null}
                        cancelButtonText={null}
                        okCallback={null}
                        hideCloseButton={false}
                        cancelCallback={() => translateModalCloseHandler()}
                        isSpin={false}
                        width={1000}>
                        <Content>
                            <AttributeValuesTranslatorModal
                                dataJsonForAttributeValue={[dataForAttributeValueTranslater]}
                                respectiveId={parseInt(product_id)}
                                // setName={setCategoryName}
                                // setDescription={setCategoryDescription}
                                // setTitleName={setCategoryTitleName}
                                setTranslateModalVisible={setTranslateModalVisible}
                                onChangeDisableFields={onChangeDisableFields}
                                setOnChangeDisableFields={setOnChangeDisableFields}
                            />
                        </Content>
                    </StoreModal>
                )}
            </>
        </Content>
    )
}
export default GetAttributesControlsNew
