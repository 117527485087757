import { Button, Layout, Table, Tag, Typography, Input, Tooltip, Badge } from 'antd'
import { SearchOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import MarketplaceServices from '../../services/axios/MarketplaceServices'

import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { CopyTextImage, CheckGreen, CopyTextNew } from '../../constants/media'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import DmPagination from '../../components/dmpagination/DmPagination'
import StoreModal from '../../components/storeModal/StoreModal'
import { DiscountImage, newDiscountImage } from '../../constants/media'
import { fnMakeAPICall } from '../../services/redux/actions/ActionsAPICall'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import useGetAllCoupons from './hooks/useGetAllCoupons'

const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const updateCouponStatusAPI = process.env.REACT_APP_UPDATE_COUPONS_STATUS_API
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

const { Content } = Layout
const { Title, Text, Paragraph } = Typography
const { Search } = Input

function Coupon() {
    const { t } = useTranslation()
    const search = useLocation().search
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    const tab = new URLSearchParams(search).get('tab')
    const pageNumber = new URLSearchParams(search).get('page')
    const pageSize = new URLSearchParams(search).get('limit')

    const [searchTableValue, setSearchTableValue] = useState('')
    const [doSerachApiCall, setDoSearchAPICall] = useState(false)
    const [apiSearchTableValue, setApiSearchTableValue] = useState('')
    const [copyMessage, setCopyMessage] = useState(false)

    const { status, data, error } = useGetAllCoupons({
        tabId: tab == 3 ? null : tab,
        pageNumber: parseInt(pageNumber) || 1,
        pageSize: parseInt(pageSize) || itemsPerPageFromEnv,
        searchParams: apiSearchTableValue,
    })

    const [couponsCount, setCouponsCount] = useState(0)
    const [couponsData, setCouponsData] = useState([])

    const [isOpenModalForInactive, setIsOpenModalForInactive] = useState(false)
    const [isOpenModalForActive, setIsOpenModalForActive] = useState(false)
    const [isOpenModalForSuspended, setIsOpenModalForSuspended] = useState(false)
    const [clickedRows, setClickedRows] = useState()
    const [langDirection, setLangDirection] = useState('ltr')

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toUpperCase())
        }
    }, [])

    const actionData = [
        { id: 1, status: 'Active' },
        { id: 0, status: 'Inactive' },
        { id: 2, status: 'Suspended' },
    ]
    const [selectedOption, setSelectedOption] = useState(
        actionData.reduce((acc, row) => {
            acc[row.id] = row.operation
            return acc
        }, {})
    )
    const [couponId, setCouponId] = useState('')
    const [couponStatus, setCouponStatus] = useState('')

    //! currency symbol
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    const defaultCreateCouponObject = {
        key: uuidv4(),
        id: uuidv4(),
        name: '',
        startDate: '',
        endDate: '',
        couponOffer: [],
        description: '',
    }

    //! PUT call for update coupon the status
    const updateCouponStatus = () => {
        MarketplaceServices.update(
            updateCouponStatusAPI,
            {
                status: couponStatus === 'Inactive' ? 0 : couponStatus === 'Active' ? 1 : 2,
            },
            { coupon_id: couponId }
        )
            .then(function (response) {
                console.log('response from update status API###--->', response.data)
                setIsOpenModalForActive(false)
                setIsOpenModalForInactive(false)
                setIsOpenModalForSuspended(false)

                MarketplaceToaster.showToast(util.getToastObject('Status updated successfully', 'success'))
            })
            .catch((error) => {
                console.log('error from update status API', error.response.data)
                setIsOpenModalForActive(false)
                setIsOpenModalForInactive(false)
                setIsOpenModalForSuspended(false)
            })
    }

    const handleRadioButtonChange = (rowId, e) => {
        setSelectedOption((prevOptions) => ({
            ...prevOptions,
            [rowId]: e.target.value,
        }))
        setCouponId(rowId)
        setCouponStatus(e.target.value)
        switch (e.target.value) {
            case 'Inactive':
                setIsOpenModalForInactive(true)
                setIsOpenModalForActive(false)
                setIsOpenModalForSuspended(false)
                break
            case 'Active':
                setIsOpenModalForInactive(false)
                setIsOpenModalForActive(true)
                setIsOpenModalForSuspended(false)
                break
            case 'Suspended':
                setIsOpenModalForInactive(false)
                setIsOpenModalForActive(false)
                setIsOpenModalForSuspended(true)
                break
            default:
                setIsOpenModalForInactive(false)
                setIsOpenModalForActive(false)
                setIsOpenModalForSuspended(false)
                break
        }
    }

    const enableShowMore = (start_date, productDetails) => {
        if (
            (start_date != null && start_date != '') ||
            (productDetails?.max_value != null && productDetails?.max_value != '') ||
            (productDetails?.min_amount != null && productDetails?.min_amount != '') ||
            (productDetails?.min_quantity != null && productDetails?.min_quantity != '')
            // (productDetails?.percentage != null && productDetails?.percentage != '')
        ) {
            return true
        } else {
            return false
        }
    }
    const tableHeader = [
        {
            title: <Content className='whitespace-nowrap'>{t('common:name')}</Content>,
            dataIndex: 'code',
            key: 'code',
            ellipsis: true,
            fixed: 'left',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='flex space-x-2'>
                        <div style={{ maxWidth: '165px' }}>
                            {/* <Link
                                to={`/dashboard/coupons/edit-discount?id=${
                                    record.id
                                }&a=${'edit'}&code=${record.coupon_code}&status=${
                                    parseInt(record.status) === 0 ? 'InActive' : 'Active'
                                }`}> */}
                            <Text
                                ellipsis={{ tooltip: record?.coupon_code }}
                                className='!text-brandGray1 !max-w[165px] font-normal text-[14px]'>
                                {record?.coupon_code}
                            </Text>
                            {/* </Link> */}
                        </div>
                        <Tooltip
                            title={<h6 className='my-auto'>{t('coupons:code_copied')}</h6>}
                            placement='right'
                            mouseLeaveDelay={0}
                            open={parseInt(clickedRows) === parseInt(record.id) ? true : false}>
                            <img
                                src={
                                    parseInt(clickedRows) === parseInt(record.id) && copyMessage
                                        ? CheckGreen
                                        : CopyTextNew
                                }
                                alt='copyText'
                                className='cursor-pointer mx-2  w-[11.24px] h-[13.26px]'
                                onClick={() => {
                                    setClickedRows(record.id)
                                    navigator.clipboard.writeText(record.coupon_code)
                                    setCopyMessage(true)
                                    setTimeout(() => {
                                        setCopyMessage(false)
                                        setClickedRows()
                                    }, 2000)
                                }}
                            />
                        </Tooltip>
                    </Content>
                )
            },
        },

        {
            title: <Content className='whitespace-nowrap'>{t('common:status')}</Content>,
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: '13%',
            render: (text, record) => {
                return (
                    <Content>
                        {record.status === 0 ? (
                            <div className='flex items-center'>
                                <Badge color='gray' />
                                <Text
                                    className={` ${langDirection === 'RTL' ? '!mr-2' : '!text-brandGray1 !text-[14px] !font-normal ml-2'}`}>
                                    {t('coupons:in_active')}
                                </Text>
                            </div>
                        ) : record.status === 1 ? (
                            <div className='flex items-center'>
                                <Badge color='green' />
                                <Text
                                    className={` ${langDirection === 'RTL' ? '!mr-2' : '!text-brandGray1 !text-[14px] !font-normal ml-2'}`}>
                                    {t('coupons:active')}
                                </Text>
                            </div>
                        ) : (
                            <div className='flex items-center'>
                                <Badge color='red' />
                                <Text
                                    className={` ${langDirection === 'RTL' ? '!mr-2' : '!text-brandGray1 !text-[14px] !font-normal ml-2'}`}>
                                    {t('coupons:expired')}
                                </Text>
                            </div>
                        )}
                    </Content>
                )
            },
        },
        {
            title: <Content className='whitespace-nowrap'>{t('coupons:summary')}</Content>,
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: '30%',
            render: (text, record) => (
                <div className='relative'>
                    <div className='mx-2 text-brandGray1 text-[14px] font-normal'>
                        {record.showFullData ? (
                            // Display full data
                            <ul style={{ listStyleType: 'disc' }}>
                                {record.product_discount_details.category &&
                                record.product_discount_details.category.length > 0 ? (
                                    <>
                                        <li>
                                            {`${
                                                record.product_discount_details.coupon_discount_type === 2
                                                    ? ` ${record.product_discount_details.percentage}% ${t('coupons:off')} `
                                                    : ` ${t('coupons:flat')} ${record.product_discount_details.fix_value} ${t('coupons:off')}`
                                            } ${record.product_discount_details.category?.length} ${t('categories:category')}`}
                                        </li>
                                        <li>{t('categories:category')}</li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            {`${
                                                record.product_discount_details.coupon_discount_type === 2
                                                    ? ` ${record.product_discount_details.percentage}% ${t('coupons:off')} `
                                                    : ` ${t('coupons:flat')} ${record.product_discount_details.fix_value} ${t('coupons:off')}`
                                            } ${record.product_discount_details.product?.length} ${t('common:product')}`}
                                        </li>
                                        <li>{t('common:product')}</li>
                                    </>
                                )}
                                {record.product_discount_details.min_amount === 0 ||
                                record.product_discount_details.min_amount ? (
                                    <li>{`${t('coupons:minimum_purchase_of')} ${currencySymbolFromRedux} ${record.product_discount_details.min_amount}`}</li>
                                ) : record.product_discount_details.min_quantity === 0 ||
                                  record.product_discount_details.min_quantity ? (
                                    <li>{`${t('coupons:minimum_purchase_quantity')} ${record.product_discount_details.min_quantity}`}</li>
                                ) : null}
                                {record.product_discount_details.number_of_use ? (
                                    <li>{`${t('coupons:limit_of')} ${record.product_discount_details.number_of_use} ${t('coupons:use')}`}</li>
                                ) : null}
                                {record.product_discount_details.once_per_customer ? (
                                    <li>{`${record.product_discount_details.once_per_customer ? t('coupons:one_per_customer') : ''}`}</li>
                                ) : null}
                                {record.start_date && record.start_date !== null && (
                                    <Tooltip
                                        title={`${t('coupons:active_from')} ${record.start_date} ${record.start_time} ${record.end_date && record.end_date !== null ? `${t('coupons:through')} ${record.end_date} ${record.end_time}` : t('coupons:onwards')}`}
                                        overlayStyle={{ maxWidth: '200px' }}>
                                        <li style={{ whiteSpace: 'nowrap', maxWidth: '180px' }}>
                                            <div
                                                style={{
                                                    width: '180px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                {`${t('coupons:active_from')} ${record.start_date} ${record.start_time} ${record.end_date && record.end_date !== null ? `${t('coupons:through')} ${record.end_date} ${record.end_time}` : t('coupons:onwards')}`}
                                            </div>
                                        </li>
                                    </Tooltip>
                                )}
                            </ul>
                        ) : (
                            // Display truncated data
                            <ul style={{ listStyleType: 'disc' }}>
                                {record.product_discount_details.category &&
                                record.product_discount_details.category.length > 0 ? (
                                    <>
                                        <li>
                                            {`${
                                                record.product_discount_details.coupon_discount_type === 2
                                                    ? ` ${record.product_discount_details.percentage}% ${t('coupons:off')} `
                                                    : ` ${t('coupons:flat')} ${record.product_discount_details.fix_value} ${t('coupons:off')}`
                                            } ${record.product_discount_details.category?.length} ${t('categories:category')}`}
                                        </li>
                                        <li>{t('categories:category')}</li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            {`${
                                                record.product_discount_details.coupon_discount_type === 2
                                                    ? ` ${record.product_discount_details.percentage}% ${t('coupons:off')} `
                                                    : ` ${t('coupons:flat')} ${record.product_discount_details.fix_value} ${t('coupons:off')}`
                                            } ${record.product_discount_details.product?.length} ${t('common:product')}`}
                                        </li>
                                        <li>{t('common:product')}</li>
                                    </>
                                )}
                            </ul>
                        )}
                    </div>
                    <div className={`absolute ${langDirection === 'RTL' ? 'left-0' : 'right-0'} bottom-0`}>
                        {console.log(
                            'record.showFullData',
                            record.showFullData,
                            record.start_date,
                            record.product_discount_details
                        )}
                        <Content
                            className={`app-btn-link text-[14px] font-normal ${
                                enableShowMore(record.start_date, record.product_discount_details)
                                    ? 'cursor-pointer'
                                    : 'invisible'
                            }`}
                            onClick={() =>
                                enableShowMore(record.start_date, record.product_discount_details)
                                    ? toggleShowFullData(record)
                                    : () => false
                            }>
                            {record.showFullData ? (
                                <span>
                                    {t('coupons:show_less')} <UpOutlined />
                                </span>
                            ) : (
                                <span>
                                    {t('coupons:show_more')} <DownOutlined />
                                </span>
                            )}
                        </Content>
                    </div>
                </div>
            ),
        },

        // {
        //     // Button column to toggle showing full data
        //     dataIndex: 'showFullData',
        //     key: 'showFullData',
        //     width: '13%',
        //     render: (_, record) => (
        //         <>
        //             <Content
        //                 className={`app-btn-link text-[14px] font-normal ${enableShowMore(record.start_date, record.product_discount_details) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
        //                 disabled={true}
        //                 onClick={() =>
        //                     enableShowMore(record.start_date, record.product_discount_details)
        //                         ? toggleShowFullData(record)
        //                         : () => false
        //                 }>
        //                 {record.showFullData ? (
        //                     <span>
        //                         {t('coupons:show_less')} <UpOutlined />
        //                     </span>
        //                 ) : (
        //                     <span>
        //                         {t('coupons:show_more')} <DownOutlined />
        //                     </span>
        //                 )}
        //             </Content>
        //         </>
        //     ),
        // },

        {
            title: <Content className='whitespace-nowrap'>{t('common:action')}</Content>,
            dataIndex: '',
            key: '',
            width: '15%',
            render: (text, record) => (
                <div>
                    {/* {record.status !== 2 && ( */}
                    <Link
                        to={`/dashboard/coupons/edit-discount?id=${
                            record.id
                        }&a=${'edit'}&code=${record.coupon_code}&status=${
                            parseInt(record.status) === 0
                                ? 'InActive'
                                : parseInt(record.status) === 2
                                  ? 'Expired'
                                  : 'Active'
                        }&${MarketplaceAppConfig.getStore(
                            ''
                        )}`}>
                        <Text className='font-medium text-[14px] text-brandPrimaryColor !cursor-pointer'>
                            {t('common:view_details')}
                        </Text>
                    </Link>
                    {/* )} */}
                </div>
            ),
        },

        // {
        //   title: (
        //     <Content className="whitespace-nowrap">
        //       {t("coupons:combinations")}
        //     </Content>
        //   ),
        //   dataIndex: "status",
        //   key: "status",
        //   ellipsis: true,
        //   width: "15%",
        //   render: (text, record) => {
        //     return (
        //       <Content>
        //         {" "}
        //         <div>{t("coupons:amount_off_products")}</div>
        //       </Content>
        //     );
        //   },
        // },
        // {
        //   title: <Content className="whitespace-nowrap">{"Description"}</Content>,
        //   dataIndex: "Description",
        //   key: "Description",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return (
        //       <div className="max-w-[100px]">
        //         <Text ellipsis={{ tooltip: record.description }}>
        //           {record.description}
        //         </Text>
        //       </div>
        //     );
        //   },
        // },
        // {
        //   title: <Content className="whitespace-nowrap">{"Start Date"}</Content>,
        //   dataIndex: "startDate",
        //   key: "startDate",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.startDate}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="whitespace-nowrap">{"End Date"}</Content>,
        //   dataIndex: "endDate",
        //   key: "endDate",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.endDate}</Content>;
        //   },
        // },
        // {
        //   title: (
        //     <Content className="whitespace-nowrap">{"Term & Condition"}</Content>
        //   ),
        //   dataIndex: "T&C",
        //   key: "T&C",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.termsCondition}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="whitespace-nowrap">{"Discount Type"}</Content>,
        //   dataIndex: "discountType",
        //   key: "discountType",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     const discountTypeText =
        //       record.discountType === 1
        //         ? "Fixed"
        //         : record.discountType === 2
        //         ? "Percentage"
        //         : "";
        //     return <Content>{discountTypeText}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="">{"Fix Value"}</Content>,
        //   dataIndex: "fixValue",
        //   key: "fixValue",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.fixValue}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="">{"Max Value"}</Content>,
        //   dataIndex: "maxValue",
        //   key: "maxValue",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.maxValue}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="">{"Discount [%]"}</Content>,
        //   dataIndex: "discountPercentage",
        //   key: "discountPercentage",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.discountPercentage}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="">{"Min Order Value"}</Content>,
        //   dataIndex: "minOrderValue",
        //   key: "minOrderValue",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.minOrderValue}</Content>;
        //   },
        // },
        // {
        //   title: <Text ellipsis>{"New Customer Order"}</Text>,
        //   dataIndex: "newCustomerOrder",
        //   key: "newCustomerOrder",
        //   width: 150,
        //   ellipsis: true,
        //   render: (text, record) => {
        //     return (
        //       <div style={{ maxWidth: "100px" }}>
        //         <Text ellipsis>{record.newCustomerOrder}</Text>
        //       </div>
        //     );
        //   },
        // },
        // {
        //   title: <Content className="">{"Max Orders Allowed"}</Content>,
        //   dataIndex: "maxOrdersAllowed",
        //   key: "maxOrdersAllowed",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return (
        //       <div style={{ maxWidth: "100px" }}>
        //         <Text ellipsis>{record.maxOrderAllowed}</Text>
        //       </div>
        //     );
        //   },
        // },

        // {
        //   title: <Text ellipsis>{"Max Order Per Customer"}</Text>,
        //   dataIndex: "maxOrderPerCustomer",
        //   key: "maxOrderPerCustomer",
        //   width: 150,
        //   ellipsis: true,
        //   render: (text, record) => {
        //     return (
        //       <div style={{ maxWidth: "100px" }}>
        //         <Text ellipsis>{record.maxOrderPerCustomer}</Text>
        //       </div>
        //     );
        //   },
        // },
        // {
        //   title: <Content className="">{"Customer Choice"}</Content>,
        //   dataIndex: "customerChoice",
        //   key: "customerChoice",
        //   ellipsis: true,
        //   width: 150,
        //   render: (text, record) => {
        //     return <Content>{record.customerChoice}</Content>;
        //   },
        // },
        // {
        //   title: <Content className="">{"Action"}</Content>,
        //   dataIndex: "operation",
        //   key: "id",
        //   ellipsis: true,
        //   fixed: "right",
        //   width: 280,
        //   render: (text, record) => {
        //     return (
        //       <Content>
        //         <Radio.Group
        //           buttonStyle={{ backgroundColor: "#0246BB", color: "#fff" }}
        //           onChange={(e) => handleRadioButtonChange(record.id, e)} // Pass the rowId to the handler
        //           value={selectedOption[record.id]} // Get the selected option for the row
        //         >
        //           <Radio.Button
        //             className="text-white"
        //             value="Active"
        //             style={{
        //               backgroundColor:
        //                 selectedOption[record.id] === "Active"
        //                   ? "#0246BB"
        //                   : "rgb(74, 45, 115)",
        //             }}
        //           >
        //             Active
        //           </Radio.Button>
        //           <Radio.Button
        //             className="text-white"
        //             value="Inactive"
        //             style={{
        //               backgroundColor:
        //                 selectedOption[record.id] === "Inactive"
        //                   ? "#0246BB"
        //                   : "rgb(74, 45, 115)",
        //             }}
        //           >
        //             Inactive
        //           </Radio.Button>
        //           <Radio.Button
        //             className="text-white"
        //             value="Suspended"
        //             style={{
        //               backgroundColor:
        //                 selectedOption[record.id] === "Suspended"
        //                   ? "#EF4444"
        //                   : "rgb(74, 45, 115)",
        //             }}
        //           >
        //             Suspended
        //           </Radio.Button>
        //         </Radio.Group>
        //       </Content>
        //     );
        //   },
        // },
        // {
        //     title: t('coupons:used'),
        //     dataIndex: 'used',
        //     key: 'used',
        //     render: (text, record) => {
        //         return <p>{record?.product_discount_details?.number_of_use || 0}</p>
        //     },
        // },
    ]

    const toggleShowFullData = (record) => {
        const newData = couponsData.map((item) =>
            item.key === record.key ? { ...item, showFullData: !item.showFullData } : item
        )
        setCouponsData(newData)
    }

    const createDiscount = () => {
        navigate(`/dashboard/coupons/create-discount?a=${'add'}&${MarketplaceAppConfig.getStore('')}`)
    }

    const handlePageNumberChange = (page, pageSize) => {
        window.scrollTo(0, 0)
        setSearchParams({
            tab: parseInt(tab),
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }

    const handleSearchChange = (searchValue) => {
        if (searchValue?.trim()) {
            setSearchTableValue(searchValue)
            setApiSearchTableValue(searchValue)
        }
    }
    const handleInputChange = (event) => {
        let searchText = event.target.value
        if (searchText === '' && searchTableValue !== ' ' && searchTableValue?.trim()) {
            // setDoSearchAPICall(!doSerachApiCall)
            setApiSearchTableValue('')
        }
        let trimmedSearchText = searchText.replace(/\s+/g, ' ')
        setSearchTableValue(trimmedSearchText)
    }

    const customButton = <Button type='primary' disabled={!searchTableValue.trim()} icon={<SearchOutlined />} />

    useEffect(() => {
        return () => {
            setSearchTableValue('')
            setApiSearchTableValue('')
        }
    }, [tab])

    useEffect(() => {
        if (status == 'success' && data) {
            setCouponsCount(data?.count)
            const newArray = data?.data.map((obj) => ({
                ...obj,
                ['key']: obj.id,
                ['showFullData']: false,
            }))
            if (newArray?.length > 0) {
                setCouponsData(newArray)
            } else {
                setCouponsData([])
            }
        } else if (status == 'error') {
            setCouponsData([])
        }
        dispatch(fnMakeAPICall({ makeAPICall: false }))
    }, [data, status])

    console.log('couponsData--->', couponsData)
    return (
        <Content className='mt-4'>
            {data?.data?.length > 0 || searchTableValue ? (
                <Search
                    placeholder={t('common:please_enter_search_text_here')}
                    onSearch={handleSearchChange}
                    onChange={handleInputChange}
                    value={searchTableValue}
                    suffix={null}
                    maxLength={searchMaxLength}
                    enterButton={customButton}
                    allowClear
                    className='!w-[30%] !float-right my-4 mr-5'
                />
            ) : null}
            {status == 'pending' || (data?.data?.length > 0 && couponsData?.length === 0) ? (
                <Content className='p-3 text-center ml-2 !bg-white shadow-brandShadow rounded-lg'>
                    <SkeletonComponent />
                </Content>
            ) : status === 'error' ? (
                <Content className='text-center !mt-10 !mb-2 bg-white p-3 shadow-brandShadow rounded-lg'>
                    <h1 level={5}>{t('common:network_error')}</h1>
                </Content>
            ) : data?.count === 0 && searchTableValue ? (
                <Content className='text-center font-semibold ml-2 mt-2 bg-white px-3 !pt-[5rem] !pb-3  shadow-brandShadow rounded-lg'>
                    <Text>{t('common:not_able_to_find_searched_details')}</Text>
                </Content>
            ) : status === 'success' ? (
                <Content>
                    <Content className='!bg-white p-2  shadow-brandShadow rounded-lg'>
                        <Content className=''>
                            {data?.data?.length <= 0 ? (
                                <>
                                    {' '}
                                    <Content className='p-0 text-center'>
                                        <img src={newDiscountImage} alt='discountImage' />
                                    </Content>
                                    <Content className='text-center my-3'>
                                        <Title level={4} className='font-medium text-[18px] !text-brandBlack'>
                                            {' '}
                                            {t('coupons:manage_discounts')}
                                        </Title>
                                        <Text className='!text-[14px] !text-brandGray2 !font-normal'>
                                            {' '}
                                            {t('coupons:manage_discounts_text')}
                                        </Text>
                                        {/* <Content className='app-btn-link cursor-pointer font-semibold'>
                                            <a href='https://docs.torryharrismarketplace.com/' target='blank'>
                                                <span>{t('coupons:know_more')}</span>
                                            </a>
                                        </Content> */}
                                    </Content>
                                    <Content className='flex justify-center items-center'>
                                        <Button
                                            className='my-3 app-btn-primary flex items-center justify-center'
                                            onClick={() => createDiscount()}>
                                            <PlusOutlined className='' />
                                            {t('coupons:create_coupon')}
                                        </Button>
                                    </Content>
                                </>
                            ) : (
                                <Content>
                                    <Content className='bg-white !p-2'>
                                        <Table
                                            dataSource={couponsData}
                                            columns={tableHeader}
                                            pagination={false}
                                            // scroll={{ x: 1000 }}
                                            // scrollToFirstRowOnChange
                                            // expandable={{
                                            //   expandedRowRender: (record) => (
                                            //     <Content className="!pl-[483px]">
                                            //       {/* {record.couponCode} */}
                                            //       <Paragraph>
                                            //         {/* <ul>
                                            //           <li>
                                            //             {" "}
                                            //             <div className="max-w-[100px]">
                                            //               <Text
                                            //                 ellipsis={{ tooltip: record.description }}
                                            //               >
                                            //                 {record.description}
                                            //               </Text>
                                            //             </div>
                                            //           </li>
                                            //           <li>
                                            //             Line 2: Your second bulletin point here.
                                            //           </li>
                                            //           <li>Line 3: Your third bulletin point here.</li>
                                            //           <li>
                                            //             Line 4: Your fourth bulletin point here.
                                            //           </li>
                                            //           <li>Line 5: Your fifth bulletin point here.</li>
                                            //         </ul> */}
                                            //         {/* <ul style={{ listStyleType: "disc" }}>
                                            //           <li>{record.description}</li>
                                            //         </ul> */}
                                            //         {console.log("recorddd", record)}
                                            //         <ul style={{ listStyleType: "disc" }}>
                                            //           {record.product_discount_details.category &&
                                            //           record.product_discount_details.category
                                            //             .length > 0 ? (
                                            //             <>
                                            //               <li>
                                            //                 {`${
                                            //                   record.product_discount_details
                                            //                     .coupon_discount_type === 2
                                            //                     ? ` ${record.product_discount_details.percentage}% off `
                                            //                     : ` Flat ${record.product_discount_details.fix_value}  off`
                                            //                 }  ${
                                            //                   record.product_discount_details.category
                                            //                     ?.length
                                            //                 } Category`}
                                            //               </li>
                                            //               <li>{"Category"}</li>
                                            //             </>
                                            //           ) : (
                                            //             <>
                                            //               <li>
                                            //                 {`${
                                            //                   record.product_discount_details
                                            //                     .coupon_discount_type === 2
                                            //                     ? ` ${record.product_discount_details.percentage}% off `
                                            //                     : ` Flat ${record.product_discount_details.fix_value}  off`
                                            //                 }  ${
                                            //                   record.product_discount_details.product
                                            //                     ?.length
                                            //                 } Product`}
                                            //               </li>
                                            //               <li>{"Product"}</li>
                                            //             </>
                                            //           )}
                                            //           {record.product_discount_details
                                            //             .minimum_purchase_requirement && (
                                            //             <li>{`Minimum purchase of ${record.product_discount_details.minimum_purchase_requirement}`}</li>
                                            //           )}
                                            //           {record.product_discount_details
                                            //             .number_of_use ? (
                                            //             <li>{`Limit of ${record.product_discount_details.number_of_use} use one per customer`}</li>
                                            //           ) : null}
                                            //           <li>{`Active from ${record.start_date}`}</li>
                                            //         </ul>
                                            //       </Paragraph>
                                            //     </Content>
                                            //   ),
                                            //   indentSize: 20,
                                            //   rowExpandable: (record) => {
                                            //     if (true) {
                                            //       return true;
                                            //     } else {
                                            //       return false;
                                            //     }
                                            //   },
                                            //   expandIcon: ({ expanded, onExpand, record }) =>
                                            //     expanded ? (
                                            //       <content className="!w-10">
                                            //         <span>Show less </span>

                                            //         <UpOutlined
                                            //           onClick={(e) => {
                                            //             onExpand(record, e);
                                            //           }}
                                            //         />
                                            //       </content>
                                            //     ) : true ? (
                                            //       <>
                                            //         <span>Show more </span>
                                            //         <DownOutlined
                                            //           onClick={(e) => {
                                            //             onExpand(record, e);
                                            //           }}
                                            //         />
                                            //       </>
                                            //     ) : null,
                                            // }}
                                        />
                                    </Content>

                                    <StoreModal
                                        isVisible={isOpenModalForInactive}
                                        title={'Confirmation'}
                                        okButtonText={'Ok'}
                                        cancelButtonText={'Cancel'}
                                        cancelCallback={() => {
                                            setIsOpenModalForInactive(false)
                                        }}
                                        okCallback={() => updateCouponStatus()}
                                        isSpin={false}>
                                        <div>
                                            <Text>Are you sure, you want to inactive?</Text>
                                        </div>
                                    </StoreModal>

                                    <StoreModal
                                        isVisible={isOpenModalForActive}
                                        title={'Confirmation'}
                                        okButtonText={'Ok'}
                                        cancelButtonText={'Cancel'}
                                        cancelCallback={() => {
                                            setIsOpenModalForActive(false)
                                        }}
                                        okCallback={() => updateCouponStatus()}
                                        isSpin={false}>
                                        <div>
                                            <Text>Are you sure, you want to active?</Text>
                                        </div>
                                    </StoreModal>

                                    <StoreModal
                                        isVisible={isOpenModalForSuspended}
                                        title={'Confirmation'}
                                        okButtonText={'Ok'}
                                        cancelButtonText={'Cancel'}
                                        cancelCallback={() => {
                                            setIsOpenModalForSuspended(false)
                                        }}
                                        okCallback={() => updateCouponStatus()}
                                        isSpin={false}>
                                        <div>
                                            <Text>Are you sure, you want to suspended?</Text>
                                        </div>
                                    </StoreModal>

                                    {/* TODO:will add based on the requirement  */}
                                    {couponsCount > itemsPerPageFromEnv ? (
                                        <Content className='!grid !justify-items-end'>
                                            <DmPagination
                                                currentPage={
                                                    parseInt(searchParams.get('page'))
                                                        ? parseInt(searchParams.get('page'))
                                                        : 1
                                                }
                                                totalItemsCount={couponsCount}
                                                defaultPageSize={itemsPerPageFromEnv}
                                                pageSize={
                                                    parseInt(searchParams.get('limit'))
                                                        ? parseInt(searchParams.get('limit'))
                                                        : itemsPerPageFromEnv
                                                }
                                                handlePageNumberChange={handlePageNumberChange}
                                                showSizeChanger={true}
                                                showTotal={true}
                                                showQuickJumper={true}
                                            />
                                        </Content>
                                    ) : null}
                                </Content>
                            )}
                        </Content>
                    </Content>
                </Content>
            ) : null}
        </Content>
    )
}

export default Coupon
