import { InfoCircleOutlined } from '@ant-design/icons'
import { Col, Layout, Row, Tooltip, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GetAttributesControls } from '../../util/AttributeControls'

const { Paragraph } = Typography
const { Content } = Layout

const ServiceProductAttributesPreview = ({ getSlotGroupAPIDataFiltered }) => {
    const { t } = useTranslation()

    return (
        <div>
            {getSlotGroupAPIDataFiltered &&
                getSlotGroupAPIDataFiltered.length > 0 &&
                getSlotGroupAPIDataFiltered.map((element, index) => (
                    <>
                        <Paragraph className='my-0 py-0'>{element.display_description}</Paragraph>
                        {element.attribute.map((item, index) => (
                            <Content key={index} className='mb-[1.5rem] break-all'>
                                <Row>
                                    <Col className='max-w-[80%]'>
                                        <h5 className='!my-0 !font-semibold'>
                                            {item.display_name}
                                            <span
                                                className={`${
                                                    item.mandatory === true ? 'text-red-600 text-sm ml-1' : null
                                                }`}>
                                                {item.mandatory === true ? '* ' : ''}
                                            </span>
                                        </h5>
                                    </Col>
                                    <Col className='!-translate-y-[5px] !ml-[7px] '>
                                        <Tooltip
                                            overlayStyle={{
                                                zIndex: 1,
                                            }}
                                            autoAdjustOverflow={true}
                                            placement='top'
                                            title={
                                                <>
                                                    {item.hide_to_customer === true ||
                                                    item.is_static === true ||
                                                    item.is_variant_attribute === true ||
                                                    item.mandatory === true ||
                                                    item.order_specific === true ||
                                                    item.revisable === true ||
                                                    item.sensible === true ||
                                                    item.unique === true ||
                                                    item.varient_specific === true ||
                                                    item.attribute_type ? (
                                                        <>
                                                            <p className='mb-0'>{`${t(
                                                                'attributes:Attribute-Type-preferences-was-selected'
                                                            )}`}</p>
                                                            {item.attribute_type !== null &&
                                                            item.attribute_type !== undefined ? (
                                                                <p className='mb-0'>{item.attribute_type}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.hide_to_customer === true ? (
                                                                <p className='mb-0'>{`${t(
                                                                    'attributes:Hide-to-Customer'
                                                                )}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.is_static === true ? (
                                                                <p className='mb-0'>{`${t('attributes:Static')}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.is_variant_attribute === true ? (
                                                                <p className='mb-0'>{`${t(
                                                                    'attributes:Mark-as-Variant'
                                                                )}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.mandatory === true ? (
                                                                <p className='mb-0'>{`${t('attributes:Mandatory')}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.order_specific === true ? (
                                                                <p className='mb-0'>{`${t(
                                                                    'attributes:Order-Specific'
                                                                )}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.revisable === true ? (
                                                                <p className='mb-0'>Revisable</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.sensible === true ? (
                                                                <p className='mb-0'>Sensible</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.unique === true ? (
                                                                <p className='mb-0'>{`${t('attributes:Unique')}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {item.varient_specific === true ? (
                                                                <p className='mb-0'>{`${t(
                                                                    'attributes:Variant-Specific'
                                                                )}`}</p>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : null}
                                                </>
                                            }>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Content className='mt-1'>
                                    <Row>
                                        {item.attribute_option && item.attribute_option.length > 0 ? (
                                            <Col span={20}>
                                                <GetAttributesControls
                                                    optionType={item.datatype_id}
                                                    attributeType={item.attribute_type}
                                                    optionValues={item.attribute_option}
                                                    sensitive={item.sensible}
                                                    isEditable={true}
                                                    nonEditableValue={null}
                                                    attribute_unit_values={item.attribute_unit_values}
                                                    placeholder={item.placeholder}
                                                />
                                            </Col>
                                        ) : (
                                            <Col span={20}>
                                                <GetAttributesControls
                                                    optionType={item.datatype_id}
                                                    attributeType={item.attribute_type}
                                                    optionValues={[]}
                                                    sensitive={item.sensible}
                                                    isEditable={true}
                                                    attribute_unit_values={item.attribute_unit_values}
                                                    placeholder={item.placeholder}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                </Content>
                            </Content>
                        ))}
                    </>
                ))}
        </div>
    )
}

export default ServiceProductAttributesPreview
