import { Layout } from 'antd'
import React from 'react'
import { usePageTitle } from '../../hooks/usePageTitle'
import './home.css'

const { Content } = Layout

const Home = () => {
    usePageTitle('Home')

    return (
        <Content className=' temppic grid justify-items-center p-3 h-[75vh] bg-bottom '>
            {/* {void auth.signinRedirect()} */}
            {/* {!isLoggedIn && (
        <>
          <h1>Loading STORE-OWNER-PORTAL</h1>
          <button onClick={() => void auth.signinRedirect()}>Log in</button>
        </>
      )} */}
            {/* <Title level={4}>This is Home page</Title> */}
            {/* {typeof persistedUserLoggedInInfo !== "undefined" ? (
      <Link to="dashboard">
        <Button className="!h-10 !bg-[#393939] text-white !border-[1px] !border-solid !border-[#393939] !box-border !rounded !pl-[15px]">
          Go to Dashboard
        </Button>
      </Link>
    ) : (
      <Link to="/signin">
        <Button className="!h-10 mt-5 !bg-[#393939] text-white !border-[1px] !border-solid !border-[#393939] !box-border !rounded !pl-[15px]">
          Signin
        </Button>
      </Link>
    )} */}
        </Content>
    )
}

export default Home
