import { Alert, Button, Checkbox, Modal, Skeleton, Tooltip, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiInformationFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import StoreModal from '../../../components/storeModal/StoreModal'
import { EmptyPolicy } from '../../../constants/media'
import { CONTACT_INFORMATION } from '../constants'
import useDeleteStoreUserConsent from '../hooks/useDeleteStoreUserConsent'
import PolicyCard from './PolicyCard'
import PreviewAndCustomize from './PreviewAndCustomize'
const { Title, Text } = Typography

const CreatePolicies = ({
    selectedTab,
    userConsents,
    userConsentStatus,
    previewAndCustomize,
    addNewPolicy,
    newPolicyRef,
    refetchUserConsent,
    handelAddNewPolicy,
    setAddNewPolicy,
    setPreviewAndCustomize,
}) => {
    const { t } = useTranslation()
    const [addContactInfo, setContactInfo] = useState(false)
    const [deletePolicy, setDeletePolicy] = useState(null)

    const { contactInformation, policiesWithoutContactInformation } = useMemo(() => {
        let contactInformation = []
        let policiesWithoutContactInformation = []
        let userConsentsFromServer = userConsents?.store_userconsent_data
        let consentInfo = {}
        userConsentsFromServer &&
            userConsentsFromServer.length > 0 &&
            userConsentsFromServer.forEach((consentDetails) => {
                consentInfo.version_count = consentDetails.count
                consentDetails?.version_details?.forEach((consent) => {
                    if (consent?.consent_name === CONTACT_INFORMATION) {
                        let contactInformationConcatenated = { ...consentInfo, ...consent }
                        contactInformation = [contactInformationConcatenated]
                    } else {
                        let consentInformationConcatenated = { ...consentInfo, ...consent }
                        policiesWithoutContactInformation.push(consentInformationConcatenated)
                    }
                })
            })
        return { contactInformation, policiesWithoutContactInformation }
    }, [userConsents?.store_userconsent_data])

    const { mutate: deleteStoreUserConsent, status: deleteStoreUserConsentStatus } = useDeleteStoreUserConsent()

    const onContactInfoChange = (e) => {
        if (contactInformation?.length > 0) {
            const contactInformationId = contactInformation?.[0]?.store_user_consent_id
            setDeletePolicy(contactInformationId)
        } else {
            setContactInfo(e.target.checked)
        }
    }

    const handelDeletePolicy = (userConsentId) => {
        if (userConsentId) setDeletePolicy(userConsentId)
        else setAddNewPolicy(false)
    }
    const deletePolicyById = (userConsentId) => {
        if (userConsentId) {
            deleteStoreUserConsent(
                { userConsentId },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        setContactInfo(false)
                        toast(t('policies:policy_deleted_successfully'), {
                            type: 'success',
                        })
                        setDeletePolicy(null)
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_deleting_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
    }

    return (
        <section className='bg-white min-h-screen p-4 rounded-lg'>
            <Skeleton loading={userConsentStatus === 'pending'} active />
            {userConsentStatus === 'success' && (
                <div>
                    {policiesWithoutContactInformation?.length > 0 &&
                        policiesWithoutContactInformation?.map((consent) => {
                            return (
                                <div key={consent?.id}>
                                    <PolicyCard
                                        policyType='SAVED_POLICY'
                                        consent={consent}
                                        refetchUserConsent={refetchUserConsent}
                                        handelDeletePolicy={handelDeletePolicy}
                                    />
                                </div>
                            )
                        })}

                    {addNewPolicy ? (
                        <div ref={newPolicyRef}>
                            <PolicyCard
                                isNewPolicy
                                refetchUserConsent={refetchUserConsent}
                                setAddNewPolicy={setAddNewPolicy}
                                selectedTab={selectedTab}
                                handelDeletePolicy={handelDeletePolicy}
                            />
                        </div>
                    ) : userConsents?.store_userconsent_data?.length <= 0 ? (
                        <div className=' py-3'>
                            <div className='flex justify-center mb-3'>
                                <img src={EmptyPolicy} alt='EmptyPolicy' />
                            </div>
                            <Title level={3} className='!font-normal mb-0 mx-auto text-center'>
                                {t('policies:policies')}
                            </Title>
                            <div className='flex justify-center'>
                                <Text className='text-slate-400'>{t('policies:craft_policy_text')}</Text>
                            </div>
                            <div className='flex justify-center'>
                                {/* <Link to=''>
                                    <Text className='!text-[#1677ff] hover:text-[#4096ff]'>
                                        {t('policies:know_more')}
                                    </Text>
                                </Link> */}
                            </div>
                            <div className='flex justify-center my-4'>
                                <Button className='app-btn-primary ' onClick={handelAddNewPolicy}>
                                    {t('policies:add_new_policy')}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                    <div className=' flex items-center'>
                        <Checkbox
                            onChange={onContactInfoChange}
                            checked={addContactInfo || contactInformation?.length > 0}>
                            {t('policies:display_contact_information')}
                        </Checkbox>
                        {/* <Tooltip title={t('policies:contact_policy_info')}>
                            <RiInformationFill className=' text-[#1677ff] text-[16px] cursor-pointer' />
                        </Tooltip> */}
                    </div>
                    <div>
                        <Alert
                            message={t('policies:contact_info')}
                            type='info'
                            showIcon
                            className=' my-2 ml-7 w-[395px] border-0'
                        />
                    </div>
                    {(addContactInfo || contactInformation?.length > 0) && (
                        <PolicyCard
                            policyType='CONTACT_POLICY'
                            refetchUserConsent={refetchUserConsent}
                            consent={contactInformation?.[0] || null}
                            selectedTab={selectedTab}
                            policyName={t('policies:contact_information')}
                            setAddNewPolicy={setAddNewPolicy}
                            isNewPolicy={contactInformation?.length === 0}
                            key={contactInformation?.[0]?.id || 'addContactInfo'}
                        />
                    )}
                </div>
            )}
            {userConsentStatus === 'error' && (
                <div className=' text-center mt-5'>
                    <Text>{t('common:network_error')}</Text>
                </div>
            )}
            <Modal
                open={deletePolicy}
                title={t('policies:delete_policies')}
                isSpin={false}
                width={'446px'}
                centered={true}
                onCancel={() => setDeletePolicy(null)}
                footer={[
                    <Button onClick={() => setDeletePolicy(null)} disabled={deleteStoreUserConsentStatus === 'pending'}>
                        {t('common:cancel')}
                    </Button>,
                    <Button
                        className=' app-btn-primary'
                        onClick={() => deletePolicyById(deletePolicy)}
                        loading={deleteStoreUserConsentStatus === 'pending'}>
                        {t('common:yes')}
                    </Button>,
                ]}>
                <Text className='!text-[#333333]'>{t('policies:delete_confirmation')}</Text>
            </Modal>
            <StoreModal
                isVisible={previewAndCustomize}
                title={t('policies:preview_and_customise')}
                isSpin={false}
                cancelCallback={() => setPreviewAndCustomize(null)}
                width={1088}
                destroyOnClose={true}>
                <PreviewAndCustomize
                    userConsents={userConsents}
                    selectedTab={selectedTab}
                    closeModal={() => setPreviewAndCustomize(null)}
                    refetchUserConsent={refetchUserConsent}
                />
            </StoreModal>
        </section>
    )
}
export default CreatePolicies
