import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
const { Content } = Layout

const TranscationsOutlet = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate('vendor-details?' + MarketplaceAppConfig.getStore(''))
    }, [])
    return (
        <Content>
            <Content>
                <Outlet />
            </Content>
        </Content>
    )
}

export default TranscationsOutlet
