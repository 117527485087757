import { Layout, Skeleton } from 'antd'
import React, { useState } from 'react'

const { Content } = Layout

const { Image } = Skeleton

const Images = ({ imageData }) => {
    const [tileImages, setTileImages] = useState(imageData)
    const [largeImage, setLargeImage] = useState(
        imageData.length === 1 ? imageData[0].imagePath : <Image className='w-[100%]' />
    )
    const [imageId, setImageId] = useState()
    //   useEffect(() => {
    //     if (tileImages.length < 1) {
    //       setLargeImage(CategoriesImage);
    //     }
    //   }, []);

    const handleTileImageChange = (id) => {
        let temp = tileImages && tileImages.filter((ele) => ele.id === id)
        setLargeImage(temp[0].imagePath)

        setImageId(id)
    }

    return (
        <Content className='flex flex-row '>
            {tileImages && tileImages.length > 1 ? (
                <Content className='w-[40%] mr-2'>
                    <Content>
                        {tileImages &&
                            tileImages.map((ele) => {
                                return (
                                    <Content
                                        className={`${
                                            ele.id === imageId
                                                ? 'border-2 border-red-400 mb-2'
                                                : 'border-2 border-zinc-400 mb-2'
                                        }`}
                                        onMouseOver={() => handleTileImageChange(ele.id)}>
                                        <img src={ele.imagePath} alt='dynamicImage' />
                                    </Content>
                                )
                            })}
                    </Content>
                </Content>
            ) : null}

            <Content className='w-[100%]'>{largeImage}</Content>
        </Content>
    )
}

export default Images
