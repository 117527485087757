import { ActionTypes } from '../constants/ActionTypes'

const { RDX_ATTRIBUTE_GROUP_DETAILS } = ActionTypes

export const fnAttributeGroupDetails = (data) => {
    return {
        type: RDX_ATTRIBUTE_GROUP_DETAILS,
        payload: data,
    }
}
